import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    Label,
    Input
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import savedSuccess from '../../../../assets/images/savedSuccess.svg';
import { Deleted } from '../../../../assets/svg/deleted';
import { getRoom } from '../../../../utils/variables';
import { TooltipItem } from '../../../../components/ToolTip';
import { DateRangePicker } from '../DateRangePicker';
import { ComparisonTimeLine } from './CompairsonTimeline';
import { Entities } from './Entities';
import { LocationMentions } from './LocationMentions';
import { CREATE_COMPARISON, DELETE_COMPARISON, GET_COMPARISON_NAME } from './ComparisonQueries';
import { Tabs } from '../../../../components/Tabs';

export const calculateAverageThreatLabel = (array) => {
    if (array?.length === 0) return null;
    const modeMap = {};
    let maxEl = array && array[0];
    let maxCount = 1;
    for (let i = 0; i < array?.length; i++) {
        const el = array[i];
        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;
        if (modeMap[el] > maxCount) {
            maxEl = el;
            maxCount = modeMap[el];
        }
    }
    return maxEl;
};

export const Compare = ({ data, isEdit, comparisonMainData }) => {
    const room = getRoom();
    const history = useHistory();
    const [saveModal, setSaveModal] = useState(false);
    const [savedSuccessModal, setSavedSuccessModal] = useState(false);
    const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [editName, setEditName] = useState(false);
    const [dateData, setDateData] = useState({});
    const [visibleSaveButton, setVisibleSaveButton] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [compareData, setCompareData] = useState(
        isEdit ? data : history?.location?.state
    );

    const [comparisonName, setComparisonName] = useState(
        isEdit ? comparisonMainData?.name : ''
    );

    const [isComparisonNameEmpty, setIsComparisonScreenEmpty] = useState(false);
    const [sameComparisonName, setSameComparisonName] = useState(false);

    const threatLabels = compareData?.map((x) => x.average_threat_label);
    const narrativeIds = compareData?.map((x) => x.id);

    useEffect(() => {
        if (
            comparisonName !== comparisonMainData?.name
      || JSON.stringify(compareData) !== JSON.stringify(data)
        ) setVisibleSaveButton(true);
    }, [comparisonName, compareData, comparisonMainData?.name, data]);

    const averageThreatLabel = calculateAverageThreatLabel(threatLabels);
    const { data: cNameData } = useQuery(GET_COMPARISON_NAME, {
        variables: {
            situationRoom: room?.id,
        },
        fetchPolicy: 'no-cache'
    });

    const [narrativeComparisonInput] = useMutation(CREATE_COMPARISON, {
        onCompleted: () => {
            setSaveModal(false);
            setSavedSuccessModal(true);
        },
    });

    const [deleteNarrativeComparison] = useMutation(DELETE_COMPARISON, {
        onCompleted: () => {
            setIsDelete(false);
            setDeleteSuccessModal(true);
        },
    });

    const onDeleteComparisonClick = () => {
        deleteNarrativeComparison({
            variables: {
                id: comparisonMainData?.id,
            },
        });
    };

    const checkName = () => {
        const NameMapValues = cNameData?.getComparisons?.map((a) => a.name.toLowerCase());
        if (NameMapValues.includes(comparisonName.toLowerCase())) {
            return true;
        }
        return false;
    };

    const onSaveClick = () => {
        if (!comparisonName) {
            setIsComparisonScreenEmpty(true);
        }
        else if (checkName()) {
            setSameComparisonName(true);
            setIsComparisonScreenEmpty(false);
        }
        else {
            setIsComparisonScreenEmpty(false);
            setSameComparisonName(false);
            narrativeComparisonInput({
                variables: {
                    data: {
                        situationRoom: room.id,
                        name: comparisonName,
                        createdAt: new Date(),
                        narratives: narrativeIds,
                        averageThreatLevel: averageThreatLabel,
                    },
                },
            });
        }
    };

    const goToPrevPage = () => {
        if (isEdit) {
            window.location.replace(`${window.location.origin}/situation-rooms/${room?.id}/narratives?true=saved-comparisons`);
        }
        else {
            history.goBack();
        }
    };

    return (
        <div>
            <Button className="go-back-from-dashboard text-decoration-none align-items-center"
                color="link"
                onClick={() => goToPrevPage()}
            >
                <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                <span>
                    {isEdit ? 'Back to saved comparisons' : 'Back'}
                </span>
            </Button>
            <div className="ml-0 mr-0 mt-3 d-flex align-items-center flex-wrap justify-content-between">
                {/* eslint-disable-next-line no-nested-ternary */}
                {isEdit ? (
                    editName ? (
                        <div className="d-flex flex-wrap w-100">
                            <Input type="text"
                                maxLength="70"
                                className="w-50"
                                value={comparisonName}
                                onChange={(e) => setComparisonName(e.target.value)}
                            />
                            <FeatherIcon.CheckCircle className="ml-1 mt-1 cursor-pointer"
                                width="25"
                                height="25"
                                color="#006FF9"
                                onClick={() => setEditName(false)}
                            />
                        </div>
                    ) : (
                        <h2>
                            {comparisonName}
                            <span className="mt-11 ml-1 z-index">
                                <FeatherIcon.Edit color="#006FF9"
                                    width="18"
                                    className="cursor-pointer"
                                    onClick={() => setEditName(true)}
                                />
                            </span>
                        </h2>
                    )
                ) : (
                    <div className="d-flex flex-wrap w-100 align-items-center justify-content-between">
                        <div className="d-flex">
                            <h2>
                                Compare
                                <span className="mt-11 ml-1 z-index">
                                    <TooltipItem placement="top"
                                        tooltipOpen="narrativescompare"
                                        id="narrativescompare"
                                        content="See how each of your Narratives are performing against each other."
                                    />
                                </span>
                            </h2>
                        </div>
                        <div className="d-flex align-date-range ml-2 mt-1">
                            <p className="w-40">Date range</p>
                            <DateRangePicker setDateData={setDateData} />
                        </div>
                    </div>
                )}
            </div>
            <div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isEdit ? (
                    editName ? null : (
                        <p>
                            Comparison saved on: {`${moment.utc((comparisonMainData?.createdAt)).format('DD/MM/YYYY, @ hh:mm:ss')}`}.
                            <span className="float-right text-danger cursor-pointer"
                                onClick={() => setIsDelete(true)}
                            >
                                Delete comparison
                            </span>
                        </p>

                    )
                ) : (
                    <p>See how each of your Narratives are performing against each other.</p>
                )}
            </div>
            <div className="mt-4">
                <Tabs data={[
                    {
                        name: 'Timeline',
                        id: 'timeline',
                        content: <ComparisonTimeLine data={data}
                            isEdit={isEdit}
                            setCompareData={setCompareData}
                            compareData={compareData}
                            setVisibleSaveButton={setVisibleSaveButton}
                            comparisonName={comparisonName}
                            comparisonMainData={comparisonMainData}
                            setSaveModal={setSaveModal}
                            dateData={dateData}
                            visibleSaveButton={visibleSaveButton}
                        />,
                    },
                    {
                        name: 'Keywords',
                        id: 'Keywords',
                        content: <Entities entitiesData={compareData} />,
                    },
                    {
                        name: 'Location mentions',
                        id: 'location-mentions',
                        content: <LocationMentions locData={compareData} />,
                    }
                ]}
                />
            </div>
            <Modal isOpen={saveModal}>
                <ModalBody className="p-3">
                    <h2>Save Comparison</h2>
                    <hr />
                    <p>
                        Please enter name to the comparison. Your report will be saved to
                        monitoring under compared narratives.
                    </p>
                    <Label>Enter</Label>
                    <Input type="text"
                        maxLength="70"
                        name="save_comparison"
                        placeholder="Comparison report"
                        onChange={(e) =>
                        { setComparisonName(e.target.value);
                            setSameComparisonName(false); }}
                    />
                    {isComparisonNameEmpty ? <p className="text-danger pt-1">Please enter a name for your comparison.</p> : null}
                    {sameComparisonName ? (
                        <p className="text-danger pt-1">This comparison name already exists. Choose a different name.
                        </p>
                    ) : null}
                    <hr />

                    <Button onClick={() => {
                        setSaveModal(false);
                        setCancelModal(true);
                    }}
                    >
                        Cancel
                    </Button>
                    <Button className="ml-2"
                        color="primary"
                        onClick={() => onSaveClick()}
                    >
                        Save
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={savedSuccessModal}>
                <ModalBody className="p-3">
                    <img src={savedSuccess} alt="successfully saved" width="90" />
                    <h2 className="from">&apos;{comparisonName}&apos; saved successfully.</h2>
                    <hr />
                    <Button onClick={() => {
                        history.goBack();
                        setSavedSuccessModal(false);
                    }}
                    >
                        Close
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={cancelModal}>
                <ModalBody className="p-3">
                    <h2>Do you want to exit the compare screen without saving?</h2>
                    <hr />
                    <p>If you press exit, your comparison won&apos;t be saved.</p>
                    <hr />
                    <Button onClick={() => setCancelModal(false)}>Cancel</Button>
                    <Button className="ml-2"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Exit
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={isDelete}>
                <ModalBody className="p-3">
                    <h2 className="from">{`Are you sure you want to delete "${comparisonMainData?.name}" comparison?`}</h2>
                    <hr />
                    <p>It will be permanently deleted.</p>
                    <hr />
                    <Button onClick={() => setIsDelete(false)}>Cancel</Button>
                    <Button color="primary"
                        className="ml-2"
                        onClick={() => onDeleteComparisonClick()}
                    >
                        Delete
                    </Button>
                </ModalBody>
            </Modal>
            <Modal isOpen={deleteSuccessModal}>
                <ModalBody className="p-3">
                    <Deleted />
                    <h2 className="from">{`'${comparisonMainData?.name}' deleted successfully.`}</h2>
                    <hr />
                    <Button onClick={() => {
                        setDeleteSuccessModal(false);
                        history.goBack();
                    }}
                    >
                        Close
                    </Button>
                </ModalBody>
            </Modal>
        </div>
    );
};
