import React from 'react';
import { BooleanVersionContextMenu } from './BooleanVersionContextMenu';

type QueryProps = {
    booleanTitle: string,
    user: {displayName: string},
    updatedAt: Date,
    notes: string,
    projectId: string,
    situationRoom: string
}

type QueryVersionPanelProps = {
    query: QueryProps,
    selectVersion: (e: QueryProps) => void,
    selected: boolean,
    isCurrentQuery: boolean,
    latest: boolean,
    getBooleanVersions: () => void,
    goToPreviousVersion: () => void,
    goToNextVersion: () => void,
    selectedIndex: number
}

export const QueryVersionPanel = ({
    query,
    selectVersion,
    selected,
    isCurrentQuery,
    latest,
    getBooleanVersions,
    goToPreviousVersion,
    goToNextVersion,
    selectedIndex
}: QueryVersionPanelProps) => (
    <div className={`d-flex flex-column border rounded p-2 mb-2 ${selected && 'bg-light'}`}
        onClick={() => selectVersion(query)}
        data-testid={`${query.booleanTitle}-panel`}
    >
        {(!selected) ? (
            <div className="d-flex justify-content-between">
                <span>{query.booleanTitle}</span>
            </div>
        ) : (
            <div className="d-flex justify-content-between mb-n2">
                <div className="d-flex justify-content-between">
                    <span>{query.booleanTitle}</span>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <BooleanVersionContextMenu query={query}
                        getBooleanVersions={getBooleanVersions}
                        goToPreviousVersion={goToPreviousVersion}
                        goToNextVersion={goToNextVersion}
                        selectedIndex={selectedIndex}
                        isCurrentQuery={isCurrentQuery}
                    />
                </div>
            </div>
        )}
        <span>{query?.user?.displayName || 'N/A'}</span>
        <span>{new Date(query.updatedAt).toLocaleDateString()}</span>
        <span>{new Date(query.updatedAt).toLocaleTimeString()}</span>
        {query.notes && (
            <>
                <span className="font-weight-bold pt-1">Notes:</span>
                <span>{query.notes}</span>
            </>
        )}
        <div className="d-flex flex-row">
            {latest && <span className="small tag mt-1 mb-1" color="light">Latest</span>}
            {isCurrentQuery
             && <span className={`small tag ${!latest ? 'mt-1 mb-1' : 'm-1'}`} color="light">Applied to Room</span>}
        </div>
    </div>
);
