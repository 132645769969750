import { gql } from '@apollo/client';

export const CREATE_COMPARISON = gql`
  mutation createComparison($data: NarrativeComparisonInput!) {
    createComparison(data: $data) {
      done
      message
    }
  }
`;

export const DELETE_COMPARISON = gql`
  mutation deleteComparison($id: ID!) {
    deleteComparison(id: $id) {
      done
      message
    }
  }
`;

export const UPDATE_COMPARISON = gql`
  mutation updateComparison($data: NarrativeComparisonInput!, $id: ID!) {
    updateComparison(data: $data, id: $id) {
      done
      message
    }
  }
`;

export const GET_COMPARISON_NAME = gql`
  query getComparisons($situationRoom: ID!) {
    getComparisons(situationRoom: $situationRoom) {
      id
      name
    }
  }
`;
