import React, { useState, useEffect } from 'react';
import { Button, Table, CustomInput } from 'reactstrap';
import moment from 'moment';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { SortToggle } from '../../../../../components/Table/SortToggle';
import { truncateString } from '../../../../../utils/text';
import { SmallPagination } from '../../../../../components/Molecules';
import { StickToBottom } from '../../../../../components/StickToBottom';
import { LinkModal } from '../../../../../components/LinkModal';
import { SelectAllCheckBox } from '../../../../../components/Form/CheckBox';

export const ContentTable = ({ data, deleteContent, deleted, page, setPage, total, loading }) => {
    const [checkBoxValues, setCheckBoxValues] = useState([]);
    const { setModal, closeModal } = useModal();
    const [sort, setSort] = useState({ field: 'pubDate', order: 'dsc' });

    const sortEntities = (a, b) => {
        if (!sort.field) {
            return 0;
        }
        if (sort.field === 'pubDate') {
            if (sort.order === 'asc') {
                return new Date(a.date) - new Date(b.date);
            }
            return new Date(b.date) - new Date(a.date);
        }
    };

    const handleModal = () => {
        setModal({
            header: false,
            component:
    <><h2>Are you sure you want to delete selected content?</h2>
        <hr />
        <p>It will be permanently deleted.</p>
        <hr />
        <Button onClick={() => closeModal(false)}>Cancel</Button>
        <Button color="primary"
            className="ml-2"
            disabled={loading}
            onClick={() => deleteContent(checkBoxValues)}
        >Submit
        </Button>
    </>
        });
    };
    const ids = data.map((item) => item.id);

    useEffect(() => {
        if (deleted) {
            setCheckBoxValues([]);
        }
    }, [deleted]);

    return (
        <div className="position-relative">
            <Table className="mt-4 mb-0">
                <thead>
                    <tr>
                        <th>
                            <div className="d-flex align-items-center ml-2">
                                <SelectAllCheckBox id="narrativeContent1"
                                    displayLabel={false}
                                    options={ids}
                                    values={checkBoxValues}
                                    allCheck={(dataB) => setCheckBoxValues(dataB)}
                                />
                                <span className=" pl-2">
                                    {`Mentions (${page * 10 + 1}-${
                                        (page + 1) * 10 > data?.length ? data?.length : (page + 1) * 10
                                    } of ${total})`}
                                </span>
                            </div>
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} field="pubDate" name="Date published" />
                        </th>
                    </tr>
                </thead>
                <tbody data-testid="contenttable-list-items">
                    {[...data].sort(sortEntities).map((item) => {
                        const checked = checkBoxValues.indexOf(item?.id) === -1;
                        return (
                            <tr key={item.id}>
                                <td>
                                    <div className=" m-0 d-flex pl-2">
                                        <div className=" pr-0 d-flex">
                                            <CustomInput type="checkbox"
                                                checked={checkBoxValues.indexOf(item.id) !== -1}
                                                className="custom-control-input-big position-fix"
                                                id={`narrativeContent11-${item.id}`}
                                                onChange={() => {
                                                    if (!checked) {
                                                        setCheckBoxValues(
                                                            checkBoxValues.filter((a) => a !== item.id)
                                                        );
                                                    } else {
                                                        setCheckBoxValues([...checkBoxValues, item.id]);
                                                    }
                                                }}
                                            />
                                            <div className="pl-2">
                                                <div><strong>{item?.from}</strong></div>
                                                <div className="pt-1">
                                                    {truncateString(item?.body, 1000)}
                                                    <span>
                                                        <a href={item?.url}
                                                            target="_blank"
                                                            id={`narContent-${item?.id}`}
                                                            className="d-inline"
                                                            rel="noreferrer"
                                                            onClick={(e) => {
                                                                if (!localStorage.getItem('externalLinks')) {
                                                                    e.preventDefault();
                                                                    setModal({
                                                                        header: false,
                                                                        component: <LinkModal link={item?.url} />
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {item?.text.length > 400
                                                                ? 'View source'
                                                                : '...View source'}
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{moment(item?.date).format('DD MMM YYYY')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {total > 10 && (
                <div className="border-top bg-white p-2 d-flex justify-content-end">
                    <SmallPagination total={total}
                        itemsPerPage={10}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            )}
            {checkBoxValues.length > 0 ? (
                <StickToBottom>
                    <div className="bg-hover p-1 d-flex align-items-center justify-content-between">
                        <p className="m-0 font-weight-bold">
                            {checkBoxValues.length} item
                            {checkBoxValues.length !== 1 ? 's' : ''} selected
                        </p>
                        <Button color="danger" onClick={() => handleModal()}>Delete</Button>
                    </div>
                </StickToBottom>
            ) : (
                ''
            )}
        </div>
    );
};
