import React, { useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { getFoundText } from '../../../../utils/text/getFoundText';
import { usePreviewData } from '../../../SituationRooms/RoomEditor/RoomPreview/usePreviewData';
import { ContentTableTypes, ContentTables } from '../../../../components/ContentTable';
import { elasticFieldMapping } from './contentModal';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

export const ContentPreviewModal = ({ filter = {}, name = '', contentType = CONTENT_TYPE.MATCH, preview }) => {
    const [offset, setOffset] = useState(0);
    const foundText = getFoundText(filter.type, name);
    const { data: contents, count, loading } = usePreviewData({
        filters: {
            ...(filter.type ? {
                [elasticFieldMapping[filter.type] || filter.type]: filter.value
            } : {}),
            ...(filter.filter || {})
        },
        offset,
        limit: 10,
        sort: 'date',
        fetchPolicy: 'no-cache'
    });

    if (loading) return <Loading height={200} relative />;

    return (
        <div className="threats" data-testid="content-modal">
            <p className="mb-2">{count} matches {foundText}</p>
            <div className="mx-n3">
                <ContentTables contents={contents}
                    pagination={{ offset, setOffset, count }}
                    tableType={ContentTableTypes.PREVIEW}
                    contentType={contentType}
                    preview={preview}
                />
            </div>
        </div>
    );
};
