import React, { useState } from 'react';
import { Maximize2, RefreshCw } from 'react-feather';
import { Button } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { useURLSnapshot } from '../../services/Content/getUrlSnapshot';
import { truncateString } from '../../utils/text';
import { Loading } from '../Loading';
import { NoPreview } from './NoPreview';

type UrlSnapshotElem = {
    id: string;
    url: string;
    text: string
}

type UrlSnapshotProps = {
    data: UrlSnapshotElem
}

export const UrlSnapshot = ({ data: { id, url, text } }: UrlSnapshotProps) => {
    const [refresh, setRefresh] = useState(false);
    const { data, loading } = useURLSnapshot({ id, url, refresh });
    const { setModal, closeModal } = useModal();
    if (loading) {
        return <Loading relative height={200} />;
    }
    if (!data) {
        return <NoPreview />;
    }
    return (
        <div className="position-relative">
            <img src={data} alt="" className="w-100" data-testid="url-snapshot-image" />
            <div className="absolute-bottom-right p-11 d-flex">
                {!refresh && (
                    <Button className="square-icon-button p-0 mr-1"
                        color="primary"
                        onClick={() => setRefresh(true)}
                    >
                        <RefreshCw size={18} />
                    </Button>
                )}
                <Button className="square-icon-button p-0"
                    color="primary"
                    onClick={() => setModal({
                        size: 'xl',
                        component: (
                            <div>
                                <h3 className="m-0">{truncateString(text, 100)}</h3>
                                <hr />
                                <img src={data} alt="" className="w-100" data-testid="modal-url-snapshot-image" />
                                <hr />
                                <Button onClick={closeModal}>Close</Button>
                            </div>
                        )
                    })}
                >
                    <Maximize2 size={18} />
                </Button>
            </div>
        </div>
    );
};
