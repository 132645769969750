import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { getRoom } from '../../utils/variables';
import { RemoveContentSuccessModal } from './RemoveSuccessModal';
import { useModal } from '../../context/modal/ModalComponent';
import { RemoveContentActions, useRemoveContent } from './useRemoveContent';
// import { CONTENT_TYPE } from '../../utils/contentTypes';

type RemoveContentModalProps = {
    onCompleted?: () => void
    onError?: () => void
    type: string
    removeData: { id: string }[]
}

export const RemoveContentModal = ({ removeData, onCompleted, onError, type }: RemoveContentModalProps) => {
    const room = getRoom();
    const { closeModal } = useModal();
    const [successModal, setSuccessModal] = useState(false);
    const { removeContent, loading, error } = useRemoveContent({
        type,
        action: RemoveContentActions.RemoveContent,
        onCompleted: () => {
            setSuccessModal(true);
            if (onCompleted) onCompleted();
        },
        ids: removeData.map(item => item.id)
    });

    if (error && onError) {
        onError();
    }

    const handleRemoveContent = () => removeContent(removeData.map(item => ({ id: item.id })));

    return (
        <>
            <Modal isOpen toggle={closeModal}>
                <ModalBody>
                    <h2>Are you sure you want to remove it?</h2>
                    <hr />
                    <p>
                        {`This content will be fully removed from "${room?.project_name}" situation room. 
                        You and your team won’t be able to see it.`}
                    </p>
                    <hr />
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button color="danger" className="ml-2" onClick={handleRemoveContent} disabled={loading}>
                        Remove
                    </Button>
                </ModalBody>
            </Modal>
            <RemoveContentSuccessModal open={successModal} onClose={() => { setSuccessModal(false); closeModal(); }} />
        </>
    );
};
