import React from 'react';
import { X } from 'react-feather';
import {
    Row,
    Col,
    Button,
    Label,
    Input
} from 'reactstrap';
import { TooltipItem } from '../../../components/ToolTip';

export const Filters = ({ isOpen, setOpen }) => {
    if (!isOpen) { return null; }
    return (
        <div className="px-4 py-3 rounded border border-primary bg-white mb-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
                <h4>Settings </h4>
                <X role="button" onClick={() => setOpen(false)} />
            </div>
            <Row className="align-items-stretch">
                <Col xs={12} sm="auto" xl={3} className="mb-2">
                    <div className="bg-light rounded p-3 pt-2 h-100">
                        <p><strong>Clustering parameters</strong></p>
                        <Label className="mt-1">Number of clusters <TooltipItem placement="top"
                            id="clusterCountTooltip"
                            content="Maximum Number of clusters-> The stable number of clusters that will be automatically formed is
                             generally less than the chosen K at the end of the algorithm training. The default value is 10"
                        />
                        </Label>
                        <Input className="mt-1" type="text" name="clusterCount" id="clusterCount" />
                        <Label className="mt-2">Alpha <TooltipItem placement="top"
                            id="alphaTooltip"
                            content="Its values lie between 0 and 1.
                            The number of clusters will slightly increase with the increase in alpha.
                             The extra clusters being formed are small in size. Default value is 0.1."
                        />
                        </Label>
                        <Input className="mt-1" type="alpha" name="alpha" />
                        <Label className="mt-2">Beta <TooltipItem placement="top"
                            id="betaTooltip"
                            content="Its values lie between 0 and 1. It has a reverse effect.
                            The number of clusters will drop with an increase in beta. Default value is 0.1."
                        />
                        </Label>
                        <Input className="mt-1" type="beta" name="beta" />
                        <Label className="mt-2">Number of iterations <TooltipItem placement="top"
                            id="iterationsCountTooltip"
                            content="Increasing the number of iterations will reduce the number of clusters till a point.
                             Ideally, the algorithm stabilizes at 10 iterations.
                            "
                        />
                        </Label>
                        <Input className="mt-1" type="iterationsCount" name="iterationsCount" />
                    </div>
                </Col>
                <Col xs={12} sm="auto" lg={3} className="mb-2">
                    <div className="bg-light rounded p-3 pt-2 h-100">
                        <p><strong>Classifier parameters</strong></p>
                        <Label className="mt-1">Number of samples <TooltipItem placement="top"
                            id="samplesCountTooltip"
                            content=" It indicates the maximum number of documents required for training a classifier."
                        />
                        </Label>
                        <Input className="mt-1" type="text" name="sampleCount" id="sampleCount" />
                        <Label className="mt-2">Threshold <TooltipItem placement="top"
                            id="thresholdTooltip"
                            content="To filter for the selected samples for training a classifier based on
                            the confidence score assigned by a clustering algorithm. (Sample value of 0.8)"
                        />
                        </Label>
                        <Input className="mt-1" type="threshold" name="threshold" />
                    </div>
                </Col>
                <Col xs={12} sm="auto" lg={3} className="mb-2" />
                <Col xs={12} sm="auto" lg={3} className="mb-2" />
            </Row>
            <Button color="primary" className="mr-2">Apply</Button>
            <Button data-testid="pageSettingsCancelBtn" onClick={() => setOpen(false)}>Cancel</Button>
        </div>
    );
};
