import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getRoom } from '../../utils/variables';
import { extractKeywords } from '../../utils/extractKeywords';

export const useQueryExtracts = ({ url = '', text = '', textLowerLimit = 200, keywordsToHighlight = [] }) => {
    const filters = useSelector((state) => state.filters.filters);
    const searchHighlights = [
        ...extractKeywords(filters.query) || [],
        ...filters.booleanQueryKeywords
    ].filter(keyword => keyword);

    const room = getRoom();
    const { data } = useQuery(GET_QUERY_KEYWORDS, {
        variables: {
            projectId: room?.project_id
        },
        skip: !room?.project_id
    });
    const keywordsFromRoomQuery = keywordsToHighlight.length ? keywordsToHighlight : (data?.getQueryKeywords || []);

    let extracts = getExtracts({ text, keywordsToHighlight: keywordsFromRoomQuery, textLowerLimit });
    let highlightedURL = addHighlights({ text: url, keywordsToHighlight: keywordsFromRoomQuery }).highlightedText;
    const keywordsMentioned = [extracts, highlightedURL]?.join(' ').match(/<em>(.*?)<\/em>/g)?.map(word => word?.replace(/(<([^>]+)>)/gi, ''));

    if (searchHighlights.length) { // keeping this separate to maintain keywordsMentioned from boolean
        extracts = getExtracts({ text, keywordsToHighlight: searchHighlights, textLowerLimit });
        highlightedURL = addHighlights({ text: url, keywordsToHighlight: searchHighlights }).highlightedText;
    }

    return {
        extracts,
        highlightedURL,
        highlightedText: addHighlights({ text,
            keywordsToHighlight:
                searchHighlights.length ? searchHighlights : keywordsFromRoomQuery }).highlightedText,
        keywordsMentioned: keywordsFromRoomQuery.map(keyword => ({
            value: keyword,
            count: keywordsMentioned?.filter(mention => mention.toLowerCase() === keyword.toLowerCase()).length
        })).filter(mention => mention.count)
    };
};

const addHighlights = ({ text, keywordsToHighlight }) => {
    let returnValue = text;

    for (const word of keywordsToHighlight) {
        if (
            returnValue.toLowerCase().includes(word.replaceAll('*', '').toLowerCase())
            && !returnValue.toLowerCase().includes(`<em>${word.toLowerCase()}</em>`)
        ) {
            // Replace any empty space in any language with standard UTF-8 empty space
            // eslint-disable-next-line max-len
            const formattedWord = word.replace('[\u2E80-\u2E99\u2E9B-\u2EF3\u2F00-\u2FD5\u3005\u3007\u3021-\u3029\u3038-\u303B\u3400-\u4DB5\u4E00-\u9FEF\uF900-\uFA6D\uFA70-\uFAD9\\U00020000-\\U0002A6D6\\U0002A700-\\U0002B734\\U0002B740-\\U0002B81D\\U0002B820-\\U0002CEA1\\U0002CEB0-\\U0002EBE0\\U0002F800-\\U0002FA1D]', ' ');

            if (word.includes('*')) {
                const regex = getWildcardSelectionRegex(formattedWord);
                returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
                returnValue = returnValue.replaceAll(' </em>', '</em> ');
            // Latin "support"
            } else if (formattedWord.match(/[a-zA-Z]/, 'gi')) {
                const regex = new RegExp(`(?<![a-zA-Z])${formattedWord}+(?![a-zA-Z])`, 'ig');
                returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
            } else {
                const regex = new RegExp(`(?<![\\S])(${formattedWord})(?![\\S])`, 'ig');
                returnValue = returnValue.replace(regex, (match) => `<em>${match}</em>`);
            }
        }
    }
    return {
        highlightedText: returnValue.trim(),
        containsHighlight: returnValue.includes('<em>')
    };
};

const getWildcardSelectionRegex = (word) => {
    // Determine how many wildcards we have and their indexes
    let regex = '';
    const indices = [];
    for (let i = 0; i < word.length; i++) {
        if (word[i] === '*') indices.push(i);
    }

    const rightToLeft = word.match('[\u04c7-\u0591\u05D0-\u05EA\u05F0-\u05F4\u0600-\u06FF]', 'gi') !== null;

    // Wildcard around the word
    if (indices.length === 2 && indices[0] === 0 && indices[1] === word.length - 1) {
        regex = new RegExp(`[\\S]*(${word.replace('*', '')})[\\S]*`, 'ig');
    // Wildcard before the word
    } else if (indices[0] === 0) {
        regex = new RegExp(`([\\S])*(${word.replace('*', '')})(\\s|$)`, 'ig');
        if (rightToLeft) {
            regex = new RegExp(`(?<![\\S])(${word.replace('*', '')})[\\S]*`, 'ig');
        }
    // Wildcard after the word
    } else if (indices[0] === word.length - 1) {
        regex = new RegExp(`(?<![\\S])(${word.replace('*', '')})[\\S]*`, 'ig');
        if (rightToLeft) {
            regex = new RegExp(`([\\S])*(${word.replace('*', '')})(\\s|$)`, 'ig');
        }
    }

    return regex;
};

const getExtracts = ({ text, textLowerLimit, keywordsToHighlight }) => {
    const chunks = getChunks({ text, textLowerLimit });
    if (!keywordsToHighlight.length) return chunks;
    const chunksWithHighlights = chunks.map(chunk => addHighlights({ text: chunk, keywordsToHighlight }))
        .filter(chunk => chunk.containsHighlight)
        .map(chunk => chunk.highlightedText);
    return chunksWithHighlights;
};

const getChunks = ({ text, textLowerLimit }) => {
    if (text.length <= textLowerLimit) return [text];

    const sentenceSplittingRegex = /\b(\w\w\.|\w\. \w\.|\w\.\w\.)|([.?!])\s+(?=[A-Za-z])/g;
    const sentences = text.replace(
        sentenceSplittingRegex,
        (m, thingsToIgnore, thingsToSplit) => thingsToIgnore || `${thingsToSplit}\r`
    ).split('\r');

    const chunks = [''];
    for (const sentence of sentences) {
        const currentChunkIndex = chunks.length - 1;
        if (chunks[currentChunkIndex].length > textLowerLimit) {
            chunks.push(sentence.trim());
        } else {
            chunks[currentChunkIndex] = `${chunks[currentChunkIndex]} ${sentence}`.trim();
        }
    }
    return chunks;
};

export const GET_QUERY_KEYWORDS = gql`
    query getQueryKeywords($projectId: String!){
        getQueryKeywords(projectId: $projectId)
    }
`;
