import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { WatchlistNarratives } from './WatchlistNarratives';
import { WatchListThreats } from './WatchlistThreats';

export const WatchList = ({ data }) => {
    const breadCrumbs = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: data?.project_name, href: `/situation-rooms/${data?.id}` },
        { title: 'Watchlist', href: `/situation-rooms/${data?.id}/narratives` }
    ];
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div className="page-padding">
            <BreadCrumbs items={breadCrumbs} />
            <h2 className="font-weight-bold my-4">Watchlist</h2>
            <Row className="align-items-center">
                <Col xs={12}>
                    <div className="multi-tabs mb-4">
                        <div className={`mr-4 cursor-pointer ${selectedTab === 0 ? 'selected-tab ' : ''}`}
                            onClick={() => {
                                setSelectedTab(0);
                            }}
                        >

                            <h4>Threats</h4>
                        </div>
                        <div className={`mr-4 cursor-pointer ${selectedTab === 1 ? 'selected-tab ' : ''}`}
                            onClick={() => {
                                setSelectedTab(1);
                            }}
                        >
                            <h4>Narratives</h4>
                        </div>
                    </div>
                    {selectedTab === 0
                        ? <WatchListThreats /> : <WatchlistNarratives /> }

                </Col>
            </Row>
        </div>
    );
};
