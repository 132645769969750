import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ComparisonChart } from './comparisonChart';
import { GET_AGGS } from '../../../../graphql';
import { elasticFilterWithProjectId } from '../../../../utils/elasticFilterWithProjectId';

export const ComparisonDetails = ({
    data,
    colors,
    comparisonMainData,
    hideNarrativeId,
    dateRange,
}) => {
    const { data: narratives } = useQuery(GET_AGGS, {
        variables: {
            limit: 0,
            query: elasticFilterWithProjectId([{
                range: {
                    datetime_tz: {
                        gte: dateRange.startDate * 1000,
                        lte: dateRange.endDate * 1000
                    }
                }
            }]),
            aggs: data.map(nar => ({
                key: nar.id,
                value: {
                    filter: {
                        ids: {
                            values: nar.content_ids
                        }
                    },
                    aggs: {
                        key: 'trend',
                        value: {
                            auto_date_histogram: {
                                field: 'datetime_tz',
                                buckets: 10
                            },
                            aggs: {
                                key: 'engagement',
                                value: {
                                    sum: {
                                        field: 'engagement'
                                    }
                                }
                            }
                        }
                    }
                }
            }))
        }
    });
    const narrativesData = narratives?.contentServiceSearch?.aggregations
        ? Object.entries(narratives.contentServiceSearch.aggregations).map((item, index) => ({
            id: item[0],
            value: item[1],
            color: colors[index],
            visible: !(hideNarrativeId?.length > 0 && hideNarrativeId?.includes(item[0]))
        })) : [];
    const [type, setType] = useState('volume');

    return (
        <div>
            <h3 className="m-0">
                Narrative comparison in the observed period by {type === 'volume' ? 'volume' : 'engagement' }.
            </h3>
            <p className="mb-0 mt-3">
                <b>Volume:&nbsp;</b>Visualise how much content was generated in the date
                range.
            </p>
            <p className="mb-0">
                <b>Engagement:&nbsp;</b>Visualise how many people interacted with content in the
                date range.
            </p>
            <div className="d-flex mt-2">
                <input type="radio"
                    id="volume"
                    name="perf-timeline"
                    value="volume"
                    checked={type === 'volume'}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setType('volume');
                        }
                    }}
                />
                <span className="pt-1 text-primary">Volume</span>
                <input className="ml-2"
                    type="radio"
                    id="engagement"
                    name="perf-timeline"
                    value="engagement"
                    checked={type === 'engagement'}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setType('engagement');
                        }
                    }}
                />
                <span className="pt-1 text-primary">Engagement</span>
            </div>
            <ComparisonChart type={type}
                comparisonMainData={comparisonMainData}
                narrativesData={narrativesData}
            />
        </div>
    );
};
