import { Button, Form } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { TextareaWithCounter } from '../../../Form/TextareaWithCounter';
import { Alert } from '../../../Atoms';
import { useBooleanVersions } from '../../../../pages/SituationRooms/RoomEditor/useBooleanVersions';
// eslint-disable-next-line import-newlines/enforce
import { useNarrativeVersions } from '../../../../pages/Room/Narratives/Overview/CustomNarratives/CreateCustomNarrative/useNarrativeVersions';

type QueryProps = {
    booleanTitle?: string,
    notes?: string,

}

type BooleanVersionModalProps = {
    narrativeId?: string,
    onConfirm: (e: {title: string, notes: string}) => void,
    onCancel: () => void,
    modalTitle: string,
    query?: QueryProps,
    roomId?: string
}

export const BooleanVersionModal = ({ narrativeId, onConfirm, onCancel, modalTitle, query = {}, roomId }: BooleanVersionModalProps) => {
    const [title, setTitle] = useState(query.booleanTitle || '');
    const [notes, setNotes] = useState(query.notes || '');
    const [showAlert, setShowAlert] = useState(false);
    const [duplicateAlert, setDuplicateAlert] = useState(false);
    const [showDuplicateAlert, setShowDuplicateAlert] = useState(false);

    const { getBooleanVersions, loading: versionsLoading, error: errorBoolean, data: BooleanVersions } = useBooleanVersions(roomId);
    const { getNarrativeVersions, loading: narrativeVersionsLoading, error: errorNarratives,
        data: narrativeVersions } = useNarrativeVersions(narrativeId);
    const handleCancel = () => {
        onCancel();
    };
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!title) {
            setShowAlert(true);
        } else if (duplicateAlert) {
            setShowDuplicateAlert(true);
        } else {
            onConfirm({ title, notes });
        }
    };

    useEffect(() => {
        if (narrativeId) {
            getNarrativeVersions();
        } else {
            getBooleanVersions();
        }
    }, [getBooleanVersions, getNarrativeVersions, narrativeId]);

    const handleTitleChange = (e: string) => {
        setTitle(e);
        setShowAlert(false);
        setDuplicateAlert(false);
        setShowDuplicateAlert(false);
        if (narrativeId) {
            narrativeVersions?.getNarrativeVersions?.forEach((version: {narrativeTitle: string}) => {
                if (version.narrativeTitle === e) {
                    setDuplicateAlert(true);
                    setShowDuplicateAlert(true);
                }
            });
        } else {
            BooleanVersions?.getBooleanVersions?.forEach((version: QueryProps) => {
                if (version.booleanTitle === e) {
                    setDuplicateAlert(true);
                    setShowDuplicateAlert(true);
                }
            });
        }
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between">
                    <h2 className="mb-2 mt-0">{modalTitle}</h2>
                    <FeatherIcon.X color="#050E2B"
                        size={20}
                        data-testid="close-modal"
                        className="cursor-pointer mt-1"
                        stroke="#050E2B"
                        onClick={handleCancel}
                    />
                </div>
                <div>
                    {showAlert && <Alert errorMessage="You must provide a version name" closeAlert={() => setShowAlert(false)} />}
                    {showDuplicateAlert
                        && <Alert errorMessage="Your version name must be unique" closeAlert={() => setShowDuplicateAlert(false)} />}
                    <TextareaWithCounter className="mb-2"
                        ariaLabel="title"
                        label="Version name"
                        name="title"
                        value={title || ''}
                        rows="1"
                        onChange={handleTitleChange}
                        limit={25}
                        error={showAlert || showDuplicateAlert}
                    />
                    <TextareaWithCounter className="mb-2"
                        ariaLabel="notes"
                        label="Notes"
                        name="notes"
                        value={notes || ''}
                        rows="4"
                        onChange={setNotes}
                        limit={250}
                    />
                </div>
                <Button className="mr-2" color="secondary" onClick={handleCancel}>Cancel</Button>
                <Button type="submit"
                    color="primary"
                    disabled={showDuplicateAlert || versionsLoading || (!!errorBoolean)
                    || narrativeVersionsLoading || !!errorNarratives}
                >Confirm
                </Button>
            </Form>
        </div>
    );
};
