import { useEffect, useState } from 'react';
import { Chart } from 'regraph';
import { betweenness, degrees, pageRank, eigenCentrality } from 'regraph/analysis';
import { useDispatch, useSelector } from 'react-redux';
import { scaleLinear } from 'd3';
import { NetworksHistoryStore, updateNetworks } from '../../store';

const regraphSizingFunctions = {
    betweenness,
    degrees,
    pageRank,
    eigenCentrality
};

export const useRegraphSizing = ({ items }: { items: Chart.Props['items'] }): Chart.Props['items'] => {
    const { sizeNodesBy } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    const [scores, setScores] = useState({} as { [id: string]: number });
    const dispatch = useDispatch();

    useEffect(() => {
        const analyseItems = async () => {
            if (!sizeNodesBy || sizeNodesBy === 'engagement' || !items) {
                setScores({});
                return null;
            }
            const sizingFunction = regraphSizingFunctions[sizeNodesBy];
            const newScores = await sizingFunction(items);
            if (!newScores) {
                return null;
            }
            setScores(newScores);
            dispatch(updateNetworks({
                chartState: {
                    positions: {}
                }
            }));
        };
        analyseItems();
    }, [dispatch, items, sizeNodesBy]);

    if (!items) return {};

    const allScores = Object.values(scores);
    const scaleScores = scaleLinear().domain([Math.min(...allScores), Math.max(...allScores)]).range([1, 30]);

    return Object.fromEntries(Object.entries(items).map(([id, node]) => ([
        id,
        {
            ...node,
            ...(scores[id] !== undefined ? { size: scaleScores(scores[id]) } : {})
        }
    ])));
};
