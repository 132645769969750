import React, { useState } from 'react';
import { Tooltip, UncontrolledTooltipProps } from 'reactstrap';

type TextTooltipProps = {
    title: string
    text?: string
    id?: string,
    placement: UncontrolledTooltipProps['placement'],
    children: React.ReactNode | string
    className?: string
}

export const TextTooltip = ({ title, text, id = '', placement = 'bottom', children, className }: TextTooltipProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <span className={className}>
            {
                id ? title
                    : <p className="m-0" id={`tooltip-${title.replaceAll(' ', '-')}`}>{title}</p>

            }
            <Tooltip placement={placement}
                isOpen={tooltipOpen}
                target={id || `tooltip-${title.replaceAll(' ', '-')}`}
                toggle={toggle}
            >
                {children || text}
            </Tooltip>
        </span>
    );
};
