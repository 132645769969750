import React, { Dispatch, SetStateAction } from 'react';

import { X } from 'react-feather';
import { ItemProps } from './DropdownWIthMultiCheckbox';

type SelectedFilterProps = {
    filter: string,
    items: ItemProps[],
    setFilters: Dispatch<SetStateAction<ItemProps[]>>
};

export const SelectedFilter = ({ filter, items, setFilters }: SelectedFilterProps) => {
    const clearFilters = (itemId?: string, clearAll = false) => {
        setFilters(oldFilterData => {
            const newFilterData = oldFilterData.map(item => {
                if (clearAll) {
                    return { ...item, selected: false };
                }
                if (item.id === itemId) {
                    return { ...item, selected: false };
                }
                return item;
            });
            return newFilterData;
        });
    };

    return (
        <div key={filter} className="d-flex border rounded mb-1 pl-2 justify-content-between">
            <div className="d-flex py-1">
                <span className="d-flex align-items-center">{filter.charAt(0).toUpperCase() + filter.slice(1)}</span>
                <div className="d-flex flex-wrap pr-2">
                    {items.map((item) => (
                        <div key={item.id} className="tag d-flex align-items-center ml-1">
                            <span>{item.name}</span>
                            <div className="d-flex align-items-center pl-1 cursor-pointer"
                                data-testid={`x-icon-${item.id}`}
                                onClick={() => clearFilters(item.id)}
                            >
                                <X color="#006FF9" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="d-flex align-items-center p-2 border-left">
                <X color="#006FF9" className="cursor-pointer" onClick={() => clearFilters(undefined, true)} />
            </div>
        </div>
    ); };
