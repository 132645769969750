import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Loading } from '../../../../../../components/Loading';
import { CONTENT_ES_SERVICE, GET_CUSTOM_NARRATIVES } from '../../../../../../graphql';
import { processDateRange } from '../../../../../../utils/getTimeFrom';
import { CustomNarrativeInfo } from './CustomNarrativeInfo';
import { Order, sortOrder } from './OrderList';
import { ThreatsList } from './ThreatsList';
import { MultiSeriesLine } from '../../../../../../components/Highcharts/MultiSeriesLine';

const currentDate = new Date().getTime();
export const CustomNarrativePage = ({ project, projectId, bread }) => {
    const match = useRouteMatch();
    const { data, loading } = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: { situationRoom: project, id: match.params.narrative },
        fetchPolicy: 'no-cache'
    });
    const { data: threats = [], name = '' } = data ? data.getCustomNarratives[0] : false;

    const { data: parentData, loading: contentLoading, error: contentError } = useQuery(CONTENT_ES_SERVICE, {
        variables: {
            limit: 0,
            query: {
                bool: {
                    filter: [
                        {
                            term: {
                                project_id__keyword: {
                                    value: projectId,
                                },
                            },
                        },
                        {
                            range: {
                                datetime_tz: {
                                    gt: currentDate - (1000 * 60 * 60 * 24 * 30 * 12 * 2)
                                }
                            }
                        }
                    ],
                },
            },
            sort: 'datetime_tz__desc',
            aggs: [{
                key: name.replace(/\s/g, '_'),
                value: {
                    aggs: [
                        {
                            key: 'date',
                            value: {
                                date_histogram: { field: 'datetime_tz', calendar_interval: '1d' },
                                aggs: { key: 'engagement', value: { sum: { field: 'engagement' } } },
                            },
                        }
                    ],
                    filters: {
                        filters: Object.fromEntries(threats?.map((threat) => ([threat.threat.replace(/\s/g, '_'), checkFilters(threat)])))
                    },
                },
            }],
            opts: {
                trackTotalHits: true
            }
        },
        skip: !threats.length
    });
    const [state, setState] = useState({
        endDate: null,
        startDate: null,
        selection: null,
        direction: 'desc',
        yAxis: 'count',
        order: 'datetime_tz__'
    });
    const chartRef = useCallback((node) => {
        if (node !== null) {
            setState((current) => ({ ...current, series: node.chart.series }));
        }
    }, []);
    const customNarrative = data ? data.getCustomNarratives[0] : false;

    const changeOrder = (e) => {
        setState((current) => ({ ...current, order: e }));
    };
    const changeDirection = (e) => {
        setState((current) => ({ ...current, direction: e }));
    };
    const changeYAxis = (e) => {
        setState((current) => ({ ...current, yAxis: e }));
    };
    const changeDate = (e) => {
        const { startDate, endDate } = processDateRange({ rangeFilterOption: e.id });
        return setState((current) => ({ ...current, selection: null, startDate: startDate || null, endDate: endDate || null }));
    };

    if (loading || contentLoading) {
        return (
            <div className="m-5 p-5 d-flex align-items-center justify-content-center">
                <Loading gif height={400} relative />
            </div>
        );
    }
    const errorMessage = contentError
        ? JSON.parse(contentError.graphQLErrors[0]?.extensions?.exception?.response || '{}')
            ?.error?.failed_shards[0]?.reason?.reason?.split('[')[1]?.replace(']', '')
        : '';
    const badThreat = (threats && errorMessage) ? threats.find(a => a.boolean === errorMessage) : false;

    return (
        <div className="page-padding threat-page">
            <Row>
                <Col xs="12" lg="8" xl="9">
                    {bread}
                    <Row className="mb-4">
                        <Col>
                            {customNarrative && <h1 className="mt-0 mb-0">Custom Narrative: {customNarrative.name}</h1>}
                        </Col>
                        <Col xs="auto" />
                    </Row>
                    {contentError && (
                        <p>
                            {badThreat
                                ? (
                                    <span>
                                        There seems to be something wrong with the following threat: {badThreat.threat}
                                        <br />Please fix this and try again
                                    </span>
                                )
                                : 'error'}
                        </p>
                    )}
                    {parentData && (
                        <>
                            <div className="content-deck-date-picker">
                                <div className="content-deck-date-picker__preset">
                                    <div data-at="all"
                                        id="all"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        All
                                    </div>
                                    <div id="month"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        30 Days
                                    </div>
                                    <div id="fortnight"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        14 Days
                                    </div>
                                    <div id="week"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        7 Days
                                    </div>
                                    <div id="day"
                                        onClick={(e) => changeDate(e.target)}
                                        className="content-deck-date-picker_chip"
                                    >
                                        24 Hours
                                    </div>
                                </div>
                            </div>
                            <div className="content-deck-controls">
                                <select id="yaxis"
                                    name="yaxis"
                                    value={state.yAxis}
                                    onChange={(e) => changeYAxis(e.target.value)}
                                >
                                    <option key="engagement" value="engagement">
                                        Engagement
                                    </option>
                                    <option key="count" value="count">
                                        Count
                                    </option>
                                </select>
                            </div>

                            <MultiSeriesLine data={parentData.contentServiceSearch.aggregations[name.replace(/\s/g, '_')]}
                                type={state.yAxis}
                                setState={setState}
                                chartRef={chartRef}
                                startDate={state.startDate}
                                endDate={state.endDate}
                            />

                            <div className="content-deck-controls">
                                <select id="order"
                                    data-testid="order-sorting"
                                    name="order"
                                    value={state.order}
                                    onChange={(e) => changeOrder(e.target.value)}
                                >
                                    {sortOrder.map((ord) => (
                                        <option key={ord} value={ord}>
                                            {Order[ord]}
                                        </option>
                                    ))}
                                </select>
                                <select id="direction"
                                    name="direction"
                                    value={state.direction}
                                    onChange={(e) => changeDirection(e.target.value)}
                                >
                                    <option value="asc">asc</option>
                                    <option value="desc">desc</option>
                                </select>
                            </div>
                            <div className="content-deck-wrapper">
                                {parentData
                                    && threats.map((threat, index) => (
                                        <ThreatsList key={threat.threat}
                                            threat={threat}
                                            index={index}
                                            state={state}
                                            projectId={projectId}
                                        />
                                    ))}
                            </div>
                        </>
                    )}
                </Col>
                <Col xs="12" lg="4" xl="3" className="right-side-bar">
                    {customNarrative && <CustomNarrativeInfo customNarrative={customNarrative} />}
                </Col>
            </Row>
        </div>
    );
};

export const makeQuery = (threat) => {
    if (threat.boolean) {
        return threat.boolean.replace(/NEAR\/[0-9]/g, 'AND').replace(/[{}]/g, '');
    }
    if (threat.nearQuery) {
        return threat.nearQuery.replace(/NEAR\/[0-9]/g, 'AND').replace(/[{}]/g, '');
    }
    return threat.keywords.join(' OR ');
};

export const checkFilters = (threat, EsQuery, startDate, endDate, projectId, selection) => {
    if (threat?.boolean && threat?.query_type === 'query') {
        return threat.dslQuery;
    }
    if (!(threat?.query_type === 'query') && EsQuery) {
        return {
            bool: {
                filter: [
                    {
                        term: {
                            project_id__keyword: {
                                value: projectId,
                            },
                        },
                    },
                    {
                        query_string: {
                            query: threat?.keywords.join(' OR '),
                            fields: ['text'],
                            lenient: true,
                        },
                    },
                    {
                        range: {
                            date: {
                                gte: (selection !== null || startDate !== null) ? new Date(selection || startDate * 1000) : null,
                                lte: (selection !== null || endDate !== null) ? new Date(selection || endDate * 1000) : null,
                            },
                        },
                    }
                ],
            },
        };
    }
    return {
        bool: {
            filter: [
                {
                    query_string: {
                        query: threat?.keywords.join(' OR '),
                        fields: ['text'],
                        lenient: true,
                    },
                }
            ],
        }
    };
};
