import { isEqual } from 'lodash';
import React, { useState, useMemo, useContext, createContext } from 'react';
import { standardTemplate } from '../../pages/Room/Overview/Templates/Standard';
import { removeTypename } from '../../utils/removeAllTypename';

export const OverviewContext = createContext({});

export const useOverview = () => {
    const context = useContext(OverviewContext);
    if (context === undefined) {
        throw new Error('useOverview must be used within a OverviewContextProvider');
    }
    return context;
};

export const OverviewContextProvider = ({ children, defaultLayout, canEdit }) => {
    const [edit, setEdit] = useState(false);
    const [layoutBackup, setLayoutBackup] = useState(removeTypename(defaultLayout || standardTemplate));
    const [layout, setLayout] = useState(removeTypename(defaultLayout || standardTemplate));
    const context = useMemo(() => ({
        edit,
        setEdit,
        layout,
        setLayout,
        hasChanges: !isEqual(layoutBackup, layout),
        handleSave: () => {
            setLayoutBackup(layout);
            setEdit(false);
        },
        handleCancel: () => {
            setLayout(layoutBackup);
            setEdit(false);
        },
        canEdit
    }), [edit, layout, layoutBackup, canEdit]);

    return (
        <OverviewContext.Provider value={context}>
            { children }
        </OverviewContext.Provider>
    );
};
