import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import removeTeam from '../../../assets/images/removeTeam.svg';
import { DELETE_USER, GET_TEAM } from '../../../graphql';

export const RemoveUserModal = ({ user, closeRemoveModal }) => {
    const history = useHistory();
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [deleteUser, { loading: deleteUserLoading }] = useMutation(DELETE_USER, {
        refetchQueries: [GET_TEAM],
        onCompleted: () => {
            setRemoveSuccess(true);
        }
    });
    const handleDelete = () => {
        deleteUser({
            variables: {
                uid: user.uid,
                id: user.id,
                instance: user.instance.id
            }
        });
    };
    return (
        <div>
            <img src={removeTeam} alt="remove-user" />
            {removeSuccess ? (
                <div>
                    <h2>Removed &apos;{user.displayName || user.email}&apos; successfully</h2>
                    <hr />
                    <Button onClick={() => { closeRemoveModal(); history.push('/team'); }}>
                        Cancel
                    </Button>
                </div>
            ) : (
                <>
                    <h2>
                        Are you sure want to remove &apos;
                        {user.displayName || user.email}&apos; from the team?
                    </h2>
                    <hr />
                    <p>
                        You will have to re-invite the user if you want to add back
                    </p>
                    <hr />
                    <div>
                        <Button className="mr-2"
                            onClick={() => closeRemoveModal()}
                        >
                            Cancel
                        </Button>
                        <Button color="danger"
                            disabled={deleteUserLoading}
                            onClick={() => handleDelete()}
                        >
                            Remove
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
