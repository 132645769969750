type CreateLinkProps = {
    id1: string
    id2: string
    label?: string
}

export const createLink = ({ id1, id2, label }: CreateLinkProps) => ({
    id1,
    id2,
    label: {
        text: label,
        backgroundColor: 'transparent',
        color: '#B0B4C5'
    },
    color: '#B0B4C5',
    lineStyle: 'dashed',
    end2: {
        arrow: true
    }
});
