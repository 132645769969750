import React, { Dispatch, SetStateAction } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getRoom } from '../../utils/variables';
import { ItemProps } from './components/DropdownWIthMultiCheckbox/index';
import { DropdownWithSearchAndCheckboxes } from './components/DropdownWithSearchAndCheckboxes';

type KeywordsFilterProps = {
    keywords: ItemProps[],
    setKeywords: Dispatch<SetStateAction<ItemProps[]>>
};

export const KeywordsFilter = ({ keywords, setKeywords }: KeywordsFilterProps) => {
    const room = getRoom();
    const globalFilters = useSelector((state: any) => state.filters.filters);
    const { data } = useQuery(TOP_KEYWORDS, {
        variables: {
            projectId: room?.project_id,
            size: 20
        },
        onCompleted: () => {
            const keywordsData = data.topKeywords.map((keyword: string) => ({
                id: keyword,
                name: keyword,
                selected: globalFilters?.keywords?.includes(keyword)
            }));
            setKeywords(keywordsData);
        }
    });

    const handleSelectItem = (id: string) => {
        setKeywords((oldKeywords: ItemProps[]) => {
            const selectedKeyword = oldKeywords.findIndex(keyword => keyword.id === id);
            const newKeywords = [...oldKeywords];
            newKeywords[selectedKeyword].selected = !newKeywords[selectedKeyword].selected;
            return newKeywords;
        });
    };
    const countFilters = (keywords.filter((a) => a.selected === true)).length;

    return (
        <div>
            <DropdownWithSearchAndCheckboxes handleSelect={handleSelectItem}
                items={keywords}
                placeholder="Search Keywords"
                description="Top 20 Keywords"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
            />
        </div>
    );
};

export const TOP_KEYWORDS = gql`
    query topKeywords(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topKeywords(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        )
    }
`;
