import { useQuery, gql } from '@apollo/client';

export const useURLSnapshot = (variables) => {
    const { data, loading } = useQuery(GET_URL_SNAPSHOT, {
        variables
    });

    return {
        data: data?.urlSnapshot,
        loading
    };
};

export const GET_URL_SNAPSHOT = gql`
    query urlSnapshot($url: String!, $id: String!, $refresh: Boolean){
        urlSnapshot(url: $url, id: $id, refresh: $refresh)
    }
`;
