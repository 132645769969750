import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TranslateIcon } from '../../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../../graphql';
import { getContentLink } from '../../../../../../utils/getContentLink';
import { getCtype } from '../../../../../../utils/text';

export const ThreatItem = ({ threat }) => {
    const content = threat?._source;
    const [isOpen, setOpen] = useState(false);
    const [getTranslation, { data }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: content.text, target: 'en' }
        },
    });
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setOpen(!isOpen);
    };
    const outputText = data ? data?.translateMultipleText[0]?.translations[0] : content.text;
    const { icon, FontFamily, name, nodeColor, iconColor } = getCtype(content.ctype);
    const contentLink = getContentLink({
        id: threat._id,
        threatLevel: content.threat_object?.threat_level
    });
    const likesCount = Number.isNaN(Number(content.likes_count)) ? -1 : Number(content.likes_count);
    const sharesCount = Number.isNaN(Number(content.shares_count)) ? -1 : Number(content.shares_count);
    const engagementCount = Number.isNaN(Number(content.engagement)) ? -1 : Number(content.engagement);

    return (
        <Link to={contentLink}>
            <div className="post-wrap">
                <div className="post-wrap-heading">
                    <div>Engagement:&nbsp;{engagementCount < 0 ? 'NA' : engagementCount}</div>
                    <div>Likes:&nbsp;{likesCount < 0 ? 'NA' : likesCount}</div>
                    <div>Shares:&nbsp;{sharesCount < 0 ? 'NA' : sharesCount}</div>
                </div>
                {(content.threat_object ? content.threat_object.threat_level : content.threat_level) === 'high' && (
                    <div className="post-wrap-threat">
                        <div style={{ color: '#fff' }}>{content.threat_object?.threat_labels[0]}</div>
                    </div>
                )}
                <div className="post-wrap-body">
                    <div className="d-flex justify-content-between">
                        <div className="post-wrap-author">
                            <div className="post-wrap-author-name">{content.display_name}</div>
                            <div className="post-wrap-author-handle">{content.from}</div>
                        </div>
                        {!['en', 'un'].includes(content.lang) && (
                            <span role="button" className="ml-auto" onClick={(e) => translation(e)}>
                                <TranslateIcon color="#006FF9" size={30} />
                            </span>
                        )}
                    </div>
                    {content.image && content.image !== 'undefined' && content.image !== 'unknown' && (
                        <div className="post-wrap-media">
                            <img src={content.image}
                                alt=""
                            />
                        </div>
                    )}

                    <div style={{ color: '#3f3f3f', lineHeight: '1.5rem', wordBreak: 'break-word' }}>
                        {isOpen ? outputText : content.text}
                    </div>
                    <div className="post-wrap-footer">
                        <div className="post-wrap-icon rounded-circle d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: nodeColor, minHeight: 30, minWidth: 30 }}
                        >
                            <i className={`font-size-14 fa ${icon}`}
                                style={
                                    FontFamily ? { fontFamily: `"${FontFamily}"`, color: iconColor } : { color: iconColor }
                                }
                            />
                        </div>
                        <span>
                            <span className="font-weight-semibold">{name}</span>
                            {' - '}
                            {content.date}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};
