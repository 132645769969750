/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable react/no-this-in-sfc */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts, { Options } from 'highcharts/highstock';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import { scaleLinear } from 'd3';
import { Minus, Plus, RefreshCcw } from 'react-feather';
import { saveSelectedCluster } from '../../store';
import { RootState } from '../../../../store';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { getSentimentColor } from '../../../../utils/getSentimentColor';

highchartsMore(Highcharts);

const scaleZoom = scaleLinear().domain([50, 65, 80, 95]).range([3.6, 2.8, 2.3, 2]);
const scaleOffset = scaleLinear().domain([50, 63, 75]).range([0.105, 0.13, 0.15]);

type DataCustomValues = {
    duplicates: number
    topActor: string
    sentiment: string
}
type DataType = {
    id: string
    name: string
    value: number
    topActor: string
    sentiment: string
}
type ClusterType = {
    clusterId: string
    name: string
    data: [DataType]
    custom: DataCustomValues
}
type SeriesPlotOptions = {
    height: string
    minSize: string
    enableSimulation: boolean
    tooltipDisabled: boolean
}
type BubblePlotProps = {
    seriesData: [ClusterType];
    plotOptions?: SeriesPlotOptions;
}

const defaultPlotOptions = {
    height: '50%',
    tooltipDisabled: true,
    minSize: '30%',
    enableSimulation: false
};

export const BubblePlot = ({ seriesData, plotOptions = defaultPlotOptions }: BubblePlotProps) => {
    let prevPoint: any;
    const { isParentCluster } = useSelector((state: RootState) => state.selectedCluster);
    const dispatch = useDispatch();
    const chartRef = useRef(null);
    const zoomRef = useRef(null);
    const options = {
        chart: {
            type: 'packedbubble',
            height: plotOptions.height,
            backgroundColor: '#E6F1FE',
            zoomType: 'y',
            margin: 0
        },
        title: {
            text: ''
        },
        tooltip: {
            enabled: plotOptions.tooltipDisabled,
            useHTML: true,
            shared: true,
            formatter() {
                const { point } = this;
                if (!point) return '';
                const sentiment = (point as any).sentiment;
                const isParentNode = (point as any).isParentNode;
                if (isParentNode) {
                    return `
                        <div style="width: 270px; font-size: 14px; font-family:Nunito" class="p-1 bg-white">
                            <p>${point.series.name}</p>
                            <hr />
                            <p>Contents: ${point.series.data.reduce((val, next) => val + (next.value || 0), 0)}</p>
                            <p>Subclusters: ${point.x}</p>
                            <p>Duplicates: ${point.series.userOptions.custom?.duplicates}</p>
                            <hr />
                            <p class="m-0">${point.series.userOptions.custom?.topActor} (Top actor)</p>
                            ${capitalizeFirstLetter(point.series.userOptions.custom?.sentiment)} sentiment
                        </div>
                    `;
                }
                return `
                    <div style="width: 270px; font-size: 14px; font-family:Nunito" class="p-1">
                        <p>${point.name}</p>
                        <hr />
                        <p>Contents: ${point.value}</p>
                        <hr />
                        ${capitalizeFirstLetter(sentiment)} sentiment
                    </div>
                `;
            },
            outside: true,
            className: 'ai-clustering-tooltip',
            positioner: (x, y, { plotX, plotY }) => ({
                x: plotX,
                y: plotY
            }),
            shape: 'square'
        },
        plotOptions: {
            packedbubble: {
                allowPointSelect: true,
                parentNode: {
                    allowPointSelect: true
                },
                minSize: plotOptions.minSize,
                maxSize: '100%',
                zMin: 0,
                zMax: 1000,
                draggable: false,
                layoutAlgorithm: {
                    gravitationalConstant: 0.05,
                    splitSeries: 'true',
                    seriesInteraction: false,
                    dragBetweenSeries: true,
                    parentNodeLimit: true,
                    enableSimulation: plotOptions.enableSimulation
                },
                point: {
                    events: {
                        select() {
                            if (prevPoint) prevPoint.select(false);
                            prevPoint = this;
                        }
                    }
                },
                states: {
                    select: {
                        halo: {
                            size: 0
                        }
                    },
                    hover: {
                        halo: {
                            size: 0
                        }
                    }
                },
                events: {
                    click: ({ point }: any) => {
                        if (zoomRef.current && chartRef.current) {
                            const { zoomToElement, instance } = (zoomRef.current as ReactZoomPanPinchContentRef);
                            const chart = (chartRef.current as HighchartsReactRefObject).chart;
                            chart.tooltip.hide();

                            if (instance.wrapperComponent) {
                                const pointElement = point.graphic.element;
                                const zoomTarget = pointElement.cloneNode(true);
                                pointElement.after(zoomTarget);
                                const { width } = instance.wrapperComponent.getBoundingClientRect();
                                zoomTarget.style.transform = `translate(-${width * scaleOffset(point.radius)}px)`;
                                zoomTarget.style.opacity = '0';
                                setTimeout(() => {
                                    zoomToElement(zoomTarget, scaleZoom(point.radius));
                                }, 100);

                                setTimeout(() => {
                                    zoomTarget.remove();
                                    if (!point.isParentNode) {
                                        dispatch(saveSelectedCluster({
                                            selectedCluster: point.id,
                                            isParentCluster: false
                                        }));
                                    } else {
                                        dispatch(saveSelectedCluster({
                                            selectedCluster: point.series.userOptions.clusterId,
                                            isParentCluster: true
                                        })); }
                                    chart.tooltip.hide();
                                }, 600);
                            }
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.value}'
                }
            }
        },
        series: seriesData.map(series => ({
            ...series,
            type: 'packedbubble',
            color: getSentimentColor(series.custom.sentiment),
            data: series.data.map(subCluster => ({
                ...subCluster,
                color: getSentimentColor(subCluster.sentiment)
            }))
        })),
        legend: {
            enabled: false
        }
    } as Options;

    return (
        <div className="h-100">
            <TransformWrapper ref={zoomRef} initialPositionX={-((window.innerWidth / 2) + 200)}>
                {({ zoomIn, zoomOut, resetTransform }: any) => (
                    <div className="h-100">
                        <TransformComponent contentClass="w-200vw" wrapperClass="w-100 h-100">
                            <div className="w-100vw h-100" style={{ transform: 'translate(-50%, 0)', marginLeft: '50%' }}>
                                <HighchartsReact highcharts={Highcharts}
                                    options={options}
                                    ref={chartRef}
                                    containerProps={{ className: 'highcharts-clusters' }}
                                />
                            </div>
                        </TransformComponent>
                        { !isParentCluster ? (
                            <div className="d-none">
                                <Plus className="button-props" onClick={() => zoomIn()} />
                                <Minus className="button-props" onClick={() => zoomOut()} />
                                <RefreshCcw className="button-props" onClick={() => resetTransform()} />
                            </div>
                        ) : null}
                    </div>
                )}
            </TransformWrapper>
        </div>
    );
};
