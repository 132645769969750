import { gql, useQuery } from '@apollo/client';
import { formatLabel } from '../../pages/Room/Overview/Visualisation/utils';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useSources = ({ dateFilter, size = 10, includeThreats } = {}) => {
    const filters = useContentFilters({
        useGlobalFilters: true,
        defaultFilters: {
            date: dateFilter
        }
    });
    const ret = useQuery(getTopSourcesQuery({ includeThreats }), {
        variables: {
            ...filters,
            size
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.topSources?.data?.map(item => ({
            ...item,
            key: item.source,
            source: formatLabel(item.source, 'ctype__keyword')
        })) || [] : [],
        total: ret.data?.topSources?.total || 0
    };
};

export const getTopSourcesQuery = ({ includeThreats = false } = {}) => gql`
    query topSources(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topSources(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        ) {
            data {
                source
                count
                ${includeThreats ? 'threats' : ''}
            }
            total
        }
    }
`;
