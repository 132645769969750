import React, { useState } from 'react';
import { Col, Row, Table } from 'reactstrap';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { AllTypes, formatThreatLabel } from '../../../../utils/text';

export const WhyIsThisAThreat = ({ data }) => {
    const [sort, setSort] = useState({
        field: 'typeName',
        order: 'asc'
    });
    const resolvers = data.threatObject?.threatResolvers?.map(item => ({
        ...item,
        resolverName: AllTypes[item.resolver]?.name || 'NA',
        typeName: AllTypes[item.type]?.name
    }));
    return (
        <div className="bg-white p-3 mt-4">
            <Row>
                <Col>
                    <h3 className="my-0">Why is this a threat?</h3>
                    <p className="my-4">
                        LI analysis has identified this content as a threat based on these insights
                    </p>
                    <Row className="mb-3 mx-n1">
                        {
                            data.threatObject ? (
                                data.threatObject?.threatLabels.map((label) => {
                                    if (!label) { return null; }
                                    return (
                                        <Col key={label} xs="auto" className="px-1">
                                            <div color="dark" className="tag">
                                                {formatThreatLabel(label).name}
                                            </div>
                                        </Col>
                                    );
                                })
                            ) : (
                                data.threatObject?.whyathreat !== 'not-applicable' && (
                                    <Col xs="auto" className="px-1">
                                        <div color="dark" className="tag">
                                            {formatThreatLabel(data.threatObject?.whyathreat)?.name}
                                        </div>
                                    </Col>
                                )
                            )
                        }
                    </Row>

                    <Row className="threat-resolvers pr-3 pl-2">
                        <Table className="border-bottom">
                            <thead>
                                <tr>
                                    <th>
                                        <SortToggle sort={sort}
                                            setSort={setSort}
                                            name="Threat type"
                                            field="typeName"
                                        />
                                    </th>
                                    <th>
                                        <SortToggle sort={sort}
                                            setSort={setSort}
                                            name="Sub category"
                                            field="resolverName"
                                        />
                                    </th>
                                    <th>%</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lodashSort(resolvers, sort).map((item) => (
                                    <tr key={item.typeName + item.resolverName}>
                                        <td>{item.typeName}</td>
                                        <td>
                                            {item.resolverName}
                                        </td>
                                        {(item.type !== 'ITH' && item.type !== 'CTA') && (
                                            <td>
                                                {item.confidence}%
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
