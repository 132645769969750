import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { ChevronDown, ChevronLeft, ChevronRight, X } from 'react-feather';
import Editor, { DiffEditor } from '@monaco-editor/react';
import { QueryVersionPanel } from './QueryVersionPanel';
import { editorOptions } from '../../Molecules/BooleanInputValidated/editorConfig';
import { useDropDown } from '../../../utils/useDropdown';

type SelectedVersionProps = {
    boolean?: string,
    projectId: string,
    booleanTitle: string,
    user: {displayName: string},
    updatedAt: Date,
    notes: string,
    situationRoom: string
}

type QueryVersionSelectorProps = {
    currentQuery: {projectId: string},
    queryVersions: SelectedVersionProps[],
    selectedVersion: SelectedVersionProps,
    setSelectedVersion: (e: SelectedVersionProps) => void,
    getBooleanVersions: () => void,
    page: number,
    itemsPerPage: number,
    setPage: (e: number) => void
}

export const QueryVersionSelector = ({
    currentQuery,
    queryVersions,
    selectedVersion,
    setSelectedVersion,
    getBooleanVersions,
    page,
    itemsPerPage,
    setPage
}: QueryVersionSelectorProps) => {
    const [selectedIndex, setSelectedIndex] = useState(queryVersions.findIndex(version => version.projectId === selectedVersion.projectId));
    const [compareVersion, setCompareVersion] = useState({ booleanTitle: '', boolean: '' } as SelectedVersionProps);
    const [compareVersionIndex, setCompareVersionIndex] = useState(0);
    const [dropdown, toggleDropdown] = useDropDown();

    const selectVersion = (query: SelectedVersionProps) => {
        setSelectedVersion(query);
        setSelectedIndex(queryVersions.findIndex(version => version.projectId === query.projectId));
    };

    const goToNextVersion = () => {
        const nextIndex = selectedIndex + 1;
        setSelectedVersion(queryVersions[nextIndex]);
        setSelectedIndex(nextIndex);
        if (nextIndex >= page * itemsPerPage + itemsPerPage) {
            setPage(page + 1);
        }
    };

    const goToPreviousVersion = () => {
        const previousIndex = selectedIndex - 1;
        setSelectedVersion(queryVersions[previousIndex]);
        setSelectedIndex(previousIndex);
        if (previousIndex < page * itemsPerPage) {
            setPage(page - 1);
        }
    };

    useEffect(() => {
        const currentVersion = queryVersions.findIndex(version => version.projectId === selectedVersion.projectId);
        setSelectedIndex(currentVersion);
        setSelectedVersion(queryVersions[currentVersion]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryVersions]);

    const versionsToDisplay = queryVersions.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

    return (
        <Row color="primary" className="bg-white border h-100 m-0" data-testid="version-selector">
            <Col className="border-right p-4 h-100" lg={4}>
                <h3 className="font-weight-bold m-0 pb-2">Versions</h3>
                <div className="overflow-auto h-100 pb-2">
                    {versionsToDisplay?.map(query => (
                        <QueryVersionPanel key={query.projectId || uuid()}
                            query={query}
                            selectVersion={selectVersion}
                            selected={query.projectId === selectedVersion.projectId}
                            latest={query.projectId === queryVersions[0].projectId}
                            isCurrentQuery={query.projectId === currentQuery.projectId}
                            getBooleanVersions={getBooleanVersions}
                            goToPreviousVersion={goToPreviousVersion}
                            goToNextVersion={goToNextVersion}
                            selectedIndex={selectedIndex}
                        />
                    ))}
                </div>
            </Col>
            <Col className="p-4 h-100" lg={8} data-testid="version-viewer">
                <div className="d-flex flex-row justify-content-between align-items-center pb-2">
                    <h3 className="font-weight-bold m-0">View Boolean</h3>
                    {queryVersions.length > 1 && (
                        <Dropdown isOpen={dropdown} toggle={toggleDropdown}>
                            <DropdownToggle tag="div"
                                data-testid="dropdown-toggle"
                                className="form-control d-flex align-items-center cursor-pointer compare-version-dropdown"
                            >
                                <>
                                    <span className="pr-1">{compareVersion?.booleanTitle || 'Compare with'}</span>
                                    <span className="float-right ml-auto">
                                        <ChevronDown data-testid="chevron-down" color="#006FF9" size={20} />
                                    </span>
                                </>
                            </DropdownToggle>
                            <DropdownMenu left={compareVersion?.toString()}
                                className="maxh-300 scrollbar-small overflow-y-auto overflow-x-hidden"
                            >
                                <DropdownItem data-testid="none-dropdown-item"
                                    onClick={() => {
                                        setCompareVersion({ booleanTitle: '', boolean: '' } as SelectedVersionProps);
                                        setCompareVersionIndex(0);
                                    }}
                                >
                                    None
                                </DropdownItem>
                                {queryVersions?.map(version => (
                                    <DropdownItem data-testid={`${version?.booleanTitle}-dropdown-item`}
                                        onClick={() => {
                                            setCompareVersion(version);
                                            setCompareVersionIndex(
                                                queryVersions.findIndex(versions => versions.projectId === version.projectId)
                                            );
                                        }}
                                        key={version?.booleanTitle}
                                        className="d-flex align-items-center"
                                    >
                                        {version?.booleanTitle}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </div>
                <div className="border rounded query-version-container">
                    <div className="d-flex justify-content-between align-items-center version-header border-bottom border-gray">
                        <div className="d-flex justify-content-between align-items-center flex-1 p-2">
                            <span className="ml-1 font-weight-bold" data-testid="boolean-title">{selectedVersion.booleanTitle}</span>
                            <div>
                                <button type="button"
                                    className="mx-1 p-0 border-0 bg-transparent"
                                    onClick={() => goToPreviousVersion()}
                                    disabled={selectedIndex === 0}
                                    data-testid="previous-button"
                                >
                                    <ChevronLeft size={20} />
                                </button>
                                <button className="mx-1 p-0 border-0 bg-transparent"
                                    type="button"
                                    onClick={() => goToNextVersion()}
                                    disabled={typeof queryVersions[selectedIndex + 1] === 'undefined'}
                                    data-testid="next-button"
                                >
                                    <ChevronRight size={20} />
                                </button>
                            </div>
                        </div>
                        {compareVersion && (
                            <div className="d-flex justify-content-between align-items-center flex-1 border-left border-gray p-2">
                                <span className="ml-1 font-weight-bold">{compareVersion.booleanTitle}</span>
                                <div>
                                    <button type="button"
                                        className="mx-1 p-0 border-0 bg-transparent"
                                        onClick={() => {
                                            setCompareVersion(queryVersions[compareVersionIndex - 1]);
                                            setCompareVersionIndex(compareVersionIndex - 1);
                                        }}
                                        disabled={compareVersionIndex === 0}
                                    >
                                        <ChevronLeft size={20} />
                                    </button>
                                    <button className="mx-1 p-0 border-0 bg-transparent"
                                        type="button"
                                        onClick={() => {
                                            setCompareVersion(queryVersions[compareVersionIndex + 1]);
                                            setCompareVersionIndex(compareVersionIndex + 1);
                                        }}
                                        disabled={typeof queryVersions[compareVersionIndex + 1] === 'undefined'}
                                    >
                                        <ChevronRight size={20} />
                                    </button>
                                    <button className="mx-1 p-0 border-0 bg-transparent"
                                        type="button"
                                        onClick={() => {
                                            setCompareVersion({ booleanTitle: '', boolean: '' } as SelectedVersionProps);
                                            setCompareVersionIndex(0);
                                        }}
                                    >
                                        <X size={20} />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    {compareVersion
                        ? (
                            <DiffEditor original={selectedVersion.boolean}
                                modified={compareVersion.boolean}
                                options={{
                                    ...editorOptions,
                                    readOnly: true,
                                    domReadOnly: true,
                                    renderOverviewRuler: false,
                                    renderIndicators: false,
                                    renderLineHighlight: 'none'
                                }}
                                language="boolean"
                                theme="boolean-theme"
                            />
                        )
                        : (
                            <Editor height="100%"
                                className="custom-editor-container"
                                language="boolean"
                                theme="boolean-theme"
                                options={{
                                    ...editorOptions,
                                    readOnly: true,
                                    domReadOnly: true
                                }}
                                value={selectedVersion.boolean}
                            />
                        )}

                </div>
            </Col>
        </Row>
    ); };
