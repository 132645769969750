import moment from 'moment';
import React from 'react';
import { Col } from 'reactstrap';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { convertToDate } from '../../../../../utils/getTimeFrom';
import { capitalizeFirstLetter, getCtype } from '../../../../../utils/text';
import { EmptyState } from '../../Dashboard/EmptyState';

export const OriginalPost = ({ originalPost, originalPostText }) => {
    const ctype = getCtype(originalPost?.contentType);
    return (
        <Col xs={10} md={5} className="pt-3 px-0 bg-white">
            <h3 className="m-0 pl-2">Original poster</h3>
            <div className="mt-2 p-2 border-top">
                {originalPost ? (
                    <>
                        <p>
                            <strong>{getPostedByName(originalPost?.from, originalPost?.displayName)}</strong>
                        </p>
                        <div>
                            <strong>Platform: </strong>
                            <SourceIconLabel data={{ contentType: originalPost?.contentType }} className="pl-1 d-inline" />
                            <p className="d-inline">{ctype?.name ? ctype.name : 'N/A'}</p>
                        </div>
                        <p>
                            <strong>Location:</strong> {originalPost?.locationsDetected}
                        </p>
                        <p>
                            <strong>Sentiment:</strong>
                            {originalPost?.sentiment ? capitalizeFirstLetter(originalPost?.sentiment) : 'N/A'}
                        </p>
                        <p>
                            <strong>Posted:</strong> {moment(convertToDate(originalPost?.pubdatetime)).format('DD MMM YYYY')}
                        </p>
                        <p>{originalPostText}</p>
                    </>
                ) : (
                    <div className="mt-10">
                        <EmptyState />
                    </div>
                )}
            </div>
        </Col>
    );
};
