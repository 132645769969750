import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useModal } from '../../../../context/modal/ModalComponent';
import { SaveFilterModal } from './SaveFilterModal';
import { getRoom } from '../../../../utils/variables';
import iconCheckCircle from '../../../../assets/images/iconCheckCircle.svg';
import { GET_ROOM_FILTERS } from '../../FiltersLayout';

type FilterItemProps = {
    id: string,
    name: string,
    selected: boolean
}

export type FiltersDataProps = {
    filter: string,
    items: FilterItemProps[]
}

type SaveFilterProps = {
    text: string,
    filtersData: FiltersDataProps[],
    filterId?: string,
    userID?: string,
    filterName?: string,
    dateRange: {
        startDate?: number,
        endDate?: number
    }
    setOpenEditSavedFIlter?: (e: boolean) => void,
    disable?: boolean
}

export const SaveFilterButton = ({ text, filtersData, filterId, userID, filterName, dateRange,
    setOpenEditSavedFIlter, disable = false }: SaveFilterProps) => {
    const { setModal, closeModal } = useModal();
    const room = getRoom();

    const [saveFilters, { data, loading }] = useMutation(SAVE_ROOM_FILTERS, {
        refetchQueries: [GET_ROOM_FILTERS],
        onCompleted: () => {
            toast.success('Filters saved', {
                icon: <img src={iconCheckCircle} />,
                type: 'success'
            });
            if (setOpenEditSavedFIlter) {
                setOpenEditSavedFIlter(false);
            }
        },
        onError: () => {
            toast.error('Filters not saved');
            console.error('Filters not saved', data);
        }
    });

    const handleOpenSaveFilterModal = () => {
        setModal({
            header: 'Save filters',
            component: (
                <SaveFilterModal closeModal={closeModal} filtersData={filtersData} roomId={room.id} dateRange={dateRange} />
            )
        });
    };
    const handleSaveFilters = () => {
        saveFilters({ variables: {
            data: {
                name: filterName,
                situationRoom: room.id,
                data: filtersData,
                user: userID,
                dateRange
            },
            id: filterId
        } });
    };

    return (
        <div>
            <Button data-testid="save-filter-button"
                onClick={() => { text === 'Save filter' ? handleOpenSaveFilterModal() : handleSaveFilters(); }}
                disabled={loading || disable}
                color={(text !== 'Save filter') ? 'primary' : 'secondary'}
            >{text}
            </Button>
        </div>

    );
};

export const SAVE_ROOM_FILTERS = gql`
    mutation saveRoomFilters($data: roomFiltersInput, $id: ID) {
        saveRoomFilters(data: $data, id: $id) {
            id
            name
            user {
                displayName
            }
            data {
                filter
                items {
                    id
                    name
                    selected
                }
            }
            dateRange {
                startDate
                endDate
            }
        }
    }
`;
