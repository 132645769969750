import { createSlice } from '@reduxjs/toolkit';
import { processDateRange } from '../../utils/getTimeFrom';

export const initialState = {
    isBooleanOpen: false,
    isFiltersOpen: false,
    filters: {
        dateRangeOption: 'all',
        dateRange: {},
        sources: [],
        domains: [],
        origin: [],
        sentiment: [],
        threatTypes: [],
        query: '',
        booleanQuery: '',
        booleanQueryKeywords: [],
        sourcesWithDomains: [],
        languages: [],
        convertedBooleanQuery: '',
        contentType: [],
        from: [],
        keywords: []
    },
};

const customNarrativesInitialState = {
    topics: [],
    selectedTopic: {
        id: '',
        threat: '',
        boolean: '',
        keywords: [],
        query_type: '',
        dslQuery: '',
        isSaved: '',
        __typename: ''
    },
    queryErrorList: [],
};
const keywordsInitialState = {
    highlightKeywords: [],
};
const removeContent = {
    sort: { key: 'ALL', name: 'All' },
    searchValue: ''
};
const selectedClusterInitialState = {
    selectedCluster: '',
    isParentCluster: false
};
const setActiveTabInitialState = {
    activeTab: '',
    isContentPage: false
};
const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        saveFilters: (state, { payload }) => {
            let dateRange;

            if (payload.dateRangeOption) {
                dateRange = processDateRange({
                    rangeFilterOption: payload.dateRangeOption,
                    range: payload.dateRange
                });
            } else if (payload.dateRange) {
                dateRange = {
                    startDate: payload.dateRange.startDate,
                    endDate: payload.dateRange.endDate
                };
            }

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                    dateRange: dateRange || state.filters.dateRange,
                }
            };
        },
        resetFilters: (state) => ({
            ...state,
            filters: {
                ...initialState.filters
            }
        }),
        openBooleanFilters: (state) => ({
            ...state,
            isBooleanOpen: true
        }),
        closeBooleanFilters: (state) => ({
            ...state,
            isBooleanOpen: false,
            filters: {
                ...state.filters,
                convertedBooleanQuery: state.filters.booleanQuery ? state.filters.convertedBooleanQuery : '',
                booleanQueryKeywords: state.filters.booleanQuery ? state.filters.booleanQueryKeywords : []
            }
        }),
        openFilters: (state) => ({
            ...state,
            isFiltersOpen: true,
        }),
        closeFilters: (state) => ({
            ...state,
            isFiltersOpen: false
        })
    }
});
const watchlistFiltersSlice = createSlice({
    name: 'watchlistFilters',
    initialState,
    reducers: {
        saveWatchlistFilters: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                dateRange: payload.dateRange ? processDateRange({
                    rangeFilterOption: payload.dateRangeOption,
                    range: payload.dateRange
                }) : state.filters.dateRange,
                ...payload,
            }
        })
    }
});
const narrativeFiltersSlice = createSlice({
    name: 'narrativeFilters',
    initialState,
    reducers: {
        saveNarrativeFilters: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                ...payload,
            }
        }),
        resetNarrativeFilters: () => initialState
    }
});
const customNarrativesSlice = createSlice({
    name: 'customNarratives',
    initialState: customNarrativesInitialState,
    reducers: {
        saveCustomNarratives: (state, { payload }) => ({
            topics: payload.topics || state.topics,
            selectedTopic: payload.selectedTopic || state.selectedTopic,
            queryErrorList: payload.queryErrorList || state.queryErrorList,
        }),
        resetCustomNarratives: () => customNarrativesInitialState
    }

});
const roomPreviewKeywordsSlice = createSlice({
    name: 'roomPreviewKeywords',
    initialState: keywordsInitialState,
    reducers: {
        saveRoomPreviewKeywords: (state, { payload }) => ({
            highlightKeywords: payload.highlightKeywords || state.highlightKeywords,
        }),
        resetRoomPreviewKeywords: () => keywordsInitialState
    }
});
const removeContentSlice = createSlice({
    name: 'removeContentFilter',
    initialState: removeContent,
    reducers: {
        saveSort: (state, { payload }) => ({
            ...state,
            sort: payload.sort || state.sort
        }),
        saveFilter: (state, { payload }) => ({
            ...state,
            searchValue: payload.searchValue || state.searchValue
        }),
        resetSort: (state) => ({
            ...state,
            sort: removeContent.sort
        }),
        resetFilter: (state) => ({
            ...state,
            searchValue: removeContent.searchValue
        }),
        resetAll: () => removeContent
    }
});

const selectedClusterSlice = createSlice({
    name: 'selectedCluster',
    initialState: selectedClusterInitialState,
    reducers: {
        saveSelectedCluster: (state, { payload }) => ({
            selectedCluster: payload.selectedCluster || state.selectedCluster,
            isParentCluster: payload.isParentCluster || state.isParentCluster
        }),
        resetSelectedCluster: () => selectedClusterInitialState
    }
});

const activeTabSlice = createSlice({
    name: 'activeTab',
    initialState: setActiveTabInitialState,
    reducers: {
        setActiveTab: (state, { payload }) => ({
            activeTab: payload.activeTab || state.activeTab,
            isContentPage: payload.isContentPage || state.isContentPage
        }),
        resetActiveTab: () => setActiveTabInitialState
    }
});

export const { saveFilters, resetFilters, openBooleanFilters, closeBooleanFilters, openFilters, closeFilters } = filtersSlice.actions;
export const filters = filtersSlice.reducer;
export const { saveWatchlistFilters } = watchlistFiltersSlice.actions;
export const watchlistFilters = watchlistFiltersSlice.reducer;
export const { saveNarrativeFilters, resetNarrativeFilters } = narrativeFiltersSlice.actions;
export const narrativeFilters = narrativeFiltersSlice.reducer;
export const { saveCustomNarratives, resetCustomNarratives } = customNarrativesSlice.actions;
export const customNarratives = customNarrativesSlice.reducer;
export const { saveRoomPreviewKeywords, resetRoomPreviewKeywords } = roomPreviewKeywordsSlice.actions;
export const roomPreviewKeywords = roomPreviewKeywordsSlice.reducer;
export const { saveSort,
    saveFilter,
    resetSort,
    resetFilter,
    resetAll } = removeContentSlice.actions;
export const removeContentFilter = removeContentSlice.reducer;
export const { saveSelectedCluster, resetSelectedCluster } = selectedClusterSlice.actions;
export const selectedCluster = selectedClusterSlice.reducer;
export const { setActiveTab, resetActiveTab } = activeTabSlice.actions;
export const activeTab = activeTabSlice.reducer;
