import React from 'react';
import { Maximize2, TrendingDown, TrendingUp } from 'react-feather';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { MatchesWidget } from '../../../../../components/Highcharts';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { trendline } from '../../../../../utils/trendline';
import { TitleTooltip } from '../utils';
import { FullLineChart } from './FullLineChart';
import { LoadingState } from './Loading';
import { resetMultiSmallChartsAction } from '../../store';
import { getEndDate, getIntervalDateRange } from '../../../../../utils/getIntervalDateRange';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { useTotalThreatsAndMatches } from '../../../../../services/Overview';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { checkAndGetUnix } from '../../../../../utils/getTimeFrom';

export const TotalMatchesAndThreatsOverTime = ({ items, preview }) => {
    const { setModal, closeModal } = useModal();
    const dispatch = useDispatch();
    const { data: totalMatchesAndThreatsOverTime, loading } = useTotalThreatsAndMatches({ showFull: false });

    const getContentFilter = ({ endDate, date, item }) => ({
        date: {
            startDate: checkAndGetUnix(date.utc().startOf('day').valueOf()),
            endDate: checkAndGetUnix(moment(endDate).utc().endOf('day').valueOf())
        },
        ...(item.title === 'Total Threats' ? { isThreat: true } : {})
    });

    const getContentModal = ({ filter }) => {
        if (preview) {
            return <ContentPreviewModal filter={{ filter }} contentType={CONTENT_TYPE.THREAT} preview={preview} />;
        }

        return <ContentModal filter={{ filter }} contentType={CONTENT_TYPE.THREAT} />;
    };

    if (loading) {
        return <LoadingState items={items} />;
    }
    if (!totalMatchesAndThreatsOverTime.matches) {
        return null;
    }
    return (
        <div data-testid="multi-small-charts">
            {items.map((item, index) => {
                const current = item.type === 'high_threats'
                    ? totalMatchesAndThreatsOverTime.threats : totalMatchesAndThreatsOverTime.matches;
                const values = current.data.map(a => a.count);
                const count = largeNumber(current.count);
                const trend = getTrend(values);
                const { interval } = totalMatchesAndThreatsOverTime;
                return (
                    <div key={item.id} className={index !== items.length - 1 ? 'mb-4' : ''}>
                        <div className="d-flex align-items-center mb-2">
                            <TitleTooltip title={item.title} type={item.type} />
                            <h3 className="my-0 ml-3">
                                {count}
                                {trend && (
                                    <span className="ml-11">
                                        {trend.textClass === 'text-warning'
                                            ? <TrendingUp color="#13BA9C" size={20} />
                                            : <TrendingDown color="#F84367" size={20} />}
                                    </span>
                                )}
                            </h3>
                        </div>
                        <MatchesWidget colors={[item.type === 'high_threats' ? '#D945D0' : '#006FF9']}
                            data={values}
                            height={100}
                            width={null}
                            dates={current.data.map(a => a.key)}
                            interval={interval}
                            onClick={(e) => {
                                const start = current.data[e.point.index].key;
                                const date = moment(new Date(start));
                                const endDate = getEndDate(start, interval);
                                setModal({
                                    header: `${item.title} - ${getIntervalDateRange(start, interval)}`,
                                    size: 'xl',
                                    goToPreviousOnClose: true,
                                    component: getContentModal({ filter: getContentFilter({ endDate, date, item }) })
                                });
                            }}
                        />
                    </div>
                );
            })}
            <div className="absolute-bottom-right p-1"
                role="button"
                data-testid="multi-small-charts-maximize-button"
                onClick={() => setModal({
                    header: false,
                    size: 'xl',
                    component: <FullLineChart items={items} preview={preview} />,
                    onClose: () => {
                        dispatch(resetMultiSmallChartsAction());
                        closeModal();
                    }
                })}
            >
                <Maximize2 className="text-primary" role="button" />
            </div>
        </div>
    );
};

export const getTrend = (values) => {
    const { slope } = trendline(values.map((y, x) => ({ x, y })));
    if (slope > 0) {
        return {
            textClass: 'text-warning',
            icon: 'uil uil-arrow-up'
        };
    }
    return {
        textClass: 'text-success',
        icon: 'uil uil-arrow-down'
    };
};
