import React, { useState } from 'react';
import { Search, X } from 'react-feather';
import {
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { ContentTypeDropdown } from './ContentTypeDropdown';

export const FeedbackFilters = ({ handleSearchValue, setOffset, total, setSort, sort }) => {
    const [search, setSearch] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearchValue(search, 'searchValue');
    };
    return (
        <div className="d-flex align-items-center">
            <div className="flex-fill">
                <Form onSubmit={handleSubmit}>
                    <InputGroup border={search ? 'active' : 'none'} className="mb-0">
                        <Input type="text"
                            placeholder="Search Members"
                            name="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            data-testid="all-users-input"
                        />
                        {search.length > 0 && (
                            <InputGroupAddon addonType="prepend" className="border-right small-border">
                                <InputGroupText role="button"
                                    onClick={() => { setSearch(''); handleSearchValue('', 'searchValue'); }}
                                    data-testid="clear-search"
                                >
                                    <X color="#006FF9" size={16} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText role="button">
                                <Search color="#006FF9" size={16} onClick={handleSubmit} data-testid="search-icon" />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Form>
            </div>
            <ContentTypeDropdown setOffset={setOffset} total={total} setSort={setSort} sort={sort} />
        </div>
    );
};
