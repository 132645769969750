import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../services/Content/contentInterface';
// import { CONTENT_TYPE } from '../../utils/contentTypes';
import { RemoveContentModal } from '../CommonModals/RemoveContent';

type RemoveContentButtonProps = {
    selectedContent: Pick<Content, WatchlistButtonContentKeys>[]
    onCompleted: () => void
    contentType: string
}

export const RemoveContentButton = ({ selectedContent, onCompleted, contentType }: RemoveContentButtonProps) => {
    const { setModal } = useModal();

    const setRemoveContentModal = () => {
        const removeData = selectedContent.map(({ id }) => ({ id }));
        setModal({
            modal: <RemoveContentModal removeData={removeData}
                type={contentType}
                onCompleted={onCompleted}
            />
        });
    };
    return (
        <Button color="danger" onClick={setRemoveContentModal} data-testid="remove-contents-button">
            Remove content{selectedContent.length > 1 && 's'}
        </Button>
    );
};
