import savedReports from '../assets/images/emptyStates/savedReports.svg';
import savedReportsDark from '../assets/images/emptyStates/darkmode/savedReports.svg';
import investigations from '../assets/images/emptyStates/investigations.svg';
import investigationsDark from '../assets/images/emptyStates/darkmode/investigations.svg';
import overview from '../assets/images/emptyStates/overview.svg';
import loading from '../assets/images/loading.gif';
import darkloading from '../assets/images/darkloading.gif';

let isDarkmode;
export const modeChecker = (darkmode) => {
    isDarkmode = darkmode;
};
export const Images = () => {
    const Modified = {};
    if (!isDarkmode) {
        Modified.savedReports = savedReports;
        Modified.investigations = investigations;
        Modified.overview = overview;
        Modified.loading = loading;
    } else {
        Modified.savedReports = savedReportsDark;
        Modified.investigations = investigationsDark;
        Modified.overview = overview;
        Modified.loading = darkloading;
    }
    return Modified;
};
