import React from 'react';
import { Table } from 'reactstrap';
import { TitleTooltip } from '../utils';
import { LoadingState } from './Loading';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { ContentModalButton } from '../../ContentModal/ContentModalButton';
import { ExternalLinkButton } from '../../../../../components/LinkModal/ExternalLinkButton';
import { roundPercentage } from '../../../../../utils/NumberFormat';

export const withTableStructure = (config) => ({ preview }) => {
    const { data, loading, total } = config.api(config.apiParams);

    if (loading) return <LoadingState size={10} />;

    return (
        <div className="h-100 d-flex flex-column">
            <TitleTooltip title={config.title({ preview })}
                tooltip={config.tooltip}
            />

            <div className="overflow-y-auto scrollbar-small mx-n3 mt-2">
                {data.length > 0 ? (
                    <Table className="mb-0 px-3">
                        <thead>
                            <tr>
                                {config.columns.map(column => (
                                    <th key={column.key}>{column.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(item => (
                                <tr key={item.key}>
                                    {config.columns.map(column => {
                                        const label = item[column.key];
                                        if (column.type === 'url') {
                                            return (
                                                <td key={column.key}>
                                                    <ExternalLinkButton url={label} />
                                                </td>
                                            );
                                        }
                                        if (column.type) {
                                            return (
                                                <td key={column.key}>
                                                    <ContentModalButton value={item.key}
                                                        type={column.type}
                                                        label={label}
                                                        preview={preview}
                                                    />
                                                </td>
                                            );
                                        }
                                        return (
                                            <td key={column.key}>
                                                {label}
                                                {column.percentage && total > 0 ? (
                                                    <span className="ml-11">
                                                        ({roundPercentage((label / total) * 100)}%)
                                                    </span>
                                                ) : null}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <EmptyState title="No data available" icon={Images().overview} />
                )}
            </div>
        </div>
    );
};
