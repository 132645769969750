import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MultiSelectDropdown } from '../Form/MultiSelectDropdown';
import { ThreatIdentifierSuccessModal } from './ThreatIdentifierSuccessModal';
import { TooltipItem } from '../ToolTip';
import { useModal } from '../../context/modal/ModalComponent';
import { RemoveContentActions, useRemoveContent } from './useRemoveContent';
import { CONTENT_TYPE } from '../../utils/contentTypes';

const threatIdentifierOptions = [
    { id: 'automatedAccount', text: 'Automated account' },
    { id: 'toxicInfo', text: 'Toxic information' },
    { id: 'src-cred', text: 'Source credibility' },
    { id: 'domestic_extremism', text: 'Domestic extremism' }
];

type ThreatIdentifierProps = {
    onClose: () => void,
    threatId: string,
    onCompleted?: () => void
}

export const ThreatIdentifier = ({ onClose, threatId, onCompleted }: ThreatIdentifierProps) => {
    const { setModal } = useModal();
    const [optionsSelected, setOptionsSelected] = useState([] as {id: string, text: string}[]);
    const [confirmButtonClicked, setConfirmButtonClicked] = useState(false);
    const { removeContent, loading } = useRemoveContent({
        type: CONTENT_TYPE.MATCH,
        action: RemoveContentActions.MarkAsThreat,
        onCompleted: () => {
            onClose();
            setSuccessModal();
            if (onCompleted) onCompleted();
        }
    });

    const confirmation = () => {
        setConfirmButtonClicked(true);
        if (optionsSelected.length > 0) {
            removeContent([{
                id: threatId,
                markThreatInfo: optionsSelected?.map(x => x.text)
            }]);
        }
    };
    const setSuccessModal = () => setModal({
        modal: <ThreatIdentifierSuccessModal />,
        goToPreviousOnClose: true
    });

    return (
        <Modal isOpen toggle={onClose} data-testid="SubmissionComplete">
            <ModalBody>
                <h2>Is this content a threat?</h2>
                <hr />
                <p>
                    Mark the content as a threat if you think that this content might be harmful.
                    This content will be classified as threat and will be visible in the threats section for you and your team.
                </p>
                <p className="font-weight-bold pt-1">
                    Select threat type(s)
                    <TooltipItem placement="bottom"
                        id="narrativePerformers"
                        content={(
                            <div>
                                <div>
                                    <strong>Automated account. </strong>
                                    Content that originates from bot-operated account(s).
                                </div>
                                <div>
                                    <strong>Toxic information. </strong>
                                    Content intended to negatively intensify discourse.
                                </div>
                            </div>
                        )}
                    />
                </p>
                <MultiSelectDropdown id="threatIdentifier"
                    options={threatIdentifierOptions}
                    onChange={setOptionsSelected}
                />
                {confirmButtonClicked
                     && optionsSelected?.length < 1 && <p className="text-danger pt-1">Please select at least one option.</p>}
                <hr />
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => !loading && confirmation()}
                    color="primary"
                    className="ml-2"
                    disabled={loading}
                >
                    Confirm
                </Button>
            </ModalBody>
        </Modal>
    );
};
