import React from 'react';

type KeywordsListProps = {
    keyword: string
    count: number
}

export const KeywordsList = ({ keyword, count }: KeywordsListProps) => (
    <>
        <div className="d-flex justify-content-between align-items-center p-2">
            <p className="w-75 text-primary">{keyword}</p>
            <p className="w-25">{count}</p>
        </div>
        <hr className="set-underline-props" />
    </>
);
