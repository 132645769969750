import React, { useState } from 'react';
import {
    Button,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { Search, X } from 'react-feather';
import { useLazyQuery } from '@apollo/client';
import { mapValues } from 'lodash';
import { useModal } from '../../../../context/modal/ModalComponent';
import { SourceFinder } from './SourceFinder';
import { GET_SUBCATEGORY_DOMAIN } from '../SourceFilter';
import { getRoom } from '../../../../utils/variables';
import { Loading } from '../../../Loading';

export const AdvanceSourceFilter = ({ sourceData, sourcesWithDomains: defaultDomains, setSourcesWithDomains: setDefaultDomains }) => {
    const room = getRoom();
    const [searchText, setSearchText] = useState('');
    const [domainsList, setDomainsList] = useState(sourceData);
    const [shownDetails, setShownDetails] = useState({});
    const setCollapseAll = (collapseAll) => {
        if (collapseAll) {
            const collapseObject = { ...shownDetails };
            setShownDetails(mapValues(collapseObject, () => false));
        }
        else setShownDetails({});
    };
    const [isFiltered, setIsFiltered] = useState(false);
    const { closeModal } = useModal();
    const [sourcesWithDomains, setSourcesWithDomains] = useState(defaultDomains || []);
    const [urlList, setUrlList] = useState([]);
    const totalDomainsCount = domainsList.reduce((acc, src) => acc + src.domainCount, 0);
    const extractUrls = (pastedElement) => {
        const doc = document.createElement('html');
        doc.innerHTML = pastedElement;
        const links = doc.getElementsByTagName('a');
        const urls = [];
        for (let i = 0; i < links.length; i++) {
            urls.push(links[i].hostname);
        }
        setUrlList(urls);
    };
    const [loadAllDomains, { loading }] = useLazyQuery(GET_SUBCATEGORY_DOMAIN, {
        onCompleted: (data) => {
            if (data) {
                setDomainsList(data.getContentTypesWithDomains);
            }
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFiltered(true);
        loadAllDomains({ variables: {
            projectId: room?.project_id,
            filters: {
                searchDomains: urlList.length > 0 ? urlList[1] : searchText
            },
            size: 100
        } });
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <InputGroup border={searchText ? 'active' : 'none'} className="mb-0">
                    <Input type="text"
                        placeholder="Search by domain"
                        name="search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onPaste={(e) => extractUrls(e.clipboardData.getData('text/html'))}
                    />
                    {searchText.length > 0 && (
                        <InputGroupAddon addonType="prepend" className="border-right small-border">
                            <InputGroupText role="button"
                                onClick={() => { setSearchText(''); setDomainsList(sourceData); setIsFiltered(false); }}
                            >
                                <X color="#006FF9" size={20} />
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText role="button">
                            <Search color="#006FF9" size={20} onClick={handleSubmit} />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </Form>
            {loading ? <div className="my-4"><Loading relative /></div>
                : (
                    <>
                        <div className="d-flex justify-content-between align-items-center pt-2">
                            <p>{totalDomainsCount} sources {`${isFiltered ? '(filtered)' : ''}`}</p>
                            {totalDomainsCount !== 0
                            && <p className="text-primary cursor-pointer" onClick={() => setCollapseAll(true)}>Collapse all</p>}
                        </div>
                        {totalDomainsCount === 0
                            ? (
                                <div className="p-4 bg-lightgray text-center">
                                    <p>There are no sources that match your search, your search query needs refinement.</p>
                                </div>
                            )
                            : (
                                <div className="bg-lightgray">
                                    <SourceFinder sourceFilters={domainsList}
                                        sourcesWithDomains={sourcesWithDomains}
                                        setSourcesWithDomains={setSourcesWithDomains}
                                        shownDetails={shownDetails}
                                        setShownDetails={setShownDetails}
                                        setCollapseAll={setCollapseAll}
                                    />
                                </div>
                            )}
                        <Button color="secondary" className="mt-2" onClick={closeModal}>Cancel</Button>
                        {totalDomainsCount !== 0
                            && (
                                <Button color="primary"
                                    className="ml-2 mt-2"
                                    onClick={() =>
                                    { setDefaultDomains(sourcesWithDomains); closeModal(); }}
                                >Apply
                                </Button>
                            )}
                    </>
                )}
        </div>
    );
};
