import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { AlertTriangle } from 'react-feather';
import { useUser } from '../../context/user/UserContext';
import { SituationRoomsList } from './SituationRoomsList';
import { useGetInstance } from '../../services/Instance/getInstance';
import { GET_SITUATION_ROOMS } from '../../graphql';

export const SituationRooms = () => {
    let canCreateRoom = false;
    const { user, isAdmin } = useUser();
    const { instance: instanceData, loading } = useGetInstance({ instanceId: user?.instance?.id });
    const { data: situationRoomsData } = useQuery(GET_SITUATION_ROOMS, {
        variables: {
            instance: user?.instance?.id,
            status: 'active'
        },
        skip: !user?.instance?.id
    });

    if (situationRoomsData?.situationRooms?.total !== undefined && instanceData?.plan?.situationroomLimit) {
        canCreateRoom = instanceData?.plan?.situationroomLimit > situationRoomsData?.situationRooms.total;
    }

    const isInactiveInstance = instanceData?.plan?.validUntil ? instanceData?.plan?.validUntil < new Date().getTime() : false;
    const onCreateRoomButtonClick = () => {
        if (isInactiveInstance) {
            toast.error(`This account belongs to an organisation with an inactive Logically Intelligence license. 
            Creating new Situation Rooms requires an active license.`, {
                autoClose: 60000,
                style: {
                    marginTop: 76,
                    marginRight: 12
                },
                icon: <AlertTriangle className="text-danger" size={24} />
            });
        }
    };

    const renderCreateRoomButton = () => (
        <Button color="primary" onClick={onCreateRoomButtonClick}>
            Create Situation Room
        </Button>
    );

    return (
        <div className="mt-4 pl-4 pr-4 w-100">
            <Row className="align-items-center mb-4">
                <Col sm="12" md="4" xs="12" className="mr-auto" style={{ minWidth: 270 }}>
                    <h1 className="mb-0" style={{ lineHeight: 1 }}>
                        Hello {' '}
                        {user?.displayName}
                    </h1>
                </Col>
                {(isAdmin && canCreateRoom && !loading) && (
                    <Col sm="6" xs="12" md="auto">
                        {isInactiveInstance ? renderCreateRoomButton() : (
                            <Link to="situation-room/create-room">
                                {renderCreateRoomButton()}
                            </Link>
                        )}
                    </Col>
                )}
            </Row>
            <SituationRoomsList />
        </div>
    );
};
