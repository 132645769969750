import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { ClusterStats } from '../Summary/ClusterStats';
import { calculateAverageSentiment } from '../../../../../utils/calculateAverageSentiment';

type SentimentType = {
    sentiment: string
    score: string
}

type SubClusterProps = {
    subClusterTitle: string
    subClusterSummary: string
    volume: number
    duplicatedDocsCount: number
    sentimentJson: [SentimentType]
    id: string
}

type SubClusterItemProps = {
    subCluster: SubClusterProps
    shownDetails: any
    toggleDetails: (id: string) => void
}

export const SubClusterItem = ({ subCluster, shownDetails, toggleDetails }: SubClusterItemProps) => (
    <>
        <div className="d-flex align-items-top justify-content-between p-2">
            <p className={`m-0 w-100 ${shownDetails[subCluster.id] && 'font-weight-bold'}`}>{subCluster.subClusterTitle}</p>
            {shownDetails[subCluster.id] ? (
                <ChevronUp size={30}
                    role="button"
                    color="#006FF9"
                    onClick={() => toggleDetails(subCluster.id)}
                    data-testid="hideButton"
                />
            )
                : (
                    <ChevronDown size={30}
                        role="button"
                        color="#006FF9"
                        onClick={() => toggleDetails(subCluster.id)}
                        data-testid="showButton"
                    />
                )}
        </div>
        {shownDetails[subCluster.id] ? <p className="px-2 pb-2 m-0">{subCluster.subClusterSummary}</p> : null }
        <div className="cluster-stats-align px-2 pb-2 pt-0 m-0">
            <ClusterStats title="Content" icon="contents" count={subCluster.volume} />
            <ClusterStats title="Duplicates" icon="duplicates" count={subCluster.duplicatedDocsCount} />
            <ClusterStats title="Sentiment"
                icon="sentiment"
                sentiments={subCluster.sentimentJson}
                averageSentiment={calculateAverageSentiment(subCluster.sentimentJson)}
            />
        </div>
        <hr className="set-underline-props" />
    </>
);
