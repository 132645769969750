import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { AlertTriangle, Check } from 'react-feather';
import { Link } from 'react-router-dom';
import { useRoomEditor } from '../RoomEditorContext';
import { InfoBanner, RadioInput } from '../../../../components/Atoms';
import { useRoomNameAvailability } from '../inputs/useRoomNameAvailability';
import { useSituationRoomDraftByID } from '../../../../services/SituationRooms/useSituationRoomDraftByID';
import { TooltipItem } from '../../../../components/ToolTip';
import { DatePicker } from '../../../../components/DatePicker';

export const RoomDetails = () => {
    const { isBooleanQuery, edit, draft, room, oldRoom, errors, setErrors, setRoom } = useRoomEditor();
    const [roomName, setRoomName] = useState(room.project_name);
    const { availability, nameNotAvailable } = useRoomNameAvailability({ roomName });
    const [reason, setReason] = useState(room.reason);
    const [contentVisibilityRestriction, setContentVisibilityRestriction] = useState(room.contentVisibilityRestriction);
    const [showBanner, setShowBanner] = useState();
    const { data: draftRoom } = useSituationRoomDraftByID({ id: room?.draftId });

    useEffect(() => {
        if (!draft && draftRoom && showBanner === undefined) {
            setShowBanner(true);
        }
    }, [draftRoom, draft, showBanner]);

    const handleReasonChange = (e) => {
        setErrors({
            ...errors,
            reason: false
        });
        const val = e.target.value || '';
        setReason(val);
        setRoom({
            ...room,
            reason: val
        });
    };

    const handleRoomNameChange = (e) => {
        setErrors({
            ...errors,
            roomName: false
        });
        const val = e.target.value || '';

        if (val.length > 70) {
            setRoomName(roomName);
            return;
        }
        const formattedVal = val[0] !== ' ' ? val : val?.trim();
        setRoomName(formattedVal);
        setRoom({
            ...room,
            project_name: formattedVal
        });
    };

    const handleContentVisibilityRestrictionChange = (e) => {
        setErrors({
            ...errors,
            contentVisibilityRestriction: false
        });
        const val = e.target.value || '';
        setContentVisibilityRestriction(val);
        setRoom({
            ...room,
            contentVisibilityRestriction: val
        });
    };

    const handleStartDateChange = (start) => {
        const restrictedDateStart = moment().subtract(contentVisibilityRestriction, 'days').toDate();
        setErrors({
            ...errors,
            contentVisibilityRestriction: false
        });
        setRoom({ ...room, start_date: start });

        if (start && contentVisibilityRestriction && start.diff(restrictedDateStart, 'days') > 0) {
            const startDateClone = moment(start);
            const contentRestrictionEndDate = startDateClone.add(contentVisibilityRestriction, 'days');
            setErrors({
                ...errors,
                contentVisibilityRestriction: `You won't see any contents before ${contentRestrictionEndDate.format('DD-MM-YYYY')}`
            });
        }
    };

    const handleRoomNameFocusOut = () => {
        setErrors({
            ...errors,
            roomName: !roomName?.trim()
        });
    };

    const handleReasonFocusOut = () => {
        setErrors({
            ...errors,
            reason: !reason?.trim()
        });
    };

    const handleContentVisibilityRestrictionFocusOut = () => {
        if (contentVisibilityRestriction) {
            const restrictedDateStart = moment().subtract(contentVisibilityRestriction, 'days').toDate();
            const startDateClone = moment(room?.start_date);
            const contentRestrictionEndDate = startDateClone.add(contentVisibilityRestriction, 'days');

            if (room?.start_date.diff(restrictedDateStart, 'days') > 0) {
                return setErrors({
                    ...errors,
                    contentVisibilityRestriction: `You won't see any contents before ${contentRestrictionEndDate.format('DD-MM-YYYY')}`
                });
            }
        }

        setErrors({
            ...errors,
            contentVisibilityRestriction: false
        });
    };

    let contentVisibilityRestrictionTooltip = 'When set it will restrict the content visibility to be not newer than X number of days.';

    if (edit) {
        contentVisibilityRestrictionTooltip += " It's only possible to set up when creating the situation room";
    }

    return (
        <div>
            <div className="d-flex flex-column mx-n2">
                {!draft && draftRoom && showBanner && (
                    <div className="mx-2 mw-300 banner-width">
                        <InfoBanner closeBanner={() => setShowBanner(false)}>
                            <div className="pr-2 mr-auto">
                                {draftRoom.createdBy?.displayName} saved a draft on {
                                    moment(draftRoom.updatedAt || draftRoom.createdAt).format('DD/MM/YYYY | hh:mm A')
                                }, would you like to restore this draft?
                            </div>
                            <Link to={`/situation-room/edit-draft-room/${draftRoom.id}`}>
                                Restore
                            </Link>
                        </InfoBanner>
                    </div>
                )}
                <div className="px-2 mb-4 mw-300">
                    <div className="input-width">
                        <p className="font-weight-bold mb-0 d-inline">
                            Situation room name ({roomName?.length}/70 characters)
                        </p>
                        <InputGroup border="none"
                            state={((errors.roomName && !roomName?.trim())
                        || nameNotAvailable) ? 'error' : ''}
                            className="mt-1"
                        >
                            <Input placeholder="Enter room name"
                                autoFocus
                                value={roomName}
                                onChange={handleRoomNameChange}
                                onBlur={handleRoomNameFocusOut}
                                data-testid="room-name-input"
                            />
                            {((errors.roomName && !roomName?.trim()) || nameNotAvailable) && (
                                <InputGroupAddon addonType="append">
                                    <InputGroupText className="border-0 p-1 bg-white">
                                        <AlertTriangle color="#F84367" size={20} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                            {(roomName?.trim() && availability && (!edit || roomName?.trim() !== oldRoom?.project_name)) && (
                                <InputGroupAddon addonType="append">
                                    <InputGroupText className="border-0 p-1 bg-white">
                                        <Check color="#13BA9C" size={16} strokeWidth={3} />
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>

                        {(errors.roomName && !roomName?.trim()) && <p className="text-danger">Your room must have a name.</p>}
                        {nameNotAvailable && (
                            <p className="text-danger">
                                This room title already exists. Choose a different title.
                            </p>
                        )}
                    </div>
                </div>
                <div className="pl-2 mb-4 mw-300 input-width-date">
                    <div className="mb-1">
                        <p className="font-weight-bold d-inline">Monitoring start date</p>
                        <TooltipItem id="start_date"
                            content="Select start date from which you would like to
                        monitor content. By default, the date made available
                        will be back dated 6 months."
                            color="#6C63FF"
                        />
                    </div>
                    <DatePicker startDate={new Date(room.start_date)}
                        maxDate={moment(new Date()).toDate()}
                        onCallback={handleStartDateChange}
                    />
                </div>
            </div>
            <div className="mb-4 input-width">
                <p className="font-weight-bold mb-0 d-inline">
                    Situation room description
                </p>
                <TooltipItem id="reason"
                    content="Let us know the main objective or goal you hope to achieve by creating this dashboard.
                     Your valuable feedback will assist us in improving Logically Intelligence."
                    color="#6C63FF"
                />
                <InputGroup border="none"
                    state={(errors.reason) ? 'error' : ''}
                    className="mt-1"
                >
                    <Input placeholder="Enter a reason for creating this room"
                        className="maxh-100 minh-42"
                        type="textarea"
                        value={reason}
                        onChange={handleReasonChange}
                        onBlur={handleReasonFocusOut}
                        data-testid="room-reason-input"
                    />
                </InputGroup>
                {errors.reason && <p className="text-danger">Your room must have a description.</p>}
            </div>
            <div className="mb-4 input-width">
                <p className="font-weight-bold mb-0 d-inline">
                    Restrict the content visibility by number of days
                </p>
                <TooltipItem id="contentVisibilityRestriction"
                    content={contentVisibilityRestrictionTooltip}
                    color="#6C63FF"
                />
                <InputGroup border="none"
                    state={(errors.contentVisibilityRestriction) ? 'error' : ''}
                    className="mt-1"
                >
                    <Input className="maxh-100 minh-42"
                        type="number"
                        value={contentVisibilityRestriction}
                        onChange={handleContentVisibilityRestrictionChange}
                        onBlur={handleContentVisibilityRestrictionFocusOut}
                        data-testid="room-contentVisibilityRestriction-input"
                        disabled={!!edit}
                    />
                </InputGroup>
                {errors.contentVisibilityRestriction
                    && (<p className="text-danger">{errors.contentVisibilityRestriction}</p>)}
            </div>
            {!edit && (
                <div className="d-flex flex-column">
                    <p className="font-weight-bold mb-1 d-inline">
                        Room type
                    </p>
                    <div className="d-flex flex-row">
                        <RadioInput label="Boolean"
                            name="roomType"
                            id="boolean-select"
                            checked={isBooleanQuery}
                            onChange={() => setRoom({
                                ...room,
                                query_type: 'boolean'
                            })}
                            className="mb-1"
                        />

                        <RadioInput label="Keywords"
                            name="roomType"
                            id="keywords-select"
                            checked={!isBooleanQuery}
                            onChange={() => setRoom({
                                ...room,
                                query_type: 'keyword'
                            })}
                            className="mb-1"
                        />
                    </div>
                </div>
            )}
        </div>

    ); };
