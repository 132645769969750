import React from 'react';
import { Row, Col } from 'reactstrap';
import { Images } from '../../utils/modeSwitch';

type EmptySearchResultsProps = {
    subtitle?: string;
    mainHeading?: string;
    isSubtitleDisplayed?: boolean;
}

export const EmptySearchResults = ({ subtitle, mainHeading, isSubtitleDisplayed = true }: EmptySearchResultsProps) => (
    <div className="py-10 bg-white px-2 mt-5">
        <Row className="text-center align-items-center">
            <Col xs={12}>
                <img src={Images().overview}
                    alt="no-data-found"
                    className="maxw-350 maxh-300"
                />
            </Col>
            <Col xs={12}>
                <h2 data-testid="empty-search-results" className="my-2">{mainHeading || "There's nothing to see here just yet"}</h2>
                {isSubtitleDisplayed && (
                    <p data-testid="processing-data">
                        {subtitle || 'We are still processing the data, check back later.'}
                    </p>
                )}
            </Col>
        </Row>
    </div>
);
