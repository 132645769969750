import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import moment from 'moment';
import { DateRangePicker } from '../../DateRangePicker';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { truncateString } from '../../../../../utils/text';
import { NoDataFound } from '../Nodatafound';
import noImage from '../../../../../assets/images/no_image.jpg';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';

const sortOptions = [
    { key: 'newest', name: 'Newest' },
    { key: 'oldest', name: 'Oldest' }
];

export const FactCheck = ({ data }) => {
    const [val, setVal] = useState('');
    const [sort, setSort] = useState(sortOptions[0]);
    const [dateData, setDateTime] = useState({});
    const [isTranslated, setIsTranslated] = useState(false);
    const filteredData = data?.related_factchecks?.filter(
        (item) => moment(item?.date).unix() >= dateData?.startDate && moment(item?.date).unix() <= dateData?.endDate
    ) || [];
    const [fcData, setFcData] = useState(filteredData);

    useEffect(() => {
        let arr;
        if (sort.key === 'newest') {
            arr = fcData.slice().sort((a, b) => b.date.localeCompare(a.date));
        } else {
            arr = fcData.slice().sort((a, b) => a.date.localeCompare(b.date));
        }
        setFcData(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    const onSearchHandler = () => {
        if (val) {
            const newVal = filteredData.filter((x) => x.conclusion.includes(val));
            setFcData(newVal);
        } else {
            setFcData(filteredData);
        }
    };
    const onResetSearchBar = () => {
        setVal('');
        setFcData(filteredData);
    };
    useEffect(() => {
        setFcData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateData]);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: data?.narrative_title, target: 'en' }
        },
    });
    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : data?.narrative_title;
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };

    return (
        <>
            <div>View all of the Fact Checks performed by Logically related to your Narrative.</div>
            <h3 className="m-0 mt-3 sentence-format">{isTranslated ? outputText : data?.narrative_title}
                {data?.lang_verbose === 'English' ? null : (
                    <span role="button"
                        className="ml-2"
                        onClick={(e) => translation(e)}
                    >
                        <TranslateIcon color="#006FF9" size={30} />
                    </span>
                )}
            </h3>
            <div className="ml-0 mr-0 mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div className="w-100 align-search-bar">
                    <InputGroup border={val ? 'active' : 'none'}>
                        <Input value={val} onChange={(e) => setVal(e.target.value)} placeholder="Search" />
                        {val && (
                            <InputGroupAddon addonType="prepend" className="border-right small-border">
                                <InputGroupText role="button"
                                    onClick={() => {
                                        onResetSearchBar();
                                    }}
                                >
                                    <FeatherIcon.X color="#006FF9" size={16} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText role="button" onClick={() => onSearchHandler()}>
                                <FeatherIcon.Search size={16} color="#006FF9" />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <div className="d-flex align-date-range ml-2">
                    <p className="mr-1 no-white-space">Date range</p>
                    <DateRangePicker setDateTime={setDateTime} />
                </div>
                <div>
                    <Dropdown label="Sort by"
                        options={sortOptions}
                        value={sort}
                        onChange={setSort}
                        className="d-flex align-items-center w-100 mb-1 ml-2"
                    />
                </div>
            </div>
            {(fcData.length > 0 && fcData[0] !== null) ? <FactCheckData data={fcData} /> : (
                <div className="bg-white w-100 p-5">
                    <NoDataFound />
                </div>
            ) }
        </>
    );
};

const FactCheckData = ({ data }) => {
    if (!data) return null;
    return (
        <>
            {data.map((item) => (
                <div key={item?.id}>
                    <div className="p-3 w-100 bg-white row m-0">
                        <div className="col-md-10 p-0">
                            <div className="font-weight-bold">
                                {item?.textual_judgement} | {moment(item?.date).format('DD MMM YYYY')}
                            </div>
                            <div className="pt-1">
                                <strong>{item?.claim}</strong>
                            </div>
                            <div className="pt-1">
                                {truncateString(item?.conclusion, 220)}
                                <span className="ml-1 text-primary" role="button">
                                    <a href={item?.link} target="_blank" id={`fcItem-${item?._id}`} rel="noreferrer">
                                        {item?.conclusion?.length > 220 ? 'View source' : '...View source'}
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2 p-0 text-right">
                            <img src={item?.image ? item.image : noImage}
                                alt={item?.image ? '' : 'No image available'}
                                width={192}
                                height={115}
                            />
                        </div>
                    </div>
                    <hr className="m-0" />
                </div>
            ))}
        </>
    );
};
