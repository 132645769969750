import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { CheckBox } from '../../../components/Form/CheckBox';
import { useModal } from '../../../context/modal/ModalComponent';

export const UpdateNarrativesModal = ({ handleSaveRoom }) => {
    const { closeModal } = useModal();
    const [alert, setAlert] = useState(false);
    return (
        <div>
            <h2>Are you sure want to save the room?</h2>
            <hr />
            <p>Your changes to room will be saved and you can see matches according to the new boolean query or keywords.</p>
            <hr />
            <CheckBox label="Update situation room narratives" onChange={() => { setAlert(!alert); }} checked={alert} updateNarrative />
            {alert && (
                <Alert color="warning" className="d-flex align-items-center p-2 mt-3" fade={false}>
                    <FeatherIcon.AlertCircle color="#FCCD13" size={98} className="cursor-pointer" />
                    <span className="ml-2">Proceed with extreme care when updating the narrative to the new boolean,
                        as this action will result in the creation of a completely new set of room narratives,
                        thereby irrevocably discarding any previously saved or deleted narratives from the earlier state of the room.
                    </span>
                </Alert>
            )}
            <hr />
            <div>
                <Button onClick={() => closeModal()} className="mr-2">Cancel</Button>
                <Button color="primary"
                    data-testid="save-update-narrative"
                    onClick={() => { handleSaveRoom(alert); closeModal(); }}
                >Save
                </Button>
            </div>
        </div>
    );
};
