import React, { useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { BooleanInput, KeywordsInput } from '../../Atoms';
import { NarrativeVersionPanel } from './NarrativeVersionPanel';

type DataProps = {
    id: string,
    keywords: string[],
    boolean?: string,
    threat?: string,
    query_type: string
}

type SelectedVersionProps = {
    data: DataProps[],
    versionId: string,
    narrativeTitle?: string
}

type NarrativeVersionSelectorProps = {
    currentNarrative: {versionId: string},
    narrativeVersions: SelectedVersionProps[],
    selectedVersion: SelectedVersionProps,
    setSelectedVersion: (e: {versionId: string}) => void,
    getNarrativeVersions: () => void,
    page: number,
    itemsPerPage: number,
    setPage: (e: number) => void
}
export const NarrativeVersionSelector = ({
    currentNarrative,
    narrativeVersions,
    selectedVersion,
    setSelectedVersion,
    getNarrativeVersions,
    page,
    itemsPerPage,
    setPage
}: NarrativeVersionSelectorProps) => {
    const [selectedIndex, setSelectedIndex] = useState(narrativeVersions.findIndex(
        version => version.versionId === selectedVersion.versionId
    ));
    const [selectedTopic, setSelectedTopic] = useState(selectedVersion?.data[0]);
    const [selectedKeywords, setSelectedKeywords] = useState(selectedVersion?.data[0]?.keywords);
    const [selectedBoolean, setSelectedBoolean] = useState(selectedVersion?.data[0]?.boolean);

    const selectVersion = (narrative: SelectedVersionProps) => {
        setSelectedVersion(narrative);
        setSelectedKeywords(narrative.data[0].keywords);
        setSelectedBoolean(narrative.data[0].boolean);
        setSelectedIndex(narrativeVersions.findIndex(version => version.versionId === narrative.versionId));
    };

    const goToNextVersion = () => {
        const nextIndex = selectedIndex + 1;
        setSelectedVersion(narrativeVersions[nextIndex]);
        setSelectedIndex(nextIndex);
        if (nextIndex >= page * itemsPerPage + itemsPerPage) {
            setPage(page + 1);
        }
    };

    const goToPreviousVersion = () => {
        const previousIndex = selectedIndex - 1;
        setSelectedVersion(narrativeVersions[previousIndex]);
        setSelectedIndex(previousIndex);
        if (previousIndex < page * itemsPerPage) {
            setPage(page - 1);
        }
    };

    const versionsToDisplay = narrativeVersions.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

    return (
        <Row color="primary" className="bg-white border h-100 m-0" data-testid="version-selector">
            <Col className="border-right p-4 h-100" lg={4}>
                <h3 className="font-weight-bold m-0 pb-2">Narrative Versions</h3>
                <div className="overflow-auto h-100 pb-2">
                    {versionsToDisplay?.map(narrative => (
                        <NarrativeVersionPanel key={narrative.versionId || uuid()}
                            narrative={narrative}
                            selectVersion={selectVersion}
                            selected={narrative.versionId === selectedVersion.versionId}
                            latest={narrative.versionId === currentNarrative.versionId}
                            isCurrentNarrative={narrative.versionId === currentNarrative.versionId}
                            getNarrativeVersions={getNarrativeVersions}
                            goToPreviousVersion={goToPreviousVersion}
                            goToNextVersion={goToNextVersion}
                            selectedIndex={selectedIndex}
                        />
                    ))}
                </div>
            </Col>
            <Col className="p-4 h-100" lg={8} data-testid="version-viewer">
                <h3 className="font-weight-bold m-0 pb-2">Select a topic</h3>
                <div>
                    {selectedVersion.data.map(topic => (
                        <div key={topic.id}
                            className={`topic mb-1 mr-1 ${selectedTopic?.threat === topic.threat ? 'selected' : ''} cursor-pointer`}
                            onClick={() => { setSelectedTopic(topic);
                                setSelectedKeywords(topic.keywords);
                                setSelectedBoolean(topic.boolean); }}
                        >{topic.threat}
                        </div>
                    ))}
                </div>
                <div className="border rounded query-version-container">
                    <div className="d-flex justify-content-between align-items-center bg-light p-1">
                        <span className="ml-1 font-weight-bold">{selectedVersion.narrativeTitle}</span>
                        <div>
                            <Button color="primary" onClick={() => goToPreviousVersion()} disabled={selectedIndex === 0}>Previous</Button>
                            <Button className="ml-2"
                                color="primary"
                                onClick={() => goToNextVersion()}
                                disabled={typeof narrativeVersions[selectedIndex + 1] === 'undefined'}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                    <div className="query-version-display">
                        {selectedVersion.data[0].query_type === 'keywords'
                            ? (
                                <KeywordsInput keywords={selectedKeywords} disabled />
                            )
                            : (
                                <BooleanInput placeholder="Please select a Boolean version."
                                    booleanQuery={selectedBoolean}
                                    disabled
                                />
                            )}

                    </div>
                </div>
            </Col>
        </Row>
    ); };
