import React from 'react';
import { SourcesList } from './SourcesList';

type SourcesListProps = {
    source: string
    contentIds: string[]
}

type TopSourcesProps = {
    topSources: [SourcesListProps]
}

export const TopSources = ({ topSources }: TopSourcesProps) => (
    <div className="bg-white p-2 maxh-420 scrollbar-small overflow-y-auto overflow-x-hidden">
        <div className="subClusterList">
            <div className="d-flex justify-content-between align-items-center p-2">
                <p className="w-75 font-weight-bold m-0">Sources</p>
                <p className="w-25 font-weight-bold m-0">Contents</p>
            </div>
            <hr className="set-underline-props" />
            {
                topSources.map((sourceData: SourcesListProps) => (
                    <SourcesList key={sourceData.source}
                        source={sourceData.source}
                        contentIds={sourceData.contentIds}
                    />
                ))
            }
        </div>
    </div>
);
