import * as FeatherIcon from 'react-feather';

type RoutesLinksProps = {
    path: string;
    id?: string;
    name: string;
    icon?: any;
    header?: string;
}[];

type RoutesProps = {
    loggedOut: LoggedOutProps
    loggedIn: LoggedInProps
    narratives: NarrativesProps
    naratives: NarativesProps
    situationRoom: SituationRoomProps
    admin: AdminProps
}

type LoggedOutProps = {
    login: string
    ssoAuth: string
    userDeleted: string
    resetPassword: string
    signUpInvite: string
}

type LoggedInProps = {
    base: string
    teams: string
    team: string
    profile: string
    signUpInvite: string
    situationRooms: string
    situationRoomCreate: string
    situationRoomDraftCreate: string
    situationRoomDraftEdit: string
    situationRoomEdit: string
    createCustomNarrative: string
    manageSavedFilters: string
}

type NarrativesProps = {
    base: string
    dashboard: string
    drive: string
    performance: string
    keyLocations: string
    performers: string
    comparison: string
    compare: string
    savedComparison: string
}

type NarativesProps = {
    base: string
    emergingNarrative: string
    trackingNarrative: string
    savedComparison: string
}

type SituationRoomProps = {
    base: string
    overview: string
    matches: string
    integrations: string
    investigations: string
    threats: string
    threat: string
    match: string
    customNarrative: string
    explore: string
    factChecks: string
    factCheck: string
    settings: string
    reportsCreate: string
    reports: string
    watchlist: string
    reportsSaved: string
    narratives: string
    naratives: string
    subClusters: string
    protestMonitoringExperiment: string
    aiClustering: string
    networks: string
}

type AdminProps = {
    base: string
    instances: string
    instance: string
    users: string
    allUsers: string
    allUsersUser: string
    investigations: string
    investigation: string
    situationRooms: string
    situationRoom: string
    cTypes: string
    cType: string
}

export const routes: RoutesProps = {
    loggedOut: {
        login: '/login',
        ssoAuth: '/sso-auth',
        userDeleted: '/user-deleted',
        resetPassword: '/reset-password',
        signUpInvite: '/signup/:invite'
    },
    loggedIn: {
        base: '/',
        teams: '/team',
        team: '/team/:uid',
        profile: '/profile',
        signUpInvite: '/signup/:invite',
        situationRooms: '/situation-rooms',
        situationRoomCreate: '/situation-room/create-room',
        situationRoomDraftCreate: '/situation-room/create-room/:draftId',
        situationRoomDraftEdit: '/situation-room/edit-draft-room/:draftId',
        situationRoomEdit: '/situation-room/edit-room/:roomId',
        createCustomNarrative: '/situation-room/:roomId/create-custom-narrative',
        manageSavedFilters: '/situation-room/:roomId/manage-saved-filters',
    },
    narratives: {
        base: '/situation-rooms/:roomId/narratives',
        dashboard: '/situation-rooms/:roomId/narratives/:id/dashboard',
        drive: '/situation-rooms/:roomId/narratives/:id/dashboard/what-is-driving-the-narrative',
        performance: '/situation-rooms/:roomId/narratives/:id/dashboard/how-is-the-narrative-performing',
        keyLocations: '/situation-rooms/:roomId/narratives/:id/dashboard/key-locations',
        performers: '/situation-rooms/:roomId/narratives/:id/dashboard/who-are-the-narrative-performers',
        comparison: '/situation-rooms/:roomId/narratives/compare',
        compare: '/situation-rooms/:roomId/narratives/saved-comparisons/:id',
        savedComparison: '/situation-rooms/:roomId/narratives/saved-comparisons',
    },
    naratives: {
        base: '/situation-rooms/:situation/naratives',
        emergingNarrative: '/situation-rooms/:situation/naratives/emerging-narratives/:id',
        trackingNarrative: '/situation-rooms/:situation/naratives/tracking-narratives/:id',
        savedComparison: '/situation-rooms/:situation/naratives/saved-comparisons/:id'
    },
    situationRoom: {
        base: '/situation-rooms/:situation',
        overview: '/situation-rooms/:situation/overview',
        matches: '/situation-rooms/:situation/overview/matches',
        integrations: '/situation-rooms/:situation/integrations',
        investigations: '/situation-rooms/:situation/investigations',
        threats: '/situation-rooms/:situation/threats',
        threat: '/situation-rooms/:situation/threats/:threat',
        match: '/situation-rooms/:situation/matches/:match',
        customNarrative: '/situation-rooms/:situation/custom-narrative/:narrative',
        explore: '/situation-rooms/:situation/explore',
        factChecks: '/situation-rooms/:situation/fact-checks',
        factCheck: '/situation-rooms/:situation/fact-checks/:factcheck',
        settings: '/situation-rooms/:situation/settings',
        reportsCreate: '/situation-rooms/:situation/report/create-report',
        reports: '/situation-rooms/:situation/reports',
        watchlist: '/situation-rooms/:situation/watchlist',
        reportsSaved: '/situation-rooms/:situation/report',
        narratives: '/situation-rooms/:situation/narratives',
        naratives: '/situation-rooms/:situation/naratives',
        aiClustering: '/situation-rooms/:situation/ai-clustering',
        subClusters: '/situation-rooms/:situation/topic-insights/:name',
        protestMonitoringExperiment: '/situation-rooms/:situation/experiments/protest-monitoring',
        networks: '/situation-rooms/:situation/networks'
    },
    admin: {
        base: '/admin',
        instances: '/admin/instances',
        instance: '/admin/instances/:id',
        users: '/admin/users',
        allUsers: '/admin/allusers',
        allUsersUser: '/admin/allusers/:id',
        investigations: '/admin/investigations',
        investigation: '/admin/investigation/:id',
        situationRooms: '/admin/situation-rooms',
        situationRoom: '/admin/situation-rooms/:id',
        cTypes: '/admin/ctypes',
        cType: '/admin/ctypes/:id'
    }
};
export const topBarRoutesLinks: RoutesLinksProps = [
    {
        path: '/situation-rooms',
        name: 'Situation Rooms',
    },
    {
        path: '/team',
        name: 'Team',
    }
];
export const sidebarRoutesLinks: RoutesLinksProps = [
    {
        path: '/overview',
        id: 'overview',
        name: 'Overview',
        icon: FeatherIcon.Compass,
        header: 'Overview',
    },
    {
        path: '/threats',
        name: 'Threats',
        id: 'threats',
        icon: FeatherIcon.AlertTriangle,
    },
    {
        path: '/narratives',
        name: 'Narratives',
        id: 'narratives',
        icon: FeatherIcon.Airplay,
    },
    {
        path: '/ai-clustering',
        name: 'AI Clustering',
        id: 'aiClustering',
        icon: FeatherIcon.Codesandbox,
    },
    {
        path: '/explore',
        name: 'Explore',
        id: 'explore',
        icon: FeatherIcon.Zap,
    },
    {
        path: '/networks',
        name: 'Networks',
        id: 'networks',
        icon: FeatherIcon.Share2,
    },
    {
        path: '/fact-checks',
        name: 'Fact Checks',
        id: 'logicallyFactChecks',
        icon: FeatherIcon.CheckCircle,
    }
];
export const integrationsRoutesLinks: RoutesLinksProps = [
    {
        path: '/investigations',
        name: 'Investigations',
        id: 'investigations',
        icon: FeatherIcon.Eye,
    },
    {
        path: '/report',
        name: 'Reports',
        id: 'reports',
        icon: FeatherIcon.FileText,
    },
    {
        path: '/watchlist',
        name: 'Watchlist',
        id: 'watchlist',
        icon: FeatherIcon.Star,
    }
];
export const sandboxRoutesLinks: RoutesLinksProps = [
    {
        path: '/experiments/protest-monitoring',
        name: 'Protest Monitoring',
        id: 'protestMonitoring',
        icon: FeatherIcon.Calendar,
    }
];
export const configureRoutesLinks: RoutesLinksProps = [
    {
        path: '/settings',
        name: 'Room Settings',
        id: 'roomSettings',
        icon: FeatherIcon.Sliders,
    }
];
export const adminRoutesLinks: RoutesLinksProps = [
    {
        path: '/admin',
        name: 'Admin dashboard',
    }
];

export const adminSidebarRoutes: RoutesLinksProps = [
    {
        path: '/admin/instances',
        name: 'Instances',
        id: 'instances',
        icon: FeatherIcon.Layout
    },
    {
        path: '/admin/allusers',
        name: 'Users',
        id: 'users',
        icon: FeatherIcon.Users
    },
    {
        path: '/admin/users',
        name: 'Super admin users',
        id: 'superAdminUsers',
        icon: FeatherIcon.UserPlus
    },
    {
        path: '/admin/situation-rooms',
        name: 'Situation rooms',
        id: 'situationRooms',
        icon: FeatherIcon.Grid
    },
    {
        path: '/admin/investigations',
        name: 'Investigations',
        id: 'investigations',
        icon: FeatherIcon.Eye
    },
    {
        path: '/admin/ctypes',
        name: 'Source mapping',
        id: 'sourceMapping',
        icon: FeatherIcon.Anchor
    }
];
