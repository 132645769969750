import { createSlice } from '@reduxjs/toolkit';
import { createEditorState } from '../../pages/Room/Reports/CreateReport/AddData/createEditorState';

export const defaultReportData = {
    name: '',
    template: '',
    filters: {},
    pages: [
        {
            id: '1',
            type: 'cover',
            heading: createEditorState({ blocks: [], entityMap: {} }),
            subtitle: createEditorState({ blocks: [], entityMap: {} }),
            footer: createEditorState({ blocks: [], entityMap: {} }),
            date: '',
            logo: '',
            clientLogo: ''
        },
        {
            id: '2',
            type: 'overview',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Overview'
                    }
                ],
                entityMap: {}
            }),
            blocks: []
        },
        {
            id: '3',
            type: 'summary',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Summary'
                    }
                ],
                entityMap: {}
            }),
            bodyText: createEditorState({ blocks: [], entityMap: {} }),
        },
        {
            id: '4',
            type: 'threats',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Top Threats'
                    }
                ],
                entityMap: {}
            }),
            blocks: []
        },
        {
            id: '5',
            type: 'narratives',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Top Narratives'
                    }
                ],
                entityMap: {}
            }),
            blocks: []
        },
        {
            id: '6',
            type: 'customNarratives',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Top Custom Narratives'
                    }
                ],
                entityMap: {}
            }),
            blocks: []
        },
        {
            id: '7',
            type: 'threatsWatchlist',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Watchlist - Threats'
                    }
                ],
                entityMap: {}
            }),
            blocks: []
        },
        {
            id: '8',
            type: 'narrativesWatchlist',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Watchlist - Narratives'
                    }
                ],
                entityMap: {}
            }),
            blocks: []
        },
        {
            id: '9',
            type: 'map',
            pageTitle: createEditorState({
                blocks: [
                    {
                        text: 'Map'
                    }
                ],
                entityMap: {}
            }),
        }
    ]
};

export const CREATE_REPORT_TABS = {
    VISUALISE: 'visualise',
    EXPORT: 'export'
};

export const initialState = {
    report: defaultReportData,
    latestTool: {},
    toolbar: {
        color: '#000000',
        align: 'left'
    },
    activeTab: CREATE_REPORT_TABS.VISUALISE
};

const createReportSlice = createSlice({
    name: 'createReport',
    initialState,
    reducers: {
        setReportAction: (state, { payload }) => ({
            ...state,
            report: {
                ...state.report,
                ...payload
            }
        }),
        updatePageAction: (state, { payload }) => ({
            ...state,
            report: {
                ...state.report,
                pages: state.report.pages.map(page => {
                    if (page.id === payload.id) {
                        return { ...page, ...payload.updates };
                    }
                    return page;
                })
            }
        }),
        setToolbarAction: (state, { payload }) => ({
            ...state,
            toolbar: { ...state.toolbar, ...payload },
            latestTool: payload
        }),
        setActiveTabAction: (state, { payload }) => ({
            ...state,
            activeTab: payload
        }),
        resetReportAction: () => initialState
    }
});

export const { setReportAction, updatePageAction, setToolbarAction, resetReportAction, setActiveTabAction } = createReportSlice.actions;
export const createReport = createReportSlice.reducer;
