import React, { useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';

type SearchInputControlledProps = {
    placeholder: string,
    handleSearch: (e: string) => void
}

export const SearchInputControlled = ({
    placeholder,
    handleSearch
}: SearchInputControlledProps) => {
    const [value, onChange] = useState('');
    return (
        <form className="mr-2 d-block"
            onSubmit={(e) => {
                e.preventDefault();
                handleSearch(value);
            }}
        >
            <InputGroup border={value ? 'active' : 'none'}>
                <Input value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder || 'Search - Enter a search term to update the room overview and press ENTER'}
                    data-testid="search-input"
                />
                <InputGroupAddon addonType="prepend">
                    <InputGroupText role="button" onClick={() => handleSearch(value)}>
                        <FeatherIcon.Search size={16} color="#006FF9" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </form>
    );
};
