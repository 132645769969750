import { gql, useLazyQuery } from '@apollo/client';

export const getNarrativeVersionsQuery = (narrativeId) => ({
    query: NARRATIVE_VERSIONS,
    variables: { narrativeId }
});

export const useNarrativeVersions = (narrativeId) => {
    const { query, variables } = getNarrativeVersionsQuery(narrativeId);
    const [getNarrativeVersions, { data, loading, error }] = useLazyQuery(query, {
        variables,
        fetchPolicy: 'no-cache',
    });

    return { getNarrativeVersions, data, loading, error };
};

export const NARRATIVE_VERSIONS = gql`
    query getNarrativeVersions ($narrativeId: ID){
        getNarrativeVersions (narrativeId: $narrativeId) {
            id
            narrativeId
            situationRoom
            data {
                id
                keywords
                threat
                boolean
                query_type
                dslQuery
            }
            narrativeTitle
            notes
            versionId
            updatedAt
            user{
                id
                displayName
            }
        }
    }
`;
