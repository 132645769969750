import moment from 'moment';
import React from 'react';
import { LinkModal } from '../../../../../components/LinkModal';
import { Loading } from '../../../../../components/Loading';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { convertToDate } from '../../../../../utils/getTimeFrom';
import { truncateString } from '../../../../../utils/text';
import { EmptyState } from '../../Dashboard/EmptyState';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const RelatedPost = ({ relatedPostCount, relatedContentLoading, translatedRelatedPost }) => {
    const { setModal } = useModal();
    return (
        <>
            <h3 className="pl-2 m-0">Related posts ({relatedPostCount})</h3>
            {relatedContentLoading ? <Loading relative height={250} />
                : (
                    <div className="flex-1 border-top position-relative mt-2 ">
                        {relatedPostCount === 0
                            ? (
                                <div className="mt-10">
                                    <EmptyState />
                                </div>
                            ) : (
                                <div className="scrollbar-small set-max-height position-absolute h-100 w-100">
                                    {translatedRelatedPost?.map((content, index) => (
                                        <div key={content?.id}
                                            className={`scrollbar-small p-2 
                                        ${index !== translatedRelatedPost.length - 1 && 'border-bottom'}`}
                                        >
                                            <p>
                                                <strong>
                                                    {getPostedByName(content.from, content.displayName)}
                                                </strong>{' '}
                                                | Posted on{' '}
                                                {moment(convertToDate(content?.pubdatetime)).format('DD MMM YYYY')}
                                            </p>
                                            <p>
                                                {truncateString(content?.text, 220)}
                                                <span className="ml-1">
                                                    <a href={content.url}
                                                        target="_blank"
                                                        id={`opItem-${content?.id}`}
                                                        rel="noreferrer"
                                                        onClick={(e) => {
                                                            if (!localStorage.getItem('externalLinks')) {
                                                                e.preventDefault();
                                                                setModal({
                                                                    header: false,
                                                                    component: <LinkModal link={content.url} />
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {content?.text.length > 220 ? 'View source' : '...View source'}
                                                    </a>
                                                </span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                    </div>
                )}
        </>
    );
};
