import React, { useState } from 'react';
import { Table, Row, Col } from 'reactstrap';
import { useLazyQuery } from '@apollo/client';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { capitalizeFirstLetter, getCtype } from '../../../../../utils/text';
import { UserDetails } from './UserDetails';
import { EmptyState } from '../../Dashboard/EmptyState';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';
import { useContent } from '../../../../../services/Content/getContent';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';

export const Boosters = ({ data }) => {
    const { data: boosterData } = useContent({
        filters: {
            ids: data?.boosters
        },
        skipRequest: !data?.boosters,
        name: 'getNarrativeBoosters',
        fragments: ['Metadata', 'Document', 'Annotations']
    });
    const { setModal } = useModal();
    const [isTranslated, setIsTranslated] = useState(false);

    const handleClick = (item) => {
        setModal({
            header: false,
            component: <UserDetails boosters={item} />
        });
    };
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: data?.narrative_title, target: 'en' }
        },
    });
    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : data?.narrative_title;
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };
    return (
        <>
            <p className="mt-2 mb-0">
                A list of all the people we could determine that are driving your
                Narrative forward with a decent amount of content generated.
            </p>
            <h3 className="mt-4 sentence-format">{isTranslated ? outputText : data?.narrative_title}
                {data?.lang_verbose === 'English' ? null : (
                    <span role="button"
                        className="ml-2"
                        onClick={(e) => translation(e)}
                    >
                        <TranslateIcon color="#006FF9" size={30} />
                    </span>
                )}
            </h3>
            {boosterData.length > 0
                ? (
                    <Table className="mt-4 mb-0">
                        <thead>
                            <tr>
                                <th>Boosters ({boosterData.length})</th>
                                <th>
                                    Platform
                                </th>
                                <th>
                                    Engagement
                                </th>
                                <th>
                                    Sentiment
                                </th>
                                <th>
                                    Date boosted
                                </th>
                                <th>
                                    Location
                                </th>
                                <th>
                                    Gender
                                </th>
                                <th>Age group</th>
                            </tr>
                        </thead>
                        <tbody>
                            {boosterData.map((item) => {
                                const { age, gender } = item.demographics || {};
                                const ageRange = age
                                    ? age && Object.keys(age).reduce((a, b) => (age[a] > age[b] ? a : b))
                                    : 'No data found';
                                const bGender = gender ? `${gender.male > gender.female ? 'Male ' : 'Female'}` : 'No data found';
                                return (
                                    <tr key={item.id}>
                                        <td className="text-primary cursor-pointer"
                                            onClick={() => handleClick(item)}
                                        >
                                            {item?.displayName}
                                        </td>
                                        <td>
                                            <SourceIconLabel data={{ contentType: item.contentType }} className=" d-inline" />
                                            <p className="d-inline">{getCtype(item?.contentType).name}
                                            </p>
                                        </td>
                                        <td>{item?.engagement}</td>
                                        <td>{capitalizeFirstLetter(item?.sentiment)}</td>
                                        <td>{item?.date}</td>
                                        <td>{item?.locationsDetected}</td>
                                        <td>{bGender}</td>
                                        <td>{ageRange}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <Row className="bg-white mr-0 ml-0">
                        <Col className="p-3" xs={12}>
                            <div className="pt-2">
                                <EmptyState />
                            </div>
                        </Col>
                    </Row>
                )}
        </>
    );
};
