import React, { useState, useEffect } from 'react';
import { CustomInput, FormGroup } from 'reactstrap';

export const SELECTED_CONST = {
    all_selected: 'All selected',
    partially_selected: 'Partially selected',
    none_selected: 'None selected'
};

type OptionProps = {
    id: string,
    text: string
}

type GetStateParams = {
    values?: any[];
    options?: OptionProps[];
    selectAllReversed?: boolean;
    disabledOptions?: OptionProps[];
    maxCount?: number;
};

export const getState = ({ values = [], options = [],
    selectAllReversed = false, disabledOptions = [], maxCount = 0 }: GetStateParams = {}) => {
    if (selectAllReversed) {
        if (values.length === 0) return SELECTED_CONST.all_selected;
        if (values.includes('CTA')) {
            if (values.length === options.length + 1) return SELECTED_CONST.none_selected;
        } else if (values.length === options.length) return SELECTED_CONST.none_selected;
        return SELECTED_CONST.partially_selected;
    }

    if (values.length === 0) return SELECTED_CONST.none_selected;
    if (values.length === options.length && disabledOptions.length === 0) {
        return SELECTED_CONST.all_selected;
    }
    if (maxCount > 0 && maxCount === values.length) {
        return SELECTED_CONST.all_selected;
    }
    if (disabledOptions && values.length === options.length - disabledOptions.length) {
        return SELECTED_CONST.all_selected;
    }

    return SELECTED_CONST.partially_selected;
};

type SelectAllCheckBoxProps = {
    id: string,
    allCheck: (e: OptionProps[]) => void,
    disabledOptions?: OptionProps[],
    children?: React.ReactNode;
    options: OptionProps[],
    values: any[],
    displayLabel?: boolean,
    selectAllReversed?: boolean,
    maxCount?: number,
    page?: string,
    disabled?: boolean
}

export const SelectAllCheckBox = ({
    id,
    allCheck,
    disabledOptions = [],
    children,
    options = [],
    values = [],
    displayLabel = true,
    selectAllReversed = false,
    maxCount = 0,
    page,
    disabled
}: SelectAllCheckBoxProps) => {
    const [allSelected, setAllSelected] = useState(SELECTED_CONST.none_selected);
    const handleSelectAll = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        const selectableOptions = options.filter(option => disabledOptions.indexOf(option) === -1);
        if (selectAllReversed) {
            return allCheck(checked ? [] : selectableOptions);
        }

        return allCheck(checked ? selectableOptions : []);
    };

    useEffect(() => {
        setAllSelected(getState({ values, options, selectAllReversed, disabledOptions, maxCount }));
    }, [values, options, disabledOptions, selectAllReversed, maxCount]);

    useEffect(() => {
        const element = document.getElementById(id) as HTMLInputElement;
        if (element) {
            element.indeterminate = allSelected === SELECTED_CONST.partially_selected;
        }
    }, [allSelected, id]);

    return (
        <>
            <FormGroup className={`${displayLabel && ''} ${page === 'content-table' ? 'mt-1n' : ''} `}>
                <CustomInput tag="checkbox"
                    type="checkbox"
                    checked={allSelected === SELECTED_CONST.all_selected}
                    id={id}
                    className="custom-control-input-big"
                    label={displayLabel ? allSelected : ''}
                    onChange={handleSelectAll}
                    data-testid="checkbox-input"
                    disabled={disabled}
                />
            </FormGroup>

            {children}
        </>
    );
};
