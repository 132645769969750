import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';

export const UploadFile = ({ setFile, filename }) => {
    const removeFile = (e) => {
        e.preventDefault();
        setFile('');
    };
    const handleFile = (e) => {
        setFile(e.target?.files[0]);
    };

    return (
        <div className="mb-2">
            <Label>
                {filename && (
                    <span className="mr-1">
                        <FeatherIcon.Check className="mr-11" color="#006FF9" />
                        {filename} <FeatherIcon.X size={14}
                            className="text-danger cursor-pointer"
                            onClick={(e) => removeFile(e)}
                            data-testid="remove-upload"
                        />
                    </span>
                )}
            </Label>
            <div className="upload-btn-wrapper position-relative d-inline-block">
                <Button color="primary">Upload file</Button>
                <Input type="file" accept=".pdf" name="myfile" onChange={handleFile} data-testid="upload-file" />
            </div>
        </div>
    );
};
