import { convertToMarkers } from '../convertToMarkers';

export const supportedShorthandTags = ['#'];

export const emptyShorthandTagsValidator = (query, queryByLines, lineLengths) => {
    const highlight = [];

    if (!query || query.trim().length === 0) {
        return {
            isValid: true,
            markers: []
        };
    }

    const emptyShorthandTags = [...query.matchAll(new RegExp(`[${supportedShorthandTags.join('')}]((\\s|$)+)`, 'gm'))];

    if (!emptyShorthandTags) {
        return {
            isValid: true,
            markers: []
        };
    }

    emptyShorthandTags.forEach((match) => {
        const tagContent = match[0].replaceAll(new RegExp(`[${supportedShorthandTags.join('')} ]`, 'gm'), '') || '';
        if (tagContent.length === 0 || !tagContent) {
            highlight.push([match.index, match.index + match[0].length]);
        }
    });

    return highlight.length === 0 ? {
        isValid: true,
        markers: []
    } : {
        isValid: false,
        markers: convertToMarkers(
            highlight,
            queryByLines,
            lineLengths,
            'You have empty short tag'
        )
    };
};
