import React, { useState } from 'react';
import { useRouteMatch, useLocation, useHistory, Link } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { useQuery, useLazyQuery } from '@apollo/client';
import { NarrativeLocations } from './NarrativeLocations';
import { NarrativePerformance } from './NarrativePerformance';
import { NarrativeDrivingContent } from './NarrativeDrivingContent';
import { NarrativePerformers } from './NarrativePerformers';
import { AddToWatchlist } from '../Overview/Narratives/AddToWatchlist';
import { DateRangePicker } from '../DateRangePicker';
import { Loading } from '../../../../components/Loading';
import { TranslateIconBlue } from '../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION, GET_NARRATIVES } from '../../../../graphql';
import { sortArrays } from './DrivingNarratives/Keywords';
import { RemoveContentModal } from '../../../../components/CommonModals/RemoveContent';
import { useDropDown } from '../../../../utils/useDropdown';
import { elasticFilterWithProjectId } from '../../../../utils/elasticFilterWithProjectId';
import { getRoom } from '../../../../utils/variables';
import { useModal } from '../../../../context/modal/ModalComponent';
import { Alert } from '../../../../components/Atoms';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { useContent } from '../../../../services/Content/getContent';

export const NarrativeDashboard = () => {
    const { setModal } = useModal();
    const location = useLocation();
    const room = getRoom();
    const [dropdown, toggleDropdown] = useDropDown();
    const { newPage } = location?.state || {};
    const [dateData, setDateData] = useState({});
    const [isTranslated, setIsTranslated] = useState(false);
    const match = useRouteMatch();
    const history = useHistory();
    const narrativeId = match.params.id;
    const { data: nData, loading } = useQuery(GET_NARRATIVES, {
        variables: {
            query: elasticFilterWithProjectId([{
                ids: {
                    values: narrativeId
                }
            }])
        },
        fetchPolicy: 'no-cache',
    });
    const narrativeData = nData?.newNarrativeServiceSearch?.hits?.map((a) => ({
        source: a?._source,
    }));
    const handleRemoveNarrativeError = () => {
        setModal({
            header: 'Failed to remove content',
            component: <p>Failed to remove content, please try again later</p>
        });
    };
    const setRemoveContentModal = () => {
        setModal({
            modal: <RemoveContentModal removeData={[{ id: nData?.newNarrativeServiceSearch?.hits[0]?._id }]}
                type={CONTENT_TYPE.NARRATIVE}
                onCompleted={() => {
                    history.push(`/situation-rooms/${room?.id}/narratives`);
                }}
                onError={handleRemoveNarrativeError}
            />
        });
    };

    const contentDocs = narrativeData ? narrativeData[0]?.source?.content_docs : [];
    const keyPhraseTable = narrativeData ? narrativeData[0]?.source?.keyphrase_table : {};
    const sortedKeyphraseData = sortArrays([
        keyPhraseTable?.matches || [],
        keyPhraseTable?.change || [],
        keyPhraseTable?.terms || []
    ]);
    const sortedKephrasesByMatches = {
        matches: sortedKeyphraseData[0],
        change: sortedKeyphraseData[1],
        terms: sortedKeyphraseData[2]
    };

    const topKeyphrases = sortedKephrasesByMatches.terms.slice(0, 5);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: [{ text: narrativeData && narrativeData[0]?.source?.narrative_title, target: 'en' },
                ...(topKeyphrases.map((item) => ({
                    text: item,
                    target: 'en'
                })))
            ]
        },
    });

    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0]
        : narrativeData && narrativeData[0].source.narrative_title;
    const newData = {
        ...narrativeData && narrativeData[0]?.source,
        content_docs: translatedData && isTranslated
            ? contentDocs?.map((item, i) => ({
                ...item, text: translatedData.translateMultipleText[i + topKeyphrases.length + 3]?.translations[0]
            }))
            : contentDocs
    };

    const { data: contentData, loading: contentLoading, count } = useContent({
        filters: {
            ids: newData.content_ids
        },
        limit: 5000,
        name: 'getNarrativeContent',
        fragments: ['Metadata', 'Document', 'Engagement']
    });

    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };
    if (loading) return <Loading gif relative height={500} />;
    return (
        <div className="p-0">
            {location?.state?.newPage ? (
                <Link to={{ pathname: `/situation-rooms/${window.location.pathname.split('/')[2]}/narratives`,
                    state: { narrativePage: `${newPage}` } }}
                >
                    <Button className="go-back-from-dashboard dashboard-back-button"
                        color="link"
                    >
                        <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                        <span>Back</span>
                    </Button>
                </Link>
            ) : (
                <Button className="go-back-from-dashboard dashboard-back-button"
                    color="link"
                    onClick={() => history.goBack()}
                >
                    <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                    <span>Back</span>
                </Button>
            )}
            {(newData?.content_ids?.length === 0 || (!contentLoading && count === 0)) && (
                <div className="mt-3"><Alert errorMessage="All the contents of this Narrative has been deleted/removed/updated,
            there is no more content in this Narrative."
                />
                </div>
            )}
            <div className="p-0 m-0 mt-4 d-flex justify-content-between align-items-center">
                <h2 className="m-0 p-0">Dashboard</h2>
                <div className="d-flex align-items-center ml-1">
                    <div className="text-primary w-100 cursor-pointer align-dashboard-translation-icon" onClick={(e) => translation(e)}>
                        {narrativeData && narrativeData[0].source.lang_verbose === 'English'
                            ? null
                            : (
                                <span role="button"
                                    className="ml-2"
                                >
                                    <TranslateIconBlue color="#006FF9" size={30} />Translate content
                                </span>
                            )}
                    </div>
                    <div className="set-min-width ml-2">
                        <AddToWatchlist narrativeId={narrativeId} />
                    </div>
                    <div className="d-flex w-100 align-date-range ml-2 mt-1">
                        <p className="w-40">Date range</p>
                        <DateRangePicker setDateData={setDateData} />
                    </div>
                    <div>
                        <Dropdown onClick={(e) => { e.preventDefault(); }}
                            className="d-inline ml-1"
                            isOpen={dropdown}
                            toggle={toggleDropdown}
                            direction="left"
                            data-testid="narrative-dashboard-actions"
                        >
                            <DropdownToggle className="bg-transparent border-0 set-dropdown-width">
                                <span>
                                    <i className="fa fa-ellipsis-v" />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-2">
                                <DropdownItem>
                                    <span className="text-danger" onClick={setRemoveContentModal}>
                                        Delete narrative
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            {!loading && narrativeData?.length > 0 ? (
                <>
                    <div className="p-0 m-0 mt-3 d-flex justify-content-between align-items-center">
                        <h3 className="m-0 sentence-format">{isTranslated ? outputText
                            : narrativeData && narrativeData[0].source.narrative_title}
                        </h3>
                    </div>
                    <div className="m-0 p-0 mt-3 d-flex flex-column">
                        <p><strong className="m-0">Why is this a narrative?</strong></p>
                        <p className="m-0 pt-1">
                            A lot of content has been generated that relates to the topics in
                            your situation room, which several people are spreading the
                            stories about.
                        </p>
                    </div>
                    <Row className="m-0 mt-4 p-0">
                        <Col sm={10} md={6} className="pl-0">
                            <NarrativePerformance contentLoading={contentLoading} data={contentData} pData={newData} dateRange={dateData} />
                        </Col>
                        <Col sm={10} md={6} className="pr-0">
                            <NarrativeLocations contentLoading={contentLoading}
                                lData={newData}
                                isEmptyNarrative={newData?.content_ids?.length === 0 || (!contentLoading && count === 0)}
                            />
                        </Col>
                    </Row>
                    <Row className="m-0 mb-2 mt-4 p-0">
                        <Col sm={10} md={6} className="pl-0">
                            <NarrativeDrivingContent contentLoading={contentLoading}
                                dcData={newData}
                                isEmptyNarrative={newData?.content_ids?.length === 0 || (!contentLoading && count === 0)}
                            />
                        </Col>
                        <Col sm={10} md={6} className="pr-0">
                            <NarrativePerformers contentLoading={contentLoading}
                                npData={newData}
                                dateRange={dateData}
                                isEmptyNarrative={newData?.content_ids?.length === 0 || (!contentLoading && count === 0)}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <div className="minh-200 position-relative">
                    <Loading />
                </div>
            )}
        </div>
    );
};
