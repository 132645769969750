/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';

type VolumeOverTimeType = {
    time: string
    date: string
    count: number
}

type VolumeOverTimeTypePlotProps = {
    content: [VolumeOverTimeType]
}

export const VolumeOverTimeTypePlot = ({ content }: VolumeOverTimeTypePlotProps) => {
    const contentData = content.map((e: any) => {
        const time = e.time || e.date;
        const date = time.split('-').reverse().join('-');
        return ({
            y: e.count,
            x: new Date(date).getTime()
        }); }).sort((a, b) => a.x - b.x);

    const options = {
        chart: {
            height: 300,
            borderColor: '#B0B4C5',
            borderRadius: 2,
            borderWidth: 1,
            width: 1050
        },
        title: {
            text: null
        },
        xAxis: {
            type: 'datetime',
            labels: {
                enabled: false
            },
            gridLineColor: '#B0B4C5',
            gridLineWidth: 1
        },
        yAxis: {
            title: {
                text: undefined
            },
            gridLineWidth: 0,
            labels: {
                enabled: false
            }
        },
        series: [{
            data: contentData,
            type: 'spline'
        }],
        legend: {
            enabled: false
        },
        tooltip: {
            useHTML: true,
            headerFormat: '',
            shared: true,
            formatter() {
                const { x, y } = this as any;
                return `
            <div class="d-flex justify-content-between" style="min-width: max-content">
                <p class="mr-4">Date<p/>
                ${moment(x).format('DD MMMM YYYY')}
            </div>
            <hr class="m-0"/>
            <div class="d-flex justify-content-between" style="min-width: max-content">
                <p class="mr-4">Contents<p/>
                ${y}
            </div>
        `; }
        },
        color: ['#006FF9']
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};
