import React from 'react';
import lookup from 'country-code-lookup';
import { MapbubbleChart } from '../../../../../components/Highcharts/Mapbubble';

export const KeyLocationsIndicator = ({ current, locationData }) => {
    let filteredMapData = {};
    if (locationData && 'country_code' in locationData[0] && locationData[0].country_code) {
        filteredMapData = locationData?.map(loc => ({
            code3: loc.country_code,
            location: loc.location,
            z: parseInt(lookup.byCountry(loc?.location)?.isoNo, 10),
            count: loc.count
        }));
    }
    else {
        filteredMapData = locationData?.map((a) => ({
            code3: `${lookup.byCountry(a?.location)?.iso3}`,
            z: parseInt(lookup.byCountry(a?.location)?.isoNo, 10),
            code: `${lookup.byCountry(a?.location)?.iso2}`,
            count: `${a.count}`,
        }));
    }
    if (!locationData) return null;

    const seriesData = [
        {
            name: 'Countries',
            color: '#006FF9',
            enableMouseTracking: true,
        },
        {
            type: 'mapbubble',
            color: '#006FF9',
            name: '',
            joinBy: ['iso-a3', 'code3'],
            data: filteredMapData.map((a) => ({
                ...a,
                ...(current
                    ? {
                        color: `rgba(0, 111, 249, ${current === a.location ? '20' : '0.5'
                        })`,
                    }
                    : {}),
            })),
            minSize: 10,
            maxSize: 30,
            valueDecimals: 0,
            tooltip: {
                useHTML: true,
                headerFormat: '',
                pointFormat: 'Location: &nbsp{point.location}<br/>Mentions: &nbsp{point.count}',
            },
        }
    ];
    return (
        <div className="pt-4">
            <MapbubbleChart seriesData={seriesData} />
        </div>
    );
};
