import React, { useState, useRef } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import { Moment } from 'moment';

type DateRangePickerProps = {
    startDate?: Moment | Date
    minDate?: Moment | Date
    maxDate?: Moment | Date
    onCallback: () => void
}

export const DatePicker = ({ startDate, minDate, maxDate, onCallback }: DateRangePickerProps) => {
    const [focus, setFocus] = useState(false);
    const dateRef = useRef(null);

    return (
        <InputGroup border={focus ? 'active' : 'none'} className="d-inline-flex mt-0">
            <Label className="d-flex w-100">
                <ReactDateRangePicker ref={dateRef}
                    onCallback={onCallback}
                    initialSettings={{
                        singleDatePicker: true,
                        startDate,
                        minDate,
                        maxDate
                    }}
                >
                    <input placeholder="Select date range"
                        type="text"
                        className="form-control mw-190"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        data-testid="with-date-input"
                    />
                </ReactDateRangePicker>
                <InputGroupAddon addonType="prepend" className={`small-border cursor-pointer ${focus ? 'border-left' : 'border-left'}`}>
                    <InputGroupText className="bg-white border-left-0"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    >
                        <FeatherIcon.Calendar size={18}
                            color="#006FF9"
                            data-testid="date-picker-single"
                        />
                    </InputGroupText>
                </InputGroupAddon>
            </Label>
        </InputGroup>
    );
};
