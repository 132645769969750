import React from 'react';
import { X } from 'react-feather';
import { Button } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../../utils/variables';
import { PossiblePersons } from './PossiblePersons';

export const AccountModal = ({ from, displayName, ctype }) => {
    const { closeModal } = useModal();
    const room = getRoom();
    const identifiedActorEnrichmentsEnabled = room.instance?.plan?.integrations?.identifiedActorEnrichments;

    const { data, loading } = useQuery(GET_ACCOUNT_ENGAGEMENT, {
        variables: {
            username: from,
            ctype,
            projectId: room.project_id
        }
    });

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="m-0">Account details</h2>
                <X onClick={closeModal} role="button" />
            </div>
            <hr />
            <div className="bg-lightgray p-2">
                <h3 className="mb-1 mt-0">{from}</h3>
                <SourceIconLabel data={{ from, displayName, ctype }} className="d-flex" />
                {!loading && (
                    <>
                        <hr />
                        <p><strong>Engagements:</strong> {data.getAccountEngagement.engagement}</p>
                        <p className="mb-0"><strong>No of posts:</strong> {data.getAccountEngagement.posts}</p>
                    </>
                )}
            </div>
            <hr />
            {identifiedActorEnrichmentsEnabled && (
                <PossiblePersons from={from} displayName={displayName} ctype={ctype} />
            )}
            <Button onClick={closeModal} className="mr-2">Close</Button>
        </div>
    );
};

export const GET_ACCOUNT_ENGAGEMENT = gql`
    query getAccountEngagement(
        $username: String!
        $ctype: String
        $projectId: String!
    ) {
        getAccountEngagement(
            username: $username
            ctype: $ctype
            projectId: $projectId
        ) {
            engagement
            posts
        }
    }
`;
