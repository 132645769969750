import { DATE_RANGE_LABEL, DATE_RANGE_VALUE } from '../getTimeFrom';

const dateFilters = [
    {
        key: DATE_RANGE_VALUE.all,
        label: DATE_RANGE_LABEL.all,
        id: 'filter-time-alltime1'
    },
    {
        key: DATE_RANGE_VALUE.day,
        label: DATE_RANGE_LABEL.day,
        id: 'filter-time-last24hrs1'
    },
    {
        key: DATE_RANGE_VALUE.week,
        label: DATE_RANGE_LABEL.week,
        id: 'filter-time-lastweek1'
    },
    {
        key: DATE_RANGE_VALUE.custom,
        label: DATE_RANGE_LABEL.custom,
        id: 'filter-time-Customdate1'
    }
];

const sentiments = [
    {
        key: 'positive',
        label: 'Positive'
    },
    {
        key: 'neutral',
        label: 'Neutral'
    },
    {
        key: 'negative',
        label: 'Negative'
    }
];

const allTypes = [
    { name: 'Automated account', key: 'automation_score' },
    { name: 'Toxic information', key: 'deep-toxic-classifier' },
    { name: 'Custom threat', key: 'custom' },
    { name: 'Source credibility', key: 'src-cred' },
    { name: 'Domestic extremism', key: 'domestic_extremism' }
];

const judgements = [
    { label: 'True', key: 'true' },
    { label: 'False', key: 'false' },
    { label: 'Misleading', key: 'misleading' },
    { label: 'Partly True', key: 'partly_true' },
    { label: 'Unverifiable', key: 'unverifiable' }
];

export {
    allTypes,
    sentiments,
    dateFilters,
    judgements
};
