import React, { useState } from 'react';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { TitleTooltip } from '../utils';
import { WordCloud } from './Vis';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';

export const Wordcloud = ({ preview }) => {
    const { setModal } = useModal();
    const options = [
        { key: 'hashtags', name: 'Hashtags' },
        { key: 'entities', name: 'Entities' },
        { key: 'mentions', name: 'Mentions' }
    ];
    const [current, setCurrent] = useState(options[0]);
    const getContentModal = ({ wordCloudItem }) => {
        if (preview) {
            return (
                <ContentPreviewModal name={wordCloudItem.original.replace(/_/g, ' ')}
                    filter={{ value: wordCloudItem.original, type: current.key }}
                    contentType={CONTENT_TYPE.THREAT}
                    preview={preview}
                />
            );
        }

        return (
            <ContentModal name={wordCloudItem.original.replace(/_/g, ' ')}
                filter={{ value: wordCloudItem.original, type: current.key }}
                contentType={CONTENT_TYPE.THREAT}
            />
        );
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="mb-3">
                <TitleTooltip type="wordcloud" title="Wordcloud Generator" />
            </div>
            <div className="mb-1">
                <Dropdown value={current} options={options} onChange={setCurrent} />
            </div>
            <WordCloud key={current.key}
                field={current.key}
                size={preview ? 15 : 30}
                onWordClick={wordCloudItem => setModal({
                    header: wordCloudItem.original.replace(/_/g, ' '),
                    size: 'xl',
                    component: getContentModal({ wordCloudItem })
                })}
            />
        </div>
    );
};
