import React from 'react';
import * as FeatherIcon from 'react-feather';
import { useInvestigation } from './InvestigationContext';

export const EmailListItem = ({ emailList, setEmailList, value }) => {
    const { investigation } = useInvestigation();
    const removeEmail = (email) => {
        const newFinalArray = [...emailList];
        const index = emailList.indexOf(email);
        newFinalArray.splice(index, 1);
        setEmailList(newFinalArray);
    };
    return (
        <div className="d-flex align-items-center">
            <p className="m-0">
                {value}
            </p>
            {investigation.status === 'In Progress' && (
                <FeatherIcon.X size={15}
                    className="ml-1"
                    role="button"
                    onClick={() => { removeEmail(value); }}
                    color="#F84367"
                    data-testid="remove-button"
                />
            )}
        </div>
    );
};
