import React, { useState } from 'react';

import {
    CustomInput,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../utils/useDropdown';
import { Loading } from '../../Loading';

export type Item = {
    id: string,
    name: string,
    selected: boolean
}

type DropdownWithSearchAlphabeticalProps = {
    name: string,
    placeholder?: string,
    items: Item[],
    handleSelectItem: (id: string) => void
};

const compare = (a: Item, b: Item) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};

export const DropdownWithSearchAlphabetical = ({ name, placeholder, items, handleSelectItem }: DropdownWithSearchAlphabeticalProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');

    const filteredItems = searchValue ? items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : items;

    const groupedItems: {[key: string]: Item[]} = {};

    filteredItems?.forEach(item => {
        const firstLetter = item.name[0].toUpperCase();
        const key = firstLetter.match(/[A-Z]/) ? firstLetter : '#';
        if (groupedItems[key]) {
            groupedItems[key] = groupedItems[key].concat(item);
        } else {
            groupedItems[key] = [item];
        }
    });

    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
            className="dropdown-checkboxes"
        >
            <DropdownToggle tag="div"
                data-testid="dropdown-toggle"
                className={`dropdown-filter d-flex justify-content-between align-items-center 
                    cursor-pointer px-2 py-1 ${dropdown && 'border border-primary'}`}
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color="#006FF9"
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={`dropdown-filter-menu-checkboxes ${dropdown && 'border border-primary'}`}>
                <div className="p-1 border-bottom">
                    <Input value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        placeholder={placeholder || 'Search'}
                        data-testid="dropdown-search-input"
                    />
                </div>
                <div className="dropdown-filter-menu-items scrollbar-small">
                    {items.length ? Object.keys(groupedItems).sort().map(key => (
                        <div key={key}>
                            <div className="font-weight-bold">{key}</div>
                            <div className="dropdown-filter-columns">{groupedItems[key].sort(compare).map(item => (
                                <FormGroup key={item.id}>
                                    <CustomInput type="checkbox"
                                        checked={item.selected}
                                        className="custom-control-input-checkbox"
                                        label={<span className="truncate-this">{item.name}</span>}
                                        id={item.id}
                                        data-testid={`checkbox-${item.id}`}
                                        value={item.name}
                                        onChange={() => handleSelectItem(item.id)}
                                    />
                                </FormGroup>
                            ))}
                            </div>
                        </div>
                    )) : <Loading relative height={168} width={255} />}
                </div>
            </DropdownMenu>
        </Dropdown>
    ); };
