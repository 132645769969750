import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { X } from 'react-feather';
import { Row, Col, Button } from 'reactstrap';
import { MultiSelectDropdown } from '../../../../components/Form/MultiSelectDropdown';
import { SentimentFilter } from '../../../../components/Search/Filters';
import { resetNarrativeFilters, saveNarrativeFilters } from '../../store';
import { RootState } from '../../../../store';

type Ctype = {
    id: string,
    name: string,
    key: string
}

type Locations = {
    id: string,
    text: string
}

type Aggs = {
    ctype: Ctype[],
    locations: Locations[]
}

type FilterProps = {
    isOpen: boolean,
    setOpen: (e: boolean) => void,
    aggs: Aggs
}

type Filter = {
    sentiment: string,
    includedLocations?: string,
    includedSources?: string
}

export const Filters = ({ isOpen, setOpen, aggs }: FilterProps) => {
    const dispatch = useDispatch();
    const { filters: narrativeFilters }: any = useSelector((state: RootState) => state.narrativeFilters);
    const { includedSources, includedLocations, sentiment } = narrativeFilters || {};
    const allCtypeOptions = aggs.ctype.map(ctype => ({ id: ctype.id, text: ctype.name, key: ctype.key }));
    const allLocationOptions = aggs.locations.sort((a, b) => a.id.localeCompare(b.id));
    const [localSentiment, setLocalSentiment] = useState(sentiment || []);

    const refs = {
        includedSources: useRef<any>(null),
        includedLocations: useRef<any>(null),
    };

    const handleApply = () => {
        const filters: Filter = {
            ...Object.entries(refs).map(a => ({ [a[0]]: a[1].current?.getSelected() })).reduce((a, b) => ({ ...a, ...b })),
            sentiment: localSentiment
        };
        const allLocationsSelected = filters?.includedLocations?.length === allLocationOptions?.length;
        const allSourcesSelected = filters?.includedSources?.length === aggs.ctype?.length;

        if (localSentiment.length === 0 && allSourcesSelected && allLocationsSelected) {
            return dispatch(resetNarrativeFilters());
        }

        dispatch(saveNarrativeFilters({
            ...filters,
            sentiment: (localSentiment?.length === 0) ? undefined : filters.sentiment,
            includedSources:
                (allSourcesSelected) ? undefined : filters.includedSources,
            includedLocations:
                (allLocationsSelected) ? undefined : filters.includedLocations,
        }));
        setOpen(false);
    };
    const handleClear = () => {
        Object.entries(refs).map(a => a[1].current?.reset());
        setLocalSentiment([]);
        dispatch(resetNarrativeFilters());
    };

    if (!isOpen) return null;

    return (
        <div className="px-4 py-3 rounded border border-primary bg-white my-4">
            <div className="d-flex align-items-center justify-content-between mb-2">
                <h4>Filters</h4>
                <X role="button" onClick={() => setOpen(false)} />
            </div>
            <Row className="align-items-stretch">
                <Col xs={12} sm="auto" xl={4} className="mb-2">
                    <div className="bg-light rounded p-3 pt-2 h-100">
                        <p><strong>Source</strong></p>
                        <MultiSelectDropdown id="sources"
                            options={allCtypeOptions}
                            defaultOptions={includedSources || allCtypeOptions}
                            className="mb-2"
                            ref={refs.includedSources}
                        />
                    </div>
                </Col>
                <Col xs={12} sm="auto" lg={4} className="mb-2">
                    <div className="bg-light rounded p-3 pt-2 h-100">
                        <SentimentFilter sentiment={localSentiment} setSentiment={setLocalSentiment} />
                    </div>
                </Col>
                <Col xs={12} sm="auto" lg={4} className="mb-2">
                    <div className="bg-light rounded p-3 pt-2 h-100">
                        <p><strong>Location mention</strong></p>
                        <MultiSelectDropdown id="location"
                            options={allLocationOptions}
                            defaultOptions={includedLocations || allLocationOptions}
                            className="mb-2"
                            ref={refs.includedLocations}
                            search
                        />
                    </div>
                </Col>
            </Row>
            <Button color="primary" onClick={handleApply} className="mr-2">Apply</Button>
            <Button onClick={handleClear}>Clear</Button>
        </div>
    );
};
