import React from 'react';

export const Logo = () => (
    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_4)">
            <path fill="url(#paint0_linear_1_4)"
                d="M44.42 40.06C44.42 40.8195 44.1948 41.5619 43.7728 42.1934C43.3509 42.8249
                42.7512 43.3171 42.0495 43.6077C41.3478 43.8983 40.5757 43.9744 39.8308 43.8262C39.086
                43.678 38.4017 43.3123 37.8647 42.7753C37.3277 42.2383 36.9619 41.554 36.8138
                40.8091C36.6656 40.0643 36.7417 39.2922 37.0323 38.5905C37.3229 37.8888 37.8151
                37.2891 38.4466 36.8672C39.0781 36.4452 39.8205 36.22 40.58 36.22C41.596 36.2278
                42.5682 36.6349 43.2866 37.3534C44.0051 38.0718 44.4121 39.044 44.42 40.06V40.06Z"
            />
            <path fill="url(#paint1_linear_1_4)"
                d="M19.15 1.22C18.0836 1.24206 17.0246 1.40343 16 1.7C15.6798 1.81303 15.3898 1.99802
                15.1522 2.24073C14.9147 2.48344 14.7361 2.7774 14.63 3.1C14.3413 4.19204 14.2199 5.32155
                14.27 6.45V37.33C14.2288 38.283 14.33 39.2368 14.57 40.16C14.6479 40.4258 14.7845 40.6707
                14.9697 40.8767C15.1549 41.0826 15.3839 41.2444 15.64 41.35C16.4366 41.591 17.2688 41.6925 18.1
                41.65H21.1C22.4973 41.6326 23.871 41.288 25.111 40.6437C26.351 39.9994 27.4226 39.0734 28.24
                37.94C30.4155 35.0728 31.7705 31.668 32.16 28.09H33.56C33.44 29.47 33.38 31.25 33.38 33.44C33.3457
                36.6488 33.4892 39.8571 33.81 43.05C31.05 42.93 26.4067 42.87 19.88 42.87C11.04 42.87 4.43333 42.93
                0.06 43.05V41.83C1.07775 41.8136 2.08795 41.652 3.06 41.35C3.34076 41.2225 3.59067 41.0359 3.79261
                40.8029C3.99455 40.5699 4.14375 40.296 4.23 40C4.49568 38.9042 4.60676 37.7766 4.56 36.65V6.45C4.6061
                5.32343 4.49503 4.19592 4.23 3.1C4.14381 2.789 3.9884 2.50152 3.7754 2.25908C3.56239 2.01664 3.29731
                1.82551 3 1.7C2.02795 1.39799 1.01775 1.23636 0 1.22L0 0C2.06667 0.12 5.27 0.18 9.61 0.18C13.7433
                0.18 16.9233 0.12 19.15 0V1.22Z"
            />
        </g>
        <defs>
            <linearGradient id="paint0_linear_1_4" x1="36.74" y1="36.22" x2="36.74" y2="43.9" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F62459" />
                <stop offset="1" stopColor="#FF4379" />
            </linearGradient>
            <linearGradient id="paint1_linear_1_4" x1="0" y1="0" x2="0" y2="43.05" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F62459" />
                <stop offset="1" stopColor="#FF4379" />
            </linearGradient>
            <clipPath id="clip0_1_4">
                <rect width="44.42" height="43.9" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const LogoL = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
        <circle cx="27.5" cy="25.5" r="2.5" fill="#F84367" />
        <path fill="#F84367"
            d="M12.4877 0.792576C11.7868 0.805206 11.0907 0.910055 10.4173 1.10441C10.2081 1.17652 10.0185 1.29625 9.8637
        1.45411C9.70888 1.61198 9.59302 1.80364 9.5253 2.01392C9.33445 2.7229 9.25321 3.4568 9.2844 4.19026V24.258C9.2555
        24.8754 9.3236 25.4936 9.48624 26.09C9.53078 26.2676 9.61559 26.4325 9.73411 26.5722C9.85264 26.7118 10.0017 26.8225
        10.1699 26.8956C10.6887 27.0512 11.2304 27.1171 11.7715 27.0905H13.7248C14.635 27.0772 15.5297 26.8524 16.3377
        26.4341C17.1458 26.0157 17.845 25.4152 18.38 24.6803C19.7938 22.8161 20.6758 20.6049 20.9322 18.2812H21.8437C21.7656
        19.1777 21.7265 20.3406 21.7265 21.7568C21.7056 23.8413 21.7969 25.9253 22 28C20.2095 27.922 17.182 27.8831 12.937
        27.8831C7.18142 27.8831 2.87994 27.922 0.032554 28V27.1814C0.682872 27.1743 1.32913 27.0781 1.95324 26.8956C2.1542
        26.8203 2.33446 26.6987 2.4793 26.5406C2.62414 26.3825 2.72945 26.1925 2.78662 25.9861C2.96195 25.2767 3.03647 24.5463
        3.00799 23.8162V4.19026C3.0307 3.45711 2.9496 2.72445 2.76709 2.01392C2.71096 1.80994 2.60828 1.62169 2.46709
        1.4639C2.3259 1.30612 2.15003 1.18307 1.95324 1.10441C1.32054 0.907419 0.662709 0.802396 0 0.792576L0 0C1.34123
        0.0779582 3.42468 0.116938 6.25037 0.116938C8.94584 0.116938 11.0184 0.0779582 12.4682 0L12.4877 0.792576Z"
        />
    </svg>
);
