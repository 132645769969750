import {
    andOrValidator,
    angularBracketsValidator,
    balancedParenthesesValidator,
    consecutiveOperatorsValidator,
    emptyNearValidator,
    emptyParenthesesValidator,
    missingOperatorQuotesValidator,
    nearAndValidator,
    nearOperatorValidator,
    operatorAtEndValidator,
    operatorCapitalisationValidator,
    quotesValidator,
    singleQuotesValidator,
    squareBracketsValidator,
    tagValidators,
    dotsValidator,
    emptyShorthandTagsValidator,
    wildcardAtStartValidator,
    hyphenWordWithQuotesValidator
} from './Validators';
import { replaceStringsInQuery } from './replaceStringsInQuery';

export const validateQuery = (query) => {
    const queryByLines = query.split('\n');
    const lineLengths = queryByLines.map((line) => line.length + 1);
    const singleLineQuery = replaceStringsInQuery({ query: queryByLines.join(' ') }).trim();

    const { isValid: isAndOrValid, markers: andOrMarkers } = andOrValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isAngularBracketsValid,
        markers: angularBracketsMarkers
    } = angularBracketsValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isBalancedParenthesesValid,
        markers: balancedParenthesesMarkers
    } = balancedParenthesesValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isConsecutiveOperatorsValid,
        markers: consecutiveOperatorsMarkers
    } = consecutiveOperatorsValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isEmptyNearValid,
        markers: emptyNearMarkers
    } = emptyNearValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isEmptyParenthesesValid,
        markers: emptyParenthesesMarkers
    } = emptyParenthesesValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isMissingOperatorQuotesValid,
        markers: missingOperatorQuotesMarkers
    } = missingOperatorQuotesValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isNearAndValid,
        markers: nearAndMarkers
    } = nearAndValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isNearOperatorValid,
        markers: nearOperatorMarkers
    } = nearOperatorValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isOperatorAtEndValid,
        markers: operatorAtEndMarkers
    } = operatorAtEndValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isOperatorCapitalisationValid,
        markers: operatorCapitalisationMarkers
    } = operatorCapitalisationValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isSquareBracketsValid,
        markers: squareBracketsMarkers
    } = squareBracketsValidator(singleLineQuery, queryByLines, lineLengths);
    const { isValid: areDotsValid, markers: dotsMarkers } = dotsValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: areEmptyShorthandTagsValid,
        markers: emptyShorthandTagsMarkers
    } = emptyShorthandTagsValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isWildcardAtStart,
        markers: wildcardAtStartMarkers
    } = wildcardAtStartValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isQuotesValid,
        markers: quotesMarkers
    } = quotesValidator(queryByLines.join(' ').trim(), queryByLines, lineLengths);
    const {
        isValid: isSingleQuotesValid,
        markers: singleQuotesMarkers
    } = singleQuotesValidator(singleLineQuery, queryByLines, lineLengths);
    const {
        isValid: isHyphenWordValid,
        markers: hyphenWordMarkers
    } = hyphenWordWithQuotesValidator(singleLineQuery, queryByLines, lineLengths);

    const {
        isTagValid,
        tagValidMarkers,
        isTagCapitalisationValid,
        tagCapitalisationMarkers,
        isAccountAtSymbolValid,
        accountAtSymbolMarkers,
        isFromTagValid,
        fromTagMarkers,
        isMentionsTagValid,
        mentionsTagMarkers,
        isEmptySpaceValid,
        emptySpaceMarkers,
        isIllegalEmptySpaceValid,
        illegalEmptySpaceMarkers,
        isDomainTagValid,
        domainMarkers,
    } = tagValidators(queryByLines.join(' '), queryByLines, lineLengths);

    return {
        accountAtSymbolValidator: {
            isError: (isTagValid && isIllegalEmptySpaceValid) ? !isAccountAtSymbolValid : false,
            markers: (isTagValid && isIllegalEmptySpaceValid) ? accountAtSymbolMarkers : []
        },
        andOrValidator: {
            isError: !isAndOrValid,
            markers: andOrMarkers
        },
        angularBracketsValidator: {
            isError: !isAngularBracketsValid,
            markers: angularBracketsMarkers
        },
        balancedParenthesesValidator: {
            isError: !isBalancedParenthesesValid,
            markers: balancedParenthesesMarkers
        },
        consecutiveOperatorsValidator: {
            isError: !isConsecutiveOperatorsValid,
            markers: consecutiveOperatorsMarkers
        },
        domainValidator: {
            isError: !isDomainTagValid,
            markers: domainMarkers
        },
        emptyNearValidator: {
            isError: !isEmptyNearValid,
            markers: emptyNearMarkers
        },
        emptyParenthesesValidator: {
            isError: !isEmptyParenthesesValid,
            markers: emptyParenthesesMarkers
        },
        emptySpaceValidator: {
            isError: !isEmptySpaceValid,
            markers: emptySpaceMarkers
        },
        fromTagValidator: {
            isError: !isFromTagValid,
            markers: fromTagMarkers
        },
        illegalEmptySpaceValidator: {
            isError: !isIllegalEmptySpaceValid,
            markers: illegalEmptySpaceMarkers
        },
        mentionsTagValidator: {
            isError: !isMentionsTagValid,
            markers: mentionsTagMarkers
        },
        missingOperatorQuotesValidator: {
            isError: !isMissingOperatorQuotesValid,
            markers: missingOperatorQuotesMarkers
        },
        nearAndValidator: {
            isError: !isNearAndValid,
            markers: nearAndMarkers
        },
        nearOperatorValidator: {
            isError: !isNearOperatorValid,
            markers: nearOperatorMarkers
        },
        operatorAtEndValidator: {
            isError: !isOperatorAtEndValid,
            markers: operatorAtEndMarkers
        },
        operatorCapitalisationValidator: {
            isError: !isOperatorCapitalisationValid,
            markers: operatorCapitalisationMarkers
        },
        quotesValidator: {
            isError: !isQuotesValid && isMissingOperatorQuotesValid,
            markers: isMissingOperatorQuotesValid ? quotesMarkers : [],
        },
        squareBracketsValidator: {
            isError: !isSquareBracketsValid,
            markers: squareBracketsMarkers
        },
        singleQuotesValidator: {
            isError: !isSingleQuotesValid,
            markers: singleQuotesMarkers
        },
        tagCapitalisationValidator: {
            isError: !isTagCapitalisationValid,
            markers: tagCapitalisationMarkers
        },
        dotsValidator: {
            isError: isEmptySpaceValid ? !areDotsValid : false,
            markers: isEmptySpaceValid ? dotsMarkers : [],
        },
        tagValidator: {
            isError: isIllegalEmptySpaceValid ? !isTagValid : false,
            markers: isIllegalEmptySpaceValid ? tagValidMarkers : []
        },
        emptyShorthandTagsValidator: {
            isError: !areEmptyShorthandTagsValid,
            markers: emptyShorthandTagsMarkers
        },
        wildcardAtStartValidator: {
            isError: !isWildcardAtStart,
            markers: wildcardAtStartMarkers
        },
        hyphenWordWithQuotesValidator: {
            isError: !isHyphenWordValid,
            markers: hyphenWordMarkers
        }
    };
};
