import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { NarrativeListItem } from '../../../../../Narratives/Overview/Narratives/NarrativeListItem';
import { DeleteBlockButton } from '../getUpdatedReportPages';
import { AddNarrativesFromWatchlistModal } from './AddNarrativesFromWatchlistModal';

export const NarrativesWatchlist = ({ data }) => {
    const { setModal } = useModal();
    if (data.blocks.length === 0) {
        return (
            <div className="w-100 flex-1 d-flex align-items-center justify-content-center">
                <Button color="link"
                    className="text-decoration-none"
                    onClick={() => setModal({
                        header: false,
                        size: 'full',
                        component: <AddNarrativesFromWatchlistModal />
                    })}
                >
                    + Add narratives in watchlist
                </Button>
            </div>
        );
    }
    return (
        <div className="font-size-10">
            {data.blocks.map(narrative => (
                <div key={narrative.id} className="bg-verylight border p-1 mb-2 position-relative">
                    <div className="position-absolute report-right-edit-position">
                        <DeleteBlockButton block={narrative} type="narrativesWatchlist" />
                    </div>
                    <NarrativeListItem narrative={narrative} small />
                </div>
            ))}
        </div>
    );
};
