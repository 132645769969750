import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { allCtypesMongo, getRoom, languagesList } from '../../utils/variables';
import { allTypes as allThreatTypes, sentiments } from '../../utils/lib/search';

export const useContentFilters = ({ defaultFilters = {}, useGlobalFilters = true } = {}) => {
    const filters = useSelector((state) => state.filters.filters);
    const sourcesFilter = filters?.sourcesWithDomains?.map(source => source.key) || [];
    const domainsFilter = filters?.sourcesWithDomains?.map(domain => domain.domains || '').flat().filter(dom => dom) || [];
    const room = getRoom();
    const searchFilters = JSON.parse(JSON.stringify(defaultFilters));

    const { pathname } = useLocation();
    const isRoomPreview = pathname?.includes('create-room') || pathname?.includes('edit-room');

    if (useGlobalFilters && !isRoomPreview) {
        if (filters.query.length && !searchFilters?.search) searchFilters.search = filters.query;
        if (filters.dateRange?.startDate && filters.dateRange?.endDate && !searchFilters?.date) {
            searchFilters.date = { ...filters.dateRange };
        }
        if (domainsFilter.length && !searchFilters?.domains) searchFilters.domains = domainsFilter;
        if (filters.origin?.length && !searchFilters?.locations) searchFilters.locations = filters.origin;

        searchFilters.contentType = getSupportedCTypesFilters({
            sourcesFilter,
            providedFilters: searchFilters?.contentType
        });
        searchFilters.sentiment = getSupportedSentimentFilters({
            filtersSentiment: filters?.sentiment,
            providedSentiment: searchFilters?.sentiment
        });
        searchFilters.threatLabels = getSupportedThreatTypesFilters({
            filtersThreatTypes: filters?.threatTypes,
            providedThreatTypes: searchFilters?.threatLabels
        });
        searchFilters.languages = getSupportedLanguagesFilters({
            filtersLanguages: filters?.languages,
            providedLanguages: searchFilters?.languages
        });
    }

    const ret = {
        ...(room?.project_id && { projectId: room.project_id }),
        filters: searchFilters
    };

    if (useGlobalFilters && filters.dateRange?.startDate && filters.dateRange?.endDate && !searchFilters?.date) {
        searchFilters.date = { ...filters.dateRange };
    }

    if (!searchFilters?.date?.endDate && room.contentVisibilityRestriction) {
        if (!searchFilters?.date) searchFilters.date = {};
        searchFilters.date.endDate = moment(moment().subtract(room.contentVisibilityRestriction, 'days').startOf('day').toDate()).unix();
    }

    if (useGlobalFilters && filters.convertedBooleanQuery) {
        ret.dslQuery = filters.convertedBooleanQuery;
    }

    if (filters.from?.length) {
        searchFilters.from = filters.from;
    }
    if (filters.keywords?.length) {
        searchFilters.keywords = filters.keywords;
    }

    return ret;
};

const getSupportedCTypesFilters = ({ sourcesFilter = [], providedFilters = [] }) => {
    const allTypes = allCtypesMongo().map((type) => type.key);

    if (providedFilters.length) {
        return allTypes.filter((type) => providedFilters.includes(type));
    }
    if (sourcesFilter.length) {
        return allTypes.filter((type) => sourcesFilter.includes(type));
    }

    return undefined;
};

const getSupportedSentimentFilters = ({ filtersSentiment = [], providedSentiment = [] }) => {
    const allSentiment = sentiments.map((sentiment) => sentiment.key);

    if (providedSentiment.length) {
        return allSentiment.filter((sentiment) => providedSentiment.includes(sentiment));
    }
    if (filtersSentiment.length) {
        return allSentiment.filter((sentiment) => !filtersSentiment.includes(sentiment));
    }

    return undefined;
};

const getSupportedThreatTypesFilters = ({ filtersThreatTypes = [], providedThreatTypes = [] }) => {
    const allThreatLabels = allThreatTypes.map((type) => type.key);

    if (providedThreatTypes.length) {
        return allThreatLabels.filter((type) => providedThreatTypes.includes(type));
    }
    if (filtersThreatTypes.length) {
        return allThreatLabels.filter((type) => !filtersThreatTypes.includes(type));
    }

    return undefined;
};

const getSupportedLanguagesFilters = ({ filtersLanguages = [], providedLanguages = [] }) => {
    const allLanguages = languagesList();

    if (providedLanguages.length) {
        return allLanguages.filter(lang => providedLanguages.includes(lang));
    }
    if (filtersLanguages.length) {
        return allLanguages.filter(lang => !filtersLanguages.includes(lang));
    }

    return undefined;
};
