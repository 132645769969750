import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { BackButton } from '../../../../components/Atoms';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { GET_MULTIPLE_TRANSLATION } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { ActionsRow } from './ActionsRow';
import { ContentText } from './ContentText';
import { Entities } from './Entities';
import { RightPanel } from './RightPanel';
import { WhyIsThisAThreat } from './WhyIsThisAThreat';

export const Page = ({
    data,
    refetch
}) => {
    const room = getRoom();
    const entities = formatSentimentData(data?.entitySentiment || []);

    const [translateEntities, { data: translatedEntities }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: entities.map(item => ({
                text: item.entity,
                target: 'en'
            }))
        },
    });

    const outputEntities = entities.map((item, i) => {
        if (translatedEntities) {
            return {
                ...item,
                original: item.entity,
                entity: translatedEntities.translateMultipleText[i].translations[0]
            };
        }
        return item;
    });

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'Threats' }
    ];

    const filters = useSelector((state) => state.filters.filters);
    const highlightingSearchQuery = Boolean(filters.query || filters.booleanQuery);

    const likesCount = Number.isNaN(Number(data.likesCount)) ? -1 : Number(data.likesCount);
    const retweetsCount = Number.isNaN(Number(data.retweetsCount)) ? -1 : Number(data.retweetsCount);
    const repliesCount = Number.isNaN(Number(data.repliesCount)) ? -1 : Number(data.repliesCount);
    const engagementCount = Number.isNaN(Number(data.engagement)) ? -1 : Number(data.engagement);

    return (
        <div className="px-4 threat-page">
            <Row>
                <Col xs="12" lg="8" xl="9" className="pr-4 py-4">
                    <BreadCrumbs items={bread} />
                    <BackButton pathName={`/situation-rooms/${room.id}/threats`} gotToPreviousPath />
                    <Row>
                        <Col>
                            <div className="threat-title">
                                <span>
                                    Threat summary
                                </span>
                            </div>
                            <div className="highlight-em pt-3">
                                View the threat content and everything we know about the threat
                                here. <em>Highlighted</em> information are the {highlightingSearchQuery
                                    ? 'search keywords.'
                                    : 'keywords that are monitored in your situation room.'}
                            </div>
                        </Col>
                    </Row>
                    <ActionsRow content={data} refetch={refetch} />
                    <ContentText data={data} translateEntities={translateEntities} />
                    <div className="bg-white p-3 mt-4">
                        <div className="content-reach-title d-flex">Content engagement
                            <div className="threat-tooltip" id="reachTooltip">?</div>
                            <UncontrolledTooltip placement="right" target="reachTooltip">
                                Our analysis of the engagement of this content so you can see how it is being engaged with
                            </UncontrolledTooltip>
                        </div>

                        <div className="d-flex flex-wrap">
                            <Engagement title="Likes" count={likesCount} />
                            <Engagement title="Retweets" count={retweetsCount} />
                            <Engagement title="Replies" count={repliesCount} />
                            <Engagement title="Estimated engagement" count={engagementCount} />
                        </div>
                    </div>
                    <WhyIsThisAThreat data={data} />
                    <div className="bg-white p-3 mt-4">
                        <Entities data={outputEntities} id={data.id} />
                    </div>
                </Col>
                <RightPanel data={data} />
            </Row>
        </div>
    );
};

const Engagement = ({ title, count }) => (
    <div className="engagement-box mr-2 mb-2">
        <div className="font-weight-bold">{count < 0 ? 'NA' : count}</div>
        <div>{title}</div>
    </div>
);

export const formatSentimentData = (data) => {
    const colours = {
        positive: 'success',
        negative: 'danger',
        neutral: 'warning',
    };
    return data.filter(a => a).map((item) => ({
        entity: item.split(':')[0].replace(/_/g, ' '),
        sentiment: item.split(':')[1],
        color: colours[item.split(':')[1]] || 'warning',
    }));
};
