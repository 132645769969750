import React, { useState, useRef, useEffect } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

export type DateRangeProps =
    { startDate: number, endDate: number }

type DatePickerProps = {
    setDateRange: (dateRange: { startDate: number, endDate: number }) => void;
    dateRange?: DateRangeProps;
};

export const DatePicker = ({ setDateRange, dateRange }: DatePickerProps) => {
    const [focus, setFocus] = useState(false);
    const dateRef = useRef<any>(null);

    useEffect(() => {
        if (dateRange && dateRef.current) {
            dateRef.current.setStartDate(moment.unix(dateRange.startDate).toDate());
            dateRef.current.setEndDate(moment.unix(dateRange.endDate).toDate());
        }
    }, [dateRange]);

    const inputRange = {
        Today: [moment().toDate(), moment().toDate()],
        Yesterday: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
        'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
        'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
        'Last 3 Months': [
            moment().subtract(3, 'month').toDate(),
            moment().toDate()
        ],
    };

    const handleCallback = (start: moment.Moment, end: moment.Moment) => {
        const newDateRange = {
            startDate: moment(start).unix(),
            endDate: moment(end).unix(),
        };
        setDateRange(newDateRange);
    };

    return (
        <button type="button"
            className="btn p-0 cursor-default"
            data-testid="date-range-picker-button"
        >
            <InputGroup border={focus ? 'active' : 'none'} className="d-inline-flex mt-0">
                <Label className="d-flex w-100">
                    <ReactDateRangePicker onCallback={handleCallback}
                        initialSettings={{
                            alwaysShowCalendars: true,
                            ranges: inputRange,
                            locale: { format: 'DD/MM/YYYY' },
                        }}
                        ref={(ref: any) => {
                            dateRef.current = ref;
                        }}
                    >
                        <input placeholder="dd/mm/yyyy"
                            type="text"
                            data-testid="input-range"
                            className="form-control w-65 text-truncate"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        />
                    </ReactDateRangePicker>
                    <InputGroupAddon addonType="prepend"
                        className={`small-border cursor-pointer ${focus ? 'border-left' : 'border-left'}`}
                    >
                        <InputGroupText className="bg-white border-left-0"
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                        >
                            <FeatherIcon.Calendar size={18} color="#006FF9" />
                        </InputGroupText>
                    </InputGroupAddon>
                </Label>
            </InputGroup>
        </button>
    );
};
