import React, { useEffect, useState } from 'react';
import { Input, Col, Row } from 'reactstrap';
import moment from 'moment';
import { DatePicker } from '../components/DatePicker';
import { MultiSelectSourceFilter } from '../MultiSelectSourceFilters';
import { MultiSelectLanguageFilters } from '../MultiSelectLanguageFilters';
import { KeywordsFilter } from '../KeywordsFilter';
import { ActorsFilter } from '../ActorsFilter';
import { SelectedFilters } from '../components/SelectedFilters';
import { RoomFilterProps, setDataFilters } from '../FiltersLayout';
import { ItemProps } from '../components/DropdownWIthMultiCheckbox';
import { FiltersDataProps } from '../components/SaveFilter';

type EditSavedFilterProps = {
    filterData: RoomFilterProps[],
    filterId: string,
    filterName: string,
    setFilterName: (e: string) => void,
    setEditFilterData: (e: {data: FiltersDataProps[], dateRange: {startDate: number, endDate: number}}) => void
}

export const EditSavedFilter = ({ filterData, filterId, filterName, setFilterName, setEditFilterData }: EditSavedFilterProps) => {
    const [queriesFinished, setQueriesFinished] = useState(false);

    // const [commentsResultsOnly, setCommentsResultsOnly] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: moment(moment().toDate()).unix(),
        endDate: moment(moment().toDate()).unix(),
    });
    const [sourceData, setSourceData] = useState<ItemProps[]>([]);
    const [languagesData, setLanguagesData] = useState<ItemProps[]>([]);
    const [keywords, setKeywords] = useState<ItemProps[]>([]);
    const [actors, setActors] = useState<ItemProps[]>([]);
    useEffect(() => {
        if ((filterData && !queriesFinished) && (sourceData.length && languagesData.length && actors.length)) {
            setFilterName(filterData[0].name);
            const deletedTypenames = filterData.map((a) => {
                const dataNew = a.data;
                dataNew.map((item) => {
                    const newFilter = { ...item };
                    delete newFilter.__typename;
                    return newFilter;
                });

                return { ...a, data: dataNew };
            });
            const selectedFilter = deletedTypenames.find((a) => a.id === filterId);
            if (selectedFilter) {
                const sourceFilter = selectedFilter.data.find((a) => a.filter === 'Sources');
                const languagesFilter = selectedFilter.data.find((a) => a.filter === 'Languages');
                const keywordFilter = selectedFilter.data.find((a) => a.filter === 'Keywords');
                const actorsFilter = selectedFilter.data.find((a) => a.filter === 'Actors');
                sourceFilter ? setSourceData(setDataFilters(sourceData, sourceFilter))
                    : setSourceData(sourceData.map((a) => ({ ...a, selected: false })));

                languagesFilter ? setLanguagesData(setDataFilters(languagesData, languagesFilter, 'languages'))
                    : setLanguagesData(languagesData.map((a) => ({ ...a, selected: true })));

                keywordFilter ? setKeywords(setDataFilters(keywords, keywordFilter))
                    : setKeywords(keywords.map((a) => ({ ...a, selected: false })));

                actorsFilter ? setActors(setDataFilters(actors, actorsFilter))
                    : setActors(actors.map((a) => ({ ...a, selected: false })));
                setDateRange(selectedFilter.dateRange);
            }

            setQueriesFinished(true);
        }
    }, [filterData, sourceData, languagesData, keywords, actors, queriesFinished, filterId, setFilterName]);

    useEffect(() => {
        const sourceFilteredData = sourceData.filter((a) => a.selected === true);
        const languagesFilteredData = languagesData.filter((a) => a.selected === false);
        const keywordsFilteredData = keywords.filter((a) => a.selected === true);
        const actorsFilteredData = actors.filter((a) => a.selected === true);
        const finalData = [
            {
                filter: 'Sources',
                items: sourceFilteredData
            },
            {
                filter: 'Languages',
                items: languagesFilteredData
            },
            {
                filter: 'Keywords',
                items: keywordsFilteredData
            },
            {
                filter: 'Actors',
                items: actorsFilteredData
            }
        ];
        setEditFilterData({ data: finalData, dateRange: { startDate: dateRange.startDate, endDate: dateRange.endDate } });
    }, [sourceData, languagesData, keywords, actors, dateRange, setEditFilterData]);

    return (
        <>
            <div className="d-flex flex-column mx-4 mt-3">
                <div>
                    <div className="d-flex flex-column w-25">
                        <p>Filter name</p>
                        <Input value={filterName}
                            onChange={e => setFilterName(e.target.value)}
                        />
                    </div>
                    <hr />
                    <div className="pt-2 bg-white">
                        <p className="font-weight-bold">Filters</p>
                        <Row className="align-items-stretch flex-md-nowrap">
                            <Col xs="12" sm="auto" className="mb-1 pr-1 pl-2">
                                <p className="font-weight-semibold">Date</p>
                                <DatePicker setDateRange={setDateRange} dateRange={dateRange} />
                            </Col>

                            <Col xs="12" sm="auto" className="mb-1 px-1">
                                <p className="font-weight-semibold">Source</p>
                                <MultiSelectSourceFilter sourceData={sourceData} setSourceData={setSourceData} />
                            </Col>
                            <Col xs="12" sm="auto" className="mb-1 px-1">
                                <p className="font-weight-semibold">Languages</p>
                                <MultiSelectLanguageFilters languagesData={languagesData} setLanguagesData={setLanguagesData} />
                            </Col>
                            <Col xs="12" sm="auto" className="mb-1 px-1">
                                <p className="font-weight-semibold">Keywords</p>
                                <KeywordsFilter keywords={keywords} setKeywords={setKeywords} />
                            </Col>
                            <Col xs="12" sm="auto" className="mb-1 px-1">
                                <p className="font-weight-semibold">Actors</p>
                                <ActorsFilter actors={actors} setActors={setActors} />
                            </Col>

                            {/* <Col xs="auto" className="mb-1">
                        <p className="font-weight-semibold">Labels</p>
                    </Col> */}
                        </Row>

                        {/* <FormGroup>
                            <CustomInput id="comments-result"
                                type="checkbox"
                                className="d-inline"
                                checked={commentsResultsOnly}
                                onChange={({ target: { checked } }) => { setCommentsResultsOnly(checked); }}
                            />

                            Only display results with comments
                        </FormGroup> */}

                    </div>
                </div>
            </div>
            <hr />
            {(sourceData.find(item => item.selected) || languagesData.find(item => !item.selected)
                    || keywords.find(item => item.selected) || actors.find(item => item.selected))
                    && (
                        <div className="border-top p-3 bg-white ml-1">
                            <SelectedFilters sourceData={sourceData}
                                setSourceData={setSourceData}
                                languagesData={languagesData}
                                setLanguagesData={setLanguagesData}
                                keywords={keywords}
                                setKeywords={setKeywords}
                                actors={actors}
                                setActors={setActors}
                                setDateRange={setDateRange}
                            />
                        </div>
                    )}
        </>

    );
};
