import mixpanel from 'mixpanel-browser';

mixpanel.init('8867f74b1b9a000dda29f2dc9d761101');

const envCheck = window.REACT_APP_ENV !== 'development';

export const mixpanelTrack = (event, props) => envCheck && mixpanel.track(event, props);

export const mixpanelIdentify = (id) => envCheck && mixpanel.identify(id);

export const mixpanelAlias = (id) => envCheck && mixpanel.alias(id);

export const mixpanelPeopleSet = (props) => envCheck && mixpanel.people.set(props);
