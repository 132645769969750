import React from 'react';
import * as FeatherIcon from 'react-feather';
import Highcharts, { Options, SeriesLineOptions, SeriesClickEventObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getIntervalDateRange } from '../../utils/getIntervalDateRange';

type MatchesWidgetProps = {
    colors: string[];
    data: any;
    description: string;
    descriptionSize: number;
    title: string;
    trend: any;
    height: number;
    width: number;
    dates: any;
    interval: string;
    onClick: (e: SeriesClickEventObject) => void;
}

export const MatchesWidget = ({
    colors,
    data,
    description,
    descriptionSize,
    title,
    trend,
    height,
    width,
    dates,
    interval,
    onClick
}: MatchesWidgetProps) => {
    const values = data.map((res: any) => res.value || res);
    const series: SeriesLineOptions[] = [{ name: 'Data', data: values, type: 'line' }];
    const min = Math.min.apply(Math, [...values]);
    const max = Math.max.apply(Math, [...values]);
    const options: Options = {
        title: {
            text: ''
        },
        chart: {
            height: height || 60,
            width: width !== undefined ? width : 100,
            spacing: [10, 0, 10, 0],
            backgroundColor: 'transparent'
        },
        colors: colors || ['#006FF9'],
        series,
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false,
            min,
            max
        },
        legend: {
            enabled: false
        },
        tooltip: {
            useHTML: true,
            headerFormat: '',
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            padding: 0,
            pointFormatter() {
                const { y } = this;
                return (
                    `<div style="padding: 4px 10px;" class="bg-white border rounded shadow-sm">
                        ${dates ? `
                            <p class="d-flex align-items-center justify-content-between mb-0">
                                <span class="mr-4">Date</span>
                                <span>${getIntervalDateRange(dates[(this as any).x], interval)}</span>
                            </p>
                            <hr style="margin: 2px 0;" />
                        ` : ''}
                        <p class="d-flex align-items-center justify-content-between mb-0">
                            <span class="mr-4">Matches</span>
                            <span>${y}</span>
                        </p>
                    </div>`
                );
            },
            style: {
                color: '#030E3A',
                fontFamily: 'inherit',
                fontSize: '14px'
            },
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false,
                    fillColor: '#fff',
                    lineColor: colors ? colors[0] : '',
                    lineWidth: 2,
                    radius: 2
                },
                states: {
                    hover: {
                        halo: {
                            size: 5
                        }
                    }
                },
                events: {
                    click: (e) => {
                        if (onClick) {
                            onClick(e);
                        }
                    }
                }
            }
        }
    };
    return (
        <div data-testid="matches-widget">
            <span className={`text-uppercase font-weight-bold color-secondary font-size-${descriptionSize || 13}`}>
                {description}
            </span>
            {title && (
                <h3 className="mb-0">
                    {title}
                    {trend && (
                        <span className="ml-11">
                            {trend.textClass === 'text-warning'
                                ? <FeatherIcon.TrendingUp color="#13BA9C" size={20} data-testid="matches-widget-trend-up" />
                                : <FeatherIcon.TrendingDown color="#F84367" size={20} data-testid="matches-widget-trend-down" />}
                        </span>
                    )}
                </h3>
            )}

            <div>
                <HighchartsReact options={options}
                    highcharts={Highcharts}
                    data-testid="react-highchart"
                    containerProps={{ style: { height: '100%', width: '100%' } }}
                />
            </div>
        </div>
    );
};
