import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading } from '../../../../components/Loading';
import { FeedbackTable } from './FeedbackTable';
import { getUser } from '../../../../utils/variables';
import { Pagination } from '../../../../components/Molecules';
import { FeedbackFilters } from './FeedbackFilters';
import { EmptySearchResults } from '../../../../components/EmptySearchResults';
import { FeedbackTableActionsBar } from './TableActionsBar';
import { useGetRemovedContent } from '../../../../services/Content/getRemovedContent';
import { saveFilter, saveSort } from '../../store';

export const Feedback = ({ mockUser }) => {
    const user = mockUser || getUser();
    const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const { sort, searchFilters } = useSelector(state => state.removeContentFilter);
    const [checkBoxValues, setCheckBoxValues] = useState([]);
    const limit = 10;

    const { data: deletedContentData, count: total, loading, refetch } = useGetRemovedContent({
        searchFilters,
        offset,
        sort,
        limit
    });

    const handleSearch = (val, identifier) => {
        dispatch(saveFilter({
            ...searchFilters,
            [identifier]: val
        }));
        setOffset(0);
    };
    const deletedContentDataIds = deletedContentData?.map(x => x?.doc_id);
    const handleClearSelection = () => setCheckBoxValues([]);
    const handleRemovePermanentlySuccess = () => {
        handleClearSelection();
        setOffset(0);
    };

    const handleRecoverContentSuccess = () => refetch();
    const handleRecoverContentAbort = () => {
        handleClearSelection();
        refetch();
    };
    const handleSelectAll = () => setCheckBoxValues(deletedContentDataIds);

    if (!loading && sort.name === 'All' && !searchFilters && total === 0) {
        return <EmptySearchResults mainHeading="No records found" isSubtitleDisplayed={false} />;
    }
    return (
        <div className="bg-white px-3 pt-3 pb-0">
            {!loading && total !== 0 && (
                <>
                    <h3 className="m-0">Removed content {total ? `(${total})` : ''}</h3>
                    <hr className="my-3" />
                    <p className="mb-3">
                        You can find all removed content (matches, threats, narratives) here.
                        {user?.role !== 'ADMIN' && ' You can only delete the "removed content" in your name.'}
                    </p>
                </>
            )}
            <FeedbackFilters handleSearchValue={handleSearch}
                setOffset={setOffset}
                setSort={(data) => dispatch(saveSort({ sort: data }))}
                sort={sort}
                total={total}
            />
            {loading ? <Loading relative height={300} /> : (
                <FeedbackTable removedContent={deletedContentData}
                    deletedContentDataIds={deletedContentDataIds}
                    checkBoxValues={checkBoxValues}
                    setCheckBoxValues={setCheckBoxValues}
                    user={user}
                    setPage={setOffset}
                />
            )}
            {checkBoxValues.length > 0 ? (
                <FeedbackTableActionsBar selectedContentIds={checkBoxValues}
                    onRemovePermanentlySuccess={handleRemovePermanentlySuccess}
                    onRecoverContentSuccess={handleRecoverContentSuccess}
                    onRecoverContentAction={handleClearSelection}
                    onRecoverAbort={handleRecoverContentAbort}
                    onSelectAll={handleSelectAll}
                    total={total}
                    searchFilters={searchFilters}
                    offset={offset}
                    sort={sort}
                    contentsToDisplay={limit}
                />
            ) : (
                total > limit && (
                    <div className="border-top">
                        <Pagination total={total}
                            itemsPerPage={limit}
                            offset={offset}
                            setOffset={setOffset}
                            className="py-2"
                        />
                    </div>
                )
            )}
        </div>
    );
};
