import React, { useState } from 'react';
import {
    Form,
    Input,
    Label,
    Button,
    FormGroup
} from 'reactstrap';
import { useUser } from '../../../context/user/UserContext';

export const PersonalInfo = ({ updateProfile }) => {
    const { user, setUser } = useUser();
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [jobDescription, setJobDescription] = useState(user.jobDescription || '');
    const [bio, setBio] = useState(user.bio || '');

    const handleSave = (e) => {
        e.preventDefault();
        setUser({ ...user, bio, jobDescription });
        updateProfile({
            displayName: `${firstName} ${lastName}`,
            jobDescription,
            bio,
            uid: user.uid,
            email: user.email,
            lastName,
        });
    };

    const hasNoChanges = () => {
        if ((user.firstName || '') !== firstName) return false;
        if ((user.lastName || '') !== lastName) return false;
        if ((user.jobDescription || '') !== jobDescription) return false;
        return (user.bio || '') === bio;
    };

    return (
        <div>
            <Form onSubmit={handleSave}>
                <FormGroup className="mb-2">
                    <Label>First name*</Label>
                    <Input data-testid="firstname-surname"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup className="mb-2">
                    <Label>Last name*</Label>
                    <Input data-testid="last-name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup className="mb-2">
                    <Label>Job description</Label>
                    <Input data-testid="job"
                        value={jobDescription}
                        maxLength={600}
                        onChange={(e) => setJobDescription(e.target.value)}
                        type="textarea"
                        rows={5}
                    />
                </FormGroup>
                <FormGroup className="mb-2">
                    <Label>Bio</Label>
                    <Input data-testid="bio"
                        value={bio}
                        maxLength={600}
                        onChange={(e) => setBio(e.target.value)}
                        type="textarea"
                        rows={5}
                    />
                </FormGroup>
                <Button type="submit" disabled={hasNoChanges()}>Update</Button>
            </Form>
        </div>
    );
};
