import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CustomInput
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { Link } from 'react-router-dom';
import { NarrativeStats } from './NarrativeStats';
import { getRoom, refetchGetNarratives } from '../../../../../utils/variables';
import { TranslateIconBlue } from '../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';
import { AddToWatchlist } from './AddToWatchlist';
import { RemoveContentModal } from '../../../../../components/CommonModals/RemoveContent';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { EditNarrativeTitle } from './EditNarrativeTitle';
import { useUser } from '../../../../../context/user/UserContext';
import { useDropDown } from '../../../../../utils/useDropdown';
import { truncateString } from '../../../../../utils/text';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';

export const NarrativeListItem = ({ narrative, small, onThreatListItemCheckChange, compare }) => {
    const room = getRoom();
    const refetchNarratives = refetchGetNarratives();
    const { notAllowed } = useUser();
    const { setModal } = useModal();
    const [narrativeTitle, setNarrativeTitle] = useState(narrative.narrative_title);
    const [dropdown, toggleDropdown] = useDropDown();
    const [isTranslated, setIsTranslated] = useState(false);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: narrative.narrative_title, target: 'en' }
        },
        fetchPolicy: 'no-cache'
    });
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const outputText = translatedData
        ? translatedData?.translateMultipleText[0]?.translations[0]?.replace(urlRegex, '') : narrativeTitle?.replace(urlRegex, '');
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };
    const fromReport = window.location.pathname.includes('report');
    const openEditNarrativeTitleModal = () => {
        setModal({
            component: <EditNarrativeTitle setNarrativeTitle={setNarrativeTitle}
                id={narrative.id}
                narrativeTitle={narrativeTitle}
            />
        });
    };
    const handleRemoveNarrativeError = () => {
        setModal({
            header: 'Failed to remove content',
            component: <p>Failed to remove content, please try again later</p>
        });
    };
    const setRemoveContentModal = () => {
        setModal({
            modal: <RemoveContentModal removeData={[{ id: narrative.id }]}
                onCompleted={refetchNarratives}
                onError={handleRemoveNarrativeError}
                type={CONTENT_TYPE.NARRATIVE}
            />
        });
    };
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    return (
        <>
            {!fromReport && (
                <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-item-start" data-testid="narratives-list-items">
                        {!notAllowed.includes('editData') && (
                            <CustomInput type="checkbox"
                                checked={compare?.map(x => x?.id || x).indexOf(narrative.id) !== -1}
                                id={`narrativeContent11-${narrative.id}`}
                                onChange={(event) => {
                                    if (!onThreatListItemCheckChange) {
                                        return;
                                    }
                                    onThreatListItemCheckChange(event.target.checked);
                                }}
                            />
                        )}
                        <AddToWatchlist narrativeId={narrative.id} />
                        <span>
                            {(narrative.lang_verbose === 'English' || !narrative.lang_verbose) ? null : (
                                <span role="button"
                                    className="ml-3 text-primary"
                                    data-testid="translate-icon"
                                    onClick={(e) => translation(e)}
                                >
                                    <TranslateIconBlue size={25} /> Translate
                                </span>
                            )}
                            {!notAllowed.includes('editData') && (
                                <span className="align-narrative-title" onClick={() => openEditNarrativeTitleModal()}>
                                    <FeatherIcon.Edit color="#006FF9"
                                        data-testid="edit-narrative"
                                        width="18"
                                        height="18"
                                    /> Edit narrative title
                                </span>
                            )}
                        </span>
                    </div>
                    {(!notAllowed.includes('editData') && contentActionsEnabled) && (
                        <span className="mt-n1">
                            <Dropdown onClick={(e) => { e.preventDefault(); }}
                                className="d-inline ml-1"
                                isOpen={dropdown}
                                toggle={toggleDropdown}
                                tag="div"
                                direction={window.innerWidth < 540 ? 'left' : 'right'}
                                data-testid="narrative-list-item-actions"
                            >
                                <DropdownToggle className="bg-transparent border-0 pr-0" style={{ minWidth: 10 }}>
                                    <span><i className="fa fa-ellipsis-v text-secondary" /></span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={setRemoveContentModal}>
                                        <span>
                                            Remove content
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </span>
                    )}
                </div>
            )}
            <Link to={`/situation-rooms/${room.id}/narratives/${narrative.id}/dashboard`} target={fromReport ? '_blank' : ''}>
                <div className="pl-6">
                    {isTranslated ? truncateString(outputText, 1000) : truncateString(narrativeTitle, 1000)} --
                    <span>&nbsp;View details</span>
                    <NarrativeStats narrative={narrative} small={small} fromReport={fromReport} />
                </div>
            </Link>
        </>
    );
};
