import React from 'react';
import { Input, Button, Popover, ListGroup } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { User } from '../../../assets/svg/user';
import { useUser } from '../../../context/user/UserContext';
import { useDropDown } from '../../../utils/useDropdown';

export const ProfileImage = ({ updateProfile }) => {
    const { user } = useUser();
    const [dropdown, toggleDropdown] = useDropDown();
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        updateProfile({
            variables: {
                data: {
                    id: user.uid,
                    picture: file,
                },
            },
        });
    };

    return (
        <div>
            <div className="position-relative d-inline-block">
                {user.photoURL ? (
                    <img src={user.photoURL}
                        alt="logically user"
                        className="rounded-circle"
                        style={{ width: 130 }}
                    />
                )
                    : <span className="mx-1"> <User size="106" /></span>}
                <div className="absolute-bottom-right">
                    <Button className="p-2 border rounded-circle mw-0" id="profile-image-popover">
                        <FeatherIcon.Camera size={16} className="d-block" />
                    </Button>
                </div>
            </div>
            <Input type="file"
                className="d-none"
                id="profile-image-input"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={handleImageChange}
            />
            <Popover isOpen={dropdown}
                toggle={toggleDropdown}
                placement="right-end"
                trigger="focus"
                target="profile-image-popover"
                hideArrow
            >
                <ListGroup>
                    <Button onClick={() => {
                        document.getElementById('profile-image-input').click();
                    }}
                    >
                        Upload new picture
                    </Button>
                </ListGroup>
            </Popover>
        </div>
    );
};
