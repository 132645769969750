import React from 'react';
import './Light.css';
import { Circle } from '../Circle';

const colors = {
    red: {
        backgroundColor: '#F84367'
    },
    yellow: {
        backgroundColor: '#e7b416'
    },
    green: {
        backgroundColor: '#2dc937'
    },
    grey: {
        backgroundColor: '#E5E6EB'
    }
};

export class Light extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            red: colors.red,
            yellow: colors.grey,
            green: colors.grey,
            text: 'High'
        };
    }

    componentDidMount() {
        this.handeLightChange();
    }

    handeLightChange = () => {
        const { threatLabel } = this.props;
        if (threatLabel) {
            switch (threatLabel) {
                case 'high':
                    this.setState({
                        red: colors.red,
                        yellow: colors.grey,
                        green: colors.grey,
                        text: 'High'
                    });
                    break;
                case 'medium':
                    this.setState({
                        red: colors.grey,
                        yellow: colors.yellow,
                        green: colors.grey,
                        text: 'Medium'
                    });
                    break;
                case 'low':
                    this.setState({
                        red: colors.grey,
                        yellow: colors.grey,
                        green: colors.green,
                        text: 'Low'
                    });
                    break;
                case 'none':
                    this.setState({
                        red: colors.grey,
                        yellow: colors.grey,
                        green: colors.grey,
                        text: 'None'
                    });
                    break;
                default:
                    this.setState({
                        red: colors.grey,
                        yellow: colors.grey,
                        green: colors.grey,
                        text: 'None'
                    });
                    break;
            }
        } else {
            this.setState({
                red: colors.grey,
                yellow: colors.grey,
                green: colors.grey,
                text: 'None'
            });
        }
    };

    render() {
        const { red, yellow, green, text } = this.state;
        const { page } = this.props;
        return (
            <div className="text-center">
                <div className={page === 'timeline' ? 'light ml-0' : 'light mr-0'}>
                    <Circle color={red} />
                    <Circle color={yellow} />
                    <Circle color={green} />
                </div>
                {page !== 'timeline' ? <p className="pt-3">Current threat level</p> : null}
                <p className={page === 'timeline' ? 'font-weight-bold mt-2' : 'font-weight-bold'}>{text}</p>

            </div>
        );
    }
}
