import React from 'react';
import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';
import SuccessIcon from '../../assets/images/investigations/investigation-success.svg';

export const RequestInvestigationDone = ({ noModal, onClose }) => {
    const content = (
        <div data-testid="request-investigation-done">
            <img src={SuccessIcon} alt="" style={{ width: 80 }} />
            <p>You have successfully raised an investigation request.</p>
        </div>
    );
    return noModal ? (
        content
    ) : (
        <Modal isOpen toggle={onClose}>
            <ModalBody>
                {content}
            </ModalBody>
            <ModalFooter className="d-block">
                <Button onClick={onClose} data-testid="close-button">Close</Button>
            </ModalFooter>
        </Modal>
    );
};
