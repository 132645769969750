import React, { useState } from 'react';
import { CustomInput, Table } from 'reactstrap';
import { useUser } from '../../context/user/UserContext';
import { WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content as ContentType } from '../../services/Content/contentInterface';
import { getContentLink } from '../../utils/getContentLink';
import { SelectAllCheckBox } from '../Form/CheckBox';
import { Pagination } from '../Molecules';
import { Content } from './Content';
import { ContentMetrics } from './ContentMetrics';
import { ContentTableActionsBar } from './ContentTableActionsBar';
import { TdWithLink } from './TdWithLink';
import { getRoom } from '../../utils/variables';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { ThreatsContentTable } from '../ThreatsContentTable';
import { MatchesContentTable } from '../MatchesContentTable';
import { ThreatsWatchlistContentTable } from '../ThreatsWatchlistContentTable';

export const ContentTableTypes = {
    WATCHLIST: 'watchlist',
    THREATS: 'threats',
    MATCHES: 'matches',
    NARRATIVES: 'narratives',
    OVERVIEW: 'overview',
    PREVIEW: 'preview',
};

type PaginationType = {
    count: number
    offset: number
    setOffset: (e: number) => void;
}

type ContentProps = 'datetime' | 'body' | WatchlistButtonContentKeys;

type ContentTableProps = {
    contents: Pick<ContentType, ContentProps>[]
    pagination?: PaginationType
    tableType: string
    total: number
    contentType: string
    contentsToDisplay: number
    preview?: boolean
    // eslint-disable-next-line react/no-unused-prop-types
    clusterData?: any
}

export const ContentTables = ({ contents, pagination, tableType, total, contentType, contentsToDisplay, preview }: ContentTableProps) => {
    const room = getRoom();
    const newContentTableView = !!room.instance?.plan?.others?.newContentTableView;

    const getContentTypeTable = () => {
        switch (contentType) {
            case CONTENT_TYPE.THREAT:
                return <ThreatsContentTable isPreview={preview} />;
            case CONTENT_TYPE.MATCH:
                return <MatchesContentTable isPreview={preview} />;
            default:
                return ('ERROR NO CONTENT TYPE');
        }
    };

    if (newContentTableView) {
        switch (tableType) {
            case ContentTableTypes.WATCHLIST:
                return <ThreatsWatchlistContentTable />;
            default:
                return getContentTypeTable();
        }
    }

    return (
        <ContentTable contents={contents}
            pagination={pagination}
            tableType={tableType}
            total={total}
            contentType={contentType}
            contentsToDisplay={contentsToDisplay}
            preview={preview}
        />
    );
};

export const ContentTable = ({
    contents,
    pagination,
    tableType,
    total,
    contentType,
    contentsToDisplay,
    preview,
    clusterData
}: ContentTableProps) => {
    const [checkBoxValues, setCheckBoxValues] = useState([] as any[]);
    const { notAllowed } = useUser();
    const room = getRoom();
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;

    const noActionsAllowed = notAllowed.includes('editData') || preview || (!watchlistEnabled && !contentActionsEnabled);
    const handleSelectAll = () => setCheckBoxValues(contents);
    const isClusterData = clusterData?.length > 0;

    if ((pagination && !pagination.count) || (!pagination && !contents.length)) return null;

    return (
        <>
            <div className="bg-white overflow-x-auto">
                <Table className="m-0">
                    <thead>
                        <tr>
                            {!noActionsAllowed && !isClusterData && (
                                <td className="pr-0" data-testid="select-all-content">
                                    <SelectAllCheckBox id="allThreatsContent"
                                        displayLabel={false}
                                        options={contents}
                                        values={checkBoxValues}
                                        allCheck={setCheckBoxValues}
                                        page="content-table"
                                    />
                                </td>
                            )}
                            <td className={noActionsAllowed ? '' : 'pl-1'}><strong>Metrics</strong></td>
                            <td><strong>Content</strong></td>
                        </tr>
                    </thead>
                    <tbody data-testid="content-list-items">
                        {contents.map((content) => {
                            const checked = checkBoxValues.map(x => x.id).indexOf(content.id) === -1;
                            const contentLink = getContentLink({
                                id: content.id,
                                threatLevel: content.threatObject?.threatLevel
                            });
                            return (
                                <tr key={content.id}>
                                    {!noActionsAllowed && !isClusterData && (
                                        <td className="pr-0 align-top">
                                            <CustomInput type="checkbox"
                                                checked={!checked}
                                                id={`threatcontent-${content.id}`}
                                                onChange={() => {
                                                    if (!checked) {
                                                        setCheckBoxValues(
                                                            checkBoxValues?.filter(checkBoxValue => checkBoxValue.id !== content.id)
                                                        );
                                                    } else {
                                                        setCheckBoxValues([
                                                            ...checkBoxValues,
                                                            content
                                                        ]);
                                                    }
                                                }}
                                            />
                                        </td>
                                    )}
                                    <TdWithLink className="align-top"
                                        linkClassName="py-2 pl-1 pr-3"
                                        to={contentLink}
                                        disabled={noActionsAllowed}
                                        target={isClusterData ? '_blank' : ''}
                                    >
                                        <ContentMetrics content={content}
                                            noActions={noActionsAllowed}
                                            clusterData={clusterData}
                                        />
                                    </TdWithLink>
                                    <TdWithLink className="align-top" to={contentLink} disabled={noActionsAllowed}>
                                        <Content content={content} noActions={noActionsAllowed} isClusterData={isClusterData} />
                                    </TdWithLink>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            {checkBoxValues.length
                ? (
                    <ContentTableActionsBar selectedContent={checkBoxValues}
                        clearSelection={() => setCheckBoxValues([])}
                        tableType={tableType}
                        contentType={contentType}
                        total={total}
                        onSelectAll={handleSelectAll}
                        contentsToDisplay={contentsToDisplay}
                    />
                )
                : (
                    <div className="border-top bg-white pt-2 pb-11">
                        {pagination && (
                            <Pagination total={pagination.count}
                                itemsPerPage={10}
                                offset={pagination.offset}
                                setOffset={pagination.setOffset}
                            />
                        )}
                    </div>
                )}

        </>
    );
};
