import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Dropdown } from '../../../../../../../../components/Form/Dropdown';
import { SearchInputControlled } from '../../../../../../../../components/Search/SearchInputControlled';
import { narrativeSortOptions } from '../../../../../../Narratives/Overview/Narratives';
import { PageFilters } from '../../../../../../Narratives/PageFilters';

export const NarrativeSearch = ({ onSort, onSearchPhrase, sort }) => {
    const [isOpen, setOpen] = useState(false);
    const filters = useSelector((state) => state.narrativeFilters);
    return (
        <div>
            <div className="d-flex">
                <div className="flex-1">
                    <SearchInputControlled placeholder="Search" handleSearch={onSearchPhrase} />
                </div>
                <Dropdown label="Sort by"
                    options={narrativeSortOptions}
                    value={sort}
                    onChange={onSort}
                    className="d-flex align-items-center mr-2 mb-2"
                />
                <Button onClick={() => setOpen(!isOpen)} className={filters ? 'btn-has-filters mb-2' : 'mb-2'}>
                    Filters
                </Button>
            </div>
            <PageFilters isOpen={isOpen} setOpen={setOpen} />
        </div>
    );
};
