import React from 'react';
import { FormGroup, Label, Input, InputGroup } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { v4 as uuid } from 'uuid';
import { TooltipItem } from '../ToolTip';

export const Attachments = ({ file, setFile, multipleFiles, setMultipleFiles }) => {
    const verifySize = (fileInput) => {
        if (fileInput.size >= 1048576) {
            const sizeInMB = (fileInput.size / 1048576);
            return sizeInMB <= 20;
        }
        return true;
    };
    const fileUploadChange = (e) => {
        if (verifySize(e.target.files[0])) {
            setFile(e.target.files[0]);
            setMultipleFiles([...multipleFiles, e.target.files[0]]);
        } else {
            // eslint-disable-next-line no-alert
            alert('Please ensure size of one file is below 20MB');
        }
        e.target.value = null;
    };
    const removeFileFromUpload = (name) => {
        setMultipleFiles(multipleFiles.filter(singleFile => singleFile.name !== name));
        if (multipleFiles.length === 1) setFile(null);
    };

    return (
        <>
            <FormGroup className="mb-2">
                <Label className="mt-1">
                    <strong className="pr-1">Attachments</strong>
                    <TooltipItem id="attachmentsTrigger"
                        content="Any more evidence in form of video,
                                image or pdf you want to provide that our investigations team can use"
                        Icon={FeatherIcon.Info}
                    />
                </Label>
                <InputGroup className="d-flex align-items-center">
                    <FormGroup>
                        <span>
                            <label className="mb-0 upload-file cursor-pointer">
                                <FeatherIcon.Paperclip color="#006FF9" size={15} className="mr-11" />
                                <Input onChange={(e) => { fileUploadChange(e); }}
                                    type="file"
                                    accept=".pdf,application/msword,application/msexcel
                                                ,image/*,audio/*,video/*,.docx,.doc,.xml"
                                    className="d-none"
                                    data-testid="file-upload"
                                />
                                <b className="text-primary">Choose file</b>
                            </label>
                        </span>
                    </FormGroup>
                    <span className="ml-2 mb-1 d-inline-block rounded">{file ? file.name : 'No file choosen'}</span>
                </InputGroup>
            </FormGroup>
            {multipleFiles.length > 0 && (
                <FormGroup className="mb-2">
                    <h3>Uploaded Files</h3>
                    <ul>
                        {multipleFiles.length > 0 && multipleFiles.map((fileItem) => (
                            <li key={uuid()}>
                                <div className="d-flex align-items-center">
                                    {fileItem.name} {' '}
                                    <FeatherIcon.Trash2 size={15}
                                        className="ml-1"
                                        onClick={() => { removeFileFromUpload(fileItem.name); }}
                                        data-testid={`${fileItem.name}-x-icon`}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </FormGroup>
            )}
        </>
    );
};
