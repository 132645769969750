import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { X, ChevronLeft } from 'react-feather';
import { useQuery } from '@apollo/client';
import { SavedFilterRow } from './SavedFilterRow';
import { SortToggle } from '../../Table/SortToggle';
import { Loading } from '../../Loading';
import { Pagination } from '../../Molecules';
import { GET_ROOM_FILTERS, RoomFilterProps } from '../FiltersLayout';
import { EditSavedFilter } from './EditSavedFilter';
import { FiltersDataProps, SaveFilterButton } from '../components/SaveFilter';
import { useUser } from '../../../context/user/UserContext';
import { Images } from '../../../utils/modeSwitch';

type EditFilterDataProps = {
    data: FiltersDataProps[],
    dateRange: {
        startDate?: number,
        endDate?: number
    }
}

type ManageSavedFiltersSort = {
    order: 'asc' | 'desc'
    field: 'project_name'
}

export const ManageSavedFilters = () => {
    const location = useLocation();
    const { user } = useUser();
    const roomId = location.pathname.split('/')[2];
    const [filterName, setFilterName] = useState('');
    const [editFilterData, setEditFilterData] = useState<EditFilterDataProps>({ data: [], dateRange: {} });
    const [openEditSavedFIlter, setOpenEditSavedFIlter] = useState(false);
    const [editFilterId, setEditFilterId] = useState('');
    const [sort, setSort] = useState({ field: 'project_name', order: 'desc' } as ManageSavedFiltersSort);
    const [offset, setOffset] = useState(0);
    const history = useHistory();
    const { data, loading } = useQuery(GET_ROOM_FILTERS, {
        variables: {
            situationRoom: roomId,
            limit: 5,
            skip: offset,
        },
        fetchPolicy: 'no-cache'
    });
    const handleEditSavedFilter = (filterId: string) => {
        setEditFilterId(filterId);
        setOpenEditSavedFIlter(true);
    };

    const filtersData = (data?.getRoomFilters?.filters || []) as RoomFilterProps[];
    const totalCount = (data?.getRoomFilters?.filtersCount || 0) as number;

    return (
        <div className="bg-white d-flex flex-column flex-1">
            <div className="bg-white border-bottom py-3 px-4 d-flex align-items-center justify-content-between">
                {!openEditSavedFIlter ? <h2 className="m-0">Manage saved filters</h2> : (
                    <h2 className="d-flex cursor-pointer text-center">
                        <span className="mr-2">
                            <ChevronLeft size={20} color="#006FF9" onClick={() => setOpenEditSavedFIlter(false)} />
                        </span>
                        Edit saved filter
                    </h2>
                )}
                <div role="button"
                    onClick={() => { !openEditSavedFIlter ? history.goBack() : setOpenEditSavedFIlter(false); }}
                >
                    <X size={18} />
                </div>
            </div>
            <div className="d-flex flex-column flex-1">
                {loading && <Loading gif relative height={400} />}
                {(!openEditSavedFIlter && !loading && totalCount > 0) && (
                    <div className="pb-2">
                        <div className="border-bottom border-right border-left m-4">
                            <Table className="mb-0">
                                <thead>
                                    <tr>
                                        <th>
                                            <SortToggle sort={sort} setSort={setSort} name="Filter name" field="project_name" />
                                        </th>
                                        <th>Created by</th>
                                        <th>Created on</th>
                                        <th>Selected filters</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtersData.map((filter: RoomFilterProps) => (
                                        <tr key={filter.id}>
                                            <SavedFilterRow filter={filter}
                                                handleEditSavedFilter={handleEditSavedFilter}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <Pagination total={totalCount}
                            itemsPerPage={5}
                            offset={offset}
                            setOffset={setOffset}
                            className="p-2 bg-white border-top"
                        />
                    </div>
                )}
                {(openEditSavedFIlter && !loading) && (
                    <EditSavedFilter filterData={filtersData.filter((a: RoomFilterProps) => a.id === editFilterId)}
                        filterId={editFilterId}
                        filterName={filterName}
                        setFilterName={setFilterName}
                        setEditFilterData={setEditFilterData}
                    />
                )}
                {(totalCount === 0 && !loading) && (
                    <div className="border d-flex flex-column align-items-center justify-content-center p-4 m-4 h-50">
                        <img src={Images().overview}
                            alt="no-data-found"
                        />
                        <h2 className="my-2">There&apos;s nothing to see here</h2>
                        <Button onClick={() => history.goBack()} color="secondary">Close</Button>
                    </div>
                )}
            </div>
            <div className="border-top d-flex justify-content-end">
                <Button color="link my-2 mr-1" onClick={() => history.goBack()}>Close</Button>
                <div className="pt-2 mr-2">
                    {openEditSavedFIlter && (
                        <SaveFilterButton text="Save"
                            filtersData={editFilterData.data}
                            filterId={editFilterId}
                            filterName={filterName}
                            userID={user.id}
                            dateRange={editFilterData.dateRange}
                            setOpenEditSavedFIlter={setOpenEditSavedFIlter}
                        />
                    )}
                </div>

            </div>
        </div>

    );
};
