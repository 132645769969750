import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { findAndReplaceInObject } from '../../../../utils/lib/findAndReplaceInObject';

export const useQueryValidator = ({ room, skip = false, lazy, validateIngestionQuery = true }) => {
    const newQuery = {
        boolean: room?.query_type === 'boolean' ? room.query.boolean : '',
        included: room?.query_type === 'keyword' ? room.query.included : [],
        excluded: room?.query_type === 'keyword' ? room.query.excluded : []
    };
    if (newQuery.boolean?.split(' ').includes('##BW##')) {
        newQuery.boolean = newQuery.boolean?.split('##BW##')[0];
    }
    else if (newQuery.boolean?.split(' ').includes('##BW_ID##')) {
        newQuery.boolean = newQuery.boolean?.split('##BW_ID##')[0];
    }
    else if (newQuery.boolean?.split(' ').includes('##CT_ID##')) {
        newQuery.boolean = newQuery.boolean?.split('##CT_ID##')[0];
    }
    const postData = {
        query: JSON.parse(JSON.stringify(newQuery), (key, value) => (value === null ? undefined : value)),
        query_type: room?.query_type,
        count_historics: false
    };
    const shouldSkip = () => {
        if (skip || !room || lazy) {
            return true;
        }
        if (room.query_type === 'keyword') {
            if (room.query.included.find(a => a.type === 'unknown') || room.query.excluded.find(a => a.type === 'unknown')) {
                return true;
            }
        }
        return false;
    };
    const { loading, data } = useQuery(QUERY_VALIDATOR, {
        variables: {
            data: postData,
            validateIngestionQuery
        },
        skip: shouldSkip()
    });
    const [validateQuery, { loading: loadingB, data: dataB }] = useLazyQuery(QUERY_VALIDATOR, {
        variables: {
            data: postData,
            validateIngestionQuery
        }
    });
    const ret = lazy ? dataB : data;
    const defaultRet = lazy ? { newQuery } : false;
    return {
        loading: lazy ? loadingB : loading,
        data: ret ? {
            isValid: ret.validateQuery.isValid,
            newQuery,
            query: findAndReplaceInObject(ret.validateQuery.query || {}, 'minimum_should_match', (a) => a.toString()),
            errors: ret.validateQuery.errors || [],
            keywords: ret.validateQuery.keywords
        } : defaultRet,
        validateQuery
    };
};

export const QUERY_VALIDATOR = gql`
    query validateQuery ($data: JSON, $validateIngestionQuery: Boolean){
        validateQuery (data: $data, validateIngestionQuery: $validateIngestionQuery) {
            isValid
            query
            errors {
                message
                startColumn
                endColumn
                startRow
                endRow
                errorCode
                type
            }
            keywords
        }
    }
`;
