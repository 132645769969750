/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useApolloClient } from '@apollo/client';
import { X } from 'react-feather';
import { ProgressBar } from '../../../ProgressBar';
import { useContentLazy } from '../../../../services/Content/getContent';
import { getRoom } from '../../../../utils/variables';
import { BatchRemoveCancelledModal } from './BatchRemoveCancelledModal';
import { RemoveContentActions, useRemoveContent } from '../../../CommonModals/useRemoveContent';
import { BatchRemoveNoContentsModal } from './BatchRemoveNoContentsModal';
import { useModal } from '../../../../context/modal/ModalComponent';
import { BatchRemovePreparingModal } from './BatchRemovePreparingModal';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

/*
 * This modal will process the bulk removal of all documents provided by search query
 * It will fetch data and start removing data recursively until the job is done
 *
 * It is possible to pause the operation in which case it will pause from the next batch
 */

type GetQueryProps = {
    onCompleted?: ({ total, timeInSeconds }: { total: number, timeInSeconds: number }) => void;
    onAbort: () => void;
    contentType: string;
    room: {
        projectId: string;
        projectName: string;
    };
};

const getQuery = ({ contentType, room }: GetQueryProps) => {
    if (contentType.toString() === CONTENT_TYPE.THREAT) {
        return {
            filters: {
                isThreat: true
            },
            limit: 100,
            sort: 'date',
            fetchPolicy: 'no-cache',
            skipRequest: !room.projectId,
            name: 'getThreats',
            useGlobalFilters: true,
            fragments: ['Metadata']
        };
    }

    if (contentType.toString() === CONTENT_TYPE.MATCH) {
        return {
            limit: 100,
            sort: 'date',
            fetchPolicy: 'no-cache',
            name: 'getMatches',
            useGlobalFilters: true
        };
    }

    // Return a default query object if none of the conditions are met
    return {
        filters: {},
        limit: 100,
        sort: 'date',
        fetchPolicy: 'no-cache',
        skipRequest: true,
        name: '',
        useGlobalFilters: true,
        fragments: []
    };
};

type BatchRemoveModalProps = {
    onCompleted?: ({ total, timeInSeconds }: { total: number, timeInSeconds: number }) => void;
    onAbort: () => void;
    contentType: string;
};
export const BatchRemoveModal = ({ onCompleted, onAbort, contentType }: BatchRemoveModalProps) => {
    const { closeModal } = useModal();
    const room = getRoom();
    const apolloClient = useApolloClient();
    const [timeStart, setTimeStart] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [firstFetch, setFirstFetch] = useState(false);
    const [lastContent, setLastContent] = useState('[]');
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [removalPending, setRemovalPending] = useState(false);

    const {
        getContent,
        data: content,
        count: currentTotal,
        loading: fetchPending
    } = useContentLazy(getQuery({ contentType, room } as GetQueryProps));

    const { removeContent, loading: removeRequestProcessing } = useRemoveContent({
        type: contentType,
        action: RemoveContentActions.RemoveContent,
        refetchQueries: [],
    });

    const handleAbort = () => {
        // Refresh all data to prevent displaying outdated Threats after pausing the bulk action
        apolloClient.refetchQueries({ include: 'all' });
        onAbort();
    };

    const handleCancel = () => {
        if (totalDocuments === 0) return closeModal();
        setIsPaused(true);
    };

    const handleCompleted = () => {
        const totalTimeInSeconds = (moment.now() - timeStart) / 1000;
        // Refresh all data to prevent displaying outdated Threats after pausing the bulk action
        apolloClient.refetchQueries({ include: 'all' });
        if (onCompleted) {
            onCompleted({ total: totalDocuments, timeInSeconds: totalTimeInSeconds });
        }
    };

    const contentJson = useCallback(() => JSON.stringify(content.map((item: any) => ({ id: item.id }))), [content])();
    const isCompleted = firstFetch && !fetchPending && !isPaused && !removalPending && currentTotal === 0 && content.length === 0;
    const dataStateChanged = !fetchPending && currentTotal > totalDocuments;
    const pausePending = isPaused && removalPending;

    // Initial data fetch
    useEffect(() => {
        if (!firstFetch) {
            setTimeStart(moment.now());
            setFirstFetch(true);
            getContent();
        // Sets initial data and prevents negative progress when new data appears faster than we remove
        } else if (dataStateChanged) {
            setTotalDocuments(currentTotal);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTotal, dataStateChanged, firstFetch]);

    // Waiting for the data state to change (either removed by this process, other user or new data appears)
    useEffect(() => {
        if (removalPending && !fetchPending && contentJson === lastContent) {
            setTimeout(() => {
                getContent();
            }, 2000);
        } else if (removalPending && !fetchPending && !removeRequestProcessing && contentJson !== lastContent) {
            setRemovalPending(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentJson, fetchPending, lastContent, removalPending, removeRequestProcessing]);

    // Remove batch logic
    useEffect(() => {
        if (isCompleted) {
            handleCompleted();
        } else if (contentJson !== lastContent && !fetchPending && !isPaused && !removalPending && content.length > 0) {
            const contentIds = content.map((item: any) => ({ id: item.id }));
            // Limits the risk of removing the same data twice due to race condition
            setLastContent(JSON.stringify(contentIds));
            setRemovalPending(true);
            removeContent(contentIds);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content, contentJson, fetchPending, isCompleted, isPaused, lastContent, removalPending, timeStart, totalDocuments]);

    if (firstFetch && !fetchPending && totalDocuments === 0 && currentTotal === 0) {
        return <BatchRemoveNoContentsModal />;
    }

    if (totalDocuments === 0) {
        return <BatchRemovePreparingModal handleCancel={handleCancel} />;
    }

    if (isPaused && !removalPending && !fetchPending) {
        return <BatchRemoveCancelledModal onResume={() => setIsPaused(false)} onAbort={handleAbort} contentsToProcess={currentTotal} />;
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <h2 className="mt-0">In progress</h2>
                <X color="#050E2B"
                    size={20}
                    data-testid="close-modal"
                    className="cursor-pointer mt-1"
                    stroke="#050E2B"
                    onClick={handleCancel}
                />
            </div>
            <hr className="mb-2 mt-0" />
            <BatchRemoveModalContent totalDocuments={totalDocuments} currentTotal={currentTotal} pausePending={pausePending} />
            <hr className="my-2" />
            <Button className="mr-2" color="secondary" disabled={pausePending} onClick={handleCancel}>Cancel</Button>
        </>
    );
};
type BatchRemoveModalContentProps = {
    totalDocuments: number;
    currentTotal: number;
    pausePending: boolean;
};
const BatchRemoveModalContent = ({ totalDocuments, currentTotal, pausePending }: BatchRemoveModalContentProps) => {
    const room = getRoom();
    const removedCount = totalDocuments > 0 ? totalDocuments - currentTotal : 0;
    let description = 'Bulk removal in progress';

    if (pausePending) {
        description = 'Cancelling, please wait';
    }

    return (
        <>
            <div className="mb-1">
                <FontAwesomeIcon icon={faSpinner as any} className="mr-1 fa-spin" /> {description}
            </div>
            <ProgressBar max={totalDocuments} current={removedCount < 0 ? 0 : removedCount} />
            <p className="mt-1">There are {currentTotal} contents left to be removed from {room.project_name}</p>
        </>
    );
};
