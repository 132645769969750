import React from 'react';
import { Button } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { Timeline } from './Timeline';
import { Tabs } from '../../../../components/Tabs';
import { Content } from './Content';
import { FactCheck } from './FactCheck';
import { Loading } from '../../../../components/Loading';
import { GET_NARRATIVES } from '../../../../graphql';
import { elasticFilterWithProjectId } from '../../../../utils/elasticFilterWithProjectId';

export const NarrativePerformance = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const prevTab = location.state?.prevTab;
    const history = useHistory();
    const { data, loading, refetch } = useQuery(GET_NARRATIVES, {
        variables: {
            query: elasticFilterWithProjectId([{
                ids: {
                    values: match.params.id
                }
            }])
        },
    });
    return (
        <div>
            <Button className="go-back-from-dashboard text-decoration-none align-items-center"
                color="link"
                onClick={history.goBack}
            >
                <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                <span>Back</span>
            </Button>
            <h2 className="mt-4 mb-0">How is the narrative performing?</h2>
            {loading ? (
                <div className="minh-200 position-relative">
                    <Loading />
                </div>
            ) : (
                <div className="mt-4">
                    <Tabs prevKey={prevTab}
                        data={[
                            {
                                name: 'Timeline',
                                id: 'timeline',
                                content: <Timeline data={data?.newNarrativeServiceSearch?.hits[0]?._source} />,
                            },
                            {
                                name: 'Content',
                                id: 'content',
                                content: <Content data={data?.newNarrativeServiceSearch?.hits[0]}
                                    refetch={refetch}
                                />,
                            },
                            {
                                name: 'Fact checks',
                                id: 'fact-check',
                                content: <FactCheck data={data?.newNarrativeServiceSearch?.hits[0]?._source} />,
                            }
                        ]}
                    />
                </div>
            )}
        </div>
    );
};
