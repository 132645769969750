import moment from 'moment';
import React from 'react';
import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useQueryExtracts } from '../../../../../services/Content/getQueryExtracts';
import { getContentLink } from '../../../../../utils/getContentLink';
import { getContentText } from '../../../../../utils/getContentText';
import { getHighlightedContent } from '../../../../../utils/getHighlightedContent';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { truncateString } from '../../../../../utils/text';

export const ContentModalInner = ({ content, id }) => {
    const { closeModal } = useModal();

    const post = content[0]._source;
    const { extracts } = useQueryExtracts({
        url: content.url,
        text: post.body || post.text
    });
    const contentText = getContentText({ text: post.text, body: post.body, extracts });
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="m-0">Post details</h2>
                <X onClick={closeModal} role="button" />
            </div>
            <hr />
            <div className="d-flex align-items-start">
                <p className="mr-1"><strong>Content:</strong></p>
                <p className="dont-break-out">
                    {getHighlightedContent(truncateString(contentText[0], 500))}
                </p>
            </div>
            <hr />
            <p><strong>Engagement:&nbsp;</strong>{post.engagement < 0 ? 0 : post.engagement}</p>
            <p><strong>Posted by:&nbsp;</strong>{getPostedByName(post.from, post.display_name)}</p>
            <p><strong>Posted on:&nbsp;</strong>{moment(new Date(post.datetime_tz)).format('DD/MM/YYYY @ hh:mm')}</p>
            <p><strong>Location Mentions:&nbsp;</strong>{post.locations_detected}</p>
            <hr />
            <Button onClick={closeModal} className="mr-2">Close</Button>
            <Link to={getContentLink({ id, threatLevel: post.threat_object.threat_level })} onClick={closeModal}>
                <Button color="primary">View content source</Button>
            </Link>
        </div>
    );
};
