import React from 'react';
import { ContentTableView } from '../Organisms/ContentTableView';

type ThreatsContentTableProps = {
    isPreview?: boolean
}

export const ThreatsContentTable = ({ isPreview }: ThreatsContentTableProps) => (
    <>
        This is the placeholder for Threats Content Table {isPreview && '(Preview)'}
        <ContentTableView />
    </>
);
