import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { merge } from 'lodash';
import { SmallPagination } from '../../../../../../components/Molecules';
import { CONTENT_ES_SERVICE } from '../../../../../../graphql';
import { capitalizeFirstLetter } from '../../../../../../utils/text';
import { ThreatItem } from './ThreatItem';
import { Loading } from '../../../../../../components/Loading';
import { checkFilters } from '.';

export const ThreatsList = ({ state, threat, projectId }) => {
    const { series, order, direction, selection, startDate, endDate } = state;
    const { threat: name } = threat;
    const color = series
        ? series.filter((a) => a?.name === threat.threat)[0]?.color
        : '#333';
    const sort = `${order}${direction}`;
    const [page, setPage] = useState(0);
    const { data, loading } = useQuery(CONTENT_ES_SERVICE, {
        variables: {
            limit: 10,
            skip: page * 10,
            sort,
            query: threat.query_type === 'query' ? merge({
                boosting: {
                    positive: {
                        bool: {
                            filter: [
                                {
                                    term: {
                                        project_id__keyword: {
                                            value: projectId,
                                        },
                                    },
                                },
                                {
                                    range: {
                                        date: {
                                            gte: (selection !== null
                                                 || startDate !== null) ? new Date(selection || startDate * 1000) : null,
                                            lte: (selection !== null || endDate !== null) ? new Date(selection || endDate * 1000) : null,
                                        },
                                    },
                                }
                            ],
                        },
                    },

                }

            }, threat.dslQuery) : checkFilters(threat, 'EsQuery', startDate, endDate, projectId, selection),
            opts: {
                trackTotalHits: true
            }
        },
    });
    const threats = data?.contentServiceSearch?.hits || [];
    const totalDataCount = data?.contentServiceSearch?.count;
    if (loading) {
        return (
            <Loading relative height={300} />
        );
    }

    return (
        <div key={name} className="content-deck">
            <div className="content-deck-header">
                <div style={{ background: `${color}` }} className="content-deck-header-color" />
                <h3>{capitalizeFirstLetter(name)} ({totalDataCount && totalDataCount})</h3>
            </div>
            <div className="content-wrapper">
                {threats && threats.map((threatItem) => (
                    <ThreatItem key={threatItem.id} threat={threatItem} />
                ))}
            </div>
            <div className="content-wrapper mt-1">
                <div className="custom-narrative-pagination">
                    <SmallPagination total={totalDataCount || 0}
                        itemsPerPage={10}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            </div>
        </div>
    );
};
