import React from 'react';
import { Col } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { formatDate, formatDateLocal } from '../../../../utils/getTimeFrom';
import { ContentPreview } from '../../../../components/ContentPreview';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { getRoom } from '../../../../utils/variables';

export const RightPanel = ({ data }) => {
    const hasLocations = data.locationsDetected.filter(a => a !== 'Other').length > 0;
    const room = getRoom();
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;
    return (
        <Col xs="12" lg="4" xl="3" className="right-side-bar pl-4  py-4">
            <div className="right-bar">
                <div className={`${data.contentType === 'facebook' ? 'pb-0 mb-0' : ' mb-4'}`}>
                    <div className="content-reach-title">Content Preview</div>
                    <ContentPreview data={data} />
                </div>
                <div className="account-info mb-4">
                    <div className="content-reach-title">Content details</div>
                    <hr />
                    <div>Posted on</div>
                    <h3 className={`sub-content ${dateInLocalTimeZone ? 'mb-0' : ''}`}>
                        {formatDate(data.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}
                    </h3>
                    {dateInLocalTimeZone && (
                        <h3 className="sub-content mt-0">
                            {formatDateLocal(data.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}
                        </h3>
                    )}
                    {data.url
                    && (
                        <div className="account-item">
                            <div>Link to content</div>
                            <a className="sub-content d-block mt-1 " target="_blank" rel="noreferrer" href={data.url}>
                                View content source
                            </a>
                        </div>
                    )}
                </div>

                <div className="bg-white mb-4">
                    <div className="p-2">
                        <h3 className="m-0">Location Mentions</h3>
                    </div>
                    <hr className="m-0" />
                    <div>
                        {hasLocations ? (
                            <div>
                                {data.locationsDetected.map((location, index) => (
                                    <div key={location}
                                        className={
                                            `${index !== data.locationsDetected.length - 1 ? 'border-bottom' : ''} p-2
                                            d-flex justify-content-between align-items-center`
                                        }
                                    >
                                        <p className="m-0 font-weight-bold">{location}</p>
                                        <a href={`https://www.google.co.uk/maps/place/${location}`} target="_blank" rel="noreferrer">
                                            <FeatherIcon.Map className="text-primary" />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="unknown-location bg-white">
                                <div className="text">
                                    <div>
                                        <FeatherIcon.Map color="#d8d8d8" size={25} />
                                    </div>
                                    <div className="description">No location Available</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="account-info mb-4">
                    <div className="content-reach-title d-flex">Account details
                    </div>
                    <hr />
                    <div className="account-item">
                        <h3>{data.displayName}</h3>
                        <SourceIconLabel data={data} link className="d-flex" />
                    </div>
                </div>
            </div>
        </Col>
    );
};
