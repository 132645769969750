import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { KeywordsEntry } from './KeywordsEntry';
import { BooleanInputValidated } from '../../../../../../../components/Molecules/BooleanInputValidated';
import { saveCustomNarratives } from '../../../../../store';
import { validateQuery } from '../../../../../../SituationRooms/RoomEditor/RoomPreview/validateQuery';

export const EditorRightPanel = ({ booleanSwitch }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const { selectedTopic, topics, queryErrorList } = useSelector(state => state.customNarratives);
    const [keywords, setKeywords] = useState(selectedTopic?.keywords || []);
    const [booleanInputValue, setBooleanInputValue] = useState(selectedTopic?.boolean || '');
    const [validationErrors, setValidationErrors] = useState({});
    const booleanInputValueRef = useRef(booleanInputValue);
    const selectedTopicRef = useRef(selectedTopic);
    const queryErrorListRef = useRef(queryErrorList);
    const updateTopic = (updatedValue) => {
        let newTopics = [];
        topics.forEach((topic) => {
            if (topic.threat === selectedTopic.threat) {
                const updatedTopic = { ...topic };
                if (!booleanSwitch) {
                    updatedTopic.keywords = updatedValue === 'clear' ? [] : updatedValue;
                    setKeywords(updatedTopic.keywords);
                }
                else {
                    updatedTopic.boolean = updatedValue;
                    setBooleanInputValue(updatedTopic.boolean);
                    booleanInputValueRef.current = updatedValue;
                    dispatch(saveCustomNarratives({ selectedTopic: { ...selectedTopic, boolean: updatedValue } }));
                }
                updatedTopic.query_type = (booleanSwitch && updatedTopic.boolean) ? 'query' : 'keywords';
                newTopics = [...newTopics, updatedTopic];
            }
            else {
                newTopics = [...newTopics, topic];
            }
        });
        dispatch(saveCustomNarratives({ topics: newTopics }));
    };
    const handleValidationErrors = (value) => {
        let updatedList = [];
        updatedList = queryErrorListRef.current.map((a) => {
            if (a.threat === selectedTopicRef.current.threat) {
                const newQuery = { ...a };
                newQuery.error = value;

                return newQuery;
            }
            return a;
        });

        dispatch(saveCustomNarratives({ queryErrorList: updatedList }));
        setValidationErrors(value);
    };
    const fetchData = useCallback(async () => {
        const values = Object.values(validationErrors);
        const isErrorList = values.filter((a) => a.numberOfIssues >= 1);
        if (booleanInputValueRef.current && isErrorList.length === 0) {
            const room = { query_type: 'boolean', query: { boolean: booleanInputValueRef.current } };
            const { validateQueryResponse, isValid } = await validateQuery({ client,
                room,
                validateIngestionQuery: false });
            let updatedTopicsList = [];
            updatedTopicsList = topics.map((a) => {
                if (a.threat === selectedTopic.threat) {
                    const newTopic = { ...a };
                    newTopic.dslQuery = validateQueryResponse?.query;
                    newTopic.isDslQueryValid = isValid;
                    return newTopic;
                }
                return a;
            });
            dispatch(saveCustomNarratives({ topics: updatedTopicsList }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booleanInputValueRef, client, dispatch, selectedTopic.threat, validationErrors]);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const values = Object.values(validationErrors);
            const isErrorList = values.filter((a) => a.numberOfIssues >= 1);
            if (booleanInputValueRef.current && isErrorList.length === 0) {
                fetchData();
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [booleanInputValueRef, fetchData, validationErrors]);

    useEffect(() => {
        selectedTopicRef.current = selectedTopic;
        setKeywords(selectedTopic?.keywords || []);
        setBooleanInputValue(selectedTopic?.boolean || '');
        queryErrorListRef.current = queryErrorList;
    }, [queryErrorList, selectedTopic]);

    return (
        <div className="d-flex flex-column flex-1 bg-white h-100">
            {(!booleanSwitch && (selectedTopic?.isSaved ? selectedTopic?.query_type === 'keywords' : true)) ? (
                <div>
                    <div className="d-flex flex-column mb-3">
                        <strong className=" mb-1">Enter keywords for selected topic</strong>
                        <p>You can enter words, strings, URL/Domains, social media account and names. Separate keywords using a comma.</p>
                    </div>
                    <KeywordsEntry keywords={keywords}
                        setKeywords={updateTopic}
                        clearKeywords={() => updateTopic('clear')}
                    />
                </div>
            ) : (
                <div className="d-flex flex-column flex-1">
                    <h5 className="mb-2">Enter boolean query for selected topic</h5>
                    <div className="border rounded d-flex flex-column flex-1 narrative-right-panel">
                        <BooleanInputValidated booleanQuery={booleanInputValue}
                            setBooleanQuery={updateTopic}
                            validationErrors={validationErrors}
                            setValidationErrors={handleValidationErrors}
                            customNarrative
                        />
                    </div>
                </div>
            )}
        </div>

    ); };
