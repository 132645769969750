import React, { useState } from 'react';
import { TooltipItem } from '../../../../components/ToolTip';

export const LocationMentions = ({ handleChange, locationData }) => {
    const [hoverIndex, setHoverIndex] = useState(0);
    const [mouseHover, setMouseHover] = useState(false);

    if (!locationData) return null;
    return (
        <>
            <h3 className="d-inline">Location Mentions<TooltipItem placement="right"
                tooltipOpen="locationmentions"
                id="locationMentions"
                content="Top locations mentioned in the content across this Narrative."
            />
            </h3>
            {locationData.map((item, index) => (
                <div className={`outer-box ${index === 0 ? 'mt-1' : ''}`}
                    onMouseEnter={() => { handleChange(item?.location); setMouseHover(true); setHoverIndex(index); }}
                >
                    <div className={`inner-box ${mouseHover && hoverIndex === index ? 'mouse-hover-effect' : null}`}>
                        <p className="text-left">
                            <strong>{item?.location}</strong>
                        </p>
                        <div className="text-left">Mentions: {item?.count}</div>
                    </div>
                </div>
            ))}
        </>
    );
};
