import React from 'react';
import { KeywordsLegend } from './KeywordsLegend';
import { SplineChart } from '../../../../../components/Highcharts/Spline';

export const KeywordsChart = ({ formattedChart: keySeries, chartData }) => {
    const seriesData = chartData?.map((item) => ({
        data: item?.data.length > 0 && formatChartData(item?.data, 'volume'),
        color: item?.color,
        name: item?.keyword,
        marker: {
            enabled: false,
            symbol: 'circle'
        }
    }));

    return (
        <div className="pt-2 bg-white h-100" data-testid="keywords-chart">
            <div className="pl-3 pr-3 bg-white">
                <SplineChart title="Top 4 keywords - matches over time" height={370} seriesData={seriesData} />
                <KeywordsLegend series={keySeries} />
            </div>
        </div>
    );
};

export const formatChartData = (content, type) => {
    let dataCopy = JSON.stringify(content);
    dataCopy = JSON.parse(dataCopy);
    const ret = [];
    let interval = 3600; // default interval is 1 hour
    const dataInOrder = dataCopy.sort((a, b) => a.pubdatetime - b.pubdatetime);
    const minDate = dataInOrder[0]?.pubdatetime;
    const maxDate = dataInOrder[dataInOrder.length - 1]?.pubdatetime;
    if (maxDate - minDate > 259200) {
    // if the difference is more than 3 days then set the interval to 1 day
        interval = 86400;
    }
    if (maxDate - minDate > 7776000) {
    // if the difference is more than 3 months then set the interval to 1 month
        interval = 2592000;
    }
    if (type === 'volume') {
        for (const item of dataInOrder) {
            const numToAdd = item.volume;
            if (!ret[0]) {
                ret.push([item.pubdatetime, numToAdd]);
            } else if (ret[ret.length - 1][0] + interval > item.pubdatetime) {
                ret[ret.length - 1][1] += numToAdd;
            } else {
                ret.push([item.pubdatetime, numToAdd]);
            }
        }
    }
    else {
        for (const item of dataInOrder) {
            const numToAdd = type === 'engagement' ? item.engagement : 1;
            if (!ret[0]) {
                ret.push([item.pubdatetime, numToAdd]);
            } else if (ret[ret.length - 1][0] + interval > item.pubdatetime) {
                ret[ret.length - 1][1] += numToAdd;
            } else {
                ret.push([item.pubdatetime, numToAdd]);
            }
        }
    }
    return ret.map((a) => ({ x: new Date(a[0] * 1000).getTime(), y: a[1] }));
};
