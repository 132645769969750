import React from 'react';
import {
    Copy,
    Disc,
    FileText,
    Frown,
    Meh,
    PieChart,
    Smile
} from 'react-feather';

// This is a file to manadate a particular icon for a particular use case throughout the application

export const getIcons = (icon: string, sentiment?: string) => {
    switch (icon) {
        case 'engagement': { return <PieChart size={20} color="#006FF9" />; }
        case 'subClusters': { return <Disc size={20} color="#006FF9" />; }
        case 'contents': { return <FileText size={20} color="#006FF9" />; }
        case 'duplicates': { return <Copy size={20} color="#006FF9" />; }
        case 'sentiment': {
            if (sentiment === 'positive') return <Smile size={20} color="#13BA9C" />;
            if (sentiment === 'negative') return <Frown size={20} color="#F84367" />;
            return <Meh size={20} color="#FCCD13" />;
        }
    }
};
