import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EmptyState } from '../../../../components/EmptyState';
import { useGetWatchlist } from '../../../../services/Threat/getWatchlist';
import NotFoundFC from '../../../../assets/images/noFactChecks.svg';
import { Loading } from '../../../../components/Loading';
import { SearchBar } from '../../../../components/Search';
import { getRoom } from '../../../../utils/variables';
import { ThreatsWatchlistCsv } from './ThreatsWatchlistCsv';
import { ContentTableTypes, ContentTables } from '../../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

const initialState = {
    dateRangeOption: 'all',
    dateRange: {},
    sources: [],
    origin: [],
    sentiment: [],
    threatTypes: [],
    query: ''
};

export const WatchListThreats = () => {
    const [offset, setOffset] = useState(0);
    const match = useRouteMatch();
    const room = useReactiveVar(getRoom);
    const [sort, setSort] = useState({ sort: 'datetime_tz__desc' });
    const filters = useSelector((state) => state.watchlistFilters.filters);
    let filteredContentData;
    const [types] = useState([
        'fn-type',
        'deep-toxic-classifier',
        'src-cred',
        'automation_score',
        'custom',
        'account_based'
    ]);

    const { watchlist, loading } = useGetWatchlist({
        situationRoomId: match.params.situation,
        getFullContent: true
    });

    const sortApplied = (sortNum) => {
        switch (sortNum) {
            case 1:
                setSort({ sort: 'datetime_tz__desc' });
                break;
            case 2:
                setSort({ sort: 'engagement__desc' });
                break;
        }
        setOffset(0);
    };

    const sortData = (data) => {
        let newData;
        if (sort.sort === 'datetime_tz__desc') {
            newData = data.sort((a, b) => new Date(parseInt(b.pubdatetime, 10) * 1000) - new Date(parseInt(a.pubdatetime, 10) * 1000));
        }
        if (sort.sort === 'engagement__desc') {
            newData = data.sort((a, b) => parseInt(b.engagement, 10) - parseInt(a.engagement, 10));
        }
        return newData;
    };

    if (watchlist && (filters === initialState)) {
        filteredContentData = sortData(watchlist);
    } else if (watchlist) {
        let tempData;
        // Filter by date
        if (filters.dateRange?.startDate) {
            const startDate = new Date(filters.dateRange.startDate * 1000);
            const endDate = new Date(filters.dateRange.endDate * 1000);
            tempData = watchlist.filter(
                watchlistItem => new Date(watchlistItem.pubdatetime * 1000) > startDate
                    && new Date(watchlistItem.pubdatetime * 1000) < endDate
            );
        }
        // Filter by source
        if (filters.sources.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => !filters.sources.some((b) => b === watchlistItem.contentType)
            );
        }
        // Filter by sentiment
        if (filters.sentiment.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => !filters.sentiment.some((b) => b === watchlistItem.sentiment)
            );
        }
        // Filter by origin
        if (filters.origin.length > 0) {
            tempData = (tempData || watchlist).filter(
                watchlistItem => !filters.origin.some((b) => b === watchlistItem.locationsDetected[0])
            );
        }
        // Filter by query string
        if (filters.query?.trim() !== '') {
            tempData = (tempData || watchlist).filter(
                watchlistItem => (watchlistItem.body?.length > 0 ? watchlistItem.body : watchlistItem.text).includes(filters.query)
            );
        }

        // Filter by threat type
        if (filters.threatTypes.length > 0) {
            const filterTypes = types.filter((a) => filters.threatTypes.indexOf(a) !== -1);
            if (tempData !== undefined) {
                tempData = tempData.filter(
                    watchlistItem => !filterTypes.some((b) => watchlistItem.threatObject.threatLabels.some((c) => b === c))
                );
            } else {
                tempData = watchlist.filter(
                    watchlistItem => !filterTypes.some((b) => watchlistItem.threatObject.threatLabels.some((c) => b === c))
                );
            }
        }

        filteredContentData = sortData(tempData || watchlist);
    }

    const linkToThreats = { name: 'View threats', to: `/situation-rooms/${room.id}/threats` };

    if (loading && watchlist.length === 0) {
        return (
            <div className="minh-200 position-relative">
                <Loading relative />
            </div>
        );
    } if (watchlist.length === 0) {
        return (
            <EmptyState title="No threats added to watchlist"
                summary="Check threats section and use 'Add to watchlist' button to add the threats to this space"
                icon={NotFoundFC}
                action={linkToThreats}
            />
        );
    }

    return (
        <div>
            <div className="d-flex mb-2">
                <div className="flex-fill">
                    <SearchBar placeholder="Search threats"
                        name="Page Filters"
                        data={room}
                        additionalFilters={['whyathreat']}
                        sort
                        sortApplied={sortApplied}
                        watchlist
                    />
                </div>
                <ThreatsWatchlistCsv contents={filteredContentData} />
            </div>
            {filteredContentData.length > 0 ? (
                <ContentTables contents={filteredContentData.slice(offset, offset + 10)}
                    pagination={{ offset, setOffset, count: watchlist?.length || 0 }}
                    tableType={ContentTableTypes.WATCHLIST}
                    contentType={CONTENT_TYPE.THREAT}
                />
            ) : (
                <EmptyState title="No threats found in the watchlist"
                    summary="We can’t find any items matching your search"
                    icon={NotFoundFC}
                    action={linkToThreats}
                />
            )}
        </div>
    );
};
