import { GET_CTYPES } from '../graphql';
import { allCtypesMongo } from './variables';

export const fetchCtypes = ({ client }) => client.query({
    query: GET_CTYPES
}).then(({ data }) => allCtypesMongo(data.getCtypes));

const GetAllTypes = () => ({
    ...THREAT,
    ...LEVEL,
    ...CONTENT_TYPE
});

export const getCtype = (item) => {
    const all = allCtypesMongo();
    const current = all?.filter(a => a.key === item)[0];
    return current || { name: item };
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const truncateString = (str, number, toNearestFullWord = true) => {
    if (!str) { return ''; }
    const num = number || 300;
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str;
    }
    const trimmedString = str.slice(0, num);
    if (trimmedString.match(/[\u3400-\u9FBF]/) || !toNearestFullWord) return `${trimmedString}...`;
    // Return str truncated with '...' concatenated to the end of str.
    return `${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))}...`;
};

const LEVEL = {
    HIGH: {
        name: 'High',
        color: '217, 142, 66',
        description: '',
    },
    NONE: {
        name: 'None',
        color: '118, 185, 92',
        description: '',
    },
    MEDIUM: {
        name: 'Medium',
        color: '80, 117, 179',
        description: '',
    },
    LOW: {
        name: 'Low',
        color: '80, 117, 179',
        description: '',
    },
};

const THREAT = {
    automation_score: { name: 'Automated account', color: 'primary', description: '' },
    'deep-toxic-classifier': { name: 'Toxic information', color: 'primary', description: '' },
    custom: { name: 'Custom threat', color: 'primary', description: '' },
    'src-cred': { name: 'Source credibility', color: 'primary', description: '' },
    manual_label: { name: 'Manual label', color: 'primary' },
    domestic_extremism: { name: 'Domestic extremism', color: 'primary' }
};

const CONTENT_TYPE = {
    blogpost: {
        name: 'Forums',
        color: '217, 142, 66',
    },
    facebook: {
        name: 'Facebook',
        color: '80, 117, 179',
        description: '',
    },
    tweet: {
        name: 'Twitter',
        color: '86, 172, 239',
        description: '',
    },
    article: {
        name: 'News & Articles',
        color: '118, 185, 92',
        description: '',
    },
    redditpost: {
        name: 'Reddit',
        color: '118, 185, 92',
        description: '',
    },
    forumpost: {
        name: 'Forum Post',
        color: '118, 185, 92',
        description: '',
    },
    tumblrpost: {
        name: 'Tumblr',
        color: '118, 185, 92',
        description: '',
    },
    review: {
        name: 'Review',
        color: '118, 185, 92',
        description: '',
    },
    youtube: {
        name: 'Youtube',
        color: '118, 185, 92',
        description: '',
    },
    tencetqq: {
        name: 'Tencet QQ',
        color: '118, 185, 92',
        description: '',
    },
    telegram: {
        name: 'Telegram',
        color: '118, 185, 92',
        description: '',
    },
};

const types = {
    'src-cred': {
        name: 'Source credibility',
        color: 'primary',
    },
    automation_score: {
        name: 'Automated account',
        color: 'primary',
    },
    'deep-toxic-classifier': {
        name: 'Toxic information',
        color: 'primary',
    },
    domestic_extremism: {
        name: 'Domestic extremism',
        color: 'primary',
    }
};

export const formatThreatLabel = (item) => {
    if (item !== 'not-applicable' && !types[item] && item !== '-') {
        return {
            name: 'Custom threat',
            color: 'primary',
        };
    }
    return types[item];
};

export const AllTypes = GetAllTypes();
