import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { SortToggle } from '../../../../../components/Table/SortToggle';
import { SmallPagination } from '../../../../../components/Molecules';
import { LoadingState } from '../Table/Loading';
import { lodashSort } from '../../../../../utils/lib/tableSorting';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';

const itemsPerPage = 5;

export const withTable = (config) => ({ keywords }) => {
    const [sort, setSort] = useState({ field: 'count', order: 'desc' });
    const [page, setPage] = useState(0);
    const { data, loading } = config.api({ keywords });

    if (loading) {
        return <LoadingState size={5} />;
    }

    if (!data.length) {
        return (
            <div className="border-top mx-n3 pt-4">
                <EmptyState title="No data available" icon={Images().overview} />
            </div>
        );
    }

    return (
        <>
            <div className="mx-n3 overflow-x-auto scrollbar-small">
                <Table className="border-bottom mb-2">
                    <thead>
                        <tr>
                            {config.columns.map(column => (
                                <th key={column.key} className={column.className}>
                                    <SortToggle sort={sort} setSort={setSort} field={column.key} name={column.label} />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lodashSort([...data], sort).map((item, index) => {
                            if (index >= page * itemsPerPage && index < (page + 1) * itemsPerPage) {
                                return (
                                    <tr key={item.value}>
                                        {config.columns.map(column => (
                                            <td key={column.key} className={column.className}>
                                                {item[column.key]}{column.key === 'matchPercentage' ? '%' : ''}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            }
                            return null;
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="d-flex justify-content-end mb-n1">
                <SmallPagination total={data.length} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
            </div>
        </>
    );
};
