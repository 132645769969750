import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { capitalizeFirstLetter } from '../../../../utils/text';

export const RemoveClustersModal = ({ clusterIdList, clustersData, confirmRemoveClusters }) => {
    const { openPreviousModal } = useModal();
    return (
        <div>
            <h2>Remove clusters</h2>
            <hr />
            <p>The selected cluster and subclusters will be deleted permanently. Please confirm before deleting.</p>
            <ol className="pl-0 ml-2">
                {clustersData.map((cluster) => {
                    if (clusterIdList.includes(cluster.cluster_tag)) { return (
                        <>
                            <li key={cluster.cluster_tag} className="w-100">
                                {cluster?.cluster_name && `${capitalizeFirstLetter(cluster.cluster_name)} (${cluster.cluster_tag})`}
                            </li>
                            {cluster.sub_cluster_info.map((subCluster) => (
                                <p className="mb-0" key={subCluster.cluster_tag}>{ subCluster?.sub_cluster_name
                            && `${capitalizeFirstLetter(subCluster.sub_cluster_name)}
                            (${subCluster.cluster_tag})`}
                                </p>
                            ))}
                        </>

                    ); }
                    if (cluster.sub_cluster_info.some((a) => clusterIdList.find(id => a.cluster_tag === id))) {
                        const filteredIdsList = cluster.sub_cluster_info.filter((a) => clusterIdList.find(id => a.cluster_tag === id));
                        return (
                            <>
                                {
                                    filteredIdsList.map((subCluster) => (
                                        <li key={subCluster.cluster_tag} className="w-100">
                                            {subCluster?.sub_cluster_name
                                 && `${capitalizeFirstLetter(subCluster.sub_cluster_name)}(${subCluster.cluster_tag})`}
                                        </li>

                                    ))
                                }
                            </>
                        );
                    }
                })}
            </ol>
            <hr />
            <div>
                <Button onClick={() => openPreviousModal()} className="mr-2">Cancel</Button>
                <Button onClick={() => confirmRemoveClusters()} color="primary">Confirm</Button>
            </div>
        </div>
    ); };
