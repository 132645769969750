import React, { useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';

export const SearchInput = ({
    placeholder,
    handleSearch,
    className = '',
    defaultValue = ''
}) => {
    const [value, setValue] = useState(defaultValue);
    const onResetQuery = () => {
        setValue('');
        handleSearch('');
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSearch(value);
    };

    return (
        <form onSubmit={handleFormSubmit} className={`d-block ${className}`} data-testid="search-input-component">
            <InputGroup border={value ? 'active' : 'none'}>
                <Input value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={placeholder || 'Search - Enter a search term to update the room overview and press ENTER'}
                    data-testid="search-input"
                />
                {value && (
                    <InputGroupAddon addonType="prepend" className="border-right small-border">
                        <InputGroupText role="button" onClick={onResetQuery} data-testid="x-icon">
                            <FeatherIcon.X color="#006FF9" size={16} />
                        </InputGroupText>
                    </InputGroupAddon>
                )}
                <InputGroupAddon addonType="prepend">
                    <InputGroupText role="button" onClick={() => handleSearch(value)} data-testid="search-icon">
                        <FeatherIcon.Search size={16} color="#006FF9" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </form>
    );
};
