import React from 'react';
import { SavedComparison } from './SavedComparison';

const comparisons = [
    {
        id: 1,
    },
    {
        id: 2,
    }
];

export const SavedComparisons = () => (
    <>
        <section className="d-flex flex-column">
            <p>
                View and manage the saved narrative comparisons
            </p>
        </section>
        <section className="d-flex flex-column">
            {comparisons.map((comparison) => (
                <SavedComparison id={comparison.id} />))}
        </section>

    </>

);
