import React from 'react';
import { Minus, Plus } from 'react-feather';
import {
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from 'reactstrap';

type NumberInputProps = {
    label: string;
    value: number;
    id: string;
    onChange: (e: number) => void;
    className?: string;
    disabled: boolean;
}

export const NumberInput = ({ label, value, id, onChange, className = '', disabled }: NumberInputProps) => (
    <div className={className}>
        <Label className="mb-1" for={id}>{label}</Label>
        <InputGroup>
            <Input required
                name={id}
                id={id}
                value={value}
                onChange={({ target }) => onChange(target.value ? parseInt(target.value, 10) : 0)}
                min={10}
                data-testid="number-input"
                disabled={disabled}
            />
            <InputGroupAddon addonType="append"
                role="button"
                onClick={() => {
                    if (!disabled) {
                        onChange(value > 0 ? value - 1 : 0);
                    }
                }}
            >
                <InputGroupText className="bg-white">
                    <Minus color="#006FF9" size={20} />
                </InputGroupText>
            </InputGroupAddon>
            <InputGroupAddon addonType="append"
                role="button"
                onClick={() => {
                    if (!disabled) {
                        onChange(value + 1);
                    }
                }}
            >
                <InputGroupText className="bg-white">
                    <Plus color="#006FF9" size={20} />
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    </div>
);
