import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as ActiveState } from 'react-router-dom';
import { useUser } from '../../../context/user/UserContext';

type Links = {
    id: string,
    icon: string,
    path: string,
    name: string
}

type Category = {
    name: string,
    links: [Links]
}

type NavigationItemsProps = {
    category: Category
}
export const NavigationItems = ({ category }: NavigationItemsProps) => {
    const { notAllowed }: any = useUser();
    const links = category.links.filter(link => {
        if (notAllowed.includes(link.id)) {
            return false;
        }
        return true;
    });
    if (!links.length) { return null; }
    return (
        <>
            {(category.name && category.name !== 'Core') && <p className="category">{category.name}</p>}
            <ul>
                {links.map((item) => {
                    const Icon = item.icon || null || React.Component;
                    return (
                        <NavItem key={item.path}>
                            <NavLink tag={ActiveState}
                                isActive={(match: string) => match}
                                to={item.path}
                                className="nav-link d-flex align-items-center"
                            >
                                {item.icon && <Icon />}
                                <span>{item.name}</span>
                            </NavLink>
                        </NavItem>
                    );
                })}
            </ul>
        </>
    );
};
