import React from 'react';
import { Button, Spinner, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUpdatedReportPages } from '../../getUpdatedReportPages';
import { NarrativeListItem } from '../../../../../../Narratives/Overview/Narratives/NarrativeListItem';
import { truncateString } from '../../../../../../../../utils/text';
import { useTranslateMultipleText } from '../../../../../../../../services/google/translate';
import { TranslateIconBlue } from '../../../../../../../../assets/icons/svgicons';
import { setReportAction } from '../../../../../../../../redux/reports';

export const NarrativesTableInner = ({ count, data, type = 'narratives' }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const selectedNarratives = report.pages.filter(a => a.type === type).map(a => a.blocks).flat();
    const toggleNarrative = (narrative, selected) => {
        const pages = getUpdatedReportPages(
            report,
            selected ? selectedNarratives.filter(a => a.id !== narrative.id) : [...selectedNarratives, narrative],
            type
        );
        dispatch(setReportAction({
            ...report,
            pages
        }));
    };

    return (
        <Table>
            <thead>
                <tr>
                    <td>Narratives ({count})</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
                {data.map(narrative => {
                    const selected = selectedNarratives.find(a => a.id === narrative.id);
                    return (
                        <tr key={narrative.id}>
                            <NarrativeRow narrative={narrative} selected={selected} toggleNarrative={toggleNarrative} />
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

const NarrativeRow = ({ narrative, selected, toggleNarrative }) => {
    const { getTranslation, data, loading } = useTranslateMultipleText({ text: narrative.narrative_title });
    const outputText = data.length ? data[0].translations[0] : data.text;
    return (
        <>
            <td>
                <NarrativeListItem narrative={{
                    ...narrative,
                    narrative_title: truncateString(outputText, 550)
                }}
                />
            </td>
            <td className="no-white-space d-flex flex-column">
                {narrative.lang === 'en' ? null : (
                    <Button color="link" onClick={getTranslation} className="text-decoration-none">
                        {loading ? (
                            <div className="text-primary px-4">
                                <Spinner size="sm" />
                            </div>
                        ) : (
                            <>
                                <TranslateIconBlue />
                                <span className="text-primary ml-1">Translate</span>
                            </>
                        )}
                    </Button>
                )}
                {!selected
                    ? (
                        <Button color="primary" onClick={() => toggleNarrative({ ...narrative, narrative_title: outputText }, selected)}>
                            Add to report
                        </Button>
                    )
                    : (
                        <Button color="danger"
                            onClick={() => toggleNarrative(narrative, selected)}
                        >
                            Remove from report
                        </Button>
                    ) }
            </td>
        </>
    );
};
