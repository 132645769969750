import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { TooltipItem } from '../ToolTip';

export const Summary = ({ summary, setSummary }) => (
    <FormGroup className="mb-2">
        <Label>
            <strong>Summary</strong> ({summary.length}/45 characters)
            <span className="pl-1">
                <TooltipItem id="SummaryTrigger"
                    content="Please provide a short summary of the investigation request
                                                 that can be used as a reference eg. 'Potential threat/fake news in India'."
                    Icon={FeatherIcon.Info}
                />
            </span>
        </Label>
        <InputGroup border={summary ? 'active' : 'none'}>
            <Input value={summary}
                onChange={
                    (e) => setSummary(e.target.value.length < 46 ? e.target.value : summary)
                }
                placeholder="Eg. 'Potential threat/fake news in India'."
                required
                data-testid="summary-input"
            />
            {summary && (
                <InputGroupAddon addonType="prepend">
                    <InputGroupText role="button"
                        onClick={() => { setSummary(''); }}
                    >
                        <FeatherIcon.X color="#006FF9" size={16} data-testid="summary-x-icon" />
                    </InputGroupText>
                </InputGroupAddon>
            )}
        </InputGroup>
    </FormGroup>
);
