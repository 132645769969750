import React from 'react';
import { useContent } from '../../../services/Content/getContent';
import { Loading } from '../../../components/Loading';
import { NoDataFound } from '../Narratives/Performance/Nodatafound';
import { PostsContentTable } from './PostsContentTable';

export const Posts = ({ dateData, data, page, setPage }) => {
    const { data: content, loading, count } = useContent({
        filters: {
            ids: data,
            ...(dateData ? { date: { startDate: dateData.startDate * 1000, endDate: dateData.endDate * 1000 } } : {})
        },
        limit: 10,
        skip: page,
        skipRequest: !data,
        name: 'getTopicInsightsPosts',
        fragments: ['Metadata', 'Document']
    });
    return (
        <div>
            {content.length > 0 ? (
                <PostsContentTable data={content}
                    page={page}
                    setPage={setPage}
                    total={count}
                />
            ) : (
                <div className="bg-white p-5">
                    {loading ? <Loading relative height={300} /> : <NoDataFound />}
                </div>
            )}
        </div>
    );
};
