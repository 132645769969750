import React, { useState } from 'react';
import {
    Modal,
    ModalBody,
    FormGroup,
    Input,
    Button,
    ModalHeader,
    ModalFooter,
    FormFeedback
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useQuery, useMutation } from '@apollo/client';
import { GET_EXTRACTED_CLAIMS, SUBMIT_CLAIM_TO_FC } from '../../graphql';
import { useModal } from '../../context/modal/ModalComponent';

export const FactCheck = ({ open, setOpen, data: propsData, projectId, roomId, user }) => {
    const history = useHistory();
    const { setModal, closeModal } = useModal();
    const [checked, setChecked] = useState(0);
    const [isWarning, setIsWarning] = useState(false);
    const [otherClaim, setOtherClaim] = useState('');
    const [message, setMessage] = useState('');
    const [claimValid, setClaimValid] = useState('');
    const [claimSubmissionLoading, setClaimSubmissionLoading] = useState(false);
    const [claimID, setClaimID] = useState('');
    const { data: extractedClaims, loading } = useQuery(GET_EXTRACTED_CLAIMS, {
        variables: {
            id: propsData?.threadId,
            text: `${propsData?.text}`,
            url: propsData?.url,
            room_id: roomId
        },
    });
    const [submitClaimToFc] = useMutation(SUBMIT_CLAIM_TO_FC, {
        onCompleted: (data) => {
            setSaveModal();
            setClaimID(data.submitClaimToFc.claimId);
            resetClaimSubmission();
        },
    });
    const resetClaimSubmission = () => {
        setChecked(0);
        setIsWarning(false);
        setOtherClaim('');
        setMessage('');
        setClaimValid('');
        setClaimSubmissionLoading(false);
    };
    const handleClaimSubmit = () => {
        let content = '';
        if (checked === 'other') {
            content = otherClaim;
            const error = validateClaim(content);
            if (error) return setClaimValid(error);
        } else {
            content = extractedClaims.getExtractedClaims.claims[checked].text;
        }
        const data = {
            claim_source: 'li_client',
            type: 'body',
            content,
            user_id: user.id,
            message,
            project_id: projectId,
        };
        setClaimSubmissionLoading(true);
        submitClaimToFc({
            variables: {
                data,
            },
        });
    };
    const handleMessage = (e) => {
        setMessage(e.target.value);
    };
    const claimSelect = (e) => {
        setIsWarning(false);
        setOtherClaim('');
        setClaimValid('');
        setChecked(e);
    };
    const validateClaim = (claim) => {
        const claimStringArray = claim.trim().split(' ');
        const claimCharArray = claim.trim().replace(/\s/g, '').split('');
        if (
            /^\d+$/.test(claim.trim('').replace(/\s/g, ''))
            || (claimStringArray.length >= 3
                && claim
                    .trim()
                    .split(' ')
                    .every((val) => val === claimStringArray[0]))
            || claimCharArray.every((val) => val === claimCharArray[0])
        ) {
            return 'Please enter a valid claim';
        }
        if (claim.trim().split(' ').length < 5) { return 'Please enter atleast 5 words'; }
        return null;
    };
    const setSaveModal = () => {
        if (checked === '') {
            setIsWarning(true);
        } else {
            setOpen(false);
            openSuccessModal();
        }
    };

    const openSuccessModal = () => {
        setModal({
            component: (
                <div>
                    <h3 className="m-0">Your request was successfully sent!</h3>
                    <hr />
                    <p>You will get an email once your request is completed.</p>
                    <p>You can see all of your fact-checks in fact checking tab on the menu.</p>
                    <hr />
                    <Button color="secondary"
                        className="mr-3"
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                    <Button color="primary"
                        onClick={() => {
                            closeModal();
                            history.push({
                                pathname: `/situation-rooms/${roomId}/fact-checks/${claimID}`,

                                state: { claimID }
                            });
                        }}
                    >
                        See your request
                    </Button>
                </div>
            )
        });
    };

    return (
        <Modal isOpen={open} toggle={() => setOpen(false)}>
            <ModalHeader toggle={() => setOpen(false)} tag="h3">
                Request fact check by our fact-checkers
            </ModalHeader>
            <ModalBody className="p-3">
                <p>
                    If you want to get an in depth report about this threat, you can
                    request it to be fact-checked by our fact-checkers. It will take up to
                    X days.
                </p>
                <p>
                    If you can&apos;t find a topic you want to fact-check, add your
                    another related to this threat by selecting &quot;Other&quot;.
                </p>
                {!loading && <h5>Select what you want to fact check:</h5>}
                {!loading
          && extractedClaims?.getExtractedClaims?.claims.map((claim, index) => (
              <div className="d-flex" key={`${uuid} ${claim.ce_score}`}>
                  <input type="radio"
                      checked={checked === index}
                      id={index}
                      onChange={() => {
                          claimSelect(index);
                      }}
                      disabled={claimSubmissionLoading}
                  />
                  <span className="pb-1 px-1 max-width">{claim.text}</span>
              </div>
          ))}
                <FormGroup className="d-flex">
                    <input type="radio"
                        checked={checked === 'other'}
                        id="other"
                        onChange={() => {
                            claimSelect('other');
                        }}
                        disabled={claimSubmissionLoading}
                    />
                    <span className="p-1">Other</span>
                </FormGroup>
                {checked === 'other' && (
                    <FormGroup>
                        <Input className="mt-1"
                            invalid={!!claimValid}
                            type="textarea"
                            name="reason"
                            onChange={(e) => setOtherClaim(e.target.value)}
                            value={otherClaim}
                            cols="100"
                            placeholder=""
                            disabled={claimSubmissionLoading}
                        />
                        <FormFeedback>{claimValid}</FormFeedback>
                    </FormGroup>
                )}
                {isWarning && (
                    <p className="text-danger">
                        You have to select atleast 1 claim to send a fact-checking request.
                    </p>
                )}
                <label htmlFor="additionalComments" className="mt-2">
                    Additional comments you want to share with our fact-checking team
                </label>
                <Input type="textarea"
                    className="form-control"
                    id="additionalComments"
                    rows="3"
                    value={message}
                    onChange={handleMessage}
                    disabled={claimSubmissionLoading}
                />
            </ModalBody>
            <ModalFooter>
                <Button outline
                    type="submit"
                    className="btn btn-secondary mt-1"
                    onClick={() => {
                        setOpen(false);
                        resetClaimSubmission();
                    }}
                >
                    Cancel
                </Button>
                <Button color="primary"
                    state={(claimSubmissionLoading && 'loading') || 'false'}
                    type="submit"
                    className=" ml-2 mt-1"
                    onClick={() => handleClaimSubmit()}
                >
                    Send
                </Button>
            </ModalFooter>
        </Modal>
    );
};
