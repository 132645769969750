import React, { useEffect, useRef, useState } from 'react';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import Editor from 'draft-js-plugins-editor';
import { RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useDispatch, useSelector } from 'react-redux';
import { colorPickerPlugin } from './customColors';
import { setToolbarAction } from '../../../../../../redux/reports';

const singleLinePlugin = createSingleLinePlugin();
export const TextEditor = ({ value, onChange, placeholder, multiline, disabled, hasDarkBg }) => {
    const dispatch = useDispatch();
    const { latestTool } = useSelector(state => state.createReport);
    const [editorStateValue, setState] = useState(value);
    const editorState = disabled ? value : editorStateValue;
    const [focus, setFocus] = useState(false);
    const [align, setAlign] = useState('left');
    const color = colorPickerPlugin(setState, () => value);
    const timer = useRef(null);
    useEffect(() => {
        if (focus) {
            const currentStyles = Array.from(editorState.getCurrentInlineStyle());
            const toolbarStyles = Object.entries(latestTool).map(a => [a[0].toUpperCase(), a[1]]);
            let newVal = editorState;
            let shouldChange = false;
            for (const style of toolbarStyles) {
                if (['BOLD', 'ITALIC', 'UNDERLINE'].includes(style[0])) {
                    const isEnabledAlready = currentStyles.includes(style[0]);
                    const shouldToggle = (isEnabledAlready && !style[1]) || (!isEnabledAlready && style[1]);
                    if (shouldToggle) {
                        newVal = RichUtils.toggleInlineStyle(newVal, style[0]);
                        shouldChange = true;
                    }
                }
            }
            if (shouldChange) {
                setState(newVal);
                onChange(newVal);
            }
            if (latestTool.color && latestTool.color !== color.currentColor(editorState)) {
                color.addColor(latestTool.color);
            }
            if (latestTool.align && latestTool.align !== align) {
                setAlign(latestTool.align);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestTool, focus]);
    const handleFocus = () => {
        const currentStyles = Array.from(value.getCurrentInlineStyle());
        dispatch(setToolbarAction({
            align,
            color: color.currentColor(value),
            ...Object.fromEntries(['BOLD', 'ITALIC', 'UNDERLINE'].map(
                a => ([a.toLowerCase(), currentStyles.includes(a)])
            ))
        }));
        setFocus(true);
    };
    return (
        <Editor plugins={multiline ? [] : [singleLinePlugin]}
            editorState={editorState}
            onChange={(e) => {
                if (!disabled) {
                    setState(e);
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                        onChange(e);
                    }, 1000);
                }
            }}
            placeholder={placeholder}
            customStyleFn={(styleProps) => {
                const toolbarColor = color.customStyleFn(styleProps).color;
                if (toolbarColor) return ({ color: toolbarColor });
                return hasDarkBg ? { color: '#FFFFFF' } : {};
            }}
            onFocus={handleFocus}
            onBlur={() => setFocus(false)}
            textAlignment={align}
            readOnly={disabled}
        />
    );
};
