import { useMutation } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { GET_CTYPES, UPDATE_CTYPE } from '../../../../graphql';

export const ActionButtons = ({ sourceData, ctypeData, setCtypeData, font }) => {
    const isDataEqual = isEqual(ctypeData, sourceData) && sourceData.FontFamily === font.name;
    const history = useHistory();
    const [updateCtype, { loading }] = useMutation(UPDATE_CTYPE, {
        refetchQueries: [GET_CTYPES],
        onCompleted: () => {
            history.push('/admin/ctypes');
        }
    });

    const onSaveUpdates = () => {
        updateCtype({
            variables: {
                data: { ...ctypeData, FontFamily: font.name },
            },
        });
    };
    return (
        <>
            <Button disabled={isDataEqual || loading} color="primary" className="mx-3 my-3" onClick={() => onSaveUpdates()}>Save</Button>
            <Button onClick={() => setCtypeData(sourceData)}>Reset</Button>
        </>
    );
};
