import React, { useState } from 'react';
import { Loading } from '../../../../../../../components/Loading';
import { Pagination } from '../../../../../../../components/Molecules';
import { useGetNarrativesWatchlist } from '../../../../../../../services/Narratives/getNarrativesWatchlist';
import { getRoom } from '../../../../../../../utils/variables';
import { NarrativesTableInner } from '../TopNarratives/NarrativesTable/NarrativesTableInner';
import { NoResults } from '../NoResults';

export const NarrativesWatchlistTableContainer = () => {
    const room = getRoom();
    const [offset, setOffset] = useState(0);
    const { data, loading, count } = useGetNarrativesWatchlist({ situationRoomId: room.id, skip: offset });

    if (loading) {
        return <Loading relative height={300} />;
    }

    if (!data || data?.length === 0) {
        return <NoResults text="There are no narratives added to the watchlist yet!" />;
    }

    return (
        <>
            <NarrativesTableInner data={data} count={count} type="narrativesWatchlist" />
            <Pagination total={count} itemsPerPage={10} offset={offset} setOffset={setOffset} className="py-2" />
        </>

    );
};
