import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { resetSelectedCluster } from '../../store';
import { TabsContainer } from './TabsContainer';

type SelectedClusterViewProps = {
    isParent: boolean
    cluster: any
}

export const SelectedClusterView = ({ cluster, isParent }: SelectedClusterViewProps) => {
    const dispatch = useDispatch();
    const [expand, setExpand] = useState(false);
    const toggleExpand = () => setExpand(!expand);
    return (
        <div className={`mt-4 h-100 z-index position-relative  ${expand ? 'w-100' : 'w-75'}`}>
            <div className="bg-white p-2 d-flex text-align-center justify-content-between ">
                <p className="m-0 font-weight-bold">{isParent ? 'Cluster' : 'Subcluster'} details</p>
                <div className="d-flex text-align-center">
                    <p className="text-primary m-0 cursor-pointer" onClick={() => toggleExpand()}>
                        {expand ? <><ChevronLeft /> Collapse</> : <>Expand  <ChevronRight /></>}
                    </p>
                    <X className="ml-3 cursor-pointer" color="#006FF9" onClick={() => dispatch(resetSelectedCluster())} />
                </div>
            </div>
            <hr className="m-0" />
            <TabsContainer cluster={cluster} isParent={isParent} />
        </div>
    );
};
