import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

type PasswordInputProps = {
    password: string,
    setPassword: (e: string) => void,
    testId: string,
    required: boolean
}

export const PasswordInput = ({ password, setPassword, testId, required = false }: PasswordInputProps) => {
    const [inputType, setInputType] = useState('password');
    return (
        <InputGroup border={password ? 'active' : 'none'}>
            <Input name="password"
                data-testid={testId || 'passwordInput'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={inputType === 'password' ? 'password' : 'text'}
                required={required}
            />
            {password && (
                <InputGroupAddon addonType="prepend">
                    <InputGroupText color="transparent">
                        {inputType === 'password'
                            ? (
                                <EyeOff className="pr-11"
                                    color="#006FF9"
                                    onClick={() => setInputType('text')}
                                />
                            )
                            : <Eye className="pr-11" color="#006FF9" onClick={() => setInputType('password')} />}
                    </InputGroupText>
                </InputGroupAddon>
            )}
        </InputGroup>
    );
};
