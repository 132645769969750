import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { BackButton } from '../../../components/Atoms';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { Loading } from '../../../components/Loading';
import { useQueryExtracts } from '../../../services/Content/getQueryExtracts';
import { getContentLink } from '../../../utils/getContentLink';
import { getRoom } from '../../../utils/variables';
import { NotFound } from '../../Error/404';
import { ActionsRow } from '../Threats/Threat/ActionsRow';
import { ContentText } from '../Threats/Threat/ContentText';
import { RightPanel } from '../Threats/Threat/RightPanel';
import { useContent } from '../../../services/Content/getContent';

export const MatchSummary = () => {
    const room = getRoom();
    const match = useRouteMatch();

    const { refetch, loading, data: contents } = useContent({
        filters: {
            ids: match.params.match
        },
        limit: 1,
        fetchPolicy: 'no-cache',
        name: 'getMatchSummary'
    });
    const content = contents[0];

    const { extracts } = useQueryExtracts({
        url: content?.url,
        text: content?.body || content?.text
    });

    const filters = useSelector((state) => state.filters.filters);
    const highlightingSearchQuery = Boolean(filters.query || filters.booleanQuery);

    if (loading) return <Loading gif height={600} />;
    if (!content) return <NotFound small />;
    if (content.threatLevel !== 'none') {
        return <Redirect to={getContentLink({ id: match.params.match, threatLevel: 'high' })} />;
    }

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'Matches' }
    ];

    return (
        <div className="px-4 threat-page">
            <Row>
                <Col xs="12" lg="8" xl="9" className="pr-4 py-4">
                    <BreadCrumbs items={bread} />
                    <div className="mt-2">
                        <BackButton pathName={`/situation-rooms/${room.id}/overview`} gotToPreviousPath />
                    </div>
                    <Row>
                        <Col>
                            <div className="threat-title">
                                <span>
                                    Match summary
                                </span>
                            </div>
                            <div className="highlight-em pt-3">
                                View the content and everything we know about the match
                                here. <em>Highlighted</em> information are the {highlightingSearchQuery
                                    ? 'search keywords.'
                                    : 'keywords that are monitored in your situation room.'}
                            </div>
                        </Col>
                    </Row>
                    <ActionsRow content={content} refetch={refetch} />
                    <ContentText data={content} extracts={extracts} />
                </Col>
                <RightPanel data={content} />
            </Row>
        </div>
    );
};
