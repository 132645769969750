import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { Loading } from '../../../../../components/Loading';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { getRoom } from '../../../../../utils/variables';
import { useModal } from '../../../../../context/modal/ModalComponent';

type UserDetailsProps = {
    from: string
    author: string
}

export const UserDetails = ({ from, author }: UserDetailsProps) => {
    const room = getRoom();
    const { closeModal } = useModal();
    const { data, loading } = useQuery(GET_ACCOUNT_ENGAGEMENT, {
        variables: {
            username: from,
            projectId: room.project_id
        }
    });
    if (loading) return <Loading relative height={200} />;
    const accountEngagement = data?.getAccountEngagement || {};
    return (
        <div>
            <h3>{author}</h3>
            <hr />
            <p>Engagement: {largeNumber(accountEngagement.engagement)}</p>
            <p>No of posts: {largeNumber(accountEngagement.posts)}</p>
            <hr />
            <Button onClick={closeModal}>Close</Button>
        </div>
    );
};

export const GET_ACCOUNT_ENGAGEMENT = gql`
    query getAccountEngagement(
        $username: String!
        $projectId: String!
    ) {
        getAccountEngagement(
            username: $username
            projectId: $projectId
        ) {
            engagement
            posts
        }
    }
`;
