import React, { useState } from 'react';
import { Search, X } from 'react-feather';
import {
    Button,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { FilterByClient } from '../../SituationRooms/FilterByClient';
import { InviteUser } from '../../../../components/InviteUser';

export const PageFilters = ({ handleSearchValue }) => {
    const { setModal, closeModal } = useModal();
    const defaultSelected = [];
    const [search, setSearch] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearchValue(search, 'searchValue');
    };

    const setInviteUserModal = () => setModal({
        component: <InviteUser membersCanBeAdded={10} toggle={closeModal} isSuperAdmin />
    });
    return (
        <div className="my-4 d-flex align-items-center">
            <div className="mr-3 flex-fill">
                <Form onSubmit={handleSubmit}>
                    <InputGroup border={search ? 'active' : 'none'} className="mb-0">
                        <Input type="text"
                            placeholder="Search Members"
                            name="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            data-testid="all-users-input"
                        />
                        {search.length > 0 && (
                            <InputGroupAddon addonType="prepend" className="border-right small-border">
                                <InputGroupText role="button"
                                    onClick={() => { setSearch(''); handleSearchValue('', 'searchValue'); }}
                                    data-testid="clear-search"
                                >
                                    <X color="#006FF9" size={16} />
                                </InputGroupText>
                            </InputGroupAddon>
                        )}
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText role="button">
                                <Search color="#006FF9" size={16} onClick={handleSubmit} data-testid="search-icon" />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Form>
            </div>
            <FilterByClient defaultSelected={defaultSelected}
                onChange={(instances) => handleSearchValue(instances.map(instance => instance.id), 'clientList')}
            />
            <Button className="ml-2" color="primary" onClick={setInviteUserModal}>Add new user</Button>
        </div>
    ); };
