import React, { useState, useRef } from 'react';
import Highcharts from 'highcharts';
import { Button } from 'reactstrap';
import { FullLineChart } from '../Overview/Visualisation/TotalMatchesAndThreatsOverTime/FullLineChart';
import { BrushLineChart } from '../../../components/Highcharts/BrushLineChart';

Highcharts.Chart.prototype.showResetZoom = () => { };

export const BrushChart = ({ data, title = '', onClick }) => {
    const [showOptions, setShowOptions] = useState(true);
    const [isZoomed, setIsZoomed] = useState(false);
    const chartComponent = useRef(null);
    const options = {
        plotOptions: {
            line: {
                marker: {
                    enabled: false,
                    lineColor: '#006FF9',
                    fillColor: '#fff',
                    lineWidth: 2,
                    radius: 5.5,
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                            radiusPlus: 0
                        }
                    }
                },
                lineWidth: 2,
                states: {
                    hover: {
                        halo: {
                            opacity: 0
                        }
                    }
                },
                events: {
                    click: ({ point }) => {
                        if (point.y) {
                            onClick(point.x);
                        }
                    }
                }
            },
        }
    };
    const totalMatches = [{
        type: 'theme_matches',
        id: 'iusfg7w8grhfuwb',
        title: 'Total Matches'
    }];
    const resetZoom = () => {
        if (chartComponent && chartComponent.current) {
            chartComponent.current.chart.zoomOut();
        }
    };
    const handleShowOptions = () => {
        if (showOptions) {
            resetZoom();
        }
        setShowOptions(!showOptions);
    };
    return (
        <div data-testid="chart-line2" className="p-3 bg-white">
            <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0"><strong>{title}</strong></p>
                    <div className="d-flex align-items-center">
                        {isZoomed && (
                            <Button color="link"
                                className="pr-1"
                                onClick={resetZoom}
                            >Reset zoom
                            </Button>
                        )}

                        <Button color="link"
                            onClick={handleShowOptions}
                            className="pr-0 pl-1"
                        >{showOptions ? 'Show options' : 'Hide options'}
                        </Button>

                    </div>
                </div>
                <p className="m-0">To zoom in on a specific period or date, you can click and drag horizontally within the graph</p>
            </div>
            {showOptions ? <BrushLineChart data={data} onClick={onClick} setIsZoomed={setIsZoomed} />
                : (
                    <FullLineChart items={totalMatches}
                        plotOptions={options.plotOptions}
                        showOptions={showOptions}
                    />
                )}

        </div>
    );
};
