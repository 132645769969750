import React from 'react';

export const Deleted = () => (
    <svg width="112" height="80" viewBox="0 0 112 80" fill="none">
        <g clipPath="url(#clip0_2_2)">
            <path fill="#E5E6EB"
                d="M0.438965 68.8234C0.438965 69.9062 0.869113 70.9447 1.63478 71.7104C2.40045 72.476 3.43891 72.9062
                4.52172 72.9062H107.489C108.572 72.9062 109.61 72.476 110.376 71.7104C111.141 70.9447 111.572
                69.9062 111.572 68.8234V11.5415H0.438965V68.8234Z"
            />
            <path fill="#D2D4DB"
                d="M107.483 7.75716e-07H4.51635C3.9801 -0.000329936 3.44907 0.105087 2.95358 0.310147C2.45809
                0.515208 2.00786 0.815893 1.62867 1.19508C1.24949 1.57426 0.94874 2.0245 0.74368 2.51999C0.538619
                3.01548 0.433264 3.54651 0.433595 4.08276V11.5414H111.566V4.08276C111.566 2.99994 111.136 1.96148
                110.37 1.19582C109.605 0.430149 108.566 7.75716e-07 107.483 7.75716e-07Z"
            />
            <path fill="#F8F8F8"
                d="M40.489 22.4385H12.2593C11.3076 22.4385 10.5361 23.21 10.5361 24.1616V57.9985C10.5361
                58.9502 11.3076 59.7217 12.2593 59.7217H40.489C41.4406 59.7217 42.2121 58.9502 42.2121
                57.9985V24.1616C42.2121 23.21 41.4406 22.4385 40.489 22.4385Z"
            />
            <path fill="#F8F8F8"
                d="M101.889 22.4385H52.5361C51.5845 22.4385 50.813 23.21 50.813 24.1616V36.9306C50.813
                37.8823 51.5845 38.6538 52.5361 38.6538H101.889C102.841 38.6538 103.612 37.8823
                103.612 36.9306V24.1616C103.612 23.21 102.841 22.4385 101.889 22.4385Z"

            />
            <path fill="#F8F8F8"
                d="M101.889 42.2236H52.5361C51.5845 42.2236 50.813 42.9951 50.813 43.9468V56.7158C50.813
                57.6675 51.5845 58.439 52.5361 58.439H101.889C102.841 58.439 103.612 57.6675 103.612
                56.7158V43.9468C103.612 42.9951 102.841 42.2236 101.889 42.2236Z"
            />
            <path d="M23.8942 35.0215L14.9766 45.7001H32.8119L23.8942 35.0215Z" fill="#E5E6EB" />
            <path d="M30.7758 33.8218L20.8569 45.7003H40.6972L30.7758 33.8218Z" fill="white" />
            <path fill="#E5E6EB"
                d="M18.7847 35.8947C19.9295 35.8947 20.8576 34.9666 20.8576 33.8219C20.8576 32.6771
                19.9295 31.749 18.7847 31.749C17.6399 31.749 16.7119 32.6771 16.7119 33.8219C16.7119
                34.9666 17.6399 35.8947 18.7847 35.8947Z"
            />
            <path fill="#F8F8F8"
                d="M7.52753 7.59705C8.50977 7.59705 9.30603 6.80078 9.30603 5.81854C9.30603 4.8363
                8.50977 4.04004 7.52753 4.04004C6.54529 4.04004 5.74902 4.8363 5.74902 5.81854C5.74902
                6.80078 6.54529 7.59705 7.52753 7.59705Z"
            />
            <path fill="#E5E6EB"
                d="M11.8898 7.59705C12.8721 7.59705 13.6683 6.80078 13.6683 5.81854C13.6683 4.8363
                12.8721 4.04004 11.8898 4.04004C10.9076 4.04004 10.1113 4.8363 10.1113
                5.81854C10.1113 6.80078 10.9076 7.59705 11.8898 7.59705Z"

            />
            <path fill="#E5E6EB"
                d="M16.5183 7.59705C17.5005 7.59705 18.2968 6.80078 18.2968 5.81854C18.2968 4.8363
                17.5005 4.04004 16.5183 4.04004C15.536 4.04004 14.7397 4.8363 14.7397 5.81854C14.7397
                6.80078 15.536 7.59705 16.5183 7.59705Z"
            />
            <path fill="#D2D4DB"
                d="M97.059 28.728H56.415C56.2482 28.728 56.0883 28.6618 55.9703 28.5438C55.8524 28.4259 55.7861
                28.2659 55.7861 28.0991C55.7861 27.9323 55.8524 27.7724 55.9703 27.6545C56.0883 27.5365 56.2482
                27.4702 56.415 27.4702H97.059C97.2258 27.4702 97.3857 27.5365 97.5037 27.6545C97.6216 27.7724
                97.6879 27.9323 97.6879 28.0991C97.6879 28.2659 97.6216 28.4259 97.5037 28.5438C97.3857
                28.6618 97.2258 28.728 97.059 28.728Z"
            />
            <path fill="#D2D4DB"
                d="M97.059 33.6206H56.415C56.2482 33.6206 56.0883 33.5543 55.9703 33.4363C55.8524 33.3184 55.7861
                33.1585 55.7861 32.9917C55.7861 32.8249 55.8524 32.6649 55.9703 32.547C56.0883 32.429 56.2482 32.3628
                56.415 32.3628H97.059C97.2258 32.3628 97.3857 32.429 97.5037 32.547C97.6216 32.6649 97.6879 32.8249
                97.6879 32.9917C97.6879 33.1585 97.6216 33.3184 97.5037 33.4363C97.3857 33.5543 97.2258 33.6206 97.059 33.6206Z"
            />
            <path fill="#D2D4DB"
                d="M97.0594 48.5151H74.3237C74.1569 48.5151 73.9969 48.4489 73.879 48.3309C73.7611 48.213
                73.6948 48.053 73.6948 47.8862C73.6948 47.7194 73.7611 47.5595 73.879 47.4415C73.9969 47.3236
                74.1569 47.2573 74.3237 47.2573H97.0594C97.2262 47.2573 97.3861 47.3236 97.5041 47.4415C97.622
                47.5595 97.6883 47.7194 97.6883 47.8862C97.6883 48.053 97.622 48.213 97.5041 48.3309C97.3861
                48.4489 97.2262 48.5151 97.0594 48.5151Z"
            />
            <path fill="#D2D4DB"
                d="M97.0594 53.4077H74.3237C74.1569 53.4077 73.9969 53.3414 73.879 53.2235C73.7611 53.1056
                73.6948 52.9456 73.6948 52.7788C73.6948 52.612 73.7611 52.452 73.879 52.3341C73.9969 52.2161
                74.1569 52.1499 74.3237 52.1499H97.0594C97.2262 52.1499 97.3861 52.2161 97.5041 52.3341C97.622 52.452
                97.6883 52.612 97.6883 52.7788C97.6883 52.9456 97.622 53.1056 97.5041 53.2235C97.3861 53.3414
                97.2262 53.4077 97.0594 53.4077Z"
            />
            <path fill="#C8CACE"
                d="M55.9999 80.0023C65.2194 80.0023 72.6932 72.5285 72.6932 63.309C72.6932 54.0896 65.2194 46.6157
                55.9999 46.6157C46.7805 46.6157 39.3066 54.0896 39.3066 63.309C39.3066 72.5285 46.7805
                80.0023 55.9999 80.0023Z"
            />
            <path fill="white"
                d="M63.4583 58.9648H48.541C48.3742 58.9648 48.2142 58.8985 48.0963 58.7805C47.9784 58.6626
                47.9121 58.5027 47.9121 58.3359C47.9121 58.1691 47.9784 58.0091 48.0963 57.8912C48.2142 57.7733
                48.3742 57.707 48.541 57.707H63.4583C63.6251 57.707 63.7851 57.7733 63.903 57.8912C64.021 58.0091
                64.0872 58.1691 64.0872 58.3359C64.0872 58.5027 64.021 58.6626 63.903 58.7805C63.7851 58.8985
                63.6251 58.9648 63.4583 58.9648Z"
            />
            <path fill="white"
                d="M60.1427 72.2245H51.8413C51.2353 72.2238 50.6543 71.9826 50.226 71.5538C49.7977 71.1251 49.5571
                70.5438 49.5571 69.9378V58.3361C49.5571 58.1693 49.6234 58.0093 49.7413 57.8913C49.8593 57.7734 50.0192
                57.7072 50.186 57.7072C50.3528 57.7072 50.5128 57.7734 50.6307 57.8913C50.7487 58.0093 50.8149 58.1693
                50.8149 58.3361V69.9479C50.8149 70.2203 50.923 70.4816 51.1154 70.6745C51.3078 70.8674 51.5688 70.9761
                51.8413 70.9768H60.1427C60.2778 70.9768 60.4116 70.9501 60.5364 70.8984C60.6612 70.8467 60.7746 70.7709
                60.8702 70.6754C60.9657 70.5799 61.0415 70.4664 61.0932 70.3416C61.1449 70.2168 61.1715 70.083 61.1715
                69.9479V58.3461C61.1715 58.1793 61.2378 58.0194 61.3557 57.9014C61.4737 57.7835 61.6336 57.7172 61.8004
                57.7172C61.9672 57.7172 62.1271 57.7835 62.2451 57.9014C62.363 58.0194 62.4293 58.1793 62.4293
                58.3461V69.9479C62.426 70.5524 62.1836 71.1311 61.7552 71.5576C61.3268 71.9841 60.7472 72.2238 60.1427
                72.2245ZM59.315 58.9649C59.1482 58.9649 58.9883 58.8986 58.8703 58.7807C58.7524 58.6628 58.6861 58.5028
                58.6861 58.3361V56.6783C58.6861 56.5432 58.6595 56.4094 58.6078 56.2845C58.5561 56.1597 58.4803 56.0463 58.3848
                55.9507C58.2892 55.8552 58.1758 55.7795 58.051 55.7278C57.9262 55.676 57.7924 55.6494 57.6572 55.6494H54.3417C54.0689
                55.6494 53.8072 55.7578 53.6142 55.9507C53.4213 56.1437 53.3129 56.4054 53.3129 56.6783V58.3361C53.3129 58.5028
                53.2466 58.6628 53.1287 58.7807C53.0107 58.8986 52.8508 58.9649 52.684 58.9649C52.5172 58.9649 52.3572 58.8986
                52.2393 58.7807C52.1213 58.6628 52.0551 58.5028 52.0551 58.3361V56.6783C52.0557 56.072 52.2969 55.4908 52.7255
                55.0621C53.1542 54.6334 53.7355 54.3923 54.3417 54.3916H57.6572C58.2635 54.3923 58.8448 54.6334 59.2734
                55.0621C59.7021 55.4908 59.9433 56.072 59.9439 56.6783V58.3361C59.9439 58.5028 59.8777 58.6628 59.7597
                58.7807C59.6418 58.8986 59.4818 58.9649 59.315 58.9649Z"
            />
            <path fill="white"
                d="M54.3423 68.0812C54.1755 68.0812 54.0155 68.0149 53.8976 67.8969C53.7796 67.779 53.7134 67.6191
                53.7134 67.4523V62.479C53.7134 62.3122 53.7796 62.1522 53.8976 62.0343C54.0155 61.9163 54.1755 61.8501
                54.3423 61.8501C54.5091 61.8501 54.669 61.9163 54.787 62.0343C54.9049 62.1522 54.9712 62.3122 54.9712
                62.479V67.4523C54.9712 67.6191 54.9049 67.779 54.787 67.8969C54.669 68.0149 54.5091 68.0812 54.3423 68.0812Z"
            />
            <path fill="white"
                d="M57.6582 68.0812C57.4914 68.0812 57.3314 68.0149 57.2135 67.8969C57.0955 67.779 57.0293 67.6191 57.0293
                67.4523V62.479C57.0293 62.3122 57.0955 62.1522 57.2135 62.0343C57.3314 61.9163 57.4914 61.8501 57.6582
                61.8501C57.825 61.8501 57.985 61.9163 58.1029 62.0343C58.2208 62.1522 58.2871 62.3122 58.2871 62.479V67.4523C58.2871
                67.6191 58.2208 67.779 58.1029 67.8969C57.985 68.0149 57.825 68.0812 57.6582 68.0812Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_2_2">
                <rect width="112" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
