import React from 'react';
import { allTypes } from '../../../utils/lib/search';
import { CheckboxFilter } from '../../Filters';

export const ThreatFilter = ({ threatTypes, setTypes, title = 'Threat type' }) => {
    const threatTypeFilters = allTypes.map(item => ({ ...item, label: item.name }));

    const setTypeFilters = (data) => {
        if (data.includes('ITH')) {
            data.push('CTA');
        } else if (data.includes('CTA')) {
            const index = data.indexOf('CTA');
            data.splice(index, 1);
        }
        setTypes(data);
    };

    return (
        <div>
            <p><strong>{title}</strong></p>
            <CheckboxFilter title={title}
                filters={threatTypeFilters}
                values={threatTypes}
                setValues={setTypeFilters}
                value="threat_type"
            />
        </div>

    );
};
