import React, { useState } from 'react';

type ReadMoreProps = {
    text: string,
    limit: number,
    className?: string
}
export const ReadMore = ({ text, limit, className }: ReadMoreProps) => {
    const [readMore, setReadMore] = useState(true);

    const toggleReadMore = () => {
        setReadMore(!readMore);
    };

    return (
        <p data-testid={readMore ? 'read-more-closed' : 'read-more-open'} className={className || ''}>
            {readMore ? `${text.slice(0, limit)}...` : text}
            <span onClick={toggleReadMore} className="readMore-button">
                {readMore ? 'Read more' : ' Read less'}
            </span>
        </p>
    );
};
