import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../../../../../../components/Loading';
import { Pagination } from '../../../../../../../../components/Molecules';
import { convertToFilterQuery, useNarratives } from '../../../../../../../../services/Narratives/getNarratives';
import { narrativeSortOptions } from '../../../../../../Narratives/Overview/Narratives';
import { NarrativeSearch } from './NarrativeSearch';
import { NarrativesTableInner } from './NarrativesTableInner';
import { NoResults } from '../../NoResults';

export const NarrativesTable = () => {
    const filters = useSelector((state) => state.narrativeFilters);
    const [sort, setSort] = useState(narrativeSortOptions[0]);
    const [searchPhrase, setSearchPhrase] = useState();
    const [offset, setOffset] = useState(0);
    const filterQuery = convertToFilterQuery({ filters, searchPhrase });

    const { data, loading, count } = useNarratives({
        filterQuery: {
            ...filterQuery,
            bool: {
                ...(filterQuery?.bool || {}),
                filter: [
                    ...(filterQuery?.bool?.filter || [])
                ],
                must_not: [
                    {
                        match_phrase: {
                            status: {
                                query: 'delete'
                            }
                        }
                    }
                ]
            }
        },
        sort: sort?.key || '',
        skip: offset
    });

    if (loading) {
        return <Loading relative height={300} />;
    }

    if (!data || data?.length === 0) {
        return <NoResults text="There are no narratives created yet!" />;
    }

    return (
        <div>
            <NarrativeSearch sort={sort} onSort={setSort} onSearchPhrase={setSearchPhrase} />
            <NarrativesTableInner data={data} count={count} />
            <Pagination total={count} itemsPerPage={10} offset={offset} setOffset={setOffset} className="py-2" />
        </div>
    );
};
