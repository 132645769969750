import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useContentFilters } from '../../../useFilters';
import { entityTypes } from '../utils';
import { deduplicate } from '../../../../../utils/deduplicate';

const d = new Date();
const date = moment(d);
const oneYear = new Date();
oneYear.setFullYear(oneYear.getFullYear() - 1);

export const getInterval = ({ duration, preview } = {}) => {
    if (preview) return '1w';

    if (duration > 365) return '1y';
    if (duration > 60) return '1M';
    if (duration > 10) return '1w';
    if (duration > 1) return '1d';

    return '1h';
};

export const useTopEntityMentions = ({ size = 5, preview } = {}) => {
    const filters = useContentFilters({ useGlobalFilters: true });

    const duration = moment.duration(date.diff(moment(filters.date?.startDate || oneYear.getTime()))).asDays();
    const interval = getInterval({ duration, preview });
    const lessThanAWeek = (filters.date?.startDate || 0) > (new Date().getTime() - 7 * 24 * 60 * 60 * 1000);

    const ret = useQuery(TOP_ENTITY_MENTIONS, {
        variables: {
            ...filters,
            size,
            interval
        }
    });

    const entitiesList = ret.data ? ret.data?.topEntityMentions?.map(item => ({
        ...item,
        key: item.entity,
        entity: item.entity
    })) || [] : [];

    const entities = entityTypes.map(type => {
        const topHits = deduplicate({
            data: entitiesList.filter(item => item.type === type.key),
            field: 'entity',
            countField: 'count',
            useLevenshtein: type.key !== 'LOC'
        }).slice(0, 10);
        return topHits;
    }).flat().sort((a, b) => b.count - a.count).slice(0, size);
    return {
        ...ret,
        entities,
        lessThanAWeek,
        interval
    };
};

export const TOP_ENTITY_MENTIONS = gql`
    query topEntityMentions(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $interval: String
        $size: Int
    ) {
        topEntityMentions(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            interval: $interval
            size: $size
        ) {
            entity
            count
            type
            trend {
                date
                count
            }
        }
    }
`;
