import { sentiments } from '../../../../utils/lib/search';

export const formatFilters = (filters) => {
    if (!filters) return {};

    return {
        bool: {
            filters: [
                ...(getIncludedSources({ includedSources: filters.includedSources })),
                ...(getSentiment({ sentiment: filters.sentiment })),
                ...(getIncludedLocations({ includedLocations: filters.includedLocations }))
            ]
        },
    };
};

export const getIncludedSources = ({ includedSources }) => {
    if (!includedSources) {
        return [];
    }

    if (includedSources.length > 0) {
        return [{
            terms: {
                original_post_ctype__keyword: includedSources.map((a) => a.key)
            }
        }];
    }
    if (includedSources.length === 0) {
        return [{
            terms: {
                original_post_ctype__keyword: []
            }
        }];
    }
};

export const getSentiment = ({ sentiment }) => {
    if (!sentiment || !sentiment.length) {
        return [];
    }

    if (sentiment.length > 0 && sentiment.length <= 4) {
        const finalSentiment = sentiments.map(({ key }) => key).filter(a => !sentiment.includes(a));
        return [{
            terms: {
                average_sentiment_label: finalSentiment
            }
        }];
    }
};

export const getIncludedLocations = ({ includedLocations }) => {
    if (!includedLocations) {
        return [];
    }

    if (includedLocations.length > 0) {
        return [{
            terms: {
                narrative_locations__location__keyword: includedLocations.map((a) => a.text)
            }
        }];
    }
    if (includedLocations.length === 0) {
        return [{
            terms: {
                narrative_locations__location__keyword: []
            }
        }];
    }
};
