import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as FeatherIcon from 'react-feather';
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { REQUEST_TOKENS } from '../../../graphql';
import TicketsIcon from '../../../assets/images/investigations/tickets.svg';
import { useDropDown } from '../../../utils/useDropdown';
import { useModal } from '../../../context/modal/ModalComponent';

export const TokensRequestModal = ({ instance }) => {
    const { closeModal } = useModal();
    const [dropdown, toggleDropdown] = useDropDown();
    const [val, setVal] = useState(0);
    const [requestUpdated, setRequestUpdated] = useState(false);
    const [createTokensRequest, { loading }] = useMutation(REQUEST_TOKENS, {
        onCompleted: () => {
            setRequestUpdated(true);
        }
    });
    const handleSubmit = () => {
        createTokensRequest({
            variables: { data: {
                instance,
                tokensRequested: parseInt(val, 10)
            } }
        });
    };
    return (
        <div>
            <img src={TicketsIcon} />
            <div className="mt-2">
                {!requestUpdated ? 'Request Tokens' : 'Your request has been received.'}
            </div>
            <hr />
            {requestUpdated
                ? (
                    <div>
                        <p>Your account manager will contact you shortly.</p>
                    </div>
                )
                : (
                    <>
                        <p>Select no. of token required</p>
                        <Dropdown isOpen={dropdown} toggle={toggleDropdown} style={{ width: 250 }}>
                            <DropdownToggle tag="div" className="form-control">
                                {val}
                                <span className="float-right">
                                    <FeatherIcon.ChevronDown color="#006FF9" size={15} />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item =>
                                        <DropdownItem onClick={() => setVal(item)} key={item}>{item}</DropdownItem>)
                                }
                            </DropdownMenu>
                        </Dropdown>

                    </>
                )}
            <hr />
            {requestUpdated
                ? <Button onClick={() => closeModal()}>Close</Button>
                : (
                    <>
                        <Button color="primary"
                            type="button"
                            onClick={handleSubmit}
                            disabled={loading || val === 0}
                        >Request
                        </Button>
                        <Button className="ml-2" onClick={() => closeModal()}>Cancel</Button>
                    </>
                )}
        </div>
    );
};
