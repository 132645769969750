import moment from 'moment';
import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { EmptyState } from '../../../../../components/EmptyState';
import { Loading } from '../../../../../components/Loading';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { entityColors } from './options';
import { useEntityHistory } from './useEntityHistory';
import NoDataAvailable from '../../../../../assets/images/NoDataAvailable.png';
import { SortToggle } from '../../../../../components/Table/SortToggle';

export const EntitySentimentHistory = ({ threat }) => {
    const [sort, setSort] = useState({});
    const { closeModal } = useModal();
    const { loading, data } = useEntityHistory({ threat });
    if (loading) {
        return <Loading relative height={300} />;
    }
    const sortEntities = (a, b) => {
        if (!sort.field) {
            return 0;
        }
        if (sort.field === 'name') {
            if (sort.order === 'asc') {
                return b.entityName.localeCompare(a.entityName);
            }
            return a.entityName.localeCompare(b.entityName);
        }
        if (sort.field === 'user') {
            if (sort.order === 'asc') {
                return b.user.displayName.localeCompare(a.user.displayName);
            }
            return a.user.displayName.localeCompare(b.user.displayName);
        }
        if (sort.field === 'date') {
            if (sort.order === 'asc') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            }
            return new Date(b.createdAt) - new Date(a.createdAt);
        }
    };
    return (
        <div>
            <h2 className="mb-3 mt-0">Entity Sentiment history</h2>
            <hr className="my-3" />
            <p className="my-3">View entity add/delete/change sentiment history. You can also view the old sentiment of the entity</p>
            {!data.length ? (
                <div className="my-3 border">
                    <EmptyState title="No data available" icon={NoDataAvailable} />
                </div>
            ) : null}
            <Table className={`mb-0 ${!data.length ? 'd-none' : ''}`}>
                <thead>
                    <tr>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Date" field="date" />
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="User" field="user" />
                        </th>
                        <th>
                            Action
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Entity name" field="name" />
                        </th>
                        <th>
                            Current sentiment
                        </th>
                        <th>
                            Old sentiment
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {[...data].sort(sortEntities).map(item => (
                        <tr key={item.id}>
                            <td>
                                {moment(new Date(item.createdAt)).format('DD MMM YYYY')}
                            </td>
                            <td>
                                {item.user.displayName}
                            </td>
                            <td>
                                {actions[item.action]}
                            </td>
                            <td>
                                {item.entityName}
                            </td>
                            <td className="text-capitalize">
                                <div className="d-flex align-items-center">
                                    <div className={`bg-${entityColors[item.sentiment].bg} rounded h-0 mr-1 entity-color`} />
                                    {item.sentiment}
                                </div>
                            </td>
                            <td className="text-capitalize">
                                <div className="d-flex align-items-center">
                                    {item.oldSentiment && (
                                        <div className={
                                            `bg-${entityColors[item.oldSentiment].bg} rounded h-0 mr-1 entity-color`
                                        }
                                        />
                                    )}
                                    {item.oldSentiment || 'N/A'}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <hr className="mt-0" />
            <Button className="float-right" onClick={closeModal}>Close</Button>
        </div>
    );
};

const actions = {
    delete: 'Deleted entity',
    update: 'Changed sentiment',
    create: 'Added new entity'
};
