import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Table } from 'reactstrap';
import { Loading } from '../../../../components/Loading';
import { GET_ROOM_TEAM } from '../../../../graphql';
import { User } from '../../../../assets/svg/user';
import { useModal } from '../../../../context/modal/ModalComponent';
import { RemoveUserModal } from './RemoveUserModal';
import { AddUserModal } from './AddUserModal';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom, getTeam } from '../../../../utils/variables';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { lodashSort } from '../../../../utils/lib/tableSorting';

export const Team = () => {
    const room = getRoom();
    const { user: currentUser, isAdmin } = useUser();
    const [sort, setSort] = useState('');
    const { setModal, closeModal } = useModal();
    const { data, loading, refetch } = useQuery(GET_ROOM_TEAM, {
        variables: { instance: room.instance.id, situationRoom: room.id },
        fetchPolicy: 'no-cache'
    });
    const users = useMemo(() => (data ? data.users : []), [data]);
    const roomRemoved = data ? data.situationRoom.removedUsers || [] : [];
    const usersWithAccess = users.filter(user => !roomRemoved.includes(user.id));
    const removedUsers = users.filter(user => roomRemoved.includes(user.id));
    useEffect(() => {
        getTeam(users);
    }, [users]);
    return (
        <div className="bg-white p-3">
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="mr-3 my-0">Team</h3>
                {(removedUsers.length > 0 && isAdmin) && (
                    <Button onClick={() => setModal({
                        header: false,
                        size: 'lg',
                        component: <AddUserModal users={removedUsers} room={{ id: room.id, removedUsers: roomRemoved }} refetch={refetch} />
                    })}
                    >Add member
                    </Button>
                )}
            </div>
            <hr className="my-3" />
            <p className="mb-3">You can see the team of this situation room. Only admins can remove the members.</p>
            {loading ? <Loading height={200} relative /> : (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <SortToggle sort={sort}
                                    setSort={setSort}
                                    name="Name"
                                    field="displayName"
                                />
                            </th>
                            <th>
                                <SortToggle sort={sort}
                                    setSort={setSort}
                                    name="Email"
                                    field="email"
                                />

                            </th>
                            <th>
                                <SortToggle sort={sort}
                                    setSort={setSort}
                                    name="Role"
                                    field="role"
                                />
                            </th>
                            {isAdmin && (
                                <th>
                                    Actions
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {lodashSort(usersWithAccess, sort).map(user => (
                            <tr key={user.id}>
                                <td>
                                    {user.photoURL ? (
                                        <img src={user.photoURL}
                                            alt="logically user"
                                            className="rounded-circle mr-2"
                                            style={{ width: 32, height: 32 }}
                                        />

                                    )
                                        : (
                                            <span className="mr-2"> <User size="32" /></span>
                                        )}
                                    {user.displayName || `${user.firstName || ''} ${user.lastName || 'Not available'}`}
                                </td>
                                <td data-testid="team-user-table-email">
                                    {user.email}
                                </td>
                                <td className="text-capitalize">{user.role.toLowerCase()}</td>
                                {isAdmin && (
                                    <td>
                                        {user.id !== currentUser.id && (
                                            <p className="text-primary"
                                                role="button"
                                                onClick={() => setModal({
                                                    header: false,
                                                    component: <RemoveUserModal room={{ ...room, removedusers: roomRemoved }}
                                                        closeModal={closeModal}
                                                        refetch={refetch}
                                                        user={user}
                                                    />
                                                })}
                                            >Remove
                                            </p>
                                        )}
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};
