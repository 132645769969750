import React from 'react';
import { CustomInput } from 'reactstrap';
import { capitalizeFirstLetter } from '../../../../utils/text';

export const ClusterItems = ({ cluster, clusterIdList, handleInputChange }) => {
    const clusterId = cluster.cluster_tag;
    return (
        <div key={clusterId}>
            <hr className="cluster-line" />
            <div className="d-flex w-100 ">
                <CustomInput type="checkbox"
                    data-testid={`${clusterId}`}
                    className="mr-4"
                    checked={clusterIdList.includes(clusterId)}
                    id={clusterId}
                    onChange={() => handleInputChange(clusterId)}
                />
                <li key={clusterId} className="cluster-main-item w-100">
                    {cluster?.cluster_name && `${capitalizeFirstLetter(cluster.cluster_name)} (${cluster.cluster_tag})`}
                </li>
            </div>
            <ol className="cluster-list-items w-100 pl-0">
                {cluster.sub_cluster_info.map((subCluster) => {
                    const subClusterId = subCluster.cluster_tag;
                    return (
                        <div key={subClusterId}>
                            <hr className="mr-2" />
                            <div key={subClusterId} className="d-flex">
                                <CustomInput type="checkbox"
                                    data-testid={`${subClusterId}`}
                                    className="mr-4"
                                    checked={clusterIdList.includes(clusterId) || clusterIdList.includes(subClusterId)}
                                    disabled={clusterIdList.includes(clusterId)}
                                    id={subClusterId}
                                    onChange={() => handleInputChange(subClusterId)}
                                />
                                <li key={subClusterId} className="w-100">
                                    { subCluster?.sub_cluster_name
                                         && `${capitalizeFirstLetter(subCluster.sub_cluster_name)} (${subCluster.cluster_tag})`}

                                </li>
                            </div>

                        </div>
                    ); })}
            </ol>
        </div>
    ); };
