import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Facebook } from 'reactjs-social-embed';
import { InstagramEmbed } from 'react-social-media-embed';
import { TikTok } from './TikTok';
import { ContentRemoved } from './ContentRemoved';
import { UrlSnapshot } from './UrlSnapshot';

type ContentProps = {
    contentType: string;
    threadId: string;
    url: string;
    id: string;
    text: string;
    from: string;
}

type ContentPreviewProps = {
    data: ContentProps;
    exists: boolean;
}

export const ContentPreview = ({ data, exists }: ContentPreviewProps) => {
    const uri = data.url?.replace('/groups', '').replace('/permalink/', '/posts/');
    switch (data.contentType) {
        case 'tweet':
            return !exists ? (
                <div className="w-100">
                    <TwitterTweetEmbed tweetId={data.threadId} />
                </div>
            ) : <ContentRemoved />;
        case 'facebook':
            return (
                <div data-testid="facebook-content">
                    <Facebook type="post" width="100%" height="285px" show_text url={uri} />
                </div>
            );
        case 'tiktok':
            return (
                <div data-testid="tiktok-content">
                    <TikTok data={data} />
                </div>
            );
        case 'instagram':
            return (
                <div data-testid="instagram-content">
                    <InstagramEmbed url={data.url} />
                </div>
            );
        default:
            return <UrlSnapshot data={data} />;
    }
};
