import { useMutation } from '@apollo/client';
import { GET_WATCHLIST, REMOVE_FROM_WATCHLIST } from '../../graphql';

export const useRemoveFromWatchlist = ({
    situationRoomId,
    onCompleted,
    onError
}) => {
    const [removeFromWatchlistRequest, { loading }] = useMutation(REMOVE_FROM_WATCHLIST, {
        refetchQueries: [{ query: GET_WATCHLIST, variables: { situationRoom: situationRoomId } }],
        onCompleted,
        onError
    });
    const removeFromWatchlist = async ({ contentId }) => {
        if (!situationRoomId) {
            throw new Error('Situation Room Id is required');
        }
        if (!contentId) {
            throw new Error('Threat content id is required');
        }

        await removeFromWatchlistRequest({
            variables: {
                situationRoom: situationRoomId,
                contentId
            }
        });
    };

    return { removeFromWatchlist, loading };
};
