import React from 'react';

export const BooleanHelpText = () => (
    <div className="bg-white">
        <p>
            <b>Boolean Operators to use:</b><br />
            AND, OR, NOT, NEAR.<br /><br />
            <b>Boolean Modifiers to use:</b><br />
            quotes &quot;&quot;, brackets (), wildcard *, replacement ?<br /><br />
            <b>Boolean Fields to use:</b><br />
            title, country, language, domain, url, author, links, from, mentions, tags.<br /><br />
            <b>Boolean Advanced Fields to use:</b><br />
            engagingWith, engagingWithGuid, engagementType.<br /><br />
            <b>Some rules to follow:</b>
        </p>
        <ul>
            <li>Use brackets to signify precedence and different conditions.</li>
            <li>Do not use OR and AND Operators inside the same Boolean condition.</li>
            <li>Use spaces before and after Operators, Fields, and brackets.</li>
            <li>Use double-quotes, not inverted commas or curly/slanted double-quotes.</li>
            <li>Hyphenated words should be in double-quotes unless enclosed within larger phrases.</li>
            <li>
                Do not wrap wildcard (*) terms in double-quotes as it will be treated as a complete search
                term, and do not use them at the start of a word.
            </li>
            <li>Use abbreviations with care.</li>
            <li>
                Search terms or accounts using periods must be wrapped in double quotes, e.g. &quot;Dr. John Smith&quot;
                or from:&quot;@john.smith&quot;:twitter.
            </li>
            <li>Boolean Operators must be capitalized, and Boolean Fields must be in lowercase.</li>
            <li>
                If your term contains multiple words (e.g. United Kingdom), please wrap it in double quotes to ensure it is
                treated as one term. Otherwise it may cause unexpected behaviour during query validation.
            </li>
            <li>
                Phrases should not contain more than two or three words, as extremely long phrases have a performance impact on LI.
            </li>
            <li>
                If you enter a keyword in all uppercase, both uppercase and lowercase will appear in the room but sorting the content by
                Relevance in the room will bring all uppercase results to the top.
            </li>
        </ul><br />
        <p>Please visit <a href="https://logically-intelligence.readme.io/v2.0/docs/new-writing-a-boolean-query" target="_blank" rel="noopener noreferrer">our documentation</a> for other Boolean help.</p>
    </div>
);
