import React, { useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { RemoveClustersModal } from './RemoveClustersModal';
import { ClusterItems } from './ClusterItems';
import { InvestigationSuccess } from '../../../../assets/svg/investigation-success';

export const UpdateClusters = ({ data, setMainClustersData }) => {
    const { setModal, closeModal } = useModal();
    const [clusterIdList, setClusterIdList] = useState([]);
    const [clustersData, setClustersData] = useState(data);
    const clusterLength = useRef(0);
    const subClusterLength = useRef(0);

    const handleInputChange = (id) => {
        clusterLength.current = 0;
        subClusterLength.current = 0;
        const clusterIndex = clusterIdList.indexOf(id);
        if (clusterIndex > -1) {
            const newClusters = [...clusterIdList];
            newClusters.splice(clusterIndex, 1);
            setClusterIdList(newClusters);
        }
        else {
            setClusterIdList([...clusterIdList, id]);
        }
    };
    const confirmRemoveClusters = () => {
        const filteredMainClusters = clustersData.filter((cluster) => {
            const index = clusterIdList.indexOf(cluster.cluster_tag);
            if (index > -1) {
                return false;
            }
            return true;
        });
        const filteredSubClusters = filteredMainClusters.map((cluster) => {
            const updatedCluster = { ...cluster };
            const updatedSubClusters = updatedCluster.sub_cluster_info.filter((subCluster) => {
                const index = clusterIdList.indexOf(subCluster.cluster_tag);
                if (index > -1) {
                    return false;
                }
                return true;
            });
            updatedCluster.sub_cluster_info = updatedSubClusters;
            return updatedCluster;
        });

        setClustersData(filteredSubClusters);
        clusterLength.current = 0;
        subClusterLength.current = 0;
        setClusterIdList([]);
        onConfirmClusters();
    };

    const onUpdateClusters = () => {
        setModal({
            component: <RemoveClustersModal clusterIdList={clusterIdList}
                clustersData={clustersData}
                confirmRemoveClusters={confirmRemoveClusters}
            />
        });
    };

    const onConfirmClusters = () => {
        setMainClustersData(clustersData);
        setModal({
            component:
    <div>
        <InvestigationSuccess color="" size={20} />
        <h2 className="mt-2">Clusters updated successfully</h2>
        <hr />
        <Button onClick={() => closeModal()}>Close</Button>
    </div>
        });
    };

    if (clusterIdList.length > 0) {
        clustersData.forEach((cluster) => {
            const index = clusterIdList.indexOf(cluster.cluster_tag);
            if (index > -1) {
                clusterLength.current += 1;
            }
            cluster.sub_cluster_info.forEach((subCluster) => {
                const subClusterIndex = clusterIdList.indexOf(subCluster.cluster_tag);
                if (subClusterIndex > -1) {
                    subClusterLength.current += 1;
                }
                else if (index > -1) {
                    subClusterLength.current += 1;
                }
            });
        });
    }

    return (
        <div>
            <h2>Update clusters</h2>
            <hr />
            <p className="sentence-format">When you press ‘Confirm’, the clusters mentioned below which
                you have labelled will be saved. All other clusters removed from your situation room.
                You can also remove the labelled clusters listed below using the ‘Next’ button.
            </p>
            <hr />
            <h2 className="ml-2">Labelled clusters & subclusters</h2>
            <ol className="cluster-list pl-2 mb-1">
                {clustersData.map((cluster) => (
                    <ClusterItems clusterIdList={clusterIdList} cluster={cluster} handleInputChange={handleInputChange} />

                ))}
            </ol>
            <hr />
            {(clusterIdList.length > 0)
             && (
                 <>
                     <p className="font-weight-bold pl-2">
                         {clusterLength.current} cluster{clusterLength.current > 1 && 's'} and &nbsp;
                         {subClusterLength.current} subclusters selected
                     </p>
                     <hr className="mt-0" />
                 </>
             ) }
            <div className="d-flex">
                <Button onClick={() => closeModal()} className="mr-2">
                    Cancel
                </Button>
                {clusterIdList.length === 0 && (
                    <Button color="primary" className="mr-2" onClick={() => onConfirmClusters()}>
                        Confirm
                    </Button>
                )}
                <Button color="primary" onClick={() => onUpdateClusters()} disabled={clusterIdList.length === 0}>
                    Next
                </Button>
            </div>
        </div>
    );
};
