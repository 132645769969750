import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GET_TEAM } from '../../graphql';
import { Loading } from '../../components/Loading';
import { useUser } from '../../context/user/UserContext';
import { routes } from '../../routes';
import { UserDetails } from './UserDetails.js';
import { Users } from './Users';
import { ActionItems } from './ActionItems';
import { useGetInstance } from '../../services/Instance/getInstance';
import { getContentLink } from '../../utils/getContentLink';

export const Team = () => {
    const { user } = useUser();
    const { instance } = useGetInstance({ instanceId: user?.instance?.id });
    const [searchValue, setSearchValue] = useState('');
    const { loading, data } = useQuery(GET_TEAM, {
        variables: {
            instance: user?.instance?.id || 1,
            truncateName: 20
        },
        fetchPolicy: 'cache-and-network'
    });

    if (instance?.plan?.restrictedTeamRole && user.role !== 'ADMIN') {
        return <Redirect to={getContentLink({ id: routes.loggedIn })} />;
    }

    const handleSearch = (val) => {
        setSearchValue(val);
    };

    const filterData = () => data?.users?.filter(usr => {
        let ret = true;
        if (searchValue && !(usr.displayName?.toLowerCase()?.includes(searchValue.toLowerCase())
        || usr.email.toLowerCase().includes(searchValue.toLowerCase()))) {
            ret = false;
        }
        return ret;
    });

    return (
        <div className="p-4 teamsTab">
            <Switch>
                <Route exact path={routes.loggedIn.teams}>
                    <div>
                        {loading ? (
                            <div className="mt-4 pt-4">
                                <Loading gif />
                                {' '}
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <h1 className="m-0">
                                        Hello {user?.displayName}, Meet your team
                                    </h1>
                                </div>
                                <ActionItems users={data?.users || []} handleSearchValue={handleSearch} />
                                <Users users={filterData() || []} />
                            </div>
                        )}
                    </div>
                </Route>
                <Route path={routes.loggedIn.team}>
                    <UserDetails currentUser={user} />
                </Route>
            </Switch>
        </div>
    );
};
