import React, { useState, useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { TabContent, TabPane } from 'reactstrap';
import { getParam, setParam } from '../../utils/urlParams';

type Data = {
    id: string;
    name: string;
    content: React.ReactNode;
    disabled?: boolean;
    onlyRenderWhenActive?: boolean
}

type TabsProps = {
    data: Data[];
    small?: boolean;
    param?: string;
    prevKey?: string;
    arrows?: boolean;
    goToTab?: (setTab: any) => void;
    onChange?: (tab: string) => void;
    className?: string;
}

export const Tabs = ({ data, small, param, prevKey, arrows, goToTab, onChange, className }: TabsProps) => {
    const urlParam = getParam(param || '');
    const tabs = data.filter(item => item);
    const filterByParam = tabs.filter((item) => item?.id === urlParam);
    const paramValue = filterByParam.length > 0 ? urlParam : null;
    const filterByprevKey = tabs.filter((item) => item?.id === prevKey);
    const prevTabValue = filterByprevKey.length > 0 ? prevKey : null;
    const [tab, setTab] = useState(prevTabValue || paramValue || tabs[0]?.id);
    useEffect(() => {
        if (goToTab) {
            goToTab(setTab);
        }
    }, [goToTab]);

    useEffect(() => {
        if (param) {
            setParam(param, tab);
        }
    }, [tab, param]);

    return (
        <div>
            <div className={`multi-tabs ${className}`} data-testid="multi-tabs">
                {tabs.map((item, i) => (
                    <React.Fragment key={item.id}>
                        <div className={`mr-4 ${item.id === tab ? 'selected-tab' : ''} ${item.disabled ? 'disabled' : ''}`}
                            onClick={() => {
                                if (!item.disabled) {
                                    setTab(item.id);
                                    if (onChange) {
                                        onChange(item.id);
                                    }
                                }
                            }}
                        >
                            {small ? (
                                <p data-testid={`${item.id}-tab-item-p`} className="m-0 cursor-pointer">
                                    <strong className="cursor-pointer">{item.name}</strong>
                                </p>
                            ) : <h4 data-testid={`${item.id}-tab-item-h4`} className="cursor-pointer">{item.name}</h4>}
                        </div>
                        {(arrows && i !== tabs.length - 1) && <ChevronRight className="mr-4 text-dark mb-1" size={22} />}
                    </React.Fragment>
                ))}
            </div>
            <TabContent data-testid="tab-content" activeTab={tab}>
                {tabs.map(item => (
                    <TabPane data-testid={`${item.id}-tab-pane`} key={item.id} tabId={item.id}>
                        {(!item.onlyRenderWhenActive || tab === item.id) ? item.content : ''}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
};
