import React from 'react';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { getRoom, getWatchlist, refetchWatchlist } from '../../../../../utils/variables';
import { WATCHLIST_BUTTON_TYPES, WatchlistButton } from '../../../Threats/Threat/WatchlistButton';

export const AddToWatchlist = ({ narrativeId }) => {
    const room = getRoom();
    const refetch = refetchWatchlist();
    const [toggleWatchlist, { loading }] = useMutation(TOGGLE_WATCHLIST, {
        onCompleted: () => {
            refetch();
        },
    });
    const watchlist = useReactiveVar(getWatchlist);
    const inWatchlist = watchlist?.includes(narrativeId);

    const handleToggle = () => {
        toggleWatchlist({
            variables: {
                situationRoom: room.id,
                narrative: [narrativeId],
            },
        });
    };

    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;

    if (!watchlistEnabled) return null;

    return (
        <WatchlistButton isWatching={inWatchlist}
            isWatchingSpinner={loading}
            addToWatchlist={handleToggle}
            removeFromWatchlist={handleToggle}
            type={WATCHLIST_BUTTON_TYPES.TEXT_BUTTON}
        />
    );
};

export const TOGGLE_WATCHLIST = gql`
  mutation toggleNarrativesWatchlist(
    $situationRoom: ID!
    $narrative: [String]!
  ) {
    toggleNarrativesWatchlist(
      situationRoom: $situationRoom
      narrative: $narrative
    ) {
      done
      message
    }
  }
`;
