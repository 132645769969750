import React, { Dispatch, SetStateAction } from 'react';

import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { DropdownWithSearchAlphabetical, Item } from './components/DropdownWithSearchAlphabetical';
import { TOP_PUBLISHERS } from '../../services/Overview/topPublishers';

type Actor = {
    publisher: string
}

type ActorsFilterProps = {
    actors: Item[],
    setActors: Dispatch<SetStateAction<Item[]>>
};

export const ActorsFilter = ({ actors, setActors }: ActorsFilterProps) => {
    const globalFilters = useSelector((state: any) => state.filters.filters);
    const { data } = useQuery(TOP_PUBLISHERS, {
        variables: {
            size: 100
        },
        onCompleted: () => {
            const actorsData = data.topPublishers.data.map((actor: Actor) => ({
                name: actor.publisher, id: actor.publisher, selected: globalFilters?.from?.includes(actor.publisher)
            }));
            setActors(actorsData);
        }
    });

    const handleSelectItem = (id: string) => {
        setActors(oldActors => {
            const selectedActor = oldActors.findIndex(actor => actor.id === id);
            const newActors = [...oldActors];
            newActors[selectedActor].selected = !newActors[selectedActor].selected;
            return newActors;
        });
    };
    const countFilters = (actors.filter((a) => a.selected === true)).length;

    return (
        <DropdownWithSearchAlphabetical placeholder="Search for actors"
            items={actors}
            handleSelectItem={handleSelectItem}
            name={countFilters > 0
                ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
        />
    );
};
