import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';
import { Page } from './Page';
import { NotFound } from '../../../Error/404';
import { getContentLink } from '../../../../utils/getContentLink';
import { useContent } from '../../../../services/Content/getContent';

export const Threat = () => {
    const match = useRouteMatch();
    const {
        loading,
        data: contents,
        refetch
    } = useContent({
        filters: { ids: match.params.threat },
        limit: 1,
        fetchPolicy: 'no-cache',
        name: 'getThreatSummary'
    });
    const content = contents[0];

    if (loading) return <Loading gif height={600} />;
    if (!content) return <NotFound small />;
    if (content.threatLevel === 'none') {
        return <Redirect to={getContentLink({ id: match.params.threat, threatLevel: 'none' })} />;
    }

    return (
        <Page data={content}
            refetch={refetch}
        />
    );
};
