import React from 'react';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { getCtype } from '../../../../../utils/text';

type SourcesListProps = {
    source: string
    contentIds: string[]
}

export const SourcesList = ({ source, contentIds }: SourcesListProps) => {
    const ctype = getCtype(source);
    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-2">
                <p className="w-75 text-primary">
                    <SourceIconLabel data={{ contentType: source }} className="pl-1 d-inline" />
                    <p className="d-inline text-primary">{ctype?.name ? ctype.name : 'N/A'}</p>
                </p>
                <p className="w-25">{contentIds.length || 0}</p>
            </div>
            <hr className="set-underline-props" />
        </>
    );
};
