import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Spinner } from 'reactstrap';
import { largeNumber } from '../../utils/NumberFormat';

export const RoomMetrics = ({ roomMetrics }) => {
    if (!roomMetrics) {
        return (
            <>
                <td><Spinner size="sm" color="primary" /></td>
                <td><Spinner size="sm" color="primary" /></td>
                <td><Spinner size="sm" color="primary" /></td>
            </>
        );
    }
    return (
        <>
            <td>{largeNumber(roomMetrics?.matches)}</td>
            <td>{largeNumber(roomMetrics?.threats)}</td>
            <td>{largeNumber(roomMetrics?.narratives)}</td>
        </>
    );
};

export const RoomMetricsThreats = ({ roomMetrics }) => {
    if (!roomMetrics) {
        return (
            <Spinner size="sm" color="primary" />
        );
    }
    return (
        <p className="m-0">
            <b>{roomMetrics?.threats}</b> Threats
        </p>
    );
};

export const useRoomMetrics = (projectIds = []) => {
    const { data, loading } = useQuery(GET_ROOM_AGGS, {
        variables: {
            projectIds
        },
        skip: !projectIds.length
    });
    if (loading) {
        return [];
    }
    return data?.getRoomMetrics || [];
};

export const GET_ROOM_AGGS = gql`
    query getRoomMetrics($projectIds: [String]!) {
        getRoomMetrics(projectIds: $projectIds) {
            matches
            threats
            narratives
            projectId
        }
    }
`;
