import React from 'react';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { useDropDown } from '../../../utils/useDropdown';

export const TopicInsightsKebabMenu = ({ id }) => {
    const [dropdown, toggleDropdown] = useDropDown();

    return (
        <Dropdown onClick={(e) => { e.preventDefault(); }}
            className="d-inline ml-1 text-right"
            isOpen={dropdown}
            toggle={toggleDropdown}
            tag="div"
            direction="left"
            style={{ marginRight: '-16px' }}
            id={id}
        >
            <DropdownToggle className="bg-transparent border-0" data-testid={`kebabmenu${id}`} style={{ minWidth: 10 }}>
                <span><i className="fa fa-ellipsis-v text-secondary" /></span>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem>
                    <span className="text-primary">
                        Download PNG
                    </span>
                </DropdownItem>
                <DropdownItem>
                    <span className="text-primary">
                        Download XLS
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};
