import { gql, useQuery } from '@apollo/client';
import { elasticFilterWithProjectId } from '../../utils/elasticFilterWithProjectId';
import { useNarratives } from './getNarratives';

export const useGetNarrativesWatchlist = ({ situationRoomId, skip }) => {
    const { data: watchlist, loading } = useQuery(GET_NARRATIVES_WATCHLIST, {
        variables: {
            situationRoom: situationRoomId,
        },
        fetchPolicy: 'no-cache'
    });
    const ids = watchlist?.getNarrativesWatchlist || [];
    const { data, loading: loadingB, count } = useNarratives({
        filterQuery: elasticFilterWithProjectId([{
            ids: {
                values: ids
            }
        }]),
        limit: 10,
        skip
    });

    return {
        data,
        loading: loading || loadingB,
        count
    };
};

export const GET_NARRATIVES_WATCHLIST = gql`
    query getNarrativesWatchlist($situationRoom: ID!){
        getNarrativesWatchlist(situationRoom: $situationRoom)
    }
`;
