import { useReactiveVar } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { DeleteArchiveModal } from '../Modals';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom, refetchRoom } from '../../../../utils/variables';

export const Details = () => {
    const room = getRoom();
    const { isAdmin } = useUser();
    const { setModal, closeModal } = useModal();
    const refetch = useReactiveVar(refetchRoom);
    const handleModal = (type) => {
        setModal({
            header: false,
            component: <DeleteArchiveModal type={type}
                data={{ id: room.id,
                    title: room.project_name,
                    draftId: room.draftId,
                    situationRoomId: room.situationRoomId || room.id }}
                closeModal={closeModal}
                onDone={refetch}
            />
        });
    };
    return (
        <div className="bg-white p-3">
            <h3 className="m-0">Details</h3>
            <hr className="my-3" />
            <p className="mb-3">
                Here you can see the details of this situation room.
                Any change that you do on this page will affect and be displayed for the whole team.
            </p>
            <p className="m-0"><strong>Created</strong></p>
            <p className="mb-3">{moment(room.createdAt).format('DD/MM/YYYY | hh:mm A')} by {room?.createdBy?.displayName}</p>
            <p className="m-0"><strong>Last updated</strong></p>
            <p className="mb-3">
                {room.updatedAt && room.updatedBy
                    ? `${moment(room.updatedAt).format('DD/MM/YYYY | hh:mm A')} by ${room?.updatedBy?.displayName}`
                    : 'Not updated' }
            </p>
            {isAdmin && (
                <>
                    <hr className="my-3" />
                    <p className="my-3">
                        If you Archive the room, you will be able to reverse this action and make the room live again.
                        If you Delete the room, all of the data will be permanently lost.
                    </p>
                    <Button color="secondary" className="mr-2" onClick={() => handleModal('archive')}>Archive room</Button>
                    <Button color="danger" onClick={() => handleModal('delete')}>Delete room</Button>
                </>
            )}
        </div>
    );
};
