import React, { useState } from 'react';
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    TabContent,
    TabPane
} from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { UPDATE_PROFILE, UPDATE_USER } from '../../graphql';
import { setParam, getParam, HandleLocationChange } from '../../utils/urlParams';
import { useUser } from '../../context/user/UserContext';
import { PersonalInfo } from './personal-info';
import { ProfileImage } from './profile-image';
import { LoginAndSecurity } from './login-security';
import { Preferences } from './preferences';
import { GET_CONNECTION_DETAILS } from '../Login/EmailForm';

export const Profile = () => {
    const { user, setUser } = useUser();
    const pageOptions = { 'personal-info': 0, 'login-security': 1, preferences: 2 };
    const pageKey = 'p';
    const defaultPageParam = getParam(pageKey);
    const [page, setPage] = useState(defaultPageParam ? pageOptions[defaultPageParam] : 0);
    const [updateUser] = useMutation(UPDATE_USER);
    const [selectedTab, setSelectedTab] = useState(0);
    const [updateProfile] = useMutation(UPDATE_PROFILE, {
        onCompleted: ({ updateProfile: { done, photoURL, message } }) => {
            if (done) {
                if (photoURL) {
                    setUser({ ...user, photoURL });
                }
            } else {
                toast.error(message);
            }
        },
        fetchPolicy: 'no-cache',
    });
    const { data: connection, loading } = useQuery(GET_CONNECTION_DETAILS, {
        variables: {
            email: user.email
        }
    });

    const showAccountTab = !connection?.getConnectionDetails?.connectionName && !loading;

    const updateProfileFunction = (updateData) => {
        updateUser({ variables: { data: updateData } });
    };

    return (
        <Container className="py-4 px-3">
            <HandleLocationChange page={page} setPage={setPage} pageOptions={pageOptions} pageKey={pageKey} />
            <Row className="d-flex flex-column align-items-center">
                <Col xs={12} md={8}>
                    <h2 className="mb-4 mt-0">Profile Settings</h2>
                    <Card className="bg-white">
                        <CardBody className="py-4 px-5 d-flex">
                            <ProfileImage updateProfile={updateProfile} />
                            <div className="px-4">
                                <h2 className="mt-4 font-weight-bolder">
                                    {user.displayName}
                                </h2>
                                <h5>{user.email}</h5>
                            </div>
                        </CardBody>

                    </Card>
                    <div className="multi-tabs mb-0">
                        <div className={`mr-4 ${selectedTab === 0 ? 'selected-tab ' : ''}`}
                            onClick={() => {
                                setSelectedTab(0); setParam(pageKey, 'personal-info');
                            }}
                        >
                            <h4>Personal info</h4>
                        </div>
                        {showAccountTab ? (
                            <div className={`mr-4 ${selectedTab === 1 ? 'selected-tab ' : ''}`}
                                onClick={() => {
                                    setSelectedTab(1); setParam(pageKey, 'login-security');
                                }}
                            >
                                <h4>Account security</h4>
                            </div>
                        ) : null}
                        <div className={`mr-4 ${selectedTab === 2 ? 'selected-tab ' : ''}`}
                            onClick={() => {
                                setSelectedTab(2); setParam(pageKey, 'preferences');
                            }}
                        >
                            <h4>Preferences</h4>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={8} className="mt-4">
                    <Card className="bg-white">
                        <CardBody className="py-3 px-3">
                            <TabContent activeTab={page}>
                                <TabPane tabId={0}>
                                    <PersonalInfo updateProfile={updateProfileFunction} />
                                </TabPane>
                                <TabPane tabId={1}>
                                    <LoginAndSecurity updateProfile={updateProfile} />
                                </TabPane>
                                <TabPane tabId={2}>
                                    <Preferences updateProfile={updateProfileFunction} />
                                </TabPane>
                            </TabContent>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
