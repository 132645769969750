import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { TopicInsightsKebabMenu } from './TopicInsightsKebabMenu';
import { BubblePlot } from '../../../components/Highcharts/BubblePlot';
import { TopicWordCloud } from './TopicWordcloud';
import { DateRangePicker } from '../Narratives/DateRangePicker';
import { Posts } from './Posts';

export const Plots = ({ selectedCloud, data, setSubClusterData, isSubCluster }) => {
    const [dateData, setDateData] = useState({ });
    const [page, setPage] = useState(0);
    const [bubblePlotData, setBubblePlotData] = useState({ data: data?.map(cluster =>
        ({ name: cluster?.sub_cluster_name || cluster?.cluster_name,
            ...cluster?.cluster_loc,
            value: cluster?.cluster_size,
            id: cluster?.cluster_id,
            clicked: 0 })),
    clusterName: {} });
    const onClusterBubbleClick = (item) => {
        const modifiedData = { ...bubblePlotData };
        for (let i = 0; i < modifiedData.data.length; i++) {
            if (item.point.id === modifiedData.data[i].id) {
                modifiedData.data[i].clicked += 1;
            }
            else {
                modifiedData.data[i].clicked = 0;
            }
            if (modifiedData.data[i].clicked === 2 && !isSubCluster) {
                const cluster2 = data.filter(cluster => cluster.cluster_id === modifiedData.data[i].id);
                setSubClusterData({ data: cluster2[0].sub_cluster_info, name: modifiedData.data[i].name });
            }
        }
        setPage(0);
        setBubblePlotData({ data: modifiedData.data, clusterName: { id: item.point.id, name: item.point.name } });
    };
    useEffect(() => {
        if (data.length > 0) {
            setBubblePlotData({ data: data?.map(cluster =>
                ({ name: cluster?.sub_cluster_name || cluster?.cluster_name,
                    ...cluster?.cluster_loc,
                    value: cluster?.cluster_size,
                    id: cluster?.cluster_id,
                    clicked: 0 })),
            clusterName: { id: data[0].cluster_id, name: data[0].sub_cluster_name || data[0].cluster_name } });
            setPage(0);
        }
    }, [data]);

    const selectedCluster = data?.filter(cluster => cluster.cluster_id === bubblePlotData.clusterName.id);
    const postsData = selectedCluster.length > 0 && isSubCluster ? selectedCluster[0]?.documents
        : selectedCluster[0]?.sub_cluster_info.map(cluster => cluster.documents).flat();

    return (
        <>
            <Row className="bg-white border-bottom m-0">
                <Col xs={12} md={6} className="border-right">
                    <div className="d-flex w-100 justify-content-between">
                        <p className="mb-0 mt-1"><strong>{bubblePlotData.clusterName?.name || 'Main clusters'}</strong></p>
                        <TopicInsightsKebabMenu id="bubblePot" />
                    </div>
                    <BubblePlot bubblePlotData={bubblePlotData.data}
                        clusterName={bubblePlotData.clusterName}
                        onClusterBubbleClick={onClusterBubbleClick}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <TopicWordCloud selectedCloud={selectedCloud} data={data} clusterName={bubblePlotData?.clusterName} />
                </Col>
            </Row>
            <Row className="border-bottom justify-content-between py-3 m-0">
                <h3 className="align-items-start">Posts</h3>
                <div className="align-items-end">
                    <DateRangePicker setDateData={setDateData} />
                </div>
            </Row>
            <Posts dateData={dateData} data={postsData} page={page} setPage={setPage} />
        </>
    ); };
