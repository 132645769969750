import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { ItemProps } from './DropdownWIthMultiCheckbox';
import { SelectedFilter } from './SelectedFilter';
import { DateRangeProps } from './DatePicker';

type SelectedFiltersProps = {
    sourceData: ItemProps[],
    setSourceData: Dispatch<SetStateAction<ItemProps[]>>,
    languagesData: ItemProps[],
    setLanguagesData: Dispatch<SetStateAction<ItemProps[]>>,
    keywords: ItemProps[],
    setKeywords: Dispatch<SetStateAction<ItemProps[]>>,
    actors: ItemProps[],
    setActors: Dispatch<SetStateAction<ItemProps[]>>,
    setDateRange: (e: DateRangeProps) => void,
    start_date?: number
};

export const SelectedFilters = ({
    sourceData,
    setSourceData,
    languagesData,
    setLanguagesData,
    keywords,
    setKeywords,
    actors,
    setActors,
    setDateRange,
    start_date
}: SelectedFiltersProps) => {
    const clearAllFilters = () => {
        setSourceData(oldFilterData => oldFilterData.map(item => ({ ...item, selected: false })));
        setLanguagesData(oldFilterData => oldFilterData.map(item => ({ ...item, selected: true })));
        setKeywords(oldFilterData => oldFilterData.map(item => ({ ...item, selected: false })));
        setActors(oldFilterData => oldFilterData.map(item => ({ ...item, selected: false })));
        if (start_date) {
            setDateRange({
                startDate: moment(moment(start_date).toDate()).unix(),
                endDate: moment(moment().toDate()).unix(),
            });
        } else {
            setDateRange({
                startDate: moment(moment().toDate()).unix(),
                endDate: moment(moment().toDate()).unix(),
            });
        }
    };

    return (
        <div className="d-flex flex-column align-items-start">
            <div className="d-flex mb-1">
                <h4>Selected Filters</h4>
                <Button color="link" className="p-0" onClick={clearAllFilters}>Clear all</Button>
            </div>
            {sourceData.find(item => item.selected) && (
                <SelectedFilter filter="source"
                    items={sourceData.filter(item => item.selected)}
                    setFilters={setSourceData}
                />
            )}
            {languagesData.find(item => !item.selected) && (
                <SelectedFilter filter="languages"
                    items={languagesData.filter(item => !item.selected)}
                    setFilters={setLanguagesData}
                />
            )}
            {keywords.find(item => item.selected)
                && <SelectedFilter filter="keywords" items={keywords.filter(item => item.selected)} setFilters={setKeywords} />}
            {actors.find(item => item.selected)
                && <SelectedFilter filter="actors" items={actors.filter(item => item.selected)} setFilters={setActors} />}
        </div>
    ); };
