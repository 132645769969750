import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CUSTOM_NARRATIVES } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { isOdd } from '../../../../../utils/lib';
import { Loading } from '../../../../../components/Loading';
import { CustomNarrativeItem } from './CustomNarrativeItem';
import { EmptyCustomNarrativeState } from './EmptyNarrativeState';
import { AddNewNarrativeButton } from './AddNewNarrativeButton';

export const CustomNarratives = () => {
    const room = getRoom();
    const { data, loading } = useQuery(GET_CUSTOM_NARRATIVES, {
        variables: {
            situationRoom: room.id,
        },
        fetchPolicy: 'no-cache'
    });

    const narratives = data ? data.getCustomNarratives : [];
    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <AddNewNarrativeButton className="mb-4" />
            {(data && narratives.length > 0) ? narratives.map((narrative, index) => (
                <div key={narrative.id} className={`threat-item ${isOdd(index) ? '' : 'odd'}`}>
                    <CustomNarrativeItem narrative={narrative} />
                </div>
            )) : <EmptyCustomNarrativeState />}
        </div>
    );
};
