import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import { useContentFilters } from '../../useFilters';
import { Content } from '../../../../services/Content/contentInterface';
import { NetworksHistoryStore } from '../store';
import { convertToDate } from '../../../../utils/getTimeFrom';

export type NetworkNode = Pick<Content, 'id' | 'contentType' | 'from' | 'hashtags' | 'url' | 'engagement' | 'mentions'
| 'pubdatetime' | 'threatLevel' | 'sentiment'>

type NetworksResponse = {
    loading: boolean
    data: NetworkNode[]
    count: number
}

type UseNetworksDataProps = {
    shouldUseDateFilters?: boolean
}

export const useNetworksData = ({ shouldUseDateFilters }: UseNetworksDataProps): NetworksResponse => {
    const {
        filters: networkFilters
    } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    const filters = useContentFilters({
        useGlobalFilters: false,
        defaultFilters: omit(networkFilters, shouldUseDateFilters ? [] : ['date'])
    });
    const { data, loading } = useQuery(GET_NETWORKS, {
        variables: {
            limit: 5000, // 5000 seems to be too much for the node layout in the settings, although a limit of 2500 works for a laptop.
            sort: 'engagement',
            ...filters
        }
    });
    const content = (data?.getContent?.content || []) as NetworkNode[];
    const count = data?.getContent?.total || 0;

    return {
        loading,
        data: content.filter(({ pubdatetime }) => {
            if (shouldUseDateFilters || !networkFilters.date?.startDate) return true;
            const date = convertToDate(pubdatetime).getTime();
            const filtersStart = convertToDate(networkFilters.date.startDate).getTime();
            const filtersEnd = convertToDate(networkFilters.date.endDate).getTime();
            if (date >= filtersStart && date <= filtersEnd) return true;
            return false;
        }),
        count
    };
};

export const GET_NETWORKS = gql`
    query getNetworksData(
        $projectId: String!
        $sort: ContentSort
        $limit: Int = 10
        $offset: Int = 0
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        getContent(
            projectId: $projectId
            sort: $sort
            limit: $limit
            offset: $offset
            filters: $filters
            dslQuery: $dslQuery
        ) {
            total
            content {
                id
                contentType
                from
                hashtags
                url
                engagement
                mentions
                pubdatetime
                threatLevel
                sentiment
            }
        }
    }
`;
