import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { GET_SITUATION_ROOM_DRAFTS, UPDATE_SITUATION_ROOM, UPDATE_SITUATION_ROOM_DRAFT } from '../../../graphql';
import { Loading } from '../../../components/Loading';
import taskComplete from '../../../assets/images/TaskComplete.png';
import { useUser } from '../../../context/user/UserContext';

export const DeleteArchiveModal = ({ data, closeModal, type, onDone, draft = false }) => {
    const [done, setDone] = useState(false);
    const { user } = useUser();
    const options = {
        delete: {
            variables: {
                id: data.id,
                deleted: true,
                action: 'delete',
                is_active: false
            },
            text: {
                completed: 'deleted',
                do: 'delete',
                description: 'This room, all associated drafts & data contained will be permanently lost. This action isn\'t reversable..',
                button: 'danger'
            }
        },
        archive: {
            variables: {
                id: data.id,
                archived: true,
                action: 'archive',
                is_active: false,
                archivedDetails: {
                    archivedBy: user.id,
                    archivedAt: new Date(),
                    autoArchived: false
                }
            },
            text: {
                completed: 'archived',
                do: 'archive',
                description: 'All of the data will become unavailable to the whole team. '
                    + 'You will be able to activate the room in the homepage.',
                button: 'primary'
            }
        }
    };
    const vars = options[type];
    const [updateSituationRoom, { loading }] = useMutation(UPDATE_SITUATION_ROOM, {
        refetchQueries: [{ query: GET_SITUATION_ROOM_DRAFTS, variables: { instance: user.instance.id } }],
        onCompleted: () => {
            setDone(true);
            if (onDone) {
                onDone();
            }
            if (type === 'delete') {
                updateSituationRoomDraft({
                    variables: {
                        ...vars.variables,
                        id: data.draftId,
                        situationRoomId: data.situationRoomId
                    }
                });
            }
        },
        update: (cache) => cache.evict({ fieldName: 'situationRooms' })
    });
    const [updateSituationRoomDraft, { loading: draftLoading }] = useMutation(UPDATE_SITUATION_ROOM_DRAFT, {
        refetchQueries: [{ query: GET_SITUATION_ROOM_DRAFTS, variables: { instance: user.instance.id } }],
        onCompleted: () => {
            setDone(true);
            if (onDone) {
                onDone();
            }
        },
        update: (cache) => cache.evict({ fieldName: 'situationRoomDrafts' })
    });
    const deleteRoom = () => {
        if (draft) {
            updateSituationRoomDraft({
                variables: {
                    ...vars.variables,
                    situationRoomId: data.situationRoomId
                }
            });
        } else {
            updateSituationRoom({
                variables: {
                    ...vars.variables,
                    draftId: data.draftId
                }
            });
        }
    };
    if (loading || draftLoading) {
        return <Loading height={200} relative />;
    }
    if (done) {
        return (
            <div>
                <img src={taskComplete} alt="" />
                <h2>{data.title} {draft ? 'Draft' : 'room'} was successfully {vars.text.completed}!</h2>
                <hr />
                <Button color="secondary" onClick={closeModal}>Close</Button>
            </div>
        );
    }
    return (
        <div>
            <h2>Are you sure you want to {vars.text.do} {data.title} {draft ? 'Draft' : 'situation room'}?</h2>
            <hr />
            <p>{vars.text.description}</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button color={vars.text.button} onClick={() => deleteRoom()}>Yes, {vars.text.do}</Button>
        </div>
    );
};
