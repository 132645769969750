import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import { truncateString } from '../../../utils/text';
import { Pagination } from '../../../components/Molecules';

export const PostsContentTable = ({ page, total, setPage, data }) => {
    const ITEMS_PER_PAGE = 10;
    return (
        <div className="bg-white">
            <Table>
                <thead>
                    <tr>
                        <th>
                            Post details
                        </th>
                        <th>
                            Content
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(post => (
                        <tr key={post.id}>
                            <td>
                                <p className="word-short"><strong>Posted by: </strong>{post.from.includes('@')
                                    ? post.displayName : `@${post.displayName}`}
                                </p>
                                <p><strong>Posted on: </strong>{moment(post?.date).format('DD/MM/YYYY')}</p>
                            </td>
                            <td>{truncateString(post.text || post.body, 300)}</td>
                            <td className="text-primary d-block set-max-content">
                                <a href={post.url}
                                    target="_blank"
                                    id={`clusterpost-${post?.id}`}
                                    rel="noreferrer"
                                >
                                    Read more
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {total > 10 && (
                <div className="border-top bg-white ">
                    <Pagination total={total}
                        itemsPerPage={ITEMS_PER_PAGE}
                        offset={page}
                        setOffset={setPage}
                        className="my-4"
                    />
                </div>
            )}
        </div>
    ); };
