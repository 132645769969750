import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom } from '../../../../utils/variables';
import { TooltipItem } from '../../../../components/ToolTip';

export const Manage = () => {
    const room = getRoom();
    const { isAdmin } = useUser();
    return (
        <div className="bg-white p-3">
            <h3 className="m-0">Manage</h3>
            <hr className="my-3" />
            <p className="mb-3">
                You can manage your situation room here. Any change done on this page, will be displayed for the whole team.
            </p>
            <p className="m-0"><strong>Room title</strong></p>
            <p className="mb-3">{room.project_name}</p>
            {room.query_type === 'boolean' ? (
                <>
                    <p className="m-0"><strong>Boolean query</strong></p>
                    <p className="sentence-format mb-3">{room.query.boolean}</p>
                </>
            ) : (
                <>
                    <p className="m-0"><strong>Keywords</strong></p>
                    <div className="d-flex align-items-start flex-wrap mb-3">
                        {[...room.query.included, ...room.query.excluded].map((word) =>
                            <div key={uuid()} className="mb-1 mr-1 tag">{word.value}</div>)}
                    </div>
                </>
            )}
            {room.contentVisibilityRestriction && (
                <>
                    <p className="m-0">
                        <strong>Content visibility restriction</strong>
                        <TooltipItem id="contentVisibilityRestriction"
                            content={`Situation room will contain the content up to ${room.contentVisibilityRestriction} days ago`}
                            color="#6C63FF"
                        />
                    </p>
                    <p className="sentence-format mb-3">{room.contentVisibilityRestriction} days</p>
                </>
            )}
            {isAdmin && (
                <>
                    <hr className="my-3" />
                    <Link to={`/situation-room/edit-room/${room.id}`}>
                        <Button color="secondary">Edit situation room</Button>
                    </Link>
                </>
            )}
        </div>
    );
};
