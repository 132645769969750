import React, { useState } from 'react';
import { Button, CustomInput } from 'reactstrap';
import { Warning } from '../../assets/svg/warning';
import { useModal } from '../../context/modal/ModalComponent';

export const LinkModal = ({ link }) => {
    const { closeModal } = useModal();
    const [checked, setChecked] = useState(false);
    const handleProceed = () => {
        closeModal();
        if (checked) {
            localStorage.setItem('externalLinks', true);
        }
    };
    return (
        <div data-testid="link-modal">
            <Warning />
            <h2>External link warning</h2>
            <hr />
            <p>The link opens a new window and goes to an external website that is not part of Logically.</p>
            <p className="sentence-format"><strong>External link: </strong> {link}</p>
            <CustomInput label="Don’t show this message again."
                type="checkbox"
                checked={checked}
                id="external-link-warning"
                onChange={(e) => setChecked(e.target.checked)}
            />
            <hr />
            <Button className="mr-2" onClick={closeModal}>Cancel</Button>
            <a href={link} target="_blank" onClick={handleProceed} rel="noreferrer">
                <Button color="primary">Proceed</Button>
            </a>
        </div>
    );
};
