import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
import { Dropdown } from '../../../../../components/Form/Dropdown';
import { PageFilters } from '../../PageFilters';
import { NarrativeListItem } from './NarrativeListItem';
import noNarratives from '../../../../../assets/images/no-narratives.png';
import { Loading } from '../../../../../components/Loading';
import { formatFilters } from '../../PageFilters/formatFilters';
import { SelectAllCheckBox } from '../../../../../components/Form/CheckBox';
import { StickToBottom } from '../../../../../components/StickToBottom';
import { RemoveContentModal } from '../../../../../components/CommonModals/RemoveContent';
import { TOGGLE_WATCHLIST } from './AddToWatchlist';
import { refetchWatchlist, getWatchlist, getRoom, refetchGetNarratives } from '../../../../../utils/variables';
import { GET_NARRATIVES } from '../../../../../graphql';
import { useUser } from '../../../../../context/user/UserContext';
import { Pagination } from '../../../../../components/Molecules';
import { initialState } from '../../../store';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';

export const narrativeSortOptions = [
    { key: 'date__desc', name: 'Original post date' },
    { key: 'volume__desc', name: 'Total mentions' },
    { key: 'average_threat_label__keyword__asc', name: 'Threat level' },
    { key: 'narrative_title__keyword__asc', name: 'Sort A to Z' },
    { key: 'narrative_title__keyword__desc', name: 'Sort Z to A' }
];

export const Narratives = () => {
    const { setModal } = useModal();
    const room = getRoom();
    const filters = useSelector((state) => state.narrativeFilters);
    const formattedFilters = formatFilters(filters?.filters);
    const location = useLocation();
    const { narrativePage } = location.state ? location.state : { page: 0 };
    const [isOpen, setOpen] = useState(false);
    const [page, setPage] = useState(parseInt(narrativePage, 10) || 0);
    const [compare, setCompare] = useState([]);
    const [sort, setSort] = useState(narrativeSortOptions[0]);
    const history = useHistory();
    const refetchWatchlistStatus = refetchWatchlist();
    const watchlist = useReactiveVar(getWatchlist);
    const { notAllowed } = useUser();
    const { data: nar, loading, refetch: refetchNarratives } = useQuery(GET_NARRATIVES, {
        variables: {
            limit: 10,
            sort: sort.key,
            skip: page,
            query: {
                bool: {
                    filter: [
                        {
                            match_phrase: {
                                project_id: {
                                    query: room.project_id,
                                },
                            },
                        },
                        ...(formattedFilters?.bool?.filters || [])
                    ],
                    must_not: [
                        {
                            match_phrase: {
                                status: {
                                    query: 'delete'
                                }
                            }
                        }
                    ]
                },
            },
        },
        fetchPolicy: 'no-cache'
    });
    refetchGetNarratives(refetchNarratives);

    const narrativesToAdd = compare.filter((a) => !watchlist.some((b) => b === a.id));
    const narrativesToRemove = compare.filter((a) => watchlist.some((b) => b === a.id));
    const watchlistButtonText = narrativesToAdd.length !== 0 ? 'Add to watchlist' : 'Remove from watchlist';

    const handleRemoveNarrativeError = () => {
        setModal({
            header: 'Failed to remove content',
            component: <p>Failed to remove content, please try again later</p>
        });
    };
    const setRemoveContentModal = () => {
        const removeData = compare.map(({ id }) => ({ id }));
        setModal({
            modal: <RemoveContentModal removeData={removeData}
                type={CONTENT_TYPE.NARRATIVE}
                onCompleted={() => { setCompare([]); refetchNarratives(); }}
                onError={handleRemoveNarrativeError}
            />
        });
    };

    const [toggleWatchlist, { loading: toggleWatchlistLoading }] = useMutation(
        TOGGLE_WATCHLIST,
        {
            onCompleted: () => {
                refetchWatchlistStatus();
                setCompare([]);
            },
        }
    );

    const data = nar?.newNarrativeServiceSearch?.hits.map((a) => ({
        id: a._id,
        ...a._source,
        volume_over_time: parseInt(a._source.volume_over_time?.split(':')[1] || '0', 10),
        average_threat_label_over_time: a._source.average_threat_label_over_time?.split(':')[1],
    })) || [];
    const ids = data && data;

    const handleWatchlist = () => {
        if (narrativesToAdd.length !== 0) {
            const narrative = narrativesToAdd.map((a) => a.id);
            if (narrative.length > 0) {
                toggleWatchlist({
                    variables: {
                        situationRoom: room.id,
                        narrative,
                    },
                });
            }
            else {
                setCompare([]);
            }
        } else {
            const narrative = narrativesToRemove.map((a) => a.id);
            if (narrative.length > 0) {
                toggleWatchlist({
                    variables: {
                        situationRoom: room.id,
                        narrative,
                    },
                });
            } else {
                setCompare([]);
            }
        }
    };

    if (loading) {
        return <Loading gif relative height={300} />;
    }

    if (!loading && data.length === 0 && filters === initialState) {
        return (
            <div className="px-4 py-5 bg-white rounded">
                <div className="py-3" />
                <div className="minh-200 d-flex align-items-center justify-content-center">
                    <div className="py-5">
                        <img src={noNarratives} alt="" className="d-block mx-auto" />
                        <h3 className="mt-3 text-center">There are no narratives created yet!</h3>
                    </div>
                </div>
                <div className="py-3" />
            </div>
        );
    }

    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;

    return (
        <>
            <div className="d-flex align-items-center flex-wrap pt-2">
                <Dropdown label="Sort by"
                    options={narrativeSortOptions}
                    value={sort}
                    onChange={setSort}
                    className="d-flex align-items-center mr-2 mb-2"
                />
                <Button onClick={() => setOpen(!isOpen)} className={filters?.filters ? 'btn-has-filters mb-2' : 'mb-2'}>
                    <span className="pr-3">
                        Page Filters
                    </span>
                    <FeatherIcon.ChevronDown size={16}
                        className={isOpen ? 'transform-btn-icon ml-11 filter-btn-arrow'
                            : 'transform-none ml-11 filter-btn-arrow'}
                    />
                </Button>
            </div>
            <PageFilters isOpen={isOpen} setOpen={setOpen} />
            <div className="position-relative">
                {(!loading && data.length > 0) ? (
                    <Table className="mt-2 mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <div className="d-flex align-items-center" data-testid="select-all-narratives">
                                        {!notAllowed.includes('editData') && (
                                            <SelectAllCheckBox id="allThreatsContent"
                                                displayLabel={false}
                                                options={ids}
                                                values={compare}
                                                allCheck={(dataB) => (setCompare(dataB))}
                                                page="content-table"
                                            />
                                        )}
                                        <p className="pl-2 m-0">Narratives {nar ? `(${nar.newNarrativeServiceSearch.count})` : ''}</p>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody data-testid="narrative-list-items">
                            {data.map((narrative) => (
                                <tr key={narrative?.id}>
                                    <td>
                                        <NarrativeListItem narrative={narrative}
                                            compare={compare}
                                            onThreatListItemCheckChange={(checked) => {
                                                if (!checked) {
                                                    setCompare(compare?.filter((a) => a.id !== narrative.id));
                                                } else {
                                                    setCompare([...compare, narrative]);
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )
                    : (
                        <div className="px-4 py-5 bg-white rounded">
                            <div className="py-3" />
                            <div className="minh-200 d-flex align-items-center justify-content-center">
                                <div className="py-5">
                                    <img src={noNarratives} alt="" className="d-block mx-auto" />
                                    <p className="mt-3 text-center">
                                        <strong>There are no narratives created yet!</strong>
                                    </p>
                                </div>
                            </div>
                            <div className="py-3" />
                        </div>
                    )}
                {nar && !loading && (
                    compare.length > 0 ? (
                        <StickToBottom>
                            <div className="bg-hover border-top p-2 d-flex align-items-center justify-content-between">
                                <p className="m-0 font-weight-bold">
                                    {compare.length} item
                                    {compare.length !== 1 ? 's' : ''} selected
                                </p>
                                <div className="d-flex align-items-end">
                                    {compare.length > 1 && compare.length < 5 && (
                                        <Button className="mr-1"
                                            color="primary"
                                            onClick={() =>
                                                history.push(`${window.location.pathname}/compare`, compare)}
                                        >Compare
                                        </Button>
                                    )}
                                    {((narrativesToAdd?.length === 0 || narrativesToRemove?.length === 0) && watchlistEnabled) && (
                                        <Button className="mr-1"
                                            color={narrativesToAdd?.length !== 0 ? 'primary' : 'danger'}
                                            onClick={handleWatchlist}
                                        >{toggleWatchlistLoading ? <Loading relative /> : watchlistButtonText }
                                        </Button>
                                    )}
                                    {contentActionsEnabled && (
                                        <Button color="danger" onClick={setRemoveContentModal}>
                                            Remove narrative{compare.length > 1 && 's'}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </StickToBottom>
                    ) : nar?.newNarrativeServiceSearch?.count > 10 && (
                        <div className="border-top bg-white">
                            <Pagination total={nar.newNarrativeServiceSearch.count || 0}
                                itemsPerPage={10}
                                offset={page}
                                setOffset={setPage}
                                className="py-2"
                            />
                        </div>
                    )
                )}
            </div>
        </>
    );
};
