import React from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/
type ToggleSwitchProps = {
    id: string;
    name?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    optionLabels?: string[];
    small?: boolean;
    disabled?: boolean;
}

export const ToggleSwitch = ({ id, name, checked, onChange, optionLabels = ['Yes', 'No'], small, disabled }: ToggleSwitchProps) => {
    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.keyCode !== 32) return;
        e.preventDefault();
        onChange(!checked);
    };

    return (
        <div className={`toggle-switch${small ? ' small-switch' : ''}`} data-testid="toggle-switch">
            <input type="checkbox"
                name={name}
                className="toggle-switch-checkbox"
                id={id}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
                data-testid="toggle-switch-checkbox"
            />
            {id ? (
                <label className="toggle-switch-label"
                    htmlFor={id}
                    tabIndex={disabled ? -1 : 1}
                    onKeyDown={(e) => {
                        handleKeyPress(e);
                    }}
                    data-testid="toggle-switch-label"
                >
                    <span className={disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'}
                        data-yes={optionLabels?.[0] ?? ''}
                        data-no={optionLabels[1]}
                        tabIndex={-1}
                    />
                    <span className={disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'}
                        tabIndex={-1}
                        data-testid="toggle-switch-switch"
                    />
                </label>
            ) : null}
        </div>
    );
};

ToggleSwitch.defaultProps = {
    optionLabels: ['Yes', 'No'],
};
