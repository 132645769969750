import React, { useState } from 'react';
import { AlertCircle } from 'react-feather';
import {
    Alert,
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { RequestInvestigation } from '../../../../components/RequestInvestigation';
import { NotThreatIdentifierModal } from '../../../../components/CommonModals/NotThreatIdentifier';
import { RemoveContentModal } from '../../../../components/CommonModals/RemoveContent';
import { FactCheck } from '../../../../components/FactCheck';
import { ThreatIdentifier } from '../../../../components/CommonModals/ThreatIdentifier';
import { WatchlistButtonContainer } from './WatchlistButtonContainer';
import { useDropDown } from '../../../../utils/useDropdown';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom } from '../../../../utils/variables';
import { useModal } from '../../../../context/modal/ModalComponent';
import { WATCHLIST_BUTTON_TYPES } from './WatchlistButton';
import { useGetWatchlist } from '../../../../services/Threat/getWatchlist';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

export const ActionsRow = ({ content, refetch }) => {
    const room = getRoom();
    const history = useHistory();
    const [dropdown, toggleDropdown] = useDropDown();
    const { user, notAllowed } = useUser();
    const [factCheckModal, setFactCheckModal] = useState(false);
    const { setModal, closeModal } = useModal();

    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }) => id === content.id).length > 0 : false;
    const setRemoveContentModal = () => {
        const type = content?.threatObject?.threatLevel !== 'none' ? CONTENT_TYPE.THREAT : CONTENT_TYPE.MATCH;
        const removeData = [{ id: content.id }];
        setModal({
            modal: <RemoveContentModal removeData={removeData}
                type={type}
                onCompleted={handleRemoveContentCompleted}
            />
        });
    };
    const setNotThreatIdentifierModal = () => {
        setModal({
            modal: <NotThreatIdentifierModal ids={[content.id]} onCompleted={refetch} />
        });
    };

    const setRequestInvestigationModal = () => setModal({
        modal: <RequestInvestigation defaultDetails={content} onClose={closeModal} />,
        goToPreviousOnClose: true
    });
    const setMarkAsThreatModal = () => setModal({
        modal: <ThreatIdentifier onClose={closeModal}
            threatId={content.id}
            onCompleted={refetch}
        />
    });
    const handleRemoveContentCompleted = () => {
        history.push(`/situation-rooms/${room?.id}/${content?.threatObject?.threatLevel !== 'none' ? 'threats' : 'overview'}`);
    };

    if (notAllowed.includes('editData')) return null;

    const instancePlan = room.instance?.plan;

    return (
        <Row className="mt-2">
            <Col className="pl-1 d-flex text-align-center">
                {content?.threatObject?.threatLevel !== 'none' ? (
                    <>
                        <WatchlistButtonContainer contents={[content]}
                            isWatching={isWatching}
                            onCompleted={refetchWatchlist}
                            onError={() => {}}
                            type={WATCHLIST_BUTTON_TYPES.BUTTON}
                            className="m-1"
                        />
                        {instancePlan?.integrations?.investigations && (
                            <Button className="m-1" onClick={setRequestInvestigationModal}>
                                Request investigation
                            </Button>
                        )}
                        {instancePlan?.core?.logicallyFactChecks && (
                            <Button onClick={() => setFactCheckModal(true)} className="m-1">
                                Request fact check
                            </Button>
                        )}
                    </>
                ) : (
                    <Alert color="primary" className="d-inline ml-1 w-100">
                        <AlertCircle className="text-primary mr-1" />
                        Our Analysis hasn&apos;t flagged this content as a threat
                    </Alert>
                )}
                {instancePlan?.others?.contentActions && (
                    <Dropdown onClick={(e) => { e.preventDefault(); }}
                        className={`d-inline ${content?.threatObject?.threatLevel !== 'none' ? 'mt-1' : 'ml-1'}`}
                        isOpen={dropdown}
                        toggle={toggleDropdown}
                        direction={window.innerWidth < 540 ? 'left' : 'right'}
                        style={{ minWidth: '0' }}
                        data-testid="threat-page-actions"
                    >
                        <DropdownToggle className="bg-transparent border-0 mw-0">
                            <span><i className="fa fa-ellipsis-v text-secondary" /></span>
                        </DropdownToggle>
                        <DropdownMenu>
                            {content?.threatObject?.threatLevel === 'none' && (
                                <DropdownItem onClick={setMarkAsThreatModal}>
                                    <span>Mark as threat</span>
                                </DropdownItem>
                            )}
                            {content?.threatObject?.threatLevel !== 'none' && (
                                <DropdownItem onClick={setNotThreatIdentifierModal}>
                                    <span>Mark as not threat</span>
                                </DropdownItem>
                            )}
                            <DropdownItem onClick={setRemoveContentModal} data-testid="threat-action-remove-content">
                                Remove content
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                )}
                {instancePlan?.core?.logicallyFactChecks && (
                    <FactCheck roomId={room.id}
                        open={factCheckModal}
                        setOpen={() => setFactCheckModal(false)}
                        data={content}
                        projectId={room.project_id}
                        user={user}
                    />
                )}
            </Col>
        </Row>
    );
};
