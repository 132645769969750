import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { debounce, isEqual, pick } from 'lodash';
import {
    DateRange,
    NetworksHistoryState,
    NetworksHistoryStore,
    incrementChartKey,
    networksHistoryInitialState,
    resetNetworks,
    setRegraphLoading,
    settingsFields,
    updateNetworks
} from '../../store';
import { NetworksTimelineRef } from '../Timeline';

type UseNetworkHistoryProps = {
    timelineRef: React.RefObject<NetworksTimelineRef>
}

export const useNetworkHistory = ({ timelineRef }: UseNetworkHistoryProps) => {
    const { past, present, future } = useSelector((state: NetworksHistoryStore) => state.networksHistory);
    const dispatch = useDispatch();

    const triggerLoadingState = (action: () => void) => {
        dispatch(setRegraphLoading(true));
        debounce(action, 300)();
        // In case chart updates before we trigger loading state
        debounce(() => dispatch(setRegraphLoading(false)), 4000)();
    };

    const performAction = (nextState: NetworksHistoryState, action: () => void) => {
        const { updateDateRange } = timelineRef.current || {};
        if (!updateDateRange) return;

        if (
            isEqual(present.filters, nextState.filters)
            && isEqual(present.deletedNodes, nextState.deletedNodes)
            && isEqual(pick(present, settingsFields), pick(nextState, settingsFields))
        ) {
            action();
        } else {
            triggerLoadingState(() => action());
        }
        const dateFilter = nextState.filters.date;
        updateDateRange({
            startDate: dateFilter?.startDate ? new Date(dateFilter.startDate) : null,
            endDate: dateFilter?.endDate ? new Date(dateFilter.endDate) : null
        } as DateRange);
    };

    return {
        triggerLoadingState,
        undo: () => {
            if (!past.length) return null;
            performAction(past[past.length - 1], () => dispatch(ActionCreators.undo()));
        },
        redo: () => {
            if (!future.length) return null;
            performAction(future[0], () => dispatch(ActionCreators.redo()));
        },
        reset: (nextState?: NetworksHistoryState) => {
            performAction(nextState || networksHistoryInitialState, () => {
                dispatch(incrementChartKey());
                dispatch(resetNetworks());
                dispatch(ActionCreators.clearHistory());
                if (nextState) {
                    dispatch(updateNetworks(nextState));
                }
            });
        }
    };
};
