import { gql } from '@apollo/client';
import { queryFragments, situationRoomFragments, situationRoomsFragments } from './Fragments';

/*
-------------------------------
Queries
-------------------------------
*/

// Current User
export const GET_USER = gql`
    query user($uid: String!, $truncateName: Int) {
        user(uid: $uid) {
            id
            uid
            displayName(truncate: $truncateName)
            firstName
            lastName
            email
            photoURL
            role
            instance {
                id
                clientName
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        explore
                        logicallyFactChecks
                        narratives
                    }
                    integrations {
                        investigations
                        identifiedActorEnrichments
                        crowdtangleCSV
                        reports
                        watchlist
                    }
                    others {
                        contentActions
                        dateInLocalTimeZone
                        newContentTableView
                    }
                }
            }
            status
            lastSeen
            createdAt
            bio
            situationRoomViewList{
                situationRoomid
                lastViewed
            }
            jobDescription
            preferences {
                cookies
                marketingEmails
                productEmails
                darkmode
            }
            welcomeMessage
        }
    }
`;

// Current Instance
export const GET_INSTANCE = gql`
    query instance($id: ID!) {
        instance(where: { id: $id }) {
            id
            clientName
            account {
                contactName
                role
                email
                phoneNumber
                location
                accountManager
                notes
                contractLink
            }
            tier
            plan {
                isArchived
                isTrial
                restrictedTeamRole
                validFrom
                validUntil
                tokens
                requestedTokens
                tokensRequestedBy {
                    email
                    displayName
                }
                userLimit
                situationroomLimit
                core {
                    overview
                    threats
                    explore
                    logicallyFactChecks
                    narratives
                    aiClustering
                    networks
                }
                integrations {
                    investigations
                    identifiedActorEnrichments
                    crowdtangleCSV
                    reports
                    watchlist
                }
                others {
                    contentActions
                    dateInLocalTimeZone
                    newContentTableView
                }
                sources {
                    faceBook
                    twitter
                    newsArticles
                    forums
                }
            }
        }
    }
`;

// Current Situation Room Overview
export const GET_ROOM_OVERVIEW = gql`
    query situationRoom($id: ID) {
        situationRoom(id: $id) {
            ...SituationRoomMetadata
            instance {
                id
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        explore
                        logicallyFactChecks
                        narratives
                    }
                    integrations {
                        investigations
                        identifiedActorEnrichments
                        crowdtangleCSV
                        reports
                        watchlist
                    }
                    others {
                        contentActions
                        dateInLocalTimeZone
                        newContentTableView
                    }
                }
            }
            createdBy {
                ...SituationRoomCreatedBy
            }
            updatedBy {
                displayName
            }
            users {
                ...SituationRoomUser
            }
            query {
                ...FullQuery
            }
            draftId
        }
    }

    ${situationRoomFragments}
    ${queryFragments}
`;

// Get Situation room draft by id
export const GET_ROOM_DRAFT = gql`
    query situationRoomDraft($id: ID) {
        situationRoomDraft(id: $id) {
            ...SituationRoomMetadata
            instance {
                id
            }
            createdBy {
                ...SituationRoomCreatedBy
            }
            users {
                ...SituationRoomUser
            }
            query {
                ...FullQuery
            }
            situationRoomId
        }
    }
    ${situationRoomFragments}
    ${queryFragments}
`;

// Get Situation rooms by Instance
export const GET_SITUATION_ROOMS = gql`
    query situationRooms(
        $instance: ID!,
        $user: ID,
        $status: Statuses,
        $limit: Int,
        $skip: Int,
        $sort: SortInput,
        $id: [ID],
        $searchValue: String
    ) {
        situationRooms(
            instance: $instance,
            user: $user,
            status: $status,
            limit: $limit,
            skip: $skip,
            sort: $sort,
            id: $id,
            searchValue: $searchValue
        ) {
            total
            data {
                ...SituationRoomsMetadata
                investigationsCount
                createdBy {
                    ...SituationRoomsCreatedBy
                }
                draftId
            }
        }
    }
    ${situationRoomsFragments}
`;

export const GET_SITUATION_ROOMS_NAMES = gql`
    query getSituationRoomNames($instance: ID!, $limit: Int) {
        situationRooms(instance: $instance, limit: $limit) {
            data {
                project_name
                deleted
            }
        }
    }
`;
// Get Situation room drafts by Instance
export const GET_SITUATION_ROOM_DRAFTS = gql`
    query situationRoomDrafts($instance: ID!, $user: ID) {
        situationRoomDrafts(instance: $instance, user: $user) {
            ...SituationRoomsMetadata
            investigationsCount
            createdBy {
                ...SituationRoomsCreatedBy
            }
            actuallyCreatedBy {
                ...SituationRoomsCreatedBy
            }
            situationRoomId
        }
    }
    ${situationRoomsFragments}
`;

export const GET_TEAM = gql`
    query users($instance: ID!, $truncateName: Int) {
        users(instance: $instance) {
            id
            uid
            displayName(truncate: $truncateName)
            firstName
            lastName
            email
            photoURL
            role
            lastSeen
            status
        }
    }
`;

export const GET_ROOM_TEAM = gql`
    query getRoomTeam($instance: ID!, $situationRoom: ID!) {
        situationRoom(id: $situationRoom) {
            removedUsers
        }
        users(instance: $instance) {
            id
            uid
            displayName
            firstName
            lastName
            email
            photoURL
            role
        }
    }
`;

export const GET_ADMIN_USERS = gql`
    query adminUsers($where: UserWhereInput) {
        adminUsers(where: $where) {
            id
            uid
            displayName(truncate: 20)
            firstName
            lastName
            email
            photoURL
            role
        }
    }
`;
export const GET_MULTIPLE_TRANSLATION = gql`
    query translateMultipleText($data: [TranslateInput]) {
        translateMultipleText(data: $data) {
            translations
        }
    }
`;

export const GET_NARRATIVES = gql`
    query newNarrativeServiceSearch($query: NewNarrativesESQuery, $sort: [NewNarrativesESSortEnum], $limit: Int, $skip: Int) {
        newNarrativeServiceSearch(query: $query, sort: $sort, limit: $limit, skip: $skip) {
            count
            hits {
                _id
                _source {
                    narrative_title
                    volume
                    volume_over_time
                    engagement
                    average_threat_label
                    average_threat_label_over_time
                    original_post
                    original_post_ctype
                    content_ids
                    lang_verbose
                    boosters
                    narrative_locations {
                        location
                        count
                    }
                    top_keyphrases
                    keyphrase_table {
                        terms
                        matches
                        change
                    }
                    keyphrases_over_time
                    status
                }
            }
        }
    }
`;
export const GET_TOPIC_MODELLING_DATA = gql`
    query topicModelling($query: TopicClustersQuery, $limit: Int, $skip: Int) {
        topicModelling(query: $query, limit: $limit, skip: $skip) {
            count
            hits {
                _id
                _source {
                    cluster_name
                    cluster_id
                    bigrams {
                        text
                        trending_score
                        value
                    }
                    trigrams  {
                        text
                        trending_score
                        value
                    }
                    all_keywords  {
                        text
                        trending_score
                        value
                    }
                    cooccurance_words  {
                        text
                        value
                        x
                        y
                    }
                    all_entities  {
                        text
                        trending_score
                        value
                    }
                    cluster_size
                    cluster_loc {
                        x
                        y
                        z
                    }
                    sub_cluster_info {
                        cluster_id
                        sub_cluster_name
                        cluster_size
                        bigrams {
                            text
                            trending_score
                            value
                        }
                        trigrams  {
                            text
                            trending_score
                            value
                        }
                        all_keywords  {
                            text
                            trending_score
                            value
                        }
                        cooccurance_words  {
                            text
                            value
                            x
                            y
                        }
                        all_entities  {
                            text
                            trending_score
                            value
                        }
                        cluster_loc {
                            x
                            y
                            z
                        }
                        documents
                    }
                }
            }
        }
    }
`;

export const GET_AGGS = gql`
    query contentServiceSearch(
        $query: ContentESQuery
        $sort: JSON
        $limit: Int
        $aggs: [ContentESAggBlock]
        $opts: ContentESContentESOpts
    ) {
        contentServiceSearch(
            query: $query
            sort: $sort
            limit: $limit
            aggs: $aggs
            opts: $opts
        ) {
            count
            aggregations
        }
    }
`;

export const CHECK_USER_EXISTS = gql`
    query checkUserExists($email: String!){
        checkUserExists(email: $email){
            done
            message
        }
    }
`;

export const GET_ALL_USERS = gql`
    query allUsers($limit: Int, $skip: Int, $searchValue: String, $instance: [String], $sort: SortInput) {
        allUsers(limit: $limit, skip: $skip, searchValue: $searchValue, instance: $instance, sort: $sort) {
            total
            data {
                id
                uid
                displayName
                firstName
                lastName
                email
                photoURL
                role
                status
                lastSeen
                instance {
                    id
                    clientName
                }
            }
        }
    }
`;

export const GET_WATCHLIST = gql`
    query getSituationRoomThreatsWatchlist($situationRoom: ID!) {
        getSituationRoomThreatsWatchlist(situationRoom: $situationRoom) {
            content_id
            text
            from
            ctype
            engagement
            display_name
            threat_labels
            locations_detected
            pubdatetime
            url
            whyathreat
            is_toxic
            extracts
            sentiment
            threat_level
            lang
            detected_langs
            lang_verbose
        }
    }
`;

export const GET_INVESTIGATIONS = gql`
    query investigations($situationRoom: ID!) {
        investigations(situationRoom: $situationRoom) {
            id
            createdAt
            updatedAt
            status
            summary
            details
            source
            email
            report {
                id
                filename
                url
            }
            contactEmails
            uploadAttachments
        }
    }
`;

export const GET_ALL_INVESTIGATIONS = gql`
    query allInvestigations($id: ID) {
        allInvestigations(id: $id) {
            id
            createdAt
            updatedAt
            status
            summary
            details
            source
            uploadAttachments
            email
            contactEmails
            note
            furtherInvestigation
            reportCreatedBy
            createdBy {
                uid
                email
                displayName
                firstName
                lastName
                role
            }
            situationRoom {
                id
                project_name
            }
            report {
                id
                filename
                url
            }
        }
    }
`;

export const GET_CUSTOM_NARRATIVES = gql`
    query getCustomNarratives($situationRoom: ID!, $id: ID) {
        getCustomNarratives(situationRoom: $situationRoom, id: $id) {
            id
            name
            narrativeTitle
            versionId
            notes
            data {
                id
                threat
                boolean
                keywords
                query_type
                dslQuery
                isSaved
            }
        }
    }
`;

export const GOOGLE_TRENDS_QUERY = gql`
    query googleTrendsQuery($keyword: String!) {
        googleTrendsQuery(keyword: $keyword) {
            query
            link
            value
        }
    }
`;
export const GOOGLE_TRENDS_TOPIC = gql`
    query googleTrendsTopic($keyword: String!) {
        googleTrendsTopic(keyword: $keyword) {
            topic {
                title
            }
        }
    }
`;

export const GET_ALL_SITUATION_ROOMS = gql`
    query allSituationRooms($limit: Int, $skip: Int, $filter: RoomFilter, $sort: SortInput) {
        allSituationRooms(limit: $limit, skip: $skip, filter: $filter, sort: $sort) {
            total
            data {
                ...SituationRoomsMetadata
                createdBy {
                    ...SituationRoomsCreatedBy
                }
                actuallyCreatedBy {
                    ...SituationRoomsCreatedBy
                }
                instance {
                    id
                    clientName
                }
                query {
                    ...FullQuery
                }
            }
        }
    }
    ${situationRoomsFragments}
    ${queryFragments}
`;

export const GET_CTYPES = gql`
    query getCtypes {
        getCtypes {
            id
            key
            name
            color
            FontFamily
            nodeColor
            icon
        }
    }
`;

export const GET_CTYPE = gql`
    query getCtype($id: ID!) {
        getCtype(id: $id) {
            id
            key
            name
            color
            FontFamily
            nodeColor
            icon
        }
    }
`;

export const GET_SUGGESTIONS = gql`
    query getSuggestions ($query: String){
        getSuggestions (query: $query) {
            data {
                original
                name
                url
                description
                type
                image
                score
              }
        }
    }
`;

export const GET_CLAIMS = gql`
    query getClaims($userId: String, $projectId: String) {
    getClaims(userId: $userId, projectId: $projectId) {
       entries {
         claim_details {
           claims_docs {
             _id
            fact_checked
            project_id
            body
            claim_source_url
            status
            claim_detection
            username
            first_name
            last_name
            rejected
            message
            published_date
            claim_source
            type
            claim_verified_ids
            conclusion
            verdict
            editor_reference_urls{
                source
                offensive_content
                access_date
                source_name
                published_date
                url
                }
            claim_ids
            conclusion_summary
            user_id
            lang_code
            source
            created_at
            title
            content
            moderated
            location
           }
         }
       }
    }
  }
`;

export const GET_CLAIM = gql`
    query getClaim($userId: String, $claimId: String, $projectId: String) {
    getClaim(userId: $userId, claimId: $claimId, projectId: $projectId) {
       entries {
         claim_details {
           claims_docs {
             _id
            fact_checked
            project_id
            body
            claim_source_url
            status
            claim_detection
            rejected
            username
            first_name
            last_name
            message
            published_date
            claim_source
            type
            claim_verified_ids
            claim_ids
            user_id
            lang_code
            source
            created_at
            title
            content
            moderated
            location
            conclusion
            verdict
            conclusion_summary
            editor_reference_urls{
            source
            offensive_content
            access_date
            source_name
            published_date
            url
            }
           }
         }
       }
    }
  }
`;

export const GET_EXTRACTED_CLAIMS = gql`
  query getExtractedClaims($id: String, $text: String, $url: String, $room_id: String) {
    getExtractedClaims(id: $id, text: $text, url: $url, room_id: $room_id) {
      id
      claims {
        text
        ce_score
        fcw_score
      }
    }
  }
`;

export const CONTENT_ES_SERVICE = gql`
    query contentServiceSearch(
        $q: String
        $query: ContentESQuery
        $sort: JSON
        $limit: Int
        $skip: Int
        $aggs: [ContentESAggBlock]
        $highlight: JSON
        $opts: ContentESContentESOpts
    ) {
        contentServiceSearch(
            q: $q
            query: $query
            sort: $sort
            limit: $limit
            skip: $skip
            aggs: $aggs
            highlight: $highlight
            opts: $opts
        ) {
            hits {
                _id
                highlight
                _source {
                    from
                    project_id
                    text
                    body
                    pubdatetime
                    display_name
                    date
                    locations_detected
                    ctype
                    lang
                    detected_langs
                    lang_verbose
                    likes_count
                    replies_count
                    retweets_count
                    shares_count
                    engagement
                    whyathreat
                    url
                    is_toxic
                    is_threat
                    sentiment
                    threat_level
                    added_date
                    threat_object {
                        threat_level
                        threat_labels
                        threat_resolvers {
                            type
                            resolver
                            confidence
                        }
                    }
                    datetime_tz
                    likes_count
                    replies_count
                    retweets_count
                    latlon_coords
                    entities
                    entity_sentiment
                    image
                    hashtags
                    thread_id
                    views_count
                    main_topic
                    demographics {
                        gender
                        age
                        org
                    }
                }
            }
            count
            aggregations
        }
    }
`;

export const EXPLORE_CONTENT_ES_SERVICE = gql`
    query contentServiceSearch(
        $q: String
        $query: ContentESQuery
        $sort: JSON
        $limit: Int
        $skip: Int
        $aggs: [ContentESAggBlock]
        $highlight: JSON
        $opts: ContentESContentESOpts
    ) {
        contentServiceSearch(
            q: $q
            query: $query
            sort: $sort
            limit: $limit
            skip: $skip
            aggs: $aggs
            highlight: $highlight
            opts: $opts
        ) {
            hits {
                _id
                highlight
                _source {
                    from
                    hashtags
                    pubdatetime
                    engagement
                    threat_level
                    threat_object {
                        threat_level
                    }
                    ctype
                    url
                    thread_id
                    sentiment
                    locations_detected
                    mentions
                }
            }
            count
            aggregations
        }
    }
`;

export const GET_PASSWORD_RESET = gql`
    query getPasswordReset($code: String!){
        getPasswordReset(code: $code){
            email
        }
    }
`;

export const GET_REMOVED_CONTENT = gql`
    query getRemovedContent($projectId: String, $searchString: String, $offset: Int, $type: String, $limit: Int){
        getRemovedContent(projectId: $projectId, searchString: $searchString, offset: $offset, type: $type, limit: $limit){
            data
            total
        }
    }
`;

export const GET_LOCATIONS = gql`
    query getLocations($threats: Boolean, $projectId: String!, $size: Int) {
        getLocations(threats: $threats, projectId: $projectId, size: $size)
    }
`;

export const GET_INSTANCES = gql`
    query instances {
        instances {
            id
            clientName
        }
    }
`;

export const GET_AUTH_NOT_MONGO = gql`
    query authUsersNotMongo {
        authUsersNotMongo {
            uid
            displayName
            email
            photoURL
        }
    }
`;

export const GET_INVESTIGATION_TOKENS = gql`
    query getInvestigationTokens($instance:ID){
        getInvestigationTokens(instance: $instance){
            id
            instance{
                id
                clientName
            }
            tokens
            totalTokens
            tokensRequested{
                id
                requestedBy{
                    id
                    email
                    displayName
                }
                tokensRequested
            }
        }
    }
`;
export const GET_CLUSTERS = gql`
    query getClusters($projectId: String!){
        getClusters(projectId: $projectId){
            id
            addedDate
            description
            duplicatedDocsCount
            keyphrases{
                keyword
                count
            }
            projectId
            sentimentJson{
                sentiment
                score
            }
            title
            topActors{
                actor
                contentIds
            }
            topSources{
                source
                contentIds
            }
            subClustersMappedId
            status
            volume
            volumeOverTime{
                time
                count
            }
        }
    }
`;

export const GET_SUB_CLUSTERS = gql`
    query getSubClusters($ids: [String]!, $projectId: String!){
        getSubClusters(ids: $ids, projectId: $projectId){
            id
            addedDate
            contentIds{
                id
                duplicateIds
            }
            duplicatedDocsCount
            keyphrases{
                keyword
                count
            }
            originatorPostCtype
            originatorPostId
            sentimentJson{
                sentiment
                score
            }
            subClusterSummary
            subClusterTitle
            status
            volume
            volumeOverTime{
                date
                count
            }
            topActors{
                actor
                contentIds
            }
            topSources{
                source
                contentIds
            }
        }
    }
`;
