import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as FeatherIcon from 'react-feather';
import { useHistory } from 'react-router-dom';
import { UPDATE_COMPARISON } from './ComparisonQueries';
import { NarrativeStats } from '../Overview/Narratives/NarrativeStats';
import { ComparisonDetails } from './ComparisonDetails';
import { getRoom } from '../../../../utils/variables';
import { calculateAverageThreatLabel } from './index';
import { TranslateIcon } from '../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../graphql';

export const ComparisonTimeLine = (props) => {
    const { isEdit, setCompareData, compareData, setVisibleSaveButton,
        comparisonName, comparisonMainData, setSaveModal, dateData, visibleSaveButton } = props;
    const room = getRoom();
    const history = useHistory();
    const colors = ['#4367F8', '#D945D0', '#00C0C7', '#FF6C4C'];
    const [shownDetails, setShownDetails] = useState({});
    const [showLineChart, setShowLineChart] = useState({});
    const [narrativeIdsForLineChart, setNarrativeIdsForLineChart] = useState([]);
    const narrativeIds = compareData?.map((x) => x.id);
    const threatLabels = compareData?.map((x) => x.average_threat_label);
    const averageThreatLabel = calculateAverageThreatLabel(threatLabels);
    const toggleNarrativeDetails = (id) => {
        setShownDetails((prevShownDetails) => ({
            ...prevShownDetails,
            [id]: !prevShownDetails[id],
        }));
    };
    const toggleShowLinechart = (id) => {
        setShowLineChart((prevShownDetails) => ({
            ...prevShownDetails,
            [id]: !prevShownDetails[id],
        }));
    };
    const removeNarrativeFromComparison = (id) => {
        setCompareData(compareData.filter(item => item.id !== id));
    };
    const [updateNarrativeComparisonInput] = useMutation(UPDATE_COMPARISON, {
        onCompleted: () => {
            setVisibleSaveButton(false);
        },
    });
    const onSaveEditsClick = () => {
        if (isEdit) {
            updateNarrativeComparisonInput({
                variables: {
                    data: {
                        situationRoom: room.id,
                        name: comparisonName,
                        createdAt: comparisonMainData?.createdAt,
                        narratives: narrativeIds,
                        averageThreatLevel: averageThreatLabel,
                    },
                    id: comparisonMainData?.id,
                },
            });
        } else {
            setSaveModal(true);
        }
    };
    let ids = [];
    useEffect(() => {
        for (const property in showLineChart) {
            if (showLineChart[property]) {
                ids.push(property);
            } else {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                ids = ids?.filter((x) => x !== property);
            }
        }
        setNarrativeIdsForLineChart(ids);
    }, [showLineChart]);

    return (
        <div className="p-3 bg-white">
            <ComparisonDetails data={compareData || []}
                colors={colors}
                comparisonMainData={comparisonMainData}
                hideNarrativeId={narrativeIdsForLineChart}
                dateRange={dateData}
                isEdit={isEdit}
            />
            <hr />
            {isEdit && (
                <>
                    <p>
                        You can see more metrics about each narrative by pressing on Show
                        metrics. You can hide a specific narrative from the chart by
                        pressing on the eye icon.
                    </p>
                    <hr />
                </>
            )}
            {compareData?.map((narrative, index) => (
                <div key={narrative.id}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            {isEdit && (
                                <span className="cursor-pointer"
                                    onClick={() => toggleShowLinechart(narrative.id)}
                                >
                                    {' '}
                                    {showLineChart[narrative.id] ? (
                                        <FeatherIcon.EyeOff color="#006FF9" className="pr-1" />
                                    ) : (
                                        <FeatherIcon.Eye className="pr-1" color="#006FF9" />
                                    )}
                                </span>
                            )}
                            <div>
                                <span className="small-circle mt-1"
                                    style={{ background: colors[index] }}
                                />
                            </div>
                            <h5 className="d-inline pl-2">
                                <TranslateNarrativeTitle title={narrative?.narrative_title} lang={narrative?.lang_verbose} />
                            </h5>
                            <span className="pl-1 cursor-pointer set-min-width"
                                onClick={() => toggleNarrativeDetails(narrative.id)}
                            >
                                {shownDetails[narrative.id]
                                    ? <span className="text-primary">Show metrics <FeatherIcon.ChevronDown /> </span>
                                    : <span className="text-primary">Hide metrics <FeatherIcon.ChevronUp /></span>}
                            </span>
                        </div>
                        <div className="d-flex ml-1 set-min-width">
                            <Button onClick={
                                () => history.push(`/situation-rooms/${room?.id}/narratives/${narrative?.id}/dashboard/`)
                            }
                            >
                                View dashboard
                            </Button>

                            {isEdit && compareData.length > 2 && (
                                <Button className="float-right ml-1"
                                    onClick={() => removeNarrativeFromComparison(narrative?.id)}
                                >
                                    Remove narrative
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className={shownDetails[narrative.id] ? 'd-none' : 'd-block'}>
                        <NarrativeStats narrative={narrative} />
                    </div>
                    {compareData.length - 1 === index ? null : <hr />}
                </div>
            ))}
            {(!isEdit || visibleSaveButton) && (
                <>
                    <hr className="mb-0" />
                    <div className="pt-2 pl-2 text-right">
                        <Button color="primary" onClick={() => onSaveEditsClick()}>
                            Save comparison
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

const TranslateNarrativeTitle = ({ title, lang }) => {
    const [isTranslated, setIsTranslated] = useState(false);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: title, target: 'en' }
        },
    });
    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : title;
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };
    return (
        <div>
            {isTranslated ? outputText : title}
            {lang === 'English' ? null : (
                <span role="button"
                    className="ml-2"
                    onClick={(e) => translation(e)}
                >
                    <TranslateIcon color="#006FF9" size={30} />
                </span>
            )}

        </div>
    ); };
