import React from 'react';
import { DataAdd } from '../../../../../assets/svg/DataAdd';
import { AddNewNarrativeButton } from './AddNewNarrativeButton';

export const EmptyCustomNarrativeState = () => (
    <div className=" px-4 py-5 bg-white rounded">
        <div className="py-3" />
        <div className=" d-flex align-items-center justify-content-center">
            <div className="py-5 d-flex flex-column align-items-center">
                <DataAdd />
                <h3 className="d-block mx-auto py-1">No custom narratives set.</h3>
                <AddNewNarrativeButton color="secondary" />
            </div>
        </div>
    </div>
);
