import React from 'react';
import { EmergingNarrative } from './EmergingNarrative';

const narratives = [
    {
        id: 1,
    },
    {
        id: 2,
    }
];
export const EmergingNarratives = () => (
    <>
        <section className="d-flex flex-column">
            <p>
                A narrative is formed when a significant amount of content related
                to the keywords in your situation room is generated, and multiple
                accounts start spreading the content. Existing emerging narratives
                are refreshed and updated weekly.  If you come across a specific
                narrative that you find important, you can begin tracking it.
                By tracking a narrative, it will be refreshed and new content added daily.
                See the Tracking Narratives tab to view all the narratives you are tracking. Learn more
            </p>
        </section>
        <section className="d-flex flex-column">
            {narratives.map((narrative) => (
                <EmergingNarrative id={narrative.id} key={narrative.id} />))}
        </section>
    </>

);
