import React from 'react';
import { Button } from 'reactstrap';
import { CheckCircle } from 'react-feather';
import { toast } from 'react-toastify';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useDeleteNetwork } from './useDeleteNetwork';
import { Loading } from '../../../../../components/Loading';

export const DeleteSavedNetworkGraph = ({ id }: { id: string }) => {
    const { openPreviousModal } = useModal();
    const { deleteNetwork, loading } = useDeleteNetwork();
    const handleDeleteNetwork = () => {
        deleteNetwork({
            id,
            onCompleted: () => {
                openPreviousModal();
                toast.success('Saved network graph deleted', {
                    icon: <CheckCircle className="text-success" />,
                    style: {
                        backgroundColor: '#E8F9F6'
                    }
                });
            }
        });
    };
    if (loading) {
        return <Loading relative height={120} />;
    }
    return (
        <div>
            <p>Are you sure to delete the saved network graph. This action will permanently delete the saved item.</p>
            <hr />
            <Button onClick={() => openPreviousModal()} className="mr-2">Cancel</Button>
            <Button color="danger" onClick={handleDeleteNetwork}>Delete</Button>
        </div>
    );
};
