import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { InviteUser } from '../../components/InviteUser';
import { useUser } from '../../context/user/UserContext';
import { useGetInstance } from '../../services/Instance/getInstance';

export const ActionItems = ({ users, handleSearchValue }) => {
    const { user } = useUser();
    const { setModal, closeModal } = useModal();
    const { instance: instanceData } = useGetInstance({ instanceId: user?.instance.id });
    const userLimit = instanceData?.instance?.plan?.userLimit || 0;

    const setInviteUserModal = () => {
        setModal({
            header: false,
            size: 'md',
            component: <InviteUser membersCanBeAdded={userLimit - users.length}
                toggle={closeModal}
                instance={user ? user.instance.id : 1}
            />,
        });
    };
    return (
        <Row className="my-4">
            <Col xs="auto" className="d-flex align-items-center">
                <h2 className="m-0">Team members</h2>
            </Col>
            <Col xs="auto" className="ml-auto w-25 pr-0">
                <Input onChange={(e) => handleSearchValue(e.target.value)}
                    placeholder="Search for members"
                    data-testid="search-team-members"
                />
            </Col>
            {user?.role === 'ADMIN' && (
                <Col xs="auto">
                    <Button color="primary" onClick={setInviteUserModal}>
                        Add new members
                    </Button>
                </Col>
            )}
        </Row>
    );
};
