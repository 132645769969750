import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Table, Button } from 'reactstrap';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Download, Edit, Trash2 } from 'react-feather';
import { partition } from 'lodash';
import { GET_ALL_REPORTS, DELETE_REPORT, UPDATE_REPORT } from './graphql';
import { Loading } from '../../../components/Loading';
import { useModal } from '../../../context/modal/ModalComponent';
import { useUser } from '../../../context/user/UserContext';
import { Tabs } from '../../../components/Tabs';
import { EmptyState } from '../../../components/EmptyState';
import { Images } from '../../../utils/modeSwitch';
import { resetReportAction } from '../../../redux/reports';
import { lodashSort } from '../../../utils/lib/tableSorting';
import { SortToggle } from '../../../components/Table/SortToggle';
import { getRoom } from '../../../utils/variables';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { TeamRoleRestricted } from '../../../components/Molecules/TeamRoleRestricted';

const newReportsDate = 1654081200000; // 01/06/2022

export const SavedReports = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const [selected, setSelected] = useState([]);
    const [sort, setSort] = useState({
        field: 'createdAt', order: 'desc'
    });
    const { data, loading } = useQuery(GET_ALL_REPORTS, {
        variables: { situationRoom: room.id },
        fetchPolicy: 'no-cache',
    });
    const [deleteReport, { loading: loadingB }] = useMutation(DELETE_REPORT, {
        onCompleted: () => setSelected([]),
        refetchQueries: [GET_ALL_REPORTS]
    });
    const [updateReport] = useMutation(UPDATE_REPORT);
    const reports = data ? data.getReports || [] : [];
    const [savedReports, draftReports] = partition(reports, (report) => !report.draft);

    const setDeleteReportModal = (report) => {
        setModal({
            header: false,
            component: (
                <>
                    <h2>Are you sure you want to delete this report?</h2>
                    <hr className="my-3" />
                    <Button color="primary" className="mr-2" onClick={closeModal}>Cancel</Button>
                    <Button color="danger" onClick={() => handleDelete(report)}>Delete</Button>
                </>
            ),
        });
    };
    const handleDelete = (report) => {
        closeModal();
        deleteReport({
            variables: {
                id: report,
            },
        });
    };
    const handleSeen = (id, seenBy, name) => {
        updateReport({
            variables: {
                data: {
                    id,
                    seenBy,
                    name
                },
            },
        });
    };
    const handleCreateNewReport = () => {
        dispatch(resetReportAction());
        history.push(`/situation-rooms/${room.id}/report/create-report`);
    };
    const getTable = (tableReports) => {
        if (loading) return null;
        if (!tableReports.length) {
            return (
                <EmptyState title="No reports here yet"
                    summary="You haven't created any reports yet. Create your first report using the data in your situation room."
                    icon={Images().savedReports}
                    action={{
                        name: 'Create new report',
                        onClick: handleCreateNewReport
                    }}
                />
            );
        }
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Report ({tableReports.length})</th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Created date" field="createdAt" />
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Owner" field="createdBy.displayName" />
                        </th>
                        <th>
                            <SortToggle sort={sort} setSort={setSort} name="Last edited" field="updatedAt" />
                        </th>
                        <th>
                            <div>
                                Actions
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {lodashSort(tableReports, sort).map((item) => (
                        <TableRow id={item.id}
                            item={item}
                            selected={selected}
                            setSelected={setDeleteReportModal}
                            room={room}
                            handleSeen={handleSeen}
                        />
                    ))}
                </tbody>
            </Table>
        );
    };
    return (
        <div className="page-padding">
            <BreadCrumbs items={[
                { title: 'Situation Rooms', href: '/situation-rooms' },
                { title: room.project_name, href: `/situation-rooms/${room.id}` },
                { title: 'Reports' }
            ]}
            />
            {(loading || loadingB) && <Loading gif height={500} /> }
            <h2 className="my-4">Reports</h2>
            <Button type="button"
                className="ml-4 float-right"
                color="primary"
                onClick={handleCreateNewReport}
            >
                Create new report
            </Button>
            <Tabs param="t"
                data={[{
                    id: '1',
                    name: 'Saved reports',
                    content: getTable(savedReports)
                },
                {
                    id: '2',
                    name: 'Drafts',
                    content: getTable(draftReports)
                }]}
            />
        </div>
    );
};

const getSeen = (seenBy, user1) => {
    const userIndex = seenBy?.findIndex((a) => a?.user?.id === user1?.id);
    return [...seenBy.map((item, index) => {
        if (index === userIndex) {
            return {
                user: item?.user?.id,
                date: new Date().toString(),
            };
        }
        return {
            user: item?.user?.id,
            date: item?.date,
        };
    }), ...(userIndex === -1 ? [{
        user: user1.id,
        date: new Date().toString(),
    }] : [])];
};

const TableRow = ({
    item,
    setSelected,
    room,
    handleSeen,
    id
}) => {
    const { user } = useUser();
    const displayDate = item?.updatedAt && item?.updatedBy;

    return (
        <tr key={id}>
            <td>
                {(item.url && item.createdAt < newReportsDate)
                    ? (
                        <a href={item.url} target="_blank" rel="noreferrer">
                            {item.name}
                        </a>
                    )
                    : (
                        <Link to={`/situation-rooms/${room.id}/report/create-report?id=${item.id}`}
                            onClick={() => handleSeen(item.id, getSeen(item.seenBy || [], user), item.name)}
                        >
                            {item.name}
                        </Link>
                    )}
            </td>
            <td>{`${moment(item?.createdAt).format('DD/MM/YYYY, @ hh:mm:ss')}`}</td>
            <td>
                <TeamRoleRestricted>
                    {item?.createdBy && (item?.createdBy?.displayName || item.createdBy?.email)}
                </TeamRoleRestricted>
            </td>
            <td>
                {displayDate && (
                    <>
                        {moment(item.updatedAt).format('DD/MM/YYYY @hh:mm:ss')}
                        <TeamRoleRestricted>
                            &nbsp;by {item.updatedBy?.displayName || item.updatedBy.email}
                        </TeamRoleRestricted>
                    </>
                )}
            </td>
            <td style={{ width: 80 }}>
                <div className="d-flex justify-content-around">
                    {!item.draft
                        ? (
                            <div className="d-flex">
                                {item.url && (
                                    <a href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => handleSeen(item.id, getSeen(item.seenBy || [], user), item.name)}
                                        className="mr-2"
                                    >
                                        <Download size="17" color="#006FF9" />
                                    </a>
                                )}
                                <div onClick={() => setSelected(item.id)} role="button">
                                    <Trash2 size="17" color="#006FF9" />
                                </div>
                            </div>
                        )
                        : (
                            <div className="d-flex">
                                {!item.createdAt < newReportsDate && (
                                    <Link to={`/situation-rooms/${room.id}/report/create-report?id=${item.id}`} className="mr-2">
                                        <Edit size="17" color="#006FF9" />
                                    </Link>
                                )}
                                <div onClick={() => setSelected(item.id)} role="button">
                                    <Trash2 size="17" color="#006FF9" />
                                </div>
                            </div>
                        )}
                </div>
            </td>
        </tr>
    );
};
