import React from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { BackButton } from '../../../../components/Atoms/BackButton';
import { RemoveSource } from '../SourceList/RemoveSource';
import { SourceBody } from './SourceBody';
import { GET_CTYPE } from '../../../../graphql';
import { Loading } from '../../../../components/Loading';
import { removeTypename } from '../../../../utils/removeAllTypename';

export const EditSource = () => {
    const match = useRouteMatch();
    const ctypeId = match.params.id;
    const { data: ctypeData, loading } = useQuery(GET_CTYPE, {
        variables: { id: ctypeId }
    });
    const ctypeName = ctypeData?.getCtype?.name;
    const bread = [
        { title: 'Admin home', href: '/admin' }, { title: 'Source mapping', href: '/admin/ctypes' }, { title: ctypeName }
    ];
    return (
        <div className="page-padding">
            {
                loading ? <Loading /> : (
                    <>
                        <BreadCrumbs items={bread} />
                        <BackButton pathName="/admin/ctypes" />
                        <div className="d-flex justify-content-between mb-4">
                            <h2 className="m-0">{ctypeData?.getCtype.name}</h2>
                            <RemoveSource ctypeId={ctypeData?.getCtype.id} isEditPage />
                        </div>
                        <SourceBody sourceData={removeTypename(ctypeData?.getCtype)} />
                    </>
                )
            }
        </div>
    );
};
