import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox } from '../../../../../../../components/Form/CheckBox';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import { addItemsToArrayAtPosition } from '../../../../../../../utils/lib/addItemsToArrayAtPosition';
import { createEditorState } from '../../createEditorState';
import { LocationMentions } from './LocationMentions';
import { Sentiment } from './Sentiment';
import { Sources } from './Sources';
import { TopLanguages } from './TopLanguages';
import { TopPublishers } from './TopPublishers';
import { TopSharedUrls } from './TopSharedUrls';
import { TotalThreatsAndMatches } from './TotalThreatsAndMatches';
import { WordcloudGenerator } from './WordcloudGenerator';
import { StickToBottom } from '../../../../../../../components/StickToBottom';
import { setReportAction } from '../../../../../../../redux/reports';

export const AddOverviewWidgetsModal = () => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const overviewBlocks = report.pages.filter(a => a.type === 'overview').map(a => a.blocks).flat();
    const [modalWidgetState, setWidgets] = useState(overviewBlocks);
    const { closeModal } = useModal();
    const handleSave = () => {
        const pages = getUpdatedReportPages(report, modalWidgetState);
        dispatch(setReportAction({
            ...report,
            pages
        }));
        closeModal();
    };
    return (
        <div>
            <div className="py-3 px-4">
                <h2>Overview widgets</h2>
                <p className="mb-3 mt-2">Select the widget you wish to add in the report</p>
                <Row className="align-items-stretch">
                    {allWidgetTypes.map((widget) => (
                        <Col key={widget.type} xs={12} md={4}>
                            <div className="pb-3 h-100">
                                <div className="border h-100">
                                    <div className="bg-light px-2 py-1 border-bottom">
                                        <CheckBox id={`overviewWidget-${widget.type}`}
                                            label="Select to add to report"
                                            name={widget.type}
                                            checked={Boolean(modalWidgetState.find(a => a.type === widget.type))}
                                            onChange={(e) => {
                                                const selected = e.target.checked;
                                                const blocks = selected
                                                    ? [...modalWidgetState, widget]
                                                    : modalWidgetState.filter(a => a.type !== widget.type);
                                                setWidgets(blocks);
                                            }}
                                        />
                                    </div>
                                    <div className="bg-light px-2 py-1 border-bottom font-weight-bold">
                                        {widget.title}
                                    </div>
                                    {widget.type === 'locationMentions' && <LocationMentions />}
                                    {widget.type === 'topPublishers' && <TopPublishers />}
                                    {widget.type === 'sources' && <Sources />}
                                    {widget.type === 'topLanguages' && <TopLanguages />}
                                    {widget.type === 'topSharedUrls' && <TopSharedUrls />}
                                    {widget.type === 'totalThreatsAndMatches' && <TotalThreatsAndMatches />}
                                    {widget.type === 'sentiment' && <Sentiment />}
                                    {widget.type === 'wordcloudGenerator' && <WordcloudGenerator />}
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <StickToBottom>
                <div className=" bg-hover py-2 px-4 border-top d-flex justify-content-end">
                    <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
                    <Button color="primary" onClick={handleSave}>Save</Button>
                </div>
            </StickToBottom>
        </div>
    );
};

export const getUpdatedReportPages = (report, widgetState) => {
    let hasOneOverviewPage = false;
    let pages = [...report.pages.map(page => {
        if (page.type === 'overview') {
            if (hasOneOverviewPage) {
                return false; // this will reset the overview pages
            }
            hasOneOverviewPage = true;
            return {
                ...page,
                blocks: []
            };
        }
        return page;
    }).filter(a => a)];
    for (const widget of widgetState) {
        let done = false;
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (page.type === 'overview' && page.blocks.length < 3) {
                pages[i].blocks.push(widget);
                done = true;
            }
        }
        if (!done) {
            const lastOverviewPageIndex = pages.map(a => a.type).lastIndexOf('overview') + 1;
            pages = addItemsToArrayAtPosition(pages, lastOverviewPageIndex, [{
                id: uuid(),
                type: 'overview',
                pageTitle: createEditorState({
                    blocks: [
                        {
                            text: `Overview ${pages.filter(a => a.type === 'overview').length}`
                        }
                    ],
                    entityMap: {}
                }),
                blocks: [widget]
            }]);
        }
    }
    return pages;
};

const allWidgetTypes = [
    {
        type: 'locationMentions',
        title: 'Location mentions'
    },
    {
        type: 'totalThreatsAndMatches',
        title: 'Total threats and matches'
    },
    {
        type: 'sentiment',
        title: 'Sentiment'
    },
    {
        type: 'topPublishers',
        title: 'Top publishers'
    },
    {
        type: 'wordcloudGenerator',
        title: 'Wordcloud generator'
    },
    {
        type: 'sources',
        title: 'Sources'
    },
    {
        type: 'topLanguages',
        title: 'Top languages'
    },
    {
        type: 'topSharedUrls',
        title: 'Top shared URLs'
    }
];
