import React from 'react';
import { PieChart } from '../../../../../components/Highcharts';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { config, TitleTooltip } from '../utils';
import { LoadingState } from './Loading';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { useSentiment } from '../../../../../services/Overview';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';

export const CurrentSentiment = ({ preview }) => {
    const { setModal } = useModal();
    const { data, loading } = useSentiment();

    if (loading) {
        return <LoadingState />;
    }
    if (!data.length) { return <EmptyState title="No data available" icon={Images().overview} />; }

    const total = data.reduce((acc, { count }) => acc + count, 0);

    const getContentModal = ({ sentiment }) => {
        const filter = { value: sentiment.toLowerCase(), type: 'sentiment__keyword' };

        if (preview) {
            return <ContentPreviewModal name={sentiment} filter={filter} contentType={CONTENT_TYPE.THREAT} preview={preview} />;
        }

        return <ContentModal name={sentiment} filter={filter} contentType={CONTENT_TYPE.THREAT} />;
    };

    return (
        <div className="h-100 d-flex flex-column">
            <TitleTooltip title="Current Sentiment" type="sentiment__keyword" />
            <PieChart data={data.map(({ count }) => count)}
                labels={data.map(item => item.sentiment)}
                colors={data.map((item, i) => config.getColor(item.sentiment.toLowerCase(), i))}
                legendEnabled={false}
                height="213px"
                innerSize="80%"
            />
            <div>
                {data.map(({ sentiment, count }, i) => {
                    const percentage = (100 * count) / total;
                    return (
                        <div className="d-flex align-items-center" key={sentiment}>
                            <div style={{
                                height: 11, width: 11, borderRadius: 2, backgroundColor: config.getColor(sentiment.toLowerCase(), i),
                            }}
                            />
                            <p className="mb-0 pl-1 text-primary"
                                role="button"
                                onClick={() => setModal({
                                    header: sentiment,
                                    size: 'xl',
                                    component: getContentModal({ sentiment })
                                })}
                            >
                                {sentiment} ({Math.round(percentage)}%)
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
