import React from 'react';
import { Col } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { TooltipItem } from '../../ToolTip';

const isProduction = window.REACT_APP_ENV === 'production';
export const FROM_TAG_SOURCES = isProduction
    ? ['twitter', 'facebook', 'youtube', 'tiktok']
    : ['twitter', 'facebook', 'youtube', 'sharechat', 'instagram', 'tiktok'];
export const MENTIONS_TAG_SOURCES = ['twitter'];
export const CASE_SENSITIVE_TAGS = ['engagingWith', 'engagementType', 'engagingWithGuid'];
export const tagsWithEnforcedEmptySpaces = ['url', 'links', 'domain'];

const ERROR_MESSAGES = {
    accountAtSymbolValidator: 'The account username must start with an @ symbol',
    andOrValidator: 'The AND and OR operators cannot be mixed in the same sub-query.',
    angularBracketsValidator: 'You have used angle brackets \'<>\'. The Boolean only supports parentheses \'( )\'',
    balancedParenthesesValidator: 'Please ensure proper closure/opening of parentheses.',
    consecutiveOperatorsValidator: 'You have used two consecutive operators',
    domainValidator: 'You have entered an invalid domain. Please check the Boolean help for guidance.',
    emptyNearValidator: 'You have an empty NEAR operator',
    emptyParenthesesValidator: 'You have an empty set of parentheses.',
    emptySpaceValidator: `Tags: ${tagsWithEnforcedEmptySpaces.join(', ')} must be followed by empty space after colon`,
    fromTagValidator: `The from tag only supports the following sources: ${FROM_TAG_SOURCES.join(', ')}`,
    illegalEmptySpaceValidator: `Empty spaces are allowed only in tags: ${tagsWithEnforcedEmptySpaces.join(', ')}`,
    mentionsTagValidator: `The mentions tag only supports the following sources: ${MENTIONS_TAG_SOURCES.join(', ')}`,
    missingOperatorQuotesValidator: 'You are missing an operator or quotations',
    nearAndValidator: 'Operator NEAR is mixed with an AND operator.',
    nearOperatorValidator: 'Operator "NEAR" must follow the guidelines. ie. NEAR/5 or NEAR/5f',
    operatorAtEndValidator: 'You have an operator at the end of your query.',
    operatorCapitalisationValidator: 'You have used an invalid operator. Please check the capitalisation.',
    quotesValidator: 'You have an odd number of quotes in your query. You may have used too many or too few quotes.',
    squareBracketsValidator: 'You have used box brackets \'[ ]\'. The Boolean only supports parentheses \'( )\'',
    singleQuotesValidator: 'You have used single quotes instead of double quotes',
    tagCapitalisationValidator: 'You have used an invalid tag. Please check the capitalisation.',
    tagValidator: 'Your query contains tags/sources that are not allowed or empty tags.',
    dotsValidator: 'Dots are supported only in urls, domains, tags (ie. Twitter) and account names',
    emptyShorthandTagsValidator: 'You have empty short tag',
    wildcardAtStartValidator: 'You have used a wildcard character at the start of a word',
    hyphenWordWithQuotesValidator: 'Words with hyphens should be inside double quotes'
};

export const BooleanValidationErrors = ({ highlightsLength, validationErrors, highlightErrorFocus, onErrorClick, issues }) => {
    const getFinalErrors = () => {
        const errorEntries = Object.entries(validationErrors)
            .filter((entry) => entry[1].isError)
            .sort((a, b) => a[1].markers[0].startLineNumber - b[1].markers[0].startLineNumber
            || a[1].markers[0].startColumn - b[1].markers[0].startColumn);
        const finalErrors = Object.fromEntries(errorEntries);
        return finalErrors;
    };
    return (
        <Col lg={3} className={`boolean-search-explanation ${!highlightsLength && 'bg-light'} p-0`}>
            <div className="d-flex border-bottom rounded-top align-items-center bg-light p-3">
                <span className={`ml-1 ${issues && 'text-danger'}`}>{issues} issues</span>
                <TooltipItem id="booleanTrigger"
                    content="Click on the error message to highlight the issue in the query. You may need to scroll to see the issue."
                    Icon={FeatherIcon.Info}
                    placement="bottom"
                />
            </div>
            <div className={`${Object.keys(getFinalErrors()).find(error => getFinalErrors()[error].isError)
                ? 'boolean-search-error-panel h-100 w-100 pt-3' : ''}`}
            >
                {Object.keys(getFinalErrors()).map(error => getFinalErrors()[error].isError && (
                    <div color="danger"
                        className={`overflow-y-auto tag d-flex flex-column align-items-start 
                            ${highlightErrorFocus === error && 'error-focus'} mx-3 mb-1 cursor-pointer`}
                        key={error}
                        onClick={() => onErrorClick(error)}
                        data-testid="boolean-validation-error"
                    >
                        <div className="d-flex justify-content-center align-items-center mb-1 boolean-search-issues">
                            {getFinalErrors()[error].markers?.length}
                        </div>
                        {ERROR_MESSAGES[error]}
                    </div>
                ))}
            </div>
        </Col>
    );
};

export const checkForCyrillicChars = (query) => {
    const myRegex1 = /(?:^|\s)ОR(?=\s|$)/g;
    const myRegex2 = /(?:^|\s)NОT(?=\s|$)/g;

    if (myRegex1.test(query)) {
        return query.replace(myRegex1, ' OR');
    } if (myRegex2.test(query)) {
        return query.replace(myRegex2, ' NOT');
    }

    return query;
};
