import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { TooltipItem } from '../ToolTip';

export const ContactEmail = ({ email, setEmail }) => (
    <FormGroup className="mb-2">
        <Label className="mt-1">
            <strong className="pr-1">Contact Email</strong>
            <TooltipItem id="EmailTrigger"
                content="Please provide a contact email address that our investigations
                                         team can use to get in touch for any further information. "
                Icon={FeatherIcon.Info}
            />
        </Label>
        <InputGroup border={email ? 'active' : 'none'}>
            <Input value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                data-testid="contact-email-input"
            />
            {email && (
                <InputGroupAddon addonType="prepend">
                    <InputGroupText role="button"
                        onClick={() => { setEmail(''); }}
                        data-testid="email-x-icon"
                    >
                        <FeatherIcon.X color="#006FF9" size={16} />
                    </InputGroupText>
                </InputGroupAddon>
            )}
        </InputGroup>
    </FormGroup>
);
