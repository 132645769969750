import React, { useMemo, useContext, createContext } from 'react';

export const AdminUserContext = createContext({});

export const useAdminUser = () => {
    const context = useContext(AdminUserContext);
    if (context === undefined) {
        throw new Error('useUser be used within a UserContextProvider');
    }
    return context;
};

export const AdminUserContextProvider = ({ children, user = false }) => {
    const context = useMemo(() => ({
        user,
        instance: user?.instance?.id,
        situationRoomViewList: user?.situationRoomViewList || [],
        isAdmin: user?.role === 'ADMIN'
    }), [user]);

    return <AdminUserContext.Provider value={context}>{ children }</AdminUserContext.Provider>;
};
