import React from 'react';
import { X } from 'react-feather';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { ContentModal } from '../../../Overview/ContentModal/contentModal';

export const HashtagModal = ({ hashtag, contentType }) => {
    const { closeModal } = useModal();
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="m-0">{hashtag}</h2>
                <X onClick={closeModal} role="button" />
            </div>
            <hr />
            <ContentModal filter={{ type: 'hashtags__keyword', value: hashtag }} contentType={contentType} />
        </div>
    );
};
