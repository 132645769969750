import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { X } from 'react-feather';
import { capitalize } from 'lodash';
import { ProfileImage } from '../../../../../../components/Atoms';
import { Tabs } from '../../../../../../components/Tabs';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { SortToggle } from '../../../../../../components/Table/SortToggle';
import { lodashSort } from '../../../../../../utils/lib/tableSorting';
import { getCtype } from '../../../../../../utils/text';
import { PersonPagination } from './PersonPagination';

export const PersonModal = ({ people, activeIndex }) => {
    const [currentIndex, setIndex] = useState(activeIndex);
    const { openPreviousModal } = useModal();
    const person = people[currentIndex];
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="m-0">Possible persons ({currentIndex + 1}/{people.length}) - Account details</h2>
                <X onClick={() => openPreviousModal()} role="button" />
            </div>
            <hr />
            <PersonPagination index={currentIndex} setIndex={setIndex} totalLength={people.length} name={person.name} />
            <div className="d-flex">
                <ProfileImage photoURL={person.image} className="mr-3" size={64} key={currentIndex} />
                <div>
                    <p className="mb-0"><strong>Gender:</strong> {capitalize(person.gender)}</p>
                    <p className="mb-0">
                        <strong>Languages:</strong> <span className="text-capitalise">{person.languages.join(', ')}</span>
                    </p>
                    <p className="mb-0"><strong>DOB:</strong> {person.dob}</p>
                </div>
            </div>
            <hr />
            <Tabs small
                className="mb-2"
                data={[
                    {
                        name: 'Emails',
                        id: 'emails',
                        content: <ListItems items={person.emails}
                            columns={[{ name: 'Email', field: 'value' }, { name: 'Type', field: 'type', sortable: true }]}
                        />
                    },
                    {
                        name: 'Phones',
                        id: 'phones',
                        content: <ListItems items={person.phones}
                            columns={[{ name: 'Phone', field: 'value' }, { name: 'Type', field: 'type', sortable: true }]}
                        />
                    },
                    {
                        name: 'Usernames',
                        id: 'usernames',
                        content: <ListItems test
                            columns={[
                                { name: 'Username', field: 'key', sortable: true, formatted: 'value' },
                                { name: 'Platform', field: 'type', sortable: true }
                            ]}
                            items={person.profiles.map(profile => {
                                const ctype = getCtype(sourceMapping[profile.source] || profile.source);
                                return {
                                    value: <a href={profile.link} target="_blank" rel="noreferrer">{profile.username}</a>,
                                    type: ctype.name,
                                    key: profile.username + profile.source
                                };
                            })}
                        />
                    },
                    {
                        name: 'Addresses',
                        id: 'addresses',
                        content: <ListItems items={person.addresses.map(({ value, country }) => ({ value: country, type: value }))}
                            columns={[{ name: 'Country', field: 'value', sortable: true }, { name: 'Address', field: 'type' }]}
                        />
                    },
                    {
                        name: 'Jobs',
                        id: 'jobs',
                        content: <ListItems test
                            items={person.jobs.map((job, i) => ({
                                ...job,
                                date: `${job.start || ''}${job.end ? ` - ${job.end}` : ''}`,
                                key: i
                            }))}
                            columns={[
                                { name: 'Title', field: 'title' },
                                { name: 'Organization', field: 'organization' },
                                { name: 'Industry', field: 'industry' },
                                { name: 'Date', field: 'date' }
                            ]}
                        />
                    },
                    {
                        name: 'URLs',
                        id: 'urls',
                        content: <ListItems test
                            columns={[
                                { name: 'Name', field: 'source' },
                                { name: 'URL', field: 'url', formatted: 'formattedUrl' }
                            ]}
                            items={person.urls.map(url => ({
                                source: capitalize(url.type),
                                url: url.value,
                                key: url.value,
                                formattedUrl: <a href={url.value} target="_blank" rel="noreferrer">{url.value}</a>
                            }))}
                        />
                    },
                    {
                        name: 'Education',
                        id: 'education',
                        content: <ListItems columns={[{ name: 'Details', field: 'value' }]}
                            items={person.educations.map(({ start, end, school }) => ({
                                value: `${school} (${start || ''}${end ? ` - ${end}` : ''})`,
                                key: school
                            }))}
                        />
                    },
                    {
                        name: 'Associated with',
                        id: 'relationships',
                        content: <ListItems items={person.relationships}
                            columns={[
                                { name: 'Name', field: 'value' },
                                { name: 'Relationship', field: 'type' }
                            ]}
                        />
                    }
                ]}
            />
            <Button onClick={() => openPreviousModal()} className="mr-2">Close</Button>
        </div>
    );
};

const sourceMapping = {
    twitter: 'tweet',
    google: 'google_review',
    vk: 'vkdotcom',
    tumblr: 'tumblrpost'
};

const ListItems = ({ items, columns = [] }) => {
    const [sort, setSort] = useState({ field: 'name', order: 'desc' });
    return (
        <div className="maxh-300 overflow-auto scrollbar-small mb-2">
            {items.length ? (
                <Table className="m-0">
                    <thead>
                        <tr>
                            {columns.map(col => (
                                <th key={col.field}>
                                    {col.sortable
                                        ? <SortToggle sort={sort} setSort={setSort} name={col.name} field={col.field} />
                                        : col.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {lodashSort(items, sort).map(item => (
                            <tr key={item.key || item.value + item.type}>
                                {columns.map(col => (
                                    <td key={col.field + item.key} className="dont-break-out">
                                        {item[col.formatted || col.field]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <>
                    <hr className="mt-0" />
                    <p className="p-3 bg-lightgray">No data available</p>
                </>
            )}
        </div>
    );
};
