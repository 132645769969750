import React, { useState, useEffect, ReactNode } from 'react';

type StickToBottomProps = {
    children: ReactNode
}
export const StickToBottom = ({ children }: StickToBottomProps) => {
    const [scrollTop, setScrollTop] = useState(window.pageYOffset);
    const [limit, setLimit] = useState(document.body.offsetHeight - window.innerHeight);
    const calculateBottom = () => {
        if (scrollTop > limit - 30) {
            return 0;
        }
        return (limit - scrollTop) - 30;
    };
    const bottom = calculateBottom();
    useEffect(() => {
        const onScroll = (e: Event) => {
            const target = e.target as Document;
            setScrollTop(target.documentElement?.scrollTop);
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [setScrollTop]);
    useEffect(() => {
        setLimit(document.body.offsetHeight - window.innerHeight);
    }, []);

    return (
        <div className="sticky-bottom pt-4 pb-3 z-index-10">
            <div className="position-absolute w-100" style={{ bottom }} data-testid="stick-to-bottom">
                {children}
            </div>
        </div>
    );
};
