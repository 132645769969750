import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { SelectAllCheckBox } from '../../../../components/Form/CheckBox';
import { RemovedContentListItem } from './RemovedContentListItem';
import { SortToggle } from '../../../../components/Table/SortToggle';
import { EmptySearchResults } from '../../../../components/EmptySearchResults';

export const FeedbackTable = ({ deletedContentDataIds, removedContent, checkBoxValues, setCheckBoxValues, user, setPage }) => {
    const [sort, setSort] = useState({});
    const sortEntities = (a, b) => {
        if (!sort.field) {
            return 0;
        }
        if (sort.field === 'contentType') {
            if (sort.order === 'asc') {
                return b.type?.localeCompare(a.type);
            }
            return a.type?.localeCompare(b.type);
        }
        if (sort.field === 'content') {
            if (sort.order === 'asc') {
                return b.additional_info?.text?.localeCompare(a.additional_info?.text);
            }
            return a.additional_info?.text?.localeCompare(b.additional_info?.text);
        }
        if (sort.field === 'removedBy') {
            if (sort.order === 'asc') {
                return b.created_by?.localeCompare(a.created_by);
            }
            return a.created_by?.localeCompare(b.created_by);
        }
        if (sort.field === 'removedOn') {
            if (sort.order === 'asc') {
                return new Date(a.created_at) - new Date(b.created_at);
            }
            return new Date(b.created_at) - new Date(a.created_at);
        }
    };
    return (
        <>
            {removedContent.length === 0 ? (
                <EmptySearchResults mainHeading="No records found" subtitle="Try searching with different filters / keywords." />
            ) : (
                <Table className="mt-3">
                    <thead className="py-2">
                        <tr>
                            <th>
                                <div className="d-flex align-items-center feedbackTable">
                                    {user?.role === 'ADMIN' && (
                                        <SelectAllCheckBox id="allThreatsContent"
                                            displayLabel={false}
                                            options={deletedContentDataIds}
                                            values={checkBoxValues}
                                            allCheck={setCheckBoxValues}
                                            page="content-table"
                                        />
                                    )}
                                    <div>
                                        <SortToggle sort={sort} setSort={setSort} name="Content type" field="contentType" />
                                    </div>
                                </div>
                            </th>
                            <th><SortToggle sort={sort} setSort={setSort} name="Content" field="content" /></th>
                            <th><SortToggle sort={sort} setSort={setSort} name="Removed by" field="removedBy" /></th>
                            <th><SortToggle sort={sort} setSort={setSort} name="Removed on" field="removedOn" /></th>
                            {checkBoxValues.length < 1 && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {[...removedContent].sort(sortEntities).map(item => {
                            const checked = checkBoxValues.indexOf(item?.doc_id) === -1;
                            return (
                                <RemovedContentListItem item={item}
                                    checkBoxValues={checkBoxValues}
                                    setCheckBoxValues={setCheckBoxValues}
                                    checked={checked}
                                    user={user}
                                    key={item?.doc_id}
                                    setPage={setPage}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            )}
            <div className="mt-2" />
        </>
    );
};
