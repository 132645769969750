import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../../../components/Loading';
import { KeyBoardDownIcon, KeyBoardUpIcon } from '../../../../../assets/icons/svgicons';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import noData from '../../../../../assets/images/noData.svg';

export const SavedComparisons = (props) => {
    const { room1 } = props;
    const history = useHistory();
    const { data, loading, refetch } = useQuery(GET_COMPARISONS, {
        variables: {
            situationRoom: room1?.id,
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        refetch();
    }, [history.location.pathname, refetch]);

    if (loading) return <Loading />;
    return (
        <div>
            {data && data?.getComparisons.length > 0 ? (
                <SavedComparisonTableData data={data?.getComparisons.map((x) => ({ ...x, comparisonLength: x.narratives.length }))} />
            ) : (
                <div className="px-4 py-5 bg-white rounded">
                    <div className="py-3" />
                    <div className="minh-200 d-flex align-items-center justify-content-center">
                        <div className="py-5 d-flex flex-column align-items-center">
                            <img src={noData} alt="" className="d-block mx-auto" />
                            <h3 className="d-block mx-auto pt-1">There are no saved comparisons.</h3>
                            <p className="pt-1">
                                You can create and save a comparison in the Narratives section.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const SavedComparisonTableData = ({ data }) => {
    const history = useHistory();
    const [tableData, setTableData] = useState(data);
    const [sortOrder, setSortOrder] = useState({
        createdAt: 'asc',
        averageThreatLevel: 'desc',
        comparisonLength: 'desc',
    });

    const sortDataFromTable = (name1, order, number) => {
        const prev = sortOrder;
        let knownData;
        prev[name1] = order === 'dsec' ? 'asc' : 'dsec';
        setSortOrder(prev);
        if (tableData) {
            knownData = tableData;
        }
        let data1;
        if (number) {
            data1 = knownData?.slice().sort((a, b) => {
                if (order === 'dsec') {
                    if (b[name1] !== a[name1]) {
                        return b[name1] - a[name1];
                    }
                }
                return a[name1] - b[name1];
            });
        } else {
            data1 = knownData?.slice().sort((a, b) => {
                if (order === 'dsec') {
                    return b[name1].localeCompare(a[name1]);
                }
                return a[name1].localeCompare(b[name1]);
            });
        }
        setTableData(data1);
    };
    return (
        <div>
            {data && (
                <Table>
                    <thead>
                        <tr>
                            <th>Comparisons{`(${tableData.length})`}</th>
                            <th>
                                <span className="d-flex align-items-center"
                                    role="button"
                                    onClick={() =>
                                        sortDataFromTable('createdAt', sortOrder.createdAt, true)}
                                >
                                    Date saved{' '}
                                    {sortOrder.createdAt === 'dsec' ? (
                                        <KeyBoardUpIcon />
                                    ) : (
                                        <KeyBoardDownIcon />
                                    )}
                                </span>
                            </th>
                            <th>
                                <span className="d-flex align-items-center"
                                    role="button"
                                    onClick={() => sortDataFromTable(
                                        'comparisonLength',
                                        sortOrder.comparisonLength,
                                        true
                                    )}
                                >
                                    No. of narratives{' '}
                                    {sortOrder.comparisonLength === 'dsec' ? (
                                        <KeyBoardUpIcon />
                                    ) : (
                                        <KeyBoardDownIcon />
                                    )}
                                </span>
                            </th>
                            <th>
                                <span className="d-flex align-items-center"
                                    role="button"
                                    onClick={() => sortDataFromTable(
                                        'averageThreatLevel',
                                        sortOrder.averageThreatLevel
                                    )}
                                >
                                    Average threat level{' '}
                                    {sortOrder.averageThreatLevel === 'dsec' ? (
                                        <KeyBoardUpIcon />
                                    ) : (
                                        <KeyBoardDownIcon />
                                    )}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData && tableData.map((tableDataItem) => (
                            <tr key={tableDataItem?.id}>
                                <td className="text-primary cursor-pointer"
                                    onClick={() => {
                                        history.push(
                                            `${window.location.pathname}/${window.location.pathname.includes('saved-comparisons')
                                                ? `${tableDataItem?.name}` : `saved-comparisons/${tableDataItem?.name}`}`,
                                            { ...tableDataItem, isEdit: true }
                                        );
                                    }}
                                >
                                    {tableDataItem?.name}
                                </td>
                                <td>{`${moment(tableDataItem?.createdAt).format('DD/MM/YYYY, @ hh:mm:ss')}`}</td>
                                <td>{tableDataItem?.comparisonLength}</td>
                                <td>{capitalizeFirstLetter(tableDataItem?.averageThreatLevel)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export const GET_COMPARISONS = gql`
  query getComparisons($situationRoom: ID!) {
    getComparisons(situationRoom: $situationRoom) {
      id
      name
      createdAt
      averageThreatLevel
      narratives
    }
  }
`;
