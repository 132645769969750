import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { Tabs } from '../../../../../components/Tabs';
import { Keywords } from './Keywords';

export const DrivingNarratives = () => {
    const history = useHistory();
    const location = useLocation();
    const prevTab = location.state?.prevTab;
    return (
        <div>
            <Button className="go-back-from-dashboard text-decoration-none align-items-center"
                color="link"
                onClick={history.goBack}
            >
                <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                <span>Back</span>
            </Button>
            <h3 className="mt-4 mb-4">What is driving the Narrative?</h3>
            <Tabs prevKey={prevTab}
                data={[
                    { name: 'Keywords', id: 'keywords', content: <Keywords /> }
                ]}
            />
        </div>
    );
};
