import React from 'react';
import { Narratives } from './Narratives';
import { Tabs } from '../../../../components/Tabs';
import { SavedComparisons } from './SavedComparisons';
import { CustomNarratives } from './CustomNarratives';

export const NarrativeOverview = ({ room }) =>
    (
        <div className="pt-2">
            <Tabs param
                data={[
                    { name: 'Narratives', id: 'narratives', content: <Narratives room={room} /> },
                    { name: 'Saved comparisons', id: 'saved-comparisons', content: <SavedComparisons room1={room} /> },
                    { name: 'Custom narratives',
                        id: 'custom-narratives',
                        content: <CustomNarratives /> }
                ]}
            />
        </div>
    );
