import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { LocationTable } from '../Dashboard/NarrativeLocations';
import { getRoom } from '../../../../utils/variables';
import { TranslateIcon } from '../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../graphql';

export const LocationMentions = ({ locData }) => {
    const firsRowData = locData?.slice(0, 2);
    const secondRowData = locData?.length > 2 ? locData?.slice(2, 4) : [];
    // eslint-disable-next-line no-unsafe-optional-chaining
    const locationsArray = locData?.map(item => item?.narrative_locations.map(loc => loc?.location));
    const findCommon = (min, arrs) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const commonArr = [...new Set(arrs?.flat())].filter(e => arrs?.reduce((acc, cur) => acc + cur?.includes(e), 0) >= min);
        if (commonArr.length > 0) {
            return commonArr.join(', ');
        }
        return 'There are no common locations.';
    };
    return (
        <div className="p-3 bg-white">
            <h3 className="m-0">Common location mentions</h3>
            <p>{findCommon(locData?.length, locationsArray)}</p>
            <h3 className="mt-4">Top 5 location mentions</h3>
            <Row className="m-0 mt-4 p-0">
                {
                    firsRowData?.map((item, idx) => (
                        <LocMentionsColData item={item} idx={idx} />
                    ))
                }
            </Row>
            {
                secondRowData?.length > 0
                    ? (
                        <Row className="m-0 mb-2 mt-4 p-0">
                            {
                                secondRowData?.map((item, idx) => (
                                    <LocMentionsColData item={item} idx={idx} />
                                ))
                            }
                        </Row>
                    ) : null
            }
        </div>
    );
};

const LocMentionsColData = ({ item, idx }) => {
    const [isTranslated, setIsTranslated] = useState(false);
    const room = getRoom();
    const history = useHistory();
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: item?.narrative_title, target: 'en' }
        },
    });
    const outputText = translatedData && isTranslated ? translatedData?.translateMultipleText[0]?.translations[0]
        : item?.narrative_title;
    const translation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getTranslation();
        setIsTranslated(!isTranslated);
    };
    return (
        <Col sm={10} md={6} className={idx === 0 && 'pl-0'}>
            <h3 onClick={() => history.push(`/situation-rooms/${room?.id}/narratives/${item?.id}/dashboard/`)}
                className="m-0 mb-2 cursor-pointer text-primary"
            >{outputText}
                {['en', 'un'].includes(item?.lang) ? null : (
                    <span role="button"
                        className="ml-2"
                        onClick={(e) => translation(e)}
                    >
                        <TranslateIcon color="#006FF9" size={30} />
                    </span>
                )}
            </h3>
            <LocationTable lData={item} />
            <hr className="mb-0" />
        </Col>
    );
};
