import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Chart } from 'regraph';
import { useCallback, useEffect, useRef } from 'react';
import { NetworksState, setRegraphLoading, updateNetworks } from '../../store';

type UseDeleteNodesProps = {
    chartRef: React.RefObject<Chart<any, string | number>>
}

export const useDeleteNodes = ({ chartRef }: UseDeleteNodesProps) => {
    const { selection } = useSelector((state: { networks: NetworksState }) => state.networks);
    const dispatch = useDispatch();
    const handleKeyDown = useCallback(({ key }: KeyboardEvent) => {
        if (!chartRef.current) return null;
        if (key === 'Delete') {
            if (!selection?.length) return null;
            dispatch(setRegraphLoading(true));
            const dispatchDeletion = debounce(() => { // allow loading state to appear
                dispatch(updateNetworks({
                    deletedNodes: selection
                }));
            }, 100);
            dispatchDeletion();
        }
    }, [chartRef, dispatch, selection]);

    const cbRef = useRef(handleKeyDown);
    useEffect(() => {
        cbRef.current = handleKeyDown;
    }, [handleKeyDown]);

    useEffect(() => {
        const cb = (e: KeyboardEvent) => cbRef.current(e);
        document.addEventListener('keydown', cb, true);

        return () => {
            document.removeEventListener('keydown', cb);
        };
    }, [handleKeyDown]);
};
