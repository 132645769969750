import React, { useState } from 'react';

import {
    CustomInput,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import { useDropDown } from '../../../utils/useDropdown';
import { Loading } from '../../Loading';

type DropdownWithSearchAndCheckboxesProps = {
    name: string,
    placeholder?: string,
    description: string,
    items: {
        id: string,
        name: string,
        selected: boolean
    }[],
    handleSelect: (id: string) => void
};

export const DropdownWithSearchAndCheckboxes = ({
    name, placeholder, description, items, handleSelect
}: DropdownWithSearchAndCheckboxesProps) => {
    const [dropdown, toggleDropdown] = useDropDown();
    const [searchValue, setSearchValue] = useState('');

    const filteredItems = searchValue ? items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())) : items;

    const handleSelectItem = (id: string) => {
        handleSelect(id);
    };

    return (
        <Dropdown isOpen={dropdown}
            toggle={toggleDropdown}
        >
            <DropdownToggle tag="div"
                data-testid="dropdown-toggle"
                className={`dropdown-filter d-flex justify-content-between align-items-center cursor-pointer px-2 py-1 
                    ${dropdown && 'border border-primary'}`}
            >
                <span>{name}</span>
                <ChevronDown size={20}
                    className="ml-11"
                    color="#006FF9"
                    style={{ transition: 'all .25s ease-in-out', transform: dropdown ? 'rotate(-180deg)' : 'none' }}
                />
            </DropdownToggle>
            <DropdownMenu className={`dropdown-filter-menu-checkboxes ${dropdown && 'border border-primary'}`}>
                <div className="p-2 border-bottom">
                    <Input value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        placeholder={placeholder || 'Search'}
                        data-testid="dropdown-search-input"
                    />
                </div>
                <div className="dropdown-filter-menu-items scrollbar-small">
                    {filteredItems.length ? (
                        <>
                            {description && <div>{description}</div>}
                            {filteredItems.map((item) => (
                                <FormGroup key={item.id}>
                                    <CustomInput type="checkbox"
                                        checked={item.selected}
                                        className="custom-control-input-checkbox py-1"
                                        label={<span className="truncate-this">{item.name}</span>}
                                        id={item.id}
                                        data-testid={`checkbox-${item.id}`}
                                        value={item.name}
                                        onChange={() => handleSelectItem(item.id)}
                                    />
                                </FormGroup>
                            ))}
                        </>
                    )
                        : <Loading relative height={168} width={255} />}
                </div>
            </DropdownMenu>
        </Dropdown>
    ); };
