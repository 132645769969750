import { gql } from '@apollo/client';

export const GET_CUSTOM_NARRATIVES = gql`
    query getCustomNarratives($situationRoom: ID!, $id: ID) {
        getCustomNarratives(situationRoom: $situationRoom, id: $id) {
            id
            name
            versionId
            user
            data {
                id
                threat
                boolean
                keywords
                nearQuery
            }
        }
    }
`;

export const CHECK_NAME_EXISTS = gql`
    query getReports($name: String, $situationRoom: ID){
        getReports(name: $name, situationRoom: $situationRoom){
            id
        }
    }
`;

export const GET_REPORTS = gql`
    query getReports($id: ID, $situationRoom: ID){
        getReports(id: $id, situationRoom: $situationRoom){
            id
            situationRoom {
                id
            }
            name
            createdBy {
                id
                uid
                displayName
                email
            }
            assignedTo
            updatedBy {
                id
                uid
                displayName
                email
            }
            createdAt
            updatedAt
            filters {
                startDate
                endDate
            },
            draft
            seenBy {
                date
                user {
                    id
                    displayName
                }
            }
            template {
                id
                name
                createdBy {
                    id
                    displayName
                    email
                }
                updatedBy {
                    id
                    displayName
                    email
                }
                createdAt
                updatedAt
                backgroundImage
                settings {
                    darkBackground
                    logicallyLogo
                    customerLogo
                    pageNumber
                    showDate
                    showSubtitle
                }
                style {
                    fontFamily
                    mainTitle {
                        fontSize
                        color
                        bold
                    }
                    subtitle {
                        fontSize
                        color
                        bold
                    }
                    pageTitle {
                        fontSize
                        color
                        bold
                    }
                    bodyText {
                        fontSize
                        color
                        bold
                    }
                }
            }
            pages {
                id
                type
                logo
                clientLogo
                heading {
                    blocks
                    entityMap
                }
                subtitle {
                    blocks
                    entityMap
                }
                pageTitle {
                    blocks
                    entityMap
                }
                bodyText {
                    blocks
                    entityMap
                }
                blocks
                date
            }
            url
        }
    }
`;

export const GET_ALL_REPORTS = gql`
    query getReports($situationRoom: ID){
        getReports(situationRoom: $situationRoom){
            id
            name
            createdBy {
                id
                uid
                displayName
                email
                photoURL
            }
            assignedTo
            updatedBy {
                id
                uid
                displayName
                email
                photoURL
            }
            createdAt
            updatedAt
            autoSave
            filters {
                startDate
                endDate
                locations_detected
                originNot
            },
            draft
            url
            seenBy {
                date
                user {
                    id
                    displayName
                    email
                }
            }
        }
    }
`;

export const CREATE_REPORT = gql`
    mutation createReport($data: RoomReportInput, $file: Upload){
        createReport(data: $data, file: $file){
            id
        }
    }
`;

export const UPDATE_REPORT = gql`
    mutation updateReport($data: RoomReportUpdateInput, $file: Upload){
        updateReport(data: $data, file: $file){
            id
        }
    }
`;

export const DELETE_REPORT = gql`
    mutation deleteReport($id: [ID!]){
        deleteReport(id: $id)
    }
`;

export const GET_TEMPLATES = gql`
    query getTemplates($id: ID, $instance: ID){
        getTemplates(id: $id, instance: $instance){
            id
            name
            createdBy {
                id
                displayName
                email
            }
            updatedBy {
                id
                displayName
                email
            }
            createdAt
            updatedAt
            backgroundImage
            settings {
                darkBackground
                logicallyLogo
                customerLogo
                pageNumber
                showDate
                showSubtitle
            }
            style {
                fontFamily
                mainTitle {
                    fontSize
                    color
                    bold
                }
                subtitle {
                    fontSize
                    color
                    bold
                }
                pageTitle {
                    fontSize
                    color
                    bold
                }
                bodyText {
                    fontSize
                    color
                    bold
                }
            }
        }
    }
`;

export const CREATE_TEMPLATE = gql`
    mutation createTemplate($data: TemplateInput){
        createTemplate(data: $data){
            id
        }
    }
`;

export const UPDATE_TEMPLATE = gql`
    mutation updateTemplate($data: TemplateUpdateInput){
        updateTemplate(data: $data){
            id
        }
    }
`;

export const DELETE_TEMPLATE = gql`
    mutation deleteTemplate($id: ID!){
        deleteTemplate(id: $id)
    }
`;
