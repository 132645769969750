import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import * as FeatherIcon from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { Loading } from '../../../components/Loading';
import { GET_CLAIM } from '../../../graphql';

export const FactDetails = ({ user, room }) => {
    const history = useHistory();
    const [claimData, setClaimData] = useState(history.location.state?.item);
    const claimId = history.location.pathname.split('/').pop();
    const [getClaim, { loading }] = useLazyQuery(GET_CLAIM, {
        variables: {
            userId: user.id,
            claimId,
            projectId: room.project_id
        },
        onCompleted: (data) => {
            setClaimData(data?.getClaim?.entries[0]?.claim_details?.claims_docs[0]);
        },
        fetchPolicy: 'no-cache',
    });
    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'Fact checks', href: `/situation-rooms/${room.id}/fact-checks` },
        { title: 'Request details', href: `/situation-rooms/${room.id}/fact-checks/${claimId}` }
    ];

    const getVerdictBgColor = (verdict) => {
        if (verdict === 'True') {
            return 'success';
        } if (verdict === 'False') {
            return 'danger';
        }
        return 'primary';
    };

    useEffect(() => {
        if (!history.location.state?.item) {
            getClaim();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <Loading />;
    }
    return (
        <div className="page-padding">
            <BreadCrumbs items={bread} />

            <Button className="go-back-from-dashboard text-decoration-none align-items-center"
                color="link"
                onClick={() => {
                    history.push({
                        pathname: `/situation-rooms/${room.id}/fact-checks`,
                    });
                }}

            >
                <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                <span>Back to all factchecks</span>
            </Button>

            <h2 className="mt-4 mb-4">Request details</h2>
            {claimData?.status === 'resolved'
                ? (
                    <div className="bg-white pb-2 p-3">
                        <p className="factcheck-title mb-3"><span className="show-circle mr-1" />
                            Completed
                        </p>
                        <Alert color={getVerdictBgColor(claimData?.verdict)} className="border-0 mb-3 w-fit-content">
                            <span>{claimData?.verdict.toString().replace(/_/g, ' ')}</span>
                        </Alert>
                        <p className="factcheck-title">Fact check ID</p>
                        <p className="pb-2">
                            {claimData?._id}
                        </p>
                        <p className="factcheck-title">Requested by</p>
                        <p className="pb-2">
                            {user.displayName} on {claimData?.created_at}
                        </p>
                        <p className="factcheck-title">Requested fact check</p>
                        <p className="pb-2">
                            {claimData?.body}
                        </p>
                        <p className="factcheck-title">Fact checked by</p>
                        <p className="pb-2">
                            {claimData?.first_name || claimData?.username} on {claimData?.created_at}
                        </p>
                        <p className="factcheck-title mb-1">Explanation</p>
                        <p className="factcheck-title">{claimData?.conclusion_summary}</p>
                        <p className="pb-2">
                            {claimData?.conclusion.substring(claimData.conclusion_summary.length)}
                        </p>
                        {claimData?.editor_reference_urls.length > 0 && (
                            <>
                                <p className="factcheck-title" data-testid="reference-links">Reference Links</p>
                                {claimData.editor_reference_urls.map((refUrl) =>
                                    <p key={refUrl.url}><a href={refUrl.url} className="text-info">{refUrl.url}</a></p>)}
                            </>
                        )}
                    </div>
                ) : (
                    <div className="bg-white pb-2 p-3">
                        <p className="factcheck-title"><span className="show-circle show-circle-orange mr-1" />
                            In progress
                        </p>
                        <Alert color="warning" className="alert-container">
                            <span className="pr-1"><FeatherIcon.AlertCircle size="16px" color="#FCCD13" /></span>
                            It takes upto X days to get the result.
                        </Alert>
                        <p className="factcheck-title">Fact check ID</p>
                        <p className="pb-2">
                            {claimData?._id}
                        </p>
                        <p className="factcheck-title">Requested by</p>
                        <p className="pb-2">
                            {user.displayName} on {claimData?.created_at}
                        </p>
                        <p className="factcheck-title">Requested fact check</p>
                        <p>
                            {claimData?.body}
                        </p>
                        {claimData?.message && (
                            <>
                                <p className="factcheck-title">Comment</p>
                                <p>
                                    {claimData?.message}
                                </p>
                            </>
                        )}
                    </div>
                )}
        </div>
    );
};
