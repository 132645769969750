import React from 'react';
import { v4 as uuid } from 'uuid';
import { truncateString } from '../../../utils/text';
import { getHighlightedContent } from '../../../utils/getHighlightedContent';

export const renderContentText = ({
    extracts,
    translateClicked,
    outputText,
    fullText,
    outputExtracts
}) => {
    if (!extracts) {
        return <p className="mb-0 dont-break-out">{truncateString(translateClicked ? outputText : fullText, 400)}</p>;
    }
    if (extracts && translateClicked) {
        return (
            <p className="mb-0 dont-break-out">
                {getHighlightedContent(translateClicked ? truncateString(outputText, 400) : truncateString(fullText))}
            </p>
        );
    }
    if (extracts && !translateClicked) {
        return (
            <div>
                {outputExtracts.map((text, i) => {
                    if (i > 3) { return null; }
                    return (
                        <p key={uuid()} className="highlight-em dont-break-out">
                            {getHighlightedContent(truncateString(text, 400))}
                        </p>
                    );
                })}
            </div>
        );
    }
};
