import { useQuery } from '@apollo/client';
import React from 'react';
import { Table } from 'reactstrap';
import { Loading } from '../../components/Loading';
import { GET_SITUATION_ROOM_DRAFTS } from '../../graphql';
import { NoSituationRoomsFound } from './NoSituationRooms';
import { SituationRoomRow } from './SituationRoomRow';

export const DraftRooms = ({ instanceId }) => {
    const { data, loading } = useQuery(GET_SITUATION_ROOM_DRAFTS, {
        variables: { instance: instanceId },
        fetchPolicy: 'network-only'
    });
    if (loading) {
        return <Loading gif relative height={400} />;
    }
    const rooms = data.situationRoomDrafts || [];
    return (
        <div className="overflow-x-auto">
            <Table className="m-0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Draft origin</th>
                        <th>Created by</th>
                        <th>Last updated</th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {rooms.map((room) => (
                        <tr key={room.id}>
                            <SituationRoomRow room={{ ...room, status: 'Draft' }} />
                        </tr>
                    ))}
                </tbody>
            </Table>
            {(!loading && !rooms.length) && <NoSituationRoomsFound />}
        </div>
    );
};
