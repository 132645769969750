import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { getRoom, getWatchlist, refetchWatchlist } from '../../../../utils/variables';

export const GetNarrativesWatchlist = () => {
    const room = getRoom();
    const { data: watchlist, refetch } = useQuery(GET_NARRATIVES_WATCHLIST, {
        variables: {
            situationRoom: room.id,
        },
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (watchlist) {
            getWatchlist(watchlist.getNarrativesWatchlist);
        }
    }, [watchlist]);

    useEffect(() => {
        refetchWatchlist(refetch);
    }, [refetch]);

    return null;
};

export const GET_NARRATIVES_WATCHLIST = gql`
    query getNarrativesWatchlist($situationRoom: ID!){
        getNarrativesWatchlist(situationRoom: $situationRoom)
    }
`;
