import React from 'react';
import { KeyBoardDownIcon, KeyBoardUpIcon } from '../../../../assets/icons/svgicons';

export const SortItem = ({ sortField, sortName, sortType, initialLoad }) => {
    if (initialLoad) return <KeyBoardDownIcon />;
    if (sortField !== sortName || (sortField === sortName && sortType)) {
        return <KeyBoardDownIcon />;
    }

    return <KeyBoardUpIcon />;
};
