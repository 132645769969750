import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { Dropdown } from '../../../../components/Form/Dropdown';
import { ADD_CTYPE, GET_CTYPES } from '../../../../graphql';
import { ctypeFontOptions, getRGB, rgbToHex } from '../Utils';

export const CreateSource = ({ closeModal }) => {
    const initialCtypeData = {
        key: '',
        name: '',
        icon: '',
        nodeColor: '#000000',
        color: '0,0,0'
    };
    const [font, setFont] = useState(ctypeFontOptions[0]);
    const [ctypeData, setCtypeData] = useState(initialCtypeData);
    const [createCtype, { loading }] = useMutation(ADD_CTYPE, {
        refetchQueries: [GET_CTYPES],
        onCompleted: () => {
            closeModal();
        },
    });
    const handleSave = () => {
        createCtype({
            variables: {
                data: { ...ctypeData, FontFamily: font.name },
            },
        });
    };
    return (
        <>
            <h2>Add new source</h2>
            <hr />
            <p>Enter key</p>
            <Input data-testid="ctype-key-input"
                value={ctypeData.key}
                onChange={(e) => setCtypeData({ ...ctypeData, key: e.target.value })}
            />
            <p className="mt-2">Enter name</p>
            <Input data-testid="ctype-name-input"
                value={ctypeData?.name}
                onChange={(e) => setCtypeData({ ...ctypeData, name: e.target.value })}
            />
            <p className="mt-2">Icon font</p>
            <Dropdown options={ctypeFontOptions}
                value={font}
                onChange={setFont}
            />
            <p className="mt-2">Icon name</p>
            <Input data-testid="ctype-icon-input"
                value={ctypeData?.icon}
                onChange={(e) => setCtypeData({ ...ctypeData, icon: e.target.value })}
            />
            <p className="mt-2">Icon color</p>
            <div className="d-flex align-items-center mt-2">
                <Input value={ctypeData?.nodeColor}
                    data-testid="ctype-nodeColor-input"
                    className="mr-2 w-25"
                    onChange={(e) => setCtypeData({ ...ctypeData,
                        color: getRGB(e.target.value),
                        nodeColor: e.target.value })}
                />
                <Input type="color"
                    className="ctype-selection-box"
                    value={rgbToHex(ctypeData?.color.split(','))}
                    onChange={(e) =>
                        setCtypeData({ ...ctypeData,
                            color: getRGB(e.target.value),
                            nodeColor: e.target.value })}
                />
                <i className={`font-size-28 fa ${ctypeData?.icon} ml-2`}
                    style={font.name && { fontFamily: `"${font.name}"`, color: `rgb(${ctypeData?.color})` }}
                />
            </div>
            <hr />
            <Button className="mr-2" onClick={() => closeModal()}>Close</Button>
            <Button className="mr-2" onClick={() => setCtypeData(initialCtypeData)}>Reset</Button>
            <Button color="primary" disabled={loading} onClick={() => handleSave()}>Save</Button>
        </>
    ); };
