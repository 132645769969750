import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { UPDATE_INVESTIGATION, UPLOAD_INVESTIGATION_REPORT } from '../../../../../graphql';
import { useModal } from '../../../../../context/modal/ModalComponent';
import approveInvestigationSuccess from '../../../../../assets/images/investigations/approveInvestigationSuccess.svg';
import { useInvestigation } from '../InvestigationContext';
import { useAdminUser } from '../../../../../context/adminUser/AdminUserContext';

export const SaveInvestigation = () => {
    const { user } = useAdminUser();
    const { investigation, file, investigationStatus, note, emailList } = useInvestigation();
    const { setModal, closeModal } = useModal();
    const [updateInvestigation] = useMutation(UPDATE_INVESTIGATION, {
        onCompleted: () => {
            closeModal();
            setModal({
                component: (
                    <>
                        <img src={approveInvestigationSuccess} alt="approval-success" width="100" />
                        <h3 className="mt-2">Investigation saved successfully</h3>
                        <hr className="w-100" />
                        <Button color="secondary"
                            className="mr-2"
                            onClick={() => window.location.replace(`${window.location.origin}/admin/investigations`)}
                        >Close
                        </Button>
                    </>
                )
            });
        },
        onError: () => {
            closeModal();
            toast.error('Investigation not saved, Please try again');
        }
    });
    const [uploadInvestigationReport] = useMutation(UPLOAD_INVESTIGATION_REPORT, {
        onCompleted: (uploadedFile) => {
            updateInvestigation({
                variables: {
                    data: {
                        id: investigation.id,
                        report: uploadedFile.uploadInvestigationReport.id,
                        reportCreatedBy: user.id,
                        completedAt: new Date(),
                        status: investigationStatus,
                        updatedAt: new Date(),
                        note,
                        contactEmails: emailList || investigation.contactEmails,
                    },
                },
            });
        },
        onError: () => {
            closeModal();
            toast.error('Report not uploaded, Please try again');
        }
    });

    const confirmInvestigation = () => {
        if (file && file !== '') {
            uploadInvestigationReport({ variables: { file } });
        }
        else {
            updateInvestigation({
                variables: {
                    data: {
                        id: investigation.id,
                        report: null,
                        reportCreatedBy: user.id,
                        completedAt: new Date(),
                        status: investigationStatus,
                        updatedAt: new Date(),
                        note,
                        contactEmails: emailList || investigation.contactEmails,
                    },
                },
            });
        }
    };
    const onSaveInvestigation = () => {
        setModal({
            component: (
                <>
                    <h3>Save investigation</h3>
                    <hr className="w-100" />
                    <p>Do you wish to save the updates for the investigation?</p>
                    <hr className="w-100" />
                    <Button color="primary"
                        className="mr-2"
                        onClick={() => confirmInvestigation()}
                    >Yes
                    </Button>
                    <Button color="secondary"
                        className="mr-4"
                        onClick={() => closeModal()}
                    >Cancel
                    </Button>
                </>
            )
        });
    };
    return (
        <Button color="primary"
            type="button"
            onClick={() => onSaveInvestigation()}
        >
            Save
        </Button>
    );
};
