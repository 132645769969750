import React, { useEffect, useState, useMemo } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { capitalizeFirstLetter } from '../../../utils/text';
import { DrivingNarratives } from './Dashboard/DrivingNarratives';
import { NarrativeOverview } from './Overview';
import { NarrativeDashboard } from './Dashboard';
import { NarrativePerformance } from './Performance';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import { GetNarrativesWatchlist } from './Overview/Watchlist/GetWatchlist';
import { NarrativesBeen } from './NarrativesBeen';
import { NarrativePerformers } from './NarrativePerformers';
import { Comparisons } from './Overview/SavedComparisons/Comparison';
import { Compare } from './Compare';
import { currentTab } from '../../../utils/variables';
import { routes } from '../../../routes';

export const Narratives = ({ room }) => {
    const defaultBread = useMemo(() => ([
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: room?.project_name, href: `/situation-rooms/${room?.id}` },
        { title: 'Narratives', href: `/situation-rooms/${room?.id}/narratives` }
    ]), [room]);

    const [bread, setBread] = useState(defaultBread);
    const currentRoute = routes.narratives.base.replace(':roomId', room?.id);
    const location = useLocation();
    const tab = useReactiveVar(currentTab);

    useEffect(() => {
        const allBread = [...defaultBread];
        const pieces = location.pathname.replace(currentRoute, '').split('/').filter(Boolean);
        for (const piece of pieces) {
            const prevUrl = allBread[allBread.length - 1].href;
            const nextPiece = pieces[pieces.indexOf(piece) + 1];
            const prevPiece = pieces[pieces.indexOf(piece) - 1];
            if (nextPiece !== 'dashboard') {
                if (piece === 'dashboard') {
                    allBread.push({
                        title: capitalizeFirstLetter(piece),
                        href: `${prevUrl}/${prevPiece}/${piece}`,
                    });
                } else if (piece === 'what-is-driving-the-narrative' && tab) {
                    allBread.push({
                        title: capitalizeFirstLetter(tab?.replaceAll('-', ' ')),
                        href: `${prevUrl}/${prevPiece}/${piece}?tab=${tab}`,
                    });
                } else if (piece === 'how-is-the-narrative-performing' && tab) {
                    allBread.push({
                        title: capitalizeFirstLetter(tab?.replaceAll('-', ' ')),
                        href: `${prevUrl}/${prevPiece}/${piece}?tab=${tab}`,
                    });
                } else if (piece === 'who-are-the-narrative-performers' && tab) {
                    allBread.push({
                        title: capitalizeFirstLetter(tab?.replaceAll('-', ' ')),
                        href: `${prevUrl}/${prevPiece}/${piece}?tab=${tab}`,
                    });
                } else {
                    allBread.push({
                        title: capitalizeFirstLetter(piece.replaceAll('-', ' ')),
                        href: `${prevUrl}/${piece}`,
                    });
                }
            }
        }
        setBread(allBread);
    }, [location, currentRoute, tab, setBread, defaultBread]);

    return (
        <div className="page-padding narratives">
            <BreadCrumbs items={bread} />
            <Switch>
                <Route exact path={routes.narratives.base}>
                    <NarrativeOverview room={room} />
                </Route>
                <Route exact path={routes.narratives.dashboard}>
                    <NarrativeDashboard />
                </Route>
                <Route exact path={routes.narratives.drive}>
                    <DrivingNarratives />
                </Route>
                <Route exact path={routes.narratives.performance}>
                    <NarrativePerformance />
                </Route>
                <Route exact path={routes.narratives.keyLocations}>
                    <NarrativesBeen />
                </Route>
                <Route exact path={routes.narratives.performers}>
                    <NarrativePerformers />
                </Route>
                <Route exact path={routes.narratives.comparison}>
                    <Compare />
                </Route>
                <Route exact path={routes.narratives.compare}>
                    <Comparisons />
                </Route>
            </Switch>
            <GetNarrativesWatchlist />
        </div>
    );
};
