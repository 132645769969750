import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { getCtype } from '../../../../utils/text';
import { getRoom } from '../../../../utils/variables';
import { Filters } from './Filters';

type PageFiltersProps = {
    isOpen: boolean,
    setOpen: (e: boolean) => void
}

type Bucket = {
    key: string,
    id: string
}

export const PageFilters = ({ isOpen, setOpen }: PageFiltersProps) => {
    const room = getRoom();
    const { data } = useQuery(GET_NARRATIVE_AGGS, {
        variables: {
            query: {
                bool: {
                    filter: {
                        match_phrase: {
                            project_id: {
                                query: room.project_id
                            }
                        }
                    }
                }
            },
            aggs: [
                {
                    key: 'ctype',
                    value: {
                        terms: {
                            field: 'original_post_ctype__keyword',
                            size: 100
                        }
                    }
                },
                {
                    key: 'location',
                    value: {
                        terms: {
                            field: 'narrative_locations__location__keyword',
                            size: 100
                        }
                    }
                }
            ]
        },
        fetchPolicy: 'no-cache'
    });

    const aggs = data ? data.newNarrativeServiceSearch.aggregations : false;
    if (!aggs) return null;

    return (
        <Filters isOpen={isOpen}
            setOpen={setOpen}
            aggs={{
                ctype: aggs.ctype?.buckets.map((a: Bucket) => getCtype(a.key)).filter((a: Bucket) => a.id) || [],
                locations: aggs.location?.buckets.map((a: Bucket) => ({ id: a.key.toLowerCase(), text: a.key }))
            }}
        />
    );
};

export const GET_NARRATIVE_AGGS = gql`
    query newNarrativeServiceSearch($aggs: [NewNarrativesESAggBlock], $query: NewNarrativesESQuery){
        newNarrativeServiceSearch(aggs: $aggs, query: $query, limit: 0) {
            aggregations
        }
    }
`;
