import React from 'react';

export const SuccessIcon = () => (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_871_1355)">
            <path fill="#D2D4DB"
                d="M61.5757 17.4138L25.6305 12.3306C23.2118 11.9886 20.9737 13.6721 20.6316
        16.0909L14.8172 57.2076C14.4751 59.6264 16.1586 61.8644 18.5774
        62.2065L54.5226 67.2897C56.9414 67.6317 59.1795 65.9482 59.5215
        63.5294L65.336 22.4128C65.6781 19.994 63.9945 17.7558 61.5757 17.4138Z"
            />
            <path fill="#E5E6EB"
                d="M4.27941 0.000155608C3.72463 -0.00748039 3.17378 0.0943491 2.65838 0.299756C2.14297
        0.505163 1.67315 0.810125 1.2757 1.19726C0.878254 1.58439 0.561019 2.04607 0.342129
        2.5559C0.123239 3.06572 0.00695581 3.61368 0 4.16847V71.5725C0.00724537 72.1271 0.123707
        72.6748 0.342726 73.1844C0.561745 73.694 0.879042 74.1553 1.27646 74.5422C1.67388 74.9291
        2.14366 75.234 2.65892 75.4392C3.17418 75.6445 3.72482 75.7462 4.27941 75.7385H56.1856C56.7401
        75.7462 57.2908 75.6445 57.806 75.4392C58.3213 75.234 58.7911 74.9291 59.1885 74.5422C59.5859
        74.1553 59.9032 73.694 60.1222 73.1844C60.3413 72.6748 60.4577 72.1271 60.465
        71.5725V10.5565L49.4487 0.000155608H4.27941Z"
            />
            <path fill="#D2D4DB"
                d="M41.0562 46.2557H20.3924C20.2451 46.2557 20.1038 46.1972 19.9996 46.0931C19.8954 45.9889 19.8369
        45.8476 19.8369 45.7003C19.8369 45.5529 19.8954 45.4116 19.9996 45.3075C20.1038 45.2033 20.2451 45.1448
        20.3924 45.1448H41.0562C41.2035 45.1448 41.3448 45.2033 41.449 45.3075C41.5532 45.4116 41.6117 45.5529
        41.6117 45.7003C41.6117 45.8476 41.5532 45.9889 41.449 46.0931C41.3448 46.1972 41.2035 46.2557 41.0562 46.2557Z"
            />
            <path fill="#D2D4DB"
                d="M35.591 49.4086H20.3909C20.2436 49.4086 20.1023 49.3501 19.9981 49.2459C19.894 49.1417 19.8354
         49.0004 19.8354 48.8531C19.8354 48.7058 19.894 48.5645 19.9981 48.4603C20.1023 48.3561 20.2436 48.2976
         20.3909 48.2976H35.591C35.7383 48.2976 35.8796 48.3561 35.9838 48.4603C36.088 48.5645 36.1465 48.7058
         36.1465 48.8531C36.1465 49.0004 36.088 49.1417 35.9838 49.2459C35.8796 49.3501 35.7383 49.4086 35.591 49.4086Z"
            />
            <path fill="#D2D4DB"
                d="M30.7228 52.3392H20.3909C20.2436 52.3392 20.1023 52.2807 19.9981 52.1765C19.894 52.0724 19.8354
         51.9311 19.8354 51.7837C19.8354 51.6364 19.894 51.4951 19.9981 51.391C20.1023 51.2868 20.2436 51.2283
         20.3909 51.2283H30.7228C30.8702 51.2283 31.0115 51.2868 31.1156 51.391C31.2198 51.4951 31.2783 51.6364
         31.2783 51.7837C31.2783 51.9311 31.2198 52.0724 31.1156 52.1765C31.0115 52.2807 30.8702 52.3392 30.7228 52.3392Z"
            />
            <path fill="#D2D4DB"
                d="M14.6713 45.1005H9.19652C8.63817 45.1005 8.18555 45.5531 8.18555 46.1114V51.5862C8.18555 52.1446 8.63817
         52.5972 9.19652 52.5972H14.6713C15.2297 52.5972 15.6823 52.1446 15.6823 51.5862V46.1114C15.6823 45.5531
         15.2297 45.1005 14.6713 45.1005Z"
            />
            <path fill="#D2D4DB"
                d="M34.6979 58.0096H20.3909C20.2436 58.0096 20.1023 57.9511 19.9981 57.847C19.894 57.7428 19.8354 57.6015
         19.8354 57.4542C19.8354 57.3068 19.894 57.1655 19.9981 57.0614C20.1023 56.9572 20.2436 56.8987 20.3909
         56.8987H34.6979C34.8452 56.8987 34.9864 56.9572 35.0906 57.0614C35.1948 57.1655 35.2533 57.3068 35.2533
         57.4542C35.2533 57.6015 35.1948 57.7428 35.0906 57.847C34.9864 57.9511 34.8452 58.0096 34.6979 58.0096Z"
            />
            <path fill="#D2D4DB"
                d="M30.9139 61.1581H20.3909C20.2436 61.1581 20.1023 61.0996 19.9981 60.9954C19.894 60.8912 19.8354 60.7499
         19.8354 60.6026C19.8354 60.4553 19.894 60.314 19.9981 60.2099C20.1023 60.1057 20.2436 60.0471 20.3909
         60.0471H30.9139C31.0613 60.0471 31.2025 60.1057 31.3067 60.2099C31.4109 60.314 31.4694 60.4553 31.4694
         60.6026C31.4694 60.7499 31.4109 60.8912 31.3067 60.9954C31.2025 61.0996 31.0613 61.1581 30.9139 61.1581Z"
            />
            <path fill="#D2D4DB"
                d="M27.5433 64.0934H20.3909C20.2436 64.0934 20.1023 64.0348 19.9981 63.9306C19.894 63.8265 19.8354 63.6852
         19.8354 63.5379C19.8354 63.3906 19.894 63.2493 19.9981 63.1451C20.1023 63.0409 20.2436 62.9824 20.3909
         62.9824H27.5433C27.6906 62.9824 27.8319 63.0409 27.9361 63.1451C28.0402 63.2493 28.0987 63.3906 28.0987
         63.5379C28.0987 63.6852 28.0402 63.8265 27.9361 63.9306C27.8319 64.0348 27.6906 64.0934 27.5433 64.0934Z"
            />
            <path fill="#D2D4DB"
                d="M51.5464 33.4108H8.7879C8.64058 33.4108 8.49928 33.3522 8.39511 33.2481C8.29093 33.1439 8.23242 33.0026
         8.23242 32.8553C8.23242 32.708 8.29093 32.5667 8.39511 32.4625C8.49928 32.3583 8.64058 32.2998 8.7879
         32.2998H51.5486C51.6959 32.2998 51.8372 32.3583 51.9414 32.4625C52.0456 32.5667 52.1041 32.708 52.1041
         32.8553C52.1041 33.0026 52.0456 33.1439 51.9414 33.2481C51.8372 33.3522 51.6959 33.4108 51.5486 33.4108H51.5464Z"
            />
            <path fill="#D2D4DB"
                d="M40.2369 36.5548H8.7879C8.64058 36.5548 8.49928 36.4963 8.39511 36.3921C8.29093 36.2879 8.23242 36.1466
         8.23242 35.9993C8.23242 35.852 8.29093 35.7108 8.39511 35.6066C8.49928 35.5024 8.64058 35.4438 8.7879
         35.4438H40.2391C40.3864 35.4438 40.5277 35.5024 40.6319 35.6066C40.7361 35.7108 40.7946 35.852 40.7946
         35.9993C40.7946 36.1466 40.7361 36.2879 40.6319 36.3921C40.5277 36.4963 40.3864 36.5548 40.2391 36.5548H40.2369Z"
            />
            <path fill="#D2D4DB"
                d="M30.1649 39.4902H8.7879C8.64058 39.4902 8.49928 39.4317 8.39511 39.3275C8.29093 39.2233 8.23242 39.0821
         8.23242 38.9347C8.23242 38.7874 8.29093 38.6461 8.39511 38.542C8.49928 38.4378 8.64058 38.3793 8.7879
         38.3793H30.1672C30.3145 38.3793 30.4558 38.4378 30.5599 38.542C30.6641 38.6461 30.7226 38.7874 30.7226
         38.9347C30.7226 39.0821 30.6641 39.2233 30.5599 39.3275C30.4558 39.4317 30.3145 39.4902 30.1672 39.4902H30.1649Z"
            />
            <path fill="#D2D4DB"
                d="M14.6382 64.2954H9.23228C8.9671 64.2954 8.71281 64.1901 8.5253 64.0026C8.33779 63.8151 8.23242 63.5608
         8.23242 63.2956V57.8896C8.23242 57.6245 8.33779 57.3702 8.5253 57.1826C8.71281 56.9951 8.9671 56.8898 9.23228
         56.8898H14.6404C14.9056 56.8898 15.1599 56.9951 15.3474 57.1826C15.5349 57.3702 15.6403 57.6245 15.6403
         57.8896V63.2956C15.6403 63.4271 15.6144 63.5573 15.564 63.6787C15.5136 63.8002 15.4397 63.9105 15.3467 64.0034C15.2536
         64.0963 15.1431 64.1699 15.0215 64.22C14.9 64.2701 14.7697 64.2957 14.6382 64.2954ZM9.34115
         63.1845H14.5271V58.0007H9.34115V63.1845Z"
            />
            <path fill="#D2D4DB"
                d="M31.3914 10.0453H10.1188C9.38252 10.0453 8.78564 10.6422 8.78564 11.3784V25.732C8.78564
        26.4683 9.38252 27.0651 10.1188 27.0651H31.3914C32.1277 27.0651 32.7246 26.4683 32.7246 25.732V11.3784C32.7246 10.6422
        32.1277 10.0453 31.3914 10.0453Z"
            />
            <path fill="#D2D4DB"
                d="M49.4487 0V8.47886C49.4487 9.02984 49.6676 9.55823 50.0572 9.94784C50.4468 10.3374 50.9753 10.5563
         51.5262 10.5563H60.4672L49.4487 0Z"
            />
            <path fill="#C8CACE"
                d="M56.7612 80.0022C64.9044 80.0022 71.5058 73.4008 71.5058 65.2576C71.5058 57.1143 64.9044
         50.5129 56.7612 50.5129C48.618 50.5129 42.0166 57.1143 42.0166 65.2576C42.0166 73.4008 48.618 80.0022 56.7612 80.0022Z"
            />
            <path fill="#F8F8F8"
                d="M11.2672 50.6264C11.1942 50.6267 11.1219 50.6125 11.0544 50.5847C10.9869 50.5568 10.9256
         50.5159 10.874 50.4643L9.763 49.3533C9.65869 49.249 9.6001 49.1075 9.6001 48.96C9.6001 48.8125 9.65869 48.671
         9.763 48.5667C9.8673 48.4624 10.0088 48.4038 10.1563 48.4038C10.3038 48.4038 10.4453 48.4624
         10.5496 48.5667L11.2672 49.2866L13.3181 47.2336C13.4224 47.1293 13.5638 47.0707 13.7113 47.0707C13.8589
         47.0707 14.0003 47.1293 14.1046 47.2336C14.2089 47.3379 14.2675 47.4794 14.2675 47.6269C14.2675 47.7744
         14.2089 47.9158 14.1046 48.0201L11.6605 50.4643C11.6089 50.5159 11.5476 50.5568 11.4801 50.5847C11.4126
         50.6125 11.3403 50.6267 11.2672 50.6264Z"
            />
            <path fill="white"
                d="M54.4836 70.3746C54.4108 70.3743 54.3388 70.3596 54.2717 70.3313C54.2046 70.3031 54.1437 70.262 54.0926
         70.2102L49.531 65.6508C49.4297 65.5462 49.3735 65.406 49.3745 65.2603C49.3756 65.1147 49.4338 64.9752 49.5367
         64.8721C49.6395 64.7689 49.7787 64.7103 49.9244 64.7089C50.07 64.7074 50.2104 64.7632 50.3153 64.8643L54.4836
         69.0326L63.2046 60.3027C63.3089 60.1984 63.4504 60.1398 63.5979 60.1398C63.7454 60.1398 63.8869 60.1984 63.9912
         60.3027C64.0955 60.407 64.1541 60.5484 64.1541 60.696C64.1541 60.8435 64.0955 60.9849 63.9912 61.0892L54.8814
         70.1991C54.8302 70.2509 54.7693 70.2921 54.7022 70.3203C54.6351 70.3485 54.5631 70.3632 54.4903 70.3635L54.4836 70.3746Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_871_1355">
                <rect width="71.5056" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
