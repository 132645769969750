import React from 'react';
import { TrackingNarrative } from './TrackingNarrative';

const narratives = [
    {
        id: 1,
    },
    {
        id: 1,
    }
];

export const TrackingNarratives = () => (
    <>
        <section className="d-flex flex-column">
            <p>
                View and manage the saved narrative comparisons
            </p>
        </section>
        <section className="d-flex flex-column">
            {narratives.map((narrative) => (
                <TrackingNarrative id={narrative.id} />))}
        </section>
    </>
);
