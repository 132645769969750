type BucketsTypes = {
    key: string,
    doc_count: number,
    count: number,
    engagement: any
}

export const getSeries = (buckets: any, type?: string) => {
    let hasVal = false;
    return buckets.map((bucket: BucketsTypes) => ({
        x: bucket.key,
        y: (type === 'count' || !type) ? (bucket.doc_count || bucket.count) : bucket.engagement?.value
    })).sort((a: { x: number; }, b: { x: number; }) => b.x - a.x)
        .slice(0, 1000)
        .reverse()
        .map((a: { y: any; }) => {
            if (hasVal) {
                return a;
            } if (a.y) {
                hasVal = true;
                return a;
            }
            return false;
        })
        .filter((a: any) => a);
};
