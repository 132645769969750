import { gql, useLazyQuery } from '@apollo/client';

export const getBooleanVersionsQuery = (situationRoom) => ({
    query: BOOLEAN_VERSIONS,
    variables: { situationRoom }
});

export const useBooleanVersions = (situationRoom) => {
    const { query, variables } = getBooleanVersionsQuery(situationRoom);
    const [getBooleanVersions, { data, loading, error }] = useLazyQuery(query, {
        variables,
        fetchPolicy: 'no-cache',
    });

    return { getBooleanVersions, data, loading, error };
};

export const BOOLEAN_VERSIONS = gql`
    query booleanVersions ($situationRoom: ID!){
        getBooleanVersions (situationRoom: $situationRoom) {
            situationRoom
            projectId
            boolean
            booleanTitle
            user {
                displayName
            }
            updatedAt
            notes
        }
    }
`;
