import React from 'react';
import { Alert as ReactAlert } from 'reactstrap';
import { AlertTriangle, X } from 'react-feather';

type AlertProps = {
    errorMessage: string;
    closeAlert: () => void;
}

export const Alert = ({ errorMessage, closeAlert }: AlertProps) => (
    <ReactAlert color="danger" isOpen className="alert-error d-flex justify-content-between align-items-center" data-testid="alert">
        <div>
            <AlertTriangle color="#f84367" size={18} className="cursor-pointer" />
            <span className="pl-2 pr-2 align-middle">{errorMessage}</span>
        </div>
        <div>
            <X size={16} role="button" onClick={() => { closeAlert(); }} data-testid="alert-x-icon" />
        </div>
    </ReactAlert>
);
