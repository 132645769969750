import React, { useState, useEffect, useRef } from 'react';
import { Chart, TimeBar, FontLoader } from 'regraph';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import has from 'lodash/has';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { kCores } from 'regraph/analysis';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faExpand,
    faMinusSquare,
    faPlusSquare,
    faEdit,
    faHandPaper,
    faProjectDiagram,
    faDownload
} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import 'leaflet/dist/leaflet';
import 'leaflet/dist/leaflet.css';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';

import * as ReGraph from 'regraph/analysis';
import { useSelector } from 'react-redux';
import {
    isNode,
    ToolbarItem,
    normalize,
    getThreat,
    getAutomation,
    colorPicker
} from './functions';
import { ToggleSwitch } from '../../../../components/ToggleSwitch';
import { Loading } from '../../../../components/Loading';
import { ConvertToReGraphFormat } from './formatData';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ContentModal } from './Modals/ContentModal';
import { AccountModal } from './Modals/AccountModal';
import { HashtagModal } from './Modals/HashtagModal';
import { SearchInput } from '../../../../components/Molecules';
import { useExploreContent } from './useExploreContent';
import { TooltipItem } from '../../../../components/ToolTip';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

library.add([faExpand, faMinusSquare, faPlusSquare, faEdit, faHandPaper, faProjectDiagram, faExpand]);

const analyseTerms = {
    engagement: { name: 'Content Engagement', description: 'Size nodes and Authors by content engagement.' },
    degrees: { name: 'Degrees', description: 'Size nodes by the number of links each node has.' },
    betweenness: { name: 'Betweenness', description: 'Size nodes by the number of shortest paths a node is part of.' },
    closeness: {
        name: 'Closeness',
        description: 'Size nodes by A measure of how close each node is to all other nodes.',
    },
    eigenCentrality: {
        name: 'Eigen Centrality',
        description: 'Size nodes by the number of links a node’s neighbors have.',
    },
    pageRank: {
        name: 'Page Rank',
        description: 'Size nodes by the number of links a node’s neighbors have, considering link direction.',
    },
    reset: {
        name: 'Nodes not Sized',
    },
};
const top = ['2', '5'];
const layouts = ['organic', 'standard', 'structural', 'lens', 'tweak', 'radial', 'sequential'];
const analyses = ['reset', 'engagement', 'degrees', 'betweenness', 'closeness', 'pageRank', 'eigenCentrality'];
const combinations = [
    { key: 'Not Grouped', label: 'Not Grouped' },
    { key: 'url', label: 'URL' },
    { key: 'hashtag', label: 'Hashtag' },
    { key: 'thread_id', label: 'Threat ID' },
    { key: 'ctype', label: 'Source' },
    { key: 'from', label: 'From' },
    { key: 'sentiment', label: 'Sentiment' },
    { key: 'locations_detected', label: 'Location' },
    { key: 'threat_level', label: 'Threat Level' }
];

export const Explore = ({ project }) => {
    const filters = useSelector((state) => state.filters.filters);
    const [search, setSearch] = useState('');
    const query = {
        bool: {
            filter: [
                {
                    match_phrase: {
                        project_id: {
                            query: project,
                        },
                    },
                },
                ...(filters.origin.length > 0 ? [
                    {
                        terms: {
                            locations_detected__keyword: filters.origin
                        }
                    }
                ] : []),
                ...(search !== '' ? [{
                    query_string: {
                        query: search,
                        fields: ['text', 'body', 'from']
                    }
                }] : []),
                ...(filters.query !== '' ? [{
                    query_string: {
                        query: filters.query,
                        fields: ['text', 'body', 'from']
                    }
                }] : []),
                ...(Object.keys(filters.dateRange).length > 0 ? [{
                    range: {
                        pubdatetime: {
                            gte: filters.dateRange.startDate,
                            lte: filters.dateRange.endDate
                        }
                    }
                }] : [])
            ],
            must_not: [
                ...(filters.sources.length > 0 ? [
                    {
                        terms: {
                            ctype: filters.sources
                        }
                    }
                ] : []),
                ...(filters.sentiment.length > 0 ? [
                    {
                        terms: {
                            sentiment: filters.sentiment
                        }
                    }
                ] : []),
                { terms: { from__keyword: ['John Doe', 'johndoe'] } }
            ]
        },
    };

    const {
        error,
        loading,
        data
    } = useExploreContent({
        query,
        limit: 2500,
        sort: 'engagement__desc'
    });
    const items = !data?.length ? '' : ConvertToReGraphFormat(data);
    const chartRef = useRef(null);
    const [chartState, setChartState] = useState({
        scores: undefined,
        type: undefined,
        message: null,
        filter: { author: false, hashtag: false, url: false },
        position: { x: 0, y: 0 },
        openCombos: {},
        combine: {},
        layout: { name: 'organic', tightness: 4 },
        colorBy: 'threat_level',
        sizeBy: 'reset',
        threshold: 0,
    });

    const handle = useFullScreenHandle();

    const runLayout = (name) => {
        setChartState((current) => ({ ...current, layout: { name, top } }));
    };

    const handleCheckBoxChange = (checked, id) => {
        setChartState((current) => ({
            ...current,
            filter: { ...current.filter, [id]: !checked },
        }));
    };

    const runCombo = async (type) => {
        const { combine } = chartState;
        const { level } = combine;
        if (type !== 'Not Grouped') {
            setChartState((current) => ({
                ...current,
                combine: { properties: [type], level: 1 },
                layout: { tightness: 2 },
                selection: {},
            }));
        } else if (level > 0) {
            setChartState((current) => ({
                ...current,
                combine: { ...combine, level: 0 },
                layout: { tightness: 4 },
                selection: {},
            }));
        }
    };

    const setScores = (type, newScores) => {
        // spread the layout state to run a new layout
        // this state change causes a re-render, which will call styledItems
        setChartState((current) => ({ ...current, type, scores: normalize(newScores), layout: { ...current.layout } }));
    };

    const analyze = async (type) => {
        if (type === 'reset') {
            setScores(undefined, undefined);
        } else if (type === 'engagement') {
            setScores(type, Object.fromEntries(Object.entries(items).map(([id, value]) => ([id, value.data?.engagement]))));
        } else {
            const scores = await ReGraph[type](items);
            setScores(type, scores);
        }
    };
    // methods for the toolbar
    const zoom = (inOrOut) => {
        if (chartRef.current !== null) {
            chartRef.current.zoom(inOrOut);
        }
    };
    const fit = (fitType) => {
        if (chartRef.current !== null) {
            chartRef.current.fit(fitType);
        }
    };
    const setSize = (e) => {
        setChartState((current) => ({ ...current, sizeBy: e }));
        return analyze(e);
    };

    const exportImage = () => chartRef.current.export({
        type: 'png',
        extents: 'view',
        fitTo: {
            width: 10000,
            height: 10000
        }
    }).then((image) => {
        image.download('ReGraph_export');
    }).catch((err) => {
        console.error('Regraph Export:', err.message);
    });

    return (
        <FullScreen handle={handle}>
            <div className="explore-page">
                <div className="regraph-container">
                    <ChartComponent chartState={chartState}
                        chartRef={chartRef}
                        setChartState={setChartState}
                        data={items}
                        loading={loading}
                        error={error}
                    />
                </div>
                <div className="explore-left">
                    <div className="regraph-toolbar d-flex flex-column">
                        <ToolbarItem title="Fit to window" icon={faExpand} onClick={() => fit('all')} />
                        <ToolbarItem title="Zoom in" icon={faPlusSquare} onClick={() => zoom('in')} />
                        <ToolbarItem title="Zoom out" icon={faMinusSquare} onClick={() => zoom('out')} />
                        <ToolbarItem title="Download" icon={faDownload} onClick={exportImage} />
                    </div>
                </div>

                <div className="explore-right">
                    <div className="controls">
                        <div className="selectors">
                            <p className="mb-2">
                                <a href="https://logically-intelligence.readme.io/docs/explore"
                                    target="_blank"
                                    rel="noreferrer"
                                >How do I use explore?
                                </a>
                            </p>
                            <SearchInput placeholder="Search" handleSearch={setSearch} />
                            <div className="control">
                                <label className="explore-label" htmlFor="sizeby">
                                    Choose node layout:
                                    <TooltipItem placement="bottom"
                                        id="nodelayout"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Node layout </strong>
                                                <p className="mb-0">
                                                    Node layout determines how the nodes are distributed.
                                                    Selecting the correct layout can help detangle links, detect patterns, anomalies
                                                    and clusters within a network.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#node-layout-options"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                                <select value={chartState.layout.name}
                                    type="select"
                                    name="layout"
                                    id="layout"
                                    className="round"
                                    onChange={(e) => runLayout(e.target.value)}
                                >
                                    {layouts.map((name) => (
                                        <option key={name} value={name}>
                                            {name.charAt(0).toUpperCase() + name.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="control">
                                <label className="explore-label" htmlFor="sizeby">
                                    Size nodes by:
                                    <TooltipItem placement="bottom"
                                        id="sizenodesby"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Size nodes by</strong>
                                                <p className="mb-0">
                                                    Nodes in the network are measured and sized based on your selection.
                                                    Sizing nodes can be useful to highlight which nodes have a greater
                                                    influence over the network.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#size-nodes-by-options"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                                <select value={chartState.sizeBy}
                                    type="select"
                                    name="sizeby"
                                    id="sizeby"
                                    className="round"
                                    onChange={(e) => setSize(e.target.value)}
                                >
                                    {analyses.map((name) => (
                                        <option key={name} value={name}>
                                            {analyseTerms[name].name}
                                        </option>
                                    ))}
                                </select>
                                {analyseTerms[chartState.sizeBy].description && (
                                    <div className="select-description">
                                        **{analyseTerms[chartState.sizeBy].description}
                                    </div>
                                )}
                            </div>
                            <div className="control">
                                <label className="explore-label" htmlFor="groupby">
                                    Cluster by:
                                    <TooltipItem placement="bottom"
                                        id="clusterby"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Cluster by</strong>
                                                <p className="mb-0">
                                                    Cluster nodes with similar properties,
                                                    such as what source the node is from,
                                                    or the threat level of the node.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#cluster-by"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                                <select className="round text-capitalize"
                                    type="select"
                                    name="groupby"
                                    id="groupby"
                                    onChange={(e) => runCombo(e.target.value)}
                                >
                                    {combinations.map(({ key, label }) => (
                                        <option value={key} key={key} className="text-capitalize">
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="control">
                                <label className="explore-label">Select minimum node connections;
                                    <TooltipItem placement="bottom"
                                        id="minimumnodeconnections"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Minimum node connections</strong>
                                                <p className="mb-0">
                                                    Filter out nodes by number of connections they have.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#search--filter"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                                <input type="range"
                                    min="0"
                                    max={10}
                                    value={chartState.threshold}
                                    onChange={({ target }) =>
                                        setChartState((current) => ({ ...current, threshold: target.value }))}
                                    step="1"
                                />
                                {chartState.threshold}
                            </div>

                            <div className="control control-first">
                                <ToggleSwitch id="author"
                                    checked={!chartState.filter.author}
                                    name="Author"
                                    onChange={(checked) => handleCheckBoxChange(checked, 'author')}
                                />
                                <label className="explore-switch-label">Include Authors & Mentions
                                    <TooltipItem placement="top"
                                        id="includeauthorsandmentions"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Authors and mentions</strong>
                                                <p className="mb-0">
                                                    Can display nodes representing the authors posting content
                                                    or mentions of them within the room.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#search--filter"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                            </div>

                            <div className="control">
                                <ToggleSwitch id="hashtag"
                                    checked={!chartState.filter.hashtag}
                                    name="Include Hashtags"
                                    onChange={(checked) => handleCheckBoxChange(checked, 'hashtag')}
                                />
                                <label className="explore-switch-label">Include Hashtags
                                    <TooltipItem placement="top"
                                        id="includehashtags"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Include hashtags</strong>
                                                <p className="mb-0">
                                                    Can display nodes representing the hashtags prevalent within the room.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#search--filter"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                            </div>

                            <div className="control">
                                <ToggleSwitch id="url"
                                    checked={!chartState.filter.url}
                                    name="Include Shared URLs"
                                    onChange={(checked) => handleCheckBoxChange(checked, 'url')}
                                />
                                <label className="explore-switch-label">Include Shared URLs
                                    <TooltipItem placement="top"
                                        id="sharedurls"
                                        content={(
                                            <div className="d-flex flex-column">
                                                <strong>Shared URLs</strong>
                                                <p className="mb-0">
                                                    Can display nodes representing the URLs being shared within the room.
                                                </p>
                                                <a href="https://logically-intelligence.readme.io/docs/explore#search--filter"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >Click here to read more.
                                                </a>
                                            </div>
                                        )}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FullScreen>
    );
};

const useKCores = (data) => {
    const [kCoreResult, setkCoreResult] = useState({});
    useEffect(() => {
        kCores(data).then(result => setkCoreResult(result));
    }, [data]);
    return { kCoreResult };
};

const ChartComponent = ({
    chartRef,
    data,
    error,
    loading,
    chartState
}) => {
    const { setModal } = useModal();
    const { kCoreResult } = useKCores(data);

    const [range, setRange] = useState(null);

    const filterItems = () => {
        const { values } = kCoreResult;
        const isWithinThreshold = (id) => has(values, id) && values[id] >= chartState.threshold;
        // filter for nodes meeting the kCoreValue criterion plus all links
        return pickBy(data, (item, id) => !isNode(item) || isWithinThreshold(id));
    };

    const state = {
        ...chartState,
        filteredItems: filterItems(),
        inRange: range
    };

    if (loading) return <Loading relative />;
    if (error) return `Error! ${error}`;

    // filter chart items based on the current filter state
    const filtered = (isInRange) => {
        const nodes = {};
        forEach(isInRange, (item) => {
            if (has(item, 'id1') && has(item, 'id2')) {
                nodes[item.id1] = true;
                nodes[item.id2] = true;
            }
        });
        const filteredA = pickBy(
            isEmpty(state.filteredItems) ? data : state.filteredItems,
            (value) => !has(value, 'type') || !state.filter[value.type]
        );
        const dataB = pickBy(filteredA, (value, key) => isInRange[key] || nodes[key]);
        return dataB;
    };

    const styledItems = () => mapValues(filtered(state.inRange), (item, id) => {
        const updatedItem = { ...item };
        if (isNode(item)) {
            if (state.colorBy === 'automation_score' && updatedItem.type === 'content') {
                updatedItem.color = getAutomation(updatedItem.data.automation_score);
            }
            if (state.colorBy === 'threat_level' && updatedItem.type === 'content') {
                updatedItem.color = getThreat(updatedItem?.data?.threat_object?.threat_level || updatedItem?.data?.threat_level);
            }

            if (state.scores) {
                // if we are in an analysis state then use the colors and sizes from that
                updatedItem.size = state.scores[id] * 15 + 2;
                if (updatedItem.type !== 'content') {
                    updatedItem.label.color = colorPicker(state.scores[id]);
                    updatedItem.fontIcon.color = colorPicker(state.scores[id]);
                }
            }

            if (item.type === 'author') {
                updatedItem.label.color = '#ffffff';
            }
        }
        return updatedItem;
    });

    // clear selection and remove the menu when clicking on the background
    const handleChartClick = ({ id }) => {
        if (!id) return null;
        const activeTagsLength = [id.includes('author_'), id.includes('content_'), id.includes('hashtag_')].filter(tag => tag).length;
        if (activeTagsLength > 1) { return null; }
        const [type, contentId] = id.split(/_(.*)/s);
        if (type === 'content') {
            setModal({
                component: <ContentModal id={contentId} contentType={CONTENT_TYPE.THREAT} />
            });
        }
        if (type === 'author') {
            const author = data[id].data;
            setModal({
                component: <AccountModal from={contentId} displayName={author.displayName} ctype={author.ctype} />
            });
        }
        if (type === 'hashtag') {
            setModal({
                size: 'xl',
                component: <HashtagModal hashtag={contentId} contentType={CONTENT_TYPE.THREAT} />
            });
        }
    };

    const setTimeRange = (change) => {
        if (change.items) {
            setRange(change.items);
        }
    };

    return (
        <>
            <div className="h-100 w-100">
                <FontLoader config={{ custom: { families: ['Font Awesome 5 Free', 'Font Awesome 5 Brands'] } }}>
                    <Chart ref={chartRef}
                        items={state.inRange ? styledItems() : {}}
                        layout={state.layout}
                        animation={{ animate: true, time: 750 }}
                        options={{
                            backgroundColor: '#030e3a',
                            iconFontFamily: 'Font Awesome 5 Free',
                            selection: {
                                color: 'rgb(81,159,165)',
                            },
                            navigation: false,
                            overview: false,
                            labels: { maxLength: 20 },
                            links: { avoidLabels: false, endSpacing: 'loose' },
                        }}
                        onClick={handleChartClick}
                        combine={state.combine}
                    />
                </FontLoader>
            </div>
            <div className="timebar">
                <TimeBar items={isEmpty(state.filteredItems) ? data : state.filteredItems}
                    style={{ width: '100%', height: '100px' }}
                    onChange={setTimeRange}
                    options={{
                        style: { color: '#f62459', hoverColor: '#931535' },
                        backgroundColor: '#030e3a',
                        scale: { hoverColor: '#020927' },
                        sliders: { color: 'rgba(0, 0, 0, 0.2)' },
                        mode: 'smooth',
                        labels: {
                            fontColor: '#E0E0E0',
                            fontSize: 12,
                        },
                    }}
                />
            </div>
        </>
    );
};
