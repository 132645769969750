import React, {
    useMemo,
    useState,
    useEffect,
    createContext,
    useContext,
    ReactNode
} from 'react';

type User = {
    instance?: {
        id: string;
        clientName?: string;
    };
    uid?: string;
    id?: string;
    situationRoomViewList?: string[];
    role?: string;
    photoURL?: string;
    displayName?: string;
    email?: string;
    preferences?: any;
    status?: any;
    lastSeen?: any;
    jobDescription?: string;
    bio?: string
};

type UserContextType = {
    user: User;
    instance?: string;
    situationRoomViewList: string[];
    isAdmin: boolean;
    notAllowed: string[];
    setUser: (user: User) => void;
};

type UserContextProviderProps = {
    children: ReactNode;
    user?: User;
};

type UserPermissionsProps = {
    feature: string;
    roleRequired: string[];
}[]
export const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserContextProvider');
    }
    return context;
};

export const UserContextProvider = ({ children, user = {} }: UserContextProviderProps) => {
    const [stateUser, setUser] = useState<User>(user);

    useEffect(() => {
        setUser(user);
    }, [user]);

    const contextValue = useMemo(() => ({
        user: stateUser,
        instance: user?.instance?.id || '',
        situationRoomViewList: user?.situationRoomViewList || [],
        isAdmin: user?.role === 'ADMIN' || false,
        notAllowed: userPermissions.filter(item => !item.roleRequired.includes(user?.role || '')).map(a => a.feature),
        setUser
    }), [user, stateUser]);

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

const userPermissions: UserPermissionsProps = [
    {
        feature: 'createRoom',
        roleRequired: ['ADMIN']
    },
    {
        feature: 'teamPage',
        roleRequired: ['ADMIN', 'TEAM']
    },
    {
        feature: 'investigations',
        roleRequired: ['ADMIN', 'TEAM']
    },
    {
        feature: 'createReport',
        roleRequired: ['ADMIN', 'TEAM']
    },
    {
        feature: 'savedReports',
        roleRequired: ['ADMIN', 'TEAM']
    },
    {
        feature: 'roomSettings',
        roleRequired: ['ADMIN', 'TEAM']
    },
    {
        feature: 'manageCustomNarratives',
        roleRequired: ['ADMIN', 'TEAM']
    },
    {
        feature: 'editData',
        roleRequired: ['ADMIN', 'TEAM']
    }
];
