import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { NotThreatSuccessModal } from './NotThreatSuccessModal';
import { useModal } from '../../context/modal/ModalComponent';
import { RemoveContentActions, useRemoveContent } from './useRemoveContent';
import { CONTENT_TYPE } from '../../utils/contentTypes';

type NotThreatIdentifierModalProps = {
    ids: string[]
    onCompleted?: () => void
}

export const NotThreatIdentifierModal = ({ ids, onCompleted }: NotThreatIdentifierModalProps) => {
    const { closeModal } = useModal();
    const [successModal, setSuccessModal] = useState(false);

    const { removeContent, loading } = useRemoveContent({
        type: CONTENT_TYPE.THREAT,
        action: RemoveContentActions.MarkNotAsThreat,
        onCompleted: () => {
            setSuccessModal(true);
            if (onCompleted) onCompleted();
        },
        ids
    });

    const handleOnCompleted = () => {
        closeModal();
        setSuccessModal(false);
    };

    const markAsNotThreat = () => removeContent(ids.map(id => ({ id })));

    return (
        <>
            <Modal isOpen toggle={closeModal}>
                <ModalBody>
                    <h2>Is this content not a threat?</h2>
                    <hr />
                    <p>
                        Mark the content as not a threat if you think that this content is not harmful.
                        This content will be moved to matches (you will still be able to find it in the Overview)
                        but you and your team won’t be able to see it in the threats section anymore.
                    </p>
                    <hr />
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button color="primary"
                        className="ml-2"
                        onClick={markAsNotThreat}
                        data-testid="confirm-not-threat"
                        disabled={loading}
                    >
                        Confirm
                    </Button>
                </ModalBody>
            </Modal>
            <NotThreatSuccessModal open={successModal} onClose={handleOnCompleted} />
        </>
    );
};
