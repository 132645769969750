import React, { useEffect, useState } from 'react';
import { AlertCircle, ChevronDown, ArrowLeft } from 'react-feather';
import {
    Alert,
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { TooltipItem } from '../../../components/ToolTip';
import { Filters } from './Filters';
import { Plots } from './Plots';
import { useModal } from '../../../context/modal/ModalComponent';
import { useDropDown } from '../../../utils/useDropdown';
import { UpdateClusters } from './UpdateClusters';
import { clustersMockData } from './UpdateClusters/clustersDataMock';

export const TopicInsights = ({ room, bread }) => {
    const history = useHistory();
    const [dropdown, toggleDropdown] = useDropDown();
    const { setModal, closeModal } = useModal();
    const [sort, setSort] = useState(dropdownItems[0]);
    const [settingsButtonOn, setSettingsButtonOn] = useState(false);
    const [filters, setFilters] = useState(false);
    const [subClusterData, setSubClusterData] = useState({});
    const [clustersData, setClustersData] = useState({});

    useEffect(() => {
        if (subClusterData?.name) {
            history.push(`/situation-rooms/${room.id}/topic-insights/${subClusterData.name}-subcluster`);
        }
        else {
            history.push(`/situation-rooms/${room.id}/topic-insights`);
        }
    }, [history, room.id, subClusterData]);
    const topicsData = clustersMockData;

    useEffect(() => {
        let clusterTagCount = 1;
        const newData = topicsData?.map(cluster => {
            const newCluster = { ...cluster };
            newCluster.cluster_tag = `C${clusterTagCount}`;
            let subClusterTagCount = 0;

            const subClusterList = newCluster.sub_cluster_info.map((subCluster) => {
                const subClusterTag = String.fromCharCode('a'.charCodeAt() + subClusterTagCount);
                const newSubCluster = { ...subCluster };
                newSubCluster.cluster_tag = `C${clusterTagCount}${subClusterTag}`;
                subClusterTagCount += 1;
                return newSubCluster;
            });
            newCluster.sub_cluster_info = subClusterList;
            clusterTagCount += 1;
            return newCluster;
        });

        setClustersData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateClusterModal = () => {
        setModal({
            component: <UpdateClusters closeModal={closeModal} data={clustersData} setMainClustersData={setClustersData} />
        });
    };

    const topicModellingData = topicsData;

    const clusterCount = subClusterData?.name ? subClusterData?.data?.length : topicModellingData?.length;
    return (
        <div className="page-padding">
            {bread}
            { subClusterData?.name ? (
                <>
                    <Button className="pb-3 go-back-from-dashboard text-decoration-none align-items-center"
                        color="link"
                        onClick={() => setSubClusterData({})}
                    >
                        <ArrowLeft size={18} className="mr-1" />
                        <span>Back to main cluster</span>
                    </Button>
                    <h3 className="mb-4">{subClusterData?.name} - Subclusters</h3>
                </>
            )
                : (
                    <><h2 className="pt-2">Topic insights</h2>
                        <Alert color="warning" className="mt-4">
                            <span><AlertCircle size="16" className="mr-1" color="#FCCD13" /></span>
                            When you update the cluster, the system will delete the clusters which are not renamed by the user.
                            It also removes their related keywords.
                        </Alert>
                        <p className="m-0 pt-3"><strong>Visualize and monitor the paths taken that led to creating this narrative: </strong>
                            Click on the main cluster to view associated word clouds on the right and respective data underneath.
                            Double click on the clusters to view the Sub-cluster. Use the edit icon to rename a cluster.
                            A minimum of at least 2 clusters have to be renamed before updating the cluster.
                        </p>
                        <div className="mt-3 mb-2 d-flex justify-content-between">
                            <h3 className="mb-2">Main clusters ({clusterCount})</h3>
                            <div>
                                <p role="button" className="d-inline text-primary mr-3">Reset clusters
                                    <TooltipItem placement="top"
                                        id="resetClusterTooltip"
                                        content="Reset clusters"
                                    />
                                </p>
                                <Button onClick={() => updateClusterModal()}>Update cluster</Button>
                                <Button onClick={() => setSettingsButtonOn(!settingsButtonOn)}
                                    className={`p-1 pl-2 text-left ml-2 ${filters && 'btn-has-filters'}`}
                                >
                                    Settings
                                    <ChevronDown width="16" height="16" className="ml-2" />
                                </Button>
                            </div>
                        </div>
                        <Filters isOpen={settingsButtonOn} setOpen={setSettingsButtonOn} setFilters={setFilters} />
                    </>
                )}
            <Row className="bg-white m-0 border-bottom">
                <Col xs={12} md={6} className="border-right py-2 ">
                    <h3 className="d-inline-block">Topic bubble plot</h3>
                    <TooltipItem placement="top"
                        id="bubblePlotTooltip"
                        content="Each bubble represents a topic/narative present in a situation room.
                         The distance between the bubbles represent how different/similar these topics are from each other,
                         and the size represents the number of documents present in each bubble."
                        testid="bubblePlotTooltip"
                    />
                </Col>
                <Col xs={12} md={6} className="py-2">
                    <div className="d-flex justify-content-between">
                        <p className="mb-0 mt-1"><strong>Word cloud </strong></p>
                        <div className="d-flex align-items-center">
                            <p className="w-100 m-0 mr-1">Content type</p>
                            <Dropdown isOpen={dropdown} toggle={toggleDropdown} className="set-max-content">
                                <DropdownToggle tag="div" className="cursor-pointer d-flex" data-testid="contentType-toggle">
                                    <div className="form-control">
                                        {sort.name}
                                        {' '}
                                        <span className="float-right ml-4">
                                            <ChevronDown color="#006FF9" size={18} />
                                        </span>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {dropdownItems.map((item) => (
                                        <DropdownItem key={item.key}
                                            active={sort.key === item.key}
                                            onClick={() => setSort(item)}
                                        >
                                            {item.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
            </Row>
            <Plots selectedCloud={sort}
                data={subClusterData?.name ? subClusterData?.data : topicModellingData}
                setSubClusterData={setSubClusterData}
                isSubCluster={!!subClusterData.name}
            />
        </div>
    );
};

const dropdownItems = [
    { key: 'all_keywords', name: 'All keywords' },
    { key: 'cooccurance_words', name: 'Co-occurence' },
    { key: 'bigrams', name: 'Bi-grams' },
    { key: 'trigrams', name: 'Tri-grams' },
    { key: 'all_entities', name: 'Entities' }

];
