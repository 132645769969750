import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CustomInput } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { AddToWatchlist } from '../../Narratives/Overview/Narratives/AddToWatchlist';
import { capitalizeFirstLetter, truncateString } from '../../../../utils/text';
import { TranslateIcon } from '../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../graphql';

export const WatchlistNarrativeItem = ({ narrative, selected, setSelected, room, originalPostData }) => {
    const history = useHistory();
    const { pathname } = history.location;
    const [isTranslated, setIsTranslated] = useState(false);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: narrative?.narrative_title, target: 'en' }
        },
    });
    const outputText = translatedData && isTranslated
        ? translatedData?.translateMultipleText[0]?.translations[0] : narrative?.narrative_title;
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };

    return (
        <tr key={narrative.id} className="bg-white">
            <td>
                <CustomInput type="checkbox"
                    checked={selected.includes(narrative.id)}
                    id={`narrative-${narrative.id}`}
                    onChange={() => {
                        if (selected.includes(narrative.id)) {
                            setSelected(selected.filter((a) => a !== narrative.id));
                        } else {
                            setSelected([...selected, narrative.id]);
                        }
                    }}
                />
            </td>
            <td>
                <Link to={`/situation-rooms/${room.id}/narratives/${narrative.id}/dashboard/`}>
                    <h3 className="text-primary m-0 d-inline from">
                        {pathname.includes('watchlist') ? truncateString(outputText, 250) : outputText}
                    </h3>
                    {narrative?.lang_verbose === 'English' ? null : (
                        <span role="button"
                            className="ml-2"
                            onClick={(e) => translation(e)}
                        >
                            <TranslateIcon color="#006FF9" size={30} />
                        </span>
                    )}
                </Link>
            </td>
            <td>
                {`${moment(new Date(originalPostData?.date)).format('DD/MM/YYYY, @ hh:mm:ss')}`}
            </td>
            <td>{narrative.volume}</td>
            <td>{narrative.engagement}</td>
            <td>{capitalizeFirstLetter(narrative.average_threat_label)}</td>
            {selected.length === 0 && (
                <td className="watchlist-table">
                    <AddToWatchlist watchlistTable narrativeId={narrative.id} />
                </td>
            )}
        </tr>

    );
};
