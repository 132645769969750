import React from 'react';
import { ClusterStats } from './ClusterStats';
import { VolumeOverTimeTypePlot } from './VolumeOverTimePlot';
import { calculateAverageSentiment } from '../../../../../utils/calculateAverageSentiment';

type SentimentType = {
    sentiment: string
    score: string
}

type VolumeOverTimeType = {
    time: string
    count: number
    date: string
}

type ClusterProps = {
    title: string
    subClusterTitle: string
    subClusterSummary: string
    description: string
    subClustersMappedId: string[]
    volume: number
    duplicatedDocsCount: number
    sentimentJson: [SentimentType]
    id: string
    volumeOverTime: [VolumeOverTimeType]
}

type SummaryProps = {
    clusterData: ClusterProps
    isParent: boolean
    setTab: (a: string) => void
}

export const Summary = ({ clusterData: cluster, isParent, setTab }: SummaryProps) => (
    <div className="bg-white p-2 maxh-420 scrollbar-small overflow-y-auto overflow-x-hidden">
        <p className="m-0 font-weight-bold">{isParent ? cluster.title : cluster.subClusterTitle}</p>
        <p className="pt-2">{isParent ? cluster.description : cluster.subClusterSummary}</p>
        <div className="cluster-stats-align mb-4">
            {isParent ? (
                <ClusterStats title="Subclusters"
                    icon="subClusters"
                    count={cluster.subClustersMappedId.length}
                    setTab={setTab}
                />
            ) : null }
            <ClusterStats title="Content" icon="contents" count={cluster.volume} />
            <ClusterStats title="Duplicates" icon="duplicates" count={cluster.duplicatedDocsCount} />
            <ClusterStats title="Sentiment"
                icon="sentiment"
                sentiments={cluster.sentimentJson}
                averageSentiment={calculateAverageSentiment(cluster.sentimentJson)}
                id={cluster.id}
            />
        </div>
        <p>Volume over time</p>
        <VolumeOverTimeTypePlot content={cluster.volumeOverTime} />
    </div>
);
