import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { integrationsRoutesLinks, configureRoutesLinks, sidebarRoutesLinks } from '../../routes';
import { SidebarMenu } from '../../components/Molecules/SidebarMenu';

export const Menu = ({ currentRoomLink, instanceId, children }) => {
    const { data } = useQuery(GET_INSTANCE_PLAN, {
        variables: { id: instanceId },
    });
    const instance = data?.instance || {
        plan: {
            core: {},
            integrations: {}
        }
    };
    const filterLinks = (links, plan) => links.filter(item => (plan ? plan[item.id] !== false : true))
        .map(link => ({
            ...link, path: currentRoomLink + link.path
        }));
    const menu = [
        { name: 'Core', links: filterLinks(sidebarRoutesLinks, instance.plan.core) },
        { name: 'Integrations', links: filterLinks(integrationsRoutesLinks, instance.plan.integrations) },
        { name: 'Configure', links: filterLinks(configureRoutesLinks, false) }
    ];
    return (
        <SidebarMenu menu={menu}>
            {children}
        </SidebarMenu>
    );
};

export const GET_INSTANCE_PLAN = gql`
    query instance($id: ID!) {
        instance(where: { id: $id }) {
            plan {
                core {
                    overview
                    threats
                    explore
                    logicallyFactChecks
                    narratives
                    aiClustering
                    networks
                }
                integrations {
                    investigations
                    reports
                    watchlist
                }
            }
        }
    }
`;
