import React from 'react';
import { TrafficSignal } from '../TrafficSignal';
import { SplineChart } from '../../../../components/Highcharts/Spline';

export const ComparisonChart = ({ type, comparisonMainData, narrativesData }) => {
    const seriesData = narrativesData.map(item => ({
        data: item.value.trend.buckets.map(content => ({
            x: content.key,
            y: type === 'engagement' ? content.engagement.value : content.doc_count
        })),
        color: item.color,
        visible: item.visible
    }));
    return (
        <div className="mt-3 d-flex flex-wrap align-items-center justify-content-between">
            <div style={{ width: comparisonMainData && comparisonMainData?.isEdit ? '80%' : '100%' }}>
                <SplineChart height={300} seriesData={seriesData} />
            </div>
            {comparisonMainData && comparisonMainData?.isEdit && (
                <div className="pr-5">
                    <TrafficSignal threatLabel={comparisonMainData?.averageThreatLevel} />
                </div>
            )}
        </div>
    );
};
