import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Input } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import savedSuccess from '../../../../../assets/images/savedSuccess.svg';
import { getRoom } from '../../../../../utils/variables';
import { GET_NARRATIVES } from '../../../../../graphql';

export const EditNarrativeTitle = ({ setNarrativeTitle, narrativeTitle, id }) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const [title, setTitle] = useState(narrativeTitle);
    const [error, setError] = useState(false);

    const [editNarrativeTitle, { loading }] = useMutation(EDIT_NARRATIVE_TITLE, {
        onCompleted: (resp) => {
            closeModal();
            if (resp?.alterNarrativeContent?.data?.narrative_title) {
                setModal({
                    component:
                        (
                            <>
                                <img src={savedSuccess} alt="successfully saved" />
                                <h2 className="from pt-3">Narrative title edited successfully.</h2>
                                <hr />
                                <Button onClick={closeModal}>
                                    Close
                                </Button>
                            </>
                        )
                });
            }
            else {
                onErrorSavingTitle();
            }

            setNarrativeTitle(resp?.alterNarrativeContent?.data?.narrative_title || narrativeTitle);
        },
        onError: () => {
            onErrorSavingTitle();
        },
        fetchPolicy: 'no-cache',
        refetchQueries: [GET_NARRATIVES]
    });

    const onErrorSavingTitle = () => {
        setModal({
            component: (
                <>
                    <h3>Unable to update the edited narrative title.</h3>
                    <hr />
                    <p>Please try again</p>
                    <hr />
                    <Button onClick={() => closeModal()}>Close</Button>
                </>
            )
        });
    };
    const onSaveHandler = () => {
        if (title?.trim() === '') {
            setError(true);
        }
        else {
            setError(false);
            editNarrativeTitle({
                variables: {
                    _id: id,
                    projectId: room?.project_id,
                    contentDocs: { narrative_title: title, _id: id, status: 'update' }
                }
            });
        }
    };
    return (
        <>
            <h2>Edit Narrative Title</h2>
            <hr />
            <label>
                Edit title ({title?.length}/300 characters)
            </label>
            <Input type="textarea"
                maxLength="300"
                data-testid="narrative-title-input"
                className="w-100"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <hr />
            <div className="d-flex">
                <Button onClick={() => {
                    closeModal();
                    setTitle(narrativeTitle); setError(false);
                }}
                >Cancel
                </Button>
                <Button color="primary"
                    aria-disabled={loading}
                    className="ml-2"
                    disabled={loading}
                    onClick={() => onSaveHandler()}
                >Save
                </Button>
            </div>
            {error && <p className="text-danger">Please enter a valid narrative title.</p>}
        </>
    );
};

export const EDIT_NARRATIVE_TITLE = gql`
    mutation alterNarrativeContent($_id: String, $projectId: String, $contentDocs: JSON){
        alterNarrativeContent(_id:$_id, projectId: $projectId, contentDocs:$contentDocs)
    }
`;

export const GET_NARRATIVE_CONTENT = gql`
    query getNarrativeContent($_id: String, $projectId: String){
        getNarrativeContent(_id:$_id, projectId: $projectId)
    }
`;
