import React from 'react';

export const EmptyResults = () => (
    <svg width="71" height="80" viewBox="0 0 71 80" fill="none">
        <path fill="#E5E6EB"
            d="M9.234 0a4.12 4.12 0 0 0-4.175 4.067v65.764a4.12 4.12 0 0 0 4.175 4.065h50.645a4.118
            4.118 0 0 0 4.175-4.065V10.302L53.306 0H9.234Z"
        />
        <path fill="#D2D4DB"
            d="M53.309 0v8.273a2.027 2.027 0 0 0 2.027 2.026h8.725L53.31 0Z"
        />
        <path fill="#F8F8F8"
            d="M18.104 9.55H1.997A1.997 1.997 0 0 0 0 11.548v16.107c0 1.103.894 1.997 1.997
            1.997h16.107a1.997 1.997 0 0 0 1.996-1.997V11.547a1.997 1.997 0 0 0-1.996-1.996Z"
        />
        <path fill="#D2D4DB"
            d="M15.813 15.69H4.284a.542.542 0 0 1 0-1.085h11.529a.542.542 0 0 1 0 1.084Zm0 2.851H4.284a.542.542
            0 1 1 0-1.084h11.529a.542.542 0 0 1 0 1.084Zm0 3.047H4.284a.542.542 0 0 1 0-1.084h11.529a.542.542
            0 0 1 0 1.084Zm0 2.852H4.284a.542.542 0 0 1 0-1.085h11.529a.542.542 0 0 1 0 1.084Z"
        />
        <path fill="#C8CACE"
            d="M54.34 42.9a19.407 19.407 0 1 0-26.963 11.173l-1.57 4.632a5.982 5.982 0 0 0-4.986 4.028l-3.13
            9.24c-1.047 3.089.492 6.523 3.55 7.652a5.988 5.988 0 0 0 7.753-3.685l3.17-9.359a5.981
            5.981 0 0 0-1.518-6.235l1.57-4.632A19.432 19.432 0 0 0 54.34 42.9Zm-32.205-9.925a14.308
            14.308 0 1 1 8.357 16.922 14.325 14.325 0 0 1-8.357-16.922Z"
        />
        <path fill="#F8F8F8"
            d="M68.264 44.543H52.157a1.997 1.997 0 0 0-1.997 1.997v16.107c0 1.102.894 1.996 1.997 1.996h16.107a1.997
            1.997 0 0 0 1.996-1.996V46.54a1.997 1.997 0 0 0-1.996-1.997Z"
        />
        <path fill="#D2D4DB"
            d="M60.216 48.997a4.438 4.438 0 0 1 2.578.77 2.674 2.674 0 0 1 .52 3.867c-.412.47-.89.877-1.422
            1.207a2.385 2.385 0 0 0-1.017 1.26c-.173.529-.633.735-1.188.622a1.004 1.004 0 0
            1-.756-1.023c.01-.477.204-.93.54-1.269a10.24 10.24 0 0 1
            1.1-.938c.195-.153.38-.318.554-.492.453-.468.572-1.04.34-1.55-.217-.468-.763-.744-1.392-.714a1.44
            1.44 0 0 0-1.361.896 7.34 7.34 0 0 1-.269.537 1 1 0 0 1-1.151.545.867.867
            0 0 1-.787-.956 2.353 2.353 0 0 1 .822-1.7 3.95 3.95 0 0 1 2.89-1.062Zm-.25
            11.203a1.248 1.248 0 1 1 .019-2.496 1.248 1.248 0 0 1-.02 2.496Z"
        />
    </svg>

);
