import React from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../../../../../../components/Loading';
import { Table } from '../../../../../../../components/Table';
import { useTopSharedUrls } from '../../../../../../../services/Overview';
import { truncateString } from '../../../../../../../utils/text';

export const TopSharedUrls = ({ className, small }) => {
    const { report } = useSelector(state => state.createReport);
    const { data, loading } = useTopSharedUrls({
        dateFilter: report.filters
    });

    if (loading) {
        return <Loading relative height={200} />;
    }
    return (
        <Table columns={topSharedUrlsTablecolumns}
            data={data.map(url => ({
                ...url,
                key: truncateString(url.key, 35, false)
            }))}
            condensed={`p-${small ? 1 : 2}`}
            className={className}
        />
    );
};

const topSharedUrlsTablecolumns = [
    {
        name: 'URL',
        field: 'url'
    },
    {
        name: 'Shared',
        field: 'count'
    }
];
