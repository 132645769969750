import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import isValidDomain from 'is-valid-domain';
import { getCtype } from '../../../../utils/text';
import { convertToDate } from '../../../../utils/getTimeFrom';

const colors = {
    transparent: 'rgba(0,0,0,0)',
    white: 'rgb(255,255,255)',
    background: 'rgb(46,56,66)',
    red: 'rgb(255,0,0)',
};

const makeNode = (node) => ({
    label: {
        text: node.from,
        backgroundColor: colors.background,
        color: colors.white,
        fontSize: 10,
    },
    cutout: true,
    data: { ...node },
});

const createContentNode = (node) => {
    const { icon, FontFamily } = getCtype(node.ctype);
    const coordinates = node.latlon_coords ? node.latlon_coords.split(',') : ['', ''];
    return {
        label: { text: node.date },
        type: 'content',
        data: { ...node },
        fontIcon: { text: icon, color: '#030e3a', fontFamily: FontFamily },
        coordinates: {
            lat: parseInt(coordinates[0], 10),
            lng: parseInt(coordinates[1], 10),
        },
        color: '#fff',
    };
};

const createAuthorNode = ({ node, mention }) => {
    if (mention !== null) {
        return {
            label: { text: mention, center: false, backgroundColor: '#030e3a' },
            type: 'author',
            color: 'rgba(0,0,0,0)',
            fontIcon: {
                text: 'fa-user',
                color: '#34526f',
            },
            data: {
                mentions: 1,
                engagement: node.engagement,
                post: 0,
            },
        };
    }
    const icon = isValidDomain(node.from) ? 'fa-globe' : 'fa-user';
    return {
        label: { text: node.from, center: false, backgroundColor: '#030e3a' },
        type: 'author',
        color: 'rgba(0,0,0,0)',
        fontIcon: {
            text: icon,
            color: '#fff',
        },
        data: {
            mentions: 0,
            engagement: node.engagement,
            posts: 1,
            displayName: node.display_name,
            ctype: node.ctype
        },
    };
};
const createHashtagNode = (node) => ({
    label: {
        text: node, center: false, color: '#b3c8db', fontSize: 20, backgroundColor: '#030e3a',
    },
    color: '#030e3a',
    data: {
        hashtag: node,
    },
    fontIcon: {
        text: 'fa-hashtag',
        color: '#b3c8db',
    },
    type: 'hashtag',
    size: 0.5,
});
const updateAuthorNode = ({ node, previous, mention }) => {
    if (mention !== undefined) {
        return {
            ...previous,
            data: {
                ...previous.data,
                mentions: previous.data.mentions + 1,
                engagement: previous.data.engagement + node.engagement,
            },
        };
    }
    return {
        ...previous,
        data: {
            ...previous.data,
            engagement: previous.data.engagement + node.engagement,
            posts: previous.data.mentions + 1,
        },
    };
};

export const ConvertToReGraphFormat = (content) => {
    if (content.length === 0) {
        const text = 'Could not load data';
        return { error: makeNode({ text, color: colors.red }) };
    }

    const Content = content.filter(contentItem => contentItem.from).map(contentItem => ({
        ...contentItem,
        from: contentItem.from.split(',').map(a => a.trim()).filter(a => a).join(', ')
    }));

    const items = {};
    if (Content != null) {
        for (const node of Content) {
            items[`content_${node.id}`] = createContentNode(node);
            if (node.hashtags != null) {
                // We lower case the found hashtags to prevent duplicate nodes like
                // "#javascript" and "#JavaScript"
                const hashtagsArray = node.hashtags.map((hashtag) => hashtag?.toLowerCase());

                for (const hashtag of hashtagsArray) {
                    if (items[`hashtag_${hashtag}`] == null) {
                        items[`hashtag_${hashtag}`] = createHashtagNode(hashtag);
                    }
                    items[`content_${node.id}_hashtag_${hashtag}`] = {
                        id1: `content_${node.id}`,
                        id2: `hashtag_${hashtag}`,
                        color: '#34526f',
                        times: [{
                            time: convertToDate(node.pubdatetime),
                        }],

                    };
                }
            }

            if (node.from != null) {
                if (items[`author_${node.from}`] == null) {
                    items[`author_${node.from}`] = createAuthorNode({
                        node,
                        mention: null,
                    });
                }
                items[`author_${node.from}`] = updateAuthorNode({
                    node,
                    previous: items[`author_${node.from}`],
                });
                items[`author_${node.from}_content_${node.id}`] = {
                    id1: `author_${node.from}`,
                    id2: `content_${node.id}`,
                    end2: {
                        arrow: true,
                    },
                    lineStyle: 'dotted',
                    label: {
                        text: 'Posted',
                        bold: true,
                        backgroundColor: '#030e3a',
                        color: '#fff',
                    },
                    color: '#fff',

                    times: [{
                        time: convertToDate(node.pubdatetime),
                    }],
                };
            }
            if (node.mentions != null) {
                // We lower case the found hashtags to prevent duplicate nodes like
                // "#javascript" and "#JavaScript"

                for (const mention of node.mentions) {
                    if (items[`author_${mention}`] == null) {
                        items[`author_${mention}`] = createAuthorNode({ node, mention });
                    }
                    items[`author_${mention}`] = updateAuthorNode({ node, previous: items[`author_${mention}`], mention });
                    items[`content_${node.id}_author_${mention}`] = {
                        id1: `content_${node.id}`,
                        id2: `author_${mention}`,
                        end2: {
                            arrow: true,
                        },
                        fade: true,
                        label: {
                            text: 'Mentions',
                            backgroundColor: '#030e3a',
                            color: '#fff',
                        },
                        lineStyle: 'dotted',
                        color: '#fff',
                        times: [{
                            time: convertToDate(node.pubdatetime),
                        }],
                    };
                }
            }
        }
    }

    return items;
};
