import React from 'react';
import { Button } from 'reactstrap';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { GET_NARRATIVES, CONTENT_ES_SERVICE } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { elasticFilterWithProjectId } from '../../../../utils/elasticFilterWithProjectId';

export const NarrativesWatchlistCsv = ({ vars, sort }) => {
    const room = getRoom();
    const { data: narrativeData } = useQuery(GET_NARRATIVES, {
        onCompleted: () => {
            const data = narrativeData?.newNarrativeServiceSearch?.hits.map((a) => ({
                id: a._id,
                ...a._source,
            })) || [];
            const originalPostIds = data.map((a) => a.original_post);
            getOriginalPost(originalPostIds);
        },
        variables: {
            limit: 1000,
            sort: sort.key,
            skip: 0,
            query: {
                bool: vars,
            },
        },
        fetchPolicy: 'no-cache'
    });

    const getOriginalPost = (originalPostIds) => {
        getOriginalPostData({
            variables: {
                limit: 1000,
                skip: 0,
                opts: { trackTotalHits: true },
                query: elasticFilterWithProjectId([{
                    ids: {
                        values: originalPostIds
                    }
                }])
            },
            skip: !originalPostIds
        });
    };

    const [getOriginalPostData, { data: originalPost }] = useLazyQuery(CONTENT_ES_SERVICE, {
        fetchPolicy: 'no-cache'
    });

    const ExportCsvFile = () => {
        const postData = originalPost ? originalPost.contentServiceSearch.hits.map(a => ({
            id: a._id,
            ...a._source
        })) : [];

        const csvExporter = new ExportToCsv({
            title: `${room?.project_name}_NarrativesWatchlist`,
            useKeysAsHeaders: true,
            filename: `${room?.project_name}_NarrativesWatchlist`,
            useBlanksForUndefined: true
        });

        csvExporter.generateCsv(narrativeData?.newNarrativeServiceSearch?.hits?.map((item, index) => {
            const likesCount = Number.isNaN(Number(postData[index]?.likes_count)) ? -1 : Number(postData[index]?.likes_count);
            const repliesCount = Number.isNaN(Number(postData[index]?.replies_count)) ? -1 : Number(postData[index]?.replies_count);
            const sharesCount = Number.isNaN(Number(postData[index]?.shares_count)) ? -1 : Number(postData[index]?.shares_count);
            const viewsCount = Number.isNaN(Number(postData[index]?.views_count)) ? -1 : Number(postData[index]?.views_count);
            const engagementCount = Number.isNaN(Number(item?._source.engagement)) ? -1 : Number(item?._source.engagement);

            return {
                narrative_title: item?._source.narrative_title,
                original_post_date: postData[index]?.date,
                volume: item?._source.volume,
                threat_level: item?._source.average_threat_label,
                content_url: postData[index]?.url,
                engagement: engagementCount < 0 ? 'NA' : engagementCount,
                likes: likesCount < 0 ? 'NA' : likesCount,
                replies: repliesCount < 0 ? 'NA' : repliesCount,
                shares: sharesCount < 0 ? 'NA' : sharesCount,
                views: viewsCount < 0 ? 'NA' : viewsCount
            };
        }));
    };

    return (
        <Button className="set-button-loader ml-2 mb-2 w-1 h-1"
            onClick={ExportCsvFile}
        >
            Download as CSV
        </Button>
    );
};
