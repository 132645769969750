import moment from 'moment';
import React from 'react';
import { Edit, Trash2 } from 'react-feather';
import { Button } from 'reactstrap';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useModal } from '../../../context/modal/ModalComponent';
import { DataProps, GET_ROOM_FILTERS, RoomFilterProps } from '../FiltersLayout';
import iconCheckCircle from '../../../assets/images/iconCheckCircle.svg';

type SavedFilterRowProps = {
    filter: RoomFilterProps,
    handleEditSavedFilter: (e: string) => void
}

export const SavedFilterRow = ({ filter, handleEditSavedFilter }: SavedFilterRowProps) => {
    const { setModal, closeModal } = useModal();
    const [deleteRoomFIlter, { loading }] = useMutation(DELETE_ROOM_FILTER, {
        refetchQueries: [GET_ROOM_FILTERS],
        onCompleted: () => {
            closeModal();
            toast.success('Filters deleted successfully', {
                icon: <img src={iconCheckCircle} />,
                type: 'success'
            });
        }
    });
    const handleDeleteRoomFilter = (id: string) => {
        deleteRoomFIlter({
            variables: {
                id
            }
        });
    };

    const handleFilterDelete = (name: string, id: string) => {
        setModal({
            component: (
                <div>
                    <h2>Delete room filter</h2>
                    <hr />
                    <p>Are you sure want to delete saved filter?</p>
                    <hr />
                    <div className="d-flex">
                        <Button color="secondary mr-2" onClick={closeModal} disabled={loading}>Cancel</Button>
                        <Button color="danger" onClick={() => handleDeleteRoomFilter(id)} disabled={loading}>Delete</Button>
                    </div>
                </div>)
        });
    };
    const contentItems = filter.data.filter((a) => a.items.length > 0);

    return (
        <>
            <td className="">
                {filter.name}
            </td>
            <td>
                {filter?.user?.displayName}
            </td>
            <td>{moment(filter.createdAt).format('DD/MM/YYYY')}</td>

            <td>{contentItems.map((filterItem: DataProps) => (
                <p className="filter-item mr-1" key={filterItem.filter}>{
                    `${filterItem.filter} (${filterItem.items.length})`
                }
                </p>
            ))}
            </td>
            <td>
                <div className="d-flex w-100">
                    <Edit size={20}
                        className="d-block mr-1 cursor-pointer"
                        color="#006FF9"
                        onClick={() => handleEditSavedFilter(filter.id)}
                    />

                    <div className="cursor-pointer" onClick={() => handleFilterDelete(filter.name, filter.id)} data-testid="delete-filter">
                        <Trash2 size={20} className="d-block" color="#006FF9" />
                    </div>
                </div>

            </td>

        </>
    ); };

export const DELETE_ROOM_FILTER = gql`
    mutation deleteRoomFilter( $id: ID) {
        deleteRoomFilter(id: $id) {
            id
        }
    }
    `;
