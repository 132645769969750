import React from 'react';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { useContent } from '../../../../../services/Content/getContent';

export const OriginalPost = ({ spacing, id }) => {
    const { data } = useContent({
        filters: {
            ids: id
        },
        name: 'getNarrativeOriginalPost',
        fragments: ['Metadata', 'Document', 'Engagement']
    });
    const post = data[0];
    if (!post) { return null; }
    return (
        <div className={`h-100 border rounded p-${spacing}`}>
            <p className={`mb-${spacing}`}>Original poster account</p>
            <SourceIconLabel data={post} className={`text-dark font-weight-bold mb-${spacing}`} />
            <p className="mb-0">{post.engagement} engagement</p>
        </div>
    );
};
