import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { FiltersDataProps, SAVE_ROOM_FILTERS } from '.';
import { useUser } from '../../../../context/user/UserContext';
import iconCheckCircle from '../../../../assets/images/iconCheckCircle.svg';
import { GET_ROOM_FILTERS } from '../../FiltersLayout';

type SaveFilterModalProps = {
    filtersData: FiltersDataProps[],
    roomId: string,
    closeModal: () => void,
    dateRange: {
        startDate?: number,
        endDate?: number
    }
}

export const SaveFilterModal = ({ filtersData, roomId, closeModal, dateRange }: SaveFilterModalProps) => {
    const [filterName, setFilterName] = useState('');
    const { user } = useUser();

    const [saveFilters, { data, loading }] = useMutation(SAVE_ROOM_FILTERS, {
        refetchQueries: [GET_ROOM_FILTERS],
        onCompleted: () => {
            toast.success('Filters saved', {
                icon: <img src={iconCheckCircle} />,
                type: 'success'
            });
            closeModal();
        },
        onError: () => {
            toast.error('Filters not saved');
            console.error('Filters not saved', data);
        }
    });
    const handleSaveFilter = () => {
        saveFilters({ variables: {
            data: {
                name: filterName,
                situationRoom: roomId,
                data: filtersData,
                user: user.id,
                dateRange
            }
        } });
    };
    return (
        <div>
            <p className="mt-3">Enter filter name</p>
            <Input data-testid="filter-name" onChange={(e) => setFilterName(e.target.value)} />
            <hr className="mt-3 mb-3" />
            <div className="d-flex">
                <Button className="mr-2" onClick={closeModal} disabled={loading}>Cancel</Button>
                <Button color="primary" onClick={handleSaveFilter} disabled={loading} data-testid="saveFilter-button">Save filter</Button>
            </div>
        </div>
    );
};
