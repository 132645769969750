import { createSlice } from '@reduxjs/toolkit';
import { Chart } from 'regraph';
import { isEqual, uniq, omit, pick } from 'lodash';
import undoable from 'redux-undo';

export type DateRange = {
    startDate: Date | number | null
    endDate: Date | number | null
}

export type NetworksState = {
    regraphLoading: boolean
    selection: string[]
    chartKey: number
}

export type NetworksHistoryState = {
    filters: {
        date?: DateRange
        search: string
    },
    deletedNodes: string[]
    layout: Chart.LayoutOptions['name']
    chartState: any
    includeAuthorsAndMentions: boolean
    includeHashtags: boolean
    includeSharedURLs: boolean
    minimumNodeConnections: number
    sizeNodesBy?: 'engagement' | 'degrees' | 'betweenness' | 'pageRank' | 'eigenCentrality' | undefined | null
    clusterBy?: 'url' | 'hashtag' | 'source' | 'from' | 'sentiment' | 'threatLevel' | undefined | null
}

export const settingsFields = [
    'layout',
    'includeAuthorsAndMentions',
    'includeHashtags',
    'includeSharedURLs',
    'minimumNodeConnections',
    'sizeNodesBy',
    'clusterBy'
];

export const initialState: NetworksState = {
    regraphLoading: true,
    selection: [],
    chartKey: 1
};

export type NetworksHistoryStore = {
    networksHistory: {
        past: NetworksHistoryState[]
        present: NetworksHistoryState
        future: NetworksHistoryState[]
    }
}

export const networksHistoryInitialState = {
    filters: {
        search: '',
    },
    chartState: {
        positions: {}
    },
    deletedNodes: [],
    layout: 'organic',
    includeAuthorsAndMentions: true,
    includeHashtags: false,
    includeSharedURLs: false,
    minimumNodeConnections: 0
} as NetworksHistoryState;

const networksSlice = createSlice({
    name: 'networks',
    initialState,
    reducers: {
        setRegraphLoading: (state, { payload }) => ({
            ...state,
            regraphLoading: payload
        }),
        setSelection: (state, { payload }) => ({
            ...state,
            selection: payload
        }),
        incrementChartKey: (state) => ({
            ...state,
            chartKey: state.chartKey + 1
        })
    }
});

const networksHistorySlice = createSlice({
    name: 'networksHistory',
    initialState: {
        ...networksHistoryInitialState,
        minimumNodeConnections: 1
    },
    reducers: {
        updateNetworks: (state, { payload }) => {
            const newState = {
                ...state,
                ...payload,
                ...(payload.deletedNodes ? { deletedNodes: uniq([...state.deletedNodes, ...payload.deletedNodes]) } : {}),
                chartState: {
                    ...state.chartState,
                    ...payload.chartState
                }
            };
            if (isEqual(state, newState)) return state;
            return newState;
        },
        resetNetworks: () => networksHistoryInitialState,
        setSearch: (state, { payload }) => ({
            ...state,
            filters: {
                ...state.filters,
                search: payload.search
            }
        }),
        resetFilters: (state) => ({
            ...state,
            filters: {
                ...state.filters
            }
        }),
        updateSettings: (state, { payload }) => ({
            ...state,
            ...payload,
            chartState: {
                positions: {}
            }
        }),
        resetSettings: (state) => ({
            ...omit(state, settingsFields),
            ...pick(networksHistoryInitialState, settingsFields) as NetworksHistoryState,
            chartState: {
                positions: {}
            }
        })
    }
});

export const { resetFilters, updateNetworks, resetNetworks, updateSettings, setSearch, resetSettings } = networksHistorySlice.actions;
export const networksHistory = undoable(networksHistorySlice.reducer, {
    limit: 20,
    filter: (action, currentState, { present }: { present: any }) => {
        if (
            !isEqual(present?.filters, currentState.filters)
            || !isEqual(present?.deletedNodes, currentState.deletedNodes)
            || !isEqual(pick(present, settingsFields), pick(currentState, settingsFields))
        ) {
            return true;
        }
        return (currentState?.chartState as any)?.why !== 'auto';
    }
});

export const { setRegraphLoading, setSelection, incrementChartKey } = networksSlice.actions;
export const networks = networksSlice.reducer;
