import { getRoom } from './variables';

export const elasticFilterWithProjectId = (filters = []) => {
    const room = getRoom();
    return {
        bool: {
            filter: [
                ...(room?.project_id ? [{
                    match_phrase: {
                        project_id: {
                            query: room.project_id
                        }
                    }
                }] : []),
                ...filters
            ]
        }
    };
};
