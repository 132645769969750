import React from 'react';

export const DeleteIcon = () => (
    <svg width="72" height="80" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_969_950)">
            <path fill="#D2D4DB"
                d="M64.5542 15.4655L28.4855 10.4669C26.0584 10.1306 23.8181 11.8255 23.4818 14.2526L17.7641 55.5106C17.4278
             57.9377 19.1227 60.1779 21.5498 60.5142L57.6185 65.5128C60.0456 65.8491 62.2858 64.1543 62.6221 61.7272L68.3398
             20.4692C68.6762 18.0421 66.9813 15.8018 64.5542 15.4655Z"
            />
            <path fill="#E5E6EB"
                d="M4.2918 0.00015717C3.73561 -0.00750215 3.18332 0.094545 2.66657 0.300408C2.14981 0.506271
            1.67873 0.811946 1.28016 1.19996C0.881589 1.58798 0.563372 2.05073 0.343719 2.56178C0.124065 3.07283 0.00726635
            3.62211 0 4.17831V71.7776C0.00697596 72.334 0.123542 72.8836 0.343066 73.3949C0.56259 73.9062 0.880743 74.3692
            1.27934 74.7574C1.67794 75.1457 2.14918 75.4515 2.66608 75.6575C3.18298 75.8635 3.73542 75.9656 4.2918
            75.9579H56.3483C56.9046 75.9656 57.4571 75.8635 57.974 75.6575C58.4909 75.4515 58.9621 75.1457 59.3607
            74.7574C59.7593 74.3692 60.0775 73.9062 60.297 73.3949C60.5165 72.8836 60.6331 72.334 60.6401 71.7776V10.5871L49.5897
            0.00015717H4.2918Z"
            />
            <path fill="#D2D4DB"
                d="M41.1753 46.3897H20.4516C20.3039 46.3897 20.1622 46.3309 20.0577 46.2265C19.9532 46.122
            19.8945 45.9803 19.8945 45.8326C19.8945 45.6848 19.9532 45.5431 20.0577 45.4387C20.1622 45.3342 20.3039 45.2755
            20.4516 45.2755H41.1753C41.323 45.2755 41.4647 45.3342 41.5692 45.4387C41.6737 45.5431 41.7324 45.6848 41.7324
            45.8326C41.7324 45.9803 41.6737 46.122 41.5692 46.2265C41.4647 46.3309 41.323 46.3897 41.1753 46.3897Z"
            />
            <path fill="#D2D4DB"
                d="M35.6938 49.5473H20.4497C20.3019 49.5473 20.1602 49.4886 20.0557 49.3841C19.9513 49.2797 19.8926 49.1379 19.8926
             48.9902C19.8926 48.8424 19.9513 48.7007 20.0557 48.5963C20.1602 48.4918 20.3019 48.4331 20.4497 48.4331H35.6938C35.8415
             48.4331 35.9833 48.4918 36.0877 48.5963C36.1922 48.7007 36.2509 48.8424 36.2509 48.9902C36.2509 49.1379 36.1922
             49.2797 36.0877 49.3841C35.9833 49.4886 35.8415 49.5473 35.6938 49.5473Z"
            />
            <path fill="#D2D4DB"
                d="M30.8137 52.4909H20.4497C20.3019 52.4909 20.1602 52.4322 20.0557 52.3277C19.9513 52.2232 19.8926
            52.0815 19.8926 51.9338C19.8926 51.786 19.9513 51.6443 20.0557 51.5399C20.1602 51.4354 20.3019 51.3767 20.4497
            51.3767H30.8137C30.9615 51.3767 31.1032 51.4354 31.2077 51.5399C31.3121 51.6443 31.3708 51.786 31.3708 51.9338C31.3708
            52.0815 31.3121 52.2232 31.2077 52.3277C31.1032 52.4322 30.9615 52.4909 30.8137 52.4909Z"
            />
            <path fill="#D2D4DB"
                d="M14.7135 45.2311H9.22288C8.66292 45.2311 8.20898 45.685 8.20898 46.245V51.7356C8.20898 52.2956 8.66292 52.7495
             9.22288 52.7495H14.7135C15.2735 52.7495 15.7274 52.2956 15.7274 51.7356V46.245C15.7274 45.685 15.2735 45.2311 14.7135
             45.2311Z"
            />
            <path fill="#D2D4DB"
                d="M34.798 58.1756H20.4497C20.3019 58.1756 20.1602 58.1169 20.0557 58.0124C19.9513 57.9079 19.8926
            57.7662 19.8926 57.6185C19.8926 57.4707 19.9513 57.329 20.0557 57.2246C20.1602 57.1201 20.3019 57.0614 20.4497
            57.0614H34.798C34.9457 57.0614 35.0875 57.1201 35.1919 57.2246C35.2964 57.329 35.3551 57.4707 35.3551 57.6185C35.3551
            57.7662 35.2964 57.9079 35.1919 58.0124C35.0875 58.1169 34.9457 58.1756 34.798 58.1756Z"
            />
            <path fill="#D2D4DB"
                d="M31.0031 61.3332H20.4497C20.3019 61.3332 20.1602 61.2745 20.0557 61.17C19.9513 61.0655 19.8926
            60.9238 19.8926 60.7761C19.8926 60.6283 19.9513 60.4867 20.0557 60.3822C20.1602 60.2777 20.3019 60.219 20.4497
            60.219H31.0031C31.1509 60.219 31.2926 60.2777 31.397 60.3822C31.5015 60.4867 31.5602 60.6283 31.5602 60.7761C31.5602
            60.9238 31.5015 61.0655 31.397 61.17C31.2926 61.2745 31.1509 61.3332 31.0031 61.3332Z"
            />
            <path fill="#D2D4DB"
                d="M27.6249 64.2766H20.4497C20.3019 64.2766 20.1602 64.2179 20.0557 64.1134C19.9513 64.009
            19.8926 63.8673 19.8926 63.7196C19.8926 63.5718 19.9513 63.4301 20.0557 63.3256C20.1602 63.2212 20.3019 63.1625
            20.4497 63.1625H27.6249C27.7727 63.1625 27.9144 63.2212 28.0189 63.3256C28.1234 63.4301 28.182 63.5718 28.182
            63.7196C28.182 63.8673 28.1234 64.009 28.0189 64.1134C27.9144 64.2179 27.7727 64.2766 27.6249 64.2766Z"
            />
            <path fill="#D2D4DB"
                d="M51.6956 33.5032H8.81099C8.66324 33.5032 8.52154 33.4445 8.41706 33.3401C8.31259 33.2356
            8.25391 33.0939 8.25391 32.9461C8.25391 32.7984 8.31259 32.6567 8.41706 32.5522C8.52154 32.4477 8.66324 32.389 8.81099
            32.389H51.6956C51.8433 32.389 51.985 32.4477 52.0895 32.5522C52.194 32.6567 52.2527 32.7984 52.2527 32.9461C52.2527
            33.0939 52.194 33.2356 52.0895 33.3401C51.985 33.4445 51.8433 33.5032 51.6956 33.5032Z"
            />
            <path fill="#D2D4DB"
                d="M40.3533 36.6607H8.81099C8.66324 36.6607 8.52154 36.602 8.41706 36.4975C8.31259 36.3931 8.25391
            36.2513 8.25391 36.1036C8.25391 35.9558 8.31259 35.8142 8.41706 35.7097C8.52154 35.6052 8.66324 35.5465
            8.81099 35.5465H40.3533C40.501 35.5465 40.6427 35.6052 40.7472 35.7097C40.8517 35.8142 40.9104 35.9558 40.9104
            36.1036C40.9104 36.2513 40.8517 36.3931 40.7472 36.4975C40.6427 36.602 40.501 36.6607 40.3533 36.6607Z"
            />
            <path fill="#D2D4DB"
                d="M30.2544 39.6044H8.81099C8.66324 39.6044 8.52154 39.5457 8.41706 39.4412C8.31259 39.3367 8.25391
            39.1951 8.25391 39.0473C8.25391 38.8996 8.31259 38.7579 8.41706 38.6534C8.52154 38.5489 8.66324 38.4902 8.81099
            38.4902H30.2544C30.4022 38.4902 30.5439 38.5489 30.6483 38.6534C30.7528 38.7579 30.8115 38.8996 30.8115 39.0473C30.8115
            39.1951 30.7528 39.3367 30.6483 39.4412C30.5439 39.5457 30.4022 39.6044 30.2544 39.6044Z"
            />
            <path fill="#D2D4DB"
                d="M14.6804 64.4907H9.25666C8.9909 64.4901 8.73615 64.3843 8.54823 64.1964C8.3603 64.0084 8.25449
            63.7537 8.25391 63.488V58.0642C8.25449 57.7984 8.3603 57.5437 8.54823 57.3558C8.73615 57.1679 8.9909 57.062 9.25666
            57.0614H14.6804C14.9462 57.062 15.2009 57.1679 15.3889 57.3558C15.5768 57.5437 15.6826 57.7984 15.6832
            58.0642V63.488C15.6826 63.7537 15.5768 64.0084 15.3889 64.1964C15.2009 64.3843 14.9462 64.4901 14.6804 64.4907ZM9.36808
            63.3766H14.569V58.1756H9.36808V63.3766Z"
            />
            <path fill="#D2D4DB"
                d="M31.4818 10.0743H10.1476C9.40915 10.0743 8.81055 10.6729 8.81055 11.4114V25.8065C8.81055 26.5449
            9.40915 27.1435 10.1476 27.1435H31.4818C32.2202 27.1435 32.8188 26.5449 32.8188 25.8065V11.4114C32.8188 10.6729 32.2202
            10.0743 31.4818 10.0743Z"
            />
            <path fill="#D2D4DB"
                d="M49.5898 -0.00012207V8.5033C49.5898 9.05588 49.8093 9.5858 50.2001 9.97653C50.5908 10.3673 51.1207
            10.5868 51.6733 10.5868H60.6402L49.5898 -0.00012207Z"
            />
            <path fill="#C8CACE"
                d="M56.674 80C64.8409 80 71.4614 73.3795 71.4614 65.2126C71.4614 57.0458 64.8409 50.4253 56.674
            50.4253C48.5072 50.4253 41.8867 57.0458 41.8867 65.2126C41.8867 73.3795 48.5072 80 56.674 80Z"
            />
            <path fill="#F8F8F8"
                d="M11.3001 50.7751C11.2268 50.7749 11.1542 50.7603 11.0865 50.732C11.0188 50.7037 10.9574 50.6623
            10.9057 50.6102L9.79155 49.496C9.73842 49.4446 9.69606 49.383 9.66697 49.315C9.63787 49.247 9.62262 49.1739 9.62208
            49.0999C9.62155 49.026 9.63576 48.9526 9.66387 48.8842C9.69197 48.8158 9.73341 48.7537 9.78578 48.7014C9.83816 48.6492
            9.90046 48.6079 9.96896 48.58C10.0375 48.5521 10.1108 48.5381 10.1848 48.5388C10.2587 48.5396 10.3318 48.5551
            10.3997 48.5844C10.4676 48.6136 10.5291 48.6561 10.5804 48.7094L11.3001 49.4292L13.3569 47.3724C13.4082 47.3191
            13.4697 47.2766 13.5376 47.2473C13.6055 47.2181 13.6786 47.2026 13.7525 47.2018C13.8265 47.2011 13.8999 47.2151
            13.9684 47.243C14.0369 47.2709 14.0991 47.3122 14.1515 47.3644C14.2039 47.4167 14.2453 47.4788 14.2734
            47.5472C14.3015 47.6156 14.3158 47.689 14.3152 47.7629C14.3147 47.8369 14.2994 47.91 14.2703 47.978C14.2412
            48.046 14.1989 48.1076 14.1458 48.159L11.6946 50.6102C11.6429 50.6623 11.5815 50.7037 11.5138 50.732C11.4461
            50.7603 11.3735 50.7749 11.3001 50.7751Z"
            />
            <path fill="white"
                d="M63.281 61.917H50.0669C49.9191 61.917 49.7774 61.8583 49.6729 61.7539C49.5684 61.6494 49.5098
            61.5077 49.5098 61.3599C49.5098 61.2122 49.5684 61.0705 49.6729 60.966C49.7774 60.8615 49.9191 60.8029 50.0669
            60.8029H63.281C63.4287 60.8029 63.5704 60.8615 63.6749 60.966C63.7794 61.0705 63.8381 61.2122 63.8381 61.3599C63.8381
            61.5077 63.7794 61.6494 63.6749 61.7539C63.5704 61.8583 63.4287 61.917 63.281 61.917Z"
            />
            <path fill="white"
                d="M60.3459 73.6625H52.9924C52.4554 73.6619 51.9405 73.4482 51.5607 73.0685C51.181 72.6888 50.9674
            72.174 50.9668 71.6369V61.3598C50.9668 61.212 51.0255 61.0703 51.13 60.9658C51.2344 60.8614 51.3761 60.8027 51.5239
            60.8027C51.6716 60.8027 51.8133 60.8614 51.9178 60.9658C52.0223 61.0703 52.081 61.212 52.081 61.3598V71.6369C52.081
            71.8787 52.177 72.1105 52.3479 72.2814C52.5188 72.4523 52.7507 72.5483 52.9924 72.5483H60.3459C60.5877 72.5483 60.8195
            72.4523 60.9904 72.2814C61.1613 72.1105 61.2573 71.8787 61.2573 71.6369V61.3598C61.2573 61.212 61.316 61.0703 61.4205
            60.9658C61.525 60.8614 61.6666 60.8027 61.8144 60.8027C61.9621 60.8027 62.1038 60.8614 62.2083 60.9658C62.3128 61.0703
            62.3715 61.212 62.3715 61.3598V71.6369C62.3709 72.174 62.1573 72.6888 61.7776 73.0685C61.3978 73.4482 60.883 73.6619
            60.3459 73.6625ZM59.6061 61.9168C59.4584 61.9168 59.3167 61.8582 59.2122 61.7537C59.1077 61.6492 59.049 61.5075
            59.049 61.3598V59.8913C59.049 59.6495 58.953 59.4177 58.7821 59.2468C58.6112 59.0759 58.3793 58.9799 58.1376
            58.9799H55.2007C54.9589 58.9799 54.7271 59.0759 54.5562 59.2468C54.3853 59.4177 54.2892 59.6495 54.2892
            59.8913V61.3598C54.2892 61.5075 54.2306 61.6492 54.1261 61.7537C54.0216 61.8582 53.8799 61.9168 53.7322 61.9168C53.5844
            61.9168 53.4428 61.8582 53.3383 61.7537C53.2338 61.6492 53.1751 61.5075 53.1751 61.3598V59.8913C53.1757 59.3542 53.3893
            58.8394 53.769 58.4596C54.1488 58.0799 54.6636 57.8663 55.2007 57.8657H58.1376C58.6746 57.8663 59.1895 58.0799 59.5693
            58.4596C59.949 58.8394 60.1626 59.3542 60.1632 59.8913V61.3598C60.1632 61.5075 60.1045 61.6492 60 61.7537C59.8956 61.8582
            59.7539 61.9168 59.6061 61.9168Z"
            />
            <path fill="white"
                d="M55.2045 69.9926C55.0568 69.9926 54.9151 69.9339 54.8106 69.8294C54.7061 69.7249 54.6475 69.5832
            54.6475 69.4355V65.0367C54.6475 64.889 54.7061 64.7472 54.8106 64.6428C54.9151 64.5383 55.0568 64.4796 55.2045
            64.4796C55.3523 64.4796 55.494 64.5383 55.5985 64.6428C55.703 64.7472 55.7616 64.889 55.7616 65.0367V69.4422C55.7599
            69.5887 55.7004 69.7287 55.5961 69.8317C55.4919 69.9348 55.3511 69.9926 55.2045 69.9926Z"
            />
            <path fill="white"
                d="M58.142 69.9926C57.9943 69.9926 57.8526 69.9339 57.7482 69.8294C57.6437 69.7249 57.585 69.5832
            57.585 69.4355V65.0367C57.585 64.889 57.6437 64.7472 57.7482 64.6428C57.8526 64.5383 57.9943 64.4796 58.142
            64.4796C58.2898 64.4796 58.4315 64.5383 58.536 64.6428C58.6405 64.7472 58.6991 64.889 58.6991 65.0367V69.4422C58.6974
            69.5887 58.6379 69.7287 58.5336 69.8317C58.4294 69.9348 58.2886 69.9926 58.142 69.9926Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_969_950">
                <rect width="71.461" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
