import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTotalMatches = (appliedFilters) => {
    const filters = useContentFilters({ useGlobalFilters: true });

    const ret = useQuery(TOTAL_MATCHES, {
        variables: {
            ...filters,
            ...appliedFilters
        }
    });

    return {
        ...ret,
        totalMatches: ret.data ? ret.data?.totalMatches : 0
    };
};

export const TOTAL_MATCHES = gql`
    query totalMatches(
        $projectId: String!
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        totalMatches(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
        )
    }
`;
