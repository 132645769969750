import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { Tabs } from '../../../../components/Tabs';
import { truncateString } from '../../../../utils/text';
import { EmptyState } from './EmptyState';
import { convertToDate } from '../../../../utils/getTimeFrom';
import { formatTimelineData } from '../Performance/Timeline/index';
import { TooltipItem } from '../../../../components/ToolTip';
import noImage from '../../../../assets/images/no_image.jpg';
import { useModal } from '../../../../context/modal/ModalComponent';
import { LinkModal } from '../../../../components/LinkModal';
import { currentTab } from '../../../../utils/variables';
import { Loading } from '../../../../components/Loading';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { SplineChart } from '../../../../components/Highcharts/Spline';

export const NarrativePerformance = ({ contentLoading, data, pData, dateRange }) => {
    const location = useLocation();
    const presentTab = useRef('timeline');

    const currentTabValue = currentTab();

    if (currentTabValue === 'timeline' || currentTabValue === 'fact-check' || currentTabValue === 'content') {
        presentTab.current = currentTabValue;
    }

    if (contentLoading) {
        return <div className="bg-white p-3 h-100"><Loading relative height={300} /></div>;
    }

    return (
        <div className="bg-white p-3 h-100">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h3 className="m-0 mr-1">How is the narrative performing?</h3>
                    <TooltipItem placement="top"
                        tooltipOpen="how is the narrative performing?"
                        id="narrativePerfomance"
                        content={[
                            <div>
                                <div>
                                    <h3>Timeline. </h3>
                                    Monitor how your Narrative is performing over time by visualising how much content&nbsp;
                                    has been generated relating to it, along with how many people are following it.
                                </div>
                                <div><h3>Content. </h3>View all of the content generated that relates to your Narrative.</div>
                                <div>
                                    <h3>Fact checks. </h3>
                                    View all of the Fact Checks performed by Logically related to your Narrative.
                                </div>
                            </div>]}
                    />
                </div>
                {(
                    (data && data[0])
                    || (pData?.related_factchecks?.length > 0 && pData?.related_factchecks[0] !== null)) ? (
                        <Link to={{ pathname: `${location.pathname}${
                            location.pathname.endsWith('/') ? '' : '/'}how-is-the-narrative-performing`,
                        state: { prevTab: presentTab.current } }}
                        >
                            View details
                        </Link>
                    ) : null}
            </div>
            <div className="m-0 mt-2">
                <Tabs small
                    data={[
                        { name: 'Timeline', id: 'timeline', content: <Timeline tlData={data} dateRange={dateRange} /> },
                        {
                            name: 'Content',
                            id: 'content',
                            content: <Content contentData={data} dateRange={dateRange} />,
                        },
                        { name: 'Fact checks', id: 'fact-check', content: <Factchecks fcData={pData} dateRange={dateRange} /> }
                    ]}
                />
            </div>
        </div>
    );
};

const Timeline = ({ tlData, dateRange }) => {
    const filteredDocs = tlData?.filter(
        (item) => item?.pubdatetime >= dateRange.startDate && item?.pubdatetime <= dateRange.endDate
    );
    const seriesData = [
        {
            data: formatTimelineData(filteredDocs, 'volume'),
            name: 'Volume',
        }
    ];

    return (
        <div className="m-0 p-0">
            { filteredDocs?.length > 0 && filteredDocs[0] !== null ? (
                <>
                    <div className="d-flex align-items-center">
                        <h3 className="mr-1">Volume over time</h3>
                        <TooltipItem placement="bottom"
                            tooltipOpen="Volume over time"
                            id="volumeovertime"
                            content="Number of posts generated in the date range"
                        />
                    </div>
                    <div className="pt-1 ml-n1">
                        <SplineChart height={220} seriesData={seriesData} lineColor="#FF0000" />
                    </div>
                </>
            ) : (
                <div className="mt-10">
                    <EmptyState />
                </div>
            )}
        </div>
    );
};
const Content = ({ contentData, dateRange }) => {
    const filteredDocs = contentData?.filter(
        (item) => item?.pubdatetime >= dateRange.startDate && item?.pubdatetime <= dateRange.endDate
    );

    return (
        <div>
            {filteredDocs && filteredDocs[0] !== null && filteredDocs?.length > 0 ? (
                <>
                    {filteredDocs.map((item, i) => {
                        if (i > 2) {
                            return null;
                        }
                        return <ContentItem key={uuid()} contentItemData={item} index={i} />;
                    })}
                </>
            ) : (
                <div className="mt-10">
                    <EmptyState />
                </div>
            )}
        </div>
    );
};

const ContentItem = ({ contentItemData, index }) => {
    const { setModal } = useModal();

    return (
        <div>
            <div className="d-flex m-0">
                {contentItemData && <SourceIconLabel data={contentItemData} className="d-flex text-dark font-weight-bold mr-1" />}
                <p className="mr-1">{moment(convertToDate(contentItemData?.pubdatetime)).format('DD MMM')}</p>
            </div>
            <p className="mr-1">
                {truncateString(contentItemData.text, 130)}
                <a href={contentItemData.url}
                    target="_blank"
                    rel="noreferrer"
                    className="ml-1"
                    data-testid="external-LinkModal"
                    onClick={(e) => {
                        if (!localStorage.getItem('externalLinks')) {
                            e.preventDefault();
                            setModal({
                                header: false,
                                component: <LinkModal link={contentItemData.url} />
                            });
                        }
                    }}
                >
                    View source
                </a>
            </p>
            {index < 2 ? <hr /> : null}
        </div>
    );
};

const Factchecks = ({ fcData, dateRange }) => {
    const filteredData = fcData?.related_factchecks?.filter(
        (item) => moment(item?.date).unix() >= dateRange.startDate && moment(item?.date).unix() <= dateRange.endDate
    ) || [];

    return (
        <div>
            {filteredData[0] !== null && filteredData?.length > 0 ? (
                <>
                    {filteredData.map((item, i) => {
                        if (i > 2) {
                            return null;
                        }
                        return <FactCheckItem key={item._id} fcItemData={item} index={i} />;
                    })}
                </>
            ) : (
                <div className="mt-10">
                    <EmptyState />
                </div>
            )}
        </div>
    );
};

const FactCheckItem = ({ fcItemData, index }) => (
    <>
        <div className="d-flex ">
            <div className="pr-1">
                <img src={fcItemData?.image ? fcItemData?.image : noImage} alt="" height={88} width={152} />
            </div>
            <div className="pt-0">
                <p className="m-0">{fcItemData.textual_judgement.toUpperCase()}</p>
                <p className="">
                    {truncateString(fcItemData?.conclusion, 100)}
                    <a href={fcItemData.link} target="_blank" rel="noreferrer" className="ml-1">
                        {(fcItemData?.conclusion?.length > 100 || fcItemData?.conclusion?.length < 100)
                                && 'View source'}
                    </a>
                </p>
            </div>
        </div>
        {index < 2 ? <hr className="m-0 pb-2" /> : null}
    </>
);
