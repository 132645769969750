/* eslint-disable one-var */
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { DateRangePicker } from '../../DateRangePicker';
import { NoDataFound } from '../Nodatafound';
import { Deleted } from '../../../../../assets/svg/deleted';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { getRoom } from '../../../../../utils/variables';
import { ContentTable } from './ContentTable';
import { SearchInput } from '../../../../../components/Search/SearchInput';

export const Content = ({ data, refetch }) => {
    const room = getRoom();
    const [contentIds, setContentIds] = useState(data?._source?.content_ids);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [date, setDate] = useState({});

    const { data: narrativeDoc } = useQuery(GET_NARRATIVE_CONTENT, {
        variables: {
            _id: data._id,
            projectId: room?.project_id,
        },
        fetchPolicy: 'no-cache'
    });
    const { data: contents, loading, count } = useContent({
        filters: {
            ids: contentIds,
            ...(searchValue ? { search: searchValue } : {}),
            ...(date ? { date: { startDate: date.startDate * 1000, endDate: date.endDate * 1000 } } : {})
        },
        limit: 10,
        skip: page * 10,
        skipRequest: !data,
        name: 'getNarrativeContent',
        fragments: ['Metadata', 'Document']
    });
    const [deleted, setDeleted] = useState(false);
    const { setModal, closeModal } = useModal();
    const [isTranslated, setIsTranslated] = useState(false);

    const [deleteNarrativeContent, { loading: loadingNarcontent }] = useMutation(DELETE_NARRATIVE_CONTENT, {
        onCompleted: (resp) => {
            setDeleted(true);
            handleModal();
            setContentIds(resp?.alterNarrativeContent?.data?.content_ids || contentIds);
        },
        fetchPolicy: 'no-cache'
    });

    const handleModal = () => {
        setModal({
            header: false,
            component:
    <>
        <Deleted />
        <h2>Content deleted successfully.</h2>
        <hr />
        <Button onClick={() => { closeModal();
            refetch(); }}
        >Close
        </Button>
    </>
        });
    };

    const deleteContent = (checkBoxValues) => {
        let newContentIds = contentIds;
        for (let i = 0; i < checkBoxValues.length; i++) {
            newContentIds = newContentIds.filter(x => x !== checkBoxValues[i]);
        }
        deleteNarrativeContent({
            variables: {
                _id: data._id,
                projectId: room?.project_id,
                contentDocs: { ...narrativeDoc.getNarrativeContent.data, content_ids: newContentIds }
            }
        });
    };

    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, { variables: {
        data: [{ text: data?._source?.narrative_title, target: 'en' },
            ...(contents.map((item) => ({
                text: item?.text,
                target: 'en'
            })))
        ],
    } });
    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0]
        : data?._source?.narrative_title;
    const translatedContentDocs = translatedData && isTranslated
        ? contents?.map((item, i) => ({ ...item, text: translatedData.translateMultipleText[i + 1].translations[0] }))
        : contents;

    const handleTranslation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };

    return (
        <>
            <div className="d-flex">
                <p className="set-min-width"> View all of the content generated that relates to your Narrative. You
                    can clean the data by clicking on a checkbox next to a content piece and
                    pressing “Delete”.
                </p>
                <div className="text-primary w-100 d-flex justify-content-end cursor-pointer align-icon-top"
                    onClick={(e) => handleTranslation(e)}
                >
                    {data?._source.lang_verbose === 'English'
                        ? null
                        : (
                            <span role="button"
                                className="ml-2"
                            >
                                <TranslateIcon color="#006FF9" size={30} /> Translate content
                            </span>
                        )}
                </div>
            </div>
            <h3 className="mt-3 sentence-format">{isTranslated ? outputText : data?._source?.narrative_title}</h3>
            <div className=" ml-0 mr-0 mt-4 d-flex align-items-center justify-content-between">
                <div className="w-100 align-search-bar">
                    <SearchInput placeholder="Search by keyword."
                        searchVal={search}
                        setVal={setSearch}
                        handleSearch={(e) => { e.preventDefault();
                            setPage(0); setSearchValue(search); }}
                        resetFn={() => setSearchValue('')}
                    />
                </div>
                <div className="d-flex align-date-range ml-2">
                    <p className="mr-1 no-white-space">Date range</p>
                    <DateRangePicker setDateValue={setDate} />
                </div>
            </div>
            {contents.length > 0 && contents[0] !== null ? (
                <ContentTable data={translatedContentDocs}
                    deleteContent={deleteContent}
                    deleted={deleted}
                    page={page}
                    setPage={setPage}
                    total={count}
                    loading={loadingNarcontent}
                />
            ) : (
                <div className="bg-white p-5">
                    {loading ? <Loading relative height={300} /> : <NoDataFound />}
                </div>
            )}
        </>
    );
};

const DELETE_NARRATIVE_CONTENT = gql`
    mutation alterNarrativeContent($_id: String, $projectId: String, $contentDocs: JSON){
        alterNarrativeContent(_id:$_id, projectId: $projectId, contentDocs:$contentDocs)
    }
`;

const GET_NARRATIVE_CONTENT = gql`
    query getNarrativeContent($_id: String, $projectId: String){
        getNarrativeContent(_id:$_id, projectId: $projectId)
    }
`;
