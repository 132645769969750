import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown } from 'react-feather';
import { Button, Input, Popover, PopoverBody } from 'reactstrap';
import { TooltipItem } from '../../../../../components/ToolTip';
import { Range } from '../../../../../components/Atoms/Range';
import { CheckBox } from '../../../../../components/Form/CheckBox';
import {
    updateSettings,
    NetworksHistoryStore,
    setRegraphLoading,
    NetworksHistoryState,
    resetSettings
} from '../../store';
import { Dropdown } from '../../../../../components/Form/Dropdown';

export const Settings = () => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [nodeValue, setNodeValue] = useState(0);
    const {
        includeAuthorsAndMentions,
        includeHashtags,
        includeSharedURLs,
        layout,
        sizeNodesBy,
        clusterBy,
        minimumNodeConnections
    } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);

    const togglePopover = () => {
        if (!isOpen && minimumNodeConnections !== nodeValue) {
            setNodeValue(minimumNodeConnections);
        }
        setOpen(!isOpen);
    };

    const handleUpdateSettings = (data: Partial<NetworksHistoryState>) => {
        dispatch(setRegraphLoading(true));
        dispatch(updateSettings(data));
    };

    const handleNodeConnections = (newValue: number) => {
        const cappedValue = Math.min(Math.max(newValue, 0), 10);
        setNodeValue(cappedValue);
        dispatch(updateSettings({ minimumNodeConnections: cappedValue }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        const cappedValue = Math.min(newValue, 10);
        if (!isNaN(cappedValue)) {
            setNodeValue(cappedValue);
            handleNodeConnections(cappedValue);
        } else {
            setNodeValue(0);
            handleNodeConnections(0);
        }
    };

    const handleReset = () => {
        dispatch(setRegraphLoading(true));
        dispatch(resetSettings());
        setNodeValue(0);
    };

    const selectedLayout = nodeLayoutOptions.find(({ key }) => key === layout) || nodeLayoutOptions[0];
    const selectedNodeSize = nodeSizeOptions.find(({ key }) => key === sizeNodesBy) || nodeSizeOptions[0];
    const selectedClusterBy = clusterByOptions.find(({ key }) => key === clusterBy) || clusterByOptions[0];

    return (
        <div className="dropdown">
            <Button id="settingsLink">
                Settings
                <ChevronDown className={`transform-transition ${isOpen ? 'transform-btn-icon' : ''}`} />
            </Button>
            <Popover isOpen={isOpen} toggle={togglePopover} placement="bottom-end" target="settingsLink" trigger="legacy">
                <PopoverBody className="p-2 text-nowrap">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <h3 className="my-0  mr-11">Settings</h3>
                            <TooltipItem id="networksTooltip"
                                className="w-25 mw-100"
                                placement="bottom-start"
                                content={(
                                    <div>
                                        <p>
                                            <b>Node layout -</b> Node layout determines how the nodes are distributed.
                                            Selecting the correct layout can help detangle links, detect patterns,
                                            anomalies and clusters within a network.
                                        </p>
                                        <p>
                                            <b>Size nodes -</b> Nodes in the network are measured and sized based on your selection.
                                            Sizing nodes can be useful to highlight which nodes have a greater
                                            influence over the network.
                                        </p>
                                        <p>
                                            <b>Cluster by -</b> Cluster nodes with similar properties, such as what source the node is from,
                                            or the threat level of the node.
                                        </p>
                                        <p>
                                            <b>Minimum node connections -</b> Filter out nodes by number of connections they have.
                                        </p>
                                        <p>
                                            <b>Authors and mentions -</b> Can display nodes representing the authors posting
                                            content or mentions of them within the room.
                                        </p>
                                        <p>
                                            <b>Include hashtags -</b> Can display nodes representing the hashtags prevalent within the room.
                                        </p>
                                        <p>
                                            <b>Shared URLs -</b> Can display nodes representing the URLs being shared within the room.
                                        </p>
                                        <a href="https://logically-intelligence.readme.io/docs/explore"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button color="link" className="p-0 mw-0">Read more</Button>
                                        </a>
                                    </div>
                                )}
                            />
                        </div>
                        <div>
                            <p className="m-0" onClick={handleReset} role="button">Reset</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <p>Node Layout</p>
                        <Dropdown options={nodeLayoutOptions}
                            value={selectedLayout}
                            onChange={({ key }) => handleUpdateSettings({ layout: key as NetworksHistoryState['layout'] })}
                            dropdownMenuClass="w-100"
                            className="mb-2"
                        />
                        <p>Size node by</p>
                        <Dropdown options={nodeSizeOptions}
                            value={selectedNodeSize}
                            onChange={({ key }) => handleUpdateSettings({ sizeNodesBy: key as NetworksHistoryState['sizeNodesBy'] })}
                            dropdownMenuClass="w-100"
                            className="mb-2"
                        />
                        <p>
                            Cluster by
                        </p>
                        <Dropdown options={clusterByOptions}
                            value={selectedClusterBy}
                            onChange={({ key }) => handleUpdateSettings({ clusterBy: key as NetworksHistoryState['clusterBy'] })}
                            dropdownMenuClass="w-100"
                        />
                        <hr />
                        <p>
                            Select minimum node connections
                        </p>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <span className="pr-1">0</span>
                                <Range min={0}
                                    max={10}
                                    value={nodeValue}
                                    onChange={(newValue: number) => handleNodeConnections(newValue)}
                                    step={1}
                                />
                                <span className="pl-1 pr-2">10</span>
                            </div>
                            <div className="w-100">
                                <Input placeholder="0"
                                    value={nodeValue != null ? nodeValue.toString() : ''}
                                    // Provide a default value of '' if nodeValue is null or undefined
                                    onChange={handleInputChange}
                                    className="border-rounded"
                                    data-testid="minimum-node-connections"
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <CheckBox id="include-author-mentions"
                        label="Include Authors & Mentions"
                        name="bob"
                        value="bob"
                        checked={includeAuthorsAndMentions}
                        onChange={() => handleUpdateSettings({ includeAuthorsAndMentions: !includeAuthorsAndMentions })}
                    />
                    <CheckBox id="include-hashtags"
                        label="Include Hashtags"
                        name="bob"
                        value="bob"
                        checked={includeHashtags}
                        onChange={() => handleUpdateSettings({ includeHashtags: !includeHashtags })}
                    />
                    <CheckBox id="include-shared-urls"
                        label="Include Shared URLs"
                        name="bob"
                        value="bob"
                        checked={includeSharedURLs}
                        onChange={() => handleUpdateSettings({ includeSharedURLs: !includeSharedURLs })}
                    />
                </PopoverBody>
            </Popover>
        </div>
    );
};

const nodeLayoutOptions = [
    {
        name: 'Organic',
        key: 'organic',
    },
    {
        name: 'Structural',
        key: 'structural',
    },
    {
        name: 'Lens',
        key: 'lens',
    },
    {
        name: 'Radial',
        key: 'radial',
    },
    {
        name: 'Sequential',
        key: 'sequential',
    }
];

const nodeSizeOptions = [
    {
        name: 'Nodes not sized',
        key: '',
    },
    {
        name: 'Content engagement',
        key: 'engagement',
    },
    {
        name: 'Degrees',
        key: 'degrees',
    },
    {
        name: 'Betweenness',
        key: 'betweenness',
    },
    {
        name: 'Page Rank',
        key: 'pageRank',
    },
    {
        name: 'Eigen Centrality',
        key: 'eigenCentrality',
    }
];

const clusterByOptions = [
    {
        name: 'Not grouped',
        key: '',
    },
    {
        name: 'URL',
        key: 'url',
    },
    {
        name: 'Hashtag',
        key: 'hashtag',
    },
    {
        name: 'Source',
        key: 'source'
    },
    {
        name: 'Account',
        key: 'from',
    },
    {
        name: 'Sentiment',
        key: 'sentiment',
    },
    {
        name: 'Threat Level',
        key: 'threatLevel'
    }
];
