import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Loading } from '../../../../../components/Loading';
import { Compare } from '../../Compare';
import { elasticFilterWithProjectId } from '../../../../../utils/elasticFilterWithProjectId';

export const Comparisons = () => {
    const history = useHistory();
    const [comparedData] = useState(history?.location?.state);

    const { data: nar, loading } = useQuery(GET_COMPARED_NARRATIVES_DATA, {
        variables: {
            query: elasticFilterWithProjectId([{
                ids: {
                    values: comparedData?.narratives
                }
            }])
        },
    });
    const data = !loading && nar?.newNarrativeServiceSearch?.hits.map(item => ({
        ...item._source,
        id: item._id,
        volume_over_time: parseInt(item._source.volume_over_time?.split(':')[1] || '0', 10),
        average_threat_label_over_time: item._source.average_threat_label_over_time?.split(':')[1]
    }));
    if (loading) return <Loading />;
    return (
        <div>
            <Compare data={data} isEdit={comparedData?.isEdit} comparisonMainData={comparedData} />
        </div>
    );
};

const GET_COMPARED_NARRATIVES_DATA = gql`
    query newNarrativeServiceSearch($query: NewNarrativesESQuery) {
        newNarrativeServiceSearch(query: $query) {
            count
            hits {
                _id
                _source {
                    narrative_title
                    volume
                    volume_over_time
                    average_threat_label
                    average_threat_label_over_time
                    original_post
                    content_ids
                    lang_verbose
                    narrative_locations {
                        location
                        count
                    }
                    top_keyphrases
                }
            }
        }
    }
`;
