import React from 'react';
import { getIcons } from '../../../../../utils/getIcons';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { calculateAverageSentiment } from '../../../../../utils/calculateAverageSentiment';

type SentimentType = {
    sentiment: string
    score: string
}
type ActorsListProps = {
    actor: string
    count: number
}
type ClusterProps = {
    title: string
    subClusterTitle: string
    description: string
    subClusterSummary: string
    volume: number
    duplicatedDocsCount: number
    subClusters: [string]
    sentimentJson: [SentimentType]
    topActors: [ActorsListProps]
}
type ClusterDetailsProps = {
    isParentCluster: boolean
    cluster: ClusterProps
}

export const ClusterDetails = ({ isParentCluster, cluster }: ClusterDetailsProps) => {
    const averageSentiment = calculateAverageSentiment(cluster.sentimentJson);
    return (
        <div className="w-25 bg-white py-2 px-4">
            <p className="font-weight-bold">{isParentCluster ? cluster.title : cluster.subClusterTitle}</p>
            <p>{isParentCluster ? cluster.description : cluster.subClusterSummary}</p>
            <p>Content: {cluster.volume}</p>
            <p>Duplicates: {cluster.duplicatedDocsCount}</p>
            {isParentCluster ? <p>Subclusters: {cluster.subClusters.length}</p> : null}
            <hr />
            {getIcons('sentiment', averageSentiment)} { capitalizeFirstLetter(averageSentiment)} sentiment
            {isParentCluster ? <p className="mt-1">{cluster.topActors[0].actor} (Top actor)</p> : null}
        </div>
    ); };
