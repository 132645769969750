import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { lodashSort } from '../../../../utils/lib/tableSorting';
import { GET_INVESTIGATION_TOKENS, UPDATE_TOKENS } from '../../../../graphql';
import { useModal } from '../../../../context/modal/ModalComponent';
import investigationDeleted from '../../../../assets/images/investigations/investigationDeleted.svg';
import approveInvestigationSuccess from '../../../../assets/images/investigations/approveInvestigationSuccess.svg';

export const TokenTableBody = ({ tokensRequested, sort }) => {
    const { setModal, closeModal } = useModal();
    const [isdeleteRequest, setIsDeleteRequest] = useState(false);
    const [updateTokens] = useMutation(UPDATE_TOKENS, {
        refetchQueries: [GET_INVESTIGATION_TOKENS],
        onCompleted: () => {
            setModal({
                component: (
                    <>
                        <img src={isdeleteRequest ? investigationDeleted : approveInvestigationSuccess}
                            alt={`${isdeleteRequest ? 'deleted' : 'approved'}-success}`}
                            width="100"
                        />
                        <h3>Token request {isdeleteRequest ? 'deleted' : 'approved' } successfully</h3>
                        <hr className="w-100" />
                        <Button color="secondary"
                            onClick={() => { closeModal(); }}
                        >Close
                        </Button>
                    </>
                )
            });
        },
        onError: () => {
            toast.error('Unable to process the request, Please try again');
        }
    });
    const handleApprove = (tokenRequest) => {
        updateTokens({
            variables: { data: {
                instance: tokenRequest?.client?.id,
                tokenRequestId: tokenRequest?.id,
                tokens: tokenRequest.tokensAvailable + tokenRequest.tokensRequested,
                totalTokens: tokenRequest.totalTokens + tokenRequest.tokensRequested
            } },
        });
    };
    const handleDeleteRequest = (tokenRequest) => {
        updateTokens({
            variables: { data: {
                instance: tokenRequest?.client?.id,
                tokenRequestId: tokenRequest?.id,
                tokens: tokenRequest.tokensAvailable,
                totalTokens: tokenRequest.totalTokens
            } },
        });
    };
    return (
        <tbody>
            {lodashSort([...tokensRequested], sort).map((item) => (
                <tr key={item.id}>
                    <td>{item?.client?.clientName || 'Not available'}</td>
                    <td>
                        {item?.tokensRequested }
                    </td>
                    <td>{ item?.tokensAvailable }</td>
                    <td>{item?.requestedBy?.displayName || 'Not available'}</td>
                    <td>{item?.requestedBy?.email || 'Not available'}</td>
                    <td className="text-primary cursor-pointer"
                        onClick={() => { setIsDeleteRequest(false);
                            handleApprove(item); }}
                    >Approve
                    </td>
                    <td className="text-danger cursor-pointer"
                        onClick={() => { setIsDeleteRequest(true);
                            handleDeleteRequest(item); }}
                    >Delete
                    </td>
                </tr>
            ))}
        </tbody>
    );
};
