/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { has, isEqual, omit, pickBy } from 'lodash';
import { kCores } from 'regraph/analysis';
import { Chart } from 'regraph';
import { NetworkNode } from '../useNetworks';
import { useAuthorLinks } from './links/authors';
import { useHashtagLinks } from './links/hashtags';
import { useURLLinks } from './links/urls';
import { useAuthorNodes } from './nodes/authors';
import { useContentNodes } from './nodes/content';
import { useHashtagNodes } from './nodes/hashtags';
import { useURLNodes } from './nodes/urls';
import { NetworksHistoryStore } from '../../store';
import { useRegraphSizing } from './sizeNodes';

export type UseChartItemsProps = {
    data: NetworkNode[]
}

const isNode = (item: any) => !has(item, 'id1');

export const useKCores = ({ items }: { items: Chart.Props['items'] }) => {
    const { minimumNodeConnections } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    const [filteredItems, setItems] = useState({});

    useEffect(() => {
        const analyseItems = async () => {
            if (!items) return null;
            const { values } = await kCores(items);
            if (!values) {
                return null;
            }
            const isWithinThreshold = (id: string) => has(values, id) && values[id] >= minimumNodeConnections;
            const newFilteredItems = pickBy(items, (item, id) => !isNode(item) || isWithinThreshold(id));
            // Check if the new filtered items are different from the previous state
            if (!isEqual(newFilteredItems, filteredItems)) {
                setItems(newFilteredItems);
            }
        };
        if (minimumNodeConnections > 1) {
            analyseItems();
        } else if (items && !isEqual(items, filteredItems)) {
            setItems(items);
        }
    }, [items, filteredItems, minimumNodeConnections]);

    return filteredItems;
};

export const useChartItems = ({ data }: UseChartItemsProps): Chart.Props['items'] => {
    const { deletedNodes } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    // get nodes
    const contentNodes = useContentNodes(data);
    const authorNodes = useAuthorNodes(data);
    const hashtagNodes = useHashtagNodes(data);
    const urlNodes = useURLNodes(data);

    // get links
    const authorLinks = useAuthorLinks(data);
    const hashtagLinks = useHashtagLinks(data);
    const urlLinks = useURLLinks(data);
    // return combined nodes and links
    const nodes = {
        ...contentNodes,
        ...authorNodes,
        ...hashtagNodes,
        ...urlNodes,
    };
    const links = {
        ...authorLinks,
        ...hashtagLinks,
        ...urlLinks
    };
    const nodesToReturn = omit({
        ...nodes,
        ...links
    }, deletedNodes);

    const items = useKCores({ items: nodesToReturn });
    const sizedItems = useRegraphSizing({ items });

    return sizedItems;
};
