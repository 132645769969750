import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { TimeBar } from 'regraph';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, DebouncedFunc, isEqual } from 'lodash';
import { useTimeline } from './useTimeline';
import { convertESInterval } from '../../../../../utils/convertESInterval';
import { DateRange, NetworksHistoryStore, setRegraphLoading, updateNetworks } from '../../store';

type TimeRange = {
    start: Date | number;
    end: Date | number;
}

export type NetworksTimelineRef = {
    updateDateRange: (dateFilter?: DateRange) => void
}

export const NetworksTimeline = forwardRef((_, ref) => {
    const { loading, data, interval } = useTimeline();
    const dispatch = useDispatch();
    const [dateRange, setRange] = useState<TimeRange | undefined>(undefined);
    const intervalInMilliseconds = convertESInterval(interval) as number;
    const { chartState } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);

    useImperativeHandle(ref, () => ({
        updateDateRange: (dateFilter?: DateRange) => {
            if (dateFilter?.startDate && dateFilter?.endDate) {
                setRange({
                    start: dateFilter.startDate,
                    end: dateFilter.endDate
                });
            } else {
                const allDates = data.map(({ key }) => [key, key + intervalInMilliseconds]).flat().sort((a, b) => a - b);
                setRange({
                    start: new Date(allDates[0]),
                    end: new Date(allDates[allDates.length - 1])
                });
            }
        }
    }));

    if (loading) return null;

    const timeBarOptions = {
        style: {
            color: '#006FF9',
            hoverColor: '#328bfa'
        },
        backgroundColor: '#FDFDFD',
        sliders: {
            color: 'rgb(248, 248, 248, 0.9)',
            lineColor: '#E5E6EB'
        },
        zoom: {
            max: {
                unit: intervalInMilliseconds > (1000 * 60 * 60 * 24) ? 'week' : 'day' as ZoomUnits
            }
        }
    };
    const applyDateRange = (range: TimeRange) => {
        setRange(range);
        dispatch(setRegraphLoading(true));
        dispatch(
            updateNetworks({
                filters: {
                    date: {
                        startDate: range.start,
                        endDate: range.end
                    }
                },
                chartState: {
                    ...chartState,
                    positions: {}
                }
            })
        );
    };
    let debounceRange = null as null | DebouncedFunc<any>;
    return (
        <div className="border-top">
            <MemoizedTimeBar options={timeBarOptions}
                items={Object.fromEntries(data.map(({ key, count }) => ([key, {
                    times: [{
                        time: {
                            start: key,
                            end: key + intervalInMilliseconds
                        },
                        value: count
                    }]
                }])))}
                onChange={({ range }) => {
                    if (range) {
                        if (debounceRange) debounceRange.cancel();
                        debounceRange = debounce(() => {
                            if (!isEqual(range, dateRange)) {
                                applyDateRange(range);
                            }
                        }, 500); // allow some time for user to stop using timebar
                        debounceRange();
                    }
                }}
                range={dateRange}
            />
        </div>
    );
});

const MemoizedTimeBar = React.memo(({
    options,
    items,
    onChange,
    range
}: TimeBar.Props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    return (
        <TimeBar options={options}
            items={items}
            style={{ height: 100 }}
            onChange={(change) => {
                if (initialLoad) {
                    setInitialLoad(false);
                } else if (onChange) {
                    onChange(change);
                }
            }}
            range={range}
        />
    );
}, (prevProps, nextProps) => isEqual(prevProps.items, nextProps.items) && isEqual(prevProps.range, nextProps.range));

type ZoomUnits = 'year' | 'month' | 'week' | 'day' | 'hour';
