import React from 'react';
import { toast } from 'react-toastify';
import { CheckCircle } from 'react-feather';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';

export const ResetGraph = ({ resetGraph }: { resetGraph: () => void }) => {
    const { closeModal } = useModal();
    const handleResetGraph = () => {
        closeModal();
        resetGraph();
        toast.info('Network graph have been successfully reset to default', {
            icon: <CheckCircle className="text-primary" />,
            style: {
                backgroundColor: '#E6F1FE'
            }
        });
    };
    return (
        <div>
            <p>Are you sure you want to reset this graph? This will undo all changes made.</p>
            <hr />
            <Button onClick={closeModal} className="mr-2">Cancel</Button>
            <Button onClick={handleResetGraph} color="primary">Reset</Button>
        </div>
    );
};
