import React from 'react';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { TooltipItem } from '../../../../../components/ToolTip';
import { EmptyState } from '../../Dashboard/EmptyState';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { useContent } from '../../../../../services/Content/getContent';

export const KeyDatesContainer = ({ data, dateData, content }) => {
    const filteredDocs = content.filter(
        (item) =>
            moment(item?.date).unix() >= dateData?.startDate
      && moment(item?.date).unix() <= dateData?.endDate
    );

    return (
        <>
            <h3 className="m-0 d-inline">Key dates</h3>
            <span style={{ marginTop: '0.2rem', zIndex: 50, marginLeft: '8px' }}>
                <TooltipItem placement="right"
                    tooltipOpen="narrativeskeydates"
                    id="narrativeskeydates"
                    content="Dates related to an important change in this narrative."
                />
            </span>
            <TimeLineData content={filteredDocs} boosters={data?.boosters} originalPost={data?.original_post} />
        </>
    );
};

const TimeLineData = ({ content, boosters, originalPost: id }) => {
    const { data } = useContent({
        filters: {
            ids: id
        },
        skipRequest: !id,
        name: 'getNarrativeOriginalPost',
        fragments: ['Metadata', 'Document']
    });
    const originalPost = data[0];
    const sortedContentDocs = content.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    const changedThreatLabel = sortedContentDocs?.find(item =>
        item.threatLevel !== sortedContentDocs[0].threatLevel);
    return (
        <div data-testid="timeline-data-list">
            { ((content.length > 0) || (boosters?.length > 0) || originalPost)
                ? (
                    <ul className="timeline">
                        {content.length > 0 && (
                            <li>

                                <p>{moment(sortedContentDocs[0]?.date).format('DD MMM')}</p>
                                <p>Threat change</p>
                                <p>{changedThreatLabel && capitalizeFirstLetter(changedThreatLabel.threatLevel)} to&nbsp;
                                    {sortedContentDocs[0] && capitalizeFirstLetter(sortedContentDocs[0].threatLevel)}
                                </p>

                            </li>
                        )}
                        {boosters?.length > 0 && (
                            <li>

                                <p>{moment(boosters[0]?.date).format('DD MMM')}</p>
                                <p>Boosted <strong>{boosters[0]?.from}</strong></p>
                                <p>Engagement <span className="font-weight-bold">{boosters[0]?.engagement}</span></p>

                            </li>
                        )}
                        {originalPost && (
                            <li>
                                <p>{moment(originalPost?.date).format('DD MMM')}</p>
                                <p>Original post</p>
                                <SourceIconLabel data={originalPost} className="font-weight-bold" />
                            </li>
                        )}
                    </ul>
                ) : <EmptyState />}
        </div>
    );
};
