import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useContent } from '../../services/Content/getContent';
import { ContentTable, ContentTableTypes } from '../ContentTable';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { Loading } from '../Loading';
import { NoDataFound } from '../../pages/Room/Narratives/Performance/Nodatafound';
import { useModal } from '../../context/modal/ModalComponent';

export const DuplicatedDocsModal = ({ contentIds }: any) => {
    const { closeModal } = useModal();
    const [offset, setOffset] = useState<number>(0);
    const limit = 10;
    const { loading, data: contents, count } = useContent({
        filters: {
            ids: contentIds
        },
        limit,
        skip: offset,
        fetchPolicy: 'no-cache',
        name: 'getClusterContents'
    });
    return (
        <>
            <h2 className="mb-4">Duplicate documents</h2>
            {contents.length > 0 && contents[0] !== null ? (
                <ContentTable contents={contents}
                    pagination={{ offset, setOffset, count }}
                    tableType={ContentTableTypes.MATCHES}
                    contentType={CONTENT_TYPE.MATCH}
                    total={count}
                    contentsToDisplay={limit}
                    preview
                />
            ) : (
                <div className="bg-white p-4">
                    {loading ? <Loading relative height={300} /> : <NoDataFound />}
                </div>
            )}
            <Button color="secondary" onClick={closeModal}>Close</Button>
        </>
    );
};
