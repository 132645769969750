import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { X } from 'react-feather';

type KeywordInputProps = {
    keywords: string[]
    setKeywords?: (e: string[]) => void
    clearKeywords?: () => void
    disabled?: boolean
}

export const KeywordsInput = ({
    keywords,
    setKeywords,
    clearKeywords,
    disabled
}: KeywordInputProps) => {
    const [input, setInput] = useState('');
    const [duplicateKeyword, setDuplicateKeyword] = useState('');
    const [error, setError] = useState('');

    const removeKeyword = (keyword: string) => {
        if (!setKeywords) return null;
        const filteredKeywords = keywords.filter((a) => a !== keyword);
        setKeywords([...filteredKeywords]);
    };
    const addKeyword = (newKeyword: string) => {
        if (keywords.includes(newKeyword)) {
            setDuplicateKeyword(newKeyword);
            setError(`Keyword '${newKeyword}' has already been added. You cannot add duplicate keywords`);
        }
        else {
            if (!setKeywords) return null;
            setDuplicateKeyword('');
            setKeywords([...keywords, newKeyword]);
        }
    };
    if (error) {
        setTimeout(() => {
            setDuplicateKeyword('');
            setError('');
        }, 3000);
    }

    return (
        <div>
            <p>Keywords</p>
            <div className="form-control keyword-input">
                {(keywords.length > 0) && (
                    <div className="d-flex align-items-center flex-wrap tags pt-1 pl-1 keyword-textarea">
                        {keywords.map((keyword) => (
                            <div key={keyword}>
                                <div color={duplicateKeyword === keyword ? 'danger' : 'primary'}
                                    className={`tag mb-1 mr-1 ${duplicateKeyword === keyword && 'duplicate'}`}
                                >
                                    <span className="dont-break-out">
                                        {keyword.trim()}
                                    </span>
                                    {!disabled
                                    && (
                                        <button className="btn"
                                            type="button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeKeyword(keyword);
                                            }}
                                        >
                                            <X />
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                        {(keywords.length > 0 && !disabled && clearKeywords) && (
                            <Button color="link"
                                className="p-0 mb-1"
                                onClick={() => {
                                    clearKeywords();
                                    setError('');
                                }}
                            >Clear all
                            </Button>
                        )}
                    </div>
                )}
                { !disabled
                && (
                    <Input type="textarea"
                        data-testid="narrative-keyword"
                        rows="3"
                        className="border-0 px-1 pb-0 pt-1 h-25"
                        placeholder="Enter keywords"
                        value={input}
                        onChange={(e) => {
                            if (!['\n', ','].includes(e.target.value)) {
                                setDuplicateKeyword('');
                                setError('');
                            }
                            setInput(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ',') {
                                const inputKeywords = e.currentTarget.value.split(',');
                                const newKeyword = inputKeywords[inputKeywords.length - 1].trim();
                                if (newKeyword) {
                                    addKeyword(newKeyword);
                                }
                                setInput('');
                            }
                            if (e.key === 'Backspace') {
                                if (e.currentTarget.value === '') {
                                    const lastKeyword = keywords?.length && keywords.pop();
                                    if (lastKeyword) {
                                        removeKeyword(lastKeyword);
                                    }
                                }
                            }
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' || e.key === ',') {
                                setInput('');
                            }
                        }}
                    />
                ) }
            </div>
            {error && (
                <p className="text-danger mt-1">{error}</p>
            )}
        </div>
    );
};
