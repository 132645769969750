import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { Table } from 'reactstrap';
import { KeyBoardUpIcon, KeyBoardDownIcon } from '../../../../../assets/icons/svgicons';
import { useSortableData } from '../../../../../utils/lib/tableSorting';
import { capitalizeFirstLetter } from '../../../../../utils/text';

export const KeywordsTable = ({ data, page, formattedChart }) => {
    const [tableData, setTableData] = useState();
    const { items, requestSort, sortConfig } = useSortableData(data);

    const getIcon = (name) => (
        sortConfig?.key === name && sortConfig?.direction === 'ascending' ? <KeyBoardUpIcon /> : <KeyBoardDownIcon />);

    useEffect(() => {
        const offset = page * 10;
        setTableData(items.slice(offset, offset + 10));
    }, [page, items]);

    const getChange = (increased) => {
        if (increased) {
            return <ArrowUp className="text-success" />;
        }
        return <ArrowDown className="text-danger" />;
    };

    const topResults = (keyword) => {
        const matchFound = formattedChart.find(a => a.name === keyword);
        if (!matchFound) { return <div className="square-small" />; }
        const colors = {
            '#4367F8': 'blue',
            '#D945D0': 'purple',
            '#00C0C7': 'green',
            '#FF6C4C': 'orange'
        };
        return <div className={`square-small-${colors[matchFound.color]}`} />;
    };

    // eslint-disable-next-line no-shadow
    const keywords = (tableData) => {
        if (!tableData) return;
        return tableData.map((term) => (
            <tr key={`${uuid()} ${term.term}`}>
                <td className="bg-white pl-3">
                    {topResults(term.terms)}
                </td>
                <td className="bg-white">{capitalizeFirstLetter(term.terms)}</td>
                <td className="bg-white">{term.matches}</td>
                <td className="bg-white">
                    {term.change}%
                    {term.change ? getChange(term.change >= 0) : ''}
                </td>
            </tr>
        ));
    };

    return (
        <Table className="mt-5 border-0 table col-md mb-0">
            <thead>
                <tr>
                    <th className="border-0 no-white-space pl-3" />
                    <th className="border-0 no-white-space">
                        <button type="submit"
                            className="d-flex border-0 bg-white p-0"
                            onClick={() => requestSort('terms')}
                        >   <h5>Keywords {getIcon('terms')}</h5>
                        </button>
                    </th>
                    <th className="border-0 no-white-space">
                        <button type="submit"
                            className="d-flex border-0 bg-white p-0"
                            onClick={() => requestSort('matches')}
                        >  <h5>Matches {getIcon('matches')}</h5>
                        </button>
                    </th>
                    <th className="border-0 no-white-space">
                        <button type="submit"
                            className="d-flex border-0 bg-white p-0"
                            onClick={() => requestSort('change')}
                        > <h5>Change in matches {getIcon('change')}</h5>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody data-testid="keywords-table-body">
                {keywords(tableData)}
            </tbody>
        </Table>
    );
};
