import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { HelpCircle, Book } from 'react-feather';
import { useDropDown } from '../../utils/useDropdown';

export const HelpDropdown = () => {
    const [dropdown, toggleDropdown] = useDropDown();
    return (
        <Dropdown isOpen={dropdown} tag="div" toggle={toggleDropdown} className="mw-0" data-testid="help-dropdown">
            <DropdownToggle tag="div" className="nav-link px-2" data-testid="help-dropdown-toggle">
                <HelpCircle className="text-white" />
            </DropdownToggle>
            <DropdownMenu right>
                <a href="https://logically-intelligence.readme.io/v2.0/"
                    target="_blank"
                    rel="noreferrer"
                    className="dropdown-item notify-item"
                >
                    <Book className="icon-dual icon-xs mr-2" />
                    <span> Documentation</span>
                </a>
                <DropdownItem divider />
                <a href="https://logically.atlassian.net/servicedesk/customer/portal/3"
                    target="_blank"
                    rel="noreferrer"
                    className="dropdown-item notify-item"
                >
                    <HelpCircle className="icon-dual icon-xs mr-2" />
                    <span>Support</span>
                </a>
            </DropdownMenu>
        </Dropdown>
    );
};
