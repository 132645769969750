import React from 'react';
import moment from 'moment';
import { capitalizeFirstLetter, getCtype } from '../../../../../utils/text';
import { SourceIconLabel } from '../../../../../components/SourceIconLabel';
import { getPostedByName } from '../../../../../utils/getPostedByName';

export const UserDetails = ({ boosters }) => {
    const ctype = getCtype(boosters?.contentType);
    return (
        <div data-testid="display-text">
            <strong>
                {getPostedByName(boosters?.from, boosters?.displayName)}
            </strong>
            <div className="mt-1">
                <strong>Platform: </strong>
                <SourceIconLabel data={{ contentType: boosters?.contentType }} className="pl-1 d-inline" />
                <p className="d-inline">{ctype.name}</p>
            </div>
            <hr />
            <BasicDetails data={boosters} />
        </div>
    ); };

const BasicDetails = ({ data }) => {
    const { gender, age } = data.demographics || {};
    const ageRange = age ? Object.keys(age).reduce((a, b) => (age[a] > age[b] ? a : b)) : null;
    return (
        <div>
            <p>
                <strong>Engagement:&nbsp;</strong>
                {data?.engagement}
            </p>
            <p>
                <strong>Sentiment:&nbsp;</strong>
                {capitalizeFirstLetter(data?.sentiment)}
            </p>
            <p>
                <strong>Date boosted:&nbsp;</strong>
                {moment(data?.date).format('DD MMM YYYY')}
            </p>
            <p>
                <strong>Location:&nbsp;</strong>
                {data?.locationsDetected}
            </p>
            <p>
                <strong>Gender:&nbsp;</strong>
                {gender ? `${gender.male > gender.female ? 'Male' : 'Female'}` : 'No data found'}
            </p>
            <p>
                <strong>Age Range:&nbsp;</strong>
                {age ? ageRange : 'No data found'}
            </p>
        </div>
    );
};
