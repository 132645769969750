import React, { useState } from 'react';
import { SubClusterItem } from './SubClusterItem';

type SentimentType = {
    sentiment: string
    score: string
}

type SubCLusterProps = {
    subClusterTitle: string
    subClusterSummary: string
    volume: number
    duplicatedDocsCount: number
    sentimentJson: [SentimentType]
    id: string
}

type ClusterProps = {
    id: string
    subClusters: [SubCLusterProps]
}

type SubClustersProps = {
    clusterData: ClusterProps
}

export const SubClusters = ({ clusterData }: SubClustersProps) => {
    const subClusters = clusterData?.subClusters;
    const [shownDetails, setShownDetails] = useState({});
    const toggleSubClusterDetails = (id: any) => {
        setShownDetails((prevShownDetails: any) => ({
            ...prevShownDetails,
            [id]: !prevShownDetails[id],
        }));
    };
    return (
        <div className="bg-white px-2 pb-2 maxh-420 scrollbar-small overflow-y-auto overflow-x-hidden">
            <div className="subClusterList">
                {
                    subClusters.map((sub: SubCLusterProps) => (
                        <SubClusterItem key={sub.id}
                            subCluster={sub}
                            shownDetails={shownDetails}
                            toggleDetails={toggleSubClusterDetails}
                        />
                    ))
                }
            </div>
        </div>
    );
};
