import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useTour } from '@reactour/tour';
import { TooltipItem } from '../../../../components/ToolTip';
import { SearchInput } from '../../../../components/Molecules';
import { Settings } from './Settings';
import { NetworksHistoryStore, setSearch } from '../store';
import { SavedNetworks } from './SavedNetworks';
import { NetworksChartProps } from '../NetworksChart';

type NetworksTopBarProps = {
    totalCount: number
    timelineRef: NetworksChartProps['timelineRef']
}

export const NetworksTopBar = ({ totalCount, timelineRef }: NetworksTopBarProps) => {
    const dispatch = useDispatch();
    const { filters } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    const { setIsOpen } = useTour();

    const handleSearch = (value: string) => {
        dispatch(
            setSearch({
                search: value
            })
        );
    };
    return (
        <>
            <div className="d-flex align-items-center">
                <div>
                    <h3 className="m-0">
                        Networks
                        <TooltipItem id="networksTooltip"
                            placement="bottom-start"
                            content={(
                                <div>
                                    <p>
                                        Common links between posts or comments are found as more data is ingested to the Situation Room
                                        over time. You can inspect the links and the shared themes here, visualizing the networks in an
                                        interactive format.
                                    </p>
                                    <a href="https://logically-intelligence.readme.io/docs/explore"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button color="link" className="p-0">Read more</Button>
                                    </a>
                                    <Button color="link" className="p-0 ml-2" onClick={() => setIsOpen(true)}>Feature tour</Button>
                                </div>
                            )}
                        />
                    </h3>
                </div>
                <div className="flex-1">
                    {totalCount > 5000 && (
                        <p className="mb-0 ml-2">
                            Displaying content with the highest engagement (limited to the top 5000 results)
                        </p>
                    )}
                </div>

            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
                <SearchInput placeholder="Search"
                    className="flex-1 mb-n1"
                    handleSearch={handleSearch}
                    defaultValue={filters.search || ''}
                    key={filters.search}
                />
                <SavedNetworks timelineRef={timelineRef} />
                <Settings />
            </div>
        </>
    );
};
