import React from 'react';
import { getPostedByName } from '../../utils/getPostedByName';
import { getCtype } from '../../utils/text';

type Data = {
    contentType: string,
    from?: string,
    displayName?: string,
    url?: string
}

type SourceIconLabelProps = {
    data: Data,
    link?: boolean,
    className?: string
}
export const SourceIconLabel = ({ data, link = false, className = '' }: SourceIconLabelProps) => {
    const ctype = getCtype(data.contentType);
    const content = (
        <>
            <span className="mr-1">
                <i className={`fa ${ctype.icon}`}
                    style={
                        ctype.FontFamily
                            ? { fontFamily: `"${ctype.FontFamily}"`, color: `rgb(${ctype.color})` }
                            : { color: `rgb(${ctype.color})` }
                    }
                />
            </span>
            {getPostedByName(data.from, data.displayName)}
        </>
    );
    if (link) {
        return (
            <a href={data.url} target="_blank" rel="noreferrer" className={`${className} dont-break-out`}>
                {content}
            </a>
        );
    }
    return <span className={`${className} dont-break-out`}>{content}</span>;
};
