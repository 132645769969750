import React from 'react';
// import { Disc, List } from 'react-feather';
import { TooltipItem } from '../../../components/ToolTip';

type TopActionBarProps = {
    clusterSize: number
    subClusterSize: number
}

export const TopActionBar = ({ clusterSize, subClusterSize }: TopActionBarProps) => (
    <div className="pt-2 d-flex justify-content-between position-relative z-index">
        <div className="d-flex align-items-center">
            <p className="font-weight-bold m-0">AI clustering</p>
            <TooltipItem id="booleanTrigger"
                placement="right"
                content={(
                    <div>
                        <p>AI clustering uses Generative AI to sort and summarise vast numbers of documents into
                            different groups based on their context and overall themes.
                        </p>
                        <p><strong>Sentiment:</strong>We determine the aggregated sentiment of a cluster by scanning each
                            piece of content within the cluster for semantic signals to identify if it is positive, negative
                            , or neutral.
                        </p>
                        <p><strong>Engagement:</strong>
                            Content engagement refers to the level of engagement and response to a piece of content,
                            such as likes, shares, and comments.
                        </p>
                        {/* <p className="text-primary">View documentation</p>
                        <p className="text-primary mt-2">View information panel</p> */}
                    </div>
                )}
            />
            <span className="ml-3">
                <p className="m-0">Clusters identified: {clusterSize} | Subclusters identified: {subClusterSize}</p>
            </span>
        </div>
        {
            // The below code will be used in next iteration so please ignore the below lines for now.
        }
        {/* <div className="d-flex alig-items-center">
                <div className={`set-disc-box-style cursor-pointer ${!listView && 'bg-primary'}`}
                    onClick={() => setListView(false)}
                    data-testid="visualization-view"
                >
                    <Disc />
                </div>
                <div className={`set-list-box-style cursor-pointer ${listView && 'bg-primary'}`}
                    onClick={() => setListView(true)}
                    data-testid="list-view"
                >
                    <List />
                </div>
            </div> */}
    </div>
);
