import React from 'react';
import { Table } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { EmptyState } from './EmptyState';
import { TooltipItem } from '../../../../components/ToolTip';
import { Loading } from '../../../../components/Loading';

export const NarrativeLocations = ({ lData, isEmptyNarrative, contentLoading }) => {
    const location = useLocation();

    if (contentLoading) {
        return <div className="bg-white p-3 h-100"><Loading relative height={300} /></div>;
    }

    return (
        <div className="bg-white p-3 h-100">
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 data-testid="narrative-location-db" className="m-0 mr-1">What locations are mentioned? </h3>
                    <TooltipItem placement="bottom"
                        tooltipOpen="What locations are mentioned in this narrative?"
                        id="narrativeLocations"
                        // eslint-disable-next-line max-len
                        content="View and monitor where content building towards your monitored Narrative is being generated across the world."
                    />
                </div>
                {(lData?.narrative_locations?.length > 0 && lData?.narrative_locations[0] !== null && !isEmptyNarrative) ? (
                    <Link to={`${location.pathname}${location.pathname.endsWith('/') ? '' : '/'}key-locations`}>
                        View details
                    </Link>
                ) : null}
            </div>
            <LocationTable lData={lData} isEmptyNarrative={isEmptyNarrative} contentLoading={contentLoading} />
        </div>
    );
};

export const LocationTable = ({ lData, isEmptyNarrative }) => {
    const newData = lData?.narrative_locations?.length > 0
    && lData?.narrative_locations[0] !== null
    && lData?.narrative_locations?.map((x) => ({
        ...x,
        location:
            x?.location.toLocaleLowerCase().includes('united states')
            || x?.location.toLocaleLowerCase().includes('america')
                ? 'United States'
                : x.location,
    }));
    const reducedData = newData && newData?.reduce((acc, obj) => {
        // This method will merge the similar named Countries object to a single one.
        let found = false;
        for (let i = 0; i < acc.length; i++) {
            if (acc[i].location === obj.location) {
                found = true;
                acc[i].count += obj.count;
            }
        }
        if (!found) {
            acc.push(obj);
        }
        return acc;
    }, []);
    return (
        <div>
            {lData?.narrative_locations?.length > 0 && lData?.narrative_locations[0] !== null && !isEmptyNarrative ? (
                <Table className="mt-2">
                    <thead>
                        <tr>
                            <th>
                                Locations
                            </th>
                            <th>
                                Mentions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {reducedData.map((item, i) => {
                            if (i > 4) {
                                return null;
                            }
                            return (
                                <tr key={item.location}>
                                    <td>
                                        <p>{item?.location}</p>
                                    </td>
                                    <td>
                                        <p>{item?.count}</p>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="mt-10">
                    <EmptyState />
                </div>
            )}
        </div>
    ); };
