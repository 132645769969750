import React from 'react';

type CheckBoxProps = {
    id: string,
    label: string,
    name: string,
    value: string,
    checked: boolean,
    onChange: () => void,
    updateNarrative?: boolean
}

export const CheckBox = ({
    id,
    label,
    name,
    value,
    checked,
    onChange,
    updateNarrative
}: CheckBoxProps) => (
    <div className="custom-control custom-checkbox ">
        <input type="checkbox"
            className="custom-control-input"
            id={`customControlValidation${id || label?.replace(/ /g, '')}`}
            aria-label="Checkbox for following text input"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
        />
        {label && (
            <label className="custom-control-label mr-2 mt-11"
                htmlFor={`customControlValidation${id || label.replace(/ /g, '')}`}
            >
                <span className={updateNarrative ? 'text-down' : 'ml-2 text-down'}>{label}</span>
            </label>
        )}
        <br />
    </div>
);
