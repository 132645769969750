import React from 'react';
import { Col, Row } from 'reactstrap';
import { formatThreatLabel } from '../../utils/text';

type ThreatLabelsProps = {
    threatLabels: [string]
    whyathreat: string
}

export const ThreatLabels = ({ threatLabels, whyathreat }: ThreatLabelsProps) => {
    if (threatLabels && threatLabels.filter(label => label).length > 0) {
        return (
            <Row className="mt-2">
                {threatLabels.map((label) => (
                    <Col key={label} xs="auto">
                        <div color="dark" className="tag">
                            {formatThreatLabel(label).name}
                        </div>
                    </Col>
                ))}
            </Row>
        );
    }

    if (whyathreat && whyathreat !== 'not-applicable') {
        return (
            <div className="p-0 mt-2">
                <div color="dark" className="tag">
                    {formatThreatLabel(whyathreat)?.name}
                </div>
            </div>
        );
    }

    return null;
};
