import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import { DateRangePicker } from '../../DateRangePicker';
import { TrafficSignal } from '../../TrafficSignal';
import { TooltipItem } from '../../../../../components/ToolTip';
import { KeyDatesContainer } from './KeyDatesTimeline';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';
import { useContent } from '../../../../../services/Content/getContent';
import { SplineChart } from '../../../../../components/Highcharts/Spline';

export const Timeline = ({ data }) => {
    const { data: content } = useContent({
        filters: {
            ids: data?.content_ids
        },
        limit: 5000,
        skipRequest: !data,
        name: 'getNarrativeTimelineContent',
        fragments: ['Metadata', 'Engagement', 'ThreatData']
    });
    const [dateData, setDateRange] = useState({});
    const [isTranslated, setIsTranslated] = useState(false);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: data?.narrative_title, target: 'en' }
        },
    });
    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : data?.narrative_title;
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };
    if (!data) {
        return <div>no data</div>;
    }

    return (
        <>
            <div>
                Monitor how your Narrative is performing over time by visualising how
                much content has been generated relating to it, along with how many
                people are following it.
            </div>
            <div className="ml-0 mr-0 mt-3 d-flex align-items-center justify-content-between">
                <h3 className="mt-0 sentence-format">{isTranslated ? outputText : data?.narrative_title}
                    {data?.lang_verbose === 'English' ? null : (
                        <span role="button"
                            className="ml-2"
                            onClick={(e) => translation(e)}
                        >
                            <TranslateIcon color="#006FF9" size={30} />
                        </span>
                    )}
                </h3>
                <div className="d-flex">
                    <div className="d-flex w-100 align-date-range ml-2">
                        <p className="mr-1 no-white-space">Date range</p>
                        <DateRangePicker setDate={setDateRange} />
                    </div>
                </div>
            </div>
            <Row className="ml-0 mr-0">
                <Col xs={10} md={9} className="mt-3 pl-0">
                    <div className="p-3 bg-white">
                        <VolumeOverTimeRepresentation dateData={dateData} content={content} />
                    </div>
                </Col>
                <Col xs={10} md={3} className="text-center mt-3 pr-0">
                    <div className="bg-white p-3 h-100">
                        <h3 className="m-0 d-inline">Current threat level</h3>
                        <span className="mt-11 ml-1 z-index">
                            <TooltipItem placement="right"
                                tooltipOpen="currentthreatlevel"
                                id="currentthreatlevel"
                                content="Threat levels from a mixture of: Content Metadata, Content Itself, Content Credibility."
                            />
                        </span>
                        <div className="d-table m-auto pt-8">
                            <TrafficSignal threatLabel={data.average_threat_label} page="timeline" />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="ml-0 mr-0 mt-4">
                <Col xs={10} md={12} className="p-0">
                    <div className="bg-white p-3">
                        <KeyDatesContainer data={data} dateData={dateData} content={content} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

const VolumeOverTimeRepresentation = ({ dateData, content }) => {
    const [type, setType] = useState('volume');
    const filteredDocs = content?.filter((item) => item?.pubdatetime >= dateData?.startDate && item?.pubdatetime <= dateData?.endDate);
    const seriesData = [
        {
            data: formatTimelineData(filteredDocs, type),
            name: capitalizeFirstLetter(type),
        }
    ];
    return (
        <>
            <h3 className="m-0">Volume over time</h3>
            <p className="mb-0 mt-3">
                <b>Volume:&nbsp;</b>Visualise how much content was generated in the date
                range
            </p>
            <p className="mb-0">
                <b>Engagement:&nbsp;</b>Visualise how many people interacted with content in the
                date range
            </p>
            <div className="d-flex mt-2">
                <input type="radio"
                    id="volume"
                    name="perf-graph"
                    value="volume"
                    checked={type === 'volume'}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setType('volume');
                        }
                    }}
                />
                <span className="pt-1 text-primary">Volume</span>
                <input className="ml-2"
                    type="radio"
                    id="engagement"
                    name="perf-graph"
                    value="engagement"
                    checked={type === 'engagement'}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setType('engagement');
                        }
                    }}
                />
                <span className="pt-1 text-primary">Engagement</span>
            </div>
            <div className="mt-3">
                <SplineChart height={350} seriesData={seriesData} />
            </div>
        </>
    );
};

export const formatTimelineData = (content, type) => {
    const ret = [];
    let interval = 3600; // default interval is 1 hour
    const dataInOrder = content ? content.sort((a, b) => a.pubdatetime - b.pubdatetime) : [];
    const minDate = dataInOrder[0]?.pubdatetime;
    const maxDate = dataInOrder[dataInOrder.length - 1]?.pubdatetime;
    if (maxDate - minDate > 259200) {
    // if the difference is more than 3 days then set the interval to 1 day
        interval = 86400;
    }
    if (maxDate - minDate > 7776000) {
    // if the difference is more than 3 months then set the interval to 1 month
        interval = 2592000;
    }
    for (const item of dataInOrder) {
        const numToAdd = type === 'engagement' ? item.engagement : 1;
        if (!ret[0]) {
            ret.push([item.pubdatetime, numToAdd]);
        } else if (ret[ret.length - 1][0] + interval > item.pubdatetime) {
            ret[ret.length - 1][1] += numToAdd;
        } else {
            ret.push([item.pubdatetime, numToAdd]);
        }
    }
    return ret.map((a) => ({ x: new Date(a[0] * 1000).getTime(), y: a[1] }));
};
