import React from 'react';
import { ContentTableView } from '../Organisms/ContentTableView';

type MatchesContentTableProps = {
    isPreview?: boolean
}

export const MatchesContentTable = ({ isPreview }: MatchesContentTableProps) => (
    <>
        This is the placeholder for Matches Content Table {isPreview && '(Preview)'}
        <ContentTableView />
    </>
);
