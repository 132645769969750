import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip, UncontrolledTooltipProps } from 'reactstrap';

type NewToolTipProps = {
  open: boolean;
}

export const NewToolTip = ({ open }: NewToolTipProps) => (
    <i className="fas fa-question-circle font-size-16" role="button" style={{ color: open ? '#025AC7' : '#006FF9' }} />
);

type TooltipItemProps = {
  id: string;
  content: React.ReactNode;
  placement: UncontrolledTooltipProps['placement']
  isHover?: boolean;
  testid?: string;
  withoutIcon?: boolean;
  offset?: string;
  className?: string;
}

export const TooltipItem = ({
    id,
    content,
    placement = 'top', // default placement in reactstrap it 'top', it won't allow empty string
    isHover = false,
    testid = '',
    withoutIcon = false,
    offset = '',
    className = '',
}: TooltipItemProps) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const onClickBody = useCallback(() => {
        if (tooltipOpen) {
            setTooltipOpen(false);
        }
    }, [tooltipOpen]);

    useEffect(() => {
        document.body.addEventListener('click', onClickBody);
        return () => document.body.removeEventListener('click', onClickBody);
    }, [onClickBody]);

    if (withoutIcon) {
        return (
            <Tooltip trigger={isHover ? 'hover' : 'click'}
                placement={placement}
                isOpen={tooltipOpen}
                target={`Tooltip-${id}`}
                toggle={toggle}
                offset={offset}
                className={className}
                data-testid={testid || 'tool-tip-button'}
            >
                {content}
            </Tooltip>
        );
    }

    return (
        <span role="button" className="ml-1" id={`Tooltip-${id}`} data-testid={testid || 'tool-tip-button'}>
            <NewToolTip open={tooltipOpen} />
            <Tooltip trigger={isHover ? 'hover' : 'click'}
                placement={placement}
                isOpen={tooltipOpen}
                target={`Tooltip-${id}`}
                toggle={toggle}
            >
                {content}
            </Tooltip>
        </span>
    );
};
