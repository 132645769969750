import React from 'react';
import { Info, X } from 'react-feather';
import { Alert as ReactAlert } from 'reactstrap';

type InfoBannerProps = {
    children: React.ReactNode;
    closeBanner: () => void;
}

export const InfoBanner = ({ children, closeBanner }: InfoBannerProps) => (
    <ReactAlert color="primary-hover"
        isOpen
        className="info-banner d-flex justify-content-between align-items-center p-2"
        data-testid="info-banner"
    >
        <div className="d-flex flex-direction-row align-items-center w-100">
            <div>
                <Info color="#006FF9" size={20} className="cursor-pointer" />
            </div>
            <div className="d-flex flex-direction-row align-items-center px-2 w-100">{children}</div>
        </div>
        <div>
            <X color="#006FF9" size={20} role="button" onClick={closeBanner} data-testid="alert-x-icon" />
        </div>
    </ReactAlert>
);
