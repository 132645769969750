import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useUser } from '../../../context/user/UserContext';
import { getRoom } from '../../../utils/variables';
import { SituationRoomsDropdown } from '../TopBar/SituationRoomsDropdown';
import { useOverview } from '../../../context/overview/OverviewContext';
import { closeBooleanFilters, closeFilters } from '../store';

export const OverviewTopBar = () => {
    const { user } = useUser();
    const room = getRoom();
    const { setEdit, canEdit } = useOverview();
    const dispatch = useDispatch();

    const enableEditMode = () => {
        dispatch(closeFilters());
        dispatch(closeBooleanFilters());
        setEdit(true);
    };

    return (
        <Row className="align-items-center justify-content-between px-4 pb-2 border-bottom">
            <Col xs="auto">
                <SituationRoomsDropdown currentPage="Overview" />
            </Col>
            <Col xs="auto">
                {canEdit && <Button color="link" className="p-0" onClick={enableEditMode}>Edit overview</Button>}
                {user.role === 'ADMIN' && (
                    <Link to={`/situation-room/edit-room/${room.id}`} className="ml-3">
                        Edit room
                    </Link>
                )}
            </Col>
        </Row>
    );
};
