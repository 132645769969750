import React from 'react';

type ActorsListProps = {
    actor: string
    contentIds: string[]
}

export const ActorsList = ({ actor, contentIds }: ActorsListProps) => (
    <>
        <div className="d-flex justify-content-between align-items-center p-2">
            <p className="w-75 text-primary">{actor}</p>
            <p className="w-25">{contentIds.length || 0}</p>
        </div>
        <hr className="set-underline-props" />
    </>
);
