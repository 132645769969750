import React from 'react';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { Tabs } from '../../../components/Tabs';
import { EmergingNarratives } from './EmergingNarratives';
import { SavedComparisons } from './SavedComparisons';
import { TrackingNarratives } from './TrackingNarratives';
import { getRoom } from '../../../utils/variables';

export const NarrativesContainer = () => {
    const { project_name: roomName, id: roomId } = getRoom();
    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: roomName, href: `/situation-rooms/${roomId}` },
        { title: 'Narratives' }
    ];

    return (
        <main className="p-4">
            <BreadCrumbs items={bread} />
            <h2 className="my-4">Narratives</h2>
            <Tabs small
                param="tab"
                data={[
                    {
                        name: 'Emerging Narratives',
                        id: 'Emerging Narratives',
                        content: <EmergingNarratives />,
                    },
                    {
                        name: 'Tracking Narratives',
                        id: 'Tracking Narratives',
                        content: <TrackingNarratives />,
                    },
                    {
                        name: 'Saved Comparisons',
                        id: 'Saved Comparisons',
                        content: <SavedComparisons />,
                    }
                ]}
            />
        </main>
    );
};
