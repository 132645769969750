import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Deleted } from '../../assets/svg/deleted';

type RemoveContentSuccessModalProps = {
    open: boolean;
    onClose: () => void;
}

export const RemoveContentSuccessModal = ({ open, onClose }: RemoveContentSuccessModalProps) => (
    <Modal isOpen={open} toggle={onClose}>
        <ModalBody>
            <Deleted />
            <h2 className="pt-2">Selected content removed successfully.</h2>
            <hr />
            <Button onClick={onClose}>Close</Button>
        </ModalBody>
    </Modal>
);
