import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from '../../../../components/Tabs';
import { Summary } from './Summary';
import { TopKeywords } from './TopKeywords';
import { SubClusters } from './SubClusters';
import { TopActors } from './TopActors';
import { TopSources } from './TopSources';
import { RootState } from '../../../../store';

export const TabsContainer = ({ cluster, isParent }: any) => {
    const [setTab, setChangeTabFn] = useState<(a: string) => void>(() => undefined);
    const tabs = [
        { name: 'Summary', id: 'summary', content: <Summary clusterData={cluster[0]} isParent={isParent} setTab={setTab} /> },
        { name: 'Subclusters', id: 'subClusters', content: <SubClusters clusterData={cluster[0]} /> },
        { name: 'Top actors', id: 'topActors', content: <TopActors topActors={cluster[0].topActors} /> },
        { name: 'Top sources', id: 'topSources', content: <TopSources topSources={cluster[0].topSources} /> },
        { name: 'Top keywords',
            id: 'keywords',
            onlyRenderWhenActive: true,
            content: <TopKeywords topKeywords={cluster[0].keyphrases} /> }
    ];
    const { activeTab } = useSelector((state: RootState) => state.activeTab);
    return (
        <Tabs className="bg-white p-2 mb-0"
            prevKey={activeTab}
            small
            data={isParent ? tabs : tabs.filter(tab => tab.id !== 'subClusters')}
            goToTab={(fn) => setChangeTabFn(() => fn)}
        />
    );
};
