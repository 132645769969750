import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

export const DateRangePicker = ({ setDateData, setDate, setDateValue, setDateTime, setDateforEmotion }) => {
    const [focus, setFocus] = useState(false);
    const dateRef = useRef(null);
    const [dateRange, setRange] = useState({
        startDate: moment(moment().subtract(5, 'years').startOf('month').toDate()).unix(),
        endDate: moment(moment().endOf('day').toDate()).unix(),
    });

    useEffect(() => {
        if (setDateData !== undefined && setDateData !== null) {
            setDateData(dateRange);
        }
        if (setDate !== undefined) {
            setDate(dateRange);
        }
        if (setDateValue !== undefined) {
            setDateValue(dateRange);
        }
        if (setDateTime !== undefined) {
            setDateTime(dateRange);
        }
        if (setDateforEmotion !== undefined) {
            setDateforEmotion(dateRange);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    const handleCallback = (start, end) => {
        setRange({
            startDate: start.unix(),
            endDate: end.unix(),
        });
    };

    return (
        <InputGroup border={focus ? 'active' : 'none'} className="d-inline-flex mt-0">
            <Label className="d-flex w-100">
                <ReactDateRangePicker ref={dateRef}
                    onCallback={handleCallback}
                    initialSettings={{
                        startDate: moment().subtract(5, 'years').startOf('month').toDate(),
                        endDate: moment().endOf('day').toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                            'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate()
                            ],
                        },
                    }}
                >
                    <input placeholder="Select date range"
                        type="text"
                        className="form-control mw-190"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    />
                </ReactDateRangePicker>
                <InputGroupAddon addonType="prepend" className={`small-border cursor-pointer ${focus ? 'border-left' : 'border-left'}`}>
                    <InputGroupText className="bg-white border-left-0"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    >
                        <FeatherIcon.Calendar size={18} color="#006FF9" />
                    </InputGroupText>
                </InputGroupAddon>
            </Label>
        </InputGroup>
    );
};
