import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { Tabs } from '../../../components/Tabs';
import { Team } from './Team';
import { Manage } from './Manage';
import { Details } from './Details';
import { Feedback } from './Feedback';
import { getRoom, refetchRoom } from '../../../utils/variables';
import { CrowdtangleCSV } from './CrowdtangleCSV';
import { Loading } from '../../../components/Loading';
import { useUser } from '../../../context/user/UserContext';
import { useGetInstance } from '../../../services/Instance/getInstance';

export const Settings = ({ bread }) => {
    let prevPath = '';
    const history = useHistory();
    const { user } = useUser();
    const { instance } = useGetInstance({ instanceId: user?.instance?.id });
    const refetch = useReactiveVar(refetchRoom);
    useEffect(() => {
        const unregisterHistoryListener = history.listen((a) => {
            if (prevPath.includes('edit-room/')) {
                refetch();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            prevPath = a.pathname;
        });
        return () => {
            unregisterHistoryListener();
        };
    }, [history]);
    const room = useReactiveVar(getRoom);
    const teamRoleRestricted = instance?.plan?.restrictedTeamRole && user.role !== 'ADMIN';
    const crowdtangleCSV = room.instance?.plan?.integrations?.crowdtangleCSV;
    const contentActionsEnabled = !teamRoleRestricted && room.instance?.plan?.others?.contentActions;

    if (!room.id) { return <Loading />; }

    return (
        <div className="page-padding">
            {bread}
            <h2 className="my-4">Room settings</h2>
            <Tabs param="t"
                data={[
                    {
                        id: 'details',
                        name: 'Details',
                        content: <Details />
                    },
                    {
                        id: 'manage',
                        name: 'Manage',
                        content: <Manage />
                    },
                    ...(!teamRoleRestricted ? [{
                        id: 'team',
                        name: 'Team',
                        content: <Team />
                    }] : []),
                    ...(contentActionsEnabled ? [{
                        id: 'removedContent',
                        name: 'Removed content',
                        content: <Feedback />
                    }] : []),
                    ...(crowdtangleCSV ? [{
                        id: 'crowdtangleCSV',
                        name: 'CSV Upload',
                        content: <CrowdtangleCSV />
                    }] : [])
                ]}
            />
        </div>
    );
};
