/* eslint-disable @typescript-eslint/no-empty-function */
import { makeVar } from '@apollo/client';

export { getRoom } from './room';
export { getUser } from './user';

export const refetchRoom = makeVar(() => {});
export const getWatchlist = makeVar([]);
export const refetchWatchlist = makeVar(() => {});
export const currentTab = makeVar(false);
export const topSharedUrls = makeVar([]);
export const getTeam = makeVar([]);

type ContentType = {
    id: string,
    key: string,
    name: string,
    color: string,
    FontFamily: string,
    nodeColor: string,
    iconColor: string,
    icon: string
};

export const allCtypesMongo = makeVar<ContentType[]>([]);

export const getFilterQuery = makeVar({});
export const refetchGetNarratives = makeVar({});
export const languagesList = makeVar([]);
