import React, { useState } from 'react';
import { RequestInvestigationDone } from './RequestInvestigationDone';
import { RequestInvestigationForm } from './RequestInvestigationForm';

export const RequestInvestigation = ({
    urlOfSource = '',
    threatTest = false,
    defaultDetails,
    noModal = false,
    onClose
}) => {
    const [done, setDone] = useState(false);

    return done ? (
        <RequestInvestigationDone noModal={noModal} onClose={onClose} />
    ) : (
        <RequestInvestigationForm defaultDetails={defaultDetails}
            urlOfSource={urlOfSource}
            threatTest={threatTest}
            noModal={noModal}
            setDone={setDone}
            onClose={onClose}
        />
    );
};
