import React, { useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { largeNumber } from '../../utils/NumberFormat';
import { formatThreatLabel, getCtype, truncateString } from '../../utils/text';
import { useTranslateMultipleText } from '../../services/google/translate';
import { getHighlightedContent } from '../../utils/getHighlightedContent';
import { TranslateButton } from '../Atoms/TranslateButton';
import { getPostedByName } from '../../utils/getPostedByName';
import { getContentLink } from '../../utils/getContentLink';
import { useQueryExtracts } from '../../services/Content/getQueryExtracts';
import { formatDate, formatDateLocal } from '../../utils/getTimeFrom';
import { getRoom } from '../../utils/variables';
import { WatchlistButtonContainer, WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { WATCHLIST_BUTTON_TYPES } from '../../pages/Room/Threats/Threat/WatchlistButton';
import { useGetWatchlist } from '../../services/Threat/getWatchlist';
import { SourceIconLabel } from '../SourceIconLabel';
import { ContentURL } from './ContentURL';
import { ContentActionsDropdown } from '../ContentTable/ContentActionsDropdown';
import { KeywordsModal } from './KeywordsModal';
import { useModal } from '../../context/modal/ModalComponent';
import { TranslatedFromLanguage } from '../../utils/TranslatedFromLanguage';
import { Content } from '../../services/Content/contentInterface';

type ContentProps = 'body' | 'datetime' | 'contentType' | 'text' | 'url' | 'id' | 'threatObject' | 'engagement'
| 'langVerbose' | 'detectedLangs' | 'from' | 'displayName' | 'pubdatetime' | 'whyathreat' | & WatchlistButtonContentKeys;

type ActivityItemProps = {
    content: Pick<Content, ContentProps>,
    preview: boolean,
    keywordsToHighlight: [string]
}

export const ActivityItem = ({
    content,
    preview,
    keywordsToHighlight
}: ActivityItemProps) => {
    const location = useLocation();
    const room = getRoom();
    const [showLanguages, setShowLangauges] = useState(false);
    const { setModal } = useModal();
    const ctype = getCtype(content.contentType);
    const urlRegex = /(https?:\/\/[^ ]*)/;
    const fullText = content?.body?.length > 0 ? content?.body?.replace(urlRegex, '') : content?.text?.replace(urlRegex, '');
    const { highlightedText, highlightedURL, keywordsMentioned } = useQueryExtracts({
        url: content.url,
        text: fullText,
        keywordsToHighlight
    });

    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: highlightedText
    });
    const translatedText = translated.length ? translated[0].translations[0] : highlightedText;

    const { watchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: any) => id === content.id).length > 0 : false;

    const openKeywordsModal = () => setModal({
        component: <KeywordsModal keywords={keywordsMentioned} />
    });
    const isThreat = content?.threatObject?.threatLevel !== 'none';
    const isNotSituationRoom = location?.pathname?.includes('create-room') || location?.pathname?.includes('edit-room')
     || location?.pathname?.includes('edit-draft-room');

    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;
    const dateInLocalTimeZone = room.instance?.plan?.others?.dateInLocalTimeZone;

    const pageContent = (
        <>
            <div className="d-flex justify-content-between">
                <div className="d-flex pt-1 flex-wrap mb-1 dont-break-out">
                    <p className="m-0 mr-1">
                        <strong>
                            Engagement: {content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}
                        </strong> |&#160;
                        Keywords mentioned:
                        <span className="text-primary cursor-pointer px-1"
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); openKeywordsModal(); }}
                        >
                            {keywordsMentioned.length}
                        </span>
                    </p>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <TranslateButton className="ml-1"
                        onClick={(e) => {
                            e.preventDefault();
                            getTranslation();
                            setShowLangauges(!showLanguages);
                        }}
                        lang={content.langVerbose}
                        detectedLangs={content.detectedLangs}
                        loading={translateLoading}
                    />
                    {(room && !preview && contentActionsEnabled) && (
                        <ContentActionsDropdown content={content}
                            additionalActions={(isThreat && watchlistEnabled) ? [{
                                key: 'watchlist-button',
                                value: <WatchlistButtonContainer contents={[{ ...content, text: fullText }]}
                                    isWatching={isWatching}
                                    type={WATCHLIST_BUTTON_TYPES.TEXT}
                                />
                            }] : []}
                        />
                    )}
                </div>
            </div>
            <p className="mb-1 pr-1">
                <span>
                    <span className="ctype-circle-small" style={{ backgroundColor: `rgb(${ctype.color})` }} />
                    <span><strong>{getPostedByName(content.from, content.displayName)}</strong> via </span>
                    <SourceIconLabel data={{ contentType: content.contentType }} className="pl-1 d-inline" />
                    <span className="ctype font-weight-bold">
                        {ctype.name}
                    </span>
                </span>
            </p>
            <p className="single-line-ellipsis">
                <ContentURL url={highlightedURL || content.url} />
            </p>
            <p>
                {formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}
                {dateInLocalTimeZone && (
                    ` | ${formatDateLocal(content.pubdatetime, 'DD/MM/YYYY @ HH:mm:ss z')}`
                )}
            </p>
            <div className="my-2">
                {
                    content?.threatObject?.threatLabels?.length ? (
                        content.threatObject.threatLabels.map((label, index) => {
                            if (!label) return null;
                            return (
                                <div key={label} color="dark" className={`small tag mr-1 ${index > 2 ? 'mt-1' : ''}`}>
                                    {formatThreatLabel(label).name}
                                </div>
                            );
                        })
                    ) : (
                        content?.whyathreat !== 'not-applicable' && (
                            <div color="dark" className="small tag">
                                {formatThreatLabel(content?.whyathreat)?.name}
                            </div>
                        )
                    )
                }
            </div>
            <div className="mt-0 mb-0 dont-break-out">
                <p className="highlight-em dont-break-out d-inline">
                    {showLanguages && !translateLoading && (
                        <TranslatedFromLanguage languageVerbose={content?.langVerbose} />
                    )}
                    {getHighlightedContent(truncateString(translatedText, 190))}&nbsp;
                    {(translatedText.length > 210 && room?.id && !isNotSituationRoom) ? (
                        <Button color="link" className="p-0 mw-0">Read more</Button>
                    ) : null}
                </p>
            </div>
            <hr className="mx-n3" />
        </>
    );

    if (!room?.id) {
        return pageContent;
    }

    return (
        <Link to={!preview ? getContentLink({ id: content?.id, threatLevel: content?.threatObject?.threatLevel }) : '#'}
            className="text-secondary"
        >
            {pageContent}
        </Link>
    );
};
