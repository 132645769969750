import React, { useState, useMemo, useContext, createContext } from 'react';

export const InvestigationContext = createContext({});

export const useInvestigation = () => useContext(InvestigationContext);

export const InvestigationContextProvider = ({ children, investigation }) => {
    const [emailList, setEmailList] = useState(investigation.contactEmails || []);
    const [file, setFile] = useState(investigation.report || false);
    const [investigationStatus, setInvestigationStatus] = useState(investigation.status || '');
    const [note, setNote] = useState(investigation.note || '');
    const context = useMemo(() => ({
        investigation,
        emailList,
        setEmailList,
        file,
        setFile,
        investigationStatus,
        setInvestigationStatus,
        note,
        setNote
    }), [emailList, file, investigation, investigationStatus, note]);

    return (
        <InvestigationContext.Provider value={context}>
            { children }
        </InvestigationContext.Provider>
    );
};
