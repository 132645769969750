import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Button } from 'reactstrap';
import { Loading } from '../../../../../components/Loading';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { ContentModalInner } from './ContentModalInner';

export const ContentModal = ({ id }) => {
    const { closeModal } = useModal();
    const { data, loading } = useQuery(GET_CONTENT, {
        variables: {
            query: {
                ids: {
                    values: id
                }
            }
        }
    });
    if (loading) {
        return <Loading relative height={300} />;
    }
    const content = data.contentServiceSearch?.hits || [];
    if (!content[0]) {
        return (
            <div>
                <p>Oops, looks like something went wrong. Please try again later.</p>
                <Button onClick={closeModal}>Close</Button>
            </div>
        );
    }
    return <ContentModalInner content={content} id={id} />;
};

export const GET_CONTENT = gql`
    query contentServiceSearch(
        $q: String
        $query: ContentESQuery
        $sort: JSON
        $limit: Int
        $skip: Int
        $aggs: [ContentESAggBlock]
        $highlight: JSON
        $opts: ContentESContentESOpts
    ) {
        contentServiceSearch(
            q: $q
            query: $query
            sort: $sort
            limit: $limit
            skip: $skip
            aggs: $aggs
            highlight: $highlight
            opts: $opts
        ) {
            hits {
                _source {
                    from
                    text
                    display_name
                    ctype
                    datetime_tz
                    engagement
                    body
                    locations_detected
                    threat_object {
                        threat_level
                    }
                }
            }
        }
    }
`;
