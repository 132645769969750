import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { getRoom } from '../../utils/variables';

type NotThreatSuccessModalProps = {
    open: boolean,
    onClose: () => void
}

export const NotThreatSuccessModal = ({ open, onClose }: NotThreatSuccessModalProps) => {
    const room = getRoom();

    return (
        <Modal isOpen={open} toggle={onClose}>
            <ModalBody>
                <h2>This content was successfully marked as not a threat</h2>
                <hr />
                <p>You will be able to find it in the matches section on the
                    <a href={`${window.location.origin}/situation-rooms/${room?.id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}Overview page.
                    </a>
                </p>
                <hr />
                <Button onClick={onClose}>Close</Button>
            </ModalBody>
        </Modal>
    );
};
