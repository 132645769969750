import React, { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
// import { CustomInput } from 'reactstrap';
import { resetActiveTab } from '../../../store';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { ContentTable, ContentTableTypes } from '../../../../../components/ContentTable';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { NoDataFound } from '../../../Narratives/Performance/Nodatafound';
import { ClusterDetails } from './ClusterDetails';
import { SearchInput } from '../../../../../components/Molecules';
import { SearchSortDropdown } from '../../../../../components/Search/SearchSortDropdown';

type ContentViewProps = {
    clusterData: [any]
    isParentCluster: boolean
}

const sortOptions = {
    date: 'Most recent',
    engagement: 'Engagement',
    score: 'Relevance'
};

export const ContentView = ({ clusterData, isParentCluster }: ContentViewProps) => {
    const cluster = clusterData[0];
    const dispatch = useDispatch();
    const [offset, setOffset] = useState<number>(0);
    // const [ischecked, setIsChecked] = useState(false);
    const [sortOption, setSortOption] = useState(sortOptions.date);
    const [sort, setSort] = useState('date');
    const [searchText, setSearchText] = useState('');
    const contentIds = isParentCluster
        ? cluster.subClusters.map((subCluster: any) => subCluster.contentIds.map((content: any) => content.id)).flat()
        : cluster.contentIds.map((content: any) => content.id).flat();
    const limit = 10;
    const clusterContentData = isParentCluster
        ? cluster.subClusters.map((subCluster: any) => subCluster.contentIds).flat()
        : cluster.contentIds.flat();
    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const sortApplied = (sortNum: number) => {
        const sortKeys = Object.keys(sortOptions);
        setSort(sortKeys[sortNum - 1]);
        setOffset(0);
    };
    const handleSort = (num: number, e: React.MouseEvent<any>) => {
        sortApplied(num);
        setSortOption(e.currentTarget.name);
    };
    const { loading, data: contents, count } = useContent({
        filters: {
            ids: contentIds,
            ...(searchText ? { search: searchText } : {}),
        },
        sort,
        limit,
        skip: offset,
        fetchPolicy: 'no-cache',
        name: 'getClusterContents'
    });
    return (
        <>
            <div className="p-4 bg-white d-flex justify-content-between">
                <h2 className="m-0">Cluster contents</h2>
                <X className="text-primary cursor-pointer" onClick={() => dispatch(resetActiveTab())} />
            </div>
            <hr className="m-0" />
            <div className="d-flex align-item-start">
                <ClusterDetails isParentCluster={isParentCluster} cluster={cluster} />
                <div className="p-4 w-100">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <SearchInput placeholder="Search by keywords" handleSearch={handleSearch} className="w-75" />
                        <SearchSortDropdown score sortOption={sortOption} handleSort={handleSort} />
                        {/* <div className="mb-1 d-flex">
                            <CustomInput type="checkbox"
                                checked={ischecked}
                                id="cluster-duplicate-contents"
                                onChange={() => { setIsChecked(prevState => !prevState); }}
                            /> Show duplicate contents
                        </div> */}
                    </div>
                    {contents.length > 0 && contents[0] !== null ? (
                        <ContentTable contents={contents}
                            pagination={{ offset, setOffset, count }}
                            tableType={ContentTableTypes.MATCHES}
                            contentType={CONTENT_TYPE.MATCH}
                            total={count}
                            contentsToDisplay={limit}
                            clusterData={clusterContentData}
                        />
                    ) : (
                        <div className="bg-white p-4">
                            {loading ? <Loading relative height={300} /> : <NoDataFound />}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
