import React from 'react';

export const Archived = () => (
    <svg width="112" height="81" viewBox="0 0 112 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#E5E6EB" d="M.005 68.824a4.083 4.083 0 0 0 4.083 4.082h102.967a4.083 4.083 0 0 0 4.083-4.082V11.537H.005v57.287Z" />
        <path fill="#D2D4DB" d="M107.05 0H4.083A4.083 4.083 0 0 0 0 4.083v7.453h111.133V4.078A4.084 4.084 0 0 0 107.05 0Z" />
        <path fill="#F8F8F8"
            d="M40.055 22.439h-28.23c-.951 0-1.723.771-1.723 1.723v33.837c0 .951.772
            1.723 1.724 1.723h28.23c.951 0 1.723-.772 1.723-1.724V24.163c0-.952-.772-1.723-1.724-1.723Zm61.401
            0H52.103c-.952 0-1.723.771-1.723 1.723V36.93c0 .951.771 1.723 1.723 1.723h49.353c.952 0 1.723-.772
            1.723-1.723V24.16c0-.951-.771-1.723-1.723-1.723Zm0 19.785H52.103c-.952 0-1.723.771-1.723
            1.723v12.769c0 .951.771 1.723 1.723 1.723h49.353c.952 0 1.723-.772 1.723-1.723v-12.77c0-.95-.771-1.722-1.723-1.722Z"
        />
        <path fill="#E5E6EB" d="M23.46 35.022 14.544 45.7h17.835l-8.917-10.678Z" />
        <path fill="#fff" d="M30.343 33.822 20.423 45.7h19.841l-9.921-11.878Z" />
        <path fill="#E5E6EB" d="M18.351 35.895a2.073 2.073 0 1 0 0-4.146 2.073 2.073 0 0 0 0 4.146Z" />
        <path fill="#F8F8F8" d="M7.094 7.597a1.779 1.779 0 1 0 0-3.557 1.779 1.779 0 0 0 0 3.557Z" />
        <path fill="#E5E6EB"
            d="M11.456 7.597a1.778 1.778 0 1 0 0-3.557 1.778 1.778 0 0 0 0 3.557Zm4.628 0a1.779
            1.779 0 1 0 0-3.557 1.779 1.779 0 0 0 0 3.557Z"
        />
        <path fill="#D2D4DB"
            d="M96.625 28.728H55.981a.63.63 0 1 1 0-1.258h40.644a.629.629 0 0 1 0 1.258Zm0 4.892H55.981a.629.629
            0 0 1 0-1.257h40.644a.63.63 0 0 1 0 1.258Zm0 14.895H73.89a.63.63 0 0 1 0-1.258h22.735a.629.629
            0 0 1 0 1.258Zm0 4.893H73.89a.629.629 0 0 1 0-1.258h22.735a.63.63 0 1 1 0 1.258Z"
        />
        <path fill="#C8CACE"
            d="M55.566 80.002c9.22 0 16.694-7.474 16.694-16.693 0-9.22-7.474-16.693-16.694-16.693-9.22
            0-16.693 7.474-16.693 16.693 0 9.22 7.474 16.693 16.693 16.693Z"
        />
        <path fill="#fff"
            d="M61.888 70.428H48.905a.625.625 0 0 1-.629-.629v-9.378a.63.63 0 0 1 1.258
            0v8.75H61.26v-8.75a.63.63 0 0 1 1.258 0V69.8a.629.629 0 0 1-.629.63Z"
        />
        <path fill="#fff"
            d="M63.329 61.05H47.458a.627.627 0 0 1-.629-.628v-3.605a.629.629 0 0
            1 .629-.629h15.87a.629.629 0 0 1 .63.629v3.605a.627.627 0 0
            1-.63.629Zm-15.242-1.257H62.7v-2.347H48.087v2.347Zm8.75 4.142H53.95a.629.629 0 1
            1 0-1.257h2.886a.629.629 0 0 1 0 1.258Z"
        />
    </svg>

);
