import { gql, useQuery } from '@apollo/client';
import { capitalize } from 'lodash';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTopLanguages = ({ dateFilter = {}, size = 10, includeThreats = false, useGlobalFilters = true } = {}) => {
    const defaultFilters = (dateFilter?.startDate || dateFilter?.endDate) ? { date: dateFilter } : {};
    const filters = useContentFilters({ useGlobalFilters, defaultFilters });
    const ret = useQuery(getTopLanguagesQuery({ includeThreats }), {
        variables: {
            ...filters,
            size
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.topLanguages?.data?.map(item => ({
            ...item,
            key: item.language,
            language: capitalize(item.language),
            threats: item.threats
        })) || [] : [],
        total: ret.data?.topLanguages?.total || 0
    };
};

export const getTopLanguagesQuery = ({ includeThreats = false } = {}) => gql`
    query topLanguages(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $size: Int
    ) {
        topLanguages(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            size: $size
        ) {
            data {
                language
                count
                ${includeThreats ? 'threats' : ''}
            }
            total
        }
    }
`;
