import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    CornerUpLeft,
    CornerUpRight,
    Download,
    Minus,
    Plus,
    RefreshCw,
    Save
} from 'react-feather';
import { useSelector } from 'react-redux';
import { NetworksChartProps, maxZoom, minZoom } from '.';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ChartExportModal, ChartExportModalProps } from './modals/ChartExportModal';
import { NetworksHistoryStore } from '../store';
import { useNetworkHistory } from './handlers';
import { SaveNetworkGraph } from './modals';
import { ResetGraph } from './modals/ResetGraph';
import { IconButton } from '../../../../components/Atoms';

export type NetworksToolbarRef = {
    setZoom: (zoom: number) => void;
}

interface NetworksToolbarProps extends ChartExportModalProps {
    chartZoom: (direction: 'in' | 'out') => void;
    timelineRef: NetworksChartProps['timelineRef']
}

export const NetworksToolbar = forwardRef(({ chartZoom, chartExport, timelineRef }: NetworksToolbarProps, ref) => {
    const [zoomPercentage, setZoomPercentage] = useState(0);
    const { setModal } = useModal();
    const { past, future } = useSelector((state: NetworksHistoryStore) => state.networksHistory);
    const networkHistory = useNetworkHistory({ timelineRef });

    useImperativeHandle(ref, () => ({
        setZoom: (zoom: number) => {
            const currentZoom = Math.max(minZoom, Math.min(maxZoom, zoom * 1.2));
            let newZoomPercentage = 0;

            if (parseFloat(zoom.toFixed(3)) !== minZoom) {
                newZoomPercentage = Math.round((Math.log(currentZoom / minZoom) / Math.log(maxZoom / minZoom)) * 100);
            }
            if (newZoomPercentage === zoomPercentage) return null;
            setZoomPercentage(newZoomPercentage);
        }
    }));

    const openExportModal = () => {
        setModal({
            header: 'Export image as',
            component: <ChartExportModal chartExport={chartExport} />
        });
    };

    const openSaveModal = () => {
        setModal({
            header: 'Save network graph',
            component: <SaveNetworkGraph />
        });
    };

    const openResetGraphModal = () => {
        setModal({
            header: 'Reset graph',
            component: <ResetGraph resetGraph={() => networkHistory.reset()} />
        });
    };

    return (
        <div className="absolute-bottom-right bg-white mr-4 mb-4 border rounded d-flex align-items-center">
            <div className="p-2 border-right text-primary">
                <IconButton className="mr-2"
                    onClick={() => networkHistory.undo()}
                    dataTestid="history-undo-button"
                    icon={<CornerUpLeft />}
                    disabled={!past.length}
                />
                <IconButton className="mr-2"
                    onClick={() => networkHistory.redo()}
                    dataTestid="history-redo-button"
                    icon={<CornerUpRight />}
                    disabled={!future.length}
                />
                <IconButton className="mr-2 reset-network-graph"
                    onClick={openResetGraphModal}
                    dataTestid="history-reset-button"
                    icon={<RefreshCw />}
                    disabled={!past.length}
                />
                <IconButton className="mr-2 save-network-graph"
                    onClick={openSaveModal}
                    dataTestid="network-save-button"
                    icon={<Save />}
                    disabled={!past.length}
                />
                <IconButton className="export-network-graph"
                    onClick={openExportModal}
                    dataTestid="download-button"
                    icon={<Download />}
                />
            </div>
            <div className="p-2">
                <IconButton className="mr-2"
                    onClick={() => chartZoom('out')}
                    dataTestid="zoom-out-button"
                    icon={<Minus />}
                />
                <span className="user-select-none">{zoomPercentage}%</span>
                <IconButton className="ml-2"
                    onClick={() => chartZoom('in')}
                    dataTestid="zoom-in-button"
                    icon={<Plus />}
                />
            </div>
        </div>
    );
});
