import React from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';
import map from '@highcharts/map-collection/custom/world.geo.json';

type MapbubbleChartProps = {
    seriesData: any[]
}
HighchartsMap(Highcharts);

export const MapbubbleChart = ({ seriesData }: MapbubbleChartProps) => {
    const options = {
        chart: {
            borderWidth: 0,
            map,
            width: 1130,
        },

        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                align: 'right',
                verticalAlign: 'bottom',
                style: {
                    color: '#006FF9'
                },
                theme: {
                    'stroke-width': 1,
                    stroke: '#006FF9',
                    r: 5
                },
            }
        },

        series: seriesData,
    };
    return (
        <HighchartsReact highcharts={Highcharts}
            options={options}
            constructorType="mapChart"
        />
    );
};
