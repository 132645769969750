import React from 'react';
import { Options } from 'highcharts';
import { ResponsiveChart } from './ResponsiveChart';
import { dateTimeLabelFormats } from './config';

type DataPoint = [string, number | null];

type SimpleLineChartProps = {
    data: DataPoint[],
    title: string,
    height?: string | number,
    colors?: string[]
};

export const SimpleLineChart = ({
    data,
    title,
    height = 200,
    colors = ['#006FF9']
}: SimpleLineChartProps) => {
    const options: Options = {
        title: {
            text: ''
        },
        chart: {
            backgroundColor: 'transparent',
            spacingLeft: 0,
            height
        },
        colors,
        series: [{
            type: 'line',
            name: title || '',
            data
        }],
        xAxis: {
            visible: false,
            type: 'datetime'
        },
        yAxis: {
            visible: false
        },
        legend: {
            enabled: false
        },
        tooltip: {
            dateTimeLabelFormats
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: false
                }
            }
        }
    };

    return (
        <ResponsiveChart options={options} />
    );
};
