import React, { useRef, useState } from 'react';
import { NetworksTopBar } from './NetworksTopBar';
import { NetworksChart } from './NetworksChart';
import { getRoom } from '../../../utils/variables';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { NetworksTimelineRef } from './NetworksChart/Timeline';
import { FeatureTour } from '../../../components/FeatureTour';
import { networksTourSteps } from './featureTour';

export const NetworksPage = () => {
    const [totalCount, setTotalCount] = useState(0);
    const room = getRoom();
    const timelineRef = useRef<NetworksTimelineRef>(null);
    return (
        <FeatureTour tourSteps={networksTourSteps}>
            <div className="room-page d-flex flex-column">
                <div className="p-4 border-bottom">
                    <BreadCrumbs items={[
                        { title: 'Situation Rooms', href: '/situation-rooms' },
                        { title: room.project_name, href: `/situation-rooms/${room.id}` },
                        { title: 'Networks' }
                    ]}
                    />
                    <NetworksTopBar totalCount={totalCount} timelineRef={timelineRef} />
                </div>
                <div className="flex-1 d-flex flex-column mh-0">
                    <NetworksChart setTotalCount={setTotalCount} timelineRef={timelineRef} />
                </div>
            </div>
        </FeatureTour>
    );
};
