/* eslint-disable no-nested-ternary */
import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { truncateString, getCtype, formatThreatLabel } from '../../../../utils/text';
import { formatDate } from '../../../../utils/getTimeFrom';
import { PermanentDeleteModal } from './PermanentDeleteModal';
import { RecoverContentModal } from './RecoverContentModal';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { useModal } from '../../../../context/modal/ModalComponent';

export const ContentDetailsModal = ({ open, setOpen, item, user, contentText, setPage }) => {
    const { setModal } = useModal();
    const ctype = getCtype(item?.additional_info?.ctype);
    const handleResetPage = () => setPage(0);
    const deletePermanently = () => setModal({
        header: 'Are you sure you want to permanently delete selected content?',
        component: <PermanentDeleteModal ids={[item?.doc_id]} onSuccess={handleResetPage} />
    });

    const recoverContent = () => setModal({
        header: 'Are you sure you recover this content?',
        component: <RecoverContentModal contentIds={[item?.doc_id]} onSuccess={handleResetPage} />
    });

    return (
        <Modal isOpen={open} toggle={() => setOpen(false)}>
            <ModalBody>
                <h2>Removed content details</h2>
                <hr />
                <h3>{item?.type === CONTENT_TYPE.MATCH ? 'Match' : item?.type === CONTENT_TYPE.THREAT ? 'Threat' : 'Narrative'}</h3>
                {
                    item?.type === CONTENT_TYPE.THREAT && item?.additional_info?.threat_object?.threat_labels.map((label) => {
                        if (!label) { return null; }
                        return (
                            <div key={item?.doc_id} color="dark" className="tag mb-2 mt-1">
                                {formatThreatLabel(label).name}
                            </div>
                        );
                    })
                }
                <p>{contentText || truncateString(item?.additional_info?.text, 350)}
                    <span>
                        {' '}...{' '}
                        <a href={item?.additional_info?.url}
                            target="_blank"
                            id={`fcItem-${item?._id}`}
                            className="d-inline cursor-pointer"
                            rel="noreferrer"
                        >
                            Open source
                        </a>
                    </span>
                </p>
                <div className="mb-0"><strong>Posted by: </strong>{item?.additional_info?.from} via
                    <SourceIconLabel data={{ ctype: item?.additional_info.ctype }} className="pl-1 d-inline" />
                    <p className="d-inline">{ctype?.name}</p>
                </div>
                <p className="mb-0"><strong>Posted on: </strong>
                    {formatDate(item?.additional_info?.published_datetime, 'DD/MM/YYYY @ HH:mm:ss z')}
                </p>
                <p className="mb-0"><strong>Engagement:&nbsp;</strong>{`${item?.additional_info?.engagement}`}</p>
                <p className="mb-0"><strong>Removed by:&nbsp;</strong>{`${item?.created_by}`}</p>
                <p className="mb-0"><strong>Removed on:&nbsp;</strong>
                    {formatDate(item?.created_time, 'DD/MM/YYYY @ HH:mm:ss z')}
                </p>
                <hr />
                {
                    (user?.role === 'ADMIN' || user?.displayName === item?.created_by || user?.firstName === item?.created_by) && (
                        <>
                            <Button color="primary" className="mr-1" onClick={recoverContent}>Recover content</Button>
                            <Button color="danger" className="mr-1" onClick={deletePermanently}>Delete</Button>
                        </>
                    )
                }
                <Button onClick={() => setOpen(false)}>Close</Button>
            </ModalBody>
        </Modal>
    ); };
