import { useQuery } from '@apollo/client';
import { EXPLORE_CONTENT_ES_SERVICE } from '../../../../graphql';

export const useExploreContent = ({
    query,
    limit = 10,
    skip = 0,
    sort = '',
    skipRequest = false,
    fetchPolicy
}) => {
    const ret = useQuery(EXPLORE_CONTENT_ES_SERVICE, {
        variables: {
            limit,
            skip,
            ...(sort ? { sort } : {}),
            opts: { trackTotalHits: true },
            query,
        },
        skip: skipRequest,
        fetchPolicy
    });

    return {
        ...ret,
        data: ret.data ? ret.data.contentServiceSearch?.hits?.map(a => ({
            id: a._id,
            highlight: a.highlight,
            ...a._source
        })) : [],
        aggregations: ret.data ? ret.data.contentServiceSearch?.aggregations : {},
        count: ret.data ? ret.data.contentServiceSearch?.count : 0
    };
};
