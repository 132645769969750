import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getRoom } from '../../utils/variables';
import { getCtype } from '../../utils/text';
import { DropdownWithMultiCheckbox, ItemProps } from './components/DropdownWIthMultiCheckbox/index';

type MultiSelectSourceFilterProps = {
    sourceData: ItemProps[],
    setSourceData: (sourceData: ItemProps[]) => void
};

export const MultiSelectSourceFilter = ({ sourceData, setSourceData }: MultiSelectSourceFilterProps) => {
    const room = getRoom();
    const filters = useSelector((state: any) => state.filters.filters);
    const { data } = useQuery(GET_SUBCATEGORY_DOMAIN, {
        variables: {
            projectId: room?.project_id,
            size: 100
        },
        onCompleted: () => {
            const source = data?.getContentTypesWithDomains?.map((src: {key: string}) =>
                ({ id: src.key, name: getCtype(src.key).name, selected: filters.contentType.includes(src.key) })) || [];
            const sortedSource = source.sort((a: ItemProps, b: ItemProps) => a.name.localeCompare(b.name));
            setSourceData(sortedSource);
        }
    });

    const handleSelectItem = (ids: string[]) => {
        const filteredSourceData = sourceData.map((a) => {
            if (ids.includes(a.id)) {
                return { ...a, selected: true };
            }
            return { ...a, selected: false };
        });
        setSourceData(filteredSourceData);
    };
    const countFilters = (sourceData.filter((a) => a.selected === true)).length;
    return (
        <div>
            <DropdownWithMultiCheckbox handleSelectItem={handleSelectItem}
                filterOptions={sourceData}
                value="sources"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}

            />
        </div>
    );
};

export const GET_SUBCATEGORY_DOMAIN = gql`
    query getContentTypesWithDomains($projectId: String!, $size: Int, $filters: ContentFilters) {
        getContentTypesWithDomains(projectId: $projectId, size: $size, filters: $filters) {
            key
            domains
            domainCount
        }
    }
`;
