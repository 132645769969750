type DateTimeLabelFormats = {
    hour?: string;
    day?: string;
    month?: string;
    week?: string;
}

type ChartColors = string[]

export const dateTimeLabelFormats: DateTimeLabelFormats = {
    hour: '%d/%m/%Y %H:%M',
    day: '%d/%m/%Y',
    month: '%m/%Y',
    week: '%d/%m/%Y'
};

export const chartColors: ChartColors = ['#4367F8', '#D945D0', '#00C0C7', '#FF6C4C', '#FFC700', '#9C50FD', '#ABC700',
    '#295F87', '#B8D3DE', '#26BC8C'];
