import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Alert } from '../../Atoms';
import { BooleanInputValidated } from '../../Molecules/BooleanInputValidated';

type BooleanSearchContentProps ={
    booleanQuery: string,
    setBooleanQuery: (e: string) => void,
    invalidQuery: boolean,
    setInvalidQuery: (e: boolean) => void,
    validationErrors: any,
    setValidationErrors: (e: any) => void,
    disabled: boolean
}

export const BooleanSearchContent = ({
    booleanQuery,
    setBooleanQuery,
    invalidQuery,
    setInvalidQuery,
    validationErrors,
    setValidationErrors,
    disabled
}: BooleanSearchContentProps) => (
    <Container fluid>
        <Row>
            <Col className="p-0">
                <p className="mb-2">Formulate your search query using Boolean operators for even more relevant and precise results.
                    <br /> Before starting, check the&nbsp;
                    <a target="_blank" href="https://logically-intelligence.readme.io/v2.0/docs/new-writing-a-boolean-query" rel="noreferrer">
                        best practices for writing a boolean query.
                    </a>
                </p>
            </Col>
        </Row>
        {invalidQuery && (
            <Row>
                <Alert closeAlert={() => setInvalidQuery(false)}
                    errorMessage="We cannot submit your query, please visit our boolean ‘best practice’ link above and revise your query
                    before resubmitting."
                />
            </Row>
        )}
        <Row className="boolean-search-content" data-testid="boolean-search-content">
            <BooleanInputValidated booleanQuery={booleanQuery}
                setBooleanQuery={setBooleanQuery}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                resetGlobalErrors={() => { setInvalidQuery(false); }}
                disabled={disabled}
                booleanSearch
            />
        </Row>
    </Container>
);
