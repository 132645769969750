import React, { useState, useEffect } from 'react';
import { Collapse, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeBooleanFilters,
    closeFilters,
    openBooleanFilters,
    openFilters,
    saveFilters,
    saveWatchlistFilters
} from '../../pages/Room/store';
import { SearchInput } from './SearchInput';
import { SearchSortDropdown } from './SearchSortDropdown';
import { SearchFilters } from './SearchFilters';
import { BooleanSearch } from './BooleanSearch';
import { OpenButton } from './OpenButton';

const urlRegex = /(https?:\/\/[^\s]+)/g;

type SortOptionsType = {
    datetime: string,
    engagement: string,
    relevance: string
}

const sortOptions: SortOptionsType = {
    datetime: 'Most recent',
    engagement: 'Engagement',
    relevance: 'Relevance'
};

type SearchBarProps = {
    placeholder: string,
    name: string,
    additionalFilters: string[],
    sort: boolean,
    sortOptionKey?: keyof SortOptionsType,
    sortApplied: (e: number) => void,
    score: boolean,
    hiddenFilters?: string[],
    showBooleanSearch?: boolean,
    watchlist?: boolean
}

export const SearchBar = ({
    placeholder,
    name,
    additionalFilters,
    sort,
    sortOptionKey,
    sortApplied,
    score,
    hiddenFilters,
    showBooleanSearch = false,
    watchlist = false
}: SearchBarProps) => {
    const dispatch = useDispatch();
    const filters = useSelector((state: any) => (watchlist ? state.watchlistFilters.filters : state.filters.filters));
    const isBooleanOpen = useSelector((state: any) => (state.filters.isBooleanOpen));
    const isFiltersOpen = useSelector((state: any) => (state.filters.isFiltersOpen));
    const [searchVal, setVal] = useState(filters.query ? filters.query : '');
    const [sortOption, setSortOption] = useState(sortOptions[sortOptionKey || 'datetime']);
    const hasFilters = filters.origin?.length > 0
      || filters.sentiment?.length > 0
      || filters.sources?.length > 0
      || filters.dateRange?.startDate
      || filters.query?.length > 0
      || filters.threatTypes?.length > 0;
    const hasBooleanFilter = filters.booleanQuery?.length > 0;
    useEffect(() => {
        setVal(filters.query);
    }, [filters]);

    const onSaveFilters = () => {
        if (watchlist) {
            dispatch(saveWatchlistFilters({ query: searchVal.replace(urlRegex, (url: string) => `"${url}"`).replaceAll('""', '"') }));
        } else {
            dispatch(saveFilters({ query: searchVal.replace(urlRegex, (url: string) => `"${url}"`).replaceAll('""', '"') }));
        }
        dispatch(closeFilters());
    };
    const handleSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (watchlist) {
            dispatch(saveWatchlistFilters({ query: searchVal.replace(urlRegex, (url: string) => `"${url}"`).replaceAll('""', '"') }));
        } else {
            dispatch(saveFilters({ query: searchVal.replace(urlRegex, (url: string) => `"${url}"`).replaceAll('""', '"') }));
        }
    };
    const handleSort = (num: number, e: React.MouseEvent<any>) => {
        sortApplied(num);
        setSortOption(e.currentTarget.name);
    };

    return (
        <div data-testid="search-bar-component">
            <Row className="align-items-stretch flex-wrap">
                <Col xs={12} sm="auto" className="pr-0 mr-n2 mb-1 sm-flex-1">
                    <SearchInput placeholder={placeholder}
                        searchVal={searchVal}
                        setVal={setVal}
                        handleSearch={handleSearch}
                        showBooleanSearch={showBooleanSearch}
                    />
                </Col>
                {sort && (
                    <Col xs="auto" className="mb-1 pr-0 mr-n2">
                        <SearchSortDropdown score={score} sortOption={sortOption} handleSort={handleSort} />
                    </Col>
                )}
                {showBooleanSearch && (
                    <Col xs="auto" className="mb-1 pr-0">
                        <OpenButton name="Boolean Search"
                            handleClick={() => {
                                if (isBooleanOpen) {
                                    return dispatch(closeBooleanFilters());
                                }
                                dispatch(openBooleanFilters());
                            }}
                            isOpen={isBooleanOpen}
                            hasValues={hasBooleanFilter}
                        />
                    </Col>
                )}
                <Col xs="auto" className="mb-1">
                    <OpenButton name={name || 'Filters'}
                        handleClick={() => {
                            if (isFiltersOpen) {
                                return dispatch(closeFilters());
                            }

                            dispatch(openFilters());
                            dispatch(closeBooleanFilters());
                        }}
                        isOpen={isFiltersOpen}
                        slidersIcon
                        hasValues={hasFilters}
                    />
                </Col>
            </Row>
            <Collapse isOpen={isBooleanOpen} data-testid="boolean-filters">
                <BooleanSearch />
            </Collapse>
            <Collapse isOpen={isFiltersOpen} data-testid="search-filters">
                <SearchFilters onSaveFilters={onSaveFilters}
                    additionalFilters={additionalFilters}
                    hiddenFilters={hiddenFilters}
                    watchlist={watchlist}
                />
            </Collapse>
        </div>
    );
};
