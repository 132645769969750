import { useMutation } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useUser } from '../../../../../context/user/UserContext';
import { DELETE_CUSTOM_NARRATIVE, GET_CUSTOM_NARRATIVES } from '../../../../../graphql';
import { StatusModalBodyNew, STATUS_TYPES } from '../../../../../utils/SuccessErrorModal';
import { getRoom } from '../../../../../utils/variables';

export const CustomNarrativeItem = ({ narrative }) => {
    const room = getRoom();
    const { notAllowed } = useUser();
    const { setModal, closeModal } = useModal();
    const setStatusModal = ({ message, type }) => {
        setModal({
            header: false,
            component: <StatusModalBodyNew message={message} messageType={type} />
        });
    };
    const [deleteCustomNarrative] = useMutation(DELETE_CUSTOM_NARRATIVE, {
        refetchQueries: [GET_CUSTOM_NARRATIVES],
        onCompleted: () => {
            closeModal();
            setStatusModal({
                title: 'Delete Custom Narrative',
                type: STATUS_TYPES.success,
                message: 'Custom Narrative Deleted Successfully'
            });
        }
    });

    const handleDeleteCustomNarrative = (id) => {
        deleteCustomNarrative({
            variables: { id }
        });
    };
    const removeNarrative = () => {
        setModal({
            component: (
                <div>
                    <h2>Delete custom narrative</h2>
                    <hr />
                    <h4>Are you sure you want to delete this Custom Narrative ?</h4>
                    <hr />
                    <Button className="mt-2" color="danger" onClick={() => handleDeleteCustomNarrative(narrative.id)}>Yes</Button>
                    <Button className="ml-3 mt-2" onClick={() => closeModal()}>No</Button>
                </div>
            )
        });
    };
    return (
        <Row className="align-items-center justify-content-between">
            <Col md="auto">
                <Link className="pr-2 pb-2 pt-4  d-block" to={`/situation-rooms/${room?.id}/custom-narrative/${narrative.id}`}>
                    <h3 className="m-0">{narrative.name}</h3>
                    <div className="d-flex align-items-center mt-3">
                        {narrative.data.map((item, index) => {
                            if (index > 2) { return null; }
                            return (
                                <Button disabled
                                    key={item.id}
                                    className="mr-2 bg-gray border-0 text-secondary"
                                    style={{ opacity: 1 }}
                                >
                                    {item.threat}
                                </Button>
                            );
                        })}
                        {narrative.data.length > 3 && (
                            <p className="mb-0">
                                {narrative.data.length - 3} more threat{narrative.data.length - 3 === 1 ? '' : 's'}
                            </p>
                        )}
                    </div>
                </Link>
            </Col>
            {!notAllowed.includes('manageCustomNarratives') && (
                <Col md="auto">
                    <Button color="danger" onClick={removeNarrative}>Delete</Button>
                </Col>
            )}
        </Row>
    );
};
