import React from 'react';
import { X } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { addItemsToArrayAtPosition } from '../../../../../../utils/lib/addItemsToArrayAtPosition';
import { createEditorState } from '../createEditorState';
import { setReportAction } from '../../../../../../redux/reports';

export const getUpdatedReportPages = (report, widgetState, type) => {
    let hasOnePageOfType = false;
    let pages = [...report.pages.map(page => {
        if (page.type === type) {
            if (hasOnePageOfType) {
                return false; // this will reset the pages with same type
            }
            hasOnePageOfType = true;
            return {
                ...page,
                blocks: []
            };
        }
        return page;
    }).filter(a => a)];
    const maxBlockSize = type === 'customNarratives' ? 1 : 3;
    for (const widget of widgetState) {
        let done = false;
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (page.type === type && page.blocks.length < maxBlockSize) {
                pages[i].blocks.push(widget);
                done = true;
            }
        }
        if (!done) {
            const lastOverviewPageIndex = pages.map(a => a.type).lastIndexOf(type) + 1;
            pages = addItemsToArrayAtPosition(pages, lastOverviewPageIndex, [{
                id: uuid(),
                type,
                pageTitle: createEditorState({
                    blocks: [
                        {
                            text: `${reportPageTitles[type]} ${pages.filter(a => a.type === type).length}`
                        }
                    ],
                    entityMap: {}
                }),
                blocks: [widget]
            }]);
        }
    }
    return pages;
};

export const reportPageTitles = {
    cover: 'Cover page',
    overview: 'Overview',
    threats: 'Top Threats',
    narratives: 'Top Narratives',
    customNarratives: 'Top Custom Narratives',
    threatsWatchlist: 'Watchlist - Threats',
    narrativesWatchlist: 'Watchlist - Narratives',
    summary: 'Summary',
    map: 'Map'
};

export const DeleteBlockButton = ({ block, type }) => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const blocks = report.pages.filter(a => a.type === type).map(a => a.blocks).flat();
    return (
        <div role="button"
            data-testid="delete-button"
            className="rounded-circle border border-white bg-danger d-flex align-items-center
        justify-content-center icon-delete-button text-white"
            onClick={() => {
                const pages = getUpdatedReportPages(report, blocks.filter(a => a.id !== block.id), type);
                dispatch(setReportAction({
                    ...report,
                    pages
                }));
            }}
        >
            <X size={15} />
        </div>
    );
};
