/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Trash2 } from 'react-feather';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHtmlToImage } from '../../../../../../components/HTMLToImage';
import { useModal } from '../../../../../../context/modal/ModalComponent';
import { reportPageTitles } from './getUpdatedReportPages';
import { AddNarrativesFromWatchlistModal } from './NarrativesWatchlist/AddNarrativesFromWatchlistModal';
import { AddOverviewWidgetsModal } from './OverviewPage/AddOverviewWidgetsModal';
import { AddThreatsFromWatchlistModal } from './ThreatsWatchlist/AddThreatsFromWatchlistModal';
import { AddCustomNarrativesModal } from './TopCustomNarratives/AddCustomNarrativesModal';
import { AddNarrativesModal } from './TopNarratives/AddNarrativesModal';
import { AddThreatsModal } from './TopThreats/AddThreatsModal';
import { setReportAction } from '../../../../../../redux/reports';
import { getRoom } from '../../../../../../utils/variables';

export const PagesList = () => {
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const pages = reorder(
            report.pages,
            result.source.index,
            result.destination.index
        );
        dispatch(setReportAction({
            ...report,
            pages
        }));
    };
    const getThumbnail = (page, index) => (
        <div>
            <PageThumbnail page={page} index={index + 1} />
        </div>
    );
    return (
        <div className="p-4">
            <p><strong>Pages ({report.pages.length})</strong></p>
            <p>Click to select a slide and drag to top or bottom to arrange the slides</p>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {report.pages.map((page, index) => {
                                if (page.type === 'cover') {
                                    return <div key={page.id}>{getThumbnail(page, index)}</div>;
                                }
                                return (
                                    <Draggable key={page.id}
                                        draggableId={page.id}
                                        index={index}
                                    >
                                        {(providedB) => (
                                            <div ref={providedB.innerRef}
                                                {...providedB.draggableProps}
                                                {...providedB.dragHandleProps}
                                                style={getItemStyle(
                                                    providedB.draggableProps.style
                                                )}
                                            >
                                                {getThumbnail(page, index)}
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const PageThumbnail = ({ index, page }) => {
    const room = getRoom();
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const { report } = useSelector(state => state.createReport);
    const [hover, setHover] = useState(false);
    const img = useHtmlToImage({ id: `page-${page.id}`, data: page });
    const watchlistEnabled = room.instance?.plan?.integrations?.watchlist;
    const editButton = pageEditButtons[page.type];

    if ((page.type === 'threatsWatchlist' || page.type === 'narrativesWatchlist') && !watchlistEnabled) {
        return null;
    }

    return (
        <div className="py-2"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <p>{index} - {reportPageTitles[page.type]}</p>
            <div className={`position-relative rounded border ${hover ? 'border-primary' : 'border-transparent'}`}>
                <img src={img} alt="" className="w-100 rounded" />
                {(page.type !== 'cover' && hover) && (
                    <div className="position-absolute bottom-right">
                        <div role="button"
                            data-testid="trash-button"
                            className="rounded-circle border border-dark
                            bg-danger d-flex align-items-center justify-content-center icon-delete-button"
                            onClick={() => {
                                dispatch(setReportAction({
                                    ...report,
                                    pages: report.pages.filter(a => a.id !== page.id)
                                }));
                            }}
                        >
                            <Trash2 size={12} />
                        </div>
                    </div>
                )}
            </div>
            {editButton ? (
                <Button className="mt-2 w-100"
                    color="secondary"
                    onClick={() => setModal({
                        header: false,
                        size: 'full',
                        component: (
                            <editButton.ModalComponent />
                        )
                    })}
                >
                    {editButton.title}
                </Button>
            ) : null}
        </div>
    );
};

const pageEditButtons = {
    overview: {
        title: 'Add Overview widgets',
        ModalComponent: AddOverviewWidgetsModal
    },
    threats: {
        title: 'Add threats',
        ModalComponent: AddThreatsModal
    },
    narratives: {
        title: 'Add narratives',
        ModalComponent: AddNarrativesModal
    },
    customNarratives: {
        title: 'Add custom narratives',
        ModalComponent: AddCustomNarrativesModal
    },
    threatsWatchlist: {
        title: 'Add threats in watchlist',
        ModalComponent: AddThreatsFromWatchlistModal
    },
    narrativesWatchlist: {
        title: 'Add narratives in watchlist',
        ModalComponent: AddNarrativesFromWatchlistModal
    }
};
