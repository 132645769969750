import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { KeywordsChart } from './KeywordsChart';
import { KeywordsTable } from './KeywordsTable';
import { Loading } from '../../../../../components/Loading';
import { SmallPagination } from '../../../../../components/Molecules';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { EmptyState } from '../EmptyState';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION, GET_NARRATIVES } from '../../../../../graphql';
import { elasticFilterWithProjectId } from '../../../../../utils/elasticFilterWithProjectId';

export const Keywords = () => {
    const match = useRouteMatch();
    const [isTranslated, setIsTranslated] = useState(false);
    const { data, loading } = useQuery(GET_NARRATIVES, {
        variables: {
            query: elasticFilterWithProjectId([{
                ids: {
                    values: match.params.id
                }
            }])
        },
        fetchPolicy: 'no-cache',
    });
    const [page, setPage] = useState(0);
    const itemsPerPage = 10;
    const [date] = useState({
        startDate: moment(moment().subtract(5, 'years').startOf('month').toDate()).unix(),
        endDate: moment(moment().endOf('day').toDate()).unix(),
    });

    const narrativeTitle = data?.newNarrativeServiceSearch.hits[0]?._source.narrative_title;
    const narrative = data?.newNarrativeServiceSearch?.hits[0]?._source;

    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION);

    if (loading) {
        return (
            <div className="minh-200 position-relative">
                <Loading />
            </div>
        );
    }
    if (!narrative) {
        return null;
    }

    const keyphraseCount = narrative.keyphrase_table?.terms;
    const keyphrasesOverTime = [narrative.keyphrases_over_time];
    const topKeyphrases = narrative.keyphrase_table || {};
    const sortedKeyphraseData = keyphraseCount && sortArrays([topKeyphrases?.matches, topKeyphrases?.change, topKeyphrases?.terms]);
    const sortedKephrasesByMatches = keyphraseCount ? { matches: sortedKeyphraseData[0],
        change: sortedKeyphraseData[1],
        terms: sortedKeyphraseData[2] } : {};
    const sortedKeyphrasesOverTime = [];
    if (keyphraseCount) {
        sortedKeyphraseData[2]?.map((a) => keyphrasesOverTime.forEach(keyphraseOverTime => keyphraseOverTime?.filter((b) => {
            const pieces = b[0].split(':');
            if (pieces[0] === a) {
                sortedKeyphrasesOverTime.push(b);
                return true;
            }
        })));
    }
    const entries = Object.entries(sortedKephrasesByMatches);

    const formatted = entries[0] ? entries[1][1]?.map((_, index) => Object.fromEntries(entries.map((a, i) => {
        let val = entries[i][1][index];
        if (a[0] === 'change') { val = parseInt(`${val}`, 10); }
        return [a[0], val];
    }))) : [];

    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : narrativeTitle;
    const newTranslatedData = translatedData && isTranslated ? formatted?.map((x, i) =>
        ({ ...x, terms: translatedData.translateMultipleText[i + 1].translations[0] }))
        : formatted;

    const formattedKeywords = formatKeywords(sortedKeyphrasesOverTime, date);
    const chartData = formatChartData(sortedKeyphrasesOverTime, date);
    const translation = (e) => {
        e.preventDefault();
        getTranslation({
            variables: {
                data: [{ text: narrativeTitle, target: 'en' },
                    ...(formatted.map((item) => ({
                        text: item?.terms,
                        target: 'en'
                    })))
                ]
            },
        });
        setIsTranslated(!isTranslated);
    };

    return (
        <>
            <div className="d-flex">
                <p className="mb-4 set-min-width ">Monitor &#38;
                    visualise big keywords that frequently appear in content being generated in relation to your narratives.
                </p>
                <div className="text-primary w-100 d-flex justify-content-end cursor-pointer align-icon-top"
                    onClick={(e) => translation(e)}
                >
                    {narrative?.lang_verbose === 'English'
                        ? null
                        : (
                            <span role="button"
                                className="ml-2"
                            >
                                <TranslateIcon color="#006FF9" size={30} /> Translate content
                            </span>
                        )}
                </div>
            </div>

            {narrative
                && (
                    <div className="d-flex w-100 align-items-center justify-content-between">
                        <h3 className="mt-0 sentence-format w-auto">{isTranslated ? outputText : narrativeTitle}</h3>
                    </div>
                )}
            {keyphraseCount?.length > 0 || keyphrasesOverTime?.length > 0
                ? (
                    <Row>
                        <Col xs={12} lg={6}>
                            {topKeyphrases && <KeywordsTable data={newTranslatedData} page={page} formattedChart={formattedKeywords} />}
                            <div className="d-flex flex-row-reverse justify-content-between bg-white p-2 pr-3">
                                {Array.isArray(keyphraseCount) && keyphraseCount.length
                                    && (
                                        <SmallPagination total={formatted?.length || page}
                                            itemsPerPage={itemsPerPage}
                                            page={page}
                                            setPage={setPage}
                                        />
                                    )}
                            </div>
                        </Col>
                        <Col xs={12} lg={6} className="mt-5">
                            {(chartData) ? (
                                <KeywordsChart name="Top 4 keyword - Matches over time"
                                    formattedChart={formattedKeywords}
                                    chartData={chartData}
                                    date={date}
                                />
                            ) : (
                                <div className="pt-2 bg-white h-100">
                                    <EmptyState />
                                </div>
                            )}
                        </Col>
                    </Row>
                )
                : (
                    <Row className="bg-white mr-0 ml-0">
                        <Col className="p-3" xs={12}>
                            <div className="pt-2">
                                <EmptyState />
                            </div>
                        </Col>
                    </Row>
                )}
        </>
    );
};

// eslint-disable-next-line no-nested-ternary
export const sortArrays = (arrays, comparator = (a, b) => ((a > b) ? -1 : (a < b) ? 1 : 0)) => {
    const arrayKeys = Object.keys(arrays);
    const sortableArray = Object.values(arrays)[0];
    const indexes = Object.keys(sortableArray);
    const sortedIndexes = indexes.sort((a, b) => comparator(+sortableArray[a], +sortableArray[b]));

    const sortByIndexes = (array, sortedList) => sortedList.map(sortedIndex => array[sortedIndex]);

    if (Array.isArray(arrays)) {
        return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));
    }
    const sortedArrays = {};
    arrayKeys.forEach((arrayKey) => {
        sortedArrays[arrayKey] = sortByIndexes(arrays[arrayKey], sortedIndexes);
    });
    return sortedArrays;
};

const formatKeywords = (keyphraseOverTime, date) => {
    if (!keyphraseOverTime) { return []; }
    const keywords = keyphraseOverTime?.map((a) => {
        const pieces = a[0].split(':');
        return {
            keyword: pieces[0],
            date: new Date(pieces[1]),
            volume: parseInt(pieces[2], 10),
        };
    }).filter((v) => {
        if (!date?.startDate) return true;
        if (v.date.getTime() > date.startDate * 1000 && v.date.getTime() < date.endDate * 1000) return true;
        return false;
    });
    const series = [];
    const colors = ['#4367F8', '#D945D0', '#00C0C7', '#FF6C4C'];
    for (const keyword of keywords) {
        if (series?.length < 4) {
            const index = series.findIndex(a => a.name === keyword.keyword);
            if (index !== -1) {
                const indexB = series[index].data.findIndex(a => new Date(a.x).getTime() === new Date(keyword.date).getTime());
                if (indexB !== -1) {
                    series[index].data[indexB].y += keyword.volume;
                } else {
                    series[index].data.push({
                        x: keyword.date,
                        y: keyword.volume
                    });
                }
            } else {
                series.push({
                    name: keyword.keyword,
                    color: colors[series.length],
                });
            }
        }
    }
    return series;
};

const formatChartData = (keyphraseOverTime, date) => {
    const colors = ['#4367F8', '#D945D0', '#00C0C7', '#FF6C4C'];
    const keyData = keyphraseOverTime?.map(a => a.map((e) => {
        const pieces = e.split(':');
        return {
            keyword: pieces[0],
            pubdatetime: moment(pieces[1], 'YYYY-MM-DD').unix(),
            volume: parseInt(pieces[2], 10)
        };
    }));
    const content = keyData?.map((element, index) => {
        if (index <= 3) {
            return {
                data: element?.filter(
                    (item) => (item?.pubdatetime) >= (date.startDate) && (item?.pubdatetime) <= (date.endDate)
                ),
                color: colors[index],
                keyword: capitalizeFirstLetter(element[0].keyword)
            };
        }
    });
    return content;
};
