import { useQuery } from '@apollo/client';
import { GET_WATCHLIST } from '../../graphql';
import { removeAllTypename } from '../../utils/removeAllTypename';
import { useContent } from '../Content/getContent';

type UseGetWatchlistProps = {
    situationRoomId: string,
    getFullContent?: boolean
}

export const useGetWatchlist = ({ situationRoomId, getFullContent }: UseGetWatchlistProps) => {
    const {
        data,
        refetch: refetchWatchlist,
        loading: watchlistIdsLoading
    } = useQuery(GET_WATCHLIST, {
        variables: { situationRoom: situationRoomId },
        fetchPolicy: 'cache-and-network',
        skip: !situationRoomId
    });
    const watchlistData = data?.getSituationRoomThreatsWatchlist || [];
    const { loading, data: contents } = useContent({
        filters: {
            ids: watchlistData.map((watchlistItem: any) => watchlistItem.content_id),
            isThreat: true
        },
        limit: watchlistData.length,
        fetchPolicy: 'cache-and-network',
        skipRequest: !situationRoomId || !watchlistData.length,
        name: 'getWatchlistContent',
        fragments: getFullContent ? undefined : ['Metadata'],
        sort: 'date'
    });
    const watchlist = removeAllTypename(contents);

    return {
        watchlist,
        refetchWatchlist,
        loading: watchlistIdsLoading || loading
    };
};
