import React from 'react';
import { AlertTriangle } from 'react-feather';

export const ContentRemoved = () => (
    <div className="removed-at-source">
        <div className="text">
            <div>
                <AlertTriangle color="#F62459" size={30} />
            </div>
            <div className="description">Content Removed from Platform</div>
        </div>
    </div>
);
