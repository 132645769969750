import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { KeyLocationsIndicator } from './KeyLocations';
import { TranslateIcon } from '../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../graphql';
import { elasticFilterWithProjectId } from '../../../../utils/elasticFilterWithProjectId';
import { LocationMentions } from './LocationMentions';
import { Loading } from '../../../../components/Loading';

export const NarrativesBeen = () => {
    const [current, setCurrent] = useState(false);
    const [isTranslated, setIsTranslated] = useState(false);
    const match = useRouteMatch();
    const history = useHistory();
    const { loading, data } = useQuery(GET_KEY_LOCATIONS, {
        variables: {
            query: elasticFilterWithProjectId([{
                ids: {
                    values: match.params.id
                }
            }])
        },
    });

    const locationData = data && data?.newNarrativeServiceSearch?.hits[0]?._source?.narrative_locations;
    const narrativeTitle = data && data?.newNarrativeServiceSearch?.hits[0]?._source?.narrative_title;

    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: { text: narrativeTitle, target: 'en' }
        },
    });
    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : narrativeTitle;
    const translation = (e) => {
        e.preventDefault();
        getTranslation();
        setIsTranslated(!isTranslated);
    };

    return (
        <>
            <Button className="go-back-from-dashboard text-decoration-none align-items-center"
                color="link"
                onClick={history.goBack}
            >
                <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                <span>Back</span>
            </Button>
            <h2 className="mt-4 mb-0">What locations are mentioned in this narrative?</h2>
            <div className="mt-4">
                <p>View and monitor where content building towards your monitored Narrative is being generated across the world.</p>
            </div>
            <div className="ml-0 mr-0 mt-3 d-flex align-items-center flex-wrap justify-content-between">
                <h3 className="mt-0 sentence-format">{isTranslated ? outputText : narrativeTitle}
                    {!loading && data?.newNarrativeServiceSearch?.hits[0]?._source.lang_verbose === 'English' ? null : (
                        <span role="button"
                            className="ml-2"
                            onClick={(e) => translation(e)}
                        >
                            <TranslateIcon color="#006FF9" size={30} />
                        </span>
                    )}
                </h3>
            </div>
            {loading ? <div className="bg-white p-3 h-100"><Loading relative height={300} /></div>
                : (
                    <Row className="ml-1 mr-0 mt-3 bg-white">
                        <Col xs={10} md={10} className="p-0">
                            <KeyLocationsIndicator current={current} locationData={locationData} />
                            <div className="vl" />
                        </Col>
                        <Col xs={10} md={2} className="text-center p-3">
                            <LocationMentions handleChange={setCurrent} locationData={locationData} />
                        </Col>
                    </Row>
                )}
        </>
    );
};

const GET_KEY_LOCATIONS = gql`
    query newNarrativeServiceSearch($query: NewNarrativesESQuery) {
        newNarrativeServiceSearch(query: $query) {
            count
            hits {
                _id
                _source {
                    narrative_title
                    narrative_locations {
                        count
                        location
                        country_code
                    }
                    lang_verbose
                }
            }
        }
    }
`;
