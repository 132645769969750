import React from 'react';
import { Button } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../context/modal/ModalComponent';
import { DeleteIcon } from '../../../../assets/svg/delete-icon';
import { DELETE_CTYPE, GET_CTYPES } from '../../../../graphql';

export const RemoveSource = ({ ctypeId, isEditPage }) => {
    const { setModal, closeModal } = useModal();
    const history = useHistory();
    const [deleteCtype, { loading }] = useMutation(DELETE_CTYPE, {
        refetchQueries: [GET_CTYPES],
        onCompleted: () => {
            closeModal();
            if (isEditPage) {
                history.push('/admin/ctypes');
            }
        },
    });
    const onRemoveClickHandler = () => {
        setModal({
            component: (
                <>
                    <DeleteIcon />
                    <h2 className="mt-2">Delete source</h2>
                    <hr />
                    <p>Are you sure you want to delete this source?</p>
                    <hr />
                    <Button className="mr-3" onClick={closeModal}>Close</Button>
                    <Button color="danger" disabled={loading} onClick={() => deleteCtype({ variables: { id: ctypeId } })}>Delete</Button>
                </>
            ),
        });
    };

    return (
        <span className="text-danger cursor-pointer" onClick={() => onRemoveClickHandler()}>Remove source</span>
    );
};
