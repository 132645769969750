import React, { useState, Dispatch, SetStateAction } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import { DatePicker, DateRangeProps } from './components/DatePicker';
import { MultiSelectSourceFilter } from './MultiSelectSourceFilters';
import { ActorsFilter } from './ActorsFilter';
import { MultiSelectLanguageFilters } from './MultiSelectLanguageFilters';
import { KeywordsFilter } from './KeywordsFilter';
import { SelectedFilters } from './components/SelectedFilters';
import { ItemProps } from './components/DropdownWIthMultiCheckbox';
import { useTotalMatches } from '../../services/Overview/totalMatches';
import { getRoom } from '../../utils/variables';
import { saveFilters } from '../../pages/Room/store';
import { DropdownWithSearch } from './components/DropdownWithSearch';
import { SaveFilterButton } from './components/SaveFilter';
import { useUser } from '../../context/user/UserContext';
import { removeTypename } from '../../utils/removeAllTypename';

type FilterItemProps = {
    id: string,
    name: string,
    selected: boolean
}

export type DataProps = {
    filter: string,
    items: FilterItemProps[]
    __typename?: string
}

type UserProps = {
    displayName: string
}

export type RoomFilterProps = {
    id: string
    situationRoom: string,
    name: string,
    user: UserProps,
    data: DataProps[],
    createdAt: string
    dateRange: {
        startDate: number,
        endDate: number
        __typename?: string
    }
}

type FiltersLayoutProps = {
    setIsFiltersOpen: (e: boolean) => void,
    isFiltersOpen: boolean,
    setFilterName: (e: string) => void,
    sourceData: ItemProps[],
    setSourceData: Dispatch<SetStateAction<ItemProps[]>>,
    languagesData: ItemProps[],
    setLanguagesData: Dispatch<SetStateAction<ItemProps[]>>,
    keywords: ItemProps[],
    setKeywords: Dispatch<SetStateAction<ItemProps[]>>,
    actors: ItemProps[],
    setActors: Dispatch<SetStateAction<ItemProps[]>>,
    setDateRange: (e: DateRangeProps) => void,
    dateRange: DateRangeProps,
    filterName: string,
    hasFilters: any
}
export const FiltersLayout = ({ setIsFiltersOpen, isFiltersOpen, setFilterName, sourceData,
    setSourceData,
    languagesData = [],
    setLanguagesData,
    keywords = [],
    setKeywords,
    actors = [],
    setActors,
    setDateRange, dateRange, filterName, hasFilters }: FiltersLayoutProps) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const { user } = useUser();
    // const [commentsResultsOnly, setCommentsResultsOnly] = useState(false);
    const [selectedFilterName, setSelectedFilterName] = useState('');
    const [disableSaveEditFilter, setDisableSaveFilter] = useState(false);

    const { data, loading } = useQuery(GET_ROOM_FILTERS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'no-cache'
    });
    const filtersData = (data?.getRoomFilters?.filters || []) as RoomFilterProps[];

    const filters = {
        filters: {
            date: dateRange,
            contentType: sourceData.find(item => item.selected) ? sourceData.filter(item => item.selected).map(item => item.id) : undefined,
            languages: languagesData.find(item => !item.selected)
                ? languagesData.filter(item => !item.selected).map(item => item.id) : undefined,
            keywords: keywords.find(item => item.selected) ? keywords.filter(item => item.selected).map(item => item.id) : undefined,
            from: actors.find(item => item.selected) ? actors.filter(item => item.selected).map(item => item.id) : undefined
        }
    };

    const handleApply = () => {
        if (selectedFilterName) {
            setFilterName(selectedFilterName);
        } else if (checkForData('apply')) {
            setFilterName('Select filters');
        } else {
            setFilterName('Custom selected');
        }
        dispatch(saveFilters({
            ...filters.filters,
            dateRange,
            sourcesWithDomains: sourceData.find(item => item.selected)
                ? sourceData.filter(item => item.selected).map(item => ({ key: item.id, label: item.name })) : undefined,
        }));
        setIsFiltersOpen(!isFiltersOpen);
    };

    const { totalMatches, loading: totalMatchesLoading } = useTotalMatches(filters);

    let totalMatchesDisplay = totalMatches;
    if (totalMatches > 1000000) {
        totalMatchesDisplay = `${(Math.floor(totalMatches / 1000000))}m+`;
    } else if (totalMatches > 1000) {
        totalMatchesDisplay = `${Math.floor(totalMatches / 1000)}k+`;
    }

    const handleSelectItem = (id: string) => {
        const deletedTypenames = filtersData.map((a) => {
            const newFilter = { ...a };
            delete newFilter.dateRange.__typename;
            newFilter.data.forEach((item: DataProps) => {
                const newFilterItems = { ...item };
                delete newFilterItems.__typename;
                return newFilterItems;
            });

            return newFilter;
        });
        const selectedFilter = deletedTypenames.find((a) => a.id === id);
        if (selectedFilter) {
            const sourceFilter = selectedFilter.data.find((a) => a.filter === 'Sources');
            const languagesFilter = selectedFilter.data.find((a) => a.filter === 'Languages');
            const keywordFilter = selectedFilter.data.find((a) => a.filter === 'Keywords');
            const actorsFilter = selectedFilter.data.find((a) => a.filter === 'Actors');
            sourceFilter ? setSourceData(setDataFilters(sourceData, sourceFilter))
                : setSourceData(sourceData.map((a) => ({ ...a, selected: false })));

            languagesFilter ? setLanguagesData(setDataFilters(languagesData, languagesFilter, 'languages'))
                : setLanguagesData(languagesData.map((a) => ({ ...a, selected: true })));

            keywordFilter ? setKeywords(setDataFilters(keywords, keywordFilter))
                : setKeywords(keywords.map((a) => ({ ...a, selected: false })));

            actorsFilter ? setActors(setDataFilters(actors, actorsFilter))
                : setActors(actors.map((a) => ({ ...a, selected: false })));
            setDateRange(selectedFilter.dateRange);
            setSelectedFilterName(selectedFilter.name);
            setDisableSaveFilter(true);
        }
    };
    const handleAllFiltersData = () => {
        const sourceFilteredData = sourceData.filter((a) => a.selected === true);
        const languagesFilteredData = languagesData.filter((a) => a.selected === false);
        const keywordsFilteredData = keywords.filter((a) => a.selected === true);
        const actorsFilteredData = actors.filter((a) => a.selected === true);
        const finalData = [
            {
                filter: 'Sources',
                items: sourceFilteredData
            },
            {
                filter: 'Languages',
                items: languagesFilteredData
            },
            {
                filter: 'Keywords',
                items: keywordsFilteredData
            },
            {
                filter: 'Actors',
                items: actorsFilteredData
            }
        ];
        return finalData;
    };

    if (disableSaveEditFilter && filtersData?.length > 0 && selectedFilterName) {
        const filteredOne = removeTypename(filtersData.filter((a) => a.name
        === selectedFilterName));
        const languageItems = filteredOne[0].data.filter((a: DataProps) => a.filter === 'Languages')[0].items;
        const sourceItems = filteredOne[0].data.filter((a: DataProps) => a.filter === 'Sources')[0].items;
        const keywordItems = filteredOne[0].data.filter((a: DataProps) => a.filter === 'Keywords')[0].items;
        const actorItems = filteredOne[0].data.filter((a: DataProps) => a.filter === 'Actors')[0].items;
        const dateItem = filteredOne[0].dateRange;

        if (filteredOne.length) {
            const a = _.isEqual(removeTypename(languagesData.filter((item) => !item.selected)), removeTypename(languageItems));
            const b = _.isEqual(removeTypename(sourceData.filter((item) => item.selected)), removeTypename(sourceItems));
            const c = _.isEqual(removeTypename(keywords.filter((item) => item.selected)), removeTypename(keywordItems));
            const d = _.isEqual(removeTypename(actors.filter((item) => item.selected)), removeTypename(actorItems));
            const e = _.isEqual(removeTypename(dateRange), removeTypename(dateItem));
            if (!a || !b || !d || !e || !c) {
                setDisableSaveFilter(false);
                setSelectedFilterName('');
            }
        }
    }
    const checkForData = (text = '') => {
        const sourceFilteredData = sourceData.filter((a) => a.selected === true);
        const languagesFilteredData = languagesData.filter((a) => a.selected === false);
        const keywordsFilteredData = keywords.filter((a) => a.selected === true);
        const actorsFilteredData = actors.filter((a) => a.selected === true);
        const dateFilter = _.isEqual({
            startDate: moment(moment(room.start_date).toDate()).unix(),
            endDate: dateRange.endDate,
        }, removeTypename(dateRange));
        if (!text) {
            if (!sourceFilteredData.length && !languagesFilteredData.length
                && !keywordsFilteredData.length && !actorsFilteredData.length && dateFilter && filterName !== 'Custom selected'
                 && !hasFilters) {
                return true;
            }
        } else if (!sourceFilteredData.length && !languagesFilteredData.length
                && !keywordsFilteredData.length && !actorsFilteredData.length && text === 'filter') {
            return true;
        } else if (text === 'apply') {
            return (!sourceFilteredData.length && !languagesFilteredData.length
                && !keywordsFilteredData.length && !actorsFilteredData.length && dateFilter);
        }
    };

    return (
        <div className="mb-4">
            <div className="border p-3 bg-white d-flex justify-content-between">
                {(filtersData.length > 0) && (
                    <>
                        <div className="d-flex align-items-center">
                            <p className="mr-2 mt-1">Select from saved filters</p>
                            <DropdownWithSearch name={selectedFilterName || 'Select filters'}
                                placeholder="Search saved filters"
                                items={filtersData.length ? filtersData
                                    .map((filter) => {
                                        const { id } = filter;
                                        const { name } = filter;
                                        return { id, name };
                                    }) : []}
                                handleSelectItem={handleSelectItem}
                                loading={loading}
                            />
                        </div>
                        <Link className="cursor-pointer p-0 mw-0" to={`/situation-room/${room?.id}/manage-saved-filters`}>
                            Manage saved filters
                        </Link>
                    </>
                )}
            </div>
            <div className="border p-3 bg-white">
                <p className="font-weight-bold">Filters</p>
                <Row className="align-items-stretch flex-md-nowrap">
                    <Col xs="12" sm="auto" className="mb-1 pr-1 pl-2">
                        <p className="font-weight-semibold">Date</p>
                        <DatePicker setDateRange={setDateRange} dateRange={dateRange} />
                    </Col>

                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <p className="font-weight-semibold">Source</p>
                        <MultiSelectSourceFilter sourceData={sourceData} setSourceData={setSourceData} />
                    </Col>
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <p className="font-weight-semibold">Languages</p>
                        <MultiSelectLanguageFilters languagesData={languagesData} setLanguagesData={setLanguagesData} />
                    </Col>
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <p className="font-weight-semibold">Keywords</p>
                        <KeywordsFilter keywords={keywords} setKeywords={setKeywords} />
                    </Col>
                    <Col xs="12" sm="auto" className="mb-1 px-1">
                        <p className="font-weight-semibold">Actors</p>
                        <ActorsFilter actors={actors} setActors={setActors} />
                    </Col>

                    {/* <Col xs="auto" className="mb-1">
                        <p className="font-weight-semibold">Labels</p>
                    </Col> */}
                </Row>

                {/* <FormGroup>
                    <CustomInput id="comments-result"
                        type="checkbox"
                        className="d-inline"
                        checked={commentsResultsOnly}
                        onChange={({ target: { checked } }) => { setCommentsResultsOnly(checked); }}
                    />

                    Only display results with comments
                </FormGroup> */}

            </div>
            {(sourceData.find(item => item.selected)
                    || languagesData.find(item => !item.selected)
                    || keywords.find(item => item.selected)
                    || actors.find(item => item.selected))
                    && (
                        <div className="border p-3 bg-white">
                            <SelectedFilters sourceData={sourceData}
                                setSourceData={setSourceData}
                                languagesData={languagesData}
                                setLanguagesData={setLanguagesData}
                                keywords={keywords}
                                setKeywords={setKeywords}
                                actors={actors}
                                setActors={setActors}
                                setDateRange={setDateRange}
                                start_date={room.start_date}
                            />
                        </div>
                    )}
            <div className="border p-3 bg-white d-flex justify-content-end">
                <Button color="link" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>Close</Button>
                <SaveFilterButton text="Save filter"
                    filtersData={handleAllFiltersData()}
                    userID={user.id}
                    dateRange={dateRange}
                    disable={disableSaveEditFilter || checkForData('filter')}
                />
                <Button disabled={checkForData()} color="primary ml-3" onClick={handleApply}>
                    Apply filter {!totalMatchesLoading ? `(${totalMatchesDisplay})` : <Spinner className="ml-1" size="sm" color="white" />}
                </Button>
            </div>
        </div>
    );
};

export const setDataFilters = (contentData: ItemProps[], filterData: DataProps, languages = '') => {
    const matchData = contentData.map((a: any) => {
        const matchedId = filterData.items.find((b: any) => b.id === a.id);
        if (!languages) {
            if (matchedId) {
                return { ...a, selected: true };
            }
            return { ...a, selected: false };
        }
        if (matchedId) {
            return { ...a, selected: false };
        }
        return { ...a, selected: true };
    });
    return matchData;
};

export const GET_ROOM_FILTERS = gql`
    query getRoomFilters($situationRoom: ID!, $limit: Int, $skip: Int) {
        getRoomFilters(situationRoom: $situationRoom,limit: $limit, skip: $skip,) {
            filtersCount
            filters {
                id
                name
                user {
                    displayName
                }
                createdAt
                data {
                    filter
                    items {
                        id
                        name
                        selected
                    }
                }
                dateRange {
                    startDate
                    endDate
                }
            }
        }
    }
`;
