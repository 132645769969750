import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tabs } from '../../../../components/Tabs';
import { ReadMore } from '../../../../components/Readmore';
import { capitalizeFirstLetter, getCtype } from '../../../../utils/text';
import { EmptyState } from './EmptyState';
import { TooltipItem } from '../../../../components/ToolTip';
import { currentTab } from '../../../../utils/variables';
import { useContent } from '../../../../services/Content/getContent';
import { getPostedByName } from '../../../../utils/getPostedByName';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { Loading } from '../../../../components/Loading';

export const NarrativePerformers = ({ contentLoading, npData, isEmptyNarrative }) => {
    const location = useLocation();
    const presentTab = useRef('original-poster');

    const currentTabValue = currentTab();

    if (currentTabValue === 'original-poster' || currentTabValue === 'boosters') {
        presentTab.current = currentTabValue;
    }

    if (contentLoading) {
        return <div className="bg-white p-3 h-100"><Loading relative height={300} /></div>;
    }

    return (
        <div className="bg-white p-3 h-100">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h3 className="m-0 mr-1">Who are the Narrative performers?</h3>
                    <TooltipItem placement="bottom"
                        tooltipOpen="Who are the Narrative performers?"
                        id="narrativePerformers"
                        content={[
                            <div>
                                <div><strong>Original poster. </strong>The source content that began to form the Narrative.</div>
                                <div>
                                    <strong>Boosters. </strong>
                                    A list of all the people we could determine that are driving your Narrative forward&nbsp;
                                    with a decent amount of content generated.
                                </div>
                            </div>
                        ]}
                    />
                </div>
                {((npData?.original_post && true && !isEmptyNarrative) || (npData?.boosters?.length > 0 && npData?.boosters[0] !== null))
                    ? (
                        <Link to={{ pathname: `${location.pathname}${location.pathname.endsWith('/')
                            ? '' : '/'}who-are-the-narrative-performers`,
                        state: { prevTab: presentTab.current } }}
                        >
                            View details
                        </Link>
                    ) : null}
            </div>
            {
                !isEmptyNarrative ? (
                    <div className="mt-2">
                        <Tabs small
                            data={[
                                {
                                    name: 'Original poster',
                                    id: 'original-poster',
                                    content: <ContentPost id={npData?.original_post} />,
                                },
                                {
                                    name: 'Boosters',
                                    id: 'boosters',
                                    content: <ContentPost id={npData?.boosters} booster />,
                                }
                            ]}
                        />
                    </div>
                ) : (
                    <div className="mt-10">
                        <EmptyState />
                    </div>
                )
            }

        </div>
    );
};

const ContentPost = ({ id }) => {
    const { data, loading } = useContent({
        filters: {
            ids: id
        },
        skipRequest: !id,
        name: 'getNarrativeContentPost',
        fragments: ['Metadata', 'Document', 'Annotations']
    });
    const post = data.length > 0 ? data[0] : [];

    if (loading) {
        return <div className="bg-white p-3 h-100"><Loading relative height={300} /></div>;
    }
    if (post.length === 0) {
        return (
            <div className="mt-10">
                <EmptyState />
            </div>
        );
    }
    const ctype = getCtype(post.contentType);

    return (
        <div>
            <strong data-testid="originalPost-from">
                {getPostedByName(post?.from, post?.displayName)}
            </strong>
            <div className="my-1"><strong>Platform:</strong>
                <SourceIconLabel data={{ contentType: post.contentType }} className="pl-1 d-inline" />
                <p className="d-inline">{ctype.name}</p>
            </div>
            <p><strong>Location:</strong> {capitalizeFirstLetter(post.locationsDetected[0] || '')}</p>
            <p><strong>Sentiment:</strong> {capitalizeFirstLetter(post.sentiment || '')}</p>
            <strong>Original post:</strong> <ReadMore text={post.text} limit={400} />
        </div>
    );
};
