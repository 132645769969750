import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropdownWithMultiCheckbox, ItemProps } from './components/DropdownWIthMultiCheckbox';
import { useTopLanguages } from '../../services/Overview';
import { languagesList } from '../../utils/variables';

type MultiSelectLanguageFiltersProps = {
    languagesData: ItemProps[],
    setLanguagesData: (languagesData: ItemProps[]) => void
};

export const MultiSelectLanguageFilters = ({ languagesData, setLanguagesData }: MultiSelectLanguageFiltersProps) => {
    const { data } = useTopLanguages({ size: 1000, useGlobalFilters: false });
    const globalFilters = useSelector((state: any) => state.filters.filters);
    const handleSelectItem = (ids: string[]) => {
        const filteredLanguagesData = languagesData.map((a) => {
            if (ids.includes(a.id)) {
                return { ...a, selected: true };
            }
            return { ...a, selected: false };
        });
        setLanguagesData(filteredLanguagesData);
    };
    useEffect(() => {
        if (data.length && !languagesData.length) {
            const filters = data.map(({ language, key }: {language: string, key: string}) =>
                ({ id: key, name: language, selected: !globalFilters?.languages?.includes(key) }));
            const sortedFilters = filters.sort((a: ItemProps, b: ItemProps) => a.name.localeCompare(b.name));
            setLanguagesData(sortedFilters);
            languagesList(data.map(({ key }: { key: string }) => key));
        }
    }, [data, globalFilters.languages, languagesData, setLanguagesData]);
    const countFilters = (languagesData.filter((a) => a.selected === false)).length;

    return (
        <div>
            <DropdownWithMultiCheckbox filterOptions={languagesData}
                handleSelectItem={handleSelectItem}
                value="languages"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
            />
        </div>
    ); };
