import React from 'react';
import { Button } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { Tabs } from '../../../../components/Tabs';
import { OriginalPoster } from './OriginalPoster';
import { Boosters } from './Boosters';
import { Loading } from '../../../../components/Loading';
import { GET_NARRATIVES } from '../../../../graphql';
import { elasticFilterWithProjectId } from '../../../../utils/elasticFilterWithProjectId';

export const NarrativePerformers = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const prevTab = location.state?.prevTab;
    const { data, loading } = useQuery(GET_NARRATIVES, {
        variables: {
            query: elasticFilterWithProjectId([{
                ids: {
                    values: match?.params?.id
                }
            }])
        },
    });
    return (
        <>
            <Button className="go-back-from-dashboard"
                color="link"
                style={{ textDecoration: 'none' }}
                onClick={history.goBack}
            >
                <FeatherIcon.ArrowLeft size={18} className="mr-1" />
                <span style={{ marginTop: '-3px' }}>Back</span>
            </Button>
            <h2 className="mt-4 mb-0">Who are the narrative performers?</h2>
            {loading ? (
                <div className="minh-200 position-relative">
                    <Loading />
                </div>
            ) : (
                <div className="mt-4">
                    <Tabs prevKey={prevTab}
                        data={[
                            {
                                name: 'Original poster',
                                id: 'original-poster',
                                content: <OriginalPoster data={data?.newNarrativeServiceSearch?.hits[0]?._source} />,
                            },
                            {
                                name: 'Boosters',
                                id: 'boosters',
                                content: <Boosters data={data?.newNarrativeServiceSearch?.hits[0]?._source} />,
                            }
                        ]}
                    />
                </div>
            )}
        </>
    );
};
