export const entityColors = {
    positive: {
        bg: 'success',
        border: 'success',
        bgHex: '#e8f8f5'
    },
    negative: {
        bg: 'danger',
        border: 'danger',
        bgHex: '#fdecf0'
    },
    neutral: {
        bg: 'primary',
        border: 'primary',
        bgHex: '#E6F1FE'
    },
    unknown: {
        bg: 'gray',
        border: 'gray',
        bgHex: '#B0B4C5'
    }
};

export const options = [
    {
        name: 'Change label',
        options: [
            {
                color: 'success',
                name: 'positive'
            },
            {
                color: 'danger',
                name: 'negative'
            },
            {
                color: 'primary',
                name: 'neutral'
            },
            {
                color: 'grey',
                name: 'unknown'
            }
        ]
    },
    {
        name: 'Delete entity',
        options: []
    }
];
