import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Row, Col } from 'reactstrap';
import { TranslateIcon } from '../../../../../assets/icons/svgicons';
import { GET_MULTIPLE_TRANSLATION } from '../../../../../graphql';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { SmallPagination } from '../../../../../components/Molecules';
import { RelatedPost } from './RelatedPosts';
import { OriginalPost } from './OriginalPost';

export const OriginalPoster = ({ data }) => {
    const { original_post: originalPostId, content_ids: contentIds } = data;
    const { data: contentData, loading } = useContent({
        filters: {
            ids: originalPostId
        },
        name: 'getNarrativeOriginalPost',
        fragments: ['Metadata', 'Document', 'Annotations']
    });
    const [page, setPage] = useState(false);
    const { data: relatedPost, count: relatedPostCount, loading: relatedContentLoading } = useContent({
        filters: {
            ids: contentIds
        },
        limit: 10,
        skip: 10 * page,
        name: 'getNarrativeRelatedPost',
        fragments: ['Metadata', 'Document']
    });
    const originalPost = contentData[0];
    const [isTranslated, setIsTranslated] = useState(false);
    const [getTranslation, { data: translatedData }] = useLazyQuery(GET_MULTIPLE_TRANSLATION);

    if (loading) {
        return <Loading relative height={400} />;
    }

    const outputText = translatedData ? translatedData?.translateMultipleText[0]?.translations[0] : data?.narrative_title;
    const originalPostText = translatedData && isTranslated ? translatedData?.translateMultipleText[1]?.translations[0]
        : originalPost?.text;
    const translatedRelatedPost = translatedData && isTranslated
        ? relatedPost?.map((item, i) => ({ ...item, text: translatedData?.translateMultipleText[i + 2]?.translations[0] })) : relatedPost;
    const translation = (e) => {
        e.preventDefault();
        getTranslation({
            variables: {
                data: [{ text: data?.narrative_title, target: 'en' },
                    { text: originalPost?.text, target: 'en' },
                    ...(relatedPost.map(item => ({
                        text: item?.text,
                        target: 'en'
                    })))]
            },
        });
        setIsTranslated(!isTranslated);
    };

    return (
        <>
            <div className="d-flex">
                <p className="mb-0 set-min-width">Original poster is the source content that began to form the Narrative.</p>
                <div className="text-primary w-100 d-flex justify-content-end cursor-pointer align-icon-top"
                    onClick={(e) => translation(e)}
                >
                    {data?.lang_verbose === 'English'
                        ? null
                        : (
                            <span role="button"
                                className="ml-2"
                            >
                                <TranslateIcon color="#006FF9" size={30} /> Translate content
                            </span>
                        )}
                </div>
            </div>
            <h3 className="mt-4 sentence-format">{isTranslated ? outputText : data?.narrative_title}</h3>
            <Row className="ml-0 mr-0 mt-4">
                <OriginalPost originalPost={originalPost} originalPostText={originalPostText} />
                <Col xs={10} md={7} className="pt-3 px-0 bg-white op-border-left d-flex flex-column minh-500">
                    <RelatedPost relatedContentLoading={relatedContentLoading}
                        relatedPostCount={relatedPostCount}
                        translatedRelatedPost={translatedRelatedPost}
                    />
                    {relatedPostCount > 10 && (
                        <div className="border-top bg-white p-2 d-flex justify-content-end">
                            <SmallPagination total={relatedPostCount}
                                itemsPerPage={10}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};
