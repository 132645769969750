import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Alert
} from 'reactstrap';
import { AlertCircle } from 'react-feather';
import { CREATE_ADMIN_USER, GET_ADMIN_USERS } from '../../../../graphql';

export const CreateAdminUser = ({ adminUsers, closeModal }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [createAdminUser] = useMutation(CREATE_ADMIN_USER, {
        refetchQueries: [GET_ADMIN_USERS],
        onCompleted: () => {
            closeModal();
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        // Check if user is already an admin
        const isAdminAlready = checkUserAlreadyAdmin(adminUsers, email);
        if (isAdminAlready) {
            setEmail('');
            setError('User is already an admin');
            return;
        }

        // Create admin user
        setLoading(true);
        try {
            createAdminUser({
                variables: {
                    email,
                    role: 'TEAM',
                },
            });
        } catch {
            setError('Oops, looks like something went wrong, please try again later');
        }
    };

    return (
        <>
            <h2>Create admin user</h2>
            <hr />
            <Form onSubmit={async (e) => handleSubmit(e)}>
                <FormGroup>
                    <Label>Email email address</Label>
                    <Input type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        data-testid="add-admin-user-input"
                    />
                </FormGroup>
                <Alert isOpen={error.length > 0} toggle={() => setError('')} color="danger" className="mt-3">
                    <span>
                        <AlertCircle color="#F84367" />
                    </span>
                    {error}
                </Alert>
                <hr />
                <Button onClick={() => closeModal()}>Cancel</Button>
                <Button color="primary" className="ml-2" type="submit" disabled={loading}>Create</Button>
            </Form>
        </>
    );
};

const checkUserAlreadyAdmin = (adminUsers, email) => {
    for (let i = 0; i < adminUsers.length; i++) {
        if (adminUsers[i].email === email) {
            return true;
        }
    }

    return false;
};
