import React, { useState } from 'react';
import { useReactiveVar, useQuery, useMutation } from '@apollo/client';
import {
    FormGroup,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    Table,
    Button
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { useSelector } from 'react-redux';
import { Dropdown } from '../../../../components/Form/Dropdown';
import { Loading } from '../../../../components/Loading';
import { StickToBottom } from '../../../../components/StickToBottom';
import { TOGGLE_WATCHLIST } from '../../Narratives/Overview/Narratives/AddToWatchlist';
import { getRoom, getWatchlist, refetchWatchlist } from '../../../../utils/variables';
import { GET_NARRATIVES } from '../../../../graphql';
import { PageFilters } from '../../Narratives/PageFilters';
import { narrativeSortOptions } from '../../Narratives/Overview/Narratives';
import { EmptyState } from '../../../../components/EmptyState';
import NotFoundFC from '../../../../assets/images/noFactChecks.svg';
import { SelectAllCheckBox } from '../../../../components/Form/CheckBox';
import { formatFilters } from '../../Narratives/PageFilters/formatFilters';
import { useContent } from '../../../../services/Content/getContent';
import { RemoveContentModal } from '../../../../components/CommonModals/RemoveContent';
import { WatchlistNarrativeItem } from './WatchlistNarrativeItem';
import { SortItem } from './SortItem';
import { NarrativesWatchlistCsv } from './NarrativesWatchlistCsv';
import { Pagination } from '../../../../components/Molecules';
import { useModal } from '../../../../context/modal/ModalComponent';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';

export const WatchlistNarratives = () => {
    const { setModal } = useModal();
    const filters = useSelector((state) => state.narrativeFilters);
    const formattedFilters = formatFilters(filters.filters);
    const [sort, setSort] = useState(narrativeSortOptions[0]);
    const [searchBox, setSearchBox] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const watchlist = useReactiveVar(getWatchlist);
    const room = getRoom();
    const instancePlan = room.instance?.plan;
    const contentActionsEnabled = instancePlan?.others?.contentActions;
    const refetch = refetchWatchlist();
    const [sortItem, setSortItem] = useState({ field: '', type: false });
    const [initialLoad, setLoadArrow] = useState({
        originalPostDate: true,
        volume: true,
        engagement: true,
        threatLevel: true,
    });
    const vars = {
        ...(isSearch && {
            must: [
                {
                    query_string: {
                        query: searchBox,
                        fields: ['text', 'narrative_title'],
                    },
                }
            ],
        }),
        filter: [
            {
                ids: {
                    values: watchlist
                },
            },
            {
                match_phrase: {
                    project_id: {
                        query: room.project_id,
                    },
                },
            },
            ...(formattedFilters?.bool?.filters || [])
        ]
    };

    const { data: narratives, loading } = useQuery(GET_NARRATIVES, {
        variables: {
            limit: 10,
            sort: sort.key,
            skip: page,
            query: {
                bool: vars,
            },
        },
        skip: !watchlist?.length,
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setSelected([]);
        }
    });

    const [updateWatchlist, { loading: toggleWatchlistLoading }] = useMutation(
        TOGGLE_WATCHLIST,
        {
            onCompleted: () => {
                refetch();
                setSelected([]);
            }
        }
    );

    const data = narratives?.newNarrativeServiceSearch?.hits.map((a) => ({
        id: a._id,
        ...a._source,
    })) || [];

    const originalPostIds = data.map((a) => a.original_post);
    const { data: originalPost, loading: loadingOriginalPost } = useContent({
        filters: {
            ids: originalPostIds
        },
        skipRequest: !originalPostIds?.length,
        name: 'getNarrativeOriginalPost',
        fragments: ['Metadata']
    });

    const handleContentRemoved = () => {
        refetch();
        setSelected([]);
    };
    const handleRemoveNarrativeError = () => {
        setModal({
            header: 'Failed to remove content',
            component: <p>Failed to remove content, please try again later</p>
        });
    };
    const setRemoveContentModal = () => {
        const removeData = selected.map(id => ({ id }));
        setModal({
            modal: <RemoveContentModal removeData={removeData}
                type={CONTENT_TYPE.NARRATIVE}
                onCompleted={handleContentRemoved}
                onError={handleRemoveNarrativeError}
            />
        });
    };

    const linkToNarrative = { name: 'View narratives',
        to: `/situation-rooms/${room.id}/narratives?true=narratives` };
    const ids = data?.map(x => ({ id: x.id }));

    const handleSearch = () => {
        if (searchBox !== '') {
            setIsSearch(true);
        }
    };

    const handleUpdateWatchlist = () => {
        updateWatchlist({
            variables: {
                situationRoom: room.id,
                narrative: selected
            }
        });
        setPage(0);
    };

    const sortIt = (a, b) => {
        if (sortItem?.field?.length > 0) {
            if (initialLoad[sortItem?.field]) {
                const newval = initialLoad;
                newval[sortItem.field] = !newval[sortItem.field];
                setLoadArrow(newval);
            } else if (sortItem.field !== '') {
                if (sortItem.field === 'originalPostDate') {
                    const postItemsList = originalPost.filter((post) => post.id === a.original_post || post.id === b.original_post);
                    const e = new Date(postItemsList[0]?.date);
                    const f = new Date(postItemsList[1]?.date);
                    if (sortItem.type === true) {
                        return e - f;
                    }
                    if (sortItem.type === false) {
                        return f - e;
                    }
                }
                else {
                    const itemField = sortItem.field;
                    if (sortItem.type === true) {
                        if (a[itemField] < b[itemField]) {
                            return -1;
                        }
                        if (a[itemField] > b[itemField]) {
                            return 1;
                        }
                        return 0;
                    }
                    if (sortItem.type === false) {
                        if (a[itemField] > b[itemField]) {
                            return -1;
                        }
                        if (a[itemField] < b[itemField]) {
                            return 1;
                        }
                        return 0;
                    }
                }
            } else {
                return a;
            }
        } else {
            return a;
        }
    };

    const sortedDataList = data.sort(sortIt);
    const sortedPostsList = [];
    sortedDataList?.forEach((a) => originalPost?.forEach((b) => (a.original_post === b.id && sortedPostsList.push(b))));

    return (
        <div className="position-relative narratives">
            {loading || loadingOriginalPost ? (
                <div className="minh-200 position-relative">
                    <Loading />
                </div>
            ) : (
                <div>
                    {watchlist.length > 0 ? (
                        <>
                            <div className=" d-flex justify-content-between mt-4 mb-3 w-100">
                                <div className="flex-grow-1">
                                    <FormGroup onSubmit={(e) => { e.preventDefault(); handleSearch(); }} className="pr-3">
                                        <InputGroup border={searchBox ? 'active' : 'none'}>
                                            <Input value={searchBox}
                                                onChange={(e) => setSearchBox(e.target.value)}
                                                name="searchBox"
                                                placeholder="Search narratives"
                                            />

                                            {searchBox && (
                                                <InputGroupAddon addonType="prepend" className="border-right">
                                                    <InputGroupText role="button" onClick={() => { setSearchBox(''); setIsSearch(false); }}>
                                                        <FeatherIcon.X color="#006FF9" size={16} />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            )}
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText role="button" onClick={() => handleSearch()}>
                                                    <FeatherIcon.Search color="#006FF9" size={16} />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                                <div className="align-items-center">
                                    <Dropdown label="Sort by"
                                        options={narrativeSortOptions}
                                        value={sort}
                                        onChange={setSort}
                                        className="d-flex align-items-center mr-2 mb-2"
                                    />
                                </div>
                                <div>
                                    <Button onClick={() => setFiltersOpen(!filtersOpen)}
                                        className={filters ? 'btn-has-filters mb-2 ' : 'mb-2 '}
                                    >
                                        <span className="pr-3">
                                            Page Filters
                                        </span>
                                        <FeatherIcon.ChevronDown size={16}
                                            className={filtersOpen ? 'transform-btn-icon ml-11 filter-btn-arrow'
                                                : 'transform-none ml-11 filter-btn-arrow'}
                                        />
                                    </Button>
                                    <NarrativesWatchlistCsv vars={vars} sort={sort} />
                                </div>
                            </div>
                            <PageFilters isOpen={filtersOpen} setOpen={setFiltersOpen} />
                            { (data && data.length > 0) ? (
                                <Table responsive className="mb-0">
                                    <thead>
                                        <tr>
                                            <th>
                                                <SelectAllCheckBox id="allNarrativeContent"
                                                    displayLabel={false}
                                                    options={ids}
                                                    values={selected}
                                                    allCheck={(dataB) => (setSelected(dataB.map((content) => content.id)))}
                                                    page="content-table"
                                                    watchlist
                                                />
                                            </th>
                                            <th className="no-white-space">
                                                Narratives ({narratives?.newNarrativeServiceSearch?.count})
                                            </th>
                                            <th className="no-white-space cursor-pointer"
                                                onClick={() => { setSortItem({ field: 'originalPostDate', type: !sortItem.type }); }}
                                            >
                                                Original post date
                                                <SortItem sortField={sortItem.field}
                                                    sortName="originalPostDate"
                                                    sortType={sortItem.type}
                                                    initialLoad={initialLoad.originalPostDate}
                                                />
                                            </th>
                                            <th className="no-white-space cursor-pointer"
                                                onClick={() => { setSortItem({ field: 'volume', type: !sortItem.type }); }}
                                            >
                                                Volume
                                                <SortItem sortField={sortItem.field}
                                                    sortName="volume"
                                                    sortType={sortItem.type}
                                                    initialLoad={initialLoad.volume}
                                                />
                                            </th>
                                            <th className="no-white-space cursor-pointer"
                                                onClick={() => { setSortItem({ field: 'engagement', type: !sortItem.type }); }}
                                            >
                                                Engagement
                                                <SortItem sortField={sortItem.field}
                                                    sortName="engagement"
                                                    sortType={sortItem.type}
                                                    initialLoad={initialLoad.engagement}
                                                />
                                            </th>
                                            <th className="no-white-space cursor-pointer"
                                                onClick={() => { setSortItem({ field: 'average_threat_label', type: !sortItem.type }); }}
                                            >
                                                Threat level
                                                <SortItem sortField={sortItem.field}
                                                    sortName="average_threat_label"
                                                    sortType={sortItem.type}
                                                    initialLoad={initialLoad.threatLevel}
                                                />
                                            </th>
                                            {selected.length === 0 && <th>Actions</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedDataList.map((narrative, index) => (
                                            <WatchlistNarrativeItem narrative={narrative}
                                                selected={selected}
                                                setSelected={setSelected}
                                                room={room}
                                                originalPostData={originalPost && sortedPostsList[index]}
                                                key={`${narrative.volume}${index + 1}`}
                                            />
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <EmptyState title="No narratives found in the watchlist"
                                    summary="We can’t find any items matching your search"
                                    icon={NotFoundFC}
                                    action={linkToNarrative}
                                />
                            )}
                        </>
                    ) : (
                        <div className="bg-white d-flex flex-column align-items-center">
                            <EmptyState title={searchBox ? 'No narratives found in the watchlist' : 'No narratives added to watchlist'}
                                summary={searchBox
                                    ? 'We can’t find any items matching your search'
                                    : 'Check narratives section and use ‘Add to watchlist’ button to add the narratives to this space'}
                                icon={NotFoundFC}
                                action={linkToNarrative}
                            />

                        </div>
                    )}
                </div>

            )}
            {(narratives && narratives?.newNarrativeServiceSearch?.count > 10 && selected.length === 0 && !loadingOriginalPost) && (
                <div className="border-top">
                    <Pagination total={narratives?.newNarrativeServiceSearch?.count || 0}
                        itemsPerPage={10}
                        offset={page}
                        setOffset={setPage}
                        className="py-2"
                    />
                </div>
            )}
            {selected.length > 0 ? (
                <StickToBottom>
                    <div className="bg-hover p-1 d-flex align-items-center justify-content-between">
                        <p className="m-0">{selected.length} selected</p>
                        <div className="d-flex">
                            <Button className="mr-1"
                                color={!toggleWatchlistLoading && 'danger'}
                                onClick={handleUpdateWatchlist}
                            >
                                {toggleWatchlistLoading ? <Loading relative /> : 'Remove from watchlist'}
                            </Button>
                            {contentActionsEnabled && (
                                <Button color="danger" onClick={setRemoveContentModal}>
                                    Remove narrative{selected.length > 1 && 's'}
                                </Button>
                            )}
                        </div>
                    </div>
                </StickToBottom>
            ) : (
                ''
            )}
        </div>
    );
};
