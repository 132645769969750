import React, { useState } from 'react';
import { TranslateButton } from '../../../../components/Atoms/TranslateButton';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';
import { getPostedByName } from '../../../../utils/getPostedByName';
import { getCtype } from '../../../../utils/text';
import { useQueryExtracts } from '../../../../services/Content/getQueryExtracts';
import { TranslatedFromLanguage } from '../../../../utils/TranslatedFromLanguage';

export const ContentText = ({ data, translateEntities }) => {
    const [translationClicked, setTranslationClicked] = useState(false);
    const textToShow = data.body?.length > 0 ? data?.body : data?.text;
    const ctype = getCtype(data?.contentType);
    const { highlightedText } = useQueryExtracts({
        text: textToShow
    });
    const { highlightedText: hihglightedTitle } = useQueryExtracts({
        text: data?.text
    });
    const {
        getTranslation: translateText,
        data: text,
        loading: translateLoading
    } = useTranslateMultipleText({ extracts: [highlightedText, hihglightedTitle] });
    const translatedBody = text.length ? text[0].translations[0] : highlightedText;
    const translatedTitle = text.length ? text[1].translations[0] : hihglightedTitle;

    return (
        <div className="bg-white p-3 mt-4">
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
                <h3 className="m-0">
                    <span>{getPostedByName(data.from, data.displayName)}</span> via
                    <SourceIconLabel data={{ contentType: data.contentType }} className="pl-1 d-inline" />
                    <span>{ctype.name} </span>
                </h3>
                <TranslateButton className="mr-2"
                    onClick={() => {
                        translateText();
                        if (translateEntities) translateEntities();
                        setTranslationClicked(!translationClicked);
                    }}
                    lang={data.langVerbose}
                    detectedLangs={data.detectedLangs}
                    loading={translateLoading}
                    showText
                />
            </div>
            <hr />
            <div className={`my-0 dont-break-out ${(highlightedText + data.text).length > 2000
            && 'set-max-height scrollbar-small'}`}
            >
                {data.text !== data.body && (
                    <p>
                        {translationClicked && !translateLoading && <TranslatedFromLanguage languageVerbose={data?.langVerbose} />}
                        <strong>
                            {getHighlightedContent(translatedTitle)}
                        </strong>
                    </p>
                )}
                <p className="highlight-em d-inline">
                    {translationClicked && !translateLoading && data.text === data.body && (
                        <TranslatedFromLanguage languageVerbose={data?.langVerbose} />
                    )}
                    {getHighlightedContent(translatedBody)}
                </p>
            </div>
        </div>
    );
};
