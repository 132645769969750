import React, { useState } from 'react';
import { Button, Table, UncontrolledTooltip } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useUser } from '../../../../../context/user/UserContext';
import { SortToggle } from '../../../../../components/Table/SortToggle';
import { ChangeSentiment } from './ChangeSentiment';
import { AddEntity } from './AddEntity';
import { entityColors } from './options';
import { DeleteEntity } from './DeleteEntity';
import { useUpdateEntities } from './updateEntitiesHook';
import { EntitySentimentHistory } from './EntitySentimentHistory';
import { EmptyState } from '../../../../../components/EmptyState';
import NoDataAvailable from '../../../../../assets/images/NoDataAvailable.png';
import { sentiments } from '../../../../../utils/lib/search';

export const Entities = ({ data, id }) => {
    const [sort, setSort] = useState({
        field: '',
        order: ''
    });
    const { notAllowed } = useUser();
    const { setModal, closeModal } = useModal();
    const { updateEntities } = useUpdateEntities({ id });
    const [entities, setEntities] = useState(data.map(a => ({ ...a, id: uuid() })));
    const updateItem = (item, added) => {
        const newItems = added ? [...entities, item] : entities.map(a => {
            if (a.id === item.id) {
                return item;
            }
            return a;
        }).filter(a => !a.deleted);
        updateEntities(
            newItems.map(a => `${(a.original || a.entity).replace(/ /g, '_')}:${a.sentiment}`),
            item,
            added,
            entities.find(a => a.id === item.id)
        );
        setEntities(newItems);
    };
    const sortEntities = (a, b) => {
        if (!sort.field) {
            return 0;
        }
        if (sort.field === 'name') {
            if (sort.order === 'asc') {
                return b.entity.localeCompare(a.entity);
            }
            return a.entity.localeCompare(b.entity);
        }
        if (sort.field === 'sentiment') {
            const sortOrder = sentiments.map(sentiment => sentiment.key);
            if (sort.order === 'asc') {
                return sortOrder.indexOf(b.sentiment) - sortOrder.indexOf(a.sentiment);
            }
            return sortOrder.indexOf(a.sentiment) - sortOrder.indexOf(b.sentiment);
        }
    };
    return (
        <div className="bg-white">
            <div className="font-weight-bold d-flex mb-2">
                Entities
                <div className="threat-tooltip" id="entitiesTooltip">?</div>
                <UncontrolledTooltip placement="right" target="entitiesTooltip">
                    <p>
                        An entity is a person (e.g. Boris Johnson), location, or organization.
                        These are also known as key players in platform.
                        The sentiment is the feeling/attitude (positive/negative/neutral/unknown) of a person
                        (author/publisher) towards an entity.
                    </p>
                    <p>
                        Note: Any given entity can also have multiple different sentiments depending on the context in which it
                        was mentioned. For example, one entity could have both a positive and a negative sentiment because of
                        the different occurrences of it being mentioned in the text along with its different contexts; one
                        being mentioned negatively and the other being positive.
                    </p>
                </UncontrolledTooltip>
            </div>
            {!notAllowed.includes('editData') && (
                <Button color="secondary"
                    onClick={() => setModal({
                        header: false,
                        component: <AddEntity entities={entities} addEntity={(val) => updateItem(val, true)} />
                    })}
                    className="mr-2"
                >
                    Add new entity
                </Button>
            )}
            <Button color="secondary"
                onClick={() => setModal({
                    header: false,
                    component: <EntitySentimentHistory threat={id} />,
                    size: 'xl'
                })}
            >
                View edit history
            </Button>
            {!entities.length ? (
                <div className="mt-3">
                    <EmptyState title="No data available" icon={NoDataAvailable} />
                </div>
            ) : null}
            <Table className={`mt-3 ${!entities.length ? 'd-none' : ''}`}>
                <thead className="border-0">
                    <tr>
                        <th className="border-0">
                            <SortToggle sort={sort} setSort={setSort} name="Entity name" field="name" />
                        </th>
                        <th className="border-0">
                            <SortToggle sort={sort} setSort={setSort} name="Sentiment" field="sentiment" />
                        </th>
                        {!notAllowed.includes('editData') && (
                            <th className="border-0">
                                Action
                            </th>
                        )}
                        <th className="border-0" />
                    </tr>

                </thead>
                <tbody>
                    {[...entities].sort(sortEntities).map((item) => (
                        <tr key={item.id}>
                            <td className="dont-break-out">{item.entity}</td>
                            <td className="text-capitalize">
                                <div className="d-flex align-items-center">
                                    <div className={`bg-${entityColors[item.sentiment].bg} rounded h-0 mr-1 entity-color`} />
                                    {item.sentiment}
                                </div>
                            </td>
                            {!notAllowed.includes('editData') && (
                                <>
                                    <td>
                                        <div className="d-flex">
                                            <p className="my-0 mr-4 text-primary"
                                                role="button"
                                                onClick={() => setModal({
                                                    header: false,
                                                    component: <ChangeSentiment data={item}
                                                        updateSentiment={(sentiment) => {
                                                            updateItem({
                                                                ...item,
                                                                sentiment
                                                            });
                                                            closeModal();
                                                        }}
                                                    />
                                                })}
                                            >
                                                Change sentiment
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <p className="my-0 text-danger ml-5"
                                            role="button"
                                            onClick={() => setModal({
                                                header: false,
                                                component: <DeleteEntity item={item}
                                                    handleDelete={() => {
                                                        updateItem({
                                                            ...item,
                                                            deleted: true
                                                        });
                                                        closeModal();
                                                    }}
                                                />
                                            })}
                                        >
                                            Delete entity
                                        </p>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
