import React, { useState } from 'react';
import { SearchBar } from '../../../../../components/Search';
import { getRoom } from '../../../../../utils/variables';
import { OverviewTopBar } from '../../OverviewTopBar';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import { SearchFeedback } from '../../../../../components/SearchFeedback';
import { Loading } from '../../../../../components/Loading';
import { ContentTableTypes, ContentTables } from '../../../../../components/ContentTable';
import { BackButton } from '../../../../../components/Atoms';
import { EmptyContentSearchResults } from './EmptyContentSearchResults';
import { getParam, setParam } from '../../../../../utils/urlParams';
import { useContent } from '../../../../../services/Content/getContent';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';

export const MatchesPage = () => {
    const room = getRoom();
    const page = getParam('page') || 1;
    const limit = 10;
    const [offset, setOffsetState] = useState((parseInt(page, 10) - 1) * 10);
    const setOffset = (newOffset) => {
        setOffsetState(newOffset);
        setParam('page', (newOffset / limit) + 1);
    };

    const [sort, setSort] = useState('date');
    const {
        data: contents,
        count,
        loading,
        error
    } = useContent({
        limit,
        skip: offset,
        sort,
        fetchPolicy: 'no-cache',
        name: 'getMatches',
        useGlobalFilters: true
    });

    const sortApplied = (sortNum) => {
        const sortOptions = [
            'date',
            'engagement',
            'score'
        ];
        setSort(sortOptions[sortNum - 1]);
        setOffset(0);
    };

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'Overview', href: `/situation-rooms/${room.id}/overview` },
        { title: 'Matches' }
    ];

    return (
        <div data-testid="matches-page">
            <OverviewTopBar />
            <div className="page-padding">
                <BreadCrumbs items={bread} />
                <BackButton pathName={`/situation-rooms/${room.id}/overview`} />
                <h2 className="mb-4">Matches</h2>
                <div className="mb-n2">
                    <SearchBar placeholder="Search - Enter a search term to update the threats and press ENTER"
                        name="Page Filters"
                        data={room}
                        additionalFilters={['whyathreat']}
                        sort
                        sortApplied={sortApplied}
                        score
                        showBooleanSearch
                    />
                </div>
                {loading ? <Loading pointer={false} gif relative height={400} /> : (
                    <>
                        <div className="my-3">
                            <SearchFeedback totalCount={count} error={error} disableLink contentType={CONTENT_TYPE.MATCH} />
                        </div>
                        <ContentTables contents={contents}
                            pagination={{ offset, setOffset, count }}
                            tableType={ContentTableTypes.MATCHES}
                            contentType={CONTENT_TYPE.MATCH}
                            total={count}
                            contentsToDisplay={limit}
                        />
                        {!count && <div className="mt-4"><EmptyContentSearchResults /></div>}
                    </>
                )}
            </div>
        </div>
    );
};
