import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../context/modal/ModalComponent';
import { WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../services/Content/contentInterface';
import { NotThreatIdentifierModal } from '../CommonModals/NotThreatIdentifier';

type MarkAsNotThreatButtonProps = {
    selectedContent: Pick<Content, WatchlistButtonContentKeys>[]
    onCompleted: () => void
    disabled: boolean
}

export const MarkAsNotThreatButton = ({ selectedContent, onCompleted, disabled }: MarkAsNotThreatButtonProps) => {
    const { setModal } = useModal();

    const selectedItemContainsNonThreats = selectedContent.find(content => content.threatObject?.threatLevel === 'none');

    if (selectedItemContainsNonThreats) return null;

    const setNotThreatIdentifierModal = () => setModal({
        modal: <NotThreatIdentifierModal ids={selectedContent.map(({ id }) => id)}
            onCompleted={onCompleted}
        />
    });
    return (
        <Button className="mr-1" color="primary" onClick={setNotThreatIdentifierModal} disabled={disabled}>
            Mark as not threat
        </Button>
    );
};
