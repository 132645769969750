import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import bson from 'bson';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../../../../../../context/modal/ModalComponent';
import investigationDeleted from '../../../../../../../assets/images/investigations/investigationDeleted.svg';
import { TopicItem } from './TopicItem';
import { resetCustomNarratives, saveCustomNarratives } from '../../../../../store';

export const AddNewTopic = ({ setRightPanel, setBooleanSwitch }) => {
    const dispatch = useDispatch();
    const [inputText, setInputText] = useState('');
    const [emptyTopicError, setEmptyTopicError] = useState(false);
    const [duplicateTopicError, setDuplicateTopicError] = useState(false);
    const { setModal, closeModal } = useModal();
    const { selectedTopic, topics, queryErrorList } = useSelector(state => state.customNarratives);

    const handleInputText = (value) => {
        const formattedName = value[0] !== ' ' ? value : value?.trim();
        if (!formattedName) {
            setRightPanel(false);
        }
        else {
            setRightPanel(true);
        }
        setInputText(formattedName);
        setDuplicateTopicError(false);
    };
    const handleOnBlurEvent = () => {
        const formattedName = inputText?.trim();
        if (formattedName) {
            const newTopic = { id: bson.ObjectId().toString(),
                threat: formattedName,
                keywords: [],
                boolean: '',
                query_type: '',
                dslQuery: '',
                isDslQueryValid: '' };
            handleAddTopic(newTopic);
            setInputText('');
        }
    };
    const handleAddTopic = (newTopic) => {
        let isPresent = false;
        topics.forEach((topic) => {
            if (topic.threat === newTopic.threat) {
                isPresent = true;
            } });
        if (isPresent) {
            setDuplicateTopicError(`Topic '${newTopic.threat}' has already been added. You cannot add duplicate topics`);
        }
        else {
            const newQueryErrorList = [...queryErrorList, { threat: newTopic.threat, error: {} }];
            dispatch(saveCustomNarratives({ topics: [...topics, newTopic],
                queryErrorList: newQueryErrorList,
                selectedTopic: newTopic }));
        }
    };

    const setRemoveSuccessModal = (text) => {
        setModal({
            component: (
                <div>
                    <img src={investigationDeleted} alt="" width="100" />
                    <h2 className="mt-2">{text ? 'All topics removed successfully' : 'This topic has been successfully removed'}</h2>
                    <hr />
                    <Button onClick={closeModal}>Close</Button>
                </div>
            )
        });
    };

    const setRemoveTopicModal = (value) => {
        setModal({
            component: (
                <div>
                    <h2>Are you sure you want to remove the selected topic?</h2>
                    <hr />
                    <p>You will lose all keywords or boolean query added for the topic and you can’t undo this action.</p>
                    <hr />
                    <div className="d-flex flex-row">
                        <Button className="mr-2" onClick={closeModal}>Close</Button>
                        <Button color="primary" onClick={() => { removeTopic(value); setRemoveSuccessModal(); }}>Remove</Button>
                    </div>
                </div>
            )
        });
    };
    const clearTopics = () => {
        const allTopics = topics.map((topic) => topic.threat);
        setModal(
            {
                component: (
                    <div>
                        <h2>Are you sure you want to remove all topics?</h2>
                        <hr />
                        <p>You will lose all keywords or boolean query added for the topics and you can’t undo this action.</p>
                        <hr />
                        <div className="d-flex flex-row">
                            <Button className="mr-2" onClick={closeModal}>Close</Button>
                            <Button color="primary"
                                onClick={() => { removeTopic(allTopics, 'all'); setRemoveSuccessModal('all'); }}
                            >Remove
                            </Button>
                        </div>
                    </div>
                )
            }
        );
    };
    const removeTopic = (keyword, text) => {
        const filteredTopics = topics.filter((item) => !keyword.includes(item.threat));
        dispatch(saveCustomNarratives({ topics: filteredTopics }));
        dispatch((saveCustomNarratives({ selectedTopic: filteredTopics[filteredTopics.length - 1] })));
        if (filteredTopics.length === 0) {
            setRightPanel(false);
        }
        if (text) {
            dispatch(resetCustomNarratives());
        }
    };
    if (duplicateTopicError) {
        setTimeout(() => {
            setDuplicateTopicError(false);
        }, 3000);
    }
    useEffect(() => {
        if (topics.length > 0) {
            let isEmptyTopic = false;
            topics.forEach((topic) => {
                if ((topic.keywords.length === 0 && !topic.boolean) && (selectedTopic.threat !== topic.threat)) {
                    isEmptyTopic = true;
                }
            });
            if (isEmptyTopic) {
                setEmptyTopicError(`No keywords or Boolean query have been added to the highlighted topic${
                    topics.length > 1 ? 's' : ''}`);
            }
            else {
                setEmptyTopicError(false);
                if (selectedTopic?.isSaved) {
                    setBooleanSwitch(selectedTopic.query_type === 'query');
                }
            }
        } else {
            setEmptyTopicError(false);
        }
    }, [topics, selectedTopic, setBooleanSwitch]);

    return (
        <div>
            <p>Add a topic</p>
            <div className={`form-control ${emptyTopicError && 'border-danger'} keyword-input`}>
                {(topics.length > 0) && (
                    <div className="d-flex align-items-center flex-wrap tags pt-1 pl-1 keyword-textarea">
                        {topics.map((topic) => (
                            <div key={topic.threat}>
                                <TopicItem topic={topic}
                                    removeTopic={() => {
                                        setRemoveTopicModal([topic.threat]);
                                    }}
                                    selectedTopic={selectedTopic}
                                />
                            </div>
                        ))}
                        {topics.length > 0 && (
                            <Button color="link"
                                className="p-0 mb-1"
                                onClick={() => {
                                    clearTopics();
                                }}
                            >Clear all
                            </Button>
                        )}
                    </div>
                )}
                <Input type="textarea"
                    data-testid="narrative-topic"
                    rows="3"
                    className="border-0 px-1 pb-0 pt-1 h-25"
                    placeholder="Enter topics"
                    value={inputText}
                    onChange={(e) => handleInputText(e.target.value)}
                    onBlur={() => handleOnBlurEvent()}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            const inputTopic = e.target.value;
                            const formattedTopic = inputTopic.trim();
                            const newTopic = { id: bson.ObjectId().toString(),
                                threat: formattedTopic,
                                keywords: [],
                                boolean: '',
                                query_type: '',
                                dslQuery: '',
                                isDslQueryValid: '' };
                            e.preventDefault();
                            e.stopPropagation();
                            handleAddTopic(newTopic);
                            setInputText('');
                        }
                    }}
                />
            </div>
            {emptyTopicError && (
                <p className="text-danger mt-1 mb-0">{emptyTopicError}</p>
            )}
            {duplicateTopicError
               && (<p className="text-danger mt-1">{duplicateTopicError}</p>)}
        </div>
    ); };
