import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ContentPreviewModal } from './contentPreviewModal';
import { ContentModal } from './contentModal';

export const ContentModalButton = ({ value, type, label, preview, children }) => {
    const { setModal } = useModal();

    const ContentModalComponent = preview ? ContentPreviewModal : ContentModal;

    return (
        <Button color="link"
            className="p-0 text-left"
            data-testid={`content-modal-button-${value}`}
            onClick={() => setModal({
                header: label,
                size: 'xl',
                component: <ContentModalComponent name={label} filter={{ value, type }} preview={preview} />
            })}
        >
            {children || label}
        </Button>
    );
};
