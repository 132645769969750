import { useQuery } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { GET_CLUSTERS, GET_SUB_CLUSTERS } from '../../../graphql';
import { getRoom } from '../../../utils/variables';
import { BubblePlot } from './BubblePlot';
import { Loading } from '../../../components/Loading';
import { TopActionBar } from './TopActionBar';
import { RootState } from '../../../store';
import { SelectedClusterView } from './SelectedClusterView';
import { EmptyState } from '../../../components/EmptyState';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { calculateAverageSentiment } from '../../../utils/calculateAverageSentiment';
import { ContentView } from './SelectedClusterView/ContentView';

type ClusterType = {
    id: string
    title: string
    subClustersMappedId: string[]
}

type SubClusterType = {
    id: string
    title: string
}

export const AIClustering = () => {
    const room = getRoom();
    const { isContentPage } = useSelector((state: RootState) => state.activeTab);
    const { selectedCluster, isParentCluster } = useSelector((state: RootState) => state.selectedCluster);
    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS, {
        variables: {
            projectId: room.project_id
        },
        fetchPolicy: 'no-cache',
    });
    const subClusterIds = clustersData?.getClusters?.map((cluster: ClusterType) => cluster.subClustersMappedId)?.flat() || [];
    const { data: subClustersData, loading: subClusterLoading } = useQuery(GET_SUB_CLUSTERS, {
        fetchPolicy: 'no-cache',
        skip: !subClusterIds.length,
        variables: {
            projectId: room.project_id,
            ids: subClusterIds
        }
    });

    const clusters = clustersData?.getClusters?.map((cluster: any) => ({
        ...cluster,
        subClusters: cluster.subClustersMappedId?.map((id: string) =>
            subClustersData?.getSubClusters.find((subCluster: any) => subCluster.id === id))
            ?.filter((a: any) => a)
    }));

    const selectedClusterData = isParentCluster ? clusters?.filter((cluster: ClusterType) => cluster.id === selectedCluster)
        : subClustersData?.getSubClusters.filter((subCluster: SubClusterType) => subCluster.id === selectedCluster);

    if (clusterLoading || subClusterLoading) {
        return <Loading relative height={400} />;
    }
    if (clustersData.getClusters.length === 0) {
        return <EmptyState title="No data found" />;
    }

    const bread = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` },
        { title: 'AI Clustering' }
    ];

    return (
        <main>
            {isContentPage
                ? <ContentView clusterData={selectedClusterData} isParentCluster={isParentCluster} />
                : (
                    <>
                        <div className="page-padding">
                            <BreadCrumbs items={bread} />
                            <TopActionBar clusterSize={clusters.length} subClusterSize={subClustersData?.getSubClusters.length} />
                        </div>
                        <div className="px-4 ai-clustering position-relative mh-content-page d-flex flex-column">
                            {selectedCluster && <SelectedClusterView cluster={selectedClusterData} isParent={isParentCluster} />}
                            <div className={`flex-1 mx-n4 transition position-absolute top-left h-100 w-100 mh-content-page ${
                                selectedCluster ? 'pointer-events-none' : ''}`}
                            >
                                <BubblePlot seriesData={clusters.map((cluster: any) => ({
                                    clusterId: cluster.id,
                                    name: cluster.title,
                                    data: cluster.subClusters.map((subCluster: any) => ({
                                        id: subCluster.id,
                                        name: subCluster.subClusterTitle,
                                        value: subCluster.volume,
                                        sentiment: calculateAverageSentiment(subCluster.sentimentJson)
                                    })),
                                    custom: {
                                        duplicates: cluster.duplicatedDocsCount,
                                        topActor: cluster.topActors[0].actor,
                                        sentiment: calculateAverageSentiment(cluster.sentimentJson)
                                    }
                                }))}
                                />
                            </div>
                        </div>
                    </>
                )}
        </main>
    );
};
