import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { getRoom } from '../../utils/variables';
import { useModal } from '../../context/modal/ModalComponent';

export const ThreatIdentifierSuccessModal = () => {
    const { closeModal } = useModal();
    const room = getRoom();

    return (
        <Modal isOpen toggle={closeModal}>
            <ModalBody>
                <h2>This content was successfully marked as a threat</h2>
                <hr />
                <p>You will be able to find it in a threats section. Find it in the
                    <a href={`${window.location.origin}/situation-rooms/${room?.id}/threats`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}Threats section.
                    </a>
                </p>
                <hr />
                <Button onClick={closeModal}>Close</Button>
            </ModalBody>
        </Modal>
    );
};
