import React, { useRef } from 'react';
import ColorScale from 'color-scales';
import { Cooccurance } from './Cooccurance';
import { EmptyState } from '../Narratives/Dashboard/EmptyState';
import { TopicInsightsKebabMenu } from './TopicInsightsKebabMenu';
// needs to be imported this way for tests to pass
const ReactWordcloud = require('react-wordcloud').default;

const getFormattedwords = (words) => {
    const sorted = words.sort((a, b) => a.value - b.value);
    const min = sorted[0].value;
    const max = sorted[sorted.length - 1].value;
    const scale = new ColorScale(min, max, ['#FFC700', '#4367F8']);
    return scale;
};

export const TopicWordCloud = ({ selectedCloud, data, clusterName }) => {
    const wordCloudKeywords = data.filter(cluster => cluster.cluster_id
        === clusterName.id).map(cluster => cluster[selectedCloud?.key]).flat();
    const ref = useRef(null);
    const callbacks = {
        getWordColor: (word) => getFormattedwords(wordCloudKeywords).getColor(word.value).toHexString()
    };

    const options = {
        enableTooltip: true,
        deterministic: false,
        fontFamily: 'nunito',
        fontSizes: [15, 50],
        fontStyle: 'normal',
        fontWeight: '700',
        padding: 1,
        rotations: 0,
        rotationAngles: [0, 0],
        scale: 'sqrt',
        spiral: 'archimedean',
        transitionDuration: 1000
    };
    return (
        <>
            <div className="d-flex w-100 justify-content-between">
                <p className="mb-0 mt-1">
                    <strong>{`Situation room keywords
                 (${!wordCloudKeywords[0] ? 0 : wordCloudKeywords.length}) `}
                    </strong>
                </p>
                <TopicInsightsKebabMenu id="wordCloud" />
            </div>
            <div className="h-100">
                {
                    (!wordCloudKeywords || wordCloudKeywords[0] === null) ? <EmptyState />
                        : (
                            <div className="mt-2" ref={ref}>
                                {selectedCloud.name === 'Co-occurence'
                                    ? <Cooccurance docWidth={ref.current.offsetWidth} data={wordCloudKeywords} />
                                    : (
                                        <ReactWordcloud callbacks={callbacks}
                                            options={options}
                                            words={wordCloudKeywords}
                                        />
                                    )}
                                {selectedCloud.name !== 'Co-occurence' && (
                                    <div className="d-flex align-items-center justify-content-center mt-5">
                                        <p className="m-0"><small>Fading</small></p>
                                        <div className="grad1" />
                                        <p className="m-0"><small>Trending</small></p>
                                    </div>
                                )}
                            </div>
                        )
                }

            </div>
        </>
    ); };
