import { useQuery } from '@apollo/client';
import { GET_NARRATIVES } from '../../graphql';
import { getRoom } from '../../utils/variables';
import { getIncludedLocations, getIncludedSources, getSentiment } from '../../pages/Room/Narratives/PageFilters/formatFilters';

export const useNarratives = ({ filterQuery, limit = 10, skip = 0, sort = '' }) => {
    const ret = useQuery(GET_NARRATIVES, {
        variables: {
            limit,
            skip,
            ...(sort ? { sort } : {}),
            opts: { trackTotalHits: true },
            query: filterQuery
        },
    });

    return {
        ...ret,
        data: ret.data ? ret.data.newNarrativeServiceSearch.hits.map(a => ({
            id: a._id,
            ...a._source,
            volume_over_time: parseInt(a._source.volume_over_time?.split(':')[1] || '0', 10),
            average_threat_label_over_time: a._source.average_threat_label_over_time?.split(':')[1],
        })) : [],
        count: ret.data ? ret.data.newNarrativeServiceSearch.count : 0
    };
};

export const convertToFilterQuery = ({ filters, searchPhrase }) => {
    const room = getRoom();

    return {
        bool: {
            ...(searchPhrase && {
                must: [
                    {
                        query_string: {
                            query: searchPhrase,
                            fields: ['narrative_title'],
                        },
                    }
                ],
            }),
            filter: [
                ...(room ? [{
                    term: {
                        project_id__keyword: {
                            value: room.project_id
                        }
                    }
                }] : []),
                ...(getIncludedLocations({ includedLocations: filters?.includedLocations })),
                ...(getIncludedSources({ includedSources: filters?.includedSources })),
                ...(getSentiment({ sentiment: filters?.sentiment }))
            ]
        }
    };
};
