import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import min from 'lodash/min';
import values from 'lodash/values';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// returns the color based on a node's value
export const colorPicker = (value) => {
    // if (value < 0.25) {
    //     return '#34526f';
    // }
    if (value < 0.5) {
        return '#b3c8db';
    }
    if (value < 0.75) {
        return '#e8eff4';
    }
    return '#ffffff';
};

// converts a set of scores to values between 0 and 1
const zeroToOne = (scores) => {
    const minimum = min(values(scores));
    const maximum = max(values(scores));
    return mapValues(scores, (value) => {
        if (maximum === minimum) {
            return minimum;
        }
        return (value - minimum) / (maximum - minimum);
    });
};

export const getThreat = (value) => {
    if (value === 'medium') {
        return 'rgb(253, 141, 60)';
    }
    if (value === 'high') {
        return 'rgb(252, 78, 42)';
    }
    return '#f3f3f3';
};
export const getAutomation = (value) => {
    if (value < 25) {
        return '#f3f3f3';
    }
    if (value < 50) {
        return 'rgb(253, 141, 60)';
    }
    if (value < 75) {
        return 'rgb(252, 78, 42)';
    }
    if (value >= 75) {
        return 'rgb(177, 0, 38)';
    }
    return '#f3f3f3';
};

export const normalize = (scores) => {
    if (!scores) {
        return undefined;
    }
    return zeroToOne(scores);
};

// select nodes by looking for the id1 property
export const isNode = (item) => !has(item, 'id1');

export const ToolbarItem = ({ title, icon, onClick }) => (
    <FontAwesomeIcon className="regraph-toolbutton my-1" role="button" title={title} icon={icon} onClick={onClick} />
);
