import * as React from 'react';

export const DataAdd = () => (
    <svg width={113}
        height={80}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#a)">
            <path d="M.874 68.897a4.087 4.087 0 0 0 4.087 4.087h103.08a4.086 4.086 0 0 0 4.087-4.087V11.554H.874v57.343Z"
                fill="#E5E6EB"
            />
            <path fill="#D2D4DB"
                d="M108.041 0H4.961A4.087 4.087 0 0 0 .874 4.087v7.467h111.252V4.087a4.093 4.093 0 0 0-1.196-2.89A4.076 4.076
                 0 0 0 108.041 0Z"
            />
            <path fill="#F8F8F8"
                d="M40.972 22.46h-28.26c-.952 0-1.724.773-1.724 1.726v33.873c0 .953.772 1.725 1.725 1.725h28.26c.952 0
                 1.724-.772 1.724-1.725V24.186c0-.953-.772-1.725-1.725-1.725ZM102.439 22.46H53.033c-.953 0-1.725.773-1.725
                  1.726v12.783c0 .952.772 1.725 1.725 1.725h49.406c.952 0 1.725-.773
                   1.725-1.725V24.186c0-.953-.773-1.725-1.725-1.725ZM102.439 42.269H53.033c-.953 0-1.725.772-1.725
                    1.725v12.783c0 .952.772 1.725 1.725 1.725h49.406c.952 0 1.725-.773 1.725-1.725V43.994c0-.953-.773-1.725-1.725-1.725Z"
            />
            <path d="m24.36 35.059-8.927 10.69h17.854l-8.927-10.69Z" fill="#E5E6EB" />
            <path d="m31.25 33.86-9.93 11.89H41.18L31.25 33.86Z" fill="#fff" />
            <path d="M19.246 35.931a2.075 2.075 0 1 0 0-4.15 2.075 2.075 0 0 0 0 4.15Z"
                fill="#E5E6EB"
            />
            <path d="M7.975 7.604a1.78 1.78 0 1 0 0-3.561 1.78 1.78 0 0 0 0 3.56Z"
                fill="#F8F8F8"
            />
            <path fill="#E5E6EB"
                d="M12.342 7.604a1.78 1.78 0 1 0 0-3.561 1.78 1.78 0 0 0 0 3.56ZM16.975 7.604a1.78 1.78 0 1 0 0-3.561 1.78
                 1.78 0 0 0 0 3.56Z"
            />
            <path fill="#D2D4DB"
                d="M97.606 28.758H56.92a.63.63 0 0 1 0-1.259h40.69a.63.63 0 1 1 0 1.26h-.005ZM97.607 33.658H56.922a.63.63 0
                 1 1 0-1.26h40.69a.63.63 0 0 1 0 1.26h-.005ZM97.604 48.568h-22.76a.63.63 0 1 1 0-1.26h22.76a.63.63 0 0 1
                  0 1.26ZM97.604 53.47h-22.76a.63.63 0 0 1 0-1.26h22.76a.63.63 0 0 1 0 1.26Z"
            />
            <path fill="#C8CACE"
                d="M56.5 80c9.229 0 16.71-7.481 16.71-16.71 0-9.23-7.481-16.712-16.71-16.712-9.23 0-16.712 7.482-16.712
                 16.711C39.788 72.52 47.27 80 56.5 80Z"
            />
            <path d="M56.5 69.572a.63.63 0 0 1-.63-.63V57.638a.63.63 0 1 1 1.26 0v11.304a.63.63 0 0 1-.63.63Z"
                fill="#fff"
            />
            <path d="M62.152 63.92H50.848a.63.63 0 1 1 0-1.26h11.304a.63.63 0 0 1 0 1.26Z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(.874)" d="M0 0h111.252v80H0z" />
            </clipPath>
        </defs>
    </svg>
);
