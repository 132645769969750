export const clustersMockData = [
    {
        cluster_name: 'death',
        cluster_id: '1',
        bigrams: [
            {
                text: 'covid-19',
                trending_score: 0.9215686274509803,
                value: 918,
                __typename: 'TopicClustersTopicClustersBigrams'
            }
        ],
        trigrams: [
            {
                text: 'covid-19',
                trending_score: 0.9215686274509803,
                value: 918,
                __typename: 'TopicClustersTopicClustersBigrams'
            }
        ],
        all_entities: [
            {
                text: 'covid-19',
                trending_score: 0.9215686274509803,
                value: 918,
                __typename: 'TopicClustersTopicClustersBigrams'
            }
        ],
        cluster_size: 9307,
        cluster_loc: {
            x: -0.10752649406614018,
            y: -0.036994568991152575,
            z: 23.37192226427459,
            __typename: 'TopicClustersTopicClustersCluster_loc'
        },
        sub_cluster_info: [
            {
                cluster_id: '2',
                sub_cluster_name: 'child',
                cluster_size: 3059,
                bigrams: [
                    {
                        text: 'covid-19',
                        trending_score: 0.8,
                        value: 242,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoBigrams'
                    }
                ],
                cooccurance_words: [
                    {
                        text: 'child',
                        value: 16,
                        x: 0.3488330476986676,
                        y: -0.6561473758919973,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoCooccurance_words'
                    }
                ],
                all_entities: [
                    {
                        text: 'covid',
                        trending_score: 0,
                        value: 560,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoAll_entities'
                    },
                    {
                        text: 'covid-19',
                        trending_score: 0.8,
                        value: 112,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoAll_entities'
                    }
                ],
                cluster_loc: {
                    x: -0.010249216609676587,
                    y: 0.01289558514258521,
                    z: 30.95717157154141,
                    __typename: 'TopicClustersTopicClustersSub_cluster_infoCluster_loc'
                },
                documents: [
                    '1575470837347790850-bbc9cc8b',
                    '1575517874256773125-192c85a6',
                    '1575509822161625089-ceaad09c',
                    '1575512113736450052-b5fe7fba'
                ],
                __typename: 'TopicClustersTopicClustersSub_cluster_info',
                cluster_tag: 'C1a'
            }
        ],
        __typename: 'TopicClustersTopicClusters',
        cluster_tag: 'C1'
    },
    {
        cluster_name: 'job',
        cluster_id: '8',
        bigrams: [
            {
                text: 'covid-19',
                trending_score: -1,
                value: 434,
                __typename: 'TopicClustersTopicClustersBigrams'
            }
        ],
        trigrams: [
            {
                text: 'supply-chain-issue',
                trending_score: 0,
                value: 28,
                __typename: 'TopicClustersTopicClustersTrigrams'
            }
        ],
        all_keywords: [
            {
                text: 'job',
                trending_score: 0,
                value: 287,
                __typename: 'TopicClustersTopicClustersAll_keywords'
            }
        ],
        cooccurance_words: [
            {
                text: 'job',
                value: 19,
                x: 0.5105194271514925,
                y: -0.45201187459084147,
                __typename: 'TopicClustersTopicClustersCooccurance_words'
            }
        ],
        all_entities: [{
            text: 'covid',
            trending_score: -0.8108108108108109,
            value: 956,
            __typename: 'TopicClustersTopicClustersAll_entities'
        }],
        cluster_size: 7086,
        cluster_loc: {
            x: -0.09304748609857691,
            y: -0.0065909044649005155,
            z: 20.58466095050387,
            __typename: 'TopicClustersTopicClustersCluster_loc'
        },
        sub_cluster_info: [
            {
                cluster_id: '0',
                sub_cluster_name: 'trump',
                cluster_size: 2369,
                bigrams: [
                    {
                        text: 'covid-19',
                        trending_score: 0,
                        value: 115,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoBigrams'
                    }
                ],
                trigrams: [
                    {
                        text: 'supply-chain-issue',
                        trending_score: 0,
                        value: 22,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoTrigrams'
                    }
                ],
                all_keywords: [
                    {
                        text: 'trump',
                        trending_score: -0.4444444444444444,
                        value: 434,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoAll_keywords'
                    }
                ],
                cooccurance_words: [
                    {
                        text: 'trump',
                        value: 30,
                        x: -0.4927732307146965,
                        y: 0.9005897038974042,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoCooccurance_words'
                    }
                ],
                all_entities: [
                    {
                        text: 'covid',
                        trending_score: -0.8,
                        value: 310,
                        __typename: 'TopicClustersTopicClustersSub_cluster_infoAll_entities'
                    }
                ],
                cluster_loc: {
                    x: 0.0119326611805757,
                    y: -0.07921122114727412,
                    z: 31.577430720126582,
                    __typename: 'TopicClustersTopicClustersSub_cluster_infoCluster_loc'
                },
                documents: ['6331ae22e0fa9d35b92ea812-b269b22e',
                    '6331cc4107a3970fc9e21fb3-473abc7e',
                    '6331e9fd07a3970fc9ed214c-8748cea9',
                    '63321abcad36d0b54b5c81a1-864a8b11'],
                __typename: 'TopicClustersTopicClustersSub_cluster_info',
                cluster_tag: 'C2a'
            }
        ],
        __typename: 'TopicClustersTopicClusters',
        cluster_tag: 'C2'
    }
];
