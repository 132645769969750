import React from 'react';
import { capitalizeFirstLetter } from '../../../../../utils/text';

export const KeywordsLegend = ({ series }) => {
    const colors = ['blue', 'purple', 'green', 'orange', 'yellow'];
    return (
        <div className="d-flex bg-white pl-2 pt-2 flex-row flex-wrap">
            {series?.map(({ name }, index) => (
                <div key={name} className="d-flex mr-2 align-items-center">
                    <div data-testid={`legends-${index}`} className={`square-small-${colors[index]} mr-1`} /> {capitalizeFirstLetter(name)}
                </div>
            ))}
        </div>
    );
};
