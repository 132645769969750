import React from 'react';

export const Warning = () => (
    <svg width="110" height="80" viewBox="0 0 110 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_252)">
            <path fill="#E5E6EB"
                d="M-6.10352e-05 67.5502C-6.10352e-05 68.6128 0.422143 69.632 1.17356 70.3834C1.92498 71.1348 2.94406
            71.5569 4.00672 71.5569H105.06C105.587 71.5573 106.108 71.4538 106.594 71.2526C107.08 71.0514 107.522 70.7562
            107.894 70.3841C108.266 70.012 108.561 69.5702 108.763 69.0839C108.964 68.5976 109.067 68.0764 109.067
            67.5502V11.3366H-6.10352e-05V67.5502Z"
            />
            <path fill="#D2D4DB"
                d="M105.06 0H4.00672C2.94406 0 1.92498 0.422144 1.17356 1.17356C0.422143 1.92498 -6.10352e-05
2.94412 -6.10352e-05 4.00678V11.3291H109.065V4.00678C109.065 2.94454 108.643 1.92578 107.892
1.17444C107.141 0.423089 106.123 0.000654491 105.06 0Z"
            />
            <path fill="#F8F8F8"
                d="M6.96198 7.4556C7.92594 7.4556 8.70739 6.67415 8.70739 5.71018C8.70739 4.74622 7.92594 3.96478
6.96198 3.96478C5.99802 3.96478 5.21657 4.74622 5.21657 5.71018C5.21657 6.67415 5.99802 7.4556 6.96198
7.4556Z"
            />
            <path fill="#E5E6EB"
                d="M11.2429 7.4556C12.2069 7.4556 12.9883 6.67415 12.9883 5.71018C12.9883 4.74622 12.2069 3.96478
11.2429 3.96478C10.279 3.96478 9.49751 4.74622 9.49751 5.71018C9.49751 6.67415 10.279 7.4556 11.2429
7.4556Z"
            />
            <path fill="#E5E6EB"
                d="M15.7851 7.4556C16.7491 7.4556 17.5305 6.67415 17.5305 5.71018C17.5305 4.74622 16.7491 3.96478
15.7851 3.96478C14.8212 3.96478 14.0397 4.74622 14.0397 5.71018C14.0397 6.67415 14.8212 7.4556 15.7851
7.4556Z"
            />
            <path fill="#F8F8F8"
                d="M37.9494 22.0211H10.245C9.31107 22.0211 8.55394 22.7783 8.55394 23.7122V56.9195C8.55394 57.8534
9.31107 58.6106 10.245 58.6106H37.9494C38.8834 58.6106 39.6405 57.8534 39.6405 56.9195V23.7122C39.6405
22.7783 38.8834 22.0211 37.9494 22.0211Z"
            />
            <path fill="#F8F8F8"
                d="M98.2099 22.0211H49.7754C48.8414 22.0211 48.0843 22.7783 48.0843 23.7122V36.2436C48.0843 37.1776
48.8414 37.9347 49.7754 37.9347H98.2099C99.1439 37.9347 99.901 37.1776 99.901 36.2436V23.7122C99.901 22.7783
99.1439 22.0211 98.2099 22.0211Z"
            />
            <path fill="#F8F8F8"
                d="M98.2099 41.438H49.7754C48.8414 41.438 48.0843 42.1951 48.0843 43.1291V55.6605C48.0843 56.5945
48.8414 57.3516 49.7754 57.3516H98.2099C99.1439 57.3516 99.901 56.5945 99.901 55.6605V43.1291C99.901 42.1951
99.1439 41.438 98.2099 41.438Z"
            />
            <path d="M21.6635 34.3698L12.9117 44.8497H30.4152L21.6635 34.3698Z" fill="#E5E6EB" />
            <path d="M28.4206 33.1923L18.6838 44.8497H38.1549L28.4206 33.1923Z" fill="white" />
            <path fill="#E5E6EB"
                d="M16.6491 35.2267C17.7726 35.2267 18.6834 34.3159 18.6834 33.1924C18.6834 32.069 17.7726 31.1582
16.6491 31.1582C15.5256 31.1582 14.6149 32.069 14.6149 33.1924C14.6149 34.3159 15.5256 35.2267 16.6491
35.2267Z"
            />
            <path fill="#D2D4DB"
                d="M93.4694 28.1957H53.5818C53.4181 28.1957 53.2612 28.1306 53.1454 28.0149C53.0297 27.8991 52.9646
27.7422 52.9646 27.5785C52.9646 27.4148 53.0297 27.2578 53.1454 27.1421C53.2612 27.0263 53.4181 26.9613
53.5818 26.9613H93.4694C93.6331 26.9613 93.7902 27.0263 93.9059 27.1421C94.0217 27.2578 94.0866 27.4148
94.0866 27.5785C94.0866 27.7422 94.0217 27.8991 93.9059 28.0149C93.7902 28.1306 93.6331 28.1957 93.4694
28.1957Z"
            />
            <path fill="#D2D4DB"
                d="M93.4694 32.9874H53.5818C53.4181 32.9874 53.2612 32.9224 53.1454 32.8067C53.0297 32.6909 52.9646
32.5339 52.9646 32.3702C52.9646 32.2065 53.0297 32.0495 53.1454 31.9338C53.2612 31.818 53.4181 31.753
53.5818 31.753H93.4694C93.6331 31.753 93.7902 31.818 93.9059 31.9338C94.0217 32.0495 94.0866 32.2065
94.0866 32.3702C94.0866 32.5339 94.0217 32.6909 93.9059 32.8067C93.7902 32.9224 93.6331 32.9874 93.4694
32.9874Z"
            />
            <path fill="#D2D4DB"
                d="M93.4698 47.6125H71.1572C70.9935 47.6125 70.8366 47.5475 70.7209 47.4318C70.6051 47.316 70.54
47.159 70.54 46.9953C70.54 46.8316 70.6051 46.6747 70.7209 46.5589C70.8366 46.4432 70.9935 46.3781 71.1572
46.3781H93.4698C93.6335 46.3781 93.7905 46.4432 93.9063 46.5589C94.022 46.6747 94.087 46.8316 94.087
46.9953C94.087 47.159 94.022 47.316 93.9063 47.4318C93.7905 47.5475 93.6335 47.6125 93.4698 47.6125Z"
            />
            <path fill="#D2D4DB"
                d="M93.4698 52.414H71.1572C70.9935 52.414 70.8366 52.349 70.7209 52.2333C70.6051 52.1175 70.54
51.9605 70.54 51.7968C70.54 51.6331 70.6051 51.4762 70.7209 51.3604C70.8366 51.2447 70.9935 51.1796
71.1572 51.1796H93.4698C93.6335 51.1796 93.7905 51.2447 93.9063 51.3604C94.022 51.4762 94.087 51.6331
94.087 51.7968C94.087 51.9605 94.022 52.1175 93.9063 52.2333C93.7905 52.349 93.6335 52.414 93.4698
52.414Z"
            />
            <path fill="#C8CACE"
                d="M54.5321 79.9999C63.58 79.9999 70.9148 72.6652 70.9148 63.6173C70.9148 54.5694 63.58 47.2346
54.5321 47.2346C45.4842 47.2346 38.1495 54.5694 38.1495 63.6173C38.1495 72.6652 45.4842 79.9999 54.5321
79.9999Z"
            />
            <path fill="white"
                d="M54.5327 72.364C52.8032 72.364 51.1126 71.8511 49.6746 70.8903C48.2366 69.9294 47.1157 68.5638
46.4539 66.9659C45.7921 65.3681 45.619 63.6099 45.9564 61.9137C46.2938 60.2175 47.1266 58.6594 48.3495
57.4365C49.5724 56.2136 51.1305 55.3807 52.8267 55.0433C54.523 54.7059 56.2812 54.8791 57.879
55.5409C59.4768 56.2028 60.8425 57.3236 61.8033 58.7616C62.7642 60.1996 63.277 61.8902 63.277
63.6196C63.2744 65.938 62.3523 68.1606 60.713 69.7999C59.0737 71.4392 56.851 72.3613 54.5327
72.364ZM54.5327 56.1097C53.0474 56.1097 51.5954 56.5501 50.3604 57.3754C49.1254 58.2006 48.1628 59.3734
47.5944 60.7457C47.026 62.118 46.8773 63.628 47.167 65.0848C47.4568 66.5415 48.172 67.8797 49.2223
68.93C50.2726 69.9803 51.6108 70.6955 53.0676 70.9853C54.5244 71.2751 56.0343 71.1263 57.4066
70.5579C58.7789 69.9895 59.9518 69.0269 60.777 67.7919C61.6022 66.5569 62.0426 65.105 62.0426
63.6196C62.0407 61.6285 61.2488 59.7194 59.8409 58.3115C58.4329 56.9035 56.5238 56.1117 54.5327
56.1097Z"
            />
            <path fill="white"
                d="M54.5327 64.2368C54.369 64.2368 54.2119 64.1718 54.0962 64.0561C53.9804 63.9403 53.9155
63.7833 53.9155 63.6196V60.3683C53.9155 60.2046 53.9804 60.0476 54.0962 59.9319C54.2119 59.8161
54.369 59.7511 54.5327 59.7511C54.6964 59.7511 54.8533 59.8161 54.969 59.9319C55.0848 60.0476
55.1498 60.2046 55.1498 60.3683V63.6196C55.1498 63.7833 55.0848 63.9403 54.969 64.0561C54.8533 64.1718
54.6964 64.2368 54.5327 64.2368Z"
            />
            <path fill="white"
                d="M54.5422 67.486C54.378 67.486 54.2205 67.4211 54.104 67.3055C53.9875 67.1899 53.9214 67.0329
    53.9201 66.8688C53.9194 66.788 53.9348 66.7078 53.9653 66.633C53.9957 66.5581 54.0407 66.49 54.0976
    66.4327C54.1545 66.3753 54.2223 66.3297 54.2969 66.2987C54.3715 66.2676 54.4515 66.2516 54.5323
    66.2516C54.696 66.2516 54.8529 66.3166 54.9687 66.4324C55.0844 66.5481 55.1495 66.7051 55.1495
    66.8688C55.1495 67.0325 55.0844 67.1895 54.9687 67.3052C54.8529 67.421 54.696 67.486 54.5323
    67.486H54.5422Z"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_252">
                <rect width="109.065" height="80" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
