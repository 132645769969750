import React from 'react';
import Highcharts, { Options, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

type MarkerProps = {
    enabled: boolean;
    symbol: string;
}

type SeriesData = {
    data: any[],
    color?: string,
    name: string,
    marker?: MarkerProps,
}

type SplineChartProps = {
    title?: string,
    seriesData: SeriesData[],
    height: number,
    lineColor?: string,
}

export const SplineChart = ({ title = undefined, seriesData, height, lineColor = '#E5E6EB' }: SplineChartProps) => {
    const convertedSeriesData: SeriesOptionsType[] = seriesData.map(data => ({
        ...data,
        type: 'spline'
    }));
    const options: Options = {
        title: {
            text: title,
            align: 'left',
            style: {
                color: '#030E3A',
                fontFamily: '"Nunito", sans-serif',
                fontSize: '18px',
                fontWeight: 'bold'
            }
        },
        chart: {
            height,
            plotBorderWidth: 1,
            type: 'spline',
            style: {
                fontFamily: 'serif'
            },
        },
        yAxis: {
            title: {
                text: null,
            },
            gridLineWidth: 0,
            labels: {
                style: {
                    color: '#B0B4C5'
                }
            },
            lineColor,
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 1,
            tickmarkPlacement: 'on',
            labels: {
                style: {
                    color: '#B0B4C5'
                }
            },
            tickWidth: 0,
            lineColor,
        },
        series: convertedSeriesData,
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        colors: ['#006FF9'],
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};
