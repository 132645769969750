import React from 'react';
import Highcharts, { Options, SeriesClickCallbackFunction } from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

// needed for tests to pass
highchartsMore(Highcharts);

type BubblePlotDataType = {
    name: string;
    x: number;
    y: number;
    z: number;
    __typename: string;
    value: number;
    id: string;
    clicked: number;
}

type ClusterNameType = {
    id: string,
    name: string
}

type BubblePlotProps = {
    bubblePlotData: BubblePlotDataType[];
    clusterName: ClusterNameType;
    onClusterBubbleClick: (e: any) => void;
}

export const BubblePlot = ({ bubblePlotData, clusterName, onClusterBubbleClick }: BubblePlotProps) => {
    const options = {
        chart: {
            type: 'bubble',
            zoomType: 'xy'
        },

        legend: {
            enabled: false
        },

        title: {
            text: ''
        },

        xAxis: {
            visible: false
        },

        yAxis: {
            visible: false
        },

        tooltip: {
            useHTML: true,
            headerFormat: '<table>',
            pointFormat: '<tr><th colspan="2"><h5 class="m-0">{point.name}</h5></th></tr>'
                + '<tr><th>Volume:</th><td>{point.value}</td></tr>',
            footerFormat: '</table>',
            followPointer: true
        },

        plotOptions: {
            series: {
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}',
                    color: 'black'
                },
                events: {
                    click: (e: SeriesClickCallbackFunction) => {
                        onClusterBubbleClick(e);
                    }
                }
            }
        },
        colors: ['#006FF9'],
        series: [{
            minSize: 40,
            maxSize: '25%',
            data: bubblePlotData?.map(item => ({
                ...item,
                ...(clusterName?.name ? {
                    color: `rgba(0, 111, 249, ${clusterName?.id === item.id ? '20' : '0.5'})`,
                    className: 'm-4',
                } : {})
            })) || []
        }]
    } as unknown as Options;
    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
