import { useTopPublishers } from '../../../../../services/Overview';
import { withTableStructure } from './withTableStructure';

const columns = [
    { key: 'publisher', label: 'Publisher', type: 'from' },
    { key: 'count', label: 'Matches' }
];

const config = {
    api: useTopPublishers,
    title: ({ preview }) => `Top ${!preview ? '10 ' : ''}Publishers`,
    tooltip: `View the top publishers of content in this room, with a count of the number of publications made.
    These can be websites, posts and tweets.`,
    columns
};

export const TopPublishers = withTableStructure(config);
