import React, { useRef } from 'react';
import { Table } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { Tabs } from '../../../../components/Tabs';
import { EmptyState } from './EmptyState';
import { TooltipItem } from '../../../../components/ToolTip';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { currentTab } from '../../../../utils/variables';
import { Loading } from '../../../../components/Loading';

export const NarrativeDrivingContent = ({ contentLoading, dcData, isEmptyNarrative }) => {
    const location = useLocation();
    const presentTab = useRef('keywords');

    const currentTabValue = currentTab();

    if (currentTabValue === 'keywords' || currentTabValue === 'emotions') {
        presentTab.current = currentTabValue;
    }
    if (contentLoading) {
        return <div className="bg-white p-3 h-100"><Loading relative height={300} /></div>;
    }
    return (
        <div className="p-3 bg-white h-100">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <h3 className=" m-0 mr-1">What is driving the Narrative?</h3>
                    <TooltipItem placement="bottom"
                        tooltipOpen="What is driving the Narrative?"
                        id="narrativeDrivingContent"
                        content={[
                            <div>
                                <div>
                                    <strong>Keywords. </strong>
                                    Big keywords that frequently appear in content being generated in relation to your Narrative.
                                </div>
                            </div>
                        ]}
                    />
                </div>
                {
                    (dcData?.keyphrase_table?.terms?.length > 0 && dcData?.keyphrase_table?.terms[0] !== null && !isEmptyNarrative)
                    || (dcData?.narrative_topics?.length > 0 && dcData?.narrative_topics[0] !== null)
                    || (dcData?.narrative_emotions && Object.keys(dcData?.narrative_emotions).length > 0)
                        ? (
                            <Link to={{ pathname: `${location.pathname}${
                                location.pathname.endsWith('/') ? '' : '/'
                            }what-is-driving-the-narrative`,
                            state: { prevTab: presentTab.current } }}
                            >
                                View details
                            </Link>
                        )
                        : null
                }
            </div>
            <div className="m-0 mt-2">
                <Tabs small
                    data={[
                        {
                            name: 'Keywords',
                            id: 'keywords',
                            content: <Keywords keywordData={dcData} isEmptyNarrative={isEmptyNarrative} />,
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export const Keywords = ({ keywordData, isEmptyNarrative }) => {
    const getChange = (increased, index) => {
        if (keywordData.keyphrase_table?.change[index]) {
            if (increased) {
                return <FeatherIcon.ArrowUp className="text-success" />;
            }
            return <FeatherIcon.ArrowDown className="text-danger" />;
        }
        return '';
    };
    return (
        <div>
            {keywordData?.keyphrase_table?.terms?.length > 0 && keywordData?.keyphrase_table?.terms[0] !== null && !isEmptyNarrative ? (
                <Table className="mt-2">
                    <thead>
                        <tr>
                            <th>
                                Keywords
                            </th>
                            <th className="text-center">
                                Matches
                            </th>
                            <th>
                                <p className="mb-0">
                                    Change in matches
                                    <TooltipItem placement="bottom"
                                        tooltipOpen="Change in matches"
                                        id="changeinmatches"
                                        content="The change in total matches over the date range selected."
                                    />

                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {keywordData.keyphrase_table?.terms?.sort((a, b) => b.localeCompare(a) - a.localeCompare(b)).map((item, i) => {
                            if (i > 4) {
                                return null;
                            }
                            return (
                                <tr key={item}>
                                    <td>
                                        <p>{capitalizeFirstLetter(item)}</p>
                                    </td>
                                    <td className="text-center">
                                        <p>{keywordData.keyphrase_table?.matches[i]}</p>
                                    </td>
                                    <td className="text-center">
                                        <div className="d-flex align-items-center">
                                            <p className="mr-1">
                                                {`${keywordData.keyphrase_table?.change[i]}%`}
                                            </p>
                                            {getChange(keywordData.keyphrase_table?.change[i] >= 0, i)}

                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : (
                <div className="mt-10">
                    <EmptyState />
                </div>
            )}
        </div>
    );
};
