import React, { useState, useEffect, useRef, useCallback } from 'react';
import uuid from 'uuid-random';

export const Cooccurance = ({ docWidth, data }) => {
    const [mainData, setMainData] = useState([]);

    const targetRef = useRef();
    const reArrangeData = useCallback((mockdata) => {
        const colors = ['#4367f8', '#d945d0', '#ff6c4c', '#ffa600'];
        const mock = mockdata;
        const x = { q1: [], q2: [], q3: [], q4: [] };
        for (let i = 0; i < mock.length; i++) {
            const q1 = (mock[i].x > 0 && mock[i].y > 0);
            const q2 = (mock[i].x < 0 && mock[i].y > 0);
            const q3 = (mock[i].x < 0 && mock[i].y < 0);
            const q4 = (mock[i].x > 0 && mock[i].y < 0);

            if (q1) {
                mock[i].color = colors[0];
                x.q1.push(mock[i]);
            }
            if (q2) {
                mock[i].color = colors[1];
                x.q2.push(mock[i]);
            }
            if (q3) {
                mock[i].color = colors[2];
                x.q3.push(mock[i]);
            }
            if (q4) {
                mock[i].color = colors[3];
                x.q4.push(mock[i]);
            }
        }
        return { ...x };
    }, []);

    const getTextWidth = useCallback((text, value) => {
        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
        const context = canvas.getContext('2d');
        context.font = `${value}px arial`;
        const metrics = context.measureText(text);
        return metrics.width + 2;
    }, []);

    const quadrantDivisionV2 = useCallback((data1, width = 300) => {
        const qData = reArrangeData(data1);
        let x = [];
        const y = [];
        let halfWidth = width / 2;

        const q1 = [...qData.q1];
        const q2 = [...qData.q2];
        const q3 = [...qData.q3];
        const q4 = [...qData.q4];
        let lineLength = 0;

        let lineNumber = 0;

        halfWidth = width / 2;
        for (let i = 0; i < q2.length; i++) {
            const word = q2[i];
            const wordLength = getTextWidth(word.text, word.value); // 11
            word.lineLength = lineLength;
            lineLength += wordLength;
            x.push(word);

            if (lineLength >= halfWidth || i === q2.length - 1) {
                y.push(x);
                x = [];
                lineLength = 0;
            }
        }

        halfWidth = width / 2;
        for (let i = 0; i < q1.length; i++) {
            const word = q1[i];
            const wordLength = getTextWidth(word.text, word.value); // 11
            word.lineLength = lineLength;
            lineLength += wordLength;
            x.push(word);
            if (lineLength >= halfWidth || i === q1.length - 1) {
                if (y[lineNumber] === undefined) {
                    halfWidth = width;
                    y.push(x);
                } else {
                    y[lineNumber] = [...y[lineNumber], ...x];
                }
                x = [];
                lineLength = 0;
                lineNumber += 1;
            }
        }

        halfWidth = width / 2;
        for (let i = 0; i < q3.length; i++) {
            const word = q3[i];
            const wordLength = getTextWidth(word.text, word.value); // 11
            word.lineLength = lineLength;
            lineLength += wordLength;
            x.push(word);

            if (lineLength >= halfWidth || i === q3.length - 1) {
                y.push(x);
                x = [];
                lineLength = 0;
            }
        }

        halfWidth = width / 2;
        for (let i = 0; i < q4.length; i++) {
            const word = q4[i];
            const wordLength = getTextWidth(word.text, word.value); // 11
            word.lineLength = lineLength;
            lineLength += wordLength;
            x.push(word);
            if (lineLength >= halfWidth || i === q4.length - 1) {
                if (y[lineNumber] === undefined) {
                    halfWidth = width;
                    y.push(x);
                } else {
                    y[lineNumber] = [...y[lineNumber], ...x];
                }
                x = [];
                lineLength = 0;
                lineNumber += 1;
            }
        }
        setMainData(y);
    }, [getTextWidth, reArrangeData]);

    useEffect(() => {
        quadrantDivisionV2([...data], docWidth / 1.4);
    }, [data, docWidth, quadrantDivisionV2]);

    return (
        <div ref={targetRef}>
            {mainData?.map((x) => (
                <div key={uuid()}
                    className="d-flex flex-row justify-content-center"
                >
                    {x.map((item) => { if (item.dummy === undefined) {
                        return (
                            <p key={item.text}
                                className="wordCloud_transition"
                                style={{ color: item.color, fontSize: `${item.value}px` }}
                                fontFamily="arial"
                            >{item.text}
                            </p>
                        );
                    } })}
                </div>
            ))}
        </div>
    );
};
