import React from 'react';
import { ActorsList } from './ActorsList';

type ActorsListProps = {
    actor: string
    contentIds: string[]
}

type TopActorsProps = {
    topActors: [ActorsListProps]
}

export const TopActors = ({ topActors }: TopActorsProps) => (
    <div className="bg-white p-2 maxh-420 scrollbar-small overflow-y-auto overflow-x-hidden">
        <div className="subClusterList">
            <div className="d-flex justify-content-between align-items-center p-2">
                <p className="w-75 font-weight-bold m-0">Actor details</p>
                <p className="w-25 font-weight-bold m-0">Contents</p>
            </div>
            <hr className="set-underline-props" />
            {
                topActors.map((actorData: ActorsListProps) => (
                    <ActorsList key={actorData.actor}
                        actor={actorData.actor}
                        contentIds={actorData.contentIds}
                    />
                ))
            }
        </div>
    </div>
);
