import React from 'react';
import { Spinner } from 'reactstrap';
import { TranslateIconBlue } from '../../../assets/icons/svgicons';

type TranslateButtonProps = {
    onClick: (e: any) => void,
    className?: string,
    lang?: string,
    detectedLangs: string[],
    loading?: boolean,
    showText?: boolean
}

export const TranslateButton = ({ onClick, className, lang, detectedLangs, loading, showText }: TranslateButtonProps) => {
    if (lang === 'English' && detectedLangs.length < 2) return null;
    return (
        <div role="button"
            className={`position-relative ${className}`}
            onClick={onClick}
        >
            <div className={loading ? 'invisible' : ''}>
                <TranslateIconBlue color="#006FF9" size={30} />
                {showText && <span className="text-primary">Translate</span>}
            </div>
            {loading && (
                <div className="text-primary absolute-center">
                    <Spinner size="sm" />
                </div>
            )}
        </div>
    );
};
