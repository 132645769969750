import React from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import { Col, Row } from 'reactstrap';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { TrafficSignalSmall } from '../../TrafficSignal/Small';
import { TooltipItem } from '../../../../../components/ToolTip';
import { OriginalPost } from './OriginalPost';

export const NarrativeStats = ({ narrative, small, fromReport }) => {
    const mentionsIncrease = narrative.volume_over_time
        ? ((narrative.volume - narrative.volume_over_time) / narrative.volume_over_time) * 100
        : 0;
    const spacing = small ? '11' : '2';
    return (
        <Row className="text-primary align-items-stretch">
            <Col xs="auto" className={`mt-${spacing}`}>
                <div className={`h-100 border rounded p-${spacing}`}>
                    <p className={`d-flex align-items-center mb-${spacing}`}>
                        <span>Total mentions</span>{' '}
                        {!small && (
                            <TooltipItem placement="top"
                                isHover
                                id={`totalMentionsTooltip${narrative.id}`}
                                content="The total amount of content pieces discovered that relate to a particular Narrative."
                                testid="narratives_tooltip"
                            />
                        )}
                    </p>
                    <p className={`d-flex align-items-center mb-${spacing}`}>
                        <strong>{largeNumber(narrative.volume)}</strong>
                        {narrative.volume_over_time ? (
                            <span className={`d-flex align-items-center ${
                                mentionsIncrease > 0 ? 'text-success' : 'text-danger'
                            }`}
                            >
                                {mentionsIncrease > 0 ? (
                                    <ArrowUp size={16} className="mx-1" />
                                ) : (
                                    <ArrowDown size={16} className="mx-1" />
                                )}
                                {Math.round(mentionsIncrease)}%
                            </span>
                        ) : (
                            ''
                        )}
                    </p>
                    <p className="mb-0">
                        <span>
                            {narrative.volume_over_time
                                ? largeNumber(narrative.volume_over_time)
                                : 'Not available'}{' '}
                            - 7 days ago
                        </span>
                        {!small && (
                            <TooltipItem placement="top"
                                isHover
                                id={`oldMentionsTooltip${narrative.id}`}
                                content="You can see the total mentions 7 days ago, the current amount of total mentions
                            and the change in mentions over the week"
                            />
                        )}
                    </p>
                </div>
            </Col>
            <Col xs="auto" className={`mt-${spacing} pl-0`}>
                <div className={`h-100 border rounded p-${spacing}`}>
                    <p className={`mb-${spacing}`}>
                        <span>Threat level</span>
                        {!small && (
                            <TooltipItem placement="top"
                                isHover
                                id={`threatLevelMentions${narrative.id}`}
                                content={[
                                    <div key={1}>
                                        <div>We create threat levels from a mixture of:
                                        </div>
                                        <div>- Content Metadata, usually metrics like Likes, Shares, and Engagement.</div>
                                        <div>
                                            - Content Itself, such as the language used, and the content sentiment.
                                        </div>
                                        <div>
                                            - Content Credibility, based on the content poster and where the content is hosted.
                                        </div>
                                    </div>]}
                            />
                        )}
                    </p>
                    <p className={`d-flex align-items-center text-capitalize mb-${spacing}`}>
                        <strong className="mr-1">{narrative.average_threat_label}</strong>
                        <TrafficSignalSmall level={narrative.average_threat_label} />
                    </p>
                    <p className="d-flex align-items-center mb-0">
                        <span className="d-flex align-items-center">
                            {narrative.average_threat_label_over_time ? (
                                <span className="d-flex align-items-center text-capitalize">
                                    <span className="mr-1">
                                        {narrative.average_threat_label_over_time}
                                    </span>
                                    <TrafficSignalSmall level={narrative.average_threat_label_over_time} />
                                </span>
                            ) : (
                                'No narrative available'
                            )}
              &nbsp;- 7 days ago
                        </span>
                        {!small && (
                            <TooltipItem placement="top"
                                isHover
                                id={`oldThreatLevelTooltip${narrative.id}`}
                                content="You can see the threat level 7 days ago, the current threat level and the change over the week"
                            />
                        )}
                    </p>
                </div>
            </Col>
            {narrative.original_post && !fromReport && (
                <Col xs="auto" className={`mt-${spacing} pl-0`}>
                    <OriginalPost spacing={spacing} id={narrative.original_post} />
                </Col>
            )}
        </Row>
    );
};
