import React from 'react';
import { Link } from 'react-router-dom';
import { getRoom } from '../../utils/variables';
import { ContentModal } from '../../pages/Room/Overview/ContentModal/contentModal';
import { useModal } from '../../context/modal/ModalComponent';
import { useAppSelector } from '../../store';

type SearchFeedbackProps = {
    totalCount: number,
    error: boolean,
    threats: boolean,
    disableLink: boolean,
    contentType?: string
}

type WithLinkProps ={
    children: React.ReactNode,
    disabled: boolean,
    contentType?: string
}

export const SearchFeedback = ({ totalCount, error, threats = false, disableLink = false, contentType }: SearchFeedbackProps) => {
    const { query: searchPhrase, booleanQuery } = useAppSelector((state) => state.filters.filters);
    const room = getRoom();

    if (searchPhrase || booleanQuery) {
        if (error) {
            return (
                <p>
                    An error occurred while trying to search for {searchPhrase}.
                </p>
            );
        }
        if (totalCount === 0) {
            return (
                <p data-testid="results-found" className="mb-2">
                    0 Results found for {searchPhrase || 'your query'}.
                    Check if this is related to your&nbsp;
                    <Link to={`/situation-rooms/${room.id}/settings?t=manage`}>
                        Situation Room Keyword
                    </Link> or try a more general keyword.
                </p>
            );
        }

        return (
            <p data-testid="showing-results">
                <WithLink disabled={disableLink}>
                    {totalCount} Result{totalCount > 1 && 's'}
                </WithLink> for {searchPhrase ? <strong>`{`${searchPhrase}`}`</strong> : 'your query'} (
                <span className="highlighted-text">Highlighted</span> information are the search keywords)
            </p>
        );
    }

    if (!totalCount) return null;
    return (
        <p>
            <WithLink disabled={disableLink} contentType={contentType}>
                {totalCount} Total {threats ? 'Threats' : 'Matches'}
            </WithLink> (
            <span className="highlighted-text">
                Highlighted
            </span> information are the keywords that are monitored in your situation room)
        </p>
    );
};

const WithLink = ({ children, disabled, contentType }: WithLinkProps) => {
    const { setModal } = useModal();
    if (disabled) return <span>{children}</span>;
    return (
        <span className="text-primary d-inline-block"
            role="button"
            onClick={() => setModal({
                header: 'Matches',
                component: <ContentModal contentType={contentType} />,
                size: 'xl'
            })}
        >
            {children}
        </span>
    );
};
