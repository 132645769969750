import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import removeUser from '../../../../assets/images/removeUser.png';
import { Loading } from '../../../../components/Loading';
import { UPDATE_SITUATION_ROOM } from '../../../../graphql';

export const RemoveUserModal = ({ room, closeModal, user, refetch }) => {
    const [done, setDone] = useState(false);
    const [updateSituationRoom, { loading }] = useMutation(UPDATE_SITUATION_ROOM, {
        onCompleted: () => {
            setDone(true);
            refetch();
        }
    });
    if (done) {
        return (
            <div>
                <img src={removeUser} alt="" />
                <h2>{user.displayName || user.email} successfully removed from {room.project_name} team!</h2>
                <hr />
                <Button onClick={() => closeModal()} color="secondary" className="mr-2">Close</Button>
            </div>
        );
    }
    if (loading) {
        return <Loading height={200} relative />;
    }
    return (
        <div>
            <img src={removeUser} alt="" />
            <h2>Are you sure you want to remove {user.displayName || user.email} from &quot;{room.project_name}&quot; team?</h2>
            <hr />
            <Button onClick={() => closeModal()} color="secondary" className="mr-2">Cancel</Button>
            <Button color="primary"
                onClick={() => updateSituationRoom({
                    variables: {
                        id: room.id,
                        removedUsers: [...room.removedusers, user.id]
                    }
                })}
            >Yes, Remove
            </Button>
        </div>
    );
};
