import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

type BackButtonProps = {
    pathName: string;
    buttonName?: string;
    gotToPreviousPath?: boolean;
}

export const BackButton = ({ pathName, buttonName = 'Back', gotToPreviousPath }: BackButtonProps) => {
    const history = useHistory();
    const content = (
        <div className="d-flex align-items-center">
            <ArrowLeft size={20} className="mr-11" />
            <span className="line-height-1">{buttonName}</span>
        </div>
    );
    if (history.length > 1 && gotToPreviousPath) {
        return (
            <Button color="link" className="mt-2 mb-4 d-inline-block p-0" onClick={() => history.goBack()}>
                {content}
            </Button>
        );
    }
    return (
        <Link to={pathName} className="mt-2 mb-4 d-inline-block">
            {content}
        </Link>
    );
};
