import React from 'react';
import { Col, Row } from 'reactstrap';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import ReactWordcloud, { OptionsProp } from 'react-wordcloud';
import { KeywordsList } from './KeywordsList';
import { truncateString } from '../../../../../utils/text';
import { EmptyState } from '../../../../../components/EmptyState';

type KeywordsListProps = {
    keyword: string
    count: number
}

type TopKeywordsProps = {
    topKeywords: [KeywordsListProps];
}

const options = {
    colors: ['#006FF9'],
    enableTooltip: false,
    fontFamily: 'nunito',
    fontSizes: [8, 20],
    fontStyle: 'normal',
    fontWeight: '800',
    rotations: 0,
    padding: 0,
    rotationAngles: [0, 90],
    scale: 'linear',
    spiral: 'rectangular',
    transitionDuration: 1000,
    deterministic: true,
} as OptionsProp;

export const TopKeywords = ({ topKeywords }: TopKeywordsProps) =>
    (
        <div className="bg-white p-2 maxh-420 scrollbar-small overflow-y-auto overflow-x-hidden">
            {topKeywords
                ? (
                    <Row>
                        <Col xs="auto" md={6}>
                            <div className="subClusterList">
                                <div className="d-flex justify-content-between align-items-center p-2">
                                    <p className="w-75 font-weight-bold m-0">Keywords</p>
                                    <p className="w-25 font-weight-bold m-0">Contents</p>
                                </div>
                                <hr className="set-underline-props" />
                                {
                                    topKeywords.map((keywords: KeywordsListProps) => (
                                        <KeywordsList key={keywords.keyword}
                                            keyword={keywords.keyword}
                                            count={Math.ceil(keywords.count)}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        <Col xs="auto" md={6} className="h-25 cluster-wordcloud">
                            <ReactWordcloud options={options}
                                words={topKeywords.map((item: KeywordsListProps) => ({
                                    text: truncateString(item.keyword, 40),
                                    value: item.count,
                                    original: item.keyword,
                                    ...item
                                }))}
                            />
                        </Col>
                    </Row>
                )
                : <EmptyState title="No keywords found" />}
        </div>
    );
