import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { AlertCircle, Eye, EyeOff } from 'react-feather';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    ModalBody
} from 'reactstrap';
import { Loading } from '../../../components/Loading';
import { verifyPassword } from '../../../auth0';
import { useUser } from '../../../context/user/UserContext';
import { DELETE_USER } from '../../../graphql';

export const LoginAndSecurity = ({ updateProfile }) => {
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [deleteSelected, setDeleteSelected] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmationError, setConfirmationError] = useState(false);
    const [modalInputText, setModalInuptText] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordEye, setOldPasswordEye] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordEye, setPasswordEye] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);
    const [deleteUser] = useMutation(DELETE_USER, {
        onCompleted: () => {
            window.location.replace(`${window.location.origin}/user-deleted`);
        }
    });
    const handleDelete = () => {
        if (modalInputText !== 'DELETE') {
            setConfirmationError(true);
        }
        else {
            setConfirmationError(false);
            deleteUser({
                variables: {
                    uid: user?.uid,
                    id: user?.id
                }
            });
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.length < 8) {
            setError('Password must be 8 characters or longer.');
        } else if (password !== confirmPassword) {
            setError('New password and Confirm password do not match.');
        } else if (oldPassword === password) {
            setError('Password cannot be the same as the old password.');
        } else {
            setLoading(true);
            try {
                const verified = await verifyPassword(user.email, oldPassword);
                if (verified) {
                    // update password using auth0
                    await updateProfile({
                        variables: {
                            data: {
                                id: user.uid,
                                password,
                            },
                        },
                    });
                    setDone(true);
                    setOldPassword('');
                    setPassword('');
                    setConfirmPassword('');
                } else {
                    setError('The old password you have entered is incorrect');
                }
            } catch {
                setError('Something went wrong, please try again later');
            } finally {
                setLoading(false);
            }
        }
    };
    return loading ? <Loading /> : (
        <>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label className="w-100">Old password
                        <InputGroup border={oldPassword ? 'active' : 'none'}>
                            <Input value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                type={oldPasswordEye ? 'text' : 'password'}
                                required
                            />
                            {oldPassword && (
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText onClick={() => setOldPasswordEye(!oldPasswordEye)}>
                                        {oldPasswordEye ? (
                                            <Eye color="#006FF9" size={22} />
                                        ) : (
                                            <EyeOff color="#006FF9" size={22} />

                                        )}
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>
                    </Label>
                </FormGroup>
                <FormGroup className="mt-2">
                    <Label className="w-100">New password
                        <InputGroup border={passwordEye ? 'active' : 'none'}>
                            <Input value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={passwordEye ? 'text' : 'password'}
                                required
                            />
                            {password && (
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText onClick={() => setPasswordEye(!passwordEye)}>
                                        {passwordEye ? (
                                            <Eye color="#006FF9" size={22} />
                                        ) : (
                                            <EyeOff color="#006FF9" size={22} />
                                        )}
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>
                    </Label>
                </FormGroup>
                <FormGroup className="mt-2">
                    <Label className="w-100">Confirm password
                        <InputGroup border={confirmPasswordEye ? 'active' : 'none'}>
                            <Input value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={confirmPasswordEye ? 'text' : 'password'}
                                required
                            />
                            {confirmPassword && (
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText onClick={() => setConfirmPasswordEye(!confirmPasswordEye)}>
                                        {confirmPasswordEye ? (
                                            <Eye color="#006FF9" size={22} />
                                        ) : (
                                            <EyeOff color="#006FF9" size={22} />
                                        )}
                                    </InputGroupText>
                                </InputGroupAddon>
                            )}
                        </InputGroup>
                    </Label>
                </FormGroup>
                <div className="mt-3 d-flex">
                    <Button className="mr-2" type="submit">Update</Button>
                    <Button color="danger" onClick={() => { setDeleteModal(true); }}>Delete account</Button>
                </div>
                <Alert color="danger" className="my-3" isOpen={error !== false} toggle={() => setError(false)}>
                    <span><AlertCircle color="#F84367" /> </span>
                    {error}
                </Alert>
                <Alert color="success" className="my-3" isOpen={done} toggle={() => setDone(false)}>
                    <span><AlertCircle color="#13BA9C" /></span>
                    Successfully updated password!
                </Alert>
            </Form>
            {
                deleteModal && (
                    <Modal isOpen={deleteModal}>
                        <ModalBody className="p-3">
                            <h2 className="m-0">Delete account</h2>
                            <hr />
                            <p>Your account will be permanently deleted.</p>
                            {deleteSelected && (
                                <div>
                                    <p>Type DELETE to confirm.</p>
                                    <Input type="text" onChange={(e) => setModalInuptText(e.target.value)} />
                                    {confirmationError
                                        && <p className="text-danger">Either type &ldquo;DELETE&rdquo; or press &ldquo;Cancel&rdquo;</p>}
                                </div>
                            )}
                            <hr />
                            {!deleteSelected && (
                                <><Button color="primary" onClick={() => setDeleteModal(false)}>Keep my account</Button>
                                    <Button className="ml-3"
                                        onClick={() => setDeleteSelected(true)}
                                    >Delete my account
                                    </Button>
                                </>
                            )}
                            {deleteSelected && (
                                <><Button color="primary" onClick={() => handleDelete()}>Delete my account</Button>
                                    <Button className="ml-3"
                                        onClick={() => { setDeleteModal(false); setDeleteSelected(false); }}
                                    >Cancel
                                    </Button>
                                </>
                            )}
                        </ModalBody>
                    </Modal>
                )
            }
        </>
    );
};
