import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { KeywordItem } from './KeywordItem';

export const KeywordsEntry = ({ keywords, setKeywords, clearKeywords }) => {
    const [input, setInput] = useState('');
    const [duplicateKeyword, setDuplicateKeyword] = useState('');
    const [error, setError] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState('');

    const removeKeyword = (keyword) => {
        const filteredKeywords = keywords.filter((a) => a !== keyword);
        setKeywords([...filteredKeywords]);
    };
    const addKeyword = (newKeyword) => {
        if (keywords.includes(newKeyword)) {
            setDuplicateKeyword(newKeyword);
            setError(`Keyword '${newKeyword}' has already been added. You cannot add duplicate keywords`);
        }
        else {
            setDuplicateKeyword('');
            setKeywords([...keywords, newKeyword]);
        }
    };
    if (error) {
        setTimeout(() => {
            setDuplicateKeyword('');
            setError(false);
        }, 3000);
    }

    return (
        <div>
            <p>Keywords</p>
            <div className="form-control keyword-input">
                {(keywords.length > 0) && (
                    <div className="d-flex align-items-center flex-wrap tags pt-1 pl-1 keyword-textarea">
                        {keywords.map((keyword) => (
                            <div key={keyword}>
                                <KeywordItem keyword={keyword}
                                    removeKeyword={(e) => {
                                        e.stopPropagation();
                                        removeKeyword(keyword);
                                    }}
                                    duplicateKeyword={duplicateKeyword}
                                    setSelectedKeyword={setSelectedKeyword}
                                    selectedKeyword={selectedKeyword}
                                />
                            </div>
                        ))}
                        {keywords.length > 0 && (
                            <Button color="link"
                                className="p-0 mb-1"
                                onClick={() => {
                                    clearKeywords();
                                    setError(false);
                                }}
                            >Clear all
                            </Button>
                        )}
                    </div>
                )}
                <Input type="textarea"
                    data-testid="narrative-keyword"
                    rows="3"
                    className="border-0 px-1 pb-0 pt-1 h-25"
                    placeholder="Enter keywords"
                    value={input}
                    onChange={(e) => {
                        if (!['\n', ','].includes(e.target.value)) {
                            setDuplicateKeyword('');
                            setError(false);
                        }
                        setInput(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                            const inputKeywords = e.target.value.split(',');
                            const newKeyword = inputKeywords[inputKeywords.length - 1].trim();
                            if (newKeyword) {
                                addKeyword(newKeyword);
                            }
                            setInput('');
                        }
                        if (e.key === 'Backspace') {
                            if (e.target.value === '') {
                                const lastKeyword = keywords?.length && keywords.pop();
                                removeKeyword(lastKeyword);
                            }
                        }
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                            setInput('');
                        }
                    }}
                />
            </div>
            {error && (
                <p className="text-danger mt-1">{error}</p>
            )}
        </div>
    ); };
