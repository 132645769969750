import React from 'react';
import { useDispatch } from 'react-redux';
import { getIcons } from '../../../../../utils/getIcons';
import { PieChart } from '../../../../../components/Highcharts';
import { capitalizeFirstLetter } from '../../../../../utils/text';
import { getSentimentColor } from '../../../../../utils/getSentimentColor';
import { TextTooltip } from '../../../../../components/ToolTip/TextTooltip';
import { setActiveTab } from '../../../store';

type SentimentType = {
    sentiment: string
    score: string
}

type ClusterStatsProps = {
    title: string
    icon: string
    count?: number
    averageSentiment?: string
    sentiments?: [SentimentType]
    id?: string
    setTab?: (a: string) => void
}

export const ClusterStats = ({ title, icon, count, averageSentiment, sentiments, id, setTab }: ClusterStatsProps) => {
    const dispatch = useDispatch();
    const setStoreValues = () => {
        if (icon === 'subClusters' && setTab) {
            setTab('subClusters');
        }
        else if (icon !== 'sentiment' && icon !== 'subClusters')dispatch(setActiveTab({ isContentPage: true }));
    };
    return (
        <div className="cluster-stat-container cursor-pointer" id={id} onClick={() => setStoreValues()}>
            <p className="text-primary m-0">{title}</p>
            <span className="font-weight-bold d-flex align-items-center">{getIcons(icon, averageSentiment)}
                {averageSentiment ? (
                    <TextTooltip placement="right" title={capitalizeFirstLetter(averageSentiment)} id={id} className="ml-1">
                        <p className="m-0">Sentiment</p>
                        <PieChart data={sentiments?.map(({ score }) => Math.round(parseFloat(score))) || []}
                            labels={sentiments?.map(item => item.sentiment) || []}
                            colors={sentiments?.map(item => getSentimentColor(item.sentiment)) || []}
                            legendEnabled={false}
                            height={200}
                            width={200}
                            innerSize="80%"
                        />
                        {sentiments?.map(({ sentiment, score }, i) => (
                            <div className="d-flex align-items-center" key={sentiment}>
                                <div style={{
                                    height: 11,
                                    width: 11,
                                    borderRadius: 2,
                                    backgroundColor: getSentimentColor(sentiment),
                                }}
                                />
                                <p className="mb-0 pl-1 text-primary">
                                    {capitalizeFirstLetter(sentiment)} {score}
                                </p>
                            </div>
                        ))}
                    </TextTooltip>
                )
                    : (
                        <p className="m-0 ml-1 text-primary">{count}
                        </p>
                    ) }
            </span>
        </div>
    );
};
